import React, { useContext, useEffect, useState } from "react";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";

const getFieldDropDowns = (subjectId: any, collegeId: any) => async () => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/examProcess/getVerificationOfQuestions", {
      params: { subjectId, collegeId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.data;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function QuestionBankCreatorVerification({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const { collegeId } = useContext(FirebaseContext);

  const { data: newData = {}, refetch } = useQuery(["getQuestionBank", addNew?.subjectId?._id], getFieldDropDowns(addNew?.subjectId?._id, collegeId));

  const addNewForm = async (status: string) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/actionQuestionnair", { collegeId, subjectId: addNew?.subjectId?._id, qid: newData?._id, status })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const [data, setData] = useState([]);
  const getData = async () => {
    const instance = await getAxiosTokenInstance();
    const data = await instance.get("/college/subject/getSubjectByFilter", {
      params: { collegeId },
    });
    setData(data?.data?.finalArray);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Main title="Question Bank Verification" width="70vw" height="70vh">
      <Header>
        <div className="flex">
          <Input width="400px" fieldName="Choose Subject" returnKey="subjectId" optionDisplay="name" valueShowKey="name" state={addNew} setState={setAddNew} options={data} type="drop" />
        </div>

        <div className="flex">
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <Body>
        {newData?.question ? (
          <div className="flex flex-col w-full max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Question Verification Window</h3>

            <div className="mb-2">
              <span className="font-medium">Question:</span> {newData?.question}
            </div>
            <div className="mb-2">
              <span className="font-medium">Mark:</span> {newData?.mark}
            </div>
            <div className="mb-2">
              <span className="font-medium">Taxonomy:</span> {newData?.taxonomy}
            </div>
            <div className="mb-4">
              <span className="font-medium">Connected Module:</span> {newData?.module}
            </div>
            <div className="mb-4">
              <span className="font-medium">Connected Topic:</span> {newData?.topic}
            </div>
            <div className="mb-4">
              <span className="font-medium">Level:</span> {newData?.level}
            </div>

            <div className="row flex justify-between ">
              <Button
                type="delete"
                onClick={() => {
                  addNewForm("delete");
                  refetch();
                }}
              >
                Delete
              </Button>
              <Button
                type="update"
                onClick={() => {
                  addNewForm("skip");
                  refetch();
                }}
              >
                Skip
              </Button>
              <Button
                type="save"
                onClick={() => {
                  addNewForm("verified");
                  refetch();
                }}
              >
                Verified
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">All Job Completed: Thank you your service is better con</div>
        )}
      </Body>
      <Footer></Footer>
    </Main>
  );
}

export default QuestionBankCreatorVerification;
