import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';

export const getAllClasses =
	(collegeId: string, classOnly: boolean = true) =>
	async () => {
		const instance = getAxiosInstance();
		const { data } = await instance
			.get('/college/classes/getClass', {
				params: { collegeId, needAll: true, classOnly: true },
			})
			.catch((error) => {
				throw new Error(error?.response?.data?.message || error.message || 'API Error');
			});

		if (data?.statusCode === 200) {
			return data;
		} else {
			throw new Error(data?.message);
		}
	};

function BEDStudent({ onClose }: { onClose: () => void }) {
	const { collegeId } = useContext(FirebaseContext);
	const [values, setValues] = useState<any[]>([]);
	const [selectedClass, setSelectClass] = useState('');
	const [dropDowns, setDropDowns] = useState<any[]>([]);
	const [splitup, setSplitup] = useState<any[]>([]);

	const [splitupData, setSplitupData] = useState<any[]>([]);

	const { data: classes } = useQuery(['allClasses', collegeId, true], getAllClasses(collegeId, true));

	const getSplitups = async () => {
		const instance = await getAxiosTokenInstance();
		const res = await instance
			.get('/college/admissionProcess/admittedCategory', { params: { collegeId } })
			.then((res) => {
				setSplitup(res?.data?.splitups);
			})
			.catch((err) => console.log(err));
		console.log(res);
	};

	const handleChange = (e: any, i: number) => {
		const { name, value } = e.target;
		const temp = [...values];
		temp[i][name] = value;
		setValues([...temp]);
	};
	const getNoDataStudents = async () => {
		const instance = await getAxiosTokenInstance();
		instance
			.get('/college/users/noDataStudent', { params: { collegeId, classId: selectedClass } })
			.then((res: any) => {
				console.log(res.data);
				setValues(res?.data?.data?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name)));
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	console.log(selectedClass);

	const handleSave = async () => {
		const instance = await getAxiosTokenInstance();
		instance
			.post('/college/users/updateBulkUsers', { data: values, collegeId })
			.then((res: any) => {
				console.log(res.data);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};
	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
			console.log(val);
		};

		getDropDowns();
		getSplitups();
	}, [collegeId]);
	useEffect(() => {
		getNoDataStudents();
	}, [collegeId, selectedClass]);

	function getOptions(splitup: any[], x: { collegeRoles: any[] }, collegeId: any) {
		const defaultOption = { title: 'Select Option' };

		if (!Array.isArray(splitup)) {
			return [defaultOption];
		}

		const classId = x?.collegeRoles?.find((y: { collegeId: any }) => y.collegeId === collegeId)?.classId;

		const filteredOptions = splitup?.find((p) => p?._id === classId);

		if (Array.isArray(filteredOptions?.splitup)) {
			return [defaultOption, ...filteredOptions?.splitup];
		} else {
			return [defaultOption];
		}
	}
	return (
		<Main title='Bulk Student Data Editor' height='90vh' width='98vw'>
			<Header>
				<Input fieldName='Select Class' setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
				<div className='flex'>
					<Button type='save' onClick={handleSave}>
						Save
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className=' overflow-auto'>
					<Table width='2800px' innerWidth={['200px', '90px', '140px', '160px', '60px']}>
						<Thead>
							<Tr>
								<Th position={4}>SN</Th>
								<Th position={2}>Adm.No.</Th>
								<Th position={0}>Name</Th>
								<Th position={0}>Blood Group</Th>
								<Th position={3}>Date of Adm.</Th>
								<Th position={1}>Roll No</Th>
								<Th position={2}>Admitted Category</Th>
								<Th position={1}>Index No.</Th>
								<Th position={1}>Last TC No</Th>
								<Th position={1}>Last TC Date</Th>
								<Th position={0}>Hall Ticket No</Th>
								<Th position={0}>Cap Id</Th>
								<Th position={3}>Date of Birth</Th>
								<Th position={2}>Gender</Th>
								<Th position={0}>Aadhaar No.</Th>
								<Th position={3}>Stay</Th>
								<Th position={3}>Second Language</Th>
								<Th position={3}>Guardian mobile Number</Th>
								<Th position={1}>Our TC Number</Th>
								<Th position={1}>Our TC Date</Th>
							</Tr>
						</Thead>
						<Tbody height='69vh'>
							{values?.map((x: any, i: number) => (
								<Tr>
									<Td position={4} padding={'1px'} index={i}>
										{i + 1}
									</Td>
									<Td position={2} padding={'1px'} index={i}>
										<input type='text' value={x?.admisionNo || ''} name='admisionNo' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={0} padding={'1px'} index={i}>
										<input type='text' value={x?.name || ''} name='name' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={0} padding={'1px'} index={i}>
										<input type='text' value={x?.bloodGroup || ''} name='bloodGroup' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={3} padding={'1px'} index={i}>
										<input type='date' name='dateOfAdmission' onChange={(e) => handleChange(e, i)} value={x?.dateOfAdmission ? (Date.parse(x.dateOfAdmission) ? new Date(x.dateOfAdmission).toISOString().split('T')[0] : '') : ''} />
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='text' value={x?.rollNo || ''} name='rollNo' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={2} padding={'1px'} index={i}>
										<select value={x?.admnType || ''} name='admnType' onChange={(e) => handleChange(e, i)}>
											{getOptions(splitup, x, collegeId).map((z) => (
												<option key={z.title} value={z?.title}>
													{z?.title}
												</option>
											))}
										</select>
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='text' value={x?.index || ''} name='index' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='text' value={x?.lastTcNumber || ''} name='lastTcNumber' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='date' value={x?.lastTcDate || ''} name='lastTcDate' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={0} padding={'1px'} index={i}>
										<input type='text' value={x?.hallTicketNo || ''} name='hallTicketNo' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={0} padding={'1px'} index={i}>
										<input type='text' value={x?.capId || ''} name='capId' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={3} padding={'1px'} index={i}>
										<input type='date' name='dob' onChange={(e) => handleChange(e, i)} value={x?.dob ? (Date.parse(x.dob) ? new Date(x.dob).toISOString().split('T')[0] : '') : ''} />
									</Td>
									<Td position={2} padding={'1px'} index={i}>
										<select value={x?.gender || ''} name='gender' onChange={(e) => handleChange(e, i)}>
											{['Select Option', ...dropDowns[0]?.gender]?.map((z: any) => (
												<option value={z}>{z}</option>
											))}
										</select>
									</Td>
									<Td position={0} padding={'1px'} index={i}>
										<input type='text' value={x?.aadhaarNumber || ''} name='aadhaarNumber' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={3} padding={'1px'} index={i}>
										<select value={x?.stay || ''} name='stay' onChange={(e) => handleChange(e, i)}>
											{['Select Option', ...(dropDowns[14]?.[collegeId || 'stayWhere'] || dropDowns[14]?.['stayWhere'])]?.map((z: any) => (
												<option value={z}>{z}</option>
											))}
										</select>
									</Td>
									<Td position={3} padding={'1px'} index={i}>
										<select value={x?.secondLanguage || ''} name='secondLanguage' onChange={(e) => handleChange(e, i)}>
											{['Select Option', ...(dropDowns[11]?.[collegeId || 'secondLanguage'] || dropDowns[11]?.['secondLanguage'])]?.map((z: any) => (
												<option value={z}>{z}</option>
											))}
										</select>
									</Td>
									<Td position={3} padding={'1px'} index={i}>
										<input type='number' value={x?.guardianMobile || ''} name='guardianMobile' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='text' value={x?.ourTcNumber || ''} name='ourTcNumber' onChange={(e) => handleChange(e, i)} />
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='date' value={x?.ourTcDate || ''} name='ourTcDate' onChange={(e) => handleChange(e, i)} />
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</div>
			</Body>
		</Main>
	);
}

export default BEDStudent;
