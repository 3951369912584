import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAllAccademics } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';

const getFieldDropDowns = (collegeId: any, type: any, search: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getHolidaysDrops', {
			params: { collegeId, type, search },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface invoiceManagementProps {
	onClose: () => void;
}

function AccademicHoliday({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [filteredItems, setItems] = useState<any>([]);
	const [search, setSearch] = useState<any>('');

	const removeItems = (subject: any) => {
		setItems(filteredItems.filter((s: any) => s !== subject));
	};

	const handleItems = (event: any) => {
		const value = event.target.value;
		if (!filteredItems?.includes(value)) {
			setItems([...(filteredItems || []), value]);
		}
	};

	const { data: tableData = [], refetch } = useQuery('getAllAccadamicHolidays', getAllAccademics(collegeId));
	const { data: dropItems = [] } = useQuery(['getFieldDropDowns', addNew?.effected], getFieldDropDowns(collegeId, addNew?.effected, search));

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteAccadamicHoliday/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postAccadamicHolidays', { ...addNew, collegeId })
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	return (
		<>
			<Main title='Accadamic Holiday Management' width='90vw' height='90vh'>
				<Header>
					<Button
						type='update'
						onClick={() => {
							setShowForm(true);
						}}>
						New Holiday
					</Button>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%' innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Start Date</Th>
									<Th position={2}>End Date</Th>
									<Th position={3}>Holiday</Th>
									<Th position={4}>Effected</Th>
									<Th position={5}>Managed By</Th>
									<Th position={6}>Action</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableData?.map((x: any, i: number) => (
									<Tr>
										<Td index={i} position={0}>
											{i + 1}
										</Td>
										<Td index={i} position={1}>
											{moment(x?.startDate).format('ddd-DD-MM-YYYY')}
										</Td>
										<Td index={i} position={2}>
											{moment(x?.endDate).format('ddd-DD-MM-YYYY')}
										</Td>
										<Td index={i} position={3}>
											{x?.tittle}
										</Td>
										<Td index={i} position={4}>
											{x?.effected}
										</Td>
										<Td index={i} position={5}>
											{x?.managedBy}
										</Td>
										<Td index={i} position={6}>
											<div className='flex'>
												<Button
													type='update'
													onClick={() => {
														setAddNew(x);
														setShowForm(true);
													}}>
													Edit
												</Button>
												<Button type='delete' onClick={() => handleDelete(x?._id)}>
													Delete
												</Button>
											</div>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>

			{showForm && (
				<Main width='40vw' height='100%'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'>
							<Input width='100%' fieldName='Holiday Tittle.' returnKey='tittle' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Start Date' returnKey='startDate' state={addNew} setState={setAddNew} type='date' />
							<Input width='100%' fieldName='End Date' returnKey='endDate' state={addNew} setState={setAddNew} type='date' />
							<Input width='100%' fieldName='Add to Working Days' returnKey='workingDays' state={addNew} setState={setAddNew} options={['Yes', 'No']} type='drop' />
							<Input width='100%' fieldName='Who Is Effect' returnKey='effected' state={addNew} setState={setAddNew} options={['All', 'Departmenet Wise', 'Batch Wise', 'Class Wise', 'Students Wise']} type='drop' />

							{addNew?.effected !== 'All' && (
								<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
									<div className='flex justify-center'>
										<span className='font-bold text-green-700 fs-4'> Select {addNew?.effected}</span>
									</div>

									{filteredItems?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
											<span>{dropItems.find((x: any) => x?._id === subject)?.name}</span>
											<button onClick={() => removeItems(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
											Select {addNew?.effected}
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleItems} value=''>
											<option value=''> Select {addNew?.effected}...</option>
											{dropItems?.map((subject: any, index: any) => (
												<option key={index} value={subject?._id}>
													{subject?.name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}

							<Button
								type='save'
								onClick={() => {
									addNewForm();
								}}>
								Save
							</Button>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default AccademicHoliday;
