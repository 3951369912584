import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { useQuery, useQueryClient } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
type AddNewEntry = {
	admisionNo?: string;
	name?: string;
	dob?: string;
	dateOfAdmission?: string;
	classId?: string;
	gender?: string;
	religion?: string;
	category?: string;
	caste?: string;
	tcNumber?: string;
	tcDate?: string;
	activeStatus?: string;
};

export const getAllClasses =
	(collegeId: string, classOnly: boolean = true) =>
	async () => {
		const instance = getAxiosInstance();
		const { data } = await instance
			.get('/college/classes/getClass', {
				params: { collegeId, needAll: true, classOnly: true },
			})
			.catch((error) => {
				throw new Error(error?.response?.data?.message || error.message || 'API Error');
			});

		if (data?.statusCode === 200) {
			return data;
		} else {
			throw new Error(data?.message);
		}
	};

export const getClassEssentials =
	(collegeId: string, classOnly: boolean = true) =>
	async () => {
		const instance = getAxiosInstance();
		const { data } = await instance
			.get('/college/action/getClassEssentials', {
				params: { collegeId },
			})
			.catch((error) => {
				throw new Error(error?.response?.data?.message || error.message || 'API Error');
			});

		if (data?.statusCode === 200) {
			return data;
		} else {
			throw new Error(data?.message);
		}
	};

export const getUsersDataBy = (collegeId: string, filterData: {}) => async () => {
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/action/getClassesByFilter', {
			params: { collegeId, ...filterData },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data?.classes;
	} else {
		throw new Error(data?.message);
	}
};

function CourseManagement({ onClose }: { onClose: () => void }) {
	const { collegeId } = useContext(FirebaseContext);
	const [values, setValues] = useState<any[]>([]);
	const [dropDowns, setDropDowns] = useState<any[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [addNew, setAddNew] = useState<AddNewEntry>({});
	const [addNewModal, setAddNewModal] = useState(false);
	const [isOffline, setIsOffline] = useState(!navigator.onLine);
	console.log({ addNew });

	const datadsdsd = { needAll: true, classOnly: true, subId: false };
	const allClasses = useAllClasses(datadsdsd);

	const classList = allClasses?.data || [];

	const [filterData, setOptions] = useState<{}>(() => {
		try {
			const savedData = localStorage.getItem('filterData');
			return savedData ? JSON.parse(savedData) : {};
		} catch (error) {
			console.error('Failed to parse local storage data for filterData:', error);
			return {};
		}
	});

	useEffect(() => {
		try {
			localStorage.setItem('filterData', JSON.stringify(filterData));
		} catch (error) {
			console.error('Failed to save filterData to local storage:', error);
		}
	}, [filterData]);

	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 150;

	const { data: dataData } = useQuery(['getClasdddddsBdyFilter', collegeId, filterData], getUsersDataBy(collegeId, filterData));

	// Define state variables for departments, courses, batches, etc.
	const [departments, setDepartments] = useState<any[]>([]);
	const [coures, setCourses] = useState<any[]>([]);
	const [batches, setBatches] = useState<any[]>([]);
	const [speclisations, setSpecializations] = useState<any[]>([]);
	const [courseTypes, setCourseTypes] = useState<any[]>([]);

	// Fetch data using useQuery and update the state
	const { data } = useQuery(['getClassEsdddsddentials', collegeId], getClassEssentials(collegeId), {
		staleTime: 1000 * 60 * 50,
		cacheTime: 1000 * 60 * 100,
	});
	const handleReload = async () => {
		await queryClient.fetchQuery(['getClasdddddgseghjkjsBdyFilter', collegeId, filterData], () => getUsersDataBy(collegeId, filterData));
	};

	useEffect(() => {
		if (data) {
			setDepartments(data.departments || []);
			setCourses(data.coures || []);
			setBatches(data.batches || []);
			setSpecializations(data.speclisations || []);
			setCourseTypes(data.courseTypes || []);
		}
	}, [data]);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (Array.isArray(dataData)) {
			setValues(dataData);
		}
	}, [dataData]);

	const totalPages = Math.ceil(values.length / rowsPerPage);
	const currentRows = values.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	const handleChange = (e: any, i: number) => {
		const { name, value } = e.target;
		const updatedValues = [...values];
		updatedValues[i] = { ...updatedValues[i], [name]: value };
		setValues(updatedValues);
	};

	const handleChangeClasses = (e: React.ChangeEvent<HTMLSelectElement>, i: number, keyItem: string) => {
		const { value } = e.target;

		const updatedValues = [...values];
		updatedValues[i] = {
			...updatedValues[i],
			[keyItem]: value,
		};

		// Handle different cases based on the keyItem
		if (keyItem === 'department') {
			const selectedDepartment = departments.find((item: any) => item._id === value);
			updatedValues[i] = {
				...updatedValues[i],
				departmentId: selectedDepartment?._id || '',
			};
		}

		if (keyItem === 'course') {
			const selectedCourse = coures.find((item: any) => item._id === value);
			updatedValues[i] = {
				...updatedValues[i],
				courseId: selectedCourse?._id || '',
			};
		}

		if (keyItem === 'batch') {
			const selectedBatch = batches.find((item: any) => item._id === value);
			updatedValues[i] = {
				...updatedValues[i],
				batchId: selectedBatch?._id || '',
				batchName: selectedBatch?.name || '',
			};
		}

		if (keyItem === 'specialization') {
			const selectedSpecialization = speclisations.find((item: any) => item._id === value);
			updatedValues[i] = {
				...updatedValues[i],
				specialiId: selectedSpecialization?._id || '',
				shortName: selectedSpecialization?.shortName || '',
			};
		}

		if (keyItem === 'courseType') {
			const selectedCourseType = courseTypes.find((item: any) => item._id === value);
			updatedValues[i] = {
				...updatedValues[i],
				courseTypeId: selectedCourseType?._id || '',
			};
		}

		// Update your state with the new values
		setValues(updatedValues);
	};

	const handleSave = async () => {
		setIsSaving(true);
		const instance = await getAxiosTokenInstance();
		try {
			await instance.post('/college/action/updateBulkClasses', { data: values, collegeId });
			console.log('Save successful');
			queryClient.invalidateQueries(['getClasdddddsBdyFilter', collegeId, filterData]);
			window.location.reload();
		} catch (err) {
			console.error('Save failed', err);
		} finally {
		}
	};
	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
			console.log(val);
		};

		getDropDowns();
	}, [collegeId]);

	// Render Pagination Controls
	const renderPaginationControls = () => (
		<div className='flex justify-between items-center mt-4'>
			<Button type='fetch' disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
				Previous
			</Button>
			<span>
				Page {currentPage} of {totalPages}
			</span>
			<Button type='save' disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
				Next
			</Button>
		</div>
	);

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/updateClassManagement', { ...addNew, collegeId })
				.then(() => {
					queryClient.invalidateQueries(['getUsersDataBy', collegeId, filterData]);
					window.location.reload();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			throw new Error('API Error');
		}
	};

	// Calculate filled counts for all fields

	// Use the function to get filled counts
	useEffect(() => {
		const handleOffline = () => setIsOffline(true);
		const handleOnline = () => setIsOffline(false);

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
		};
	}, []);
	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 20 }, (_, index) => (currentYear - index).toString());

	return (
		<Main title='Bulk Student Data Editor' height='90vh' width='98vw'>
			<Header>
				<div className='flex'>
					<Input width='200px' fieldName='Year' returnKey='year' state={filterData} setState={setOptions} options={years} type='drop' />
					<Input width='200px' fieldName='Mode' returnKey='mode' state={filterData} setState={setOptions} options={['Self', 'Aided']} type='drop' />
					<Input width='200px' fieldName='Search' returnKey='name' state={filterData} setState={setOptions} type='text' />
				</div>
				<div className='flex'>
					<Button type='save' onClick={handleSave}>
						Save
					</Button>
					<Button type='fetch' onClick={handleReload}>
						Remove Cache and Reload
					</Button>
					<Button
						type='save'
						onClick={() => {
							setAddNewModal(true);
						}}>
						Add New
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>

			<Table width='100%' innerWidth={['200px', '170px', '160px', '120px', '90px', '250px', '350px']}>
				<Thead>
					<Tr>
						<Th position={4}>SN</Th>
						<Th position={4}>Copy</Th>
						<Th position={4}>Count</Th>
						<Th position={5}>Batch</Th>
						<Th position={5}>Course</Th>
						<Th position={6}>Specialisation</Th>
						<Th position={5}>Department</Th>
						<Th position={5}>Type</Th>
						<Th position={2}>No of Semester</Th>
						<Th position={2}>Govern By</Th>
						<Th position={2}>Custome Admission No</Th>
					</Tr>
				</Thead>

				<Tbody height='69vh'>
					{currentRows?.map((x: any, i: number) => (
						<Tr>
							<Td position={4} padding={'1px'} index={i}>
								{i + 1}
							</Td>

							<Td index={x} position={4} textAlign='center' fontSize='6px'>
								<button
									onClick={() => navigator.clipboard.writeText(x?._id)}
									style={{
										backgroundColor: 'transparent',
										border: 'none',
										cursor: 'pointer',
										fontSize: '6px',
										color: 'blue',
										textDecoration: 'underline',
									}}>
									Copy ID
								</button>
							</Td>
							<Td position={4} padding={'1px'} index={i}>
								{x?.studentCount}
							</Td>

							<Td position={5} padding={'1px'} index={i}>
								<select value={x.batchId || ''} onChange={(e) => handleChangeClasses(e, i, 'batch')} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Batch
									</option>
									{batches.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.name}
										</option>
									))}
								</select>
							</Td>
							<Td position={5} padding={'1px'} index={i}>
								<select value={x.courseId || ''} onChange={(e) => handleChangeClasses(e, i, 'course')} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Course
									</option>
									{coures.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.name}
										</option>
									))}
								</select>
							</Td>

							<Td position={6} padding={'1px'} index={i}>
								<select value={x.specialiId || ''} onChange={(e) => handleChangeClasses(e, i, 'specialization')} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Specialization
									</option>
									{speclisations.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.name}
										</option>
									))}
								</select>
							</Td>
							<Td position={5} padding={'1px'} index={i}>
								<select value={x.departmentId || ''} onChange={(e) => handleChangeClasses(e, i, 'department')} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Department
									</option>
									{departments.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.name}
										</option>
									))}
								</select>
							</Td>

							<Td position={5} padding={'1px'} index={i}>
								<select value={x.courseTypeId || ''} onChange={(e) => handleChangeClasses(e, i, 'courseType')} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Course Type
									</option>
									{courseTypes.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.name}
										</option>
									))}
								</select>
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								<select value={x?.noOfSem || ''} name='noOfSem' onChange={(e) => handleChange(e, i)}>
									<option value='' disabled>
										Select No Semster
									</option>
									{['4', '6', '8', '10']?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								<select value={x?.governBody || ''} name='governBody' onChange={(e) => handleChange(e, i)}>
									<option value='' disabled>
										Select Govern Body
									</option>
									{['Aided', 'Self', 'Govt']?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								<select value={x?.customAdmissionNumber || ''} name='customAdmissionNumber' onChange={(e) => handleChange(e, i)}>
									<option value='' disabled>
										Select Custome Admission No
									</option>
									{['true', 'false']?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
						</Tr>
					))}
					{renderPaginationControls()}
				</Tbody>
			</Table>
			{addNewModal && (
				<Main width='40vw' height='100%'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								setAddNewModal(false);
							}}>
							Close
						</Button>
					</Header>

					<Body>
						<h2 className='text-lg font-bold mb-4'>Add New Entry</h2>
						<Input width='100%' didntShowKey fieldName='Department' optionDisplay='name' returnKey='departmentId' optionKey='_id' state={addNew} setState={setAddNew} options={departments} type='drop' />
						<Input width='100%' didntShowKey fieldName='Course Type' optionDisplay='name' returnKey='courseTypeId' optionKey='_id' state={addNew} setState={setAddNew} options={courseTypes} type='drop' />
						<Input width='100%' didntShowKey fieldName='Course' optionDisplay='name' returnKey='courseId' optionKey='_id' state={addNew} setState={setAddNew} options={coures} type='drop' />
						<Input width='100%' didntShowKey fieldName='Batch' optionDisplay='name' returnKey='batchId' optionKey='_id' state={addNew} setState={setAddNew} options={batches} type='drop' />
						<Input width='100%' didntShowKey fieldName='Specialisation' optionDisplay='name' returnKey='specialiId' optionKey='_id' state={addNew} setState={setAddNew} options={speclisations} type='drop' />
						<Input width='100%' fieldName='No of Semester' returnKey='noOfSem' state={addNew} setState={setAddNew} type='number' />
						<Input width='100%' fieldName='Govern Body?*' returnKey='governBody' state={addNew} setState={setAddNew} options={['Aided', 'Self', 'Govt']} type='drop' />
						<Input width='100%' fieldName='Custom Admission Number?*' returnKey='customAdmissionNumber' state={addNew} setState={setAddNew} options={[true, false]} type='drop' />
						<Button
							type='save'
							onClick={() => {
								addNewForm();
							}}>
							Save
						</Button>
					</Body>
				</Main>
			)}
		</Main>
	);
}

export default CourseManagement;
