import React, { useEffect, useState } from "react";
import Register from "./Register";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import ApplicationOpen from "./ApplicationOpen";
import Controlls from "./Controlls";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

const ShCollegeChalakudy: React.FC = () => {
  const initCollegeId = "COL-4BE8C5548D0C-432E-AA07-1D5DE104B3C6";
  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const [apply, setApply] = useState<boolean>(false);
  const { data: collegeData } = useQuery([`collegeData`, initCollegeId], getCollegeDetails);
  const [login, setLoginAll] = useState<boolean>(false);

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };
    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("userToken");
    if (token) setLogin(true);
    else setLogin(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
Cookies.remove('userToken');
    window.location.reload();
  };

  return !internet ? (
    <div className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Nirmala College Muvatuppuzha!
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center lg:w-full lg:h-screen bg-no-repeat bg-cover bg-center" style={{ backgroundImage: "url('https://www.info24.in/uploads/15502250301465410695Sacred%20Heart%20college%20Chalakudy%201.jpg')" }}>
      <>
        {successLogin ? (
          <>
            {apply ? (
              <ApplicationOpen initCollegeId={initCollegeId} collegeData={collegeData} />
            ) : (
              <div className="flex justify-center min-h-screen row  overflow-auto">
                <div className="bg-[#b20f06] h-12 shadow flex items-center justify-between">
                  <div className="flex-1">
                    <nav className="hidden md:flex space-x-10"></nav>
                  </div>
                  <div className="flex-1">
                    <nav className="hidden md:flex justify-end space-x-10 p-3">
                      <a href="/login" className="text-base font-medium text-white hover:text-gray-200">
                        Login
                      </a>

                      <a href="https://sacredheartcollege.ac.in/wp-content/uploads/2022/04/NEP.pdf" className="text-base font-medium text-white hover:text-gray-200">
                        NEP
                      </a>
                      <a href="https://sacredheartcollege.ac.in/alumni/" className="text-base font-medium text-white hover:text-gray-200">
                        Alumni
                      </a>
                      <a href="https://sacredheartcollege.ac.in/nirf/" className="text-base font-medium text-white hover:text-gray-200">
                        NIRF
                      </a>
                    </nav>
                  </div>
                </div>
                <div className="bg-white h-28 shadow">
                  <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                      <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="https://sacredheartcollege.ac.in/">
                          <img className="h-12 w-auto sm:h-16" src="https://sacredheartcollege.ac.in/wp-content/uploads/2022/03/logo.png" alt="Nirmala College Logo" />
                        </a>
                      </div>
                      <nav className="hidden md:flex space-x-10">
                        <a href="https://sacredheartcollege.ac.in/admission/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                          Admission
                        </a>
                        <a href="https://sacredheartcollege.ac.in/facilities/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                          Facilities
                        </a>
                        <a href="https://sacredheartcollege.ac.in/fyugp/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                          FYUGP
                        </a>
                        <a href="https://sacredheartcollege.ac.in/academics/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                          Academics
                        </a>
                      </nav>
                      {apply ? (
                        <div className="flex items-center justify-end md:flex-1 lg:w-0">
                          <a
                            onClick={() => {
                              setApply(false);
                            }}
                            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700"
                          >
                            Back to Dash Board
                          </a>
                        </div>
                      ) : (
                        <div className="flex items-center justify-end md:flex-1 lg:w-0">
                          <a onClick={handleLogout} className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700">
                            Logout
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Controlls initCollegeId={initCollegeId} collegeData={collegeData} setApply={setApply} applicationOpen={false} />
              </div>
            )}
          </>
        ) : (
          <div
            className="flex justify-center min-h-screen overflow-scroll row"
            style={{
              backgroundImage: "url('https://www.info24.in/uploads/15502250301465410695Sacred%20Heart%20college%20Chalakudy%201.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="bg-[#b20f06] h-12 shadow flex items-center justify-between">
              <div className="flex-1">
                <nav className="hidden md:flex space-x-10"></nav>
              </div>
              <div className="flex-1">
                <nav className="hidden md:flex justify-end space-x-10 p-3">
                  <a href="/login" className="text-base font-medium text-white hover:text-gray-200">
                    Login
                  </a>

                  <a href="https://sacredheartcollege.ac.in/wp-content/uploads/2022/04/NEP.pdf" className="text-base font-medium text-white hover:text-gray-200">
                    NEP
                  </a>
                  <a href="https://sacredheartcollege.ac.in/alumni/" className="text-base font-medium text-white hover:text-gray-200">
                    Alumni
                  </a>
                  <a href="https://sacredheartcollege.ac.in/nirf/" className="text-base font-medium text-white hover:text-gray-200">
                    NIRF
                  </a>
                </nav>
              </div>
            </div>
            <div className="bg-white h-24 shadow">
              <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                  <div className="flex justify-start lg:w-0 lg:flex-1">
                    <a href="https://sacredheartcollege.ac.in/">
                      <img className="h-12 w-auto sm:h-16" src="https://sacredheartcollege.ac.in/wp-content/uploads/2022/03/logo.png" alt="Nirmala College Logo" />
                    </a>
                  </div>
                  <nav className="hidden md:flex space-x-10">
                    <a href="https://sacredheartcollege.ac.in/admission/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                      Admission
                    </a>
                    <a href="https://sacredheartcollege.ac.in/facilities/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                      Facilities
                    </a>
                    <a href="https://sacredheartcollege.ac.in/fyugp/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                      FYUGP
                    </a>
                    <a href="https://sacredheartcollege.ac.in/academics/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                      Academics
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="w-full max-w-8xl mx-auto my-4 bg-opacity-60 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg overflow-scroll">
              <div className="lg:flex">
                <div className="lg:p-4 overflow-y-auto lg:w-1/3 bg-blue-900 bg-opacity-30 rounded-l-lg">
                  <Register collegeData={collegeData} login={login} initCollegeId={initCollegeId} setLogin={setLogin} />
                </div>
                <div className="p-4 bg-blue-900 bg-opacity-30 lg:w-2/3 text-white rounded-r-lg">
                  <h1 className="text-2xl lg:text-3xl text-shadow font-nunito font-bold mb-4 text-white text-center">Embark on Your Academic Journey</h1>
                  <p className="font-nunito text-shadow font-bold text-white text-lg">{collegeData?.collegeName} Welcomes You!</p>
                  <p className="mt-2 text-white text-shadow font-bold text-base text-justify">
                    Your path to academic excellence and holistic development starts here. Explore our diverse range of programs and find one that aligns with your aspirations. Learn more about our application process, scholarships, and the vibrant campus life by visiting our website:
                    <br />
                    <a href={collegeData?.website} className="text-blue-200 hover:text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer">
                      {collegeData?.website}
                    </a>
                    .
                  </p>
                  <p className="mt-2 text-base text-white text-shadow font-bold text-justify">Please prepare your academic records and necessary documents for submission. Guidance is available every step of the way.</p>
                  <p className="mt-2 text-base text-white text-shadow font-nunito font-medium text-justify">
                    Need help? Our dedicated admissions team is here to assist you, whether you prefer online assistance or a personal consultation. Visit our admissions help desk from 9:00 AM to 3:00 PM or reach us at:
                    <a href={`tel:${collegeData?.phoneNumber}`} className="text-blue-200 hover:text-blue-300 hover:underline">
                      {collegeData?.phoneNumber}
                    </a>
                    for any inquiries.
                  </p>
                  <p className="mt-4 font-nunito text-shadow font-semibold text-white text-lg">We're excited to welcome you to the {collegeData?.collegeName} community!</p>
                  {collegeData?.collegeTour && (
                    <div className="flex justify-center my-4">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/og954Ofc5xA?si=nHykw6VfpbruusrL" title="Campus Tour Video Player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ShCollegeChalakudy;
