import React from 'react';

const PortFolio = () => {
	return (
		<div className='font-sans'>
			{/* Hero Section */}
			<section className='bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white py-20'>
				<div className='container mx-auto text-center transform transition-all duration-1000 ease-in-out'>
					<h1 className='text-5xl font-bold mb-4'>Hi, I'm Uvais K.T.</h1>
					<p className='text-lg mb-6'>Full Stack Developer | Entrepreneur | Innovator</p>
					<a href='#about' className='bg-white text-indigo-600 py-3 px-6 rounded-full font-bold hover:bg-gray-100 transition-transform transform hover:scale-110'>
						Learn More
					</a>
				</div>
			</section>

			{/* About Section */}
			<section id='about' className='py-20 bg-white '>
        <h1>vsgvdh</h1>
				<div className='container mx-auto transition-opacity duration-1000 ease-in-out opacity-40 animate-fadeIn'>
					<h2 className='text-3xl font-bold mb-6'>About Me</h2>
					<p className='text-lg mb-4' style={{ color: 'red' }}>
						I'm a full stack developer with a passion for building scalable and efficient applications. I specialize in React, Node.js, and databases. Currently the Director of Centre for Innovation and Entrepreneurship (CIE), I'm dedicated to helping businesses and individuals innovate and grow.
					</p>
				</div>
			</section>

			{/* Skills Section */}
			<section className='py-20 bg-gray-100 text-gray-800'>
				<div className='container mx-auto'>
					<h2 className='text-3xl font-bold mb-6 text-center'>Skills</h2>
					<div className='grid grid-cols-2 md:grid-cols-4 gap-8'>
						{['React.js', 'Node.js', 'Database Management', 'DevOps'].map((skill, index) => (
							<div key={index} className='text-center p-6 bg-white rounded-lg shadow-md transform transition-transform duration-500 hover:scale-105'>
								<span className='text-5xl mb-4'>💻</span>
								<p className='mt-4 font-semibold'>{skill}</p>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Projects Section */}
			<section className='py-20 bg-white text-gray-800'>
				<div className='container mx-auto transition-opacity duration-1000 ease-in-out opacity-0 animate-fadeIn'>
					<h2 className='text-3xl font-bold mb-6'>Projects</h2>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
						{[
							{ title: 'Project 1', description: 'Description of the project, the technologies used, and the challenges solved.' },
							{ title: 'Project 2', description: 'Description of the project, the technologies used, and the challenges solved.' },
						].map((project, index) => (
							<div key={index} className='bg-gray-200 p-6 rounded-lg shadow-md transform transition-transform duration-500 hover:scale-105'>
								<h3 className='text-2xl font-bold mb-4'>{project.title}</h3>
								<p>{project.description}</p>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Contact Section */}
			<section className='py-20 bg-gradient-to-r from-blue-600 via-indigo-700 to-purple-700 text-white'>
				<div className='container mx-auto transition-opacity duration-1000 ease-in-out opacity-0 animate-fadeIn'>
					<h2 className='text-3xl font-bold mb-6 text-center'>Contact Me</h2>
					<p className='text-lg mb-6 text-center'>Feel free to reach out for collaborations, consulting, or just a chat about technology.</p>
					<form className='grid grid-cols-1 gap-6 max-w-lg mx-auto'>
						<input type='text' placeholder='Your Name' className='p-4 rounded bg-gray-700 text-white focus:transform focus:scale-105 transition-transform duration-500' />
						<input type='email' placeholder='Your Email' className='p-4 rounded bg-gray-700 text-white focus:transform focus:scale-105 transition-transform duration-500' />
						<textarea placeholder='Your Message' className='p-4 rounded bg-gray-700 text-white focus:transform focus:scale-105 transition-transform duration-500' rows='4'></textarea>
						<button type='submit' className='bg-blue-500 text-white py-3 rounded hover:bg-blue-600 transition-transform transform hover:scale-110'>
							Send Message
						</button>
					</form>
				</div>
			</section>
		</div>
	);
};

export default PortFolio;
