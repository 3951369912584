import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileEditNew } from '../../NecttosComp/APICaller/queryHooks';
import { ProgressBar, calculateProfilepPercentage } from '../../NecttosComp/ProgressBar/ProgressBar';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Loader from '../../NecttosComp/Loader/Loader';
import Loader1 from '../../NecttosComp/Loaders/Loader';
import { ColorAbbreviationListOfProfile, ProfileDataDisplay, getProfileUpdateColors, postProfileSubmission } from '../../FunctionalExport/Formate';
import { createRandomId } from '../../FunctionalExport/Utils';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Popconfirm } from 'antd';
import CSVEditor from './csvEditor';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}

function Traveling({ onClose, studentId, onNext, modal }: Props) {
	const titleKey = 'travelDetails';
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [csv, setCSv] = useState(false);
	const [isTick, setIsTick] = useState<any>(null);
	const [temp, setTemp] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>();
	const { user, collegeId } = useContext(FirebaseContext);
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });
	// const { data: profileData, refetch } = useProfileEdit(titleKey);
	const [values, setValues] = useState<any[]>(profileData);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	const handleUpdate = async (addNew: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/updateByKeyData', {
				_id: addNew._id,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
				newValue: addNew,
			});
			console.log(res);
			refetch();
			setAddNew('');
		} catch (error) {
			console.error(error);
		}
	};
	const handleDelete = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.delete('college/profile/deleteByKeyData', {
				data: { _id: data._id, userId: user?.uid, collegeId, keyData: titleKey },
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleUploadCSV = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/addByKeyData', {
				data,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};

	console.log(values);

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);

	return (
		<>
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div className='w-full '>
								<Table ref={componentRef} id={'profilePDF'} width='100%' innerWidth={['10%', '6%', '14%', '14%', '9%', '10%', '10%', '10%', '10%']}>
									<Thead>
										<Tr>
											<Th position={0} children='Vehicle' />
											<Th position={1} children='Fuel' />
											<Th position={2} children='Travel From' />
											<Th position={3} children='Travel To' />
											<Th position={4} children='Distance' />
											<Th position={5} children='Co-Traveler 1' />
											<Th position={6} children='Co-Traveler 2' />
											<Th position={7} children='Co-Traveler 3' />
											<Th position={8} children='Action' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr>
												<Td position={0} index={index}>
													<p>{x?.typeOfVehicle}</p>
												</Td>
												<Td position={1} index={index}>
													<p>{x?.typeOfEnergy}</p>
												</Td>
												<Td position={2} index={index}>
													<p>{x?.travelStart}</p>
												</Td>
												<Td position={3} index={index}>
													<p>{x?.travelTo}</p>
												</Td>
												<Td position={4} index={index}>
													<p>{x?.km}</p>
												</Td>
												<Td position={5} index={index}>
													<p>{x?.coTraveler1}</p>
												</Td>
												<Td position={6} index={index}>
													<p>{x?.coTraveler2}</p>
												</Td>
												<Td position={7} index={index}>
													<p>{x?.coTraveler3}</p>
												</Td>
												<Td position={8} index={index}>
													<div className='flex'>
														<Button
															type='update'
															onClick={() => {
																setMode('edit');
																setAddNew(x);
																setTemp(x);
															}}>
															Edit
														</Button>
														<Popconfirm placement='topRight' title='Are you sure you want to delete?' okText='Yes' cancelText='No' onConfirm={() => handleDelete(x)}>
															<Button type='delete'>Delete</Button>
														</Popconfirm>
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>

							<Button
								onClick={async () => {
									setMode(!mode);
									setTemp({});
								}}
								type='update'>
								Add how to come to college
							</Button>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</div>

			{mode && (
				<Main width='95vw' height='95vh'>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='grid grid-flow-col p-2 gap-2 justify-items-center'>
							{['delete', 'edit'].includes(mode) && (
								<div className=' p-5 rounded bg-white h-full'>
									<ProfileDataDisplay temp={temp} />

									<div className='flex float-right items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-[35vw] z-50 overflow-auto  max-h-[85vh] h-full bg-white rounded'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Vehicle' options={dropDowns[7].vehicle} returnKey='typeOfVehicle' setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[2]'>
										<Input width='98%' fieldName='Fuel' returnKey='typeOfEnergy' options={dropDowns[7].engine} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName='Travel Start' returnKey='travelStart' setState={setAddNew} state={addNew} type='text' />
									</div>
									<Input width='98%' fieldName='Travel To' returnKey='travelTo' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Distance' returnKey='km' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Co-Traveler 1' returnKey='coTraveler1' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Co-Traveler 2' returnKey='coTraveler2' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Co-Traveler 3' returnKey='coTraveler3' setState={setAddNew} state={addNew} type='text' />
									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { coTraveler3: '', coTraveler2: '', coTraveler1: '', km: '', travelTo: '', travelStart: '', typeOfEnergy: '', typeOfVehicle: '' })?.percentage)} />
									<Button
										width='100%'
										onClick={async () => {
											handleUpdate(addNew);
											closeAnimation();
											setTimeout(() => setMode(false), 250);
										}}
										type='save'
										children='Save'
									/>
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}

			{isLoading && <Loader />}
			{csv && (
				<CSVEditor
					titleKey={titleKey}
					keysNeeded={['coTraveler3', 'coTraveler2', 'coTraveler1', 'km', 'travelTo', 'travelStart', 'typeOfEnergy', 'typeOfVehicle']}
					setCsv={setCSv}
					returnData={async (data) => {
						if (data) {
							console.log(data);
							let val = [...values];
							setIsTick(false);
							await handleUploadCSV(data);
							setIsTick(true);
							setTimeout(() => {
								setIsTick(null);
								setCSv(false);
							}, 2000);
						}
					}}
				/>
			)}
			{isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}
			{isLoading && <Loader />}
		</>
	);
}

export default Traveling;
