import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../components/LoadingAnimation';

function C3_1_1({ val, onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		naacFetchData({
			data: { collegeId, academicYear },
			url: '/college/NAAC/getNAAC_C3_1_1',
			setLoading,
			setValues,
		});
	}, []);
	return (
		<>
			<div className='  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto'>
				{loading ? (
					<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
						<LoadingAnimation />
					</div>
				) : null}

				<table className='table'>
					<thead>
						<tr className=' bg-white'>
							<th colSpan={8} style={{ background: 'rgb(241 245 249)', border: 'none' }}>
								<h1 className='text-xl flex justify-between sticky'>
									3.1.1 Grants received from Government and non-governmental agencies for research projects / endowments in the institution during the year {'('}INR in Lakhs{')'}
									<div className='flex'>
										<AcademicYears setAcademicYear={setAcademicYear} />
										<button
											className='bg-green-500 text-sm'
											onClick={() =>
												naacExcelGenerator({
													data: { collegeId, academicYear },
													url: '/college/NAAC/getNAAC_C3_1_1',
													setLoading,
												})
											}>
											Dowload
										</button>
										<button className='closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]' onClick={onClose}>
											X
										</button>
									</div>
								</h1>
								<h1 className='text-xl sticky'>3.1.3 Number of departments having Research projects funded by government and non government agencies during the year</h1>
							</th>
						</tr>
						<tr>
							<th className='w-1/4'>Name of the Project/ Endowments, Chairs</th>
							<th className='w-1/4'>Name of the Principal Investigator/Co-investivator</th>
							<th className='w-1/4'>Department of Principal Investigator</th>
							<th className='w-1/4'>Year of Award</th>
							<th className='w-1/4'>Amount Sanctioned</th>
							<th className='w-1/4'>Duration of the project</th>
							<th className='w-1/4'>Name of the Funding Agency</th>
							<th className='w-1/4'>Type (Government/non-Government)</th>
						</tr>
					</thead>
					<tbody>
						{values?.map((x) => (
							<tr>
								<td>{x?.projectName}</td>
								<td>{x?.name}</td>
								<td>{x?.department}</td>
								<td>{x?.year}</td>
								<td>{x?.amount}</td>
								<td>{x?.duration}</td>
								<td>{x?.fundingAgency}</td>
								<td>{x?.type}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default C3_1_1;
