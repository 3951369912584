import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { DFCRInduvidual } from './DFCRInduvidual';
import { PulseLoader } from 'react-spinners';

import toast from 'react-hot-toast';

const getAccounts = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getDailyFeeColloctionRegister', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getIndividualAccounts = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getSubsidiaryIndividualRegister', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const DFCR = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(false);
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
		startDate: '',
		endDate: '',
	});

	useEffect(() => {
		if (!state.startDate) {
			setState((prv) => ({ ...prv, startDate: moment().startOf('month').format('YYYY-MM-DD') }));
		}
		if (!state.endDate) {
			setState((prv) => ({ ...prv, endDate: moment().format('YYYY-MM-DD') }));
		}
	}, []);

	const { data, refetch: issuedBookRefetch, isFetching } = useQuery(['DFCR0', state?.startDate, state?.endDate], getAccounts({ collegeId, startDate: state.startDate, endDate: state.endDate, setLoading }));
	const { data: individualData, refetch: refetchData } = useQuery(['DFCRoIndividual', state?.selectedDate, state?.countData], getIndividualAccounts({ collegeId, date: state?.selectedDate }));
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [date, setDate] = useState({ date: '', type: 'Government', subType: '' });
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(true);

	function handleScroll() {
		const container = document.getElementById('scroll-container');
		if (container.scrollLeft === 0) {
			setShowLeftArrow(false);
		} else {
			setShowLeftArrow(true);
		}
		if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
			setShowRightArrow(false);
		} else {
			setShowRightArrow(true);
		}
	}

	useEffect(() => {
		const updatePageNumbers = () => {
			const totalPages = Math.ceil(componentRef.current.scrollHeight / componentRef.current.clientHeight);
			document.querySelectorAll('.footer').forEach((footer, index) => {
				footer.innerHTML = `Necttos (OPC) PVT LTD| Page ${index + 1} of ${totalPages}`;
			});
		};

		updatePageNumbers();
	}, []);

	const handleClick = async ({ collegeId, remittedDate, date }) => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/action/updateRemittedDate', {
				collegeId,
				remittedDate,
				date,
			});

			console.log({ resp });
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	return (
		<>
			<Main title={'DFCR'} height={'100vh'} width={'100vw'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Input type='date' state={state} returnKey='startDate' fieldName='Start Date' setState={setState} />
						<Input type='date' state={state} returnKey='endDate' fieldName='End Date' setState={setState} />
						<Button type='fetch' onClick={issuedBookRefetch}>
							Proceed
						</Button>
					</div>

					<div className='flex flex-wrap items-center'>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
						<Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button type='pdf' tableRef={componentRef.current} pdfId='accounts'>
							PDF
						</Button>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<section id='scroll-container'>
						<div ref={componentRef} className='printable'>
							<div className='header flex flex-row items-center justify-between p-4 border-b-2'>
								<div className='flex items-center'>
									<img className='w-16 h-16 mr-4 print:w-16 print:h-16' src={selectedCollege?.logo} alt='College Logo' />
									<div>
										<h1 className='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
										<p className='text-md font-semibold '>{selectedCollege?.accreditedGrade}</p>
										<p className='text-md font-semibold '>{selectedCollege?.address}</p>
									</div>
								</div>

								<div className='text-right'>
									<p className='text-xl font-medium '>DFCR</p>
									<p className='text-md font-medium '>
										{date.type} - {date.subType}
									</p>

									<p className='text-sm font-medium '>
										{moment(state.startDate).format('DD MMM YYYY')} to {moment(state.endDate).format('DD MMM YYYY')}
									</p>
									<p className='text-sm font-medium '>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
								</div>
							</div>
							{data?.subsidiaryRegisterData?.length > 0 ? (
								<div className='relative w-full h-full'>
									<div className='content overflow-x-auto scrollbar-hide ' onScroll={handleScroll}>
										<table style={{ width: '100%' }} bgcolor='white' className='border'>
											<thead className='bg-[#485056] text-white text-xs'>
												<tr style={{ height: '70px' }} className='border'>
													<th style={{ width: '40px', textAlign: 'center' }} className='border'>
														SN
													</th>
													<th style={{ width: '70px', textAlign: 'center' }} className='border'>
														Date
													</th>
													<th style={{ width: '30px', textAlign: 'center' }} className='border'>
														C
													</th>
													<th style={{ width: '80px', textAlign: 'center' }} className='border'>
														Rt.No.
													</th>

													{Array.from({ length: data?.totals?.countHeads || 25 }).map((_, i) => (
														<th key={i} style={{ width: '35px', fontSize: '12px', textAlign: 'center' }} className='border p-0'>
															<span
																style={{
																	transform: 'rotate(-90deg)',
																	display: 'block',
																	lineHeight: '12px',

																	textAlign: 'center',
																	margin: '1px',
																}}>
																{data?.keyValueData[`dailyFeeCollectionRegister${i + 1}`]?.slice(0, 4)?.toUpperCase().replace(/\s+/g, '')}
															</span>
														</th>
													))}
													<th style={{ width: '70px', textAlign: 'center' }}>TOTAL</th>
													<th style={{ width: '90px', textAlign: 'center' }}>Remitted Date</th>
												</tr>
											</thead>
											<tbody className='font-semibold'>
												{data?.subsidiaryRegisterData?.map((item, i) => (
													<tr key={i} onClick={() => setState({ ...state, openModal: true, selectedDate: item.date })}>
														<td className='border' style={{ width: '40px', textAlign: 'center', fontSize: '13px' }}>
															{i + 1}
														</td>
														<td className='border' style={{ width: '70px', textAlign: 'left', fontSize: '12px' }}>
															{item?.date}
														</td>
														<td className='border' style={{ width: '30px', textAlign: 'center', fontSize: '12px' }}>
															{item?.countData}
														</td>
														<td
															className='border'
															style={{
																width: '80px',
																textAlign: 'center',
																fontSize: '15px',
																padding: '0px',
															}}>
															{item?.recieptNumber}
														</td>

														{Array.from({ length: data?.totals?.countHeads || 25 }).map((_, j) => (
															<td
																className='border p-0'
																key={j}
																style={{
																	width: '35px',
																	textAlign: 'center',
																	fontSize: '15px',
																	padding: '0px',
																	lineHeight: '-4px',
																}}>
																{item[`dailyFeeCollectionRegister${j + 1}`] || ''}
															</td>
														))}
														<td className='border text-[10px]' style={{ width: '70px', textAlign: 'right', fontSize: '14px' }}>
															{item?.totalAmount || ''}
														</td>
														{item?.remittedDate ? (
															<td
																className='border text-nowrap text-[10px]'
																style={{ width: '80px', textAlign: 'right', fontSize: '14px' }}
																onClick={(e) => {
																	e.stopPropagation();
																	setEditModal(true);
																	setState({ ...state, selectedDate: item.remittedDate });
																}}>
																{item?.remittedDate}
															</td>
														) : (
															<td
																className='border text-nowrap text-[10px]'
																style={{ width: '90px', textAlign: 'right', fontSize: '14px' }}
																onClick={(e) => {
																	e.stopPropagation();
																	setEditModal(true);
																	setState({ ...state, selectedDate: item.date });
																}}>
																{item?.date}
															</td>
														)}
													</tr>
												))}
											</tbody>
											<tfoot className='bg-[#6c757d] text-white font-extrabold'>
												<tr>
													<td className='border' style={{ width: '220px', textAlign: 'right' }} colSpan={4}>
														Total
													</td>

													{Array.from({ length: data?.totals?.countHeads || 25 }).map((_, i) => (
														<td className='border' key={i} style={{ width: '35px', textAlign: 'right', fontSize: '14px' }}>
															{data?.totals[`dailyFeeCollectionRegisterMainTotal${i + 1}`] || ''}
														</td>
													))}
													<td className='border text-sm' style={{ width: '80px', textAlign: 'right' }}>
														{data?.totals?.grandTotal || ''}
													</td>
													<td className='border' style={{ width: '90px', textAlign: 'right' }}></td>
												</tr>
											</tfoot>
										</table>
									</div>

									<div className=' mt-3 text-right text-black text-xs'>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</div>
									<div className='footer mt-3 text-right'></div>
								</div>
							) : (
								<div className='flex justify-center items-center pt-20'>{isFetching ? <PulseLoader color='#002147' size={15} /> : <img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/nodatafound.png?alt=media&token=75763b23-2b98-4570-b308-963bc28c070b' alt='' />}</div>
							)}
						</div>
					</section>
					{editModal && <EditModal editModal={editModal} setEditModal={setEditModal} date={state?.selectedDate} data={individualData} setStateData={setState} stateData={state} refetch={refetchData} collegeId={collegeId} />}
					{state.openModal && <DFCRInduvidual date={state?.selectedDate} data={individualData} setStateData={setState} stateData={state} refetch={refetchData} onClose={() => setState({ ...state, openModal: false })} />}
				</Body>
			</Main>
		</>
	);
};

const EditModal = ({ setEditModal, editModal, data, date, collegeId, setStateData, stateData, refetch }) => {
	console.log({ setEditModal, editModal, stateData, date, collegeId });
	const [state, setState] = useState({});
	const [billNumbers, setBillNumbers] = useState([]);
	const [faculties, setFaculties] = useState([]);
	const [billIds, setBillIds] = useState([]);
	const [total, setTotal] = useState();
	const [remiitedDate, setRemittedDate] = useState(null);
	const { data: individualData, isFetching, refetch: refetchData } = useQuery(['DFCRoIndividual', stateData?.selectedDate, stateData?.countData, editModal], getIndividualAccounts({ collegeId, date }));
	console.log({ individualData });

	useEffect(() => {
		if (individualData) {
			let tempBills = [];
			let tempIds = [];
			individualData.subsidiaryRegisterData.forEach((item, index) => {
				tempBills.push(item.billNo);
				tempIds.push(item.feeId);
			});
			setTotal(individualData.subsidiaryRegisterData.totalAmount);
			setBillNumbers(tempBills);
			setBillIds(tempIds);
		}
	}, [individualData]);

	const handlereset = () => {
		setBillIds([]);
		setBillNumbers([]);
		setEditModal(false);
	};

	const getFaculties = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/action/getFaculties?collegeId=${collegeId}`;
			const res = await instance.get(url);
			setFaculties(res?.data.list);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getFaculties();
	}, []);

	console.log({ total });

	const handleClick = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/action/updateRemittedDate', {
				collegeId,
				remittedDate: state.remittedDate,
				remittedBy: state.remittedBy,
				chalanNo: state.chalanNo,
				date,
				billIds,
				billNumbers,
			});
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				console.log({ resp });
				toast.success('successfully submitted');
				setEditModal(false);
				return resp?.data;
			}
		} catch (error) {
			toast.error('error submitting');
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	return (
		<Main width='65vw' height='auto'>
			<div className='flex justify-end'>
				<Button
					type='close'
					onClick={() => {
						handlereset();
					}}>
					close
				</Button>
			</div>
			<h5 className='text-center'>Update Remitted Date, Remitted by & chalan No</h5>
			<p className='text-lg font-bold text-center'>Receipt Numbers: [{billNumbers.length > 1 ? billNumbers[0] + '-' + billNumbers[billNumbers.length - 1] : billNumbers[0]}]</p>
			<section className='flex gap-1 justify-center items-center max-h-[90vh] overflow-y-scroll'>
				<div className='flex flex-col items-center justify-center'>
					<div className='w-fulll h-full'>
						<Table width='500px' border='1'>
							<Thead>
								<Tr>
									<Th width='210px' textAlign='left'>
										Title
									</Th>
									<Th width='105px' textAlign='center'>
										Amount
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{individualData?.subTotalsDetails?.map((item, i) => (
									<Tr key={i} border='1'>
										<Td width='210px' textAlign='left'>
											{item?.name}
										</Td>
										<Td width='105px' textAlign='center'>
											{item?.amount}
										</Td>
									</Tr>
								))}
							</Tbody>
							<Tfoot>
								<TfootTr>
									<TfootTd colSpan={1} textAlign='center' width='210px'>
										Total:
									</TfootTd>
									<TfootTd colSpan={1} textAlign='center' width='105px'>
										{data?.totals?.grandTotal}
									</TfootTd>
								</TfootTr>
							</Tfoot>
						</Table>
					</div>
				</div>
				<div className='flex gap-1 flex-col p-4 justify-center items-center'>
					<Input width='250px' fieldName='Remitted Date ' returnKey='remittedDate' state={state} setState={setState} type='date' />
					<Input fieldName='Select Remitted By' state={state} setState={setState} didntShowKey returnKey='remittedBy' optionDisplay='name' optionKey='_id' options={faculties} type='drop' width='250px' />
					<Input width='250px' fieldName='Chalan No ' returnKey='chalanNo' state={state} setState={setState} type='text' />
					<div className='flex justify-center'>
						{/* <Button width='200px' type='submit' onClick={handleClick}>
						submit
					</Button> */}
					</div>
					<div className='flex gap-1 justify-center'></div>
				</div>
			</section>
			<div className='flex justify-center my-6'>
				<Button width='350px' type='submit' onClick={handleClick}>
					submit
				</Button>
			</div>
		</Main>
	);
};
