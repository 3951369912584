import home from '../static/images/home.png';
import group from '../static/images/footerIcons/group.png';
import roles from '../static/images/footerIcons/role.png';
import timetable from '../static/images/footerIcons/timetable.png';
import addUser from '../static/images/footerIcons/addUser.png';
import feeManagement from '../static/images/footerIcons/fee_management.png';
import moment from 'moment';

export const FOOTER_ICONS_MAIN = [
	{
		icon: home,
		name: 'user',
		to: '/',
		allowedUsers: ['office', 'library', 'teacher', 'classTeacher', 'hod', 'principal'],
		label: 'Home',
	},
	{
		icon: home,
		name: 'user',
		to: '/supperVission',
		allowedUsers: ['office', 'library', 'teacher', 'classTeacher', 'hod', 'principal'],
		label: 'Super Vision',
	},
	{
		icon: group,
		name: 'user',
		to: '/master',
		allowedUsers: ['office', 'library', 'classTeacher', 'hod', 'principal'],
		label: 'Master Table',
	},
	{
		icon: addUser,
		name: 'addUser',
		to: '/addUser',
		allowedUsers: ['office', 'classTeacher', 'hod', 'principal'],
		label: 'Add User',
	},
	{
		icon: feeManagement,
		name: 'Fees Mangement',
		to: '/feeManagement',
		allowedUsers: ['office', 'principal'],
		label: 'Fees Mangement',
	},
	{
		icon: timetable,
		name: 'timetable',
		to: '/timetable',
		allowedUsers: ['office', 'classTeacher', 'hod', 'principal'],
		label: 'Timetable',
	},
];

export const ROLES = ['student', 'teacher', 'staff', 'scholars'];

export const ROLES_manage = ['director', 'manager', 'principal', 'iqac', 'naac', 'nodal', 'office', 'academic', 'hod', 'tutor', 'superintend', 'headAccountant', 'cashier', 'officeAttendant', 'library', 'libraryAssistant', 'clerk', 'typist', 'technicalAssistant', 'computerAssistant', 'mechanic', 'labAssistant', 'herbariumKeeper', 'watchman', 'gardner', 'sweepers', 'storeKeeper'];

export const ADMIN_ROLES = ['principal', 'iqac', 'naac', 'academic', 'teacher', 'office', 'staff', 'library', 'audit', 'manager', 'director', 'hod', 'tutor'];

export const MASTER_TABLE_KEYS = [
	{ title: 'Select', key: 'select', show: true, exportDisabled: true },
	{ title: 'Full Name', dataIndex: 'name', key: 'name', show: true },
	{ title: 'Action', key: 'action', show: true, exportDisabled: true },
	{
		title: 'Mobile Number',
		dataIndex: 'phoneNumber',
		key: 'number',
		show: true,
	},
	{ title: 'Class', dataIndex: 'class', key: 'class', show: true },
	{ title: 'Department', dataIndex: 'department', key: 'department', show: false },
	{ title: 'Designation', dataIndex: 'designation', key: 'designation', show: false },
	{ title: 'Designation Status', dataIndex: 'designationStatus', key: 'designationStatus', show: false },

	{ title: 'Admn No', dataIndex: 'admisionNo', key: 'admisionNo', show: true },
	{ title: 'Date of Birth', dataIndex: 'dob', key: 'dob', show: false },
	{
		title: 'Date of Joining',
		dataIndex: 'dateOfJoin',
		render: (a, data, i) => <span>{data?.dateOfJoin ? moment(new Date(data?.dateOfJoin)).format('DD MMM YYYY') : ''}</span>,
		show: false,
	},
	{
		title: 'Date of Admn',
		dataIndex: 'dateOfAdmission',
		render: (a, data, i) => <span>{data?.dateOfAdmission.slice(0, 10)}</span>,
		show: false,
	},
	{
		title: 'Hallticket No',
		dataIndex: 'hallTicketNo',
		key: 'hallTicketNo',
		show: false,
	},
	{ title: 'CAP ID', dataIndex: 'capId', key: 'capId', show: false },
	{ title: 'Gender', dataIndex: 'gender', key: 'gender', show: false },
	// { title: "Roll No", dataIndex: "rollNo", key: "rollNo", show: false },
	{ title: 'Email Id', dataIndex: 'email', key: 'email', show: false },
	{
		title: 'Blood Group',
		dataIndex: 'bloodGroup',
		key: 'bloodGroup',
		show: false,
	},
	{
		title: 'Aadhaar No',
		dataIndex: 'aadhaarNumber',
		key: 'aadhaarNumber',
		show: false,
	},
	{ title: 'Category', dataIndex: 'category', key: 'category', show: false },
	{ title: 'Caste', dataIndex: 'caste', key: 'caste', show: false },
	{ title: 'Religion', dataIndex: 'religion', key: 'religion', show: false },
	{
		title: 'House Name',
		dataIndex: 'houseName',
		key: 'houseName',
		show: false,
	},
	{ title: 'Place', dataIndex: 'place', key: 'place', show: false },
	{
		title: 'Post Office',
		dataIndex: 'postOffice',
		key: 'postOffice',
		show: false,
	},
	{ title: 'District', dataIndex: 'district', key: 'district', show: false },
	{ title: 'State', dataIndex: 'state', key: 'state', show: false },
	{ title: 'Pin Code', dataIndex: 'pinCode', key: 'pinCode', show: false },
	{ title: 'Status', dataIndex: 'status', key: 'status', show: true },
];

export const NO_PREVIEW = 'https://upload.wikimedia.org/wikipedia/commons/d/dc/No_Preview_image_2.png';
