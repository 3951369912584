import React from 'react';
import { createRandomId } from './Utils';
import { EditDetails } from '../NecttosComp/APICaller/ApiServices';
interface Qualification {
	yearOfPass: any; // Consider using specific types instead of 'any'
	course: any;
	specialisation: any;
	type: any;
	_id?: string;
	new: boolean;
	deleted: boolean;
	edited?: boolean;
	ukey?: string;
	akey?: string;
	// Add other qualification properties here as needed
}

interface ProfileItem {
	[key: string]: any; // Dynamic key-value pairs
	ukey?: string; // Optional properties for ukey and akey
	akey?: string;
}

type ItemType = {
	edited: any;
	deleted?: boolean;
	new?: boolean;
};

const excludeKeys = ['_id', 'edited', 'deleted', 'new'];

export const processQualifications = (profileData: any, keyToSort: string, keys: string[]): Qualification[] => {
	try {
		profileData?.[keyToSort]?.forEach((item: ProfileItem) => {
			item.ukey = [item[keys[0]], item[keys[1]], item[keys[2]]].filter(Boolean).join('|');
			item.akey = Object.entries(item)
				.filter(([key, value]) => !excludeKeys.includes(key) && Boolean(value))
				.map(([key, value]) => value)
				.join('|');
		});

		profileData?.pendingData?.[keyToSort]?.forEach((item: ProfileItem) => {
			item.ukey = [item[keys[0]], item[keys[1]], item[keys[2]]].filter(Boolean).join('|');
			item.akey = Object.entries(item)
				.filter(([key, value]) => !excludeKeys.includes(key) && Boolean(value))
				.map(([key, value]) => value)
				.join('|');
		});

		let allItems: ProfileItem[] = [];

		profileData?.pendingData?.[keyToSort]?.forEach((item: ProfileItem) => {
			let checkItems = profileData?.[keyToSort]?.find((checkKey: ProfileItem) => checkKey.ukey === item.ukey);
			if (!item._id) {
				if (checkItems && checkItems._id) {
					item._id = checkItems._id;
				} else {
					item._id = createRandomId('PQ');
				}
			}
			allItems.push(item);
		});

		profileData?.[keyToSort]?.forEach((item: ProfileItem) => {
			let checkItems = profileData?.pendingData?.[keyToSort]?.find((checkKey: ProfileItem) => checkKey?.ukey === item?.ukey);

			if (item._id) {
				if (checkItems && checkItems._id) {
					item._id = checkItems._id;
				} else {
					item._id = createRandomId('PQ');
				}
			}
			if (item?.ukey !== checkItems?.ukey) {
				allItems.push(item);
			}
		});

		let returnObj: Qualification[] = allItems?.map((item) => {
			let originalData = profileData?.[keyToSort]?.some((checkKey: ProfileItem) => checkKey?.akey === item?.akey);
			let editData = profileData?.pendingData?.[keyToSort]?.some((checkKey: ProfileItem) => checkKey?.akey === item?.akey);

			return {
				...item,
				yearOfPass: item.yearOfPass,
				course: item.course,
				specialisation: item.specialisation,
				type: item.type,
				edited: editData,
				deleted: originalData && !editData,
				new: !originalData && editData,
			};
		});

		return returnObj;
	} catch (error) {
		console.error('Error processing qualifications:', error);
		return [];
	}
};

type ColorAbbreviation = {
	status: string;
	abbreviation: string;
	color: string;
};

const colorAbbreviations: ColorAbbreviation[] = [
	{
		status: 'Deleted',
		abbreviation: 'Removed item',
		color: '#D9534F',
	},
	{
		status: 'Edited',
		abbreviation: 'Modified item',
		color: '#F0AD4E',
	},
	{
		status: 'New',
		abbreviation: 'Newly added',
		color: '#5CB85C',
	},
	{
		status: 'Unchanged',
		abbreviation: 'No changes',
		color: '#5BC0DE',
	},
];
export const getProfileUpdateColors = (x: ItemType): string | undefined => {
	if (x.deleted) {
		return 'text-red-600'; // Closest to #D9534F
	} else if (x.edited) {
		return 'text-yellow-500'; // Closest to #F0AD4E
	} else if (x.new) {
		return 'text-green-500'; // Closest to #5CB85C
	} else {
		return 'text-blue-400'; // Closest to #5BC0DE
	}
};
export const ColorAbbreviationListOfProfile: React.FC = () => {
	return (
		<div className='flex justify-start items-center space-x-4 mb-4'>
			{colorAbbreviations.map(({ status, abbreviation, color }) => (
				<div key={status} className='flex items-center space-x-1'>
					<div className={`w-3 h-3 rounded-full`} style={{ backgroundColor: color }}></div>
					<div>
						<div className='font-semibold text-xs'>{status}</div>
					</div>
				</div>
			))}
		</div>
	);
};

type Props = {
	temp: { [key: string]: any };
};
type PropsKey = {
	temp: { [key: string]: any };
	placeHoldValues: { [key: string]: any }; // Replace 'any' with a more specific type if possible
	title: string;
	needVerification?: boolean;
};

export const ProfileDataDisplay: React.FC<Props> = ({ temp }) => {
	return (
		<>
			<h5 className='text-blue-900 font-bold'>This is your current data!</h5>
			{Object.entries(temp || {}).map(([key, value]) => {
				if (['_id', 'matchKey', 'deleted', 'new', 'edited'].includes(key)) {
					return null;
				}
				let formattedKey = key === 'yearOfPass' ? 'Year of Pass' : key.replace(/([A-Z])/g, ' $1').trim();
				formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

				const displayValue = typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value);

				return (
					<div key={key} className='flex flex-col justify-start items-start my-1 p-[1px] bg-gray-100 rounded-md shadow-sm min-w-[250px] max-w-[500px]'>
						<label className='text-xs font-medium capitalize mb-1'>{formattedKey}:</label>
						<p className='text-sm font-semibold text-gray-700 pl-4'>{displayValue || '—'}</p>
					</div>
				);
			})}
		</>
	);
};

export const ProfileDataDisplayKeyVlaue: React.FC<PropsKey> = ({ temp, placeHoldValues, title, needVerification }) => {
	return (
		<div>
			<h5 className='text-blue-900 font-bold'>{title}</h5>
			<div id='scrollBarEnable' className='px-3 py-2 w-[28vw] overflow-auto  max-h-[68vh]'>
				{Object.entries(placeHoldValues).map((key: any) => {
					if (!temp?.[key[0]]) {
						return null;
					}

					return (
						<div>
							<div key={key} className='flex flex-col justify-start items-start my-1 p-[1px] bg-gray-100 rounded-md shadow-sm min-w-[250px] max-w-[500px]'>
								<label className='text-xs font-medium capitalize mb-[1px]'>{key[1]}:</label>
								<p className='text-sm font-semibold text-gray-700 pl-4'>{temp?.[key[0]] || '—'}</p>
							</div>
						</div>
					);
				})}
			</div>
			{needVerification && <h5 className='text-[#a33333] text-[12px] font-light'>Please wait the you updated data verification process completed!</h5>}
		</div>
	);
};

//Profile ************ ************** **************** ****************** *****************

// Define the shape of your data with an interface
export interface DataItem {
	_id?: string;
	deleted?: boolean;
	new?: boolean;
	edited?: boolean;
	matchKey?: boolean;
	akey?: boolean;
	ukey?: boolean;
	// Include other properties as per your data structure
}

// Define the type for the function parameters
interface PostProfileSubmissionParams {
	keyData: string;
	values: DataItem[];
	temp: DataItem;
	addNew: DataItem;
	setIsLoading: (isLoading: boolean) => void;
	setTemp: (temp: DataItem | {}) => void;
	setMode: (mode: boolean) => void;
	setAddNew: (addNew: DataItem | {}) => void;
	refetch: () => void;
	titleKey: string;
}

export const postProfileSubmission = async ({ keyData, values, temp, addNew, titleKey, setIsLoading, setTemp, setMode, setAddNew, refetch }: PostProfileSubmissionParams): Promise<void> => {
	setIsLoading(true);

	let currentData;
	switch (keyData) {
		case 'delete':
			currentData = values.filter((x) => x._id !== temp._id).filter((x) => !x.deleted);
			break;
		case 'edit':
			currentData = [...values.filter((x) => x._id !== temp._id && !x.deleted), { _id: temp._id, ...addNew }];
			break;
		case 'nill':
			currentData = [{ noData: true }];
			break;
		case 'yes':
			currentData = null;
			break;
		default:
			currentData = [{ ...addNew, _id: createRandomId('EQ') }, ...values];
	}

	currentData?.forEach((item: DataItem) => {
		delete item.deleted;
		delete item.new;
		delete item.matchKey;
		delete item.akey;
		delete item.edited;
		delete item.ukey;
	});

	setTemp({});
	setMode(false);
	setAddNew({});

	await EditDetails({ [titleKey]: currentData });
	refetch();
	setIsLoading(false);
};
