import React, { useContext, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import classNames from "./addBookModal.module.scss";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { List } from "antd";

export const getFieldDropDowns = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getUserNames", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list[0].data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

function SearchableUserInput({ primoryKey, secondaryKey, tertiaryKey, collection, stateData, setStateData, titleData }) {
  const { collegeId } = useContext(FirebaseContext);
  const [showList, setShowList] = React.useState(false);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const { data: nameOfAuthorDrop = [] } = useQuery(["dataDataData", stateData[primoryKey], collection, primoryKey, secondaryKey, tertiaryKey], getFieldDropDowns({ collegeId, collection, primoryKey, secondaryKey, tertiaryKey, search: stateData[primoryKey] }));
  const nameOfAuthor = useRef(null);
  useOutsideAlerter(nameOfAuthor);
  const ref = useRef(null);
  return (
    <div>
      <div style={{ margin: 10 }}>
        <div className={classNames.group}>
          <input type="text" onChange={(e) => setStateData({ ...stateData, [primoryKey]: e.target.value })} onFocus={() => setShowList(true)} value={stateData[primoryKey]} />
          <label>{titleData}</label>
        </div>
        <div ref={nameOfAuthor}>
          {showList && stateData[primoryKey] && nameOfAuthorDrop.length ? (
            <List
              size="small"
              style={{ margin: 0, padding: 0 }}
              dataSource={nameOfAuthorDrop}
              renderItem={(item) => (
                <List.Item>
                  <div style={{ cursor: "pointer", width: "100%", height: 20, color: "green" }} onClick={() => setStateData({ ...stateData, collegeId: item.collegeId[0], [primoryKey]: item[secondaryKey], _id: item._id, data: [] })}>
                    {item[primoryKey] + " " + item[secondaryKey]}
                  </div>
                </List.Item>
              )}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SearchableUserInput;
