import React, { useState } from 'react';
import { Body, Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import Input from '../../../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';

function MultiSelectDropdown({ fieldName, returnKey, state, setState, options }) {
	const handleSelectChange = (event) => {
		const value = Array.from(event.target.selectedOptions, (option) => option.value);
		setState({ ...state, [returnKey]: value });
	};

	return (
		<div className='w-full mb-4'>
			<label className='block text-sm font-medium text-gray-700'>{fieldName}</label>
			<select multiple={true} value={state[returnKey]} onChange={handleSelectChange} className='mt-1 block w-full p-2.5 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		</div>
	);
}

function AddModule({ onClose, values }) {
	const [val, setVal] = useState(values || {});
	const [topicContent, setTopicContent] = useState('');
	const [modules, setModules] = useState(values?.points || []);

	const extractTopics = () => {
		const topics = topicContent
			.split(/;|\./)
			.map((topic) => topic.trim())
			.filter((topic) => topic.length > 0);
		setModules(topics);
		setTopicContent('');
	};

	const handleTopicChange = (index, value) => {
		const updatedModules = [...modules];
		updatedModules[index] = value;
		setModules(updatedModules);
	};

	const addTopic = () => {
		setModules([...modules, '']);
	};

	const removeTopic = (index) => {
		const updatedModules = [...modules];
		updatedModules.splice(index, 1);
		setModules(updatedModules);
	};

	const onSubmit = async () => {
		const instance = await getAxiosTokenInstance();
		instance
			.post(
				'/college/subject/updateSubjectModule',
				{
					name: val.name,
					hours: val.hours,
					marks: val.marks,
					partB: val.partB,
					partA: val.partA,
					partC: val.partC,
					taxonomy: val.taxonomy,
					difficulty: val.difficulty,
					important: val.important,
					type: val.type,
					points: modules,
				},
				{
					params: {
						subjectId: values.subjectId,
						moduleId: values?.moduleId,
					},
				},
			)
			.then(() => {
				onClose();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	console.log({ val });
	return (
		<Main width='auto' height='auto' title='Module Management'>
			<div className='self-end'>
				<Button type='close' onClick={onClose}>
					Close
				</Button>
			</div>
			<Body>
				<div className='w-full max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg overflow-hidden'>
					<div className='w-full items-end'>
						<div className='self-end'></div>
					</div>
					<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
						<Input width='100%' fieldName='Module Name' returnKey='name' state={val} setState={setVal} type='text' />
						<Input width='100%' fieldName='Teaching Hours for this module' returnKey='hours' state={val} setState={setVal} type='number' />
						<Input width='100%' fieldName='Marks expected from this module' returnKey='marks' state={val} setState={setVal} type='number' />

						<Input width='100%' fieldName='Blueprint Part A Questions' returnKey='partA' state={val} setState={setVal} type='number' />
						<Input width='100%' fieldName='Blueprint Part B Questions' returnKey='partB' state={val} setState={setVal} type='number' />
						<Input width='100%' fieldName='Blueprint Part C Questions' returnKey='partC' state={val} setState={setVal} type='number' />

						<MultiSelectDropdown fieldName='Taxonomy' returnKey='taxonomy' state={val} setState={setVal} options={['Creating', 'Evaluating', 'Analysing', 'Applying', 'Understanding', 'Remembering']} />

						<MultiSelectDropdown fieldName='Difficulty' returnKey='difficulty' state={val} setState={setVal} options={['Challenging', 'Medium', 'Easy']} />

						<MultiSelectDropdown fieldName='Important' returnKey='important' state={val} setState={setVal} options={['Very Important', 'Important', 'Normal']} />

						<MultiSelectDropdown fieldName='Type' returnKey='type' state={val} setState={setVal} options={['Theory', 'Application']} />
					</div>

					<div className='my-4'>
						<label className='block text-sm font-medium text-gray-700'>Please paste the entire module content here, ensuring each topic is separated by a semicolon (;). Additionally, I'd like to include the functionality to copy and paste text within a text area. This method simplifies the process of splitting content.</label>
						<textarea className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500' rows='5' placeholder='Paste the entire topic content here' value={topicContent} onChange={(e) => setTopicContent(e.target.value)}></textarea>
						<button onClick={extractTopics} className='mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
							Extract
						</button>
					</div>

					<div className='mb-4'>
						<label className='block text-sm font-medium text-gray-700'>Topics</label>
						{modules.map((topic, index) => (
							<div key={index} className='flex items-center gap-2 mb-2'>
								<input type='text' className='border border-gray-300 rounded-md shadow-sm py-2 px-3 leading-tight focus:outline-none focus:ring-blue-500 focus:border-blue-500 flex-grow' value={topic} onChange={(e) => handleTopicChange(index, e.target.value)} />
								<button onClick={() => removeTopic(index)} className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
									Delete
								</button>
							</div>
						))}
						<button onClick={addTopic} className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
							Add Topic
						</button>
					</div>
				</div>
			</Body>
			<Button
				type='save'
				onClick={() => {
					onSubmit();
				}}>
				Submit
			</Button>
		</Main>
	);
}

export default AddModule;
