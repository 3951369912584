import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main } from '../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import { CSVReader } from 'react-papaparse';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import toast, { Toaster } from 'react-hot-toast';
import Input from '../../NecttosComp/Input/Input';
import moment from 'moment';

function HungryFreeCampus({ onClose }: { onClose: () => void }) {
	const tableRef = useRef<any>(null);
	const { collegeId } = useContext(FirebaseContext);
	const [abbrevation, setAbrevation] = useState(false);
	const [data, setData] = useState<any[]>([]);
	const [state, setState] = useState<any>({ startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
	const [search, setSearch] = useState<any>({ custom: false, search: false });
	const [detailedView, setDetailedView] = useState<null | any>();
	const [days, setDays] = useState<null | any>();

	let startDate, endDate;
	const fetchData = async () => {
		console.log('State ===> ', state);

		if (search.custom && state?.day && state?.month && state?.year && !state?.custom) {
			const month = new Date('1' + state?.month + '2000').getMonth() + 1;
			startDate = month >= 4 ? `${state?.day}-${month}-${state?.year?.slice(0, 4)}` : `${state?.day}-${month}-20${state?.year?.slice(-2)}`;
			endDate = startDate;
			setState({ ...state, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') });
		} else if (state?.month && state?.year && search.custom && !state?.custom) {
			const month = new Date('1' + state?.month + '2000').getMonth() + 1;
			startDate = month >= 4 ? `01-${month}-${state?.year?.slice(0, 4)}` : `01-${month}-20${state?.year?.slice(-2)}`;
			endDate = month >= 4 ? `31-${month}-${state?.year?.slice(0, 4)}` : `31-${month}-20${state?.year?.slice(-2)}`;
			setState({ ...state, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') });
		} else if (state?.year && search.custom && !state?.custom) {
			startDate = `01-04-${state?.year?.slice(0, 4)}`;
			endDate = `31-03-20${state?.year?.slice(-2)}`;
			setState({ ...state, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') });
		} else {
			startDate = state?.startDate ? moment(state?.startDate).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
			endDate = state?.endDate ? moment(state?.endDate).format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
			setState({ ...state, day: startDate.split('-')[0], month: new Date(startDate).toLocaleString('default', { month: 'long' }), year: startDate.split('-')[2] });
		}

		const instance = await getAxiosTokenInstance();
		instance
			.get('/college/hungry/collegeData', { params: { collegeId, startDate: startDate, endDate: endDate } })
			.then((res) => {
				setData(res.data.hungerData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchData();
	}, [search.search]);

	useEffect(() => {
		const getDaysInMonth = (year: number, month: number) => {
			if (!year || !month) return [];
			const monthIndex = parseInt(moment().month(month).format('M')); // Convert month name to month index
			const daysInMonth = new Date(year, monthIndex, 0).getDate();
			return Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
		};
		const month = new Date('1' + state?.month + '2000').getMonth() + 1;
		const year = month >= 4 ? state?.year?.slice(0, 4) : `20${state?.year?.slice(-2)}`;
		setDays(getDaysInMonth(year, month));
	}, [state?.year, state?.month]);

	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 5 }, (_, i) => `${currentYear - i} - ${currentYear - i - 1999}`);

	return (
		<>
			<Toaster />
			<Main height='90vh' width='90vw'>
				<Header>
					<div className='flex'>
						<Input width='150px' fieldName='Start Date' returnKey='startDate' setState={setState} state={state} type='date' />
						<Input width='150px' fieldName='End Date' returnKey='endDate' setState={setState} state={state} type='date' />
					</div>
					<div className='flex'>
						<Input width='140px' dropdownWidthStyle={{ width: '150px' }} fieldName='Year *' returnKey='year' state={state} setState={setState} options={years} type='drop' />
						<Input width='120px' dropdownWidthStyle={{ width: '150px' }} fieldName='Month *' returnKey='month' state={state} setState={setState} disabled={!state?.year} options={['July', 'August', 'September', 'October ', 'November', 'December ', 'January ', 'February ', 'March ', 'April ']} type='drop' />
						<Input width='110px' dropdownWidthStyle={{ width: '150px' }} fieldName='Day *' returnKey='day' state={state} setState={setState} options={days} disabled={!state?.month} type='drop' />
					</div>
					<div className='flex'>
						<Button type='search' onClick={() => setSearch({ search: !search.search, custom: true })}>
							Custom Search
						</Button>
						<Button type='search' onClick={() => setSearch({ search: !search.search, custom: false })}>
							Date Search
						</Button>
					</div>

					<div className='flex'>
						<Button type='print' tableRef={tableRef?.current}>
							Print
						</Button>
						<Button type='excel' tableRef={tableRef?.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Table width='100%' ref={tableRef} innerWidth={['5%', '8%', '16%', '9%', '9%', '9%', '9%', '9%', '9%', '8%', '10%']}>
						<Thead>
							<Tr>
								<Th position={0}>Sl No</Th>
								<Th position={1}>Adm.No.</Th>
								<Th position={2}>Name</Th>
								<Th position={3}>Class</Th>
								<Th position={4}>Booked Count</Th>
								<Th position={5}>Present Count</Th>
								<Th position={6}>Wastage Count</Th>
								<Th position={7}>Student Contribution</Th>
								<Th position={8}>Goverment Contribution</Th>
								<Th position={9}>Wastage Contribution</Th>
								<Th position={10}>Total</Th>
							</Tr>
						</Thead>
						<Tbody height='75vh'>
							{data?.map((x: any, i: number) => (
								<Tr
									onClick={() => {
										setDetailedView(x);
									}}>
									<Td position={0} index={i}>
										{i + 1}
									</Td>
									<Td position={1} index={i}>
										{x?.admisionNo}
									</Td>
									<Td position={2} index={i}>
										{x?.name}
									</Td>
									<Td position={3} index={i}>
										{x?.className}
									</Td>
									<Td position={4} index={i}>
										{x?.totalBooked}
									</Td>
									<Td position={5} index={i}>
										{x?.totalPresent}
									</Td>
									<Td position={6} index={i}>
										{x?.totalPresent - x?.totalBooked}
									</Td>
									<Td position={7} index={i}>
										{x?.totalPresent * 10}
									</Td>
									<Td position={8} index={i}>
										{x?.totalPresent * 25}
									</Td>
									<Td position={9} index={i}>
										{x?.totalPresent - x?.totalBooked * 35}
									</Td>
									<Td position={10} index={i}>
										{x?.totalPresent * 10 + x?.totalPresent * 25 + (x?.totalPresent - x?.totalBooked * 35)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Body>
				<Footer></Footer>
			</Main>

			{abbrevation && (
				<Main title='Learn Necttos'>
					<Header>
						<Button type='close' onClick={() => setAbrevation(false)}>
							Close
						</Button>
					</Header>
					<Body>
						<p>Replace All field with these field</p>
						<p>These Field are Required name,class,admissionNumber,distance,timeStamp,email,department,tutor,criteria1,criteria2,criteria3,criteria4,criteria5,criteria6,criteria7</p>
						<ul>
							<li>
								criteria1 :- Have you lost your father/mother? {'('}നിങ്ങളുടെ അച്ഛനോ അമ്മയോ മരിച്ചതാണോ?{')'}{' '}
							</li>
							<li>
								criteria2 :- Do you have only a single parent {'('}or none{')'}? {'('}നിങ്ങളെ വളര്‍ത്തുന്നത് ഒരു രക്ഷിതാവ് ഒറ്റയ്ക്കാണോ?{')'}{' '}
							</li>
							<li>
								criteria3 :- Is your father and/or mother bedridden due to any serious illness and unable to work? {'('}നിങ്ങളുടെ അച്ഛനോ അമ്മയോ കടുത്ത രോഗിയാണോ / രോഗമായി കിടപ്പിലാണോ?{')'}{' '}
							</li>
							<li>
								criteria4 :- Are you differently abled? {'('}നിങ്ങള്‍ക്ക് ഏതെങ്കിലും തരത്തിലുള്ള അംഗപരിമിതി ഉണ്ടോ?{')'}{' '}
							</li>
							<li>
								criteria5 :- Are you suffering from any serious illness? {'('}നിങ്ങള്‍ക്ക് കടുത്ത രോഗങ്ങള്‍ ഉണ്ടോ?{')'}{' '}
							</li>
							<li>
								criteria6 :- Is your family Below Poverty Line {'('}BPL{')'}? {'('}നിങ്ങളുടെ കുടുംബം ദാരിദ്ര്യരേഖയ്ക്ക് താഴെയാണോ?{')'}{' '}
							</li>
							<li>criteria7 :- Are you a day scholar or a hosteler? </li>
						</ul>
					</Body>
				</Main>
			)}

			{detailedView && (
				<Main>
					<Header>
						<Button type='close' onClick={() => setDetailedView(null)}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='h-[75vh]'>
							<img src={detailedView?.img} alt={detailedView?.name} className='mb-4 rounded-full' />
							<div className='text-center'>
								<h2 className='text-2xl font-bold mb-2'>{detailedView?.name}</h2>
								<p className='text-gray-600'>Class: {detailedView?.className}</p>
								<p className='text-gray-600'>Admission Number: {detailedView?.admisionNo}</p>
							</div>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default HungryFreeCampus;
