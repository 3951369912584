import React, { useContext, useState } from 'react';
import Button from './Button/Button';
import { FirebaseContext } from '../context/FirebaseContext';
import { useQuery } from 'react-query';
import { getAxiosTokenInstance } from '../utils/axiosInstance';
import { getSettings } from './APICaller/ApiServices';

const SettingsList = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);

	let { data = [], refetch } = useQuery('getAccounts', getSettings(collegeId, 'accounts'));
	console.log({ data });

	const [categories, setCategories] = useState(data?.settings);
	const [is, setIsLoading] = useState();

	const [editMode, setEditMode] = useState({ keyTitle: null, index: null });

	const updateCategoryOptions = (keyTitle, updatedOptions) => {
		setCategories((prevCategories) => prevCategories.map((category) => (category.keyTitle === keyTitle ? { ...category, options: updatedOptions } : category)));
	};

	const handleAddItem = (keyTitle) => {
		setCategories((prevCategories) =>
			prevCategories.map((category) => {
				if (category.keyTitle === keyTitle && category.newItem.trim()) {
					return {
						...category,
						options: [...category.options, category.newItem.trim()],
						newItem: '',
					};
				}
				return category;
			}),
		);
	};

	const handleDeleteItem = (keyTitle, index) => {
		const category = categories.find((cat) => cat.keyTitle === keyTitle);
		const updatedOptions = category.options.filter((_, i) => i !== index);
		updateCategoryOptions(keyTitle, updatedOptions);
	};

	const handleEditItem = (keyTitle, index, value) => {
		const category = categories.find((cat) => cat.keyTitle === keyTitle);
		const updatedOptions = [...category.options];
		updatedOptions[index] = value.trim();
		updateCategoryOptions(keyTitle, updatedOptions);
		setEditMode({ keyTitle: null, index: null });
	};

	const postAccountsSettings = async (deleteData, _id) => {
		try {
			setIsLoading(true);
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postSettings', {
				collegeId,
				type: 'accounts',
				settings: categories,
				_id: data?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setCategories(data.settings);
				refetch();
			}
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='p-6 flex flex-col items-center '>
			<div className='flex self-end'>
				<Button width='100px' type='doc' onClick={() => {}}>
					Clear
				</Button>
				<Button
					type='submit'
					width='100px'
					color='blue'
					onClick={() => {
						postAccountsSettings();
					}}>
					Submit
				</Button>
				<Button width='100px' type='close' onClick={onClose}>
					Close
				</Button>
			</div>
			<div className='p-6 flex flex-col items-center h-[80vh] overflow-scroll'>
				<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full  '>
					{categories?.map((category) => (
						<div key={category.keyTitle} className='bg-white p-4 rounded-lg shadow-md shadow-blue-400 flex flex-col'>
							<h3 className='text-lg font-bold text-gray-800 mb-4'>{category.title}</h3>

							<div className='flex gap-2 mb-4'>
								<input type='text' placeholder='Add new item' className='flex-grow px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 shadow-sm' value={category.newItem} onChange={(e) => setCategories((prevCategories) => prevCategories.map((cat) => (cat.keyTitle === category.keyTitle ? { ...cat, newItem: e.target.value } : cat)))} />
								<button className='bg-blue-900 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition' onClick={() => handleAddItem(category.keyTitle)}>
									Add
								</button>
							</div>

							<ul className='space-y-2 h-60 overflow-y-scroll  ml-[-30px] '>
								{category.options.map((item, index) => (
									<li key={index} className='flex justify-between items-center bg-blue-100 p-2 rounded-lg'>
										{editMode.keyTitle === category.keyTitle && editMode.index === index ? (
											<input type='text' className='flex-grow px-2 py-1 border rounded-lg' defaultValue={item} onBlur={(e) => handleEditItem(category.keyTitle, index, e.target.value)} autoFocus />
										) : (
											<span className='text-gray-700 cursor-pointer flex-grow' onClick={() => setEditMode({ keyTitle: category.keyTitle, index })}>
												{item}
											</span>
										)}
										<button className='text-red-900 font-bold text-sm hover:bg-red-400 p-1 rounded border-none' onClick={() => handleDeleteItem(category.keyTitle, index)}>
											✕
										</button>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default SettingsList;
