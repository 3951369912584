import Space from "antd/lib/space";
import Collapse from "antd/lib/collapse";
import classNames from "./detailView.module.scss";
import { useMemo } from "react";

const { Panel } = Collapse;

const CulturalActivities = ({ activities }) => {
  const parsedActivites = useMemo(() => {
    let list = [];

    if (activities) {
      list = Object.keys(activities)?.map((key) => ({
        title: key,
        list: activities[key],
      }));
    }

    return list;
  }, [activities]);

  return (
    <>
      <div className={classNames.subTitle}>Cultural Activities</div>
      {activities ? (
        <Space direction="vertical" className={classNames.space}>
          <Collapse collapsible="header" accordion>
            {parsedActivites?.map((item) => (
              <Panel
                key={item.title}
                header={
                  <>
                    <span>{item.title}</span>
                  </>
                }
              >
                {item.list?.map((activityItem) => (
                  <div
                    key={activityItem.name}
                    className={classNames.attendanceMonth}
                  >
                    <span>{activityItem.name}</span>
                    {/* <span>{item.percentage}%</span> */}
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        </Space>
      ) : (
        <div className={classNames.emptyMessage}>
          No Cultural Activities For This User
        </div>
      )}
    </>
  );
};

export default CulturalActivities;
