import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { useDropDowns } from '../../../NecttosComp/APICaller/queryHooks';
import Scrollbars from 'react-custom-scrollbars';
import { getBookWithMember, getReturnBook } from '../../../NecttosComp/APICaller/ApiServices';
import noPhotoAvailable from '../../../static/images/library/noPhotoAvailable.png';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import SearchableBookInput from '../../../myComp/SearchableBookInput';
function getDifferenceInDays(date1, date2) {
	const diffInMs = Math.abs(date2 - date1);
	return diffInMs / (1000 * 60 * 60 * 24);
}
export const ReturnBook = ({ onClose }) => {
	const [loading, setLoading] = useState(false);

	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { data: bookWithMembers, refetch } = useQuery(['getBookWithMember', state?.bookStocksId], getBookWithMember(collegeId, state?.bookStocksId, state?.editedDate));
	const { data: returnBook, refetch: activeBookRefetch } = useQuery(['getReturnBook'], getReturnBook({ collegeId, state, setLoading }));
	const { data: dropDown } = useDropDowns('library');

	let bookMemberData = bookWithMembers?.bookDetails || {};

	let returnData = returnBook?.returnData || {};

	const { Option } = Select;

	let returnDate = state?.editedDate ? new Date(state?.editedDate) : new Date();
	let tookDate = new Date(bookMemberData?.tookOn);

	let days = getDifferenceInDays(tookDate, returnDate).toFixed(0);
	let fine = 14 < parseInt(days) ? parseInt(days) - 14 : 0;

	const UpdateBookStatus = async (item) => {
		try {
			setLoading(true);
			let data = {
				bookId: item?.bookId,
				bookStocksId: item?.bookStocksId,
				collegeId,
				userId: bookMemberData?.userId,
				action: 'return',
				takenStatus: item.takenStatus,
				returnDate,
				days: item.days,
				fine: item.fine,
				bookDetails: state?.bookDetails || item?.bookDetails || '',
			};
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/library/takeBook', data);
			setLoading(false);
			setState({
				...state,
				accessionNumber: '',
				'': '',
				bookId: '',
				bookStocksId: '',
				imageUrl: '',
				keyData: '',
				keyData: '',
				keyData: '',
				keyData: '',
				editedDate: '',
			});
			activeBookRefetch();
			refetch();
			return resp.data;
		} catch (err) {
			return new Error('Book not available');
		}
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div style={{ flexDirection: 'row', display: 'flex' }}>
					<div style={{ width: '65%' }}>
						<SearchableBookInput filter={'returned'} stateData={state} setStateData={setState} keyData={``} titleData={`Search Book`} />
					</div>
					<Formik enableReinitialize>
						<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ height: 40 }}>
							<Field
								type='date'
								name='year'
								id='year'
								value={state.editedDate}
								onChange={(e) => {
									setState({ ...state, editedDate: e.target.value });
								}}
							/>
							<label>Edit Return Date</label>
						</div>
					</Formik>
					<Formik enableReinitialize>
						<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ height: 40 }}>
							<Field
								type='text'
								name='year'
								id='year'
								value={state.editedReason}
								onChange={(e) => {
									setState({ ...state, editedReason: e.target.value });
								}}
							/>
							<label>Reason for Edit Return Date</label>
						</div>
					</Formik>
					<Button
						className={classNames.reloadButton}
						onClick={() => {
							refetch();
						}}>
						Reload
					</Button>
					<Button
						className={classNames.reloadButton}
						onClick={() => {
							setState({
								...state,
								accessionNumber: '',
								'': '',
								bookId: '',
								bookStocksId: '',
								imageUrl: '',
								keyData: '',
								keyData: '',
								keyData: '',
								keyData: '',
								editedDate: '',
							});

							activeBookRefetch();
						}}>
						Clear
					</Button>
				</div>
				{state?.accessionNumber && (
					<div className={classNames.controllDiv}>
						<div className={classNames.mainCondainer} style={{ width: '500px' }}>
							{
								<div className={classNames.titleContainer}>
									<h5 className={classNames.nameOfBook}>{bookMemberData?.name}</h5>
									<div className={classNames.avatar}>
										<img src={bookMemberData?.photoURL || noPhotoAvailable} className={classNames.dp} />
									</div>
									<div className={classNames.typoContainer}>
										<p>{bookMemberData?.className}</p>
										<p>Mn: {bookMemberData?.admisionNo}</p>
									</div>
								</div>
							}
						</div>
						<div className={classNames.mainCondainer}>
							<div className={classNames.role_container} style={{ height: '100%' }}>
								<Scrollbars style={{ width: '100%', height: '100%', borderRadius: 25 }}>
									<div className={classNames.card_container}>
										{bookMemberData?.booksData?.map((item, i) => (
											<div className={classNames.role_card}>
												<div className={classNames.icon}>
													<img src={item.photoURL} className={classNames.iconSize} />
												</div>
												<h2>{item.accessionNumber}</h2>
												<h3>{item.callNo}</h3>
												<h3>Fine: {item.fine}</h3>
												<h3>
													Days: {item.days} ({item.holidaysReport}h)
												</h3>
												<h4>{item.nameOfBook.length > 60 ? item.nameOfBook.slice(0, 26) + ' ... ' + item.nameOfBook.slice(-26) : item.nameOfBook}</h4>
												<h5>
													{moment(new Date(item.tookOn)).format('DD MMM YY')} [{item.takenStatus === 'renew' ? 'R' : 'I'}]
												</h5>
												{loading ? (
													<LoadingAnimation customHeight={50} customWidth={50} />
												) : (
													item.accessionNumber === state.accessionNumber && (
														<Button
															className={classNames.issueButton}
															style={{ backgroundColor: 'green' }}
															onClick={() => {
																UpdateBookStatus(item);
															}}>
															Return
														</Button>
													)
												)}
											</div>
										))}
									</div>
								</Scrollbars>
							</div>
						</div>
					</div>
				)}
				{!state?.admisionNo && !state?.accessionNumber && (
					<div className={classNames.listContainer2}>
						<div className={classNames.headerPanel}>
							<div className={classNames.paginationController}>
								<Button
									className={classNames.reloadButton}
									disabled={parseInt(state.page) < 1}
									onClick={() => {
										setState({ ...state, page: parseInt(state.page) - 1 });
									}}>
									Prev
								</Button>
								<Formik enableReinitialize>
									<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
										<Field
											type='number'
											name='year'
											id='year'
											value={state.page}
											onChange={(e) => {
												setState({ ...state, page: e.target.value });
											}}
										/>
										<label>Page</label>
									</div>
								</Formik>
								<Button
									className={classNames.reloadButton}
									disabled={parseInt(state.page) * 30 > parseInt(returnData?.total?.[0]?.total) - 30}
									onClick={() => {
										setState({ ...state, page: parseInt(state.page) + 1 });
									}}>
									Next
								</Button>
								{loading ? (
									<LoadingAnimation customHeight={50} customWidth={50} />
								) : (
									<>
										<span className={classNames.heading}>
											Showing Result : {parseInt(state.page) * 30} - {parseInt(state.page) * 30 + 30} out of {returnData?.total?.[0]?.total}{' '}
										</span>
									</>
								)}
								<Button
									className={classNames.reloadButton}
									onClick={() => {
										activeBookRefetch();
									}}>
									Load
								</Button>
								<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
							</div>
						</div>
						<Scrollbars style={{ height: '90%' }}>
							<div ref={componentRef} className='printable'>
								<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
								<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
										<img width='12%' height='12%' src={selectedCollege?.logo} />
										<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
											<span style={{ fontSize: '30px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
											<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
											<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>
												{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
											</p>
										</div>
									</div>
									<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'left' }}>Return Register</h5>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Sn</h5>
										<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 14, width: '10%' }}>Acc No</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '32%' }}>Title</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '16%' }}>Author</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '9%' }}>DateTime</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>Days</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>Fine</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Mn No</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>class/Dept.</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Mn Name</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Issued By</h5>
									</div>
									{returnData?.data?.map((item) => (
										<>
											<div style={{ width: '100%', display: 'flex', height: ' 40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'renew' ? '#c0faeb' : 'white' }}>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.sn}</h5>
												<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.accessionNumber}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '32%' }}>{item.title}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '16%' }}>{item.author}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '9%' }}>{moment(new Date(item?.returnedOn)).format('DD MMM YYYY hh:mm A')}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '5%' }}>{item.days}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '5%' }}>{item.fine}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.admisionNo}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.className}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedTo}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedBy}</h5>
											</div>
											<div
												style={{
													width: '100%',
													display: 'flex',
													height: 1,
													backgroundColor: 'black',
												}}></div>
										</>
									))}
									<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
								</div>
							</div>
						</Scrollbars>
					</div>
				)}
			</div>

			{state?.accessionNumber === 0 && (
				<div className={classNames.controlBoard}>
					{bookMemberData.takenStatus === 'renew' ? <h6>Book Renewed</h6> : <h6>Book Issued</h6>}
					<h6>{moment(new Date(tookDate)).format('DD MMM YYYY hh:mm A')}</h6>
					<div className={classNames.actionsButton}>
						<Button
							className={classNames.reloadButton}
							style={{ width: 90, backgroundColor: 'red' }}
							onClick={() => {
								refetch();
							}}>
							{days} Days
						</Button>
						<Button
							className={classNames.issueButton}
							style={{ width: 90, backgroundColor: 'red' }}
							onClick={() => {
								UpdateBookStatus();
							}}>
							Fine {fine}
						</Button>
					</div>
					<Formik enableReinitialize>
						<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ height: 50 }}>
							<Field
								type='date'
								name='year'
								id='year'
								value={state.editedDate}
								onChange={(e) => {
									setState({ ...state, editedDate: e.target.value });
								}}
							/>
							<label>Edit Return Date</label>
						</div>
					</Formik>
					<Formik enableReinitialize>
						<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ height: 50 }}>
							<Field
								type='text'
								name='year'
								id='year'
								value={state.editedReason}
								onChange={(e) => {
									setState({ ...state, editedReason: e.target.value });
								}}
							/>
							<label>Reason for Edit Return Date</label>
						</div>
					</Formik>

					<Formik enableReinitialize>
						<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ height: 100 }}>
							<Field
								type='text'
								name='year'
								id='year'
								value={state.bookDetails}
								onChange={(e) => {
									setState({ ...state, bookDetails: e.target.value });
								}}
							/>
							<label>Remarks</label>
						</div>
					</Formik>
					<div className={classNames.actionsButton}>
						{state?.accessionNumber && (
							<Button
								className={classNames.reloadButton}
								onClick={() => {
									refetch();
								}}>
								Reload
							</Button>
						)}
						{state?.accessionNumber + '' === bookMemberData?.accessionNumber + '' && bookMemberData?.bookStocksId && bookMemberData?.bookId && bookMemberData?.userId && (
							<Button
								className={classNames.issueButton}
								disabled={!bookMemberData?.eligibleForRenew}
								onClick={() => {
									UpdateBookStatus();
								}}>
								Renew {bookMemberData?.renewDays}
							</Button>
						)}
					</div>
				</div>
			)}

			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Select
							showSearch
							className={classNames.formFullWidth2}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterType: val, accessionNumber: '', admisionNo: '' });
							}}>
							{dropDown?.['shortItems']?.map((item, i) => (
								<Option key={i} value={item.value}>
									{item.title}
								</Option>
							))}
						</Select>
						<label>Filter Type</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Select
							showSearch
							className={classNames.formFullWidth2}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterValue: val, accessionNumber: '', admisionNo: '' });
							}}>
							{dropDown?.[state?.filterType]?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
						<label>{state?.filterType}</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }}>
						<Field
							type='date'
							name='startDate'
							id='startDate'
							value={state.startDate}
							onChange={(e) => {
								setState({ ...state, startDate: e.target.value, accessionNumber: '', admisionNo: '' });
							}}
						/>
						<label>Start Date</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }}>
						<Field
							type='date'
							name='endDate'
							id='endDate'
							value={state.endDate}
							onChange={(e) => {
								setState({ ...state, endDate: e.target.value, accessionNumber: '', admisionNo: '' });
							}}
						/>
						<label>End Date</label>
					</div>
				</Formik>
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
