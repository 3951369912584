import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Loader1 from '../../NecttosComp/Loaders/Loader';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileEdit, useProfileEditNew } from '../../NecttosComp/APICaller/queryHooks';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Loader from '../../NecttosComp/Loader/Loader';
import { ColorAbbreviationListOfProfile, ProfileDataDisplay, getProfileUpdateColors, postProfileSubmission } from '../../FunctionalExport/Formate';
import { uploadFileToFirebase } from '../../NecttosComp/APICaller/APICaller';
import { FirebaseContext } from '../../context/FirebaseContext';
import CSVEditor from './csvEditor';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { Popconfirm } from 'antd';
import { ProgressBar, calculateProfilepPercentage } from '../NewProfile';

function Publications({ onClose, studentId, refresh, setRefresh, onNext, modal }: any) {
	const titleKey = 'publications';
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const { user, collegeId } = useContext(FirebaseContext);
	const [csv, setCSv] = useState(false);
	const [isTick, setIsTick] = useState<any>(null);
	const [temp, setTemp] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>({ title: '', organiser: '', startDate: '', endDate: '', documentId: '', documentURL: '' });
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });
	const [values, setValues] = useState<any[]>(profileData);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	const handleUpdate = async (addNew: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/updateByKeyData', {
				_id: addNew._id,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
				newValue: addNew,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleDelete = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.delete('college/profile/deleteByKeyData', {
				data: { _id: data._id, userId: user?.uid, collegeId, keyData: titleKey },
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleUploadCSV = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/addByKeyData', {
				data,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsLoading(true);
		const files = event.target.files;
		if (files && files[0]) {
			(async () => {
				try {
					const { url, fileId } = await uploadFileToFirebase(files[0], user?.uid, addNew?.documentId, (progress) => {
						// setUploadProgress((prev: any) => ({ ...prev, [documentId]: progress }));
					});
					setAddNew({ ...addNew, documentURL: url, documentId: fileId });
				} catch (error) {
					console.error('Error uploading file:', error);
				}
			})();
		}
		setIsLoading(false);
	};

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);

	console.log({ values });

	return (
		<>
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button
							type='doc'
							onClick={() => {
								setCSv(true);
							}}
							children='CSV'
						/>
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div ref={componentRef} className='w-full printable'>
								<Table id={'profilePDF'} width='100%' innerWidth={['10%', '10%', '10%', '10%', '10%', '7%', '8%', '8%', '8%', '8%', '8%', '11%']}>
									<Thead>
										<Tr>
											<Th width={'8%'} position={0} children='Title of Paper' />
											<Th width={'9%'} position={1} children='Name of the Author(s)' />
											<Th width={'10%'} position={2} children='Name of the Journal' />
											<Th width={'8%'} position={3} children='Year of Publications' />
											<Th width={'8%'} position={4} children='ISSN No' />
											<Th width={'7%'} position={5} children='Type' />
											<Th width={'8%'} position={6} children='Link to the Recognition in UGC enlistment of the Journal' />
											<Th width={'7%'} position={7} children='DOI (Link)' />
											<Th width={'8%'} position={8} children='Issue & Volume No.' />
											<Th width={'8%'} position={9} children='Date of Publication' />
											<Th width={'8%'} position={10} children='First Page' />
											<Th width={'19%'} position={11} children='Action' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr key={index}>
												<Td width={'8%'} position={0} index={index}>
													<p>{x?.articleTitle}</p>
												</Td>
												<Td width={'9%'} position={1} index={index}>
													<p>{x?.authorName}</p>
												</Td>
												<Td width={'10%'} position={2} index={index}>
													<p>{x?.journalTitle}</p>
												</Td>
												<Td width={'8%'} position={3} index={index}>
													<p>{x?.dateOfPublication}</p>
												</Td>
												<Td width={'8%'} position={4} index={index}>
													<p>{x?.issnNumber}</p>
												</Td>
												<Td width={'7%'} position={5} index={index}>
													<p>{x?.type}</p>
												</Td>
												<Td width={'8%'} position={6} index={index}>
													<p>{x?.link}</p>
												</Td>
												<Td width={'7%'} position={7} index={index}>
													<p>{x?.linkDoi}</p>
												</Td>
												<Td width={'8%'} position={8} index={index}>
													<p>{x?.issueNumber}</p>
												</Td>
												<Td width={'8%'} position={9} index={index}>
													<p>{x?.dateOfPublication}</p>
												</Td>
												<Td width={'8%'} position={10} fontSize='6' index={index}>
													{x?.documentURL && <p>{x?.documentURL?.slice(0, 3) + ' .... ' + x?.documentURL?.slice(-3)}</p>}
												</Td>
												<Td width={'19%'} position={11} index={index}>
													<div className='flex'>
														<Button
															type='update'
															onClick={() => {
																setMode('edit');
																setAddNew(x);
																setTemp(x);
															}}>
															Edit
														</Button>
														<Popconfirm placement='topRight' title='Are you sure you want to delete?' okText='Yes' cancelText='No' onConfirm={() => handleDelete(x)}>
															<Button type='delete'>Delete</Button>
														</Popconfirm>
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>

							<Button
								onClick={async () => {
									setMode(!mode);
									setTemp({});
								}}
								type='update'>
								Add New Publications
							</Button>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</div>

			{mode && (
				<Main width='100vw' height='100vh'>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body width='full' height='full'>
						<div className='grid grid-flow-col grid-col-2 p-2 justify-start gap-8'>
							{['delete', 'edit'].includes(mode) && (
								<div className=' p-2 h-full rounded '>
									<div className='bg-white rounded p-6 overflow-y-scroll '>
										<ProfileDataDisplay temp={temp} />
									</div>
									<div className='flex float-left items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-[38vw] z-50  bg-white rounded h-full overflow-y-scroll'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>

									<Input width='98%' fieldName='Article Title' returnKey='articleTitle' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Type' returnKey='type' options={dropDowns[20]?.journalType} setState={setAddNew} state={addNew} type='drop' />
									<Input width='98%' fieldName='author' returnKey='authorName' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Link to the Recognition in UGC enlistment of the Journal' returnKey='link' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Doi' returnKey='linkDoi' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Issue & Voliume Number' returnKey='issueNumber' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='ISSN Number' returnKey='issnNumber' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Volume Number' returnKey='volumeNumber' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Journal Title' returnKey='journalTitle' setState={setAddNew} state={addNew} type='text' />
									<Input width='98%' fieldName='Date of Publication' returnKey='dateOfPublication' setState={setAddNew} state={addNew} type='date' />

									<label className='mt-2'>Upload first page of the publication</label>
									<input className='mb-2' type='file' onChange={handleFileChange} />

									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { dateOfPublication: '', volumeNumber: '', journalTitle: '', articleTitle: '', linkDoi: '', issueNumber: '', issnNumber: '', link: '', authorName: '', type: '', documentId: '', documentURL: '' })?.percentage)} />
									{!isLoading && (
										<Button
											width='100%'
											onClick={async () => {
												handleUpdate(addNew);
												closeAnimation();
												setTimeout(() => setMode(false), 250);
											}}
											type='save'
											children='Save'
										/>
									)}
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}

			{csv && (
				<CSVEditor
					titleKey={titleKey}
					keysNeeded={['articleTitle', 'type', 'authorName', 'link', 'linkDoi', 'issueNumber', 'journalTitle', 'dateOfPublication', 'issnNumber']}
					setCsv={setCSv}
					returnData={async (data) => {
						if (data) {
							console.log(data);
							let val = [...values];
							setIsTick(false);
							await handleUploadCSV(data);
							setIsTick(true);
							setTimeout(() => {
								setIsTick(null);
								setCSv(false);
							}, 2000);
						}
					}}
				/>
			)}

			{isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}

			{isLoading && <Loader />}
		</>
	);
}

export default Publications;
