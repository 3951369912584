import React, { useContext, useRef, useState } from 'react';
import { Modal, message } from 'antd';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getFeeConcession, getMainAccounts } from '../../../NecttosComp/APICaller/ApiServices';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import Button from '../../../NecttosComp/Button/Button';
import { Main } from '../../../NecttosComp/Layout/Layout';
import ReactToPrint from 'react-to-print';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';
import SearchableUserInput from '../../../myComp/SearchableUserInput';
import LoadingAnimation from '../../../components/LoadingAnimation';

export const FeeConcession = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const componentRef = useRef();
	const [state, setState] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const { data = [], refetch } = useQuery('getFeeConcession', getFeeConcession(collegeId));
	const { data: accountData = [] } = useQuery(['getMainAccountss', collegeId], getMainAccounts(collegeId));

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/action/postFeeConcession', {
				collegeId,
				userId: state?._id,
				amount: state?.amount,
				naration: state?.naration,
				scholarshipType: state?.scholarshipType,
				mainAccountId: state?.mainAccountId,
				_id: state?.spId,
			});
			if (resp.data.statusCode !== 200) throw new Error(resp.data.message || 'API Error');
			setState({});
			refetch();
			setIsModalVisible(false);
			message.success('Fee Concession successfully saved!');
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleDelete = async (spId) => {
		try {
			setIsLoading(true);
			const instance = await getAxiosTokenInstance();
			await instance.delete(`/college/action/deleteFeeConcession/${spId}`);
			refetch();
			message.success('Fee Concession successfully deleted!');
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleEdit = (item) => {
		setState({
			_id: item.userId,
			amount: item.amount,
			mainAccountId: item.mainAccountId,
			admisionNo: item.name,
			scholarshipType: item.scholarshipType,
			mainAccountIdval: item.mainAccountName,
			naration: item.naration,
			spId: item.spId,
		});
		setIsModalVisible(true);
	};

	const clearForm = () => {
		setState({});
		setIsModalVisible(false);
	};

	return (
		<Main title='Fee Concession Management' width='90vw' height='90vh'>
			<div className='flex justify-between items-center'>
				<h2 className='text-2xl font-bold'>Fee Concession Management</h2>
				<div className='flex'>
					<Button type='fetch' onClick={refetch}>
						Reload
					</Button>
					<ReactToPrint trigger={() => <Button type='close'>Print</Button>} content={() => componentRef.current} />
					<Button type='save' onClick={() => setIsModalVisible(true)}>
						Add Fee Concession
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</div>

			<div ref={componentRef} className='mt-6 h-[75vh] overflow-auto'>
				<Table width='100%' innerWidth={['5%', '15%', '25%', '15%', '10%', '10%', '10%', '10%']}>
					<Thead>
						<Tr>
							<Th>Sn</Th>
							<Th>Admission No</Th>
							<Th>Account Name</Th>
							<Th>Name</Th>
							<Th>Class</Th>
							<Th>Amount</Th>
							<Th>Type</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{data.map((item, i) => (
							<Tr key={i}>
								<Td>{i + 1}</Td>
								<Td>{item.admisionNo}</Td>
								<Td>{item.mainAccountName}</Td>
								<Td>{item.name}</Td>
								<Td>{item.className}</Td>
								<Td>{item.amount}</Td>
								<Td>{item.scholarshipType}</Td>
								<Td>
									<div className='flex space-x-2'>
										<Button type='edit' onClick={() => handleEdit(item)}>
											Edit
										</Button>
										<Button type='delete' onClick={() => handleDelete(item.spId)}>
											Delete
										</Button>
									</div>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</div>

			<Modal title={state?.spId ? 'Edit Fee Concession' : 'Add Fee Concession'} open={isModalVisible} onCancel={clearForm} footer={null}>
				{isLoading ? (
					<LoadingAnimation dark />
				) : (
					<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
						<SearchableUserInput primoryKey='admisionNo' secondaryKey='name' collection='users' stateData={state} setStateData={setState} titleData='Search Students' />
						<CustomInput keyData='amount' titleData='Amount / Semester' typeData='number' stateData={state} setStateData={setState} />
						<CustomInput keyData='scholarshipType' titleData='Fee Concession Type' typeData='text' stateData={state} setStateData={setState} />
						<CustomDropDownKey keyData={'mainAccountId'} keyObject={'name'} keyReturn={'_id'} titleData={'Select Account'} dropValues={accountData} stateData={state} setStateData={setState} />
						<CustomInput keyData='naration' titleData='Naration' typeData='text' stateData={state} setStateData={setState} />
					</div>
				)}

				<div className='flex justify-end mt-6 space-x-4'>
					<Button type='cancel' onClick={clearForm}>
						Cancel
					</Button>
					<Button type='save' onClick={handleSubmit}>
						{state?.spId ? 'Update' : 'Submit'}
					</Button>
				</div>
			</Modal>
		</Main>
	);
};
