import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useDropDowns } from '../../NecttosComp/APICaller/queryHooks';
import { getAditionalStatiData, getCaste, getCatogory, getDropdownData, getSeletedDetails, getSettings, getStatiCount, getStatistics } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { convertToNormalWords, mediumBackgroundColors, darkBackgroundColors, lightBackgroundColors } from '../../utils';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import moment from 'moment';
import CustomKey from '../../components/Office/CustomKey';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';

export const Statistics = ({ onClose }) => {
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef(null);
	const [exportModal, setExportModal] = useState(false);
	const [selectedDetails, setSelectedDetails] = useState([]);
	const [filteredItems, setItems] = useState([]);
	const [settingsModal, setSettingsModal] = useState(null);
	const [list, setList] = useState({});
	const [bucketModal, setBucketModal] = useState(false);
	const [currentList, setCurrentList] = React.useState([]);

	const handleItems = (event) => {
		const value = event.target.value;
		if (!filteredItems?.includes(value)) {
			setItems([...(filteredItems || []), value]);
		}
	};
	const [blocking, setBlocking] = useState(() => {
		const saved = localStorage.getItem('blocking');
		const initialValue = saved
			? JSON.parse(saved)
			: {
					type: true,
					nature: true,
					disabilities: true,
					state: true,
					reservationCategory: true,
					religion: true,
					category: true,
					familyIncome: true,
					stay: true,
					gender: true,
					eGrandCategory: true,
					designationStatus: true,
					secondLanguage: true,
					annualIncome: true,
					designation: true,
					colleges: true,
					caste: true,
					provertyline: true,
					department: true,
					classes: true,
					language: true,
					basic: true,
					official: true,
					address: true,
					qualifications: true,
			  };
		return initialValue;
	});

	const [stateData, setStateData] = useState(() => {
		const saved = localStorage.getItem('stateData');

		if (saved?.zname) {
			saved.zname = true;
		}
		const initialValue = saved ? JSON.parse(saved) : { zname: true };

		return initialValue;
	});
	useEffect(() => {
		localStorage.setItem('blocking', JSON.stringify(blocking));
	}, [blocking]);

	const [date, setDate] = useState();

	useEffect(() => {
		localStorage.setItem('stateData', JSON.stringify(stateData));
	}, [stateData]);

	const resetStateData = () => {
		setDate((prv) => ({
			...prv,
			startDate: '',
			endDate: '',
		}));
		const initialState = { key: 'dd' };
		setStateData(initialState);
		localStorage.setItem('stateData', JSON.stringify(initialState));
	};

	const [loading, setLoading] = useState(false);
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
	const { data: categoryData = [] } = useQuery(['categoryData', stateData], getCatogory({ collegeId, collection: 'users', key: 'category', stateData }));
	const { data: religionData = [] } = useQuery(['religionData1'], getStatistics({ collegeId, collection: 'users', key: 'religion' }));
	const { data: activeStatusData = [] } = useQuery(['activeStatusData'], getStatistics({ collegeId, collection: 'users', key: 'activeStatus' }));
	const { data: disabilitiesData = [] } = useQuery(['disabilitiesData'], getStatistics({ collegeId, collection: 'users', key: 'disabilities' }));
	const { data: reservationData = [] } = useQuery(['reservationData'], getStatistics({ collegeId, collection: 'users', key: 'admType' }));
	const { data: genderData = [] } = useQuery(['genderData1'], getStatistics({ collegeId, collection: 'users', key: 'gender' }));
	const { data: designationData = [] } = useQuery(['designationData', stateData], getCaste({ collegeId, collection: 'users', key: 'designation', stateData }));
	const { data: designationStatusData = [] } = useQuery(['designationStatusData', stateData], getCaste({ collegeId, collection: 'users', key: 'designationStatus', stateData }));
	const { data: casteData = [] } = useQuery(['casteData', stateData], getCaste({ collegeId, collection: 'users', key: 'caste', stateData }));
	const { data: stayData = [] } = useQuery(['stayData', stateData], getCaste({ collegeId, collection: 'users', key: 'stay', stateData }));
	const { data: secondLanguageData = [] } = useQuery(['secondLanguageData', stateData], getCaste({ collegeId, collection: 'users', key: 'secondLanguage', stateData }));
	const { data: provertyLineData = [] } = useQuery(['provertyLineData', stateData], getCaste({ collegeId, collection: 'users', key: 'provertyLine', stateData }));
	const { data: annualIncomeData = [] } = useQuery(['annualIncomeData', stateData], getCaste({ collegeId, collection: 'users', key: 'annualIncome', stateData }));
	const { data: stateDataUsers = [] } = useQuery(['stateData', stateData], getCaste({ collegeId, collection: 'users', key: 'state', stateData }));
	const { data: count = [] } = useQuery(['count', date, stateData], getStatiCount({ collegeId, collection: 'users', date, stateData }));
	const { data: getAditionalStati = [] } = useQuery(['getAditionalStatiData', stateData], getAditionalStatiData({ collegeId, collection: 'users', stateData }));

	const incomeDrop = ['No Income', '₹0 - ₹2,50,000', '₹2,50,001 - ₹ 5,00,000', '₹5,00,001 - ₹ 7,50,000', '₹7,50,001 - ₹ 10,00,000', '₹10,00,001 - ₹12,50,000', '₹12,50,001 - ₹15,00,000', 'Above ₹ 15,00,000'];
	const officialKey = ['hallTicketNo', 'department', 'eGrands', 'tcDate', 'tcNumber', 'courseName', 'activeStatus', 'travelDetails', 'admisionNo', 'penNumber', 'designationStatus', 'designation', 'admType', 'batchName', 'class', 'capId', 'dateOfAdmission', 'dateOfJoin', 'rollNo', 'shortName', 'index', 'lastTcDate', 'lastTcNumber', 'lastSchoolName', 'secondLanguage', 'stay', 'uid'].sort((a, b) => a.localeCompare(b));
	const qualifications = ['degreeCourse', 'degreePercentage', 'degreeRegisterNo', 'degreeSpecialisation', 'degreeUniversity', 'degreeYearOfPass', 'plusTwoBoard', 'plusTwoNoOfChance', 'plusTwoPercentage', 'plusTwoRegisterNo', 'plusTwoStream', 'plusTwoYearOfPass', 'sslcBoard', 'sslcNoOfChance', 'sslcPercentage', 'sslcRegisterNo', 'sslcYearOfPass']?.sort((a, b) => a?.localeCompare(b));
	const addressKey = ['panchayatMunicipalityCorporation', 'permanentDistrict', 'permanentHouseName', 'permanentNationality', 'permanentPanchayatMunicipalityCorporation', 'permanentPinCode', 'permanentPlace', 'permanentPostOffice', 'permanentState', 'permanentVillage', 'district', 'houseName', 'nationality', 'state', 'village', 'pinCode', 'place', 'postOffice']?.sort((a, b) => a?.localeCompare(b));
	const basicKey = ['phoneNumber', 'dob', 'bloodGroup', 'email', 'gender', 'disabilities', 'aadhaarNumber', 'annualIncome', 'familyIncome', 'provertyLine', 'guardianMobile', 'caste', 'category', 'meritalStatus', 'name', 'religion']?.sort((a, b) => a?.localeCompare(b));
	// const teacherBasicKey = ["name","phoneNumber","dob","email","gender","houseName","district","pinCode","place","postOffice","nationality","aadhaarNumber","bloodGroup","caste","category","permanentDistrict","permanentHouseName","permanentNationality","permanentPinCode","permanentPlace","permanentPostOffice","permanentState","religion","shortName","photoURL","imageURL2","meritalStatus","idCardVerified","fcmToken","toggle"]?.sort((a, b) => a?.localeCompare(b));
	// const teacherOfficialKey = ["_id","uid","disabled","collegeRoles","designation","designationStatus","createdOn","updatedBy","updatedOn","collegeId","universityId","activeStatus","verifiedOn","bankDetails","qualifications","publications","experiences","seminarWorkshop","serviceCourse","aditionalResponsibilities","membersBodies","travelDetails","hungryCordinator"]?.sort((a, b) => a?.localeCompare(b));

	let { data: drops = [], refetch, status } = useQuery('getDataBank', getSettings(collegeId, 'dataBank'));

	const [dropDowns, setDropDowns] = useState([]);
	console.log({ list });

	useEffect(async () => {
		if (drops) {
			const addProperties = (array) =>
				array.map((x) => ({
					needed: false,
					start: moment().format('YYYY-MM-DD'),
					priority: 'Low',
					...x,
				}));

			const updatedSettings = {
				scholars: addProperties(drops?.settings?.scholars),
				students: addProperties(drops?.settings?.student),
				teachers: addProperties(drops?.settings?.teacher),
				staff: addProperties(drops?.settings?.staff),
			};

			setList(updatedSettings);

			const data = await getDropdownData();
			setDropDowns(data);
		}
	}, [drops]);

	const { data: dropDown } = useDropDowns('eGrantz');

	const eGrandData = dropDown?.[collegeId]?.map((x) => x.type);

	const CheckboxGroup = ({ indexOrg, title, data, hidden, stateData, setStateData, prefix }) => {
		const handleCheckboxChange = (categoryKey) => {
			const key = prefix + (categoryKey?._id || categoryKey);
			setStateData({
				...stateData,
				[key]: !stateData[key],
			});
		};

		const getDisplayText = (categoryKey) => {
			if (prefix === 'u') {
				return convertToNormalWords(categoryKey);
			}
			if (prefix === 'c') {
				return categoryKey?.slice(0, 1) + categoryKey?.slice(1, 100)?.toLowerCase();
			}

			return categoryKey?._id ? categoryKey.name : convertToNormalWords(categoryKey);
		};

		return (
			<div hidden={blocking[hidden]}>
				<div className='flex justify-center h-[80px] item-center border-2 border-indigo-300 rounded-lg p-3 m-1 w-[250px] shadow-xl ' style={{ backgroundColor: mediumBackgroundColors[indexOrg], color: 'white' }}>
					<h5 className='font-bold text-center' style={{ color: lightBackgroundColors[indexOrg] }}>
						{title}
					</h5>
				</div>

				<div className='border-2 border-indigo-300 rounded-lg p-3 m-1 w-[250px] shadow-xl' style={{ backgroundColor: darkBackgroundColors[indexOrg] }}>
					<div className='w-full  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1'>
						{data?.map((categoryKey, index) => (
							<label key={index} className='flex items-center space-x-2 p-2 border border-[#3498db] rounded-lg  shadow-md hover:border-[#2c3e50] hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer' style={{ backgroundColor: lightBackgroundColors[indexOrg] }}>
								<input type='checkbox' className='form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400' checked={stateData[prefix + (categoryKey?._id || categoryKey)]} onChange={() => handleCheckboxChange(categoryKey)} />
								<span className='text-lg text-gray-800 ml-2'>{getDisplayText(categoryKey)}</span>
							</label>
						))}
					</div>
				</div>
			</div>
		);
	};

	const categoryDatas = useMemo(() => {
		return Object.keys(stateData)?.map((key) => ({
			type: key[0],
			category: key.slice(1),
			value: stateData[key],
		}));
	}, [stateData]);

	const religionArray = useMemo(() => {
		return categoryDatas.filter((item) => item.type === 'z' && item.value)?.map((item) => item.category);
	}, [categoryDatas]);

	useEffect(() => {
		setSelectedDetails(religionArray);
	}, [religionArray]);

	const { data: getSelectedDetails = [] } = useQuery(['getSelectedDetails', selectedDetails], getSeletedDetails({ collegeId, date, collection: 'users', stateData }));

	const [itemsToDisplay, setItemsToDisplay] = useState([]);

	const postAccountsSettings = async (deleteData, _id) => {
		try {
			setLoading(true);
			const instance = await getAxiosTokenInstance();

			let resp;
			resp = await instance.post('/college/action/postSettings', {
				collegeId,
				type: 'dataBank',
				userId: user?._id,
				settings: currentList
					? {
							...list,
							[settingsModal]: currentList,
					  }
					: list,
				_id: drops?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				refetch();
				setSettingsModal(null);
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
			<Body>
				<div className='h-[100%] overflow-auto'>
					{loading || status === 'loading' ? (
						<LoadingAnimation dark />
					) : (
						<div>
							<div className='flex justify-center w-full bg-gradient-to-r from-red-100 to-red-200 p-6 bg-blue-50'>
								<div className='w-full grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-3'>
									<Button width='100%' height='80%' type='close' onClick={onClose}>
										Close
									</Button>
									<Input width='100%' fieldName='Start Date' state={date} setState={setDate} returnKey='startDate' type='date' />
									<Input width='100%' fieldName='End Date' state={date} setState={setDate} returnKey='endDate' type='date' />

									{[
										{ label: 'Type of Users', key: 'type' },
										{ label: 'Nature of Users', key: 'nature' },
										{ label: 'Disabilities', key: 'disabilities' },
										{ label: 'State', key: 'state' },
										{ label: 'Reservation Category', key: 'reservationCategory' },
										{ label: 'Religion', key: 'religion' },
										{ label: 'Category', key: 'category' },
										{ label: 'Family Income', key: 'familyIncome' },
										{ label: 'Stay', key: 'stay' },
										{ label: 'Gender', key: 'gender' },
										{ label: 'E Grand Category', key: 'eGrandCategory' },
										{ label: 'Designation Status', key: 'designationStatus' },
										{ label: 'Second Language', key: 'secondLanguage' },
										{ label: 'Annual Income', key: 'annualIncome' },
										{ label: 'Designation', key: 'designation' },
										{ label: 'Colleges', key: 'colleges' },
										{ label: 'Department', key: 'department' },
										{ label: 'Batches', key: 'batches' },
										{ label: 'Classes', key: 'classes' },
										{ label: 'Caste', key: 'caste' },
										{ label: 'Poverty Line', key: 'provertyline' },
									]?.map(({ label, key }, indexOrg) => (
										<label
											key={key}
											className={`flex items-center space-x-2 p-3 border rounded-xl shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer`}
											style={{
												borderColor: lightBackgroundColors[indexOrg],
												backgroundColor: lightBackgroundColors[indexOrg],
												color: mediumBackgroundColors[indexOrg],
											}}
											onMouseEnter={(e) => {
												e.currentTarget.style.borderColor = mediumBackgroundColors[indexOrg];
												e.currentTarget.style.backgroundColor = mediumBackgroundColors[indexOrg];
												e.currentTarget.querySelector('span').style.color = 'white';
											}}
											onMouseLeave={(e) => {
												e.currentTarget.style.borderColor = lightBackgroundColors[indexOrg];
												e.currentTarget.style.backgroundColor = lightBackgroundColors[indexOrg];
												e.currentTarget.querySelector('span').style.color = mediumBackgroundColors[indexOrg];
											}}>
											<input type='checkbox' className='form-checkbox h-6 w-6 text-teal-600 focus:ring-teal-500' checked={!blocking[key]} onChange={() => setBlocking((prev) => ({ ...prev, [key]: !prev[key] }))} />
											<span className='font-semibold'>{label}</span>
										</label>
									))}
									<Button width='100%' height='80%' type='save' onClick={resetStateData}>
										Reset
									</Button>
								</div>
							</div>
							<div className='flex place-content-evenly  mt-2 mb-2 p-4 bg-white from-blue-50 to-blue-100 rounded-lg shadow-md'>
								<label className='flex justify-center items-center'>
									<span className='text-blue-800 text-2xl font-medium mr-2'>Total Count:</span>
									<span className='text-white bg-blue-600 px-4 py-2 rounded-full text-5xl font-bold shadow-lg'>{count}</span>
									<Button
										type='download'
										width='100%'
										height='80%'
										onClick={() => {
											setExportModal(true);
										}}>
										Export {count} Details
									</Button>
								</label>
								<label className='flex justify-center items-center justify-self-end'>
									<Button
										color='#3480eb'
										onClick={() => {
											setSettingsModal('students');
											setCurrentList(list['students']);
										}}>
										Settings
									</Button>
								</label>
							</div>

							<div className='relative overflow-x-auto flex space-x-4 py-4 border border-blue-500 rounded-lg bg-white shadow-md scrollable-container'>
								<div className='flex flex-nowrap space-x-4 '>
									<CheckboxGroup hidden='type' indexOrg={0} title='Type of Users' data={['scholars', 'student', 'teacher', 'staff']} stateData={stateData} setStateData={setStateData} prefix='u' />
									<CheckboxGroup hidden='nature' indexOrg={1} title='Nature of Users' prefix='a' data={activeStatusData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='disabilities' indexOrg={3} title='Disabilities' prefix='w' data={disabilitiesData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='gender' indexOrg={2} title='Gender' prefix='g' data={genderData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='state' indexOrg={4} title='State' prefix='s' data={stateDataUsers} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='reservationCategory' indexOrg={5} title='Reservation Category' prefix='q' data={reservationData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='eGrandCategory' indexOrg={6} title='E Grand Category' prefix='e' data={eGrandData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='religion' indexOrg={7} title='Religion' prefix='r' data={religionData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='category' indexOrg={8} title='Category' prefix='t' data={categoryData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='familyIncome' indexOrg={9} title='Family Income' prefix='f' data={incomeDrop} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='stay' indexOrg={10} title='Stay' prefix='h' data={stayData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='secondLanguage' indexOrg={11} title='Second Language' prefix='i' data={secondLanguageData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='provertyline' indexOrg={12} title='Proverty Line' prefix='j' data={provertyLineData} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='annualIncome' indexOrg={13} title='Annual Income' prefix='o' data={annualIncomeData} stateData={stateData} setStateData={setStateData} />
									{!stateData.ustudent && (
										<>
											<CheckboxGroup hidden='designation' indexOrg={14} title='Designation' prefix='m' data={designationStatusData} stateData={stateData} setStateData={setStateData} />
											<CheckboxGroup hidden='designationStatus' indexOrg={15} title='Designation Status' prefix='n' data={designationData} stateData={stateData} setStateData={setStateData} />
										</>
									)}
									<CheckboxGroup hidden='colleges' indexOrg={16} title='Colleges' prefix='y' data={getAditionalStati?.collegeAll} stateData={stateData} setStateData={setStateData} />
									<CheckboxGroup hidden='department' indexOrg={17} title='Department' prefix='d' data={getAditionalStati?.department} stateData={stateData} setStateData={setStateData} />
									{stateData.ustudent && (
										<>
											<CheckboxGroup hidden='batches' indexOrg={18} title='Batches' prefix='b' data={getAditionalStati?.batches} stateData={stateData} setStateData={setStateData} />
											<CheckboxGroup hidden='classes' indexOrg={19} title='Classes' prefix='l' data={getAditionalStati?.classes} stateData={stateData} setStateData={setStateData} />
										</>
									)}
									<CheckboxGroup hidden='caste' indexOrg={20} title='Caste' prefix='c' data={casteData} stateData={stateData} setStateData={setStateData} />
								</div>
							</div>
						</div>
					)}
				</div>
			</Body>
			{exportModal && (
				<Main width='95%' height='95%'>
					<Header>
						<div className='w-full grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-3'>
							<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleItems} value=''>
								{itemsToDisplay?.map((subject, index) => (
									<option key={index} value={subject?._id}>
										{subject?.name}
									</option>
								))}
							</select>

							<Button type='print' tableRef={ref.current}>
								Print
							</Button>
							<Button type='excel' tableRef={ref.current}>
								Excel
							</Button>
							<Button width='100%' height='80%' type='close' onClick={() => setExportModal(false)}>
								Close
							</Button>
						</div>
					</Header>

					<div className='flex flex-col w-full'>
						<div className='flex flex-col w-full bg-gradient-to-r from-red-100 to-red-200 p-1 space-y-6'>
							<div className='relative overflow-x-auto flex space-x-4 py-4 border border-blue-500 rounded-lg bg-white shadow-md'>
								<div className='flex flex-nowrap space-x-4'>
									<Body height='80vh' width='10vw'>
										<CheckboxGroup hidden='basicDetails' indexOrg={11} title='Basic Details' prefix='z' data={basicKey} stateData={stateData} setStateData={setStateData} />
										<CheckboxGroup hidden='officialDetails' indexOrg={19} title='Official Details' prefix='z' data={officialKey} stateData={stateData} setStateData={setStateData} />
										<CheckboxGroup hidden='address' indexOrg={20} title='Address' prefix='z' data={addressKey} stateData={stateData} setStateData={setStateData} />
										<CheckboxGroup hidden='qualifications' indexOrg={2} title='Qualifications' prefix='z' data={qualifications} stateData={stateData} setStateData={setStateData} />
									</Body>
									<Body height='80vh' width='90vw'>
										<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
											<table className='min-w-full table-auto'>
												<thead>
													<tr>
														<td className='p-1 bg-white' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
															<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className='p-1 text-center text-black bg-white text-xl font-bold' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
															Seleted users count: {count}
														</td>
													</tr>
													<tr>
														<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
															STATISTICS REPORT FROM NECTTOS @ {moment(new Date()).format('DD-MM-YYYY hh:mm A')}
														</td>
													</tr>
												</tbody>
											</table>

											<Table width={`${selectedDetails && selectedDetails?.length * 200 + 50}px`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
												<Thead>
													<Tr>
														<Th width='50px' position={0}>
															Sl.No.
														</Th>
														{selectedDetails?.map((x) => (
															<Th width='200px' position={1}>
																{x?.toUpperCase()}
															</Th>
														))}
													</Tr>
												</Thead>
												<Tbody height='auto'>
													{getSelectedDetails?.map((data, i) => (
														<Tr>
															<Td index={i} width='50px' position={0}>
																{i + 1}
															</Td>
															{selectedDetails?.map((x) => (
																<Td index={i} width='200px' position={5}>
																	{data[x]}
																</Td>
															))}
														</Tr>
													))}
												</Tbody>
											</Table>
										</div>
									</Body>
								</div>
							</div>
						</div>
					</div>
				</Main>
			)}
			{settingsModal && (
				<Main width='95%' height='95%' bgColor='white'>
					<Header>
						<div className='w-full flex flex-col overflow-auto ' style={{ height: 'calc(100vh - 100px)' }}>
							<div className='flex justify-end'>
								<Button height='80%' type='save' color='green' onClick={postAccountsSettings}>
									Save
								</Button>
								<Button height='80%' type='close' color='red' onClick={() => setSettingsModal(false)}>
									Close
								</Button>
							</div>
							<div className='flex flex-row justify-between mb-3'>
								<div className='flex justify-start'>
									<Button
										color={settingsModal !== 'students' ? 'orange' : 'green'}
										onClick={() => {
											setSettingsModal('students');
											currentList &&
												setList({
													...list,
													[settingsModal]: currentList,
												});
											setCurrentList(list['students']);
										}}>
										Students
									</Button>{' '}
									<Button
										color={settingsModal !== 'teachers' ? 'orange' : 'green'}
										onClick={() => {
											setSettingsModal('teachers');
											currentList &&
												setList({
													...list,
													[settingsModal]: currentList,
												});
											setCurrentList(list['teachers']);
										}}>
										Teachers
									</Button>{' '}
									<Button
										color={settingsModal !== 'staff' ? 'orange' : 'green'}
										onClick={() => {
											setSettingsModal('staff');
											currentList &&
												setList({
													...list,
													[settingsModal]: currentList,
												});
											setCurrentList(list['staff']);
										}}>
										Staff
									</Button>
									<Button
										color={settingsModal !== 'scholars' ? 'orange' : 'green'}
										onClick={() => {
											setSettingsModal('scholars');
											setCurrentList(list['scholars']);
										}}>
										Scholars
									</Button>
								</div>
								<div className='flex justify-end '>
									<Button color='green' onClick={() => setBucketModal(list)}>
										+ Bucket
									</Button>
								</div>
							</div>
							<CustomKey setCurrentList={setCurrentList} currentList={currentList} settingsModal={settingsModal} isBucket={false} dropdown={dropDowns} />
						</div>
					</Header>
				</Main>
			)}
			{bucketModal && (
				<Main width='95%' height='95%' bgColor='white'>
					<Header>
						<div className='w-full flex flex-col overflow-auto' style={{ height: 'calc(100vh - 100px)' }}>
							<div className='flex justify-end'>
								<Button height='80%' type='close' color='red' onClick={() => setBucketModal(false)}>
									Close
								</Button>
							</div>
							<div className='flex justify-start'>
								<Button
									height='80%'
									type='save'
									color='green'
									onClick={() => {
										setBucketModal(false);

										setList({
											...list,
											[settingsModal]: currentList,
										});
									}}>
									Save
								</Button>
							</div>
							<CustomKey setCurrentList={setCurrentList} currentList={currentList} settingsModal={settingsModal} isBucket={true} />
						</div>
					</Header>
				</Main>
			)}
		</Main>
	);
};
