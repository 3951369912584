import React, { useEffect, useState } from 'react';
import { EditDetails, getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, widthChangeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
// import { ProgressBar, calculateProfilepPercentage } from '../../../NecttosComp/ProgressBar/ProgressBar';
import Loader from '../../NecttosComp/Loader/Loader';
import moment from 'moment';
import { ProfileDataDisplayKeyVlaue, ProgressBar, calculateProfilepPercentage } from '../NewProfile';

interface Props {
	onClose: () => void;
	onNext: () => void;
	studentId?: string | null | undefined;
	userRole?: string;
	modal?: Boolean;
}

function PersonalInfo({ onClose, studentId, onNext, modal }: Props) {
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { data: profileData, refetch } = useProfileData();

	const [values, setValues] = useState<any>({});
	const [val, setVal] = useState({ dob: moment('22/10/2024', 'DD-MM-YYYY').format('YYYY-MM-DD') });

	useEffect(() => {
		setValues({
			name: profileData.pendingData?.name || profileData.name || '',
			dob: profileData.pendingData?.dob || profileData.dob || '',
			gender: profileData.pendingData?.gender || profileData.gender || '',
			annualIncome: profileData.pendingData?.annualIncome || profileData.annualIncome || '',
			provertyLine: profileData.pendingData?.provertyLine || profileData.provertyLine || '',
			disabilities: profileData.pendingData?.disabilities || profileData.disabilities || '',
			meritalStatus: profileData.pendingData?.meritalStatus || profileData.meritalStatus || '',
			email: profileData.pendingData?.email || profileData.email || '',
			bloodGroup: profileData.pendingData?.bloodGroup || profileData.bloodGroup || '',
			aadhaarNumber: profileData.pendingData?.aadhaarNumber || profileData.aadhaarNumber || '',
			category: profileData.pendingData?.category || profileData.category || '',
			caste: profileData.pendingData?.caste || profileData.caste || '',
			religion: profileData.pendingData?.religion || profileData.religion || '',
		});
	}, [profileData]);

	const [categoryData, setCategoryData] = useState([]);
	const [casteData, setCasteData] = useState([]);
	const [selectedReligion, setSelectedReligion] = useState('');

	const onReligionSelect = ({ value: item }: { value: string }) => {
		setSelectedReligion(item);
		setCasteData([]);
		setCategoryData(dropDowns[2].RELIGION[0][item + 'RESERVATIONCATEGORY']);
		return setValues({ ...values, religion: item, category: '', caste: '' });
	};

	const onCategorySelect = ({ value: item }: { value: string }) => {
		setCasteData(dropDowns[2]?.RELIGION?.[0]?.[selectedReligion + 'CAST']?.[0]?.[item]);
		return setValues({ ...values, category: item, caste: '' });
	};

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	const [placeHoldValues, setPlaceHolderValues] = useState<{}>({});
	let userRole: string = '';
	type CollegeRole = {
		type: string;
	};
	const rolesArray: string[] = ['student', 'teacher', 'staff', 'admin'];
	userRole = rolesArray.find((element) => profileData.collegeRoles.some((x: CollegeRole) => x.type === element)) || userRole;
	const staffKeys = {
		name: 'Name',
		dob: 'Date of Birth',
		gender: 'Gender',
		bloodGroup: 'Blood Group',
		disabilities: 'Disabilities',
		meritalStatus: 'Marital Status',
		email: 'Email',
		aadhaarNumber: 'Aadhaar Number',
		religion: 'Religion',
		category: 'Category',
		caste: 'Caste',
	};

	const studentsKeys = {
		name: 'Name',
		dob: 'Date of Birth',
		gender: 'Gender',
		bloodGroup: 'Blood Group',
		disabilities: 'Disabilities',
		meritalStatus: 'Marital Status',
		provertyLine: 'Proverty Line',
		annualIncome: 'Annual Income',
		email: 'Email',
		aadhaarNumber: 'Aadhaar Number',
		religion: 'Religion',
		category: 'Category',
		caste: 'Caste',
	};

	const teacherKeys = {
		name: 'Name',
		dob: 'Date of Birth',
		gender: 'Gender',
		bloodGroup: 'Blood Group',
		disabilities: 'Disabilities',
		meritalStatus: 'Marital Status',
		email: 'Email',
		aadhaarNumber: 'Aadhaar Number',
		religion: 'Religion',
		category: 'Category',
		caste: 'Caste',
	};

	useEffect(() => {
		if (userRole === 'student') {
			setPlaceHolderValues(studentsKeys);
		} else if (userRole === 'teacher') {
			setPlaceHolderValues(teacherKeys);
		} else if (userRole === 'staff') {
			setPlaceHolderValues(staffKeys);
		} else {
			setPlaceHolderValues(staffKeys);
		}
	}, [userRole]);

	useEffect(() => {
		if (values?.dob && !moment(values.dob, 'YYYY-MM-DD', true).isValid()) {
			setValues((prev: any) => ({
				...prev,
				dob: moment(prev.dob, 'DD MMM YYYY').format('YYYY-MM-DD'),
			}));
		}
	}, [values.dob]);

	console.log({ values });

	return (
		<main className='w-full h-full bg-white relative'>
			<div className='absolute right-0 top-0 z-10 flex'>
				{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
				<Button type='close' onClick={onClose} children='Close' />
			</div>
			<section className='flex justify-around w-full h-full box-border p-0'>
				<article className='w-full h-full flex flex-col justify-center items-center p-8'>
					<ProfileDataDisplayKeyValue temp={profileData} placeHoldValues={placeHoldValues} title='Verified Data' progressPercentage={parseInt(calculateProfilepPercentage(profileData, placeHoldValues)?.percentage) || 0} />
					<div className='flex w-full justify-center gap-2 mt-2'>
						{!isEditing ? (
							<Button
								onClick={async () => {
									setIsEditing(!isEditing);
								}}
								type='update'
								width='10vw'>
								Edit
							</Button>
						) : null}
						<Button type='close' onClick={onClose} children='Next' width='10vw' />
					</div>
				</article>
				<article className='w-full h-full  p-8'>
					{isEditing && (
						<div className='shadow p-2 w-full flex flex-col items-center rounded h-full mb-1 relative' style={{ backgroundColor: 'white', color: 'black' }}>
							<div className=' w-full p-4'>
								<h6 className='text-blue-900 text-[1.2vw] font-semibold'>You can edit your data here !</h6>
								<ProgressCircle progressPercentage={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
								{/* <ProgressBar title='Updated Data' progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} /> */}
							</div>
							<div id='scrollBarEnable' className='px-1 py-1 max-w-[40vw] w-full z-50 overflow-auto  max-h-[62vh]'>
								<Input width='98%' fieldName='Name' returnKey='name' setState={setValues} state={values} type='text' />
								<Input width='98%' fieldName='Date of Birth' returnKey='dob' setState={setValues} state={values} type='date' />
								<Input width='98%' fieldName='Gender' returnKey='gender' options={dropDowns[0]?.gender} setState={setValues} state={values} type='drop' />
								<div className='relative z-[7]'>
									<Input width='98%' fieldName='Blood Group' returnKey='bloodGroup' options={dropDowns[1]?.bloodGroup} setState={setValues} state={values} type='drop' />
								</div>
								<div className='relative z-[5]'>
									<Input width='98%' fieldName='Merital Status' returnKey='meritalStatus' options={dropDowns[9]?.meritalStatus} setState={setValues} state={values} type='drop' />
								</div>
								<Input width='98%' fieldName='Email' returnKey='email' setState={setValues} state={values} type='email' />
								<Input width='98%' maxLength={12} fieldName='Aadhaar Number' returnKey='aadhaarNumber' setState={setValues} state={values} type='text' />
								<div className='relative z-[6]'>
									<Input width='98%' fieldName='Disabilities' returnKey='disabilities' options={['Yes', 'No']} setState={setValues} state={values} type='drop' />
								</div>
								{userRole === 'student' && (
									<>
										<div className='relative z-[4]'>
											<Input width='98%' fieldName='Proverty Line' returnKey='provertyLine' options={dropDowns[13]?.provertyLine} setState={setValues} state={values} type='drop' />
										</div>
										<div className='relative z-[3]'>
											<Input width='98%' fieldName='Annual Income' returnKey='annualIncome' options={dropDowns[12]?.income} setState={setValues} state={values} type='drop' />
										</div>
									</>
								)}
								<div className='relative z-[2]'>
									<Input width='98%' onChange={onReligionSelect} fieldName='Religion' returnKey='religion' options={dropDowns[2]?.RELIGION[0]?.RELIGIONNAME} setState={setValues} state={values} type='drop' />
								</div>
								<div className='relative z-[1]'>
									<Input width='98%' onChange={onCategorySelect} fieldName='Category' returnKey='category' options={categoryData} setState={setValues} state={values} type='drop' />{' '}
								</div>
								<div className=' relative z-[0]'>
									<Input width='98%' fieldName='Caste' returnKey='caste' options={casteData} setState={setValues} state={values} type='drop' />{' '}
								</div>
							</div>

							<div className='flex justify-center w-full h-auto gap-2 mt-2'>
								<Button
									width='100%'
									onClick={async () => {
										setIsEditing(!isEditing);
										setIsLoading(false);
									}}
									type='refresh'
									children='Cancel'
								/>
								<Button
									width='100%'
									onClick={async () => {
										setIsLoading(true);
										await EditDetails(values);
										await refetch();
										setIsEditing(!isEditing);
										setIsLoading(false);
									}}
									type='save'
									children='Save'
								/>
							</div>
						</div>
					)}
				</article>
			</section>
			{isLoading && <Loader />}
		</main>
	);
}

export default PersonalInfo;

export const ProfileDataDisplayKeyValue: React.FC<any> = ({ temp, placeHoldValues, title, needVerification, progressPercentage }) => {
	return (
		<div className='p-4 bg-white rounded-lg shadow-lg relative w-full h-full justify-center items-center'>
			{/* Progress Circle */}
			<div className='absolute top-4 right-4'>
				<svg className='w-14 h-14'>
					<circle className='text-gray-300' strokeWidth='4' stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
					<circle className='text-blue-600' strokeWidth='4' strokeLinecap='round' strokeDasharray='150' strokeDashoffset={150 - (progressPercentage / 100) * 150} stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
				</svg>
				<span className='absolute inset-0 flex items-center justify-center text-sm font-semibold text-blue-900'>{progressPercentage}%</span>
			</div>

			<h5 className='text-lg font-semibold text-blue-900 mb-3 mt-3'>{title}</h5>
			<div id='scrollBarEnable' className='px-4 py-3 w-full overflow-auto max-h-[68vh] space-y-2'>
				{Object.entries(placeHoldValues).map(([key, label]: any) => {
					if (!temp?.[key]) return null;

					return (
						<div key={key} className='flex flex-col bg-gray-50 rounded-lg shadow-md p-3 min-w-[250px]  w-full border border-gray-200'>
							<label className='text-xs font-medium text-gray-600 mb-1 capitalize'>{label}:</label>
							<p className='text-sm font-semibold text-gray-800'>{temp[key] || '—'}</p>
						</div>
					);
				})}
			</div>
			{needVerification && <div className='mt-4 p-2 text-center text-[#a33333] text-xs font-light border-t border-gray-200'>Please wait while your updated data undergoes verification.</div>}
		</div>
	);
};
export const ProgressCircle: React.FC<any> = ({ progressPercentage }) => {
	return (
		<div className='absolute top-4 right-4'>
			<svg className='w-14 h-14'>
				<circle className='text-gray-300' strokeWidth='4' stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
				<circle className='text-blue-600' strokeWidth='4' strokeLinecap='round' strokeDasharray='150' strokeDashoffset={150 - (progressPercentage / 100) * 150} stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
			</svg>
			<span className='absolute inset-0 flex items-center justify-center text-sm font-semibold text-blue-900'>{progressPercentage}%</span>
		</div>
	);
};
