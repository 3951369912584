import React, { useContext, useEffect, useRef, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import { naacGET } from '../../NecttosComp/APICaller/APICaller';
import MissingDataFinder from '../../NecttosComp/MissingDataFinder/MissingDataFinder';

function C2_4({ onClose }) {
	const [loading, setLoading] = useState(false);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [analyze, setAnalyze] = useState();
	const ref = useRef(null);
	const { collegeId } = useContext(FirebaseContext);

	const { data: values = [] } = useQuery(['criteria2_4', academicYear], naacGET({ endPoint: 'criteria2_4', params: { collegeId, academicYear } }));

	return (
		<>
			{loading ? (
				<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
					<LoadingAnimation />
				</div>
			) : null}

			<Main title='2.1.1 Enrolment Number' width='80vw' height='80vh'>
				<Header>
					<div className='flex'>
						<AcademicYears setAcademicYear={setAcademicYear} />
					</div>
					<div className='flex'>
						<Button type='update' onClick={() => setAnalyze(true)}>
							Analyze
						</Button>
						<Button pdfId='emp' type='pdf'>
							PDF
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div className='h-[60vh] printable overflow-auto' ref={ref}>
						<Table width='100%' innerWidth={['25%', '25%', '25%', '25%']}>
							<Thead>
								<Tr>
									<Th position={0}>Programme name</Th>
									<Th position={1}>Programe code</Th>
									<Th position={2}>number of seats sanctioned</Th>
									<Th position={3}>Number of students admitted</Th>
								</Tr>
							</Thead>
							<Tbody height='60vh'>
								{values?.map((x, i) => (
									<Tr>
										<Td position={0} index={i}>
											{x?.programName}
										</Td>
										<Td position={1} index={i}>
											{x?.programCode}
										</Td>
										<Td position={2} textAlign={'center'} index={i}>
											{x?.numberOfSeatSanctioned}
										</Td>
										<Td position={3} textAlign={'center'} index={i}>
											{x?.numberOfStudentAdmitted}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
			</Main>
			{analyze && (
				<MissingDataFinder
					data={values}
					onClose={() => setAnalyze(false)}
					values={[
						{ importance: true, name: 'programName', title: 'Programme name' },
						{ importance: true, name: 'programCode', title: 'Programe code' },
						{ importance: true, name: 'numberOfSeatSanctioned', title: 'number of seats sanctioned' },
						{ importance: true, name: 'numberOfStudentAdmitted', title: 'Number of students admitted' },
					]}
				/>
			)}
		</>
	);
}

export default C2_4;
