import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getAxiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Cookies from "js-cookie";

interface RegisterProps {
  setLogin: (val: boolean) => void;
  collegeData: {
    collegeName: string;
    lastApplicationNo: string;
    logo: string;
  };
  initCollegeId: string;
  login?: boolean;
}

interface FilledData {
  mobile: string;
  name: string;
  houseName: string;
  place: string;
  postOffice: string;
  pinCode: string;
  plusTwoDegreeStream: string;
  coursePreference: string;
}

export const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId, year, optionalSubjects, type] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getClasses", {
      params: { collegeId, year, type },
    });
    if (data?.statusCode === 200) {
      return data.list;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

export const getDepartment = async ({ queryKey }: any) => {
  const [_key, collegeId, year, optionalSubjects, type] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getClasses", {
      params: { collegeId, year, type },
    });
    if (data?.statusCode === 200) {
      return data.list;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};


const Register: React.FC<RegisterProps> = ({ collegeData, initCollegeId, login, setLogin }) => {
  const [isSendOtp, setSendOtp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasAccount, setHasAccount] = useState<boolean>(login || false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [otpFull, setOtpFull] = useState<string>("");
  const [confirm, setConfirm] = useState<string | null>(null);

  const [errror, setError] = useState(null);
  const [blocked, setBlocked] = useState(null);

  const [filledData, setFilledData] = useState<FilledData>({
    mobile: "",
    name: "",
    houseName: "",
    place: "",
    postOffice: "",
    pinCode: "",
    plusTwoDegreeStream: "",
    coursePreference: "",
  });

  const params = useParams<{ id: string; type: string }>();
  const { data: classes } = useQuery([`collegeData`, initCollegeId, params?.type?.toUpperCase() === "UG" ? "2024-2028" : "2024-2026"], getCollegeDetails);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const verifyOtp = async (otp: string) => {
    setIsLoading(true);
    const instance = await getAxiosInstance();
    instance
      .post(`/auth/newAdmission-verify-otp`, {
        token: confirm,
        otp,
        collegeId: initCollegeId,
        ...filledData,
      })
      .then(async (response) => {
        Cookies.set("userToken", response.data.jwtToken);
        localStorage.setItem("collegeId", JSON.stringify(response.data.collegeId));
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            phoneNumber: response.data.phoneNumber,
            username: response.data.userName,
            displayName: response.data.userName,
            uid: response.data.uid,
          })
        );
        setLogin(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  const sendOtp = async () => {
    const instance = await getAxiosInstance();
    if (filledData.mobile) {
      instance
        .post(`/auth/newAdmission-sent-otp`, {
          phoneNumber: `+91${filledData.mobile}`,
          collegeId: initCollegeId,
        })
        .then((response) => {
          if (response?.data?.loginBlocked !== true) {
            setError(null);
            const elem = document.getElementById("loginForm");
            if (elem) {
              elem.style.height = "350px";
              const otp = document.getElementById("otp");
              if (otp) {
                otp.style.display = "flex";
              }
            }
            let second = 300;
            let intervalId = setInterval(() => {
              second = second - 1;
              setSendOtp(second);
              if (second <= 0) {
                clearInterval(intervalId);
              }
            }, 1000);
            setConfirm(response.data.token);

            toast.success("OTP Sended");
          } else {
            setBlocked(response?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
        });
    } else {
      toast.error("Please give Phone number");
    }
  };

  const sendOtpTwo = async () => {
    const instance = await getAxiosInstance();
    if (filledData.mobile) {
      instance
        .post(`/auth/copyPaste-sent-otp`, {
          phoneNumber: `+91${filledData.mobile}`,
          collegeId: initCollegeId,
        })
        .then((response) => {
          setError(null);
          const elem = document.getElementById("loginForm");
          if (elem) {
            elem.style.height = "350px";
            const otp = document.getElementById("otp");
            if (otp) {
              otp.style.display = "flex";
            }
          }
          let second = 300;
          let intervalId = setInterval(() => {
            second = second - 1;
            setSendOtp(second);
            if (second <= 0) {
              clearInterval(intervalId);
            }
          }, 1000);
          setConfirm(response.data.token);
          toast.success("OTP Sended");
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
          toast.error("Number Already Exist");
        });
    } else {
      toast.error("Please give Phone number");
    }
  };

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newOtp.every((value) => value.length === 1)) {
      setOtpFull(newOtp.join(""));
      verifyOtp(newOtp.join(""));
    }
  };
  const handlePaste = (e: { preventDefault: () => void; clipboardData: { getData: (arg0: string) => string } }) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("");
    if (pasteData.length === 6) {
      setOtp(pasteData);
      pasteData.forEach((value: string, index: number) => {
        inputRefs.current[index].value = value; // Directly setting value of input elements
        if (index === 5) {
          console.log("Pasted OTP is:", pasteData.join(""));
        }
      });
    }
  };
  const styleNmae = "block w-full px-4 py-3 mb-2 text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ";
  const plusTwoDegreeStreams = ["Commerce", "Humanities", "Science", "Others"];
  const degreeStreams = ["Arts & Humanities", "Science", "Commerce & Management", "Engineering & Technology", "Health & Life Sciences", "Education", "Law", "Social Sciences", "Computer Science & IT", "Environmental Science", "Performing Arts", "Visual Arts & Design", "Agricultural Sciences", "Hospitality & Tourism", "Media & Communication"];

  const ug = [
    { id: "1", name: "B.Sc. Physics (Honours)" },
    { id: "2", name: "B.Sc. Chemistry (Honours)" },
    { id: "3", name: "B.Sc. Botany (Honours)" },
    { id: "4", name: "B.Sc. Zoology (Honours)" },
    { id: "5", name: "B.Sc. Mathematics (Honours)" },
    { id: "6", name: "B.Com (Honours)" },
    { id: "7", name: "B.A Economics (Honours)" },
    { id: "8", name: "B.A Malayalam (Honours)" },
    { id: "9", name: "B.A Hindi (Honours)" },
    { id: "10", name: "B.A English (Honours)" },
    { id: "11", name: "Bachelor of Computer Applications (BCA)" },
    { id: "12", name: "Bachelor of Travel & Tourism Management (Honours)" },
    { id: "13", name: "B.Voc. Logistic Management" },
    { id: "14", name: "Integrated M.Sc. Computer Science-Data Science (Integrated Programme)" },
  ];

  const pg = [
    { id: "1", name: "M.Sc. Chemistry" },
    { id: "2", name: "M.Sc. Statistics" },
    { id: "3", name: "M.Sc. Zoology" },
    { id: "4", name: "M.A. Economics" },
    { id: "5", name: "M.A. Malayalam" },
    { id: "6", name: "M.A. Hindi" },
    { id: "7", name: "M.A. English" },
    { id: "8", name: "M.Com. Finance and Taxation" },
    { id: "9", name: "Master of Computer Applications (MCA)" },
    { id: "10", name: "M.A. Human Resource Management (MA HRM)" },
    { id: "11", name: "Master of Tourism and Travel Management (ΜΤΤΜ)" },
  ];

  function sortCoursesAlphabetically(courses: any) {
    return courses.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));
  }

  const sortedUg = sortCoursesAlphabetically(ug);

  const sortedPg = sortCoursesAlphabetically(pg);

  const [selectAll, setSelectAll] = useState(false);
  const [openQuota, setOpenQuota] = useState(false);
  const [sportsQuota, setSportsQuota] = useState(false);
  const [communityQuota, setCommunityQuota] = useState(false);
  const [managementQuota, setManagementQuota] = useState(false);

  const handleCheckboxChange = (setter: (arg0: any) => void) => {
    return (e: { target: { checked: any } }) => {
      setter(e.target.checked);
    };
  };

  useEffect(() => {
    setOpenQuota(selectAll);
    setSportsQuota(selectAll);
    setCommunityQuota(selectAll);
    setManagementQuota(selectAll);
  }, [selectAll]);

  useEffect(() => {
    if (openQuota && sportsQuota && communityQuota && managementQuota) {
      setSelectAll(true);
    } else if (!openQuota && !sportsQuota && !communityQuota && !managementQuota) {
      setSelectAll(false);
    }
  }, [openQuota, sportsQuota, communityQuota, managementQuota]);

  if ([""]?.includes(initCollegeId) && ["UG"]?.includes(params?.type?.toUpperCase())) {
    return (
      <div className="mx-auto max-w-lg p-6 bg-red-100 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold text-red-700">Attention Applicants!</h2>
        <p>The registration for undergraduate courses is currently on hold for rank preparation. Please check back later or contact the college directly for further information. </p>
      </div>
    );
  }

  if ([""]?.includes(initCollegeId) && ["PG"]?.includes(params?.type?.toUpperCase())) {
    return (
      <div className="mx-auto max-w-lg p-6 bg-red-100 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-bold text-red-700">Attention Applicants!</h2>
        <p>The registration for post-graduate courses is currently on hold for rank preparation. Please check back later or contact the college directly for further information. </p>
      </div>
    );
  }

  return (
    <div className="max-w-lg  mx-auto p-6 bg-[#ffffff] rounded-lg shadow-lg">
      {!hasAccount ? (
        <>
          <div className="flex justify-center">
            <img src={collegeData?.logo} alt="College Logo" className="w-40 h-40 object-contain" />
          </div>
          <h2 className="text-2xl font-nunito font-bold  text-center text-blue-800 ">{collegeData?.collegeName}</h2>
          <h2 className="text-2xl font-nunito font-bold text-center text-blue-800 ">{params.type.toUpperCase()} Online Application Form</h2>
          <h3 className="text-xl font-nunito font-bold text-center text-blue-400 mb-8">Application no : {collegeData?.lastApplicationNo}</h3>
        </>
      ) : (
        <h2 className="text-2xl  font-nunito font-bold text-center text-gray-800 ">Login to your Account</h2>
      )}
      <div className="mb-6">
        <div className="flex items-center mb-4">
          <input id="existingAccount" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={(e) => setHasAccount(e.target.checked)} />
          <label htmlFor="existingAccount" className="ml-2 text-sm font-nunito font-medium text-gray-900 dark:text-gray-300">
            I already have an account
          </label>
        </div>
        {!hasAccount && (
          <>
            <label htmlFor="name" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
              Name (As per SSLC)
            </label>
            <input id="name" type="text" placeholder="Enter your Name" className={styleNmae} value={filledData.name} onChange={(e) => setFilledData({ ...filledData, name: e.target.value })} />
            <label htmlFor="place" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
              Place
            </label>

            <input id="place" type="text" placeholder="Enter your place" className={styleNmae} value={filledData.place} onChange={(e) => setFilledData({ ...filledData, place: e.target.value })} />
            <label htmlFor="plusTwoDegreeStream" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
              {params?.type?.toUpperCase() === "UG" ? "Plus Two Stream" : "Degree Stream"}
            </label>
            <select id="plusTwoDegreeStream" className={styleNmae} value={filledData.plusTwoDegreeStream} onChange={(e) => setFilledData({ ...filledData, plusTwoDegreeStream: e.target.value })}>
              <option value="">Select your stream</option>
              {(params?.type?.toUpperCase() === "UG" ? plusTwoDegreeStreams : degreeStreams).map((stream) => (
                <option key={stream} value={stream}>
                  {stream}
                </option>
              ))}
            </select>
            {params?.type?.toUpperCase() !== "MCA" && (
              <>
                <label htmlFor="pinCode" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
                  Pin Code
                </label>
                <input id="pinCode" type="number" placeholder="Enter your pin code" className={styleNmae} value={filledData.pinCode} onChange={(e) => setFilledData({ ...filledData, pinCode: e.target.value })} />

                <label htmlFor="coursePreference" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
                  Course Preference
                </label>

                <select id="coursePreference" className={styleNmae} value={filledData.coursePreference} onChange={(e) => setFilledData({ ...filledData, coursePreference: e.target.value })}>
                  <option value="">Select your preference</option>
                  {classes?.map((course: any) => (
                    <option key={course._id} value={course._id}>
                      {course.className}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}
        <label htmlFor="mobile" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
          Phone Number
        </label>
        <input min={1000000000} max={9999999999} id="mobile" type="number" placeholder="Enter your phone number" className={styleNmae} value={filledData.mobile} onChange={(e) => setFilledData({ ...filledData, mobile: e.target.value })} />

        {blocked ? (
          <p className="text-red-600 text-lg text-bold">{blocked}</p>
        ) : (
          <>
            {errror ? (
              <>
                <p className="text-red-600 text-lg text-bold">{errror}</p>
                <button onClick={sendOtpTwo} disabled={isLoading || isSendOtp > 0} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? "bg-blue-400" : "bg-blue-500 hover:bg-blue-600"}`}>
                  i need to Create your Account same this number
                </button>
              </>
            ) : (
              <button onClick={sendOtp} disabled={isLoading || isSendOtp > 0} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? "bg-blue-400" : "bg-blue-500 hover:bg-blue-600"}`}>
                {isLoading ? "Sending..." : hasAccount ? "Login" : "Register Now"}
              </button>
            )}
          </>
        )}
      </div>

      <div id="otp" className={`grid grid-cols-6 gap-4 ${isSendOtp > 0 ? "block" : "hidden"}`}>
        {otp.map((value, index) => (
          <input key={index} type="text" maxLength={1} className="w-full px-2 py-2 text-center text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" value={value} onChange={(e) => handleInputChange(index, e)} ref={(el) => (inputRefs.current[index] = el as HTMLInputElement)} />
        ))}
      </div>

      {isSendOtp > 0 && (
        <>
          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              OTP expires in: <span className="font-nunito font-semibold">{isSendOtp}</span> seconds
            </p>
          </div>

          <button onClick={() => verifyOtp(otpFull)} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? "bg-blue-400" : "bg-blue-500 hover:bg-blue-600"}`}>
            Verify OTP
          </button>
        </>
      )}
    </div>
  );
};

export default Register;
