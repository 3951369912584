import React, { useContext, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { Popconfirm } from 'antd';

interface invoiceManagementProps {
	onClose: () => void;
}

export const getAllStocks = (collegeId: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllStocks', {
			params: {
				collegeId,
			},
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function MasterStockRegister({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const [mode, setMode] = useState<any>('');

	const { collegeId, user } = useContext(FirebaseContext);

	const ref = useRef<any>(null);

	const { data: tableData = [], refetch } = useQuery('getAllStocks', getAllStocks(collegeId));

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteStock', { params: { collegeId, mode, _id } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const addNewForm = async () => {
		if (!addNew?.particulars || !addNew?.date) {
			toast.error('fill required data');
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postStock', { ...addNew, collegeId, updatedBy: user.uid, mode })
				.then(() => {
					toast.success('updated successfully');
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
					toast.error('update stock failed');
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			toast.error('cannot update');
			throw new Error('API Error');
		}
	};

	return (
		<>
			<Main title=' Master Stock Register' width='100vw' height='100vh'>
				<Toaster />
				<Header>
					<Button
						type='update'
						onClick={() => {
							setShowForm(true);
						}}>
						New Stock
					</Button>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%'>
              {/* 1570px */}
							<Thead>
								<Tr>
									<Th width={'60px'}>Sl.No.</Th>
									<Th width={'110px'}>Date</Th>
									<Th width={'200px'}>Particulurs (No. & Date of contigent Voucher, invoice etc) </Th>
									<Th width={'70px'}>Rate</Th>
									<Th width={'100px'}>Qty Purchased</Th>
									<Th width={'150px'}>Manufactural Sl No. of each equipments</Th>
									<Th width={'70px'}>Stock No</Th>
									<Th width={'70px'}>total</Th>
									<Th width={'70px'}>Nos. Issued</Th>
									<Th width={'90px'}>Balance</Th>
									<Th width={'100px'}>Issued to Whom</Th>
									<Th width={'100px'}>Department</Th>
									{/* <Th width={'150px'}>Signature of the recipients with seal</Th>
									<Th width={'150px'}>Signature of the Principal with seal</Th> */}
									<Th width={'180px'}>Actions</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableData?.map((x: any, i: number) => (
									<Tr>
										<Td index={i} width={'60px'}>
											{i + 1}
										</Td>
										<Td index={i} width={'110px'}>
											{x?.date && moment(x?.date).format('DD-mm-yyyy')}
										</Td>
										<Td index={i} width={'200px'}>
											{x?.particulars}
										</Td>
										<Td index={i} width={'70px'}>
											{x?.rate}
										</Td>
										<Td index={i} width={'100px'}>
											{x?.qtyPurchased}
										</Td>
										<Td index={i} width={'150px'}>
											{x?.manufacturalSerialNo}
										</Td>
										<Td index={i} width={'70px'}>
											{x?.stockNo}
										</Td>
										<Td index={i} width={'70px'}>
											{x?.total}
										</Td>
										<Td index={i} width={'70px'}>
											{x?.nosIssued}
										</Td>
										<Td index={i} width={'90px'}>
											{x?.balance}
										</Td>
										<Td index={i} width={'100px'}>
											{x?.issuedWhom}
										</Td>
										<Td index={i} width={'100px'}>
											{x?.department}
										</Td>
										{/* <Td index={i} width={'150px'}>
											{x?.signRecipients}
										</Td>
										<Td index={i} width={'150px'}>
											{x?.signPrincipal}
										</Td> */}
										<Td index={i} width={'180px'}>
											<div className='flex'>
												<Button
													type='update'
													onClick={() => {
														setAddNew(x);
														setShowForm(true);
													}}>
													Edit
												</Button>

												<Popconfirm
													placement='topLeft'
													title='Are you sure you want to delete?'
													okText='Yes'
													cancelText='No'
													onConfirm={() => {
														setMode('delete');
														handleDelete(x._id);
													}}>
													<Button type='delete'>Delete</Button>
												</Popconfirm>
											</div>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>

			{showForm && (
				<Main width='40vw' height='auto' title='Stock'>
					<Toaster />

					<Header>
						<div></div>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'>
							<Input width='100%' fieldName=' Date *' returnKey='date' state={addNew} setState={setAddNew} type='date' required />
							<Input width='100%' fieldName='Particulars *' returnKey='particulars' state={addNew} setState={setAddNew} type='text' required />
							<Input width='100%' fieldName='Rate' returnKey='rate' state={addNew} setState={setAddNew} type='text' required />
							<Input width='100%' fieldName='Qty Purchased' returnKey='qtyPurchased' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Manufactural Sl No. of each equipments' returnKey='manufacturalSerialNo' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Stock No' returnKey='stockNo' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Total' returnKey='total' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName=' Nos Issued' returnKey='nosIssued' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Balance' returnKey='balance' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Issued to whom ' returnKey='issuedWhom' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Department ' returnKey='department' state={addNew} setState={setAddNew} type='text' />
							{/* <Input width='100%' fieldName='Signature of the recipients with seal ' returnKey='signRecipients' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Signature of the principal with seal ' returnKey='signPrincipal' state={addNew} setState={setAddNew} type='text' /> */}

							<div className='flex justify-center mb-2'>
								<Button
									width='100px'
									type='save'
									onClick={() => {
										addNewForm();
									}}>
									Save
								</Button>
							</div>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default MasterStockRegister;
