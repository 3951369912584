
import { Select } from "antd";
import React from "react";
import classNames from "./addBookModal.module.scss";

function CustomDropDownKey({
  keyData,
  keyData2,
  stateData,
  setStateData,
  dropValues,
  titleData,
  keyObject,
  keyReturn2,
  keyReturn
}) {


  console.log({ stateData });
  const { Option } = Select;





  
  return (
    <div>
      <div style={{ margin: 5 }}>
        <div className={classNames.group} >
          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            value={stateData[keyData + 'val']}
            onChange={(val) => { setStateData({ ...stateData, [keyData + 'val']: val, [keyData]: ((dropValues?.find((x, i) => i + x[keyObject] === val))[keyReturn]) , [keyData2]: ((dropValues?.find((x, i) => i + x[keyObject] === val))[keyReturn2])}) }}>
            {dropValues?.map((item, i) => (
              <Option key={i} value={i + item[keyObject]}>
                {item[keyObject]}
              </Option>
            ))}
          </Select>
          <label>{titleData}</label>
        </div>
      </div>
    </div >
  )
}
export default CustomDropDownKey;

