import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getAdmissionRegister3, getBatches, getKeys, getNewClasses, getNextSequenceNumber, getProfile, getSettings, getStudentAttendance1 } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getCollegeDetailsee } from './RemoveAction';
import toast, { Toaster } from 'react-hot-toast';
import { FaCog } from 'react-icons/fa';
import { BarLoader, PulseLoader } from 'react-spinners';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Popconfirm, Progress, message } from 'antd';
import { getEditData } from '../Accounts/FeesBilling/feesManagementServices';
import { BillNumberModal2 } from '../Accounts/FeesBilling/components/BillNumberModal/PrintReceipt';
import dummyProfilePic from '../../NecttosAssets/Office/dummyProfilePic.png';

interface invoiceManagementProps {
	onClose: () => void;
}

function AdmissionRegister3({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [admn, setAdmn] = useState<any>();
	const [tcNo, setTcNo] = useState<any>();
	const [tcDate, setTcDate] = useState<any>();
	const { collegeId, user, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x: any) => x.collegeId === collegeId);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [selectedBatch, setSelectedBatch] = useState<any>({});
	const [selectModal, setSelectModal] = useState(false);
	const [iSearch, setISearch] = useState(true);
	const [advancedSearch, setAdvancedSearch] = useState('');

	const [advanced, setAdvanced] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [fieldsData, setFieldsData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);
	const [selectedFields, setSelectedFields] = useState<any>({
		scholars: [
			{
				_id: 'name',
				name: 'Full Name',
				shortName: 'Name',
				type: 'text',
				width: ['100px', '200px', '300px'],
				category: 'Basic Details',
			},
		],
		staff: [
			{
				_id: 'name',
				name: 'Full Name',
				shortName: 'Name',
				type: 'text',
				width: ['100px', '200px', '300px'],
				category: 'Basic Details',
			},
		],
		student: [
			{
				_id: 'name',
				name: 'Full Name',
				shortName: 'Name',
				type: 'text',
				width: ['100px', '200px', '300px'],
				category: 'Basic Details',
			},
		],
		teacher: [
			{
				_id: 'name',
				name: 'Full Name',
				shortName: 'Name',
				type: 'text',
				width: ['100px', '200px', '300px'],
				category: 'Basic Details',
			},
		],
	});
	const [classData, setClassData] = useState<any>([]);
	const [filterKey, setFilterKey] = useState('ALL');
	const [printable, setPritable] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [viewMissing, setViewMissing] = useState(false);
	const [viewMissingTc, setViewMissingTc] = useState(false);
	const [profileData, setProfileData] = useState<any>({});
	const [missingAdmNo, setMissingAdmNo] = useState<any>([]);
	const [missingTcNo, setMissingTcNo] = useState<any>([]);
	const [openAccordion, setOpenAccordion] = useState<string | null>(null);
	const [originalClassData, setOriginalClassData] = useState<any>([]);
	const [selectedFieldKeys, setSelectedFieldKeys] = useState<any>([]);
	const [masterEdit, setMasterEdit] = useState<any>(false);
	const [matchAdmisionNo, setMatchAdmisionNo] = useState<any>(null);
	const [tableWidth, setTableWidth] = useState<any>(null);
	const [type, setType] = useState<any>('student');
	const [profileDelete, setProfileDelete] = useState<any>();
	const [editProfData, setEditProfData] = useState<any>({ _id: '', key: '', value: '' });
	let { data: drops = [] } = useQuery('getDataBank', getSettings(collegeId, 'dataBank'));
	let { data: selectFields = [] } = useQuery('getAdmissionSettings', getSettings(collegeId, 'admissionRegister'));

	useEffect(() => {
		if (type && drops.settings) {
			let temp = drops.settings[type];
			setFieldsData(temp);
		}
	}, [type, drops]);
	useEffect(() => {
		if (type && selectFields?.settings) {
			let temp = selectFields.settings;
			setSelectedFields(temp);
		}
	}, [type, selectFields]);

	const { data: classes } = useQuery(['getNewClasses'], getNewClasses(collegeId, user?.uid));
	const { data: batches } = useQuery('getBatches', getBatches({ collegeId }));
	// const { data: fieldsData } = useQuery('getKeys', geteys());

	// const getSavedSettings = async () => {
	// 	try {
	// 		const instance = await getAxiosTokenInstance();
	// 		const { data } = await instance.get('/college/action/getAdmissionRegisterSettings', { params: { collegeId } });
	// 		console.log({ sele: data.response.payload });
	// 		setSelectedFields(data.response.payload);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	const postSettings = async () => {
		try {
			const instance = await getAxiosTokenInstance();

			let resp;
			resp = await instance.post('/college/action/postSettingsNew', {
				collegeId,
				type: 'admissionRegister',
				userId: user?._id,
				settings: {
					student: selectedFields?.student,
					teacher: selectedFields?.teacher,
					staff: selectedFields?.staff,
					scholars: selectedFields?.scholars,
				},
			});
			if (resp.data.statusCode !== 200) {
				toast.error('unable to save. please try again or later');
				throw new Error(resp.data.message || 'API Error');
			} else {
				refetch();
				toast.success('Saved settings succesfully');
				setSelectModal(false);
			}
		} catch (error) {
			console.log(error);
			toast.error('unable to save. please try again or later');
		} finally {
		}
	};

	useEffect(() => {
		if (selectedFields) {
			const fieldKeys = selectedFields[type]?.map((x: any) => x._id);
			setSelectedFieldKeys(fieldKeys);
		}
	}, [selectedFields]);

	const { data, refetch, status, isFetching, isFetched } = useQuery(['getAdmissionRegister', addNew?.startDate, addNew?.endingDate, admn?.startAdmNo, admn?.endAdmNo, tcDate?.tcStartDate, tcDate?.tcEndDate, tcNo?.startTcNo, tcNo?.endTcNo, selectedClass, filterKey, selectedBatch, selectedFields, advancedSearch, selectedFieldKeys, type], getAdmissionRegister3({ collegeId, startDate: addNew?.startDate, endingDate: addNew?.endingDate, startAdmNo: admn?.startAdmNo, endAdmNo: admn?.endAdmNo, tcStartDate: tcDate?.tcStartDate, tcEndDate: tcDate?.tcEndDate, startTcNo: tcNo?.startTcNo, endTcNo: tcNo?.endTcNo, classId: selectedClass, filterKey, selectedBatch: selectedBatch?.batch, advancedSearch, selectedFieldKeys, type }));

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const mainKeyArray = ['name', 'batchName', 'shortName', '_id', 'uid'];
	const { data: sequenceData } = useQuery(['getPenddingAdmissions'], getNextSequenceNumber(collegeId));
	// const { data: sequenceData } = useQuery(['getPenddingAdmissions'], getNextSequenceNumber(collegeId));
	const [settingsModal, setSettingsModal] = useState({
		openModal: false,
		newAdmissionNo: '',
		newAdmissionNoSelf: '',
		newApplicationNo: '',
		newApplicationNoSelf: '',
		newTcNo: '',
		newTcNoSelf: '',
	});

	useEffect(() => {
		if (sequenceData) {
			setSettingsModal((prv) => ({
				...prv,
				newAdmissionNo: sequenceData.admisionNo,
				newApplicationNo: sequenceData.applicationNo,
				newAdmissionNoSelf: sequenceData.admisionNoSelf,
				newApplicationNoSelf: sequenceData.applicationNoSelf,
				newTcNo: sequenceData.tcNo,
				newTcNoSelf: sequenceData.tcNoSelf,
			}));
		}
	}, [sequenceData]);

	const handleSequenceUpdate = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp = await instance.post('/college/admissionProcess/updateSequenceNumbers', {
				collegeId,
				userId: user.uid,
				admisionNo: settingsModal?.newAdmissionNo + '',
				applicationNo: settingsModal?.newApplicationNo + '',
				tcNo: settingsModal?.newTcNo + '',
				admisionNoSelf: settingsModal?.newAdmissionNoSelf + '',
				applicationNoSelf: settingsModal?.newApplicationNoSelf + '',
				tcNoSelf: settingsModal?.newTcNoSelf + '',
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setSettingsModal({ ...settingsModal, openModal: false });
			}
		} catch (error: any) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		}
	};

	const toggleAccordion = (accordionName: string) => {
		setOpenAccordion(openAccordion === accordionName ? null : accordionName);
	};

	const findMissingAdmNo = () => {
		console.log('calculated');
		let admNos = tableData?.map((item: any) => parseInt(item.admisionNo)).sort((a: any, b: any) => a - b);
		let missing: number[] = [];
		if (admn?.startAdmNo < admNos[0]) {
			let st = admn.startAdmNo;
			for (let i = st; i < admNos[0]; i++) {
				missing.push(i);
			}
		}
		for (let i = 0; i < admNos?.length - 1; i++) {
			let current = admNos[i];
			let next = admNos[i + 1];
			if (next - current > 1) {
				for (let j = current + 1; j < next; j++) {
					missing?.push(j);
				}
			}
		}
		if (admn?.endAdmNo > admNos[admNos.length - 1]) {
			let end = admNos[admNos.length - 1];
			for (let i = end + 1; i <= admn.endAdmNo; i++) {
				missing.push(i);
			}
		}

		setMissingAdmNo(missing);
	};
	const findMissingTcNo = () => {
		console.log('calculated');
		let tcNos = tableData?.map((item: any) => parseInt(item.tcNumber)).sort((a: any, b: any) => a - b);
		let missing: number[] = [];
		if (tcNo?.startTcNo < tcNos[0]) {
			let st = tcNo.startTcNo;
			for (let i = st; i < tcNos[0]; i++) {
				missing.push(i);
			}
		}
		for (let i = 0; i < tcNos?.length - 1; i++) {
			let current = tcNos[i];
			let next = tcNos[i + 1];
			if (next - current > 1) {
				for (let j = current + 1; j < next; j++) {
					missing.push(j);
				}
			}
		}
		if (admn?.endTcNo > tcNos[tcNos.length - 1]) {
			let end = tcNos[tcNos.length - 1];
			for (let i = end + 1; i <= admn.endTcNo; i++) {
				missing.push(i);
			}
		}

		setMissingTcNo(missing);
	};

	const handleReset = async () => {
		try {
			setFilterKey('');
			setAddNew('');
			setTcDate('');
			setTcNo('');
			setAdmn('');
			setSelectClass('');
			setSelectedBatch('');
			setAdvancedSearch('');
			setSearchQuery('');
		} catch (err) {
			console.log(err);
		}
	};

	// const handleSaveSettings = async () => {
	// 	try {
	// 		const instance = await getAxiosTokenInstance();
	// 		const { data } = await instance.post('/college/action/saveAdmissionRegisterSettings', { collegeId, payload: selectedFields });
	// 		toast.success('Saved settings succesfully');
	// 		setSelectModal(false);
	// 	} catch (err) {
	// 		console.log(err);
	// 		toast.error('unable to save. please try again or later');
	// 	}
	// };

	useEffect(() => {
		if (selectedFields && selectedFields[type].length > 0) {
			const calculatedWidth = selectedFields[type]?.reduce((acc: any, item: any) => {
				let wid = parseInt(item?.width[1]?.slice(0, -2));
				acc += wid;
				return acc;
			}, 0);

			const tableWidth = calculatedWidth < window.innerHeight ? '100vh' : `${calculatedWidth}px`;
			setTableWidth(tableWidth);
			console.log({ calculatedWidth, tableWidth });
		}
	}, [selectedFields, type]);

	useEffect(() => {
		if (data) {
			setTableData(data);
		}
	}, [data]);
	const { tableItems, requestSort, getIcon } = useSortableData(tableData);

	useEffect(() => {
		if (tableData) {
			findMissingAdmNo();
			findMissingTcNo();
		}
	}, [admn?.startAdmNo, admn?.endAdmNo, tableData]);

	useEffect(() => {
		if (!tableData) return;
		let filtered = [...tableData];

		if (searchQuery) {
			const lowerCaseQuery = searchQuery.toLowerCase();
			filtered = filtered.filter((data: any) => data?.name?.toLowerCase().includes(lowerCaseQuery) || data?.phoneNumber?.toString().includes(searchQuery) || (data?.tcNumber && data.tcNumber.toString().includes(searchQuery)) || data?.admisionNo?.toString().includes(searchQuery));
			setTableData(filtered);
		} else {
			setTableData(data);
		}
	}, [filterKey, searchQuery]);

	// useEffect(() => {
	// 	getSavedSettings();
	// }, []);

	useEffect(() => {
		if (classes?.list) {
			setOriginalClassData(classes.list);
			setClassData(classes.list);
		}
	}, [classes]);

	useEffect(() => {
		let filteredData = originalClassData;
		if (selectedBatch) {
			filteredData = filteredData.filter((item: any) => item.batchName === selectedBatch.batch);
		}
		if (selectedClass) {
			filteredData = filteredData.filter((item: any) => item._id === selectedClass);
		}
		setClassData(filteredData);
	}, [selectedBatch, selectedClass, originalClassData]);

	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
	}, [filterKey, addNew]);

	useEffect(() => {
		if (iSearch === false) {
			setAdvancedSearch('');
		}
	}, [iSearch]);

	useEffect(() => {
		setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
		setSelectClass('');
	}, [selectedBatch]);
	useEffect(() => {
		setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
	}, [selectedClass]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
	}, [admn]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAdmn('');
		setTcDate('');
		setTcNo('');
	}, [addNew]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAddNew('');
		setAdmn('');
		setTcDate('');
	}, [tcNo]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAdmn('');
		setAddNew('');
		setTcNo('');
	}, [tcDate]);
	useEffect(() => {
		setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
		setSelectClass('');
		setSelectedBatch('');
	}, [type]);

	const handleEditSubmit = async (_id: any, key: any, value: any, date: any) => {
		try {
			const instance = await getAxiosTokenInstance();
			const { data } = await instance.post('/college/action/updateProfDataByKey', { collegeId, _id, key, value, date });
			toast.success('Saved data succesfully');
			setSelectModal(false);
			refetch();
		} catch (err) {
			console.log(err);
			toast.error('unable to save. please try again or later');
		}
	};
	const handleProfileDelete = async (item: any) => {
		if (item.admisionNo != matchAdmisionNo) {
			toast.error('Unable to delete. Admission no. not match');
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			const { data } = await instance.delete('/college/action/profileDelete', { params: { collegeId, _id: item._id } });
			const deletedCount = data.deletedCount;
			if (deletedCount > 0) {
				toast.success(deletedCount + 'profile deleted succesfully');
			}
			setMatchAdmisionNo(null);
			setProfileDelete(null);
			refetch();
		} catch (err) {
			console.log(err);
			toast.error('unable to delete. please try again or later');
		}
	};

	console.log({ selectedFields, selectFields, fieldsData });

	// console.log({ editProfData });

	//--------view fee detail modal---------//
	const ViewModal = ({ isOpen, onClose, title, children, data }: any) => {
		if (!isOpen) return null;
		console.log({ data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full p-2  h-auto min-h-[40vh] max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Governed By</Th>
									<Th position={2}>Title</Th>
									<Th position={3}>Receipt Number</Th>
									<Th position={4}>Amount</Th>
									<Th position={5}>Method</Th>
									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data?.innerElements && data?.innerElements?.length > 0 ? (
									data?.innerElements?.map((item: any, i: any) => (
										<Tr>
											<Td index={i} position={0} textAlign='center'>
												{i + 1}
											</Td>
											<Td index={i} position={1} textAlign='center'>
												{item?.feeType}
											</Td>
											<Td index={i} position={2} textAlign='center'>
												{item?.accountName}
											</Td>
											<Td index={i} position={3} textAlign='center'>
												{item?.billNumber}
											</Td>
											<Td index={i} position={4} textAlign='center'>
												{item?.amount}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.method}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.feeYear?.toUpperCase()}
											</Td>
											<Td index={i} position={6} textAlign='center'>
												{moment(item.date).format('DD-MM-YYYY')}
											</Td>
										</Tr>
									))
								) : (
									<Tr>
										<p
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};
	//--------view fee detail modal---------//

	// --------Key Modal----------//
	const SelectModal = ({ isOpen, onClose, title, children, data }: any) => {
		if (!isOpen) return null;
		console.log({ data });

		const handleCheckboxChange = (field: any) => {
			setSelectedFields((prev: any) => {
				const updatedFields = prev[type] ?? []; // Safeguard if prev[type] is undefined
				if (updatedFields.some((item: any) => item._id === field._id)) {
					return {
						...prev,
						[type]: updatedFields.filter((item: any) => item._id !== field._id),
					};
				} else {
					return {
						...prev,
						[type]: [...updatedFields, field],
					};
				}
			});
		};

		const handleDragEnd = (result: any) => {
			if (!result.destination) return;
			const { source, destination } = result;
			const updatedFields = Array.from(selectedFields);
			const [movedItem] = updatedFields.splice(source.index, 1);
			updatedFields.splice(destination.index, 0, movedItem);
			setSelectedFields(updatedFields);
		};

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-70 flex items-center justify-center z-10 w-full h-full'>
				<div className='bg-slate-100 rounded-lg overflow-y-scroll shadow-lg max-w-7xl w-[20vw] p-2 m-1 h-[100vh]'>
					<div className='px-4 w-full'>
						<Button width='97%' type='submit' onClick={() => postSettings()}>
							{/* <Button width='97%' type='submit' onClick={() => handleSaveSettings()}> */}
							Save
						</Button>
					</div>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId='droppable'>
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef} className='mt-4 pt-1'>
									{selectedFields[type]?.map((field: any, index: any) => (
										<Draggable key={field._id} draggableId={field._id} index={index}>
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='border-blue-900 bg-blue-100 border-double rounded m-1 flex row'>
													<div onClick={() => handleCheckboxChange(field)} className='bg-slate-100 hover:bg-slate-200 h-full pl-2 py-1 pr-1 border-solid border-1 border-slate-300 hover:border-slate-400 rounded flex items-center shadow-xl shadow-slate-500'>
														<label className='flex items-center space-x-3 width=[300px] overflow-hidden pt-1 font-sans'>
															<input type='checkbox' value={field._id} checked={selectedFields[type]?.some((item: any) => item._id === field._id)} onChange={() => handleCheckboxChange(field)} className='appearance-none rounded-full h-4 w-4 cursor-pointer bg-gray-100 border-red-300 text-red-600 focus:ring-red-600 outline-none focus:ring-2 checked:bg-green-500' />
															<span className='text-sm font-medium text-gray-700'>{field.name}</span>
														</label>
													</div>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>

					<div className='p-4 border-t'></div>
				</div>
				<div className='bg-slate-100 rounded-lg overflow-y-scroll shadow-lg  p-2 m-1  max-h-[100vh] h-full w-full'>
					<div className='flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900 pt-2 ml-4'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>

					{[...new Set(fieldsData?.map((x: any) => x.category))]?.map((xxx: any) => (
						<>
							<span className='text-sm font-medium text-gray-700'>{xxx}</span>
							<div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 p-4'>
								{fieldsData
									?.filter((x: any) => !selectedFields[type]?.some((item: any) => item._id === x._id) && x.category === xxx)
									.map((field: any, index: any) => (
										<div key={`${field._id}-${index}`} onClick={() => handleCheckboxChange(field)} className='bg-slate-100 hover:bg-slate-200 h-full pl-2 py-1 pr-1 border-solid border-1 border-slate-300 hover:border-slate-400 rounded flex items-center shadow-xl shadow-slate-500'>
											<label className='flex items-center space-x-3 width=[300px] overflow-hidden pt-1 font-sans'>
												<input type='checkbox' value={field._id} checked={selectedFields[type]?.some((item: any) => item._id === field._id)} onChange={() => handleCheckboxChange(field)} className='appearance-none rounded-full h-4 w-4 cursor-pointer bg-gray-100 border-red-300 text-red-600 focus:ring-red-600 outline-none focus:ring-2 checked:bg-green-500' />
												<span className='text-sm font-medium text-gray-700'>{field.name}</span>
											</label>
										</div>
									))}
							</div>
						</>
					))}

					<div className='p-4 border-t'>
						<button onClick={() => setAdvanced(true)}>Advanced Settings</button>
						{advanced && (
							<Main zIndex='200' height='40%' width='30%'>
								<div className='flex flex-end'>
									<Button type='close' onClick={() => setAdvanced(false)}>
										Close
									</Button>
								</div>
								{disabled ? (
									<div>
										<label htmlFor=''>Click to enable</label>
										<Button type='close' onClick={() => setDisabled(false)}>
											Disabled
										</Button>
									</div>
								) : (
									<div>
										<label htmlFor=''>Click to Disable</label>
										<Button type='fetch' onClick={() => setDisabled(true)}>
											Enabled
										</Button>
									</div>
								)}
							</Main>
						)}
					</div>
				</div>
			</div>
		);
	};
	// --------Key Modal end----------//

	// --------Profile Modal----------//
	const ProfileModal: React.FC = () => {
		const [tab, setTab] = useState('profile');
		const shoProfilewModal = () => {
			setIsModalOpen(true);
		};

		const handleProfileOk = () => {
			setIsModalOpen(false);
		};

		const handleProfileCancel = () => {
			setIsModalOpen(false);
		};
		const { data: prof, isFetching } = useQuery(['getProfileDataAd', profileData?._id], getProfile({ studentId: profileData?._id, collegeId }));
		const [profile, setProfile] = useState<any>(null);

		useEffect(() => {
			if (prof) {
				setProfile(prof);
			}
		}, [prof, profileData?._id]);

		// useEffect(() => {
		// 	return setProfile(null);
		// }, []);

		const { data: feeData = [] } = useQuery(['EditDataSSSS', profileData?._id], getEditData(collegeId, profileData?._id));
		const { data: attendanceData } = useQuery('getStudentAttendance1', getStudentAttendance1({ studentId: profileData?._id, collegeId }));
		const attendance = attendanceData?.attedenceList[0];
		const [viewModalOpen, setViewModalOpen] = useState(false);
		const [viewModalData, setViewModalData] = useState('');
		const [state, setState] = React.useState({
			filterValue: '',
			_id: profileData?._id,
			page: 0,
			date: '',
			isBill: false,
			isAction: false,
			isDues: false,
			isBilling: true,
			openBillModal: false,
			openBillModal2: false,
			openBillModal3: false,
			newData: [],
			printType: '',
		});

		console.log({ profile, profileData });

		// --------Edit Modal Start----------//
		const [isEditModalOpen, setEditModalOpen] = useState(false);
		const [editModalData, setEditModalData] = useState<any>({ key: {}, value: '' });

		const EditModal: React.FC = () => {
			const [newData, setNewData] = useState({ _id: editModalData?.profileData?._id, key: editModalData?.key?.keyName, value: '' });

			const handleEditOk = async () => {
				try {
					setEditModalOpen(false);
				} catch (err) {
					console.log(err);
					toast.error('unable to save. please try again or later');
				}
			};

			const handleEditCancel = () => {
				setEditModalOpen(false);
			};

			return (
				<>
					<Modal open={isEditModalOpen} onOk={handleEditOk} onCancel={handleEditCancel} bodyStyle={{ backgroundColor: 'azure' }}>
						<div className=''>
							<h5 className='block mb-2 text-sm font-medium text-gray-900'>Modify {editModalData?.profile?.name}'s Data</h5>
							<label htmlFor={editModalData.key.keyName} className='block mb-2 text-sm font-medium text-gray-900'>
								{editModalData?.key?.title}
							</label>
							<input type='text' id={editModalData.key.keyName} value={editModalData.value} onChange={() => setNewData((prv) => ({ ...prv, value: editModalData.target.value }))} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' required />
						</div>
					</Modal>
				</>
			);
		};
		// --------Edit Modal End----------//

		return (
			<Main width='100%' title='Profile Details' height='100vh' bgColor='gray-300'>
				<div className='mt-0 px-3 overflow-auto h-[100%] bg-gray-300'>
					<header className='flex justify-end'>
						<Button
							type='close'
							onClick={() => {
								setIsModalOpen(false);
								setProfileData(null);
								setProfile(null);
							}}>
							close
						</Button>
					</header>
					{profile && !isFetching ? (
						<main className='h-[90vh]'>
							<section className=' flex h-full'>
								<article className='w-96'>
									<div className='text-left p-3 shadow-lg border-gray-500 rounded-md h-full '>
										{<img src={profile?.photoURL ?? dummyProfilePic} alt='Profile' className='w-100% max-h-72 rounded-2xl border-4 border-white shadow-lg mt-0 mx-auto' />}
										<h2 className='mt-4 text-2xl font-semibold'>{profile?.name?.toUpperCase()}</h2>
										<p className='text-gray-600 text-md font-semibold'>
											{profile?.batchName?.toUpperCase()}, {profile?.shortName?.toUpperCase()}
										</p>

										<div className='text-left grid grid-cols-1 gap-2'>
											{Object.keys(profile)?.map(
												(key) =>
													!mainKeyArray.includes(key) && (
														<>
															{fieldsData?.map((field: any) => {
																if (field._id === key && field.category === 'Basic Details') {
																	const value = field.type === 'date' && profile[key] !== undefined && profile[key] !== '' ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';

																	return (
																		<div
																			key={field._id}
																			// onClick={() => {
																			// 	setEditModalOpen(true);
																			// 	setEditModalData({ key: field, value: value, profile });
																			// }}
																		>
																			<strong className='w-36 inline-block text-gray-700'>{field.title || key.replace(/([A-Z])/g, ' $1')}:</strong>
																			<span className='text-gray-900'>{value}</span>
																		</div>
																	);
																}
																return null;
															})}
														</>
													),
											)}
										</div>
									</div>
								</article>
								<section className='w-full  h-[94%]'>
									<div className='text-sm font-medium text-center bg-gray-400 h-12 rounded p-0 m-0 shadow-lg'>
										<ul className='ml-0 pl-0 flex flex-wrap text-sm font-medium text-center list-none dark:text-gray-400 	'>
											<li className={`me-2 min-w-36 px-1 w-auto h-12 pt-2 rounded ${tab === 'profile' && 'bg-slate-600'} `} onClick={() => setTab('profile')}>
												<p aria-current='page' className={`${tab === 'profile' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													PROFILE
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'fee' && 'bg-slate-600'} `} onClick={() => setTab('fee')}>
												<p aria-current='page' className={`${tab === 'fee' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 '}>
													FEES
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1  w-auto h-12  pt-2 rounded ${tab === 'attendance' && 'bg-slate-600'} `} onClick={() => setTab('attendance')}>
												<p aria-current='page' className={`${tab === 'attendance' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													ATTENDANCE
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'callLogs' && 'bg-slate-600'} `} onClick={() => setTab('callLogs')}>
												<p aria-current='page' className={`${tab === 'callLogs' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													CALL LOGS
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'disciplinaryRecords' && 'bg-slate-600'} `} onClick={() => setTab('disciplinaryRecords')}>
												<p aria-current='page' className={`${tab === 'disciplinaryRecords' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													DISCIPLINARY RECORDS
												</p>
											</li>
										</ul>
									</div>
									{tab === 'profile' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll p-4'>
											<div className='grid grid-cols-2 gap-2 mb-4'>
												{Object.keys(profile)?.map(
													(key) =>
														!mainKeyArray.includes(key) && (
															<>
																{fieldsData?.map((field: any) => {
																	if (field._id === key) {
																		const value = field.type === 'date' && profile[key] !== undefined && profile[key] !== '' ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';

																		return (
																			<div
																				key={field._id}
																				// onClick={() => {
																				// 	setEditModalOpen(true);
																				// 	setEditModalData({ key: field, value: value, profile });
																				// }}
																			>
																				<strong className='w-56 inline-block text-gray-700'>{field.name || key.replace(/([A-Z])/g, ' $1')}:</strong>
																				<span className='text-gray-900'>{value}</span>
																			</div>
																		);
																	}
																	return null;
																})}
															</>
														),
												)}
											</div>
										</article>
									)}
									{tab === 'fee' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='p-3'>
												<Table width='100%' innerWidth={['5%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '30%']}>
													<Thead>
														<Tr>
															<Th position={0}>Sl.No.</Th>
															<Th position={1}>Governed By</Th>
															<Th position={2}>Title</Th>
															<Th position={3}>Sub Title</Th>
															<Th position={4}>Rt No</Th>
															<Th position={5}>Amount</Th>
															<Th position={6}>Method</Th>
															<Th position={7}>Year</Th>
															<Th position={8}>Date</Th>
															<Th position={9}>Action</Th>
														</Tr>
													</Thead>
													<Tbody height='auto'>
														{feeData?.collections && feeData?.collections?.length > 0 ? (
															feeData?.collections?.map((item: any, i: any) => (
																<Tr>
																	<Td index={i} position={0} textAlign='center'>
																		{i + 1}
																	</Td>
																	<Td index={i} position={1} textAlign='center'>
																		{item.feeType}
																	</Td>
																	<Td index={i} position={2} textAlign='center'>
																		{item.title}
																	</Td>
																	<Td index={i} position={3} textAlign='center'>
																		{item.subTitle}
																	</Td>
																	<Td index={i} position={4} textAlign='center'>
																		{item.billNumber}
																	</Td>
																	<Td index={i} position={5} textAlign='center'>
																		{item.amount}
																	</Td>
																	<Td index={i} position={6} textAlign='center'>
																		{item.method}
																	</Td>
																	<Td index={i} position={7} textAlign='center'>
																		{item.year}
																	</Td>
																	<Td index={i} position={8} textAlign='center'>
																		{item.date}
																	</Td>
																	<Td index={i} position={9}>
																		<div
																			style={{
																				display: 'flex ',
																				justifyContent: 'space-evenly',
																				alignItems: 'center',
																			}}>
																			<Button
																				type='fetch'
																				onClick={() =>
																					setState({
																						...state,
																						newData: {
																							...item,
																							name: feeData?.name,
																							batchName: feeData?.batchName,
																							longName: feeData?.longName,
																							admisionNo: feeData?.admisionNo,
																						},
																						openBillModal2: true,
																						printType: 'cprint',
																					})
																				}>
																				C Print
																			</Button>
																			<Button
																				type='fetch'
																				onClick={() =>
																					setState({
																						...state,
																						newData: {
																							...item,
																							collectedData: item?.innerElements?.map((x: any) => ({
																								...x,
																								title: x?.accountName,
																								year: x?.feeYear?.toUpperCase(),
																							})),
																							name: feeData?.name,
																							batchName: feeData?.batchName,
																							longName: feeData?.longName,
																							admisionNo: feeData?.admisionNo,
																						},
																						openBillModal2: true,
																						printType: 'print',
																					})
																				}>
																				Print
																			</Button>
																			<Button
																				type='submit'
																				onClick={() => {
																					setViewModalOpen(true);
																					setViewModalData(item);
																				}}>
																				View{' '}
																			</Button>
																		</div>
																	</Td>
																</Tr>
															))
														) : (
															<Tr>
																<p
																	style={{
																		textAlign: 'center',
																		color: 'red',
																		fontSize: '18px',
																		padding: '10px',
																	}}>
																	No bills available. Please add some data.
																</p>
															</Tr>
														)}
													</Tbody>
												</Table>
											</div>
										</article>
									)}
									{tab === 'attendance' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div>
												{attendanceData?.attedenceList?.length > 0 ? (
													<div className='p-4 h-full overflow-x-scroll bg-gray-300 shadow-lg rounded '>
														<h2 className='text-2xl font-semibold text-center mb-4'>Attendance Overview</h2>

														{/* Overall Attendance */}
														<div className='grid grid-cols-3 gap-4 text-center mb-6 rounded  p-4'>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Total Days</h3>
																<p className='text-3xl font-bold'>{attendance?.totalDays}</p>
															</div>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Present Days</h3>
																<p className='text-3xl font-bold'>{attendance?.totalAttendance}</p>
															</div>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Attendance %</h3>
																<p className='text-3xl font-bold'>{attendance?.percentage}%</p>
															</div>
														</div>

														{/* Monthly Attendance */}
														<h3 className='text-xl font-semibold mb-2 text-center'>Semester & Month-wise Attendance</h3>
														{attendanceData?.attedenceList
															?.sort((a: any, b: any) => b.semester - a.semester)
															?.map((attendance: any, index: any) => (
																<>
																	<h5 className='bg-gray-800 text-white py-1 px-2 rounded'>Semester: {parseInt(attendance?.semester) + 1}</h5>
																	{attendance?.semesterWiseList?.map((monthData: any, index: any) => (
																		<div key={index} className='mb-6  p-4 rounded shadow-lg bg-gray-200'>
																			<div className='flex justify-between items-center mb-2'>
																				<h4 className='text-lg font-semibold'>{monthData?.month}</h4>
																			</div>
																			<div className='mb-2'>
																				<Progress percent={parseFloat(monthData?.percentage)} />
																			</div>
																			<div className='grid grid-cols-3 gap-4 text-center'>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Total Days</h5>
																					<p className='text-lg'>{monthData?.totalDays}</p>
																				</div>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Present Days</h5>
																					<p className='text-lg'>{monthData?.totalAttendance}</p>
																				</div>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Attendance %</h5>
																					<p className='text-lg'>{monthData?.percentage}%</p>
																				</div>
																			</div>

																			{/* Subject-wise Attendance */}
																			<h4 className='mt-4 text-base font-semibold'>Subject-wise Attendance</h4>
																			<div className='grid grid-cols-3 gap-4 mt-2'>
																				{monthData?.subjectWiseList?.map((subject: any, subIndex: any) => (
																					<div key={subIndex} className='bg-gray-100 p-3 rounded-lg shadow-sm flex flex-col justify-between'>
																						<p className='font-medium'>{subject?.subjectName}</p>
																						<div>
																							<Progress percent={parseFloat(subject?.percentage)} size='small' />
																						</div>
																					</div>
																				))}
																			</div>
																		</div>
																	))}
																</>
															))}
													</div>
												) : (
													<div className='h-full flex justify-center pt-24'>
														<p className='text-2xl text-red-950'>no records found</p>
													</div>
												)}
											</div>
										</article>
									)}
									{tab === 'callLogs' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='h-full flex justify-center pt-24'>
												<p className='text-2xl text-red-950'>no records found</p>
											</div>
										</article>
									)}
									{tab === 'disciplinaryRecords' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='h-full flex justify-center pt-24'>
												<p className='text-2xl text-red-950'>no records found</p>
											</div>
										</article>
									)}
								</section>
							</section>
						</main>
					) : (
						<div className='h-[90vh] flex justify-center items-center'>
							<BarLoader />
						</div>
					)}

					<EditModal />
				</div>
				{state.openBillModal2 && (
					<BillNumberModal2
						data={state?.newData}
						date={state?.date}
						setStateData={setState}
						stateData={state}
						onClose={() => {
							setState({ ...state, openBillModal2: false, newData: [] });
						}}
					/>
				)}
				<ViewModal
					isOpen={viewModalOpen}
					onClose={() => {
						setViewModalOpen(false);
						setViewModalData('');
					}}
					title='View Fee Details'
					data={viewModalData}>
					<p>View Details.</p>
				</ViewModal>
			</Main>
		);
	};
	// --------Profile Modal End----------//

	return (
		<>
			<Main title='Admission Register' width='100%' height='100vh'>
				<Toaster />
				<div className='flex w-full h-full'>
					<div className='w-full h-full  overflow-scroll'>
						<div className='h-[97vh]'>
							<div className='flex justify-between items-center bg-[#5c655c] rounded-tl-lg h-[70px]'>
								<div>
									<div className='flex ml-4'>
										<div className='flex items-center me-4'>
											<input
												id='student'
												type='radio'
												value={'student'}
												onChange={() => {
													setType('student');
												}}
												checked={type === 'student'}
												name='type'
												className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
											/>
											<label htmlFor='student' className='ms-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300'>
												Student
											</label>
										</div>
										<div className='flex items-center me-4'>
											<input id='scholars' type='radio' value={'scholars'} onChange={() => setType('scholars')} checked={type === 'scholars'} name='type' className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
											<label htmlFor='scholars' className='ms-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300'>
												Scholars
											</label>
										</div>
										<div className='flex items-center me-4'>
											<input id='teacher' type='radio' value={'teacher'} onChange={() => setType('teacher')} checked={type === 'teacher'} name='type' className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
											<label htmlFor='teacher' className='ms-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300'>
												Teacher
											</label>
										</div>
										<div className='flex items-center me-4'>
											<input id='staff' type='radio' value={'staff'} onChange={() => setType('staff')} checked={type === 'staff'} name='type' className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600' />
											<label htmlFor='staff' className='ms-2 mt-1 text-sm font-medium text-gray-900 dark:text-gray-300'>
												Staff
											</label>
										</div>
									</div>
								</div>
								{iSearch && (
									<div className='flex justify-center'>
										<Input width='600px' fieldName='Advanced Search' type='text' setState={setAdvancedSearch} state={advancedSearch}></Input>
									</div>
								)}
								<div>
									<Button type='edit' onClick={() => setMasterEdit(!masterEdit)}>
										{masterEdit ? 'Edit Done' : 'Master edit'}
									</Button>
								</div>
							</div>

							<div ref={ref} className='printable overflow-auto '>
								{printable && (
									<div className='flex flex-row items-center justify-center p-4 border-b-2'>
										<div className='flex items-center'>
											<img className='w-16 h-16 mr-4 print:w-16 print:h-16' src={selectedCollege?.logo} alt='College Logo' />
											<div>
												<h1 className='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
												<p className='text-md font-semibold h-1'>{selectedCollege?.accreditedGrade}</p>
												<p className='text-md font-semibold h-1'>{selectedCollege?.address}</p>
											</div>
										</div>
									</div>
								)}
								<div className='relative h-full'>
									{isFetching ? (
										<div className='flex justify-center items-center h-full w-full'>
											<PulseLoader />
										</div>
									) : tableData?.length > 0 ? (
										<div className='h-full'>
											<Table width={printable ? '100%' : tableWidth}>
												<Thead>
													<Tr>
														{masterEdit && <Th width='100px'>Action</Th>}
														{selectedFields[type]?.map((field: any) => (
															<Th width={field.width[1]} key={field._id} onClick={() => requestSort(field._id)} icon={getIcon(field._id)}>
																{field.name}
															</Th>
														))}
													</Tr>
												</Thead>
												<Tbody height='87vh'>
													{tableItems?.map((item: any, index: any) => (
														<Tr
															key={index}
															onClick={() => {
																if (!masterEdit && item._id) {
																	setIsModalOpen(true);
																	setProfileData(item);
																}
															}}>
															{masterEdit && (
																<Td index={index} width='100px'>
																	<Button type='delete' onClick={() => setProfileDelete(item)}>
																		delete
																	</Button>
																</Td>
															)}
															{selectedFields[type]?.map((field: any) => {
																const keyName = field._id;
																const value = item[keyName];

																if (keyName === 'slNo') {
																	return (
																		<Td index={index} width={field.width[1]} key={`${index}-${keyName}`}>
																			{index + 1}
																		</Td>
																	);
																}

																if (field.type === 'date') {
																	const formattedDate = value && moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '';
																	return (
																		<Td index={index} width={field.width[1]} key={`${index}-${keyName}`}>
																			{masterEdit ? (
																				<input
																					type='date'
																					value={editProfData.key === keyName && editProfData._id === item._id ? editProfData?.value : formattedDate}
																					onChange={(e) => {
																						setEditProfData({ _id: item._id, key: keyName, value: e.target.value });
																						handleEditSubmit(item._id, keyName, e.target.value, true);
																					}}
																					//   onBlur={(e) => handleEditSubmit(item._id, keyName, e.target.value,true)}
																				/>
																			) : (
																				formattedDate || '-'
																			)}
																		</Td>
																	);
																}

																return (
																	<Td index={index} width={field.width[1]} key={`${index}-${keyName}`}>
																		{masterEdit ? <input type='text' value={editProfData.key === keyName && editProfData._id === item._id ? editProfData?.value : value || ''} onChange={(e) => setEditProfData({ _id: item._id, key: keyName, value: e.target.value })} onBlur={(e) => handleEditSubmit(item._id, keyName, e.target.value, false)} /> : value !== undefined && value !== null && typeof value !== 'object' ? value : '-'}
																	</Td>
																);
															})}
														</Tr>
													))}
												</Tbody>
											</Table>
										</div>
									) : (
										<div className='flex justify-center items-center mt-20 pt-20'>
											{' '}
											<img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/nodatafound.png?alt=media&token=75763b23-2b98-4570-b308-963bc28c070b' alt='' />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='w-96 pe-1 h-full bg-[#5c655c] pl-2 rounded-r-lg overflow-y-auto'>
						<header className='flex flex-col'>
							<div className='flex justify-between pt-2 ml-[-5px]'>
								<Button type='print' tableRef={ref.current}>
									Print
								</Button>
								<Button type='excel' tableRef={ref.current}>
									Excel
								</Button>
								<Button type={printable ? 'search' : 'update'} onClick={() => setPritable(!printable)}>
									{printable ? 'Hide' : 'Show'}
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
							<div className='flex justify-between w-full pe-2'>
								<div className='flex gap-2 mt-3 justify-between ml-2'>
									<div className=' mt-[2px]'>
										<input type='checkbox' className='appearance-none w-7 h-7 border border-gray-300 rounded-full focus:outline-none focus:ring-0 checked:bg-emerald-900 checked:border-emerald-900 relative cursor-pointer' onChange={() => setISearch(!iSearch)} checked={iSearch} />
										<style>{`
									input[type='checkbox']:checked {
										background-color: #065f46; /* Custom emerald-900 */
										border-color: #065f46;
									}
								`}</style>
									</div>
									<p className=' text-white rounded mb-3 px-2'>Advanced Search</p>
								</div>
								<div className='mr-[-7px] mt-2'>
									<Button type='refresh' onClick={handleReset}>
										Reset
									</Button>
								</div>
							</div>
							<div className='flex justify-between w-full pe-2'>
								<div className='w-56'>
									<Input fieldName='Search' state={searchQuery} setState={setSearchQuery} onChange={(event) => setSearchQuery(event.value)} type='text' width='100%' />
								</div>

								<button type='button' onClick={() => setSelectModal(true)} className='flex items-center h-12 mt-[10px] bg-gray-300 border border-gray-400 hover:bg-gray-400 hover:border-gray-900 text-gray-800 font-semibold py-2 px-3 ml-1 rounded-lg shadow-lg shadow-gray-900 hover:shadow-3xl transition duration-300 ease-in-out transform hover:scale-105'>
									<FaCog className=' text-gray-700' size={20} />
								</button>
							</div>
							<div className='flex justify-between w-full pe-2'>
								<div className='w-56'>
									<Input fieldName='Govern By' state={filterKey} setState={setFilterKey} returnKey='' options={['ALL', 'Aided', 'Self']} type='drop' width='100%' />
								</div>
								<button type='button' onClick={() => setSettingsModal((prv) => ({ ...prv, openModal: true }))} className='flex items-center h-12 mt-[10px] bg-green-900 border border-green-800 hover:bg-green-700 hover:border-gray-700 text-gray-800 font-semibold py-2 px-3 ml-1 rounded-lg shadow-lg shadow-gray-900 hover:shadow-3xl transition duration-300 ease-in-out transform hover:scale-105'>
									<FaCog className=' text-gray-100' size={20} />
								</button>
							</div>
							<div className='flex flex-col w-72 gap-y-1'>
								<Input fieldName='Filter by Batch' state={selectedBatch} setState={setSelectedBatch} returnKey='batch' options={batches} type='drop' width='100%' />
								<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} distinctKey='classes' didntShowKey optionDisplay='className' optionKey='_id' options={classData} type='drop' width='100%' />
							</div>

							<div className='w-[100%] flex flex-col gap-y-2 pe-1'>
								<div className='bg-[#4d5358] p-2 rounded-md accordion w-[100%] cursor-pointer '>
									<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('admissionDate')}>
										Filter by Admission Date
									</p>
									{openAccordion === 'admissionDate' && (
										<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'admissionDate' ? 'max-h-96' : 'max-h-0'}`}>
											<Input fieldName='Start Date *' returnKey='startDate' state={addNew} setState={setAddNew} type='date' width='97%' />
											<Input fieldName='End Date *' returnKey='endingDate' state={addNew} setState={setAddNew} type='date' width='97%' />
										</div>
									)}
								</div>
								<div className='bg-[#40515f] p-2 rounded-md accordion w-[100%] cursor-pointer'>
									<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('admissionNo')}>
										Filter by Admission No
									</p>
									{openAccordion === 'admissionNo' && (
										<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'admissionNo' ? 'max-h-96' : 'max-h-0'}`}>
											<Input fieldName='Start No *' returnKey='startAdmNo' state={admn} setState={setAdmn} type='number' width='97%' />
											<Input fieldName='End No *' returnKey='endAdmNo' state={admn} setState={setAdmn} type='number' width='97%' />
											{missingAdmNo.length > 0 && admn?.startAdmNo && admn?.endAdmNo && (
												<button className='border-0 rounded-lg text-center bg-[#1b3143] text-red-600 bg-none w-full shadow text-xs italic' onClick={() => setViewMissing(true)}>
													Missing{' '}
													{missingAdmNo.slice(0, 2).map((x: any) => (
														<>{x},</>
													))}{' '}
													{missingAdmNo.length > 2 && '.. view more ...'}
												</button>
											)}
										</div>
									)}
								</div>
								<div className='bg-[#4d5358] p-2 rounded-md accordion w-[100%] cursor-pointer'>
									<p className='  text-white text-center font-bold cursor-pointer hover:scale-1.5 rounded' onClick={() => toggleAccordion('tcDate')}>
										Filter by TC Date
									</p>
									{openAccordion === 'tcDate' && (
										<div className={`overflow-hidden transition-[max-height] duration-300  ease-in-out ${openAccordion === 'tcDate' ? 'max-h-96' : 'max-h-0'}`}>
											<Input fieldName='Start Date *' returnKey='tcStartDate' state={tcDate} setState={setTcDate} type='date' width='97%' />
											<Input fieldName='End Date *' returnKey='tcEndDate' state={tcDate} setState={setTcDate} type='date' width='97%' />
										</div>
									)}
								</div>
								<div className='bg-[#40515f] p-2 rounded-md accordion w-[100%] cursor-pointer'>
									<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('tcNo')}>
										Filter by TC No
									</p>
									{openAccordion === 'tcNo' && (
										<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'tcNo' ? 'max-h-96' : 'max-h-0'}`}>
											<Input fieldName='Start Tc No *' returnKey='startTcNo' state={tcNo} setState={setTcNo} type='number' width='97%' />
											<Input fieldName='End Tc No *' returnKey='endTcNo' state={tcNo} setState={setTcNo} type='number' width='97%' />
											{missingTcNo.length > 0 && (
												<button className='border-0 rounded-lg text-center bg-[#1b3143] text-red-600 bg-none w-full shadow text-xs italic' onClick={() => setViewMissingTc(true)}>
													Missing{' '}
													{missingTcNo.slice(0, 2).map((x: any) => (
														<>{x},</>
													))}{' '}
													{missingTcNo.length > 2 && '.. view more ...'}
												</button>
											)}
										</div>
									)}
								</div>
							</div>
						</header>
					</div>
				</div>
				{viewMissing && (
					<>
						<Main zIndex='100' height='80%' width='50%'>
							<div className='flex justify-end'>
								<Button type='close' onClick={() => setViewMissing(false)}>
									Close
								</Button>
							</div>
							<Body>
								<h5 className='text-center mb-4'>
									Missing Admission Number between {admn.startAdmNo} & {admn.endAdmNo}
								</h5>
								<div className='flex flex-wrap w-full text-dark font-semibold gap-2 text-md justify-center items-center'>
									{missingAdmNo.map((item: any) => (
										<li className='list-none p-2'>{item}</li>
									))}
								</div>
							</Body>
						</Main>
					</>
				)}
				{viewMissingTc && (
					<>
						<Main zIndex='100' height='80%' width='50%'>
							<div className='flex justify-end'>
								<Button type='close' onClick={() => setViewMissingTc(false)}>
									Close
								</Button>
							</div>
							<Body>
								<h5 className='text-center mb-4'>
									Missing TC Number between {tcNo.startTcNo} & {tcNo.endTcNo}
								</h5>
								<div className='flex flex-wrap w-full text-dark font-semibold gap-2 text-md justify-center items-center'>
									{missingTcNo.map((item: any) => (
										<li className='list-none p-2'>{item}</li>
									))}
								</div>
							</Body>
						</Main>
					</>
				)}
			</Main>
			{settingsModal?.openModal && (
				<Main height='70%' width='40%' bgColor='gray-200'>
					<Header>
						<div></div>
						<Button type='close' onClick={() => setSettingsModal((prv) => ({ ...prv, openModal: false }))}>
							close
						</Button>
					</Header>
					<Body>
						<div className='p-4 w-full'>
							<Input width='100%' type='text' fieldName={`Next Sequence Admission Number: ${sequenceData?.admisionNo}`} returnKey='newAdmissionNo' state={settingsModal} setState={setSettingsModal}></Input>
							<Input width='100%' type='text' fieldName={`Next Sequence Application Number: ${sequenceData?.applicationNo}`} returnKey='newApplicationNo' state={settingsModal} setState={setSettingsModal}></Input>
							{sequenceData?.admisionNoSelf && <Input width='100%' type='text' fieldName={`Next Sequence Admission Number (Self): ${sequenceData?.admisionNoSelf ?? ''}`} returnKey='newAdmissionNoSelf' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.applicationNoSelf && <Input width='100%' type='text' fieldName={`Next Sequence Application Number (Self): ${sequenceData?.applicationNoSelf ?? ''}`} returnKey='newApplicationNoSelf' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.tcNo && <Input width='100%' type='text' fieldName={`Next Sequence Tc Number: ${sequenceData?.tcNo ?? ''}`} returnKey='newTcNo' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.tcNoSelf && <Input width='100%' type='text' fieldName={`Next Sequence Tc Number(self): ${sequenceData?.tcNoSelf ?? ''}`} returnKey='newTcNoSelf' state={settingsModal} setState={setSettingsModal}></Input>}

							<div className='flex justify-center'>
								<Button width='200px' type='submit' onClick={handleSequenceUpdate}>
									Submit
								</Button>
							</div>
						</div>
					</Body>
				</Main>
			)}
			{profileDelete && (
				<Main height='40%' width='40%'>
					<Toaster />
					<Header>
						<div></div>
						<Button
							type='close'
							onClick={() => {
								setProfileDelete(null);
								setMatchAdmisionNo(null);
							}}>
							close
						</Button>
					</Header>
					<Body>
						<div className='p-4 w-full'>
							<h4>Name: {profileDelete?.name}</h4>
							<h4>Admission No: {profileDelete?.admisionNo}</h4>
							<Input width='100%' type='text' fieldName={`Match Admission Number to Delete`} state={matchAdmisionNo} setState={setMatchAdmisionNo}></Input>

							<div className='flex justify-center'>
								<Popconfirm
									placement='topLeft'
									title='Are you sure you want to delete?'
									okText='Yes'
									cancelText='No'
									onConfirm={() => {
										handleProfileDelete(profileDelete);
									}}>
									<Button width='200px' type='delete'>
										Delete
									</Button>
								</Popconfirm>
							</div>
						</div>
					</Body>
				</Main>
			)}
			<SelectModal isOpen={selectModal} onClose={() => setSelectModal(false)} title={'Select Required fields'} />
			{isModalOpen && profileData._id && <ProfileModal />}
		</>
	);
}

export default AdmissionRegister3;
