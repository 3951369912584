import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select, Table } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Input from '../../../NecttosComp/Input/Input';

const getFieldDropDowns = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/library/getClassesLibrary', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.classesData;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getAllMembers = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		if (!data.classId) {
			throw new Error('ClassId Required');
		}
		const resp = await instance.get('/college/classes/getRollView', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);

			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data?.classList;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const columns = [
	{
		title: 'Ad. No.',
		dataIndex: 'admisionNo',
		key: 'admisionNo',
		columnWidth: '15%',
	},
	{
		title: 'CN',
		dataIndex: 'rollNo',
		key: 'rollNo',
		columnWidth: '15%',
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		columnWidth: '5%',
	},
	{
		title: 'Elective',
		dataIndex: 'elective',
		key: 'elective',
		columnWidth: '5%',
	},
];

export const RollView = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [state, setState] = React.useState({});
	const componentRef = useRef();
	const { data = [] } = useQuery(['opacData'], getFieldDropDowns({ collegeId }), { keepPreviousData: true });
	const { data: allData = [], refetch } = useQuery(['members', state.classId, state.searchValue, state.date], getAllMembers({ collegeId: state.collegeId, classId: state.classId, date: state.date, setLoading }), { keepPreviousData: true });
	let allMembers = allData.studentsRolles;
	let classData = allData.classData;

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<>
					<div className={classNames.listContainer2}>
						<div style={{ width: '200px' }}>
							<div>
								<div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
									<div style={{ width: '100%', paddingLeft: 5, paddingRight: 5, display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>S.N.</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '80%' }}>Class Name</h5>
									</div>
									<div
										style={{
											height: '600px',
											width: '100%',
											position: 'relative',
										}}>
										<Scrollbars>
											{data?.map((item, i) => (
												<>
													<div
														onClick={() => {
															setState({
																...state,
																classId: item.classId,
																className: item.className,
																collegeId: item.collegeId,
															});
															refetch();
														}}
														style={{ cursor: 'pointer', width: '100%', paddingLeft: 5, paddingRight: 5, display: 'flex', height: '30px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'active' ? 'white' : '#ff8f87' }}>
														<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '20%' }}>{i + 1}</h5>
														<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '80%' }}>{item.className}</h5>
													</div>
													<div
														style={{
															width: '100%',
															display: 'flex',
															height: 1,
															backgroundColor: 'black',
														}}></div>
												</>
											))}
										</Scrollbars>
									</div>
								</div>
							</div>
						</div>
						<div style={{ width: '1180px' }}>
							<Scrollbars style={{}}>
								<div ref={componentRef} className='printable'>
									<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
									<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
										<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
											<img width='12%' height='12%' src={selectedCollege?.logo} />
											<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
												<span style={{ fontSize: '30px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
												<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
												<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>
													{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
												</p>
											</div>
										</div>
										<div style={{ width: '1080px', display: 'flex', flexDirection: 'row', height: 30, margin: 0, alignItems: 'center', alignSelf: 'center', marginBottom: 0 }}>
											<h5 style={{ marginTop: 6, color: 'black', textAlign: 'left', fontSize: 16, width: '250px' }}>Tutor: {classData?.classTeacher}</h5>
											<h5 style={{ marginTop: 6, color: 'black', textAlign: 'center', fontSize: 16, width: '580px' }}>{classData?.className}</h5>
											<h5 style={{ marginTop: 6, color: 'black', textAlign: 'right', fontSize: 16, width: '250px' }}>HoD: {classData?.hod}</h5>
										</div>
										<div style={{ width: '1080px', display: 'flex', flexDirection: 'row', borderRadius: '25px', padding: '1%' }}>
											<div style={{ margin: 10, width: allMembers?.length < 37 ? '1080px' : '530px' }}>
												<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '53px' }}>SN</h5>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 16, width: '90px' }}>Ad. No.</h5>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '53px' }}>CN</h5>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '250px' }}>Name</h5>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '50px' }}>Lan</h5>
													<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '20px' }}>G</h5>
												</div>
												{allMembers?.slice(0, 36)?.map((item, i) => (
													<>
														<div style={{ marginTop: 6, width: '100%', display: 'flex', height: ' 28px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.elective === 'Discountined' ? '#ff8f87' : '' }}>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '53px' }}>{i + 1}</h5>
															<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 16, width: '90px' }}>{item?.admisionNo}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '53px' }}>{item?.rollNo}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: item.elective === 'Discountined' ? 12 : 16, width: '250px' }}>{item?.name?.toUpperCase()}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 10, width: '50px' }}>{item?.secondLanguage?.slice(0, 3)}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '20px' }}>{item?.gender?.slice(0, 1)}</h5>
														</div>
														<div
															style={{
																width: '100%',
																display: 'flex',
																height: 1,
																backgroundColor: 'black',
															}}></div>
													</>
												))}
											</div>
											{allMembers?.length > 36 && (
												<div style={{ margin: 10, width: '530px' }}>
													<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '53px' }}>SN</h5>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 16, width: '90px' }}>Ad. No.</h5>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '53px' }}>CN</h5>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '250px' }}>Name</h5>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '50px' }}>Lan</h5>
														<h5 style={{ marginTop: 6, marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '20px' }}>G</h5>
													</div>
													{allMembers?.slice(36, 74)?.map((item, i) => (
														<>
															<div style={{ marginTop: 6, width: '100%', display: 'flex', height: ' 28px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.elective === 'Discountined' ? '#ff8f87' : '' }}>
																<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '53px' }}>{i + 37}</h5>
																<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 16, width: '90px' }}>{item?.admisionNo}</h5>
																<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '53px' }}>{item?.rollNo}</h5>
																<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: item.elective === 'Discountined' ? 12 : 16, width: '250px' }}>{item?.name?.toUpperCase()}</h5>
																<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 10, width: '50px' }}>{item?.secondLanguage?.slice(0, 3)}</h5>
																<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '20px' }}>{item?.gender?.slice(0, 1)}</h5>
															</div>
															<div
																style={{
																	width: '100%',
																	display: 'flex',
																	height: 1,
																	backgroundColor: 'black',
																}}></div>
														</>
													))}
												</div>
											)}
										</div>
										<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Printed on {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
									</div>
								</div>
							</Scrollbars>
						</div>
						<div>
							<Scrollbars>
								<table className={classNames.userTable} id='emp'>
									<Table dataSource={allMembers} pagination={false} columns={columns} />
								</table>
							</Scrollbars>
						</div>
					</div>
				</>
			</div>

			<div className={classNames.absoluteDiv}>
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<Button className={classNames.reloadButton} onClick={() => refetch()}>
						Reload
					</Button>
				)}

				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						refetch();
					}}>
					<ReactHtmlTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='collectionReport' sheet='tablexls' buttonText='EXCEL' />
				</Button>
				<Input width='200px' fieldName='Date*' returnKey='date' state={state} setState={setState} type='date' />
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
