import React, { useContext, useEffect, useRef, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { naacGET } from '../../NecttosComp/APICaller/APICaller';
import { useQuery } from 'react-query';
import MissingDataFinder from '../../NecttosComp/MissingDataFinder/MissingDataFinder';

function C2_6({ onClose, title }) {
	const [loading, setLoading] = useState(false);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [analyze, setAnalyze] = useState();
	const ref = useRef(null);
	const { collegeId } = useContext(FirebaseContext);

	const { data: values = [] } = useQuery(['criteria2_4_1', academicYear], naacGET({ endPoint: 'criteria2_4_1', params: { collegeId, academicYear } }));

	return (
		<>
			<Main width='80vw' height='80vh' title={title}>
				{loading ? (
					<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
						<LoadingAnimation />
					</div>
				) : null}
				<Header>
					<div className='flex'>
						<AcademicYears setAcademicYear={setAcademicYear} />
					</div>
					<div className='flex'>
						<Button type='update' onClick={() => setAnalyze(true)}>
							Analyze
						</Button>
						<Button pdfId='emp' type='pdf'>
							PDF
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div className='printable h-[60vh] overflow-auto' ref={ref}>
						<Table width='100%' id='emp' innerWidth={['10%', '10%', '10%', '10%', '15%', '15%', '15%', '15%']}>
							<Thead>
								<Tr>
									<Th position={0} fontSize='12px'>
										Name of the Full-time teacher
									</Th>
									<Th position={1} fontSize='12px'>
										Pan
									</Th>
									<Th position={2} fontSize='12px'>
										Designation
									</Th>
									<Th position={3} fontSize='12px'>
										Year of appointment
									</Th>
									<Th position={4} fontSize='12px'>
										Nature of appointment {'(Against Sanctioned post, temporary, permanent)'}
									</Th>
									<Th position={5} fontSize='12px'>
										Name of the Department
									</Th>
									<Th position={6} fontSize='12px'>
										Total years of Experience in the same instution
									</Th>
									<Th position={7} fontSize='10px'>
										Is the teacher still serving the instution/If not last year of the service of Faculty to the instition
									</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{values?.map((x) => (
									<Tr>
										<Td position={0} fontSize='12px'>
											{x?.name}
										</Td>
										<Td position={1} fontSize='14px'>
											{x?.panCard}
										</Td>
										<Td position={2}>{x?.designation}</Td>
										<Td position={3} fontSize='14px'>
											{x?.dateOfJoin}
										</Td>
										<Td position={4}>{x?.designationStatus}</Td>
										<Td position={5}>{x?.department?.map((x) => x + ', ')}</Td>
										<Td position={6}>{Math.round(x?.year)}</Td>
										<Td position={7}>Yes</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
			</Main>
			{analyze && (
				<MissingDataFinder
					data={values}
					onClose={() => setAnalyze(false)}
					values={[
						{ importance: true, name: 'year', title: 'Total years of Experience in the same instution' },
						{ importance: true, name: 'department', title: 'Name of the Department' },
						{ importance: true, name: 'designationStatus', title: ' Nature of appointment (Against Sanctioned post, temporary, permanent)' },
						{ importance: true, name: 'dateOfJoin', title: 'Year of appointment' },
						{ importance: true, name: 'designation', title: 'Designation' },
						{ importance: true, name: 'panCard', title: 'Pan' },
						{ importance: true, name: 'name', title: 'Name' },
					]}
				/>
			)}
			{/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto">
        <table className="tabl">
          <thead>
            <tr>
              <th className="w-1/">Name of the Full-time teacher</th>
              <th className="w-14">Pan</th>
              <th className="w-/4">Designation</th>
              <th className="w1/4">Year of appointment</th>
              <th className="-1/4">Nature of appointment {"(Against Sanctioned post, temporary, permanent)"}</th>
              <th className="-1/4">Name of the Department</th>
              <th className="-1/4">Total years of Experience in the same instution</th>
              <th>Is the teacher still serving the instution/If not last year of the service of Faculty to the instition</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "5px" }}>
            {values?.map((x) => (
              <tr>
                <td>{x?.name}</td>
                <td>{x?.panCard}</td>
                <td>{x?.designation}</td>
                <td>{x?.dateOfJoin}</td>
                <td>{x?.designationStatus}</td>
                <td>{x?.department?.map((x) => x + ", ")}</td>
                <td>{Math.round(x?.year)}</td>
                <td>Yes</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
		</>
	);
}

export default C2_6;
