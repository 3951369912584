import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { JournalEntry } from './IncomeExpenditure/DayBook';
import DetailedReportTable from './RecieptPaymentSub';
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import useApiCaller from '../../NecttosComp/CustomApiCaller/customApiCaller';
import { sumWithKey } from '../../utils';
import moment from 'moment';
import { getIncomeExpenditure } from '../../NecttosComp/APICaller/ApiServices';
import { useQuery } from 'react-query';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { Select } from 'antd';
import CustomDropDown from '../../myComp/CustomDropDown';
import { SubsidiaryRegisterMonthWise } from './SubsidiaryRegisterMonthWise';

const getAccounts = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getSubsidiaryRegister', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const SubsidiaryRegisterYearWise = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const [newStartDate, setnewStartDate] = useState('');
	const [newEndDate, setNewEndDate] = useState('');
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
		year: '',
	});
	const dataToFinancial = state?.year?.split('-');
	const startDate = dataToFinancial ? dataToFinancial?.[0] + '-06-01' : '';
	const endDate = dataToFinancial ? dataToFinancial?.[1] + '-05-31' : '';
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const { data, refetch: issuedBookRefetch } = useQuery(['pdReg777ister', state?.year], getAccounts({ collegeId, formate: 'MMMM-YYYY', startDate: newStartDate, endDate: newEndDate, setLoading }));
	const { Option } = Select;
	const [date, setDate] = useState({ date: '', type: 'Government', subType: '' });

	console.log({ data });

	useEffect(() => {
		setState((prev) => ({
			...prev,
			year: '',
		}));
	}, [newStartDate, newEndDate]);

	useEffect(() => {
		if (state?.year) {
			setnewStartDate(startDate);
			setNewEndDate(endDate);
		}
		issuedBookRefetch();
	}, [state]);

	console.log(newStartDate);
	console.log(newEndDate);

	return (
		<>
			<Main title={'Income & Expenditure A/C'} height={'90vh'} width={'90vw'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Input typable fieldName='Financial Year' setState={setState} state={state} returnKey='year' didntShow options={['2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026']} type='drop' width='160px' />
						<Input type='date' state={newStartDate} fieldName='Start Date' setState={setnewStartDate} />
						<Input type='date' state={newEndDate} fieldName='End Date' setState={setNewEndDate} />
						<Button type='fetch' onClick={issuedBookRefetch}>
							Proceed
						</Button>
					</div>

					<div className='flex flex-wrap items-center'>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
						{/* <Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button type='pdf' tableRef={componentRef.current} pdfId='accounts'>
							PDF
						</Button> */}
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={componentRef} className='printable '>
						<div class='flex flex-row items-center justify-between p-4 border-b-2'>
							<div class='flex items-center'>
								<img class='w-16 h-16 mr-4' src={selectedCollege?.logo} alt='College Logo' />
								<div>
									<h1 class='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
									<p class='text-md font-semibold '>{selectedCollege?.accreditedGrade}</p>
									<p class='text-md font-semibold '>{selectedCollege?.address}</p>
								</div>
							</div>

							<div class='text-right'>
								<p class='text-xl font-medium '>Subsidiary Register YearWise</p>
								<p class='text-md font-medium '>
									{date.type} - {date.subType}
								</p>

								<p class='text-sm font-medium '>
									{moment(date.date).format('DD MMM YYYY')} to {moment(date.endDate).format('DD MMM YYYY')}
								</p>
								<p class='text-sm font-medium '>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
							</div>
						</div>

						{data?.subsidiaryRegisterData.length > 0 && (
							<div className='w-100 h-100'>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 36, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>SN</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '22%' }}>Date</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>C</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '16%' }}>Rt.No.</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee1}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee2}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee3}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee4}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee5}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee6}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee7}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee8}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee9}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee10}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee11}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee12}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee13}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>TOTAL</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>PAY</h5>
								</div>
								{data?.subsidiaryRegisterData?.map((item, i) => (
									<>
										<div
											onClick={() => {
												setState({ ...state, openModal: true, selectedDate: item.dateActual });
											}}
											style={{ width: '100%', display: 'flex', height: ' 50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '5%' }}>{i + 1}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 16, width: '22%' }}>{item?.date}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '5%' }}>{item?.countData}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, fontStyle: 'arial', width: '16%' }}>{item?.recieptNumber}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, fontStyle: 'times', width: '8%' }}>{item?.specialFee1}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee2}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee3}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee4}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee5}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee6}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee7}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee8}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee9}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee10}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee11}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee12}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee13}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.totalAmount}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.paymentAmount}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
										</div>
										<div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black' }}></div>
									</>
								))}
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '16%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '5%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '22%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal1}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal2}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal3}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal4}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal5}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal6}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal7}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal8}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal9}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal10}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal11}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal12}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal13}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandTotal}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandPayment}</h5>
								</div>
								<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
							</div>
						)}
					</div>
					{state.openModal && <SubsidiaryRegisterMonthWise date={state?.selectedDate} onClose={() => setState({ ...state, openModal: false })} />}
				</Body>
			</Main>
			{/* {date.showEdit && <JournalEntry onClose={() => setDate({ ...date, showEdit: false, values: {} })} datavalues={date.values} setStateData={setDate} stateData={date} />}
			{date?.data?.length > 0 && (
				<div className='fixed z-30 h-screen w-screen top-0 left-0 flex justify-center items-center bg-[#000000a9]'>
					<DetailedReportTable date={date} setDate={setDate} account={date.itemName} />
				</div>
			)} */}
		</>
	);
};
