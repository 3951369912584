import React, { useContext, useRef } from 'react';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import CustomInput from '../../myComp/CustomInput';
import Button from '../../NecttosComp/Button/Button';
import { message } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import Input from '../../NecttosComp/Input/Input';
import { useAccountsHeads } from '../../NecttosComp/APICaller/queryHooks';

export const EditRecieptNo = ({ item, onClose, setStateData, isBillDelete, stateData, refetchDemo }) => {
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
		billNo: '',
		amount: '',
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const accounts = useAccountsHeads();
	const mapFeeAcounts = accounts?.data?.subAccounts || [];
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const years = [
		{ name: 'First Year', key: '1y' },
		{ name: 'Second Year', key: '2y' },
		{ name: 'Third Year', key: '3y' },
		{ name: 'Fourth year', key: '4y' },
		{ name: 'Fifth Year', key: '5y' },
		{ name: 'Year not specified', key: 'yearNotSpecified' },
	];

	const editRecieptNumber = async () => {
		if (isBillDelete) {
			if (!window.confirm('Are you sure you want to delete this record?')) {
				return;
			}
		} else {
			if (!window.confirm('Do you want to update changes?')) {
				return;
			}
		}

		try {
			const instance = await getAxiosTokenInstance();
			let resp = await instance.post('/college/action/editBillNo', {
				collegeId,
				feeId: item?.feeId,
				billNo: state?.billNo,
				amount: state?.amount,
				date: state?.date,
				year: state?.feesYear,
				oldAmount: item?.amount,
				accountId: item?.accountId || state.accountId,
				newAccountId: state.accountId,
				dueId: item?.dueId,
				isBillDelete,
				_id: item?._id,
				keyType: item?.keyType,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					feeId: '',
					billNo: '',
					amount: '',
				});
				refetchDemo();
				setStateData({ ...stateData, openModal: false });
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		}
	};

	return (
		<div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50'>
			<div className='w-full max-w-2xl bg-gray-100 rounded-lg p-4'>
				<div ref={componentRef} className='grid grid-cols-2 gap-4'>
					<div className='col-span-2 flex flex-col items-center space-y-2'>
						<img className='w-12 h-12 rounded' src={selectedCollege?.logo} alt='College Logo' />
						<h5 className='text-xl font-bold'>{isBillDelete ? 'Delete Receipt' : 'Edit Receipt'}</h5>
					</div>
					<div className='flex flex-col items-start '>
						<h5 className='text-lg pl-2'>Name: {item.userName}</h5>
						<h5 className='text-lg pl-2'>Admission No: {item.admisionNo}</h5>
						<h5 className='text-lg pl-2'>Bill Amount: {item.totalAmount}</h5>
						<h5 className='text-lg pl-2'>Bill Date: {item.date}</h5>
						<h5 className='text-lg pl-2'> Reciept No: {item?.billNo}</h5>

						<Toaster />
					</div>
					<div className='flex flex-col justify-center space-y-2'>
						{isBillDelete ? (
							<div>
								<p>Deleting Receipt is non reversible</p>
								<h6>Do you want to delete? </h6>
								<div className='flex'>
									<Button type='delete' onClick={editRecieptNumber}>
										Delete
									</Button>
									<Button type='close' onClick={onClose}>
										Close
									</Button>
								</div>
							</div>
						) : (
							<div className='flex flex-col'>
								{item.keyType === 'amount' ? (
									<>
										<h5 className='text-lg pl-2'>Bill No: {item?.billNo}</h5>
										<h5 className='text-lg pl-2'>
											Old {item?.keyData} Amt: {item?.amount}
										</h5>
										<Input width='100%' fieldName='Select Account ' returnKey='accountId' needCheck={true} didntShowKey optionDisplay='label' optionKey='value' state={state} setState={setState} options={mapFeeAcounts} type='drop' />
										<Input width='100%' fieldName='Select Fees Year' returnKey='feesYear' optionDisplay='name' optionKey='key' state={state} setState={setState} options={years} type='drop' />
										<CustomInput keyData='amount' titleData={`New ${item?.keyData} Amount`} typeData='number' stateData={state} setStateData={setState} />
									</>
								) : (
									<>
										<CustomInput keyData='billNo' titleData='New Receipt No' typeData='number' stateData={state} setStateData={setState} />
										<CustomInput keyData='date' titleData='New Date' typeData='date' stateData={state} setStateData={setState} />
									</>
								)}
								<div className='flex space-x-2 pl-2'>
									<Button type='update' onClick={editRecieptNumber}>
										Submit
									</Button>
									<Button type='close' onClick={onClose}>
										Close
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
