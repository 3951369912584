import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getEResources } from '../../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDown from '../../../myComp/CustomDropDown';
import { useDropDowns } from '../../../NecttosComp/APICaller/queryHooks';

export const EResources = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const { data = [], refetch } = useQuery('getEResources', getEResources(collegeId));
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState({});
	const { data: dropDown } = useDropDowns('library');

	const manageHolidays = async () => {
		try {
			setIsLoading(true);
			if (!state.title) {
				throw new Error('Title is Empty');
			}
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postEResources', {
				collegeId,
				title: state?.title,
				link: state?.link,
				type: state?.type,
				_id: state?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					title: '',
					link: '',
					type: '',
					_id: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<>
							<div className={classNames.list}>
								{state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}

								<CustomInput keyData={'title'} titleData={'Title of the Link'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomInput keyData={'link'} titleData={'Paste Link here'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomDropDown keyData={'type'} titleData={'Type of E Resources'} dropValues={dropDown?.eResources || []} stateData={state} setStateData={setState} />
								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												title: '',
												link: '',
												type: '',
												_id: '',
												_id: '',
												alert: '',
											});
										}}>
										{' '}
										Cancel{' '}
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											manageHolidays();
										}}>
										Submit
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} className='printable'>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>E Resources Management</h5>
								<div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '15%' }}>Type</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '35%' }}>Title of the Link</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '60%' }}>Link </h5>
								</div>
								{data?.map((item, i) => (
									<>
										<div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '15%' }}>{item?.type}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '35%' }}>{item?.title}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '60%' }}>{item?.link}</h5>
											<Button
												className={classNames.submitBotton}
												onClick={() => {
													setState({
														...state,
														title: item?.title || '',
														link: item?.link || '',
														type: item?.type || '',
														_id: item?._id || '',
													});
												}}>
												Edit
											</Button>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												height: 1,
												backgroundColor: 'black',
											}}></div>
									</>
								))}
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>

			<Button
				className={classNames.reloadButton}
				onClick={() => {
					refetch();
				}}>
				Reload
			</Button>

			<ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />

			{!isLoading && (
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			)}
		</div>
	);
};
