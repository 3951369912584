import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getInternalAssessment, getLeaveReport } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
import CustomDropDownKey from '../../myComp/CustomDropDownKey';
import Input from '../../NecttosComp/Input/Input';
import moment from 'moment';

export const LeaveReports = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef(null);
	const ref2 = useRef(null);
	const [state, setState] = useState({});
	const [rotate, setRotate] = useState(false);
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const STAFF_TYPE = [
		{ _id: 'staff', name: 'NON-TEACHING STAFF' },
		{ _id: 'teacher', name: 'TEACHER' },
		{ _id: 'library', name: 'LIBRARIAN' },
	];
	const LEAVE_TYPE = [
		{ _id: 'cl', name: 'CL' },
		{ _id: 'dl', name: 'DL' },
		{ _id: 'tr52', name: 'TR52' },
	];
	const { data: leaveData } = useQuery([`getLeaveReport`, collegeId, state?.staffType, state?.startDate, state?.endDate], getLeaveReport({ collegeId, staffType: state?.staffType, startDate: state?.startDate, endDate: state?.endDate }));

	const [staffType, setStaffType] = useState(STAFF_TYPE);

	const PrintStyles = () => {
		return (
			<style>
				{`
					@media print {
						@page {
							size: A4 landscape;
							margin: 0; /* Optional: Remove default margins */
						}
						body {
							-webkit-print-color-adjust: exact; /* Ensures colors print accurately */
						}
					}
				`}
			</style>
		);
	};
	const PrintStyles2 = () => {
		return (
			<style>
				{`
					@media print {
						@page {
							size: A4 portrait;
							margin: 0; /* Optional: Remove default margins */
						}
						body {
							-webkit-print-color-adjust: exact; /* Ensures colors print accurately */
						}
					}
				`}
			</style>
		);
	};

	// const initialData = {
	// 	CL: [
	// 		{
	// 			name: 'Test',
	// 			designation: 'clerk',
	// 			totalCasualLeave: '20',
	// 			leaveTakenOnPrevMonth: 1,
	// 			dateOfCasualLeave: [
	// 				{ date: new Date(), day: 'AN' },
	// 				{ date: new Date(), day: 'FULL' },
	// 				{ date: new Date(), day: 'FN' },
	// 			],
	// 			totalCasualLeaveThisMonth: '1 1/2',
	// 			totalCasualLeaveTaken: '1 1/2',
	// 			balanceCasualLeaveTaken: '18 1/2',
	// 			remarks: '',
	// 		},
	// 		{
	// 			name: 'ajith',
	// 			designation: 'clerk',
	// 			totalCasualLeave: '20',
	// 			leaveTakenOnPrevMonth: 1,
	// 			dateOfCasualLeave: [
	// 				{ date: new Date(), day: 'AN' },
	// 				{ date: new Date(), day: 'FULL' },
	// 				{ date: new Date(), day: 'FN' },
	// 			],
	// 			totalCasualLeaveThisMonth: '1 1/2',
	// 			totalCasualLeaveTaken: '1 1/2',
	// 			balanceCasualLeaveTaken: '18 1/2',
	// 			remarks: '',
	// 		},
	// 	],
	// 	DL: [
	// 		{
	// 			name: 'Test',
	// 			natureOfDuty: 'clerk',
	// 			totalLeaveTakenUpto: '1',
	// 			leaveTakenThisMonth: [
	// 				{ date: new Date(), day: 'AN' },
	// 				{ date: new Date(), day: 'FULL' },
	// 			],
	// 			totalDays: '1',
	// 			totalLeaveTaken: '1',
	// 		},
	// 		{
	// 			name: 'abi',
	// 			natureOfDuty: 'clerk',
	// 			totalLeaveTakenUpto: '1',
	// 			leaveTakenThisMonth: [
	// 				{ date: new Date(), day: 'AN' },
	// 				{ date: new Date(), day: 'FULL' },
	// 			],
	// 			totalDays: '1',
	// 			totalLeaveTaken: '1',
	// 		},
	// 	],
	// 	TR52: [
	// 		{
	// 			name: 'Test',
	// 			actualRateofSubPay: 'clerk',
	// 			designation: 'clerk',
	// 			kind: 'lwa',
	// 			totalDays: 1,
	// 			from: new Date(),
	// 			to: new Date(),
	// 			rateOfAbsenteeAllowance: 1,
	// 			attachemnt: 'order attachement',
	// 			substantivePost: 'order attachement',
	// 			substantivePay: 100,
	// 			additionalPay: 10,
	// 		},
	// 		{
	// 			name: 'Test2',
	// 			actualRateofSubPay: 'clerk',
	// 			designation: 'clerk',
	// 			kind: 'lwa',
	// 			totalDays: 1,
	// 			from: new Date(),
	// 			to: new Date(),
	// 			rateOfAbsenteeAllowance: 1,
	// 			attachemnt: 'order attachement',
	// 			substantivePost: 'order attachement',
	// 			substantivePay: 100,
	// 			additionalPay: 10,
	// 		},
	// 	],
	// 	TR52JoiningReport: [
	// 		{
	// 			name: 'Test1',
	// 			designation: 'clerk',
	// 			natureOfLeave: 'clerk',
	// 			startOfLeave: new Date(),
	// 			endOfLeave: new Date(),
	// 			prefix: 'NIL',
	// 			suffix: new Date(),
	// 			joiningDate: new Date(),
	// 		},
	// 		{
	// 			name: 'Test2',
	// 			designation: 'clerk',
	// 			natureOfLeave: 'clerk',
	// 			startOfLeave: new Date(),
	// 			endOfLeave: new Date(),
	// 			prefix: 'NIL',
	// 			suffix: new Date(),
	// 			joiningDate: new Date(),
	// 		},
	// 	],
	// };

	const CL = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='10' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='10' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								ATTENDANCE STATEMENT FOR THE MONTH FROM {moment(state?.startDate).format('DD-MM-YYYY')} to {moment(state?.endDate).format('DD-MM-YYYY')} ( {staffType.find((x) => x._id === state?.staffType)?.name} ) ({title})
							</th>
						</tr>

						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2'>
								SL NO.
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2'>
								NAME
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap' }}>
								DESIGNATION
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								TOTAL CASUAL LEAVE
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-rl', height: '120px' }}>
								LEAVE TAKEN DURING THE PREVIOUS MONTH
							</th>
							<th colSpan='2' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }} rowSpan='1'>
								LEAVE TAKEN DURING THE MONTH
							</th>

							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								TOTAL CASUAL LEAVE TAKEN
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								BALANCE CASUAL LEAVE TAKEN
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								REMARKS
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								DATE OF CASUAL LEAVE
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
								TOTAL CASUAL LEAVE
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '40px' }}>
								1
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '260px' }}>
								2
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '160px' }}>
								3
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}>
								4
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '40px' }}>
								5
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '190px' }}>
								6
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}>
								7
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}>
								8
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}>
								9
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}>
								10
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left'>{index + 1}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.name}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.designation}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalCasualLeave}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.leaveTakenOnPrevMonth}</td>
								<td className='font-medium border border-black p-2 text-left'>
									{row?.dateOfCasualLeave?.map((x, i) => (
										<span>{moment(x?.date).format('DD/MM/YY') + ` ${x?.day !== 'FULL' ? x?.day : ''} `}</span>
									))}
								</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalCasualLeaveThisMonth}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalCasualLeaveTaken}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.balanceCasualLeaveTaken}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.remarks}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};
	const DL = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='10' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='10' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								ATTENDANCE STATEMENT FOR THE MONTH FROM {moment(state?.startDate).format('DD-MM-YYYY')} to {moment(state?.endDate).format('DD-MM-YYYY')} ( {staffType.find((x) => x._id === state?.staffType)?.name} ) ({title})
							</th>
						</tr>

						<tr style={{ height: '100px' }}>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '50px' }}>
								SL NO.
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '250px' }}>
								Name
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '250px' }}>
								Nature of Duty
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', width: '60px' }}>
								Total Leave Taken upto
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '150px' }}>
								Leave Taken this Month
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '70px' }}>
								Total Days
							</th>

							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', width: '70px', whiteSpace: 'auto' }}>
								Total Leave Taken upto {'end Date'}
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left'>{index + 1}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.name}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.natureOfDuty}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalLeaveTakenUpto}</td>
								<td className='font-medium border border-black p-2 text-left'>
									{row?.leaveTakenThisMonth?.map((x, i) => (
										<span>{moment(x?.date).format('DD/MM/YY') + ` ${x?.day !== 'FULL' ? x?.day : ''} `}</span>
									))}
								</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalDays}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.totalLeaveTaken}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};
	const TR52 = ({ title, data }) => {
		return (
			<div className='p-10 w-full mx-auto bg-white rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg print:text-sm' style={{ fontFamily: 'Times New Roman', fontWeight: 'normal' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='13' className='border border-black p-2 text-center font-bold text-sm' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='13' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								({title}) {staffType.find((x) => x._id === state?.staffType)?.name} ( {moment(state?.startDate).format('DD-MM-YYYY')} to {moment(state?.endDate).format('DD-MM-YYYY')} )
							</th>
						</tr>
						<tr>
							<th colSpan='9' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								NATURE OF ABSENCE
							</th>
							<th colSpan='4' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								Officiating Government Servent(if any)
							</th>
						</tr>

						<tr style={{ height: '100px' }}>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								SL No.
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Name of Absentee
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Actual rate of substantive pay (Rupees per month)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Designation and rate of pay of vacant post (Rupees per month)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Kind
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Period (Total Days)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								From (A.M or P.M)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								To (A.M or P.M)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Rate of Absentee allowance (Rupees per month)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Attachment
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Substantive post
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Substantive pay (Rupees per month)
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1'>
								Additional pay for officiating (Rupees per month)
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '60px' }}></th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '260px' }}>
								1
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '100px' }}>
								2
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '160px' }}>
								3
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '80px' }}>
								4
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '80px' }}>
								5
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '100px' }}>
								6
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '100px' }}>
								7
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '80px' }}>
								8
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '260px' }}>
								9
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '100px' }}>
								10
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '80px' }}>
								11
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' rowSpan='1' style={{ width: '80px' }}>
								12
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{index + 1}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.name}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.actualRateofSubPay}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.designation}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.kind}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.totalDays}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{moment(row?.from).format('DD/MM/YY')}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{moment(row?.to).format('DD/MM/YY')}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.rateOfAbsenteeAllowance}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.attachemnt}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.substantivePost}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.substantivePay}</td>
								<td className='font-medium border border-black p-2 text-left print:text-[10px]'>{row?.additionalPay}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};
	const TR52JoiningReport = ({ title = 'JOINING REPORT STATEMENT FOR THE PERIOD', data }) => {
		return (
			<div className='p-10 w-full mx-auto bg-white rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg print:text-sm' style={{ fontFamily: 'Times New Roman', fontWeight: 'normal' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='6' className='border border-black p-2 text-center font-bold text-sm' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='6' className='border border-black p-2 text-center font-bold  text-sm print:text-xs' style={{ fontSize: '16px' }}>
								({title}) {moment(state?.startDate).format('DD-MM-YYYY')} to {moment(state?.endDate).format('DD-MM-YYYY')} {staffType.find((x) => x._id === state?.staffType)?.name}
							</th>
						</tr>

						<tr style={{ height: '100px' }}>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '360px' }}>
								Name & Designation
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '360px' }}>
								Nature of Leave
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '360px' }}>
								Period of Leave
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '200px' }}>
								Prefix
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '200px' }}>
								Suffix
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold text-sm print:text-xs' rowSpan='1' style={{ width: '200px' }}>
								Joining Date
							</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{`${row?.name} ${row.designation}`} </td>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{row?.natureOfLeave}</td>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{`${moment(row?.startOfLeave).format('DD/MM/YY')} to ${moment(row?.endOfLeave).format('DD/MM/YY')}`}</td>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{moment(row?.prefix, moment.ISO_8601, true).isValid() ? moment(row?.prefix).format('DD-MM-YY') : row?.prefix}</td>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{moment(row?.suffix, moment.ISO_8601, true).isValid() ? moment(row?.suffix).format('DD-MM-YY') : row?.suffix}</td>
								<td className='font-medium border border-black p-2 text-center print:text-[10px]'>{moment(row?.joiningDate, moment.ISO_8601, true).isValid() ? moment(row?.joiningDate).format('DD-MM-YY') : row?.joiningDate}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	return (
		<Main title='Leave Reports' width='100vw' height='100vh'>
			<Header>
				<div className='row flex'>
					<div style={{ width: 550 }}>
						<Input type='drop' options={STAFF_TYPE || []} state={state} fieldName='Select Role' optionKey='_id' didntShowKey optionDisplay='name' returnKey='staffType' setState={setState} width='90%' />
					</div>
					<div className='flex w-auto'>
						<Input type='date' fieldName='Start Date' state={state} setState={setState} returnKey='startDate' width='10vw' />
						<Input type='date' fieldName='End Date' returnKey='endDate' state={state} setState={setState} width='10vw' />
					</div>
					{/* <div style={{ width: 550 }}>
						<Input type='drop' options={LEAVE_TYPE || []} state={state} fieldName='Select Leave Type' optionKey='_id' didntShowKey optionDisplay='name' returnKey='leaveType' setState={setState} width='90%' />
					</div> */}
				</div>
				<div className='flex gap-2'>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
			</Header>

			<Body width='100vw' height='90vh'>
				<div className='  p-4  w-full h-full overflow-y-scroll'>
					<div className='container flex justify-end bg-teal-950 opacity-80 rounded p-2'>
						<Button type='print' tableRef={ref.current}>
							print
						</Button>
					</div>
					<section ref={ref} className='container printable bg-white p-6  '>
						<PrintStyles2 />
						<CL title={'CL'} data={leaveData?.CL} />

						<hr className='text-lg  h-4 bg-transparent w-full' />
						<DL title={'DL'} data={leaveData?.DL} />
					</section>
					<div className=' flex justify-end bg-teal-950 rounded p-2 mt-4'>
						<Button type='print' tableRef={ref2.current}>
							print
						</Button>
					</div>
					<section ref={ref2} className='w-full h-full printable bg-white'>
						<PrintStyles />

						<TR52 title={'TR52'} data={leaveData?.TR52} />
						<TR52JoiningReport title={'TR52 Joining Report'} data={leaveData?.TR52JoiningReport} />
					</section>
				</div>
			</Body>
		</Main>
	);
};
