import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import SearchableUserInputPhoto from '../../myComp/SearchableUserInputPhoto';
import { getProfile } from '../../NecttosComp/APICaller/queryHooks';
import { getCollegeDetails } from '../Form/Register';

interface invoiceManagementProps {
	onClose: () => void;
}

function PriorityChanger({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>({});
	const { collegeId, user } = useContext(FirebaseContext);
	const [values, setValues] = useState<any>({});
	const { data, refetch } = useQuery([`profileData`, collegeId, addNew?._id], getProfile);
	const [classData, setClassData] = useState([]);
	const { data: classes } = useQuery([`collegeData`, collegeId], getCollegeDetails);
	const [selectedClass, setSelectClass] = useState('');

	useEffect(() => {
		if (data) {
			setValues(data);
		}
	}, [data]);

	const addNewForsm = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/makeDirectAdmission', {
				studentId: data?._id,
				collegeId,
				classId: selectedClass,
				userId: user?.uid,
				addNew,
			});
			if (resp?.data?.statusCode === 200) {
				setAddNew('');
			} else {
				setAddNew('');
			}
		} catch (error) {
			setAddNew('');
		}
	};

	const addNewForm = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/priorityCorrection', {
				studentId: values?._id,
				collegeId,
				preferedCourse1: values.preferedCourse1,
				preferedCourse2: values.preferedCourse2,
				preferedCourse3: values.preferedCourse3,
				preferedCourse4: values.preferedCourse4,
				preferedCourse5: values.preferedCourse5,
				preferedCourse6: values.preferedCourse6,
				preferedCourse7: values.preferedCourse7,
				preferedCourse8: values.preferedCourse8,
				preferedCourse9: values.preferedCourse9,
				preferedCourse10: values.preferedCourse10,
				userId: user?.uid,
			});
			if (resp?.data?.statusCode === 200) {
				setAddNew({});
			} else {
			}
		} catch (error) {}
	};
	const courseKeys = ['preferedCourse1', 'preferedCourse2', 'preferedCourse3', 'preferedCourse4', 'preferedCourse5', 'preferedCourse6', 'preferedCourse7', 'preferedCourse8', 'preferedCourse9', 'preferedCourse10'];

	const dataValues = useMemo(() => {
		return courseKeys?.map((key) => values[key])?.filter(Boolean);
	}, [values]);

	const newClassData = useMemo(() => {
		return classes?.filter((classItem: any) => !dataValues.includes(classItem._id));
	}, [classes, dataValues]);

	useEffect(() => {
		if (newClassData?.length !== classData?.length) {
			setClassData(newClassData);
		}
	}, [newClassData, classData?.length]);

	return (
		<>
			<Main title='Applications Initiated' width='100%' height='100%'>
				<Header>
					<div className=' flex w-50%'>
						<SearchableUserInputPhoto primoryKey={'applicationNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} collection={'users'} stateData={addNew} setStateData={setAddNew} titleData={'Search Admision No or Name'} />
					</div>
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								if (window.confirm('Are you sure you want to save?')) {
									addNewForm();
								}
							}}>
							Save
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				{addNew?._id && (
					<Body>
						<div className='max-w-[800px'>
							<div id='preference' className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4'>
								<Input fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='preferedCourse1' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
								<div>Reg: Id: {values.preferedCourse1}</div>
								<Input fieldName='Priority 2' setState={setValues} state={values} didntShowKey returnKey='preferedCourse2' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
								<div>Reg: Id: {values.preferedCourse2}</div>
								<Input fieldName='Priority 3' setState={setValues} state={values} didntShowKey returnKey='preferedCourse3' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
							</div>
							<p>Admission To</p>
							<Input fieldName='Filter by Class' setState={setSelectClass} state={selectedClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes} type='drop' width='100%' />
							<div className='flex flex-grow self-end'>
								<Input width='200px' fieldName='I Verified' returnKey='diclarationFinal' state={addNew} setState={setAddNew} type='checkbox' />
								<Button
									width='150px'
									height='60px'
									fontSize='20px'
									type='save'
									disabled={!addNew?.diclarationFinal}
									onClick={() => {
										addNewForsm();
									}}>
									Submit
								</Button>
							</div>
						</div>
					</Body>
				)}
			</Main>
		</>
	);
}

export default PriorityChanger;
