import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getAllAccademics, getAllEnquiry, getNewClasses } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getAllClasses } from '../Academics/BEDStudent';
import ApplicationView from './ApplicationView';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';

interface invoiceManagementProps {
	onClose: () => void;
}

function ApplicationStarted({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [allowed, setAllow] = useState(false);

	const keyData = 'applicationStated';

	const { data: tableData = [], refetch } = useQuery(['getAlleeeessddseeeEnquiry', selectedClass, searchTerm], getAllEnquiry(collegeId, selectedClass, searchTerm, keyData));
	const { data: classes } = useQuery('getNeeeewCladfdsfsdsses', getNewClasses(collegeId, user?.uid));

	const [filterdata, setFilter] = useState<any>([]);
	const [filterKey, setFilterKey] = useState('ALL');
	useEffect(() => {
		if (!filterKey || !tableData) return;

		switch (filterKey) {
			case 'UG':
				setFilter(tableData.filter((data: any) => ['2024-2028', '2024-2027', '2024-2029'].includes(data.batchName)));
				break;
			case 'PG':
				setFilter(tableData.filter((data: any) => ['2024-2026'].includes(data.batchName)));
				break;
			case 'ALL':
				setFilter([...tableData]); // Creating a new array reference
				break;
			default:
				break;
		}
	}, [filterKey, tableData]);
	const { tableItems, requestSort, getIcon } = useSortableData(filterdata);

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteAccadamicHoliday/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const { data: userDetail } = useProfileData(user.uid);

	useEffect(() => {
		if (userDetail && userDetail?.collegeRoles) {
			const roleTypes = userDetail?.collegeRoles?.filter((role: any) => role.collegeId === collegeId)?.map((role: any) => role.type);
			if (roleTypes.includes('cashier')) setAllow(true);
		}
	}, [userDetail?.collegeRoles, collegeId]);

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postAccadamicHolidays', { ...addNew, collegeId })
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	const skipBill = async (id: any) => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/skipBill', {
				_id: id,
				collegeId,
				managedBy: user?.uid,
				boolType: false,
			});
			if (resp?.data?.statusCode === 200) {
				refetch();
			} else {
			}
		} catch (error) {}
	};

	return (
		<>
			<Main title='Applications Initiated' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
						<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />
						<view className='h-[50px]  rounded-md p-3 m-2 px-2 bg-white'>
							<h5> Total Applications: {tableItems?.length}</h5>
						</view>
					</div>
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								setFilterKey('ALL');
								setSelectClass('');
							}}>
							Show All
						</Button>
						<Button
							type='fetch'
							onClick={() => {
								setFilterKey('UG');
								setSelectClass('');
							}}>
							Show UG
						</Button>
						<Button
							type='doc'
							onClick={() => {
								setFilterKey('PG');
								setSelectClass('');
							}}>
							Show PG
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Thead>
						<Tr>
							<Th position={0}>Sl.No.</Th>
							<Th position={1} onClick={() => requestSort('createdOn')} icon={getIcon('createdOn')}>
								Date
							</Th>
							<Th position={0} onClick={() => requestSort('applicationNo')} icon={getIcon('applicationNo')}>
								Appl. No
							</Th>
							<Th position={2} onClick={() => requestSort('name')} icon={getIcon('name')}>
								Name
							</Th>
							<Th position={1}>Mob 1 w</Th>
							<Th position={1}>Mob 2 G</Th>
							<Th position={1}>Mob 3 S</Th>
							<Th position={0} onClick={() => requestSort('percentageFilled')} icon={getIcon('percentageFilled')}>
								Percentage
							</Th>
							<Th position={1} onClick={() => requestSort('coursePreference')} icon={getIcon('coursePreference')}>
								Course Preference
							</Th>
							<Th position={1}>Vew Application</Th>
							<Th position={1}>Payment</Th>
						</Tr>
					</Thead>
					<div ref={ref} className='h-[85vh] pt-2 printable overflow-auto'>
						<Table width='100%' innerWidth={['5%', '10%', '15%', '25%', '10%', '10%']}>
							<Tbody height='auto'>
								{tableItems?.map((x: any, i: number) => (
									<Tr>
										<Td index={i} position={0}>
											{i + 1}
										</Td>
										<Td index={i} position={1}>
											{moment(x?.createdOn).format('DD-MM-YYYY')}
										</Td>
										<Td index={i} position={0}>
											{x?.applicationNo}
										</Td>
										<Td index={i} position={2}>
											{x?.name}
										</Td>
										<Td index={i} position={1}>
											{x?.whatsappNumber}
										</Td>
										<Td index={i} position={1}>
											{x?.guardianMobile}
										</Td>
										<Td index={i} position={1}>
											{x?.phoneNumber}
										</Td>
										<Td index={i} position={0}>
											{x?.percentageFilled?.toFixed(2)}
										</Td>
										<Td index={i} position={1}>
											{x?.coursePreference}
										</Td>
										<Td index={i} position={1}>
											<Button
												type='doc'
												onClick={() => {
													setAddNew({ ...addNew, viewMode: true, _id: x._id, tyeUG: x.batchName === '2024-2026' ? 'PG' : 'UG' });
												}}>
												ViewApp
											</Button>
										</Td>
										{allowed && (
											<Td index={i} position={2}>
												<Button
													type='close'
													onClick={() => {
														skipBill(x?._id);
													}}>
													Skip Payment
												</Button>
											</Td>
										)}
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				{addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
			</Main>
			{showForm && (
				<Main width='40vw'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'>
							<Input width='100%' fieldName='Call To and details' returnKey='tittle' state={addNew} setState={setAddNew} type='text' />
							<Input width='100%' fieldName='Date' returnKey='startDate' state={addNew} setState={setAddNew} type='date' />
							<Button
								type='save'
								onClick={() => {
									addNewForm();
								}}>
								Save
							</Button>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default ApplicationStarted;
