import React, { useContext, useEffect, useState } from 'react';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import { FaCog } from 'react-icons/fa';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import { useQuery } from 'react-query';
import { RiH1 } from 'react-icons/ri';
import Metric1of1 from './Attributes/Attribute1/Metric1of1';
import Metric1of2 from './Attributes/Attribute1/Metric1of2';
import Select from 'react-select';
import { components } from 'react-select';
import toast from 'react-hot-toast';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { IoMdArrowRoundBack } from 'react-icons/io';

function Metric({ criteriaOpen, setCriteriaOpen, selectedCriteria, setSelectedCriteria }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const [dropCoordinators, setDropCoordinators] = useState();
	const [attributeData, setAttributeData] = useState();
	const [chiefCoordinator, setChiefCoordinator] = useState(null);
	const [subordinateByMetrics, setSubordinateByMetrics] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [metricSettings, setMetricSettings] = useState(false);
	const [collegeNaacData, setCollegeNaacData] = useState([]);
	const [data, setData] = useState({});
	const [attributeTotal, setAttributeTotal] = useState('');
	const [naacData, setNaacData] = useState([]);
	const [metricData, setMetricData] = useState([]);
	const [enableMetric, setEnableMetric] = useState('');
	const [selectedMetric, setSelectedMetric] = useState('');
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const getNaacAttribute = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getNaacAttribute?collegeId=${collegeId}&type=naacReform2024&attribute=${selectedCriteria.keyName}`;
			const res = await instance.get(url);
			const data = res.data.list;
			const total = data.metric
			.flatMap((x) => x.details.filter((y) => y.institutionType === collegeData.institutionType))
			.reduce((acc, el) => acc + el.max, 0);
			setAttributeTotal(total)
			setData(data);
		} catch (error) {
			console.error(error);
		}
	};

	const getCoordinators = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getCoordinators?collegeId=${collegeId}`;
			const res = await instance.get(url);
			setDropCoordinators(res?.data?.list);
		} catch (error) {
			console.error(error);
		}
	};

	const getNaacCoordinators = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getNaacCoordinators?collegeId=${collegeId}&type=naac2024Coordinators`;
			const res = await instance.get(url);
			const collegeNaacData = res?.data?.list[0]?.collegeNaacData;
			console.log({ collegeNaacData });
			setSubordinateByMetrics(res.data.list[0].subordinatesByMetrics);
			let coordinatorsArray;
			if (Array.isArray(collegeNaacData)) {
				coordinatorsArray = collegeNaacData;
			} else if (collegeNaacData) {
				coordinatorsArray = Object.keys(collegeNaacData)?.map((key) => ({
					keyName: key,
					...collegeNaacData[key],
				}));
			} else {
				coordinatorsArray = [];
			}

			const attributeData = coordinatorsArray.filter((x) => x.keyName === selectedCriteria.keyName);
			setAttributeData(attributeData[0]);
		} catch (error) {
			console.error(error);
		}
	};

	const customOption = (props) => {
		return (
			<components.Option {...props}>
				<div className='flex items-center'>
					<img src={props?.data?.photoURL} alt={props?.data?.name} className='w-6 h-6 rounded-full mr-2' />
					<span>{props?.data?.name}</span>
				</div>
			</components.Option>
		);
	};

	// const updateAttributeCoordinators = (keyName, coordinator, subordinates, obtained) => {
	// 	setCollegeNaacData((prev) => {
	// 		const index = prev?.findIndex((item) => item?.keyName === keyName);
	// 		if (index >= 0) {
	// 			const newArray = [...prev];
	// 			newArray[index] = { ...newArray[index], coordinator, subordinates, obtained };
	// 			return newArray;
	// 		} else {
	// 			return [...prev, { keyName, coordinator, subordinates, obtained }];
	// 		}
	// 	});
	// };
	// const handleSave = async () => {
	// 	try {
	// 		const instance = await getAxiosInstance();
	// 		const url = '/college/naacReforms2024/saveNaacCoordinators';
	// 		const data = {
	// 			updatedBy: user?.uid,
	// 			collegeId,
	// 			collegeNaacData,
	// 			chiefCoordinator,
	// 			type: 'naac2024Coordinators',
	// 		};
	// 		await instance?.post(url, data);
	// 		toast.success('Saved successfully');
	// 	} catch (error) {
	// 		console.error(error);
	// 		toast.error('Failed to save ');
	// 	}
	// };
	const saveSubordinates = async (attributeKey, metricId, subordinate) => {
		try {
			console.log('save');
			const instance = await getAxiosInstance();
			const url = '/college/naacReforms2024/saveSubordinates';
			const data = {
				updatedBy: user?.uid,
				collegeId,
				type: 'naac2024Coordinators',
				attributeKey,
				metricId,
				subordinate: subordinate[0],
			};
			await instance?.post(url, data);
			toast.success('Saved successfully');
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
			toast.error('Failed to save ');
		}
	};
	const deleteSubordinate = async (attributeKey, metricId, subordinate) => {
		try {
			console.log('save');
			const instance = await getAxiosInstance();
			const url = '/college/naacReforms2024/deleteSubordinates';
			const data = {
				updatedBy: user?.uid,
				collegeId,
				type: 'naac2024Coordinators',
				attributeKey,
				metricId,
				subordinate,
			};
			const res = await instance?.post(url, data);
			console.log({ deleteRes: res });
			toast.success('deleted successfully');
			setRefresh(!refresh);
		} catch (error) {
			console.error(error);
			toast.error('Failed to save ');
		}
	};

	console.log({ attributeData });
	console.log({ selectedCriteria });
	console.log({ selectedMetric });

	useEffect(() => {
		getNaacCoordinators();
	}, [refresh]);
	useEffect(() => {
		// getNaacReforms();
		getNaacAttribute();
		getCoordinators();
	}, []);

	return (
		<Main width='100vw' height='100vh' title={selectedCriteria.title}>
			<div className='flex justify-between bg-[#2F3645] items-center pt-2  rounded  h-16 mt-[-20px] mx-[-5px]'>
				<h3 className='text-white pl-6'>{selectedCriteria?.subTitle?.toUpperCase()}</h3>
				<div className='flex pb-2'>
					<Button
						type='close'
						onClick={() => {
							setCriteriaOpen(false);
							setSelectedCriteria('');
						}}>
						<IoMdArrowRoundBack /> back
					</Button>
				</div>
			</div>

			<div className='w-full px-2 h-full'>
				<div className='flex justify-between w-full '>
					<div className='w-56 absolute right-36 top-0'>
						<div className='flex flex-col h-auto pt-2 m-0'>
							{attributeData && attributeData?.coordinator && (
								<div className='flex items-center mt-1 space-x-3'>
									<img src={dropCoordinators?.find((x) => x._id === attributeData?.coordinator)?.photoURL} alt={dropCoordinators?.find((x) => x?._id === attributeData?.coordinator)?.name} className='w-10 h-10 rounded-full shadow-md hover:scale-105 transition-transform' />
									<div className='flex flex-col'>
										<span className='text-xs font-semibold italic text-white'>Coordinator</span>
										<span className='text-sm font-medium text-white'>{dropCoordinators?.find((x) => x?._id === attributeData?.coordinator)?.name}</span>
									</div>
								</div>
							)}

							{/* {attributeData && attributeData?.subordinates && (
								<div className='flex items-center mt-1 space-x-2 gap-4'>
									{attributeData?.subordinates?.slice(0, 3).map((subId, i) => {
										const subordinate = dropCoordinators?.find((x) => x?._id === subId);
										return (
											<div key={i} className='flex items-center space-x-1 w-auto h-auto'>
												<img src={subordinate?.photoURL} alt={subordinate?.name} className='w-8 h-8 rounded-full shadow-sm hover:scale-105 transition-transform object-cover aspect-square' />
												<div className='flex flex-col'>
													<span className='text-[7px] font-semibold italic text-white'>Subordinate</span>
													<span className='text-[9px] font-medium text-white'>{subordinate?.name}</span>
												</div>
											</div>
										);
									})}
									{attributeData?.subordinates?.length > 3 && <span className='text-xs text-white font-base'>+{attributeData?.subordinates?.length - 3} more</span>}
								</div>
							)} */}
						</div>
					</div>
				</div>
				<section className='flex justify-between mt-0 pt-0 h-[88vh] w-full'>
					<article className='flex-grow overflow-y-scroll'>
						<div className='flex flex-wrap justify-center'>
							{data?.metric?.map((item, index) => {
								console.log({ item });
								const institutionType = Boolean(item?.details?.find((x) => x?.institutionType === collegeData?.institutionType));
								console.log({institutionType})
								return (
									<div
										key={index}
										style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
										onClick={() => {
											setEnableMetric({
												...item,
												details: item.details.filter((x) => x?.institutionType === collegeData?.institutionType)[0],
											});
										}}
										className=''>
										<div
											style={{
												backgroundColor: 'white',
												boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
												borderRadius: '12px',
												padding: '15px',
												width: '480px',
												maxWidth: '480px',
												margin: '14px',
												display: 'flex',
												alignItems: 'flex-start',
												justifyContent: 'space-between',
												height: '340px',
											}}
											className='relative'>
											
											<div id='123' className='flex flex-col justify-between h-full w-full flex-grow'>
												<div className='flex justify-between w-full'>
												{institutionType
												 &&
												 <FaCog
														onClick={(e) => {
															e.stopPropagation();
															setMetricSettings(true);
															setSelectedMetric(item);
														}}
														className=' text-gray-800 absolute right-1 top-1 cursor-pointer'
														size={20}
													/>
												 }
													
												</div>
												<div className='flex-1'>
													<p style={{ fontSize: '14px', fontWeight: 'normal' }} className='leading-3'>
														{item?.title}
													</p>
													<p style={{ fontSize: '14px', fontWeight: 'bold' }} className='leading-3'>
														{item?.subTitle}
													</p>
												</div>
												<div className='w-full h-full justify-center items-center flex-1'>
													{(() => {
														const metdet = item?.details?.find((x) => x?.institutionType === collegeData?.institutionType);

														if (metdet) {
															return (
																<>
																	<p className='text-sm font-bold'>{metdet?.title}</p>
																	<p className='text-sm'>{metdet?.description}</p>
																	{/* <div className='flex flex-col space-y-2'>
																		{metdet?.options?.map((y) => (
																			<div key={y.keyName} className='flex  w-auto m-0 p-0'>
																				<input type='checkbox' id={y.keyName} placeholder={y.title} className=' w-auto mx-2 my-0 py-0 leading-3' />
																				<label htmlFor={y.keyName} className='text-sm pt-2 leading-3'>
																					{y.title}
																				</label>
																			</div>
																		))}
																	</div> */}
																	{metdet.inputType === 'checkbox' && (
																		<div className='flex flex-col space-y-2'>
																			{metdet?.options?.map((y) => (
																				<div key={y.keyName} className='flex  w-auto m-0 p-0'>
																					<span className='text-sm pt-2 leading-3'>{y.title}</span>
																				</div>
																			))}
																		</div>
																	)}
																	{metdet.inputType === 'list' && (
																		<div className='flex flex-col space-y-2'>
																			{metdet?.options?.map((y, i) => (
																				<div key={i} className='flex  w-auto m-0 p-0'>
																					<span className='text-xs pt-2 leading-3'>{y}</span>
																				</div>
																			))}
																		</div>
																	)}
																</>
															);
														} else {
															return (
																<div className='flex justify-center w-full h-full'>
																	<h5>Not Applicable</h5>
																</div>
															);
														}
													})()}
												</div>

												<div className=' flex mt-1'>
													{subordinateByMetrics
														?.find((x) => x.keyName === item?.keyName)
														?.subordinates?.map((x) => {
															const sub = dropCoordinators?.find((y) => y?._id === x);
															return (
																<div key={sub?.name} className='flex items-center space-x-1 w-auto h-auto'>
																	<img src={sub?.photoURL} alt={sub?.name} className='w-8 h-8 rounded-full shadow-sm hover:scale-105 transition-transform object-cover aspect-square' />
																	<div className='flex flex-col'>
																		<span className='text-[7px] font-semibold italic text-dark'>Subordinate</span>
																		<span className='text-[9px] font-medium text-dark'>{sub?.name}</span>
																	</div>
																	{/* <span className='text-lg cursor-pointer' onClick={() => deleteSubordinate(selectedCriteria.keyName, selectedMetric.keyName, x)}> <RiDeleteBack2Fill /></span> */}
																</div>
															);
														})}
												</div>
											</div>
											<div className='h-full'>
												{(() => {
													const institutionDetail = item?.details?.find((x) => x?.institutionType === collegeData?.institutionType);
													if (!institutionDetail) return null;

													return (
														<div className='flex flex-col justify-between items-end w-auto gap-2 h-full'>
															<div
																style={{
																	width: '80px',
																	height: '80px',
																	borderRadius: '50%',
																	border: '2px solid #10B981',
																}}
																className='flex flex-col justify-center items-center'>
																<span className='text-xs pt-2'>Obtained</span>
																<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.metric?.find((x) => x.keyName === item.keyName)?.mark ?? 0}</span>
															</div>

															<div
																style={{
																	width: '80px',
																	height: '80px',
																	borderRadius: '50%',
																	border: '2px solid #3B82F6',
																}}
																className='flex flex-col justify-center items-center'>
																<span className='text-xs pt-2'>Max</span>
																<span style={{ fontSize: '16px', fontWeight: '600' }}>{institutionDetail?.max}</span>
															</div>

															<div
																style={{
																	width: '80px',
																	height: '80px',
																	borderRadius: '50%',
																	border: '2px solid #F59E0B',
																}}
																className='flex flex-col justify-center items-center'>
																<span className='text-xs pt-2'>Percentage</span>
																<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.metric?.find((x) => x?.keyName === item?.keyName)?.mark > 0 ? ((parseFloat(attributeData?.metric?.find((x) => x.keyName === item?.keyName)?.mark) / parseFloat(institutionDetail?.max)) * 100).toFixed(2) : 0}%</span>
															</div>
														</div>
													);
												})()}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</article>
					<article className='w-auto  h-full '>
						<div className='flex flex-col justify-start items-end w-auto gap-2 h-full pt-4'>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #2F4F4F',
								}}
								className='flex flex-col justify-center items-center shadow-lg text-dark'>
								<span className='text-xs pt-2'>Obtained</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.obtained || 0}</span>
							</div>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #000080',
								}}
								className='flex flex-col justify-center items-center'>
								<span className='text-xs pt-2'>Max item</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeTotal}</span>
							</div>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #A0522D',
								}}
								className='flex flex-col justify-center items-center'>
								<span className='text-xs pt-2'>Percentage</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.obtained > 0 ? ((parseFloat(attributeData?.obtained) / parseFloat(selectedCriteria?.max)) * 100).toFixed(2) : 0}%</span>
							</div>
						</div>
					</article>
				</section>
			</div>
			{metricSettings && (
				<Main width='30%' height='60%'>
					<div className='flex flex-col justify-center items-center w-full h-full p-10'>
						<div className='absolute right-1 top-1'>
							<Button type='close' onClick={() => setMetricSettings(false)}>
								close
							</Button>
						</div>

						<div className='w-full mt-4'>
							{subordinateByMetrics
								?.find((x) => x?.keyName === selectedMetric?.keyName)
								?.subordinates.map((x) => {
									const sub = dropCoordinators?.find((y) => y?._id === x);
									return (
										<div key={sub?.name} className='flex items-center space-x-1 w-auto h-auto'>
											<img src={sub?.photoURL} alt={sub?.name} className='w-8 h-8 rounded-full shadow-sm hover:scale-105 transition-transform object-cover aspect-square' />
											<div className='flex flex-col'>
												<span className='text-[7px] font-semibold italic text-white'>Subordinate</span>
												<span className='text-[9px] font-medium text-white'>{sub?.name}</span>
											</div>
											<span className='text-lg cursor-pointer' onClick={() => deleteSubordinate(selectedCriteria?.keyName, selectedMetric?.keyName, x)}>
												{' '}
												<RiDeleteBack2Fill />
											</span>
										</div>
									);
								})}

							<label htmlFor='subordinates' className='block text-gray-700 text-sm font-bold mb-2'>
								Select Subordinates for {selectedMetric?.title}
							</label>
							<Select
								id='subordinates'
								name='subordinates'
								value={dropCoordinators?.filter((opt) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									return attributeData?.subordinates?.includes(opt._id);
								})}
								options={dropCoordinators.filter((x) => attributeData?.subordinates?.includes(x._id))}
								onChange={(options) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									saveSubordinates(
										selectedCriteria.keyName,
										selectedMetric.keyName,
										options?.map((opt) => opt?._id),
									);
								}}
								getOptionLabel={(option) => option?.name}
								getOptionValue={(option) => option?._id}
								components={{ Option: customOption }}
								isMulti
								className='w-full rounded'
							/>
							{/* <Select
								id='subordinates'
								name='subordinates'
								value={dropCoordinators?.filter((opt) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									return attributeData?.subordinates?.includes(opt._id);
								})}
								options={dropCoordinators.filter((x) => attributeData?.subordinates?.includes(x._id))}
								onChange={(options) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									updateAttributeCoordinators(
										selectedCriteria?.keyName,
										attributeData?.coordinator,
										options?.map((opt) => opt?._id),
										attributeData?.obtained,
									);
								}}
								getOptionLabel={(option) => option?.name}
								getOptionValue={(option) => option?._id}
								components={{ Option: customOption }}
								isMulti
								className='w-full rounded'
							/> */}
						</div>
						<div className='flex justify-center mt-4'>
							{/* <Button type='submit' onClick={handleSave}>
								Save Coordinator and Subordinates
							</Button> */}
						</div>
					</div>
				</Main>
			)}
			{enableMetric && enableMetric.keyName !== 'metric1.2' && <Metric1of1 onClose={() => setEnableMetric('')} enableMetric={enableMetric} />}
			{enableMetric.keyName === 'metric1.2' && <Metric1of2 onClose={() => setEnableMetric('')} enableMetric={enableMetric} />}
		</Main>
	);
}

export default Metric;
