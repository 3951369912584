import React, { useEffect, useState } from "react";
import Register from "./Register";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import ApplicationOpen from "./ApplicationOpen";
import Controlls from "./Controlls";
import Button from "../../NecttosComp/Button/Button";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

const StXaviersAluva: React.FC = () => {
  const initCollegeId = "COL-08B6B8276F75-46EE-A69C-77177AAA488B";
  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const [apply, setApply] = useState<boolean>(false);
  const { data: collegeData } = useQuery([`collegeData`, initCollegeId], getCollegeDetails);
  const [login, setLoginAll] = useState<boolean>(false);
  const params: { id: string; type: string } = useParams();

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };

    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("userToken");
    if (token) setLogin(true);
    else setLogin(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove('userToken');
    window.location.reload();
  };

  const handleLogout3 = () => {
    setApply(false);
  };

  const CommonHeader = ({ iconColor, headerText }: any) => (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" className={`h-16 w-16 text-${iconColor}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h1 className="text-xl font-semibold text-gray-800 mt-4">{headerText}</h1>
    </div>
  );

  const CommonMessages = () => (
    <>
      <p className="text-center text-gray-600 mt-2">Thank you for your interest in advancing your education with us!</p>
      <p className="text-center text-gray-600 mt-2">Your journey towards academic excellence starts now. Fill out the application form to take the next step in your educational career.</p>
      <p className="text-center text-gray-600 mt-4 font-medium">We appreciate your eagerness to join our community. Together, we'll strive for success in your academic pursuits!</p>
    </>
  );

  const ApplicationForm = ({ type }: any) => (
    <div className="flex flex-col items-center p-6 max-w-2xl mx-auto bg-white rounded-lg shadow-md">
      <CommonHeader iconColor={type === "UG" ? "green-500" : "green-500"} headerText={type === "UG" ? "UG Application Form Is Now Open!" : "PG Application Form Is Now Open"} />
      <CommonMessages />
      <div className="flex flex-wrap justify-around mt-4">
        {type === "UG" ? <Button width="100%" type="save" onClick={() => setApply(true)} children="UP Open Application Form" /> : <Button width="100%" type="save" onClick={() => setApply(true)} children="PG Application Form" />}
        <Button type="close" onClick={handleLogout} children="Logout" />
      </div>
    </div>
  );
  return !internet ? (
    <div className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Nirmala College Muvatuppuzha!
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center lg:w-full lg:h-screen bg-no-repeat bg-cover bg-center" style={{ backgroundImage: "url('https://nirmalacollege.ac.in/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-30-at-10.01.20-AM.jpeg')" }}>
      <>
        {successLogin ? (
          <>
            {!apply ? (
              <div className="flex justify-center lg:h-[100%] row">
                <div className="bg-[#013E7F] shadow-lg sticky top-0 z-50 w-full">
                  <a className="flex justify-center items-center mt-[10%]">
                    <img className="h-12 w-auto sm:h-12 md:h-16" src={"https://stxaviersaluva.ac.in/app/assets/images/home_logo_bck_20122023.png"} alt="College Logo" />
                  </a>
                </div>
                <div>{params.type.toUpperCase() === "UG" ? <ApplicationForm type="UG" /> : <ApplicationForm type="PG" />}</div>
              </div>
            ) : (
              <ApplicationOpen handleLogout={handleLogout3} initCollegeId={initCollegeId} collegeData={collegeData} />
            )}
          </>
        ) : (
          <div className="flex justify-center min-h-screen row  overflow-auto">
            <div className="bg-[#800000] shadow-lg w-full">
              <div className="max-w-7xl mx-auto px-4 lg:px-8">
                <div className="flex flex-wrap items-center justify-between h-16">
                  <div className="flex items-center text-white text-base lg:text-lg space-x-4">
                    <a href="mailto:college@stxaviersaluva.ac.in" className="hover:underline">
                      college@stxaviersaluva.ac.in
                    </a>
                    <a href="tel:04842628840" className="hover:underline">
                      0484-2628840, 0484-2623240
                    </a>
                  </div>
                  <nav className="flex space-x-2 lg:space-x-3">
                    <a href="/admissions-2024-25" className="text-white hover:bg-gray-800 hover:bg-opacity-25 px-2 py-2 rounded-md text-xs lg:text-sm font-medium">
                      Admissions 2024-25
                    </a>
                    <a href="/e-repository" className="text-white hover:bg-gray-800 hover:bg-opacity-25 px-2 py-2 rounded-md text-xs lg:text-sm font-medium">
                      E-Repository
                    </a>
                    <a href="/e-payment" className="text-white hover:bg-gray-800 hover:bg-opacity-25 px-2 py-2 rounded-md text-xs lg:text-sm font-medium">
                      E-Payment
                    </a>
                    <a href="/login" className="text-white hover:bg-gray-800 hover:bg-opacity-25 px-2 py-2 rounded-md text-xs lg:text-sm font-medium">
                      Login
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="bg-white shadow w-full">
              <div className="w-full px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-4 lg:py-6">
                  <div className="flex justify-start lg:w-0 lg:flex-1">
                    <a href="https://stxaviersaluva.ac.in/">
                      <img className="h-10 w-auto sm:h-12 lg:h-16" src="https://stxaviersaluva.ac.in/app/assets/images/home_logo.png" alt="College Logo" />
                    </a>
                  </div>
                  <nav className="hidden md:flex space-x-5 lg:space-x-10">
                    <a href="https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/UG-PG%20Prospectus%202024-2025.pdf?alt=media&token=793b31e0-80b7-474d-a31e-34dfffdb9a13" className="text-sm lg:text-base font-medium text-gray-500 hover:text-gray-900">
                      Prospectus
                    </a>
                    <a href="https://stxaviersaluva.ac.in/admissions-procedure/" className="text-sm lg:text-base font-medium text-gray-500 hover:text-gray-900">
                      Admissions Procedure
                    </a>
                    <a href="https://stxaviersaluva.ac.in/admission/college-guidelines/" className="text-sm lg:text-base font-medium text-gray-500 hover:text-gray-900">
                      College Guidelines
                    </a>
                    <a href="https://stxaviersaluva.ac.in/admission/fee-schedule/" className="whitespace-nowrap text-sm lg:text-base font-medium text-gray-500 hover:text-gray-900">
                      Fee Schedule
                    </a>
                    <a href="https://stxaviersaluva.ac.in/" className="whitespace-nowrap text-sm lg:text-base font-medium text-gray-500 hover:text-gray-900">
                      Additional Information
                    </a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row w-full max-w-8xl mx-auto my-4 rounded-lg">
              <div className=" w-full lg:w-1/3">
                <Register collegeData={collegeData} login={login} initCollegeId={initCollegeId} setLogin={setLogin} />
              </div>
              <div className="p-4 lg:w-2/3 w-full rounded-lg">
                <h1 className="text-xl lg:text-3xl font-bold mb-4 text-center">Start Your Educational Adventure</h1>
                <p className="font-bold text-md lg:text-lg">Welcome to St. Xavier's College for Women (Autonomous), Aluva!</p>
                <p className="mt-2 font-bold text-sm lg:text-base text-justify">
                  Ready to ignite your academic passion and discover your full potential? Join us at St. Xavier's, where you'll find a nurturing environment that fosters academic excellence alongside comprehensive personal development. Explore our diverse range of programs, each designed to equip you with the skills and knowledge to succeed in your chosen career path. Go beyond textbooks – participate in exciting student clubs dedicated to social causes, creative pursuits, or even starting your own venture! Ready to join us?
                  <br />
                  <a href={collegeData?.website} className="text-blue-200 hover:text-blue-300 hover:underline" target="_blank" rel="noopener noreferrer">
                    Explore programs @ {collegeData?.website}
                  </a>
                  .
                </p>
                <p className="mt-2 text-sm lg:text-base font-bold text-justify">Ensure your academic records and required documents are ready for submission. We provide support throughout your application process.</p>
                <p className="mt-2 text-sm lg:text-base font-bold text-justify">
                  Get application support: We're here to help! Visit our admissions desk from 9:00 AM to 3:00 PM or contact us at
                  <a href={`tel:+919846903691`} className="text-blue-200 hover:text-blue-300 hover:underline">
                    +919846903691
                  </a>
                  for further information.
                </p>
                <p className="mt-4 font-bold text-md lg:text-lg">We look forward to welcoming you to the Xaverian family!</p>
                <div className="flex justify-center my-4">
                  <iframe className="w-full" style={{ height: "315px" }} src="https://www.youtube.com/embed/PVwor2oKzEo?si=IpOYYvgCmw8MUKuy" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default StXaviersAluva;
