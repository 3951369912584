import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getAllMinor, getAllMinorNew, getDepartments, getStudentsByBatch, getSubRegisteredids, getTeachers } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { subjectsComplementory } from '../Form/Component';
import { getCollegeDetails } from '../Form/Register';
import SelectField from '../../NecttosComp/SelectField';

interface invoiceManagementProps {
	onClose: () => void;
}

function formatWhatsAppLink(link: string) {
	if (!link) return 'Add Link';
	if (link?.length <= 23) return link;
	return `${link?.slice(0, 12)}...${link?.slice(-8)}`;
}

function SubjectRegistry({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>({});
	const [showForm, setShowForm] = useState(false);
	const [workload, setWorkload] = useState(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [includedClasses, setIncludedClasses] = useState<any>([]);
	const [typeOfMinor, setType] = useState<string>('All');
	const [filters, setFilters] = useState<any>({ academicYear: '' });
	const [filters2, setFilters2] = useState<any>({ academicYear: '' });
	const [filters22, setFilters22] = useState<any>({});
	// Load saved filters from localStorage when the component mounts
	useEffect(() => {
		const savedFilters = localStorage.getItem('filters');
		const savedFilters2 = localStorage.getItem('filters2');

		if (savedFilters) {
			setFilters(JSON.parse(savedFilters));
		}

		if (savedFilters2) {
			setFilters2(JSON.parse(savedFilters2));
		}
	}, []);

	// Store filters in localStorage whenever they change
	useEffect(() => {
		localStorage.setItem('filters', JSON.stringify(filters));
	}, [filters]);

	useEffect(() => {
		localStorage.setItem('filters2', JSON.stringify(filters2));
	}, [filters2]);

	const [excludedClasses, setExcludedClasses] = useState<any>([]);
	const [includedClassesLink, setIncludedClassesLink] = useState<any>([]);
	const [excludedClassesLink, setExcludedClassesLink] = useState<any>([]);
	const [includedPlusTwo, setIncludedPlusTwo] = useState<any>([]);
	const [excludedPlusTwo, setExcludedPlusTwo] = useState<any>([]);
	const [mannualStudents, setMannualStudents] = useState<any>([]);
	const [academicYears, setAcademicYears] = useState<any>([]);

	const [splitup, setSplitup] = useState<any>([{ title: '', inTake: '' }]);
	const [teachers, setTeachers] = useState<any>([{ teacherId: '', wl: '' }]);
	const [timetable, setTimetable] = useState<any>([{ day: '', period: '' }]);

	const { data: tableData = [], refetch } = useQuery(['getAllMinorNew', filters], getAllMinorNew({ collegeId, classId: filters?.classId, departmentId: filters?.departmentId, semester: filters?.semester, batchName: filters?.batchName, academicYear: filters?.academicYear }));
	const { data: classes } = useQuery([`edeswefdsrf`, collegeId, addNew?.batchName], getCollegeDetails);
	const { data: departments } = useQuery(['getDepartments', collegeId], getDepartments(collegeId));
	const { data: subusedIds } = useQuery(['getDepartm', collegeId, filters22.subId], getSubRegisteredids(collegeId, filters22.subId));
	const { data: tableData2 = [] } = useQuery(['getAllMinorNew2', filters2], getAllMinorNew({ collegeId, classId: filters2?.classId, departmentId: filters2?.departmentId, semester: filters2?.semester, batchName: filters2?.batchName, academicYear: filters2?.academicYear }));
	console.log({ filters22 });

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteMinor/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postMinor', {
					...addNew,
					includedClasses,
					excludedClasses,
					includedPlusTwo,
					mannualStudents,
					excludedPlusTwo,
					includedClassesLink,
					excludedClassesLink,
					collegeId,
					splitup,
					timetable,
					teachers,
				})
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	const [data, setData] = useState([]);
	const getData = async () => {
		const instance = await getAxiosTokenInstance();
		const data = await instance.get('/college/subject/getSubjectByFilter', {
			params: { collegeId },
		});
		setData(data?.data?.finalArray);
	};

	useEffect(() => {
		getData();
	}, []);

	const removeIncluded = (subject: any) => {
		setIncludedClasses(includedClasses.filter((s: any) => s !== subject));
	};

	const handleIncludedChange = (event: any) => {
		const value = event.target.value;
		if (!includedClasses?.includes(value)) {
			setIncludedClasses([...(includedClasses || []), value]);
		}
	};

	const removeExcluded = (subject: any) => {
		setExcludedClasses(excludedClasses.filter((s: any) => s !== subject));
	};

	const handleExcludedChange = (event: any) => {
		const value = event.target.value;
		if (!excludedClasses?.includes(value)) {
			setExcludedClasses([...(excludedClasses || []), value]);
		}
	};

	const removeIncludedPlusTwo = (subject: any) => {
		setIncludedPlusTwo(includedPlusTwo.filter((s: any) => s !== subject));
	};

	const handleIncludedChangePlusTwo = (event: any) => {
		const value = event.target.value;
		if (!includedPlusTwo?.includes(value)) {
			setIncludedPlusTwo([...(includedPlusTwo || []), value]);
		}
	};

	const removeExcludedPlusTwo = (subject: any) => {
		setExcludedPlusTwo(excludedPlusTwo.filter((s: any) => s !== subject));
	};

	const handleExcludedChangePlusTwo = (event: any) => {
		const value = event.target.value;
		if (!excludedPlusTwo?.includes(value)) {
			setExcludedPlusTwo([...(excludedPlusTwo || []), value]);
		}
	};

	const handleAddSplitup = () => {
		setSplitup([...splitup, { title: '', inTake: '' }]);
	};

	const handleAddTeacher = () => {
		setTeachers([...teachers, { teacherId: '', wl: '' }]);
	};
	const handleRemoveTeachers = (index: any) => {
		const newTeacher = teachers.filter((_: any, i: any) => i !== index);
		setTeachers(newTeacher);
	};

	const handleTeacherChange = (index: number, key: string, value: any) => {
		const newTeacher = [...teachers];
		newTeacher[index][key] = value;
		setTeachers(newTeacher);
	};

	const handleTeacherChangeDrop = (index: number, key: string, value: any) => {
		const newTeacher = [...teachers];
		newTeacher[index][key] = value.value;
		setTeachers(newTeacher);
	};

	const handleMannualStudentChangeDrop = (value: any) => {
		console.log({ value, mannualStudents });

		if (!mannualStudents?.includes(value._id)) {
			setMannualStudents([...(mannualStudents || []), value._id]);
		}
	};

	useEffect(() => {
		if (subusedIds?.length && !arraysAreEqual(subusedIds, mannualStudents)) {
			setMannualStudents(subusedIds);
		}
	}, [subusedIds, mannualStudents]);

	const arraysAreEqual = (arr1: any[], arr2: any[]) => {
		if (arr1?.length !== arr2?.length) return false;
		for (let i = 0; i < arr1?.length; i++) {
			if (arr1[i] !== arr2[i]) return false;
		}
		return true;
	};

	const removeMannualStudentChangeDrop = (subject: any) => {
		console.log(subject);

		setMannualStudents(mannualStudents.filter((s: any) => s !== subject));
	};

	const handleAddTimeTable = () => {
		setTimetable([...timetable, { day: '', period: '' }]);
	};

	const handleRemoveTimeTable = (index: any) => {
		const newTimeTable = timetable.filter((_: any, i: any) => i !== index);
		setTimetable(newTimeTable);
	};

	const handleTimeTableChange = (index: number, key: string, value: any) => {
		const newTimeTable = [...timetable];
		newTimeTable[index][key] = value;
		setTimetable(newTimeTable);
	};
	const removeTimeTableTeachers = (subject: any, timetableId: number) => {
		const updatedTimetable = timetable.map((item: any, i: any) => {
			if (i === timetableId) {
				return {
					...item,
					teachers: item.teachers.filter((s: any) => s !== subject),
				};
			}
			return item;
		});

		setTimetable(updatedTimetable);
	};

	const handleTimeTableTeachers = (index: number, event: any) => {
		const value = event.target.value;
		const updatedTimetable = timetable.map((item: any, i: any) => {
			if (i === index) {
				return {
					...item,
					teachers: item?.teachers?.includes(value) ? item.teachers : [...(item.teachers ?? []), value],
				};
			}
			return item;
		});
		setTimetable(updatedTimetable);
	};

	const handleRemoveSplitup = (index: any) => {
		const newSplitup = splitup.filter((_: any, i: any) => i !== index);
		setSplitup(newSplitup);
	};

	const handleSplitupChange = (index: number, key: string, value: any) => {
		const newSplitup = [...splitup];
		newSplitup[index][key] = value;
		setSplitup(newSplitup);
	};

	const removeIncludedLink = (subject: any) => {
		setIncludedClassesLink(includedClassesLink.filter((s: any) => s !== subject));
	};

	const handleIncludedChangeLink = (event: any) => {
		const value = event.target.value;
		if (!includedClassesLink?.includes(value)) {
			setIncludedClassesLink([...(includedClassesLink || []), value]);
		}
	};

	const removeExcludedLink = (subject: any) => {
		setExcludedClassesLink(excludedClassesLink.filter((s: any) => s !== subject));
	};

	const handleExcludedChangeLink = (event: any) => {
		const value = event.target.value;
		if (!excludedClassesLink?.includes(value)) {
			setExcludedClassesLink([...(excludedClassesLink || []), value]);
		}
	};

	const { tableItems, requestSort, getIcon } = useSortableData(tableData);
	const { data: teachersArr } = useQuery(['getTeacherss', collegeId], getTeachers(collegeId));

	const classOptions = teachersArr?.map((classItem: { _id: string; name: string }) => ({
		value: classItem._id,
		label: classItem.name,
		_id: classItem._id,
	}));

	const { data: students } = useQuery(['getStudents', collegeId, addNew.batchName], getStudentsByBatch(collegeId, addNew?.batchName));

	const studentsOption = students?.map((studentItem: { _id: string; name: string; admisionNo: string }) => ({
		value: studentItem._id,
		label: studentItem.admisionNo + ' _ ' + studentItem.name,
		_id: studentItem._id,
	}));

	const calcAcademicYears = () => {
		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = today.getMonth() + 1;

		// Determine the start year of the current academic year
		const startYear = currentMonth >= 8 ? currentYear : currentYear - 1;

		// Generate academic years for the past 8 years including the current one
		let academicYears = [];
		for (let year = startYear - 7; year <= startYear; year++) {
			academicYears.push(`${year}-${(year + 1 + '')?.slice(-2)}`);
		}

		academicYears = academicYears.reverse();
		setAcademicYears(academicYears);
	};
	useEffect(() => {
		calcAcademicYears();
	}, []);

	console.log({ academicYears });

	return (
		<>
			<Main title='Master Subject Management' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								setShowForm(true);
							}}>
							Create New Subject
						</Button>

						<Button
							type='submit'
							onClick={() => {
								setType('All');
							}}>
							Show All
						</Button>
						<Button
							type='fetch'
							onClick={() => {
								setType('DSC_B');
							}}>
							Show DSC_B
						</Button>
						<Button
							type='refresh'
							onClick={() => {
								setType('DSC_C');
							}}>
							Show DSC_C
						</Button>
						<Button
							type='search'
							onClick={() => {
								setType('MDC');
							}}>
							Show MDC
						</Button>
						{/* <Button
							type='fetch'
							onClick={() => {
								setWorkload(true);
							}}>
							Show Workload
						</Button> */}
					</div>
					<div className='flex'>
						<Button type='refresh' onClick={() => setFilters({})}>
							Reset
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<div className='flex w-auto'>
					<Input width='12vw' fieldName='Filter Department' returnKey='departmentId' optionDisplay='name' didntShowKey optionKey='_id' state={filters} setState={setFilters} options={departments} type='drop' />
					<Input width='12vw' fieldName='Filter by Semester' returnKey='semester' state={filters} setState={setFilters} options={['Semester 1', 'Semester 2', 'Semester 3', 'Semester 4', 'Semester 5', 'Semester 6', 'Semester 7', 'Semester 8']} type='drop' />
					<Input width='12vw' fieldName='Filter by Acdemic Year' returnKey='academicYear' state={filters} setState={setFilters} options={academicYears} type='drop' />
				</div>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%' innerWidth={['5%', '15%', '8%', '8%', '8%', '7%', '9%', '8%', '8%', '6%', '4%', '3%', '5%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Subject</Th>
									<Th position={2}>Added On</Th>
									<Th position={3}>Modified On</Th>
									<Th position={4} onClick={() => requestSort('type')} icon={getIcon('type')}>
										Type of Subject
									</Th>
									<Th position={5} onClick={() => requestSort('departmentName')} icon={getIcon('departmentName')}>
										Department
									</Th>
									<Th position={6}>Pool Name</Th>
									<Th position={7}>WhatsApp Link</Th>
									<Th position={8}>Semester</Th>
									<Th position={9}>Batch</Th>
									<Th position={10}>Limit</Th>
									<Th position={11}>B</Th>
									<Th position={12}>Action</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableItems
									.filter((x) => typeOfMinor === 'All' || x.type === typeOfMinor)
									?.map((x, i) => (
										<Tr key={i}>
											<Td index={i} position={0}>
												{i + 1}
											</Td>
											<Td index={i} position={1}>
												{x?.subjectName}
											</Td>
											<Td index={i} position={2}>
												{moment(x?.addedOn).format('ddd-DD-MM-YYYY')}
											</Td>
											<Td index={i} position={3}>
												{moment(x?.modifiedOn).format('ddd-DD-MM-YYYY')}
											</Td>
											<Td index={i} position={4}>
												{x?.type}
											</Td>
											<Td index={i} position={5}>
												{x?.departmentName}
											</Td>
											<Td index={i} position={6}>
												{x?.poolName}
											</Td>
											<Td index={i} position={7}>
												{formatWhatsAppLink(x?.waLink)}
											</Td>
											<Td index={i} position={8}>
												{x?.batchName}
											</Td>
											<Td index={i} position={9}>
												{x?.semester}
											</Td>
											<Td index={i} position={10}>
												{x?.seatLimit}
											</Td>
											<Td index={i} position={11}>
												{x?.group}
											</Td>
											<Td index={i} position={12}>
												<div className='flex'>
													<Button
														type='update'
														onClick={() => {
															setAddNew(x);
															setShowForm(true);
															setIncludedClasses(x?.includedClasses);
															setExcludedClasses(x?.excludedClasses);
															setIncludedPlusTwo(x?.includedPlusTwo);
															setExcludedPlusTwo(x?.excludedPlusTwo);
															setExcludedClassesLink(x.excludedClassesLink);
															setIncludedClassesLink(x.includedClassesLink);
															setMannualStudents(x.mannualStudents);
															setTeachers(x.teachers || []);
															setTimetable(x.timetable || []);
															setSplitup(
																x?.splitup || [
																	{ title: 'OPEN', inTake: '' },
																	{ title: 'EZ', inTake: '' },
																	{ title: 'MU', inTake: '' },
																	{ title: 'LC', inTake: '' },
																	{ title: 'OBX', inTake: '' },
																	{ title: 'EWS', inTake: '' },
																	{ title: 'OBH', inTake: '' },
																	{ title: 'SC', inTake: '' },
																	{ title: 'ST', inTake: '' },
																	{ title: 'TLM', inTake: '' },
																	{ title: 'SP', inTake: '' },
																	{ title: 'SSQ', inTake: '' },
																	{ title: 'PWD', inTake: '' },
																	{ title: 'LDWP', inTake: '' },
																],
															);
														}}>
														Edit
													</Button>
												</div>
											</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
					</div>
				</Body>
			</Main>
			{showForm && (
				<Main width='100%' height='100%'>
					<Header>
						<div className='flex'></div>
						<div className='flex'>
							<Button
								type='save'
								width='200px'
								onClick={() => {
									addNewForm();
								}}>
								Save Changes
							</Button>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setShowForm(false), 500);
								}}>
								Close
							</Button>
							<>
								<Input width='12vw' fieldName='Select Subject' returnKey='subId' optionDisplay='subjectName' didntShowKey optionKey='_id' state={filters22} setState={setFilters22} options={tableData2} type='drop' />
								<Input width='12vw' fieldName='Filter Department' returnKey='departmentId' optionDisplay='name' didntShowKey optionKey='_id' state={filters2} setState={setFilters2} options={departments} type='drop' />
								<Input width='12vw' fieldName='Filter by Semester' returnKey='semester' state={filters2} setState={setFilters2} options={['Semester 1', 'Semester 2', 'Semester 3', 'Semester 4', 'Semester 5', 'Semester 6', 'Semester 7', 'Semester 8']} type='drop' />
								<Input width='12vw' fieldName='Filter by Acdemic Year' returnKey='academicYear' state={filters2} setState={setFilters2} options={academicYears} type='drop' />
							</>
						</div>
					</Header>
					<Body>
						<div className='flex flex-row w-full'>
							<div className='flex flex-col w-1/3 p-1'>
								<Input width='100%' fieldName='Choose course offering Department *' returnKey='departmentId' optionDisplay='name' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={departments} type='drop' />
								<Input width='100%' fieldName='Batch Name *' returnKey='batchName' state={addNew} setState={setAddNew} options={['2024-2028', '2024-2026', '2023-2025', '2023-2026', '2022-2025', '2024-2027', '2024-2029']} type='drop' />
								<Input width='100%' fieldName='Semester *' returnKey='semester' state={addNew} setState={setAddNew} options={['Semester 1', 'Semester 2', 'Semester 3', 'Semester 4', 'Semester 5', 'Semester 6', 'Semester 7', 'Semester 8']} type='drop' />
								<Input width='100%' fieldName='Join Our WhatsApp Group Link*' returnKey='waLink' state={addNew} setState={setAddNew} type='text' />
								{['DSC_B', 'DSC_C'].includes(addNew.type) && <Input width='100%' fieldName='Pool Name *' returnKey='poolName' state={addNew} setState={setAddNew} type='text' />}
								<Input width='100%' fieldName='Choose Subject *' returnKey='subjectId' optionDisplay='name' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={data} type='drop' />
								<Input width='100%' fieldName='Select Type *' returnKey='type' state={addNew} setState={setAddNew} options={['AEC', 'AEC_OL', 'DSC_A', 'MDC', 'DSC_B', 'DSC_C']} type='drop' />
								<Input width='100%' fieldName='Student Join Mode *' returnKey='studentJoining' state={addNew} setState={setAddNew} options={['Election', 'Mannual', 'Full']} type='drop' />
								{addNew.type === 'DSC_B' && <Input width='100%' fieldName='Select Relationship Between Classes & Subjects Linked with DSC_C:' returnKey='linkedClasses' state={addNew} setState={setAddNew} options={['Included Classes', 'Excluded Classes', 'All Classes', 'None']} type='drop' />}
								{addNew.type === 'DSC_B' && addNew.linkedClasses !== 'None' && (
									<>
										<Input width='100%' fieldName='Select Linked Subject from DSC_C *' returnKey='linkedSubjectId' optionDisplay='subjectName' didntShowKey optionKey='_id' state={addNew} setState={setAddNew} options={tableData.filter((x: any) => x.type === 'DSC_C')} type='drop' />
										{addNew.linkedClasses === 'Included Classes' && (
											<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
												<div className='flex justify-center'>
													<span className='font-bold text-green-700 fs-4'>Includ Linked Classes</span>
												</div>
												{includedClassesLink?.map((subject: any, index: any) => (
													<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
														<span>{classes?.find((x: any) => x._id === subject).className}</span>
														<button onClick={() => removeIncludedLink(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															Remove
														</button>
													</div>
												))}
												<div className='mt-4'>
													<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
														Select Includ Linked Classes
													</label>
													<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChangeLink} value=''>
														<option value=''> Select Included Classes...</option>
														{classes?.map((subject: any, index: any) => (
															<option key={index} value={subject._id}>
																{subject.className}
															</option>
														))}
													</select>
												</div>
											</div>
										)}
										{addNew.linkedClasses === 'Excluded Classes' && (
											<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
												<div className='flex justify-center'>
													<span className='font-bold text-red-700 fs-4'>Exclud Linked Classes</span>
												</div>
												{excludedClassesLink?.map((subject: any, index: any) => (
													<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
														<span>{classes?.find((x: any) => x._id === subject).className}</span>
														<button onClick={() => removeExcludedLink(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															Remove
														</button>
													</div>
												))}
												<div className='mt-4'>
													<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
														Select Exclud Linked Classes
													</label>
													<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChangeLink} value=''>
														<option value=''>Select Excluded Classes...</option>
														{classes?.map((subject: any, index: any) => (
															<option key={index} value={subject._id}>
																{subject.className}
															</option>
														))}
													</select>
												</div>
											</div>
										)}
									</>
								)}
								<Input width='100%' fieldName='Basket' returnKey='group' state={addNew} setState={setAddNew} options={['A', 'B', 'C', 'D', 'E']} type='drop' />
								{addNew.studentJoining !== 'Full' && (
									<>
										<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
											<div className='flex justify-center'>
												<span className='font-bold text-blue-700 fs-4'>Teachers</span>
											</div>
											{teachers?.map((item: any, index: any) => (
												<div key={index} className='flex space-x-2'>
													<SelectField id='claswws' name='class' classNameInset='form-multiselect block w-4/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' label='Select Teacher' options={classOptions} value={classOptions?.find((option: any) => option?._id === item?.teacherId) || null} onChange={(selectedOption: any) => handleTeacherChangeDrop(index, 'teacherId', selectedOption)} placeholder='Select a teacher' />
													<input type='number' className='block w-1/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='WL' value={item.wl} onChange={(e) => handleTeacherChange(index, 'wl', e.target.value)} />
													<button onClick={() => handleRemoveTeachers(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
														R
													</button>
												</div>
											))}
											<button onClick={handleAddTeacher} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
												Add
											</button>
										</div>
										<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
											<div className='flex justify-center'>
												<span className='font-bold text-blue-700 fs-4'>Time Table</span>
											</div>
											{timetable?.map((item: any, index: any) => (
												<div className='w-full space-y-4 bg-blue-400 p-2 rounded-lg'>
													<div key={index} className='flex space-x-2'>
														<select className='form-multiselect block w-2/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e) => handleTimeTableChange(index, 'day', e.target.value)} value={item.day}>
															<option value=''>Select Day...</option>
															<option value='Monday'>Monday</option>
															<option value='Tuesday'>Tuesday</option>
															<option value='Wednesday'>Wednesday</option>
															<option value='Thursday'>Thursday</option>
															<option value='Friday'>Friday</option>
														</select>
														<input type='number' className='block w-1/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='Period' value={item.period} onChange={(e) => handleTimeTableChange(index, 'period', e.target.value)} />

														<button onClick={() => handleRemoveTimeTable(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
															R
														</button>
													</div>
													<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
														<div className='flex justify-center'>
															<span className='font-bold text-green-700 fs-4'>Select Teacher</span>
														</div>
														{item.teachers?.map((subject: any, subIndex: any) => (
															<div key={subIndex} className='flex justify-between items-center bg-green-700 text-white font-extrabold p-2 rounded'>
																{classOptions.find((x: any) => x.value === subject)?.label}
																<button onClick={() => removeTimeTableTeachers(subject, index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
																	Remove
																</button>
															</div>
														))}
														<div className='mt-4'>
															<label htmlFor={`subjects-${index}`} className='block text-sm font-medium text-gray-700 mb-1'>
																Select Teachers
															</label>
															<select id={`subjects-${index}`} className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e: any) => handleTimeTableTeachers(index, e)} value=''>
																<option value=''>Select Teachers...</option>
																{teachers?.map((subject: any, subIndex: any) => (
																	<option key={subIndex} value={subject.teacherId}>
																		{classOptions.find((x: any) => x.value === subject?.teacherId)?.label}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>
											))}
											<button onClick={handleAddTimeTable} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
												Add
											</button>
										</div>
									</>
								)}
							</div>
							{addNew.studentJoining === 'Mannual' && (
								<div className='flex flex-col w-3/4 p-1'>
									<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
												Select students
											</label>
											<SelectField id='students' name='class' classNameInset='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' label='Select Students' options={studentsOption} value={studentsOption?.find((option: any) => option?._id === '') || null} onChange={(selectedOption: any) => handleMannualStudentChangeDrop(selectedOption)} placeholder='Select students' />
										</div>
									</div>
									<div className='flex flex-col w-full p-1'>
										<span className='font-bold text-green-700 fs-4'>Included Students</span>
										<div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full p-4'>
											{mannualStudents?.map((subject: any, index: number) => {
												const student = students?.find((x: any) => x._id === subject);

												return (
													<div key={index} className='flex flex-col items-center bg-white shadow-lg rounded-lg p-4 border border-gray-300'>
														<img src={student?.photoURL || 'https://via.placeholder.com/100'} alt={`${student?.name}'s photo`} className='w-20 h-20 rounded-full object-cover mb-4' />
														<div className='text-center'>
															<h3 className='text-lg font-semibold text-gray-800'>{student?.name || 'N/A'}</h3>
															<p className='text-sm text-gray-600'>Admission No: {student?.admissionNo || 'N/A'}</p>
														</div>
														<button onClick={() => removeMannualStudentChangeDrop(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 w-full'>
															Remove
														</button>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							)}
							{addNew.studentJoining === 'Full' && (
								<div className='flex flex-col w-1/4 p-1'>
									<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-green-700 fs-4'>Included Classes</span>
										</div>
										{includedClasses?.map((subject: any, index: any) => (
											<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
												<span>{classes?.find((x: any) => x._id === subject).className}</span>
												<button onClick={() => removeIncluded(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													Remove
												</button>
											</div>
										))}
										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
												Select Included Classes
											</label>
											<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChange} value=''>
												<option value=''> Select Included Classes...</option>
												{classes?.map((subject: any, index: any) => (
													<option key={index} value={subject._id}>
														{subject.className}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							)}
							{addNew.studentJoining === 'Full' && (
								<>
									<div className='flex flex-col w-1/4 p-1 mb-2'>
										{includedClasses?.map((subject: any, index: any) => (
											<div className='m-2'>
												<div className='w-full space-y-4 bg-blue-400 p-2 rounded-sm'>
													<div className='flex justify-center'>
														<span className='font-bold text-red-700 fs-4 '>{classes?.find((x: any) => x._id === subject).className}</span>
													</div>
												</div>

												<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
													<div className='flex justify-center'>
														<span className='font-bold text-blue-700 fs-4'>Teachers</span>
													</div>
													{teachers?.map((item: any, index: any) => (
														<div key={index} className='flex space-x-2'>
															<SelectField id='claswws' name='class' classNameInset='form-multiselect block w-4/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' label='Select Class' options={classOptions} value={classOptions?.find((option: any) => option?._id === item?.teacherId) || null} onChange={(selectedOption: any) => handleTeacherChangeDrop(index, 'teacherId', selectedOption)} placeholder='Select a teacher' />

															<input type='number' className='block w-1/5 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='WL' value={item.wl} onChange={(e) => handleTeacherChange(index, 'wl', e.target.value)} />
															<button onClick={() => handleRemoveTeachers(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
																R
															</button>
														</div>
													))}
													<button onClick={handleAddTeacher} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
														Add
													</button>
												</div>
												<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm '>
													<div className='flex justify-center'>
														<span className='font-bold text-blue-700 fs-4'>Time Table</span>
													</div>
													{timetable?.map((item: any, index: any) => (
														<div className='w-full space-y-4 bg-blue-400 p-2 rounded-lg'>
															<div key={index} className='flex space-x-2'>
																<select className='form-multiselect block w-2/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e) => handleTimeTableChange(index, 'day', e.target.value)} value={item.day}>
																	<option value=''>Select Day...</option>
																	<option value='Monday'>Monday</option>
																	<option value='Tuesday'>Tuesday</option>
																	<option value='Wednesday'>Wednesday</option>
																	<option value='Thursday'>Thursday</option>
																	<option value='Friday'>Friday</option>
																</select>
																<input type='number' className='block w-1/3 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='Period' value={item.period} onChange={(e) => handleTimeTableChange(index, 'period', e.target.value)} />

																<button onClick={() => handleRemoveTimeTable(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
																	R
																</button>
															</div>
															<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
																<div className='flex justify-center'>
																	<span className='font-bold text-green-700 fs-4'>Select Teacher</span>
																</div>
																{item.teachers?.map((subject: any, subIndex: any) => (
																	<div key={subIndex} className='flex justify-between items-center bg-green-700 text-white font-extrabold p-2 rounded'>
																		{classOptions.find((x: any) => x.value === subject)?.label}
																		<button onClick={() => removeTimeTableTeachers(subject, index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
																			Remove
																		</button>
																	</div>
																))}
																<div className='mt-4'>
																	<label htmlFor={`subjects-${index}`} className='block text-sm font-medium text-gray-700 mb-1'>
																		Select Teachers
																	</label>
																	<select id={`subjects-${index}`} className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e: any) => handleTimeTableTeachers(index, e)} value=''>
																		<option value=''>Select Teachers...</option>
																		{teachers?.map((subject: any, subIndex: any) => (
																			<option key={subIndex} value={subject.teacherId}>
																				{classOptions.find((x: any) => x.value === subject?.teacherId)?.label}
																			</option>
																		))}
																	</select>
																</div>
															</div>
														</div>
													))}
													<button onClick={handleAddTimeTable} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
														Add
													</button>
												</div>
											</div>
										))}
									</div>
								</>
							)}
							{addNew.studentJoining === 'Election' && (
								<div className='flex flex-col w-1/4 p-1'>
									<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-green-700 fs-4'>Included +2 Subject</span>
										</div>

										{includedPlusTwo?.map((subject: any, index: any) => (
											<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
												<span>{subject}</span>
												<button onClick={() => removeIncludedPlusTwo(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													Remove
												</button>
											</div>
										))}
										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
												Select Included Plus Two Subject
											</label>
											<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChangePlusTwo} value=''>
												<option value=''> Select Included Plus Two Subject...</option>
												{subjectsComplementory?.map((subject: any, index: any) => (
													<option key={index} value={subject}>
														{subject}
													</option>
												))}
											</select>
										</div>
									</div>

									<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-red-700 fs-4'>Excluded +2 Subject</span>
										</div>
										{excludedPlusTwo?.map((subject: any, index: any) => (
											<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
												<span>{subject}</span>
												<button onClick={() => removeExcludedPlusTwo(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													Remove
												</button>
											</div>
										))}
										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
												Select Excluded Plus Two Subject
											</label>
											<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChangePlusTwo} value=''>
												<option value=''>Select Excluded Plus Two Subject...</option>
												{subjectsComplementory?.map((subject: any, index: any) => (
													<option key={index} value={subject}>
														{subject}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							)}
							{addNew.studentJoining === 'Election' && (
								<div className='flex flex-col w-1/4 p-1'>
									<div className='space-y-4 bg-green-100 m-2 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-green-700 fs-4'>Included Classes</span>
										</div>
										{includedClasses?.map((subject: any, index: any) => (
											<div key={index} className='flex justify-between items-center bg-green-700  text-white font-extrabold p-2 rounded'>
												<span>{classes?.find((x: any) => x._id === subject).className}</span>
												<button onClick={() => removeIncluded(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													Remove
												</button>
											</div>
										))}
										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
												Select Included Classes
											</label>
											<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleIncludedChange} value=''>
												<option value=''> Select Included Classes...</option>
												{classes?.map((subject: any, index: any) => (
													<option key={index} value={subject._id}>
														{subject.className}
													</option>
												))}
											</select>
										</div>
									</div>

									<div className='space-y-4 bg-orange-100 m-2 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-red-700 fs-4'>Excluded Classes</span>
										</div>
										{excludedClasses?.map((subject: any, index: any) => (
											<div key={index} className='flex justify-between items-center bg-red-700  text-white font-extrabold p-2 rounded'>
												<span>{classes?.find((x: any) => x._id === subject).className}</span>
												<button onClick={() => removeExcluded(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													Remove
												</button>
											</div>
										))}

										<div className='mt-4'>
											<label htmlFor='subjects' className='block text-sm font-medium text-red-700 mb-1'>
												Select Excluded Classes
											</label>
											<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleExcludedChange} value=''>
												<option value=''>Select Excluded Classes...</option>
												{classes?.map((subject: any, index: any) => (
													<option key={index} value={subject._id}>
														{subject.className}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							)}
							{addNew.studentJoining === 'Election' && (
								<div className='flex flex-col w-1/4 p-1'>
									<div className='w-full space-y-4 bg-blue-100 p-2 rounded-sm'>
										<div className='flex justify-center'>
											<span className='font-bold text-blue-700 fs-4'>Reservation Splitup</span>
										</div>
										{splitup?.map((item: any, index: any) => (
											<div key={index} className='flex space-x-2'>
												<select className='form-multiselect block w-1/2 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={(e) => handleSplitupChange(index, 'title', e.target.value)} value={item.title}>
													<option value=''>Select Title...</option>
													<option value='OPEN'>OPEN</option>
													<option value='EZ'>EZ</option>
													<option value='MU'>MU</option>
													<option value='LC'>LC</option>
													<option value='OBX'>OBX</option>
													<option value='EWS'>EWS</option>
													<option value='OBH'>OBH</option>
													<option value='SC'>SC</option>
													<option value='ST'>ST</option>
													<option value='TLM'>TLM</option>
													<option value='SP'>SP</option>
													<option value='SSQ'>SSQ</option>
													<option value='PWD'>PWD</option>
													<option value='LDWP'>LDWP</option>
												</select>
												<input type='number' className='block w-1/2 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' placeholder='Intake' value={item.inTake} onChange={(e) => handleSplitupChange(index, 'inTake', e.target.value)} />
												<button onClick={() => handleRemoveSplitup(index)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
													R
												</button>
											</div>
										))}
										<button onClick={handleAddSplitup} className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'>
											Add
										</button>
									</div>
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}
			{workload && (
				<Main width='100vw' height='100vh'>
					<div className='w-full h-full'>
						<header className='flex justify-end'>
							<Button type='close' onClick={() => setWorkload(false)}>
								close
							</Button>
						</header>
						<main className='w-full h-full'>
							<section className='w-full h-full bg-red-200 relative'>
								<ProgressCircle progressPercentage={90} />
							</section>
						</main>
					</div>
				</Main>
			)}
		</>
	);
}

export const ProgressCircle: React.FC<any> = ({ progressPercentage }) => {
	return (
		<div className='absolute top-4 right-4'>
			<svg className='w-14 h-14'>
				<circle className='text-gray-300' strokeWidth='4' stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
				<circle className='text-blue-600' strokeWidth='4' strokeLinecap='round' strokeDasharray='150' strokeDashoffset={150 - (progressPercentage / 100) * 150} stroke='currentColor' fill='transparent' r='24' cx='28' cy='28' />
			</svg>
			<span className='absolute inset-0 flex items-center justify-center text-sm font-semibold text-blue-900'>{progressPercentage}%</span>
		</div>
	);
};

export default SubjectRegistry;
