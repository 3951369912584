import React, { useEffect, useState } from 'react';
import AddUserForm from '../../components/AddUserForm';
import { Body, Footer, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

function AddUserPage({ onClose }) {
	const [refreshStatus, setRefreshStatus] = useState(false);

	const [userType, setUserType] = useState('');
	const [classId, setClassId] = useState('');
	const [departmentId, setDepartmentId] = useState('');
	const [mode, setMode] = useState('');
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [admisionNo, setAdmisionNo] = useState('');
	const [dateOfAdmission, setDateOfAdmission] = useState('');
	const [id, setId] = useState('');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Main width='30vw' title='Add New'>
				<Header>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body>
					<AddUserForm classId={classId} setClassId={(e) => setClassId(e)} departmentId={departmentId} userType={userType} setUserType={(e) => setUserType(e)} setDepartmentId={(e) => setDepartmentId(e)} name={name} setName={(e) => setName(e)} phoneNumber={phoneNumber} setPhoneNumber={(e) => setPhoneNumber(e)} admisionNo={admisionNo} setAdmisionNo={(e) => setAdmisionNo(e)} setId={(e) => setId(e)} id={id} toggleRefresh={() => setRefreshStatus(!refreshStatus)} mode={mode} setMode={setMode} dateOfAdmission={dateOfAdmission} setDateOfAdmission={(e) => setDateOfAdmission(e)} />
					<div style={{ height: '5vh' }}></div>
				</Body>
				<Footer />
			</Main>
		</>
	);
}

export default AddUserPage;
