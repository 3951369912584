import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import moment from 'moment';
import { Body, Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';

export const EditBillAmount = ({ item, itemData, onClose, stateData }) => {
	const { collegeId } = useContext(FirebaseContext);
	const componentRef = useRef();

	const [billNo, setBillNo] = useState(itemData?.billNumber);

	const [dueDate, setDueDate] = useState(moment(itemData.date, 'DD-MM-YYYY').format('YYYY-MM-DD'));

	const editBill = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			await instance.post('/college/action/editBillNo', {
				collegeId,
				feeId: itemData._id,

				billNo,
				userId: stateData._id,
				date: dueDate,
			});
			window.alert('Succesfully updated');
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Main title='Bill Editor' height='auto' width='auto'>
			<Body>
				<div className={classNames.modal}>
					<div className={classNames.listContainer2}>
						<div ref={componentRef} className='overflow-scroll'>
							<div className='flex flex-col w-full rounded-2xl p-6 bg-white shadow-lg'>
								<div className='flex flex-col items-center w-full space-y-8'>
									<div className='w-4/5'>
										<label htmlFor='receiptNo' className='block text-lg font-semibold text-gray-700 mb-2'>
											Receipt No
										</label>
										<input id='receiptNo' type='text' className='w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400' placeholder='Enter Receipt Number' pattern='[0-9]*' value={billNo} onChange={(e) => setBillNo(e.target.value)} />
									</div>

									<div className='w-4/5'>
										<label htmlFor='dueDate' className='block text-lg font-semibold text-gray-700 mb-2'>
											Date
										</label>
										<input id='dueDate' type='date' className='w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-indigo-400' value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
									</div>

									<div className='flex justify-center w-full'>
										<button type='button' className='px-8 py-3 text-lg font-semibold text-white bg-blue-500 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-1' onClick={editBill}>
											Update
										</button>
									</div>
									<Button onClick={onClose} type='close'>
										Cancel
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Body>
		</Main>
	);
};
