import React, { useContext, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import moment from "moment";

const getExaminationDetails = (collegeId: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/examProcess/getCondonation", {
      params: { collegeId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function Condonation({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const [viewDoc, setViewDocument] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const [selectedBatchSem, setSelectedBatchSem] = useState<any>([]);

  const { data: tableData = [], refetch } = useQuery("getExddfdamInit", getExaminationDetails(collegeId));

  const addNewForm = async (mode?: any) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postCondonation", { ...addNew, mode, userId: user.uid, collegeId, batches: selectedBatchSem })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    setAddNew((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    addNewForm();
    console.log("Form Data:", addNew);
  };

  return (
    <>
      <Main title="Exam Conducted or Init Details" width="100vw" height="100vw">
        <Header>
          <Button
            type="update"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Register Condonation for Student
          </Button>
          <div className="flex">
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["5%", "200px", "230px", "15%", "10%", "10%"]}>
              <Thead>
                <Tr>
                  <Th position={1}>SN</Th>
                  <Th position={1}>NAME OF STUDNET</Th>
                  <Th position={1}>ADMISSION NO</Th>
                  <Th position={1}>No of Days</Th>
                  <Th position={1}>Fees</Th>
                  <Th position={1}>Date of apply</Th>
                  <Th position={1}>Date of Approved</Th>
                  <Th position={1}>Discription</Th>
                  <Th position={2}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {tableData?.map((item: any, i: number) => (
                  <Tr key={item?._id}>
                    <Td index={i} position={1}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.studentName}
                    </Td>

                    <Td index={i} position={1}>
                      {item?.admissionNumber}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.noOfDays}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.fees}
                    </Td>

                    <Td index={i} position={1}>
                      {moment(item?.examNotificationDate).format("DD-MM-YYYY HH:MM")}
                    </Td>
                    <Td index={i} position={1}>
                      ''
                    </Td>

                    <Td index={i} position={1}>
                      {item?.discription}
                    </Td>

                    <Td index={i} position={2}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            setAddNew(item);
                            setSelectedBatchSem(item?.batches);
                            setShowForm(true);
                          }}
                        >
                          View Document
                        </Button>
                        <Button
                          type="update"
                          onClick={() => {
                            setAddNew(item);
                            setSelectedBatchSem(item?.batches);
                            setShowForm(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          type="delete"
                          onClick={() => {
                            addNewForm("delete");
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main title="Register Condonation" width="40vw">
          <Header>
            <div></div>
            <div className="flex">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setShowForm(false), 500);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div ref={ref} className="bg-gray-100 p-6 rounded-lg shadow-md max-w-4xl mx-auto my-10">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="studentName" className="mb-1 font-medium">
                    Name of the Student
                  </label>
                  <input type="text" id="studentName" name="studentName" value={addNew?.studentName || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="admissionNumber" className="mb-1 font-medium">
                    Admission Number
                  </label>
                  <input type="text" id="admissionNumber" name="admissionNumber" value={addNew?.admissionNumber || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="semester" className="mb-1 font-medium">
                    Semester
                  </label>
                  <input type="text" id="semester" name="semester" value={addNew?.semester || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="noOfDays" className="mb-1 font-medium">
                    No of Days
                  </label>
                  <input type="number" id="noOfDays" name="noOfDays" value={addNew?.noOfDays || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="fees" className="mb-1 font-medium">
                    Fees
                  </label>
                  <input type="number" id="fees" name="fees" value={addNew?.fees || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="document" className="mb-1 font-medium">
                    Upload Document (PDF)
                  </label>
                  <input type="file" id="document" name="document" accept="application/pdf" onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="discription" className="mb-1 font-medium">
                    Description
                  </label>
                  <input type="text" id="discription" name="discription" value={addNew?.discription || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div className="flex justify-end mt-4">
                  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Body>
        </Main>
      )}
      {viewDoc && (
        <Main title="Register Condonation" width="40vw">
          <Header>
            <div className="flex">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setShowForm(false), 500);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div ref={ref} className="bg-gray-100 p-6 rounded-lg shadow-md max-w-4xl mx-auto my-10">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="studentName" className="mb-1 font-medium">
                    Name of the Student
                  </label>
                  <input type="text" id="studentName" name="studentName" value={addNew?.studentName || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="admissionNumber" className="mb-1 font-medium">
                    Admission Number
                  </label>
                  <input type="text" id="admissionNumber" name="admissionNumber" value={addNew?.admissionNumber || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="semester" className="mb-1 font-medium">
                    Semester
                  </label>
                  <input type="text" id="semester" name="semester" value={addNew?.semester || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="noOfDays" className="mb-1 font-medium">
                    No of Days
                  </label>
                  <input type="number" id="noOfDays" name="noOfDays" value={addNew?.noOfDays || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="fees" className="mb-1 font-medium">
                    Fees
                  </label>
                  <input type="number" id="fees" name="fees" value={addNew?.fees || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="document" className="mb-1 font-medium">
                    Upload Document (PDF)
                  </label>
                  <input type="file" id="document" name="document" accept="application/pdf" onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div>
                  <label htmlFor="discription" className="mb-1 font-medium">
                    Description
                  </label>
                  <input type="text" id="discription" name="discription" value={addNew?.discription || ""} onChange={handleInputChange} className="p-2 border rounded w-full" />
                </div>
                <div className="flex justify-end mt-4">
                  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default Condonation;
