import React, { useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Input } from "antd";

function AddModalView({ onExport, onClose, title, refetch }) {


  const onTemplateExport = (event) => {
    console.log(title);


    event?.preventDefault();
    const target = event?.currentTarget;
    const values = target?.acc1?.value

    onExport(values, title);
  };


  const FormField = ({ labelString, type, placeholder, ...props }) => {
    return (
      <div className={classNames.formField}>
        <label>{labelString}</label>
        <Input type={type} placeholder={placeholder} required {...props} />
      </div>
    );
  };
  return (
    <div className={classNames.container}>
      <form className={classNames.modal} onSubmit={onTemplateExport}>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
        <div className={classNames.listContainer}>

          <label className={classNames.heading}>Add {title} dropdown collection</label>

          <FormField
            name="acc1"
            type={'text'}
            multyline={true}
            placeholder="if you want to add multiple coma supperation (for expample Red, Blue, Green )"
            className={classNames.list1}
            style={{
              width: "540px",
              height: "120px",
              font: 24,
            }}
          />
          <button className={classNames.button} type="submit">
            Add to Collections
          </button>

        </div>

      </form>


    </div>
  );
};
export default AddModalView;