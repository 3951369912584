import { useState, useEffect, useCallback } from "react";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";

interface UseApiCallerOptions {
  method: string;
  url: string;
  params?: Record<string, unknown>;
  body?: Record<string, unknown>;
  autoFetch?: boolean; // Add an option to control automatic fetching
}

function useApiCaller<T = any>({
  method,
  url,
  params,
  body,
  autoFetch = false,
}: UseApiCallerOptions) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(async () => {
    if (!autoFetch) return;

    setLoading(true);
    setIsFetching(true);

    try {
      const instance = await getAxiosTokenInstance();

      const response = await instance({
        method: method as any,
        url,
        params,
        data: body,
      });
      setData(response.data);
    } catch (error) {
      console.error("API call error:", error);
      setData(null);
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  }, [method, url,  body, autoFetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, refetch: fetchData, isFetching };
}

export default useApiCaller;
