import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Input } from 'antd';
import { useMutation } from 'react-query';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';

export const exportPdf = async ({ olddata, setLoading }) => {
	const instance = await getAxiosTokenInstance();
	console.log(olddata);
	let data = {
		collegeId: olddata.collegeId,
		accessionNumbers: olddata.year,
		start: olddata.start,
		end: olddata.end,
	};
	console.log(data);

	try {
		const resp = await instance.post(`/college/reportGeneration/barcodeSpinalLabel`, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const Labels = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);

	const [custom, setCustom] = React.useState(false);
	const [stateData, setStateData] = React.useState({});

	const exportPdfMutation = useMutation(exportPdf);

	const [loading, setLoading] = useState(false);

	const onExportExam = async () => {
		setLoading(true);

		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let olddata = {
				collegeId,
				year: stateData.year,
				start: stateData.start,
				end: stateData.end,
			};

			link.download = 'Exam Reg Suport';
			const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
			setLoading(false);

			onClose();
		} catch (e) {}
	};

	const onTemplateExport = (event) => {
		event?.preventDefault();
		const target = event?.currentTarget;
		const year = target?.acc1?.value;
		onExportExam(year);
	};

	const FormField = ({ labelString, type, placeholder, ...props }) => {
		return (
			<div className={classNames.formField}>
				<label>{labelString}</label>
				<Input type={type} placeholder={placeholder} required {...props} />
			</div>
		);
	};
	return (
		<div className={classNames.container}>
			{loading ? (
				<LoadingAnimation dark />
			) : (
				<div className={classNames.modal}>
					<Button className={classNames.closeButton} onClick={onClose}>
						Close
					</Button>

					<div className={classNames.listContainer}>
						<label className={classNames.heading}>Spinal, Barcode & Secret Labels </label>
						<label style={{ fontSize: 30 }}>
							<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={custom} onChange={() => setCustom(!custom)} />
							Custom
						</label>
						{custom ? (
							<div className={classNames.group} style={{ width: 500, height: 100, margin: 10 }}>
								<input type='text' aria-multiline={true} onChange={(e) => setStateData({ ...stateData, year: e.target.value })} value={stateData.year} />
								<label>START</label>
							</div>
						) : (
							<div style={{ flexDirection: 'row', display: 'flex' }}>
								<div className={classNames.group} style={{ width: 180, margin: 10 }}>
									<input type='number' onChange={(e) => setStateData({ ...stateData, start: e.target.value })} value={stateData.start} />
									<label>START</label>
								</div>
								<div className={classNames.group} style={{ width: 180, margin: 10 }}>
									<input type='number' onChange={(e) => setStateData({ ...stateData, end: e.target.value })} value={stateData.end} />
									<label>END</label>
								</div>
							</div>
						)}

						<button
							className={classNames.button}
							onClick={() => {
								onExportExam();
							}}>
							Download Labels PDF
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
