import { DatePicker, Input, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Option = Select;

function Field({ type, onChange, nature, data, option }) {
  const [imageUrl, setImageURL] = useState(null);
  const [multiPle, setMultiPle] = useState([""]);

  useEffect(() => {
    if(type === "Multiple Answer"){
      setMultiPle([...option])
    }
    if(type==="Period"){
      console.log(data)
    }
  }, []);

  const onChangew = (e) => {
    const file = e.target.files[0];
    console.log(file);
    onChange({ target: { value: file } });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { RangePicker } = DatePicker;
  const state = nature === "Mandatory" ? true : false;

  return (
    <>
      {type === "Paragraph" ? (
        <>
          <TextArea onChange={onChange} value={data} required={state} />
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "PDF" ? (
        <>
          {imageUrl ? (
            <iframe
              src={imageUrl}
              width={200}
              height={300}
              className="object-contain mb-2 overflow-hidden"
            />
          ) : null}
          <input type="file" accept="application/pdf" onChange={onChangew} />

          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "Date" ? (
        <>
          <DatePicker
            onChange={(e) =>
              onChange({ target: { value: moment(e._d).format("YYYY-MM-DD") } })
            }
            required={state}
          />
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "Period" ? (
        <>
          <Space direction="vertical" size={12}>
            <RangePicker
              placeholder={["from", "to"]}
              onChange={(e) => {
                onChange({
                  target: {
                    value: {
                      from: moment(e[0]._d).format("YYYY-MM-DD"),
                      to: moment(e[1]._d).format("YYYY-MM-DD"),
                    },
                  },
                });
              }}
            />
          </Space>
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "Text" ? (
        <>
          <Input onChange={onChange} required={state} value={data}/>
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "Image" ? (
        <>
          {imageUrl ? (
            <img src={imageUrl} className="h-52 object-contain mb-3" />
          ) : null}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              console.log(file);
              onChange({ target: { value: file } });
              if (file && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setImageURL(reader.result);
                };
                reader.readAsDataURL(file);
              }
            }}
          />
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
        </>
      ) : type === "Multiple Answer" ? (
        <div>
          {multiPle?.map((x, c) => (
            <div>
              <input
                value={x}
                placeholder="Your Answer"
                onChange={(e) => {
                  let temp = multiPle;
                  temp[c] = e.target.value;
                  setMultiPle([...temp]);
                  onChange({ target: { value: temp } });
                }}
              />
              {c === multiPle.length - 1 ? (
                <>
                  <button
                    onClick={() => {
                      setMultiPle([...multiPle, ""]);
                      console.log(multiPle);
                    }}
                    className=" bg-black text-white"
                  >
                    +
                  </button>
                  {multiPle.length > 1 ? (
                    <button
                      onClick={() => {
                        const temp = multiPle;
                        temp.pop();
                        setMultiPle([...temp]);
                      }}
                      className="ms-2 bg-black text-white"
                    >
                      -
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
          ))}
        </div>
      ) : type === "Options" ? (
        <>
          {" "}
          <select onChange={onChange} value={data}>
            <option value="">Select Option</option>
            {option?.map((x) => (
              <option value={x}>{x}</option>
            ))}
          </select>{" "}
          {state ? (
            <p className="text-red-600 text-[12px] font-bold self-end">
              *This Field Mandatory
            </p>
          ) : null}
          </>
      ) : type==="Number" ? <>
      <Input type="number" onChange={onChange} required={state} value={data}/>
      {state ? (
        <p className="text-red-600 text-[12px] font-bold self-end">
          *This Field Mandatory
        </p>
      ) : null}
    </>:null}
    </>
  );
}

export default Field;
