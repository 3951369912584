import React, { useContext } from "react";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Main } from "../../NecttosComp/Layout/Layout";
import ApplicationOpen from "../Form/ApplicationOpen";
import { getAxiosInstance } from "../../utils/axiosInstance";

const getCollegeDetails = async ({ queryKey }) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function ApplicationView({ uid, onClose, viewMode, params }) {
  const { collegeId } = useContext(FirebaseContext);
  const { data: collegeData } = useQuery([`collegeData`, collegeId], getCollegeDetails);
  return (
    <Main width="100%" height="100%" title="Student Application">
      <ApplicationOpen viewMode={viewMode} paramsRecieved={params} userId={uid} autonumus={true} handleLogout={onClose} initCollegeId={collegeId} collegeData={collegeData} />
    </Main>
  );
}

export default ApplicationView;
