import { v4 as uuidv4 } from "uuid";

export const createRandomId = (prefix: string = ""): string => {
  try {
    const uuid = uuidv4().replace(/-/g, "").toUpperCase();
    return `${prefix ? `${prefix}-` : ""}${uuid}`;
  } catch (error) {
    throw new Error("Failed to generate a random ID.");
  }
};
