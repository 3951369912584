import React, { useCallback, useContext, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDown from '../../../myComp/CustomDropDown';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { Main } from '../../../NecttosComp/Layout/Layout';

function SettupQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = useState({
		mainQ: 1,
	});

	const loadOldData = useCallback(async () => {
		let newData = {};

		newData.mainQ = parseInt(itemData?.questionnaire?.length);

		itemData?.questionnaire?.forEach((item, c) => {
			newData[c + '~question'] = item.question;
			newData[c + 'subQ'] = parseInt(item?.subQuestions?.length);
			item?.subQuestions?.forEach((data, i) => {
				newData[c + '' + i + '~subQuestion'] = data.subQuestion;
				newData[c + '' + i + '~type'] = data.type;
				newData[c + '' + i + '~nature'] = data.nature;
				if (data.type === 'Options') {
					newData[c + '' + i + '~option1'] = data.option1;
					newData[c + '' + i + '~option2'] = data.option2;
					newData[c + '' + i + '~option3'] = data.option3;
					newData[c + '' + i + '~option4'] = data.option4;
					newData[c + '' + i + '~option5'] = data.option5;
				}
				if (data.type === 'Likert Scale') {
					newData[c + '' + i + '~likert1'] = data.likert1;
					newData[c + '' + i + '~likert5'] = data.likert5;
				}
			});
		});
		setState({ ...newData });
	}, [state]);

	console.log({ state });

	let mainCount = Array(state?.mainQ).fill(1);
	console.log({ mainCount });

	mainCount?.forEach((item, i) => {
		if (state[i + 'subQ'] > 0) {
		} else {
			setState({
				...state,
				[i + 'subQ']: 1,
			});
		}
	});

	const postQuestionnaire = useCallback(async () => {
		let finalData = [];
		mainCount?.forEach((item, c) => {
			let subQuestions = [];
			Array(state[c + 'subQ'])
				.fill(1)
				?.forEach((data, i) => {
					let subQuestion = state[c + '' + i + '~subQuestion'];
					let type = state[c + '' + i + '~type'];
					let nature = state[c + '' + i + '~nature'];
					let option1 = state[c + '' + i + '~option1'];
					let option2 = state[c + '' + i + '~option2'];
					let option3 = state[c + '' + i + '~option3'];
					let option4 = state[c + '' + i + '~option4'];
					let option5 = state[c + '' + i + '~option5'];
					let likert1 = state[c + '' + i + '~likert1'];
					let likert5 = state[c + '' + i + '~likert5'];
					subQuestions.push({
						subQuestion,
						type,
						nature,
						...(option1 && { option1: option1 }),
						...(option2 && { option2: option2 }),
						...(option3 && { option3: option3 }),
						...(option4 && { option4: option4 }),
						...(option5 && { option5: option5 }),
						...(likert1 && { likert1: likert1 }),
						...(likert5 && { likert5: likert5 }),
					});
					console.log({ subQuestions });
				});
			finalData.push({
				question: state[c + '~question'],
				subQuestions,
			});
		});

		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/iqac/postQuestionnair', {
				collegeId,
				_id: questionnaireId,
				questionnaire: finalData,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				onSuccess();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			refectOrgin();
			onSuccess();
			setState({});
		}
	}, [state]);

	return (
		<Main width='50vw' height='100vh'>
			<div className='p-6 bg-white rounded-md shadow-md'>
				<div className='flex justify-between items-center mb-4'>
					<span className='text-lg font-semibold'>Set-Up Questionnaire</span>
					<button onClick={() => loadOldData()} className='bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700'>
						Load Selected Book Data
					</button>
					<button onClick={onCloseModal} className='text-red-500 hover:text-red-700'>
						Cancel
					</button>
				</div>

				<div className='flex flex-col'>
					<span>{itemData.title}</span>
					{mainCount.map((x, c) => (
						<div key={c} className='w-full mb-4'>
							<CustomInput keyData={c + '~question'} titleData={'Title of Questionnaire'} typeData={'text'} stateData={state} setStateData={setState} />
							{(Array(state[c + 'subQ']) || 1).fill(1).map((x, i) => (
								<div key={i} className='flex flex-row items-start mb-4'>
									<div className='w-full mr-4'>
										<CustomInput keyData={c + '' + i + '~subQuestion'} titleData={'Question'} typeData={'text'} stateData={state} setStateData={setState} />
									</div>
									<div className='w-1/4 mr-4'>
										<CustomDropDown keyData={c + '' + i + '~type'} titleData={'Category'} dropValues={['Paragraph', 'Options', 'Likert Scale']} stateData={state} setStateData={setState} />
									</div>
									<div className='w-1/4'>
										<CustomDropDown keyData={c + '' + i + '~nature'} titleData={'Nature'} dropValues={['Mandatory', 'Optional']} stateData={state} setStateData={setState} />
									</div>
									{state[c + '' + i + '~type'] === 'Likert Scale' && (
										<>
											<div className='w-1/4 mr-4'>
												<CustomInput keyData={c + '' + i + '~likert1'} titleData={'Value 1'} typeData={'text'} stateData={state} setStateData={setState} />
											</div>
											<div className='w-1/4'>
												<CustomInput keyData={c + '' + i + '~likert5'} titleData={'Value 5'} typeData={'text'} stateData={state} setStateData={setState} />
											</div>
										</>
									)}
									{state[c + '' + i + '~type'] === 'Options' && (
										<>
											{[1, 2, 3, 4, 5].map((option) => (
												<div key={option} className='w-1/4 mr-4'>
													<CustomInput keyData={c + '' + i + '~option' + option} titleData={`Option ${option}`} typeData={'text'} stateData={state} setStateData={setState} />
												</div>
											))}
										</>
									)}
								</div>
							))}
							<button
								className='bg-red-500 text-white font-bold py-1 px-3 rounded hover:bg-red-700 mr-2'
								onClick={() =>
									setState({
										...state,
										[c + 'subQ']: parseInt(state[c + 'subQ']) - 1,
									})
								}>
								-
							</button>
							<button
								className='bg-green-500 text-white font-bold py-1 px-3 rounded hover:bg-green-700'
								onClick={() =>
									setState({
										...state,
										[c + 'subQ']: parseInt(state[c + 'subQ']) + 1,
									})
								}>
								+
							</button>
						</div>
					))}
					<div className='flex flex-row items-center mb-4'>
						<button
							className='bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700 mr-2'
							onClick={() =>
								setState({
									...state,
									mainQ: parseInt(state.mainQ) - 1,
								})
							}>
							-
						</button>
						<button
							className='bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700'
							onClick={() =>
								setState({
									...state,
									mainQ: parseInt(state.mainQ) + 1,
								})
							}>
							+
						</button>
					</div>
					<button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={() => postQuestionnaire()}>
						Submit
					</button>
				</div>
			</div>
		</Main>
	);
}

export default SettupQuestionnaire;
