import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getQuotaTypes } from '../Form/Component';
import { getProfile, useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import { getCollegeDetails } from '../Form/Register';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import AllotmentMemo from '../Form/AllotmentMemo';
import AdmitCard from '../Form/AdmitCard';

export const getCollegeDetailsee = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function RemoveAction({ userId, onClose, classId, quota, indexMark, params, completedApplication }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const { data: userDetail } = useProfileData(user?.uid, false);
	const [selectedClass, setSelectClass] = useState('');
	const [addNew, setAddNew] = useState({});
	const { data: collegeData } = useQuery([`collegeData`, collegeId], getCollegeDetailsee);
	const { data: classes } = useQuery([`collegeDataddd`, collegeId, params?.type?.toUpperCase() === 'UG' ? '2024-2028' : '2024-2026'], getCollegeDetails);
	const { data, refetch } = useQuery([`profileDdddata`, collegeId, userId], getProfile);
	const componentRef = useRef();
	const componentRef2 = useRef();

	const addNewForm = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/makeDecisionChanger', {
				studentId: data?._id,
				collegeId,
				classId: selectedClass,
				userId: user?.uid,
				addNew,
			});
			if (resp?.data?.statusCode === 200) {
				setAddNew('');
			} else {
				setAddNew('');
			}
		} catch (error) {
			setAddNew('');
		}
	};

	useEffect(() => {
		if (classId) {
			setSelectClass(classId);
		}
	}, [classId]);

	console.log({ selectedClass, classId });
	return (
		<Main width='100%' height='100%' title='Student Application'>
			<div className='flex overflow-scroll'>
				<Button type='print' tableRef={componentRef.current}>
					Print
				</Button>
				<Body width='70%'>
					<AdmitCard collegeData={collegeData} componentRef2={componentRef} classes={classes} type={params?.type} values={data} getQuotaTypes={getQuotaTypes} />
				</Body>
				<Body width='30%'>
					<Header>
						<div className='flex'></div>
						<div className='flex'>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</Header>
					<div className='p-6 space-y-6'>
						<div className='bg-white p-6 rounded-md shadow-md'>
							<h2 className='text-xl font-semibold mb-4'>Contact Details</h2>
							<div className='space-y-3 text-lg'>
								<div className='flex justify-between items-center'>
									<span className='font-medium'>Registered No:</span>
									<span>{data?.phoneNumber?.slice(-10)}</span>
								</div>
								<div className='flex justify-between items-center'>
									<span className='font-medium'>WhatsApp No:</span>
									<span>{data?.whatsappNumber?.slice(-10)}</span>
								</div>
								<div className='flex justify-between items-center'>
									<span className='font-medium'>Guardian No:</span>
									<span>{data?.guardianMobile?.slice(-10)}</span>
								</div>
							</div>
						</div>
						<div className='space-y-8'>
							{completedApplication ? (
								<>
									<Input fieldName='Filter by Class' setState={setSelectClass} state={selectedClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes} type='drop' width='100%' />
								</>
							) : (
								<>
									<Button fontSize='20px' height='80px' width='100%' type='close' onClick={() => setAddNew({ ...addNew, showConfirmation: true, status: 'Withdraw from College', color: 'text-lg font-semibold text-red-700' })}>
										Withdraw from College
									</Button>
									<Button fontSize='20px' height='80px' width='100%' type='search' onClick={() => setAddNew({ ...addNew, showConfirmation: true, status: 'Withdraw from Programme', color: 'text-lg font-semibold text-red-700' })}>
										Withdraw from Programme
									</Button>
									<Button fontSize='20px' height='80px' width='100%' type='doc' onClick={() => setAddNew({ ...addNew, showConfirmation: true, status: 'Awaiting Decision', color: 'text-lg font-semibold text-red-700' })}>
										Awaiting Decision
									</Button>
									<Button fontSize='20px' height='80px' width='100%' type='fetch' onClick={() => setAddNew({ ...addNew, showConfirmation: true, status: 'Waiting for Higher Preference', color: 'text-lg font-semibold text-red-700' })}>
										Waiting for Higher Preference
									</Button>
								</>
							)}

							<Button fontSize='20px' height='80px' width='100%' type='save' onClick={() => setAddNew({ ...addNew, showConfirmation: true, status: 'Confirm Admission', color: 'text-lg font-semibold text-green-700' })}>
								Confirm Admission
							</Button>
						</div>
					</div>
				</Body>
			</div>
			{addNew?.showConfirmation && (
				<>
					{addNew.status === 'Confirm Admission' ? (
						<Main width='100%' height='100%' title='Make Decision'>
							<Body>
								<div className='p-6 space-y-6'>
									<div className='flex justify-between items-center'>
										<Button
											type='close'
											onClick={() => {
												closeAnimation();
												setTimeout(() => setAddNew({ ...addNew, showConfirmation: false }), 500);
											}}>
											Close
										</Button>
									</div>
									<div className='flex flex-row w-full space-x-6'>
										<div className='bg-white p-6 rounded-lg shadow-lg w-2/3 flex flex-col items-center'>
											<h2 className={addNew?.color}>Applicant Decided to:</h2>
											<h2 className={addNew?.color}>{addNew.status}</h2>
											<Button type='print' tableRef={componentRef2.current}>
												Print Allotment Memo
											</Button>
											<Body>
												<AllotmentMemo componentRef={componentRef2} collegeData={collegeData} classId={classId} quota={quota} classes={classes} type={params?.type} indexMark={indexMark} values={data} getQuotaTypes={getQuotaTypes} />
											</Body>
										</div>
										<div className='bg-white p-6 rounded-lg shadow-lg w-1/3'>
											<div className='flex items-center space-x-4 mb-4'>
												<img src={userDetail?.photoURL} alt={userDetail?.name} className='w-16 h-16 rounded-full' />
												<span className='font-semibold text-lg'>{userDetail?.name}</span>
											</div>
											<div className='text-gray-600 mb-4'>
												<p className='text-lg'>I confirm that the decision has been updated according to the student's choice.</p>
											</div>

											<Input width='100%' fieldName='Further Details (Notes)' returnKey='details' state={addNew} setState={setAddNew} type='text' />
											<div className='flex flex-grow self-end'>
												<Input width='200px' fieldName='I Verified' returnKey='diclarationFinal' state={addNew} setState={setAddNew} type='checkbox' />
												<Button
													width='150px'
													height='60px'
													fontSize='20px'
													type='save'
													disabled={!addNew?.diclarationFinal}
													className='mt-4'
													onClick={() => {
														addNewForm();
													}}>
													Submit
												</Button>
											</div>
										</div>
									</div>
								</div>
							</Body>
						</Main>
					) : (
						<Main width='80%' height='80%' title='Make Decision'>
							<Body>
								<div className='p-6 space-y-6'>
									<div className='flex justify-between items-center'>
										<Button
											type='close'
											onClick={() => {
												closeAnimation();
												setTimeout(() => setAddNew({ ...addNew, showConfirmation: false }), 500);
											}}>
											Close
										</Button>
									</div>
									<div className='flex flex-row w-full space-x-6'>
										<div className='bg-white p-6 rounded-lg shadow-lg w-1/2 flex flex-col items-center'>
											<h2 className={addNew?.color}>Applicant Decided to:</h2>
											<h2 className={addNew?.color}>{addNew.status}</h2>
											<img src={data?.photoURL} alt={data?.name} className='w-48 h-48 rounded-lg mb-4' />
											<span className='font-semibold text-lg'>{data?.name}</span>
											<div className='text-gray-700 mt-4 text-center'>
												<p className='text-sm'>DOB: {data?.dob}</p>
												<p className='text-sm'>Place: {data?.place}</p>
											</div>
										</div>
										<div className='bg-white p-6 rounded-lg shadow-lg w-1/2'>
											<div className='flex items-center space-x-4 mb-4'>
												<img src={userDetail?.photoURL} alt={userDetail?.name} className='w-16 h-16 rounded-full' />
												<span className='font-semibold text-lg'>{userDetail?.name}</span>
											</div>
											<div className='text-gray-600 mb-4'>
												<p className='text-lg'>I confirm that the decision has been updated according to the student's choice.</p>
											</div>

											<Input width='100%' fieldName='Further Details (Notes)' returnKey='details' state={addNew} setState={setAddNew} type='text' />
											<div className='flex flex-grow self-end'>
												<Input width='200px' fieldName='I Verified' returnKey='diclarationFinal' state={addNew} setState={setAddNew} type='checkbox' />
												<Button
													width='150px'
													height='60px'
													fontSize='20px'
													type='save'
													disabled={!addNew?.diclarationFinal}
													className='mt-4'
													onClick={() => {
														addNewForm();
													}}>
													Submit
												</Button>
											</div>
										</div>
									</div>
								</div>
							</Body>
						</Main>
					)}
				</>
			)}
		</Main>
	);
}

export default RemoveAction;
