import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Body, Footer, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getNewClasses } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';

const getAllMinorsSelected = (collegeId: any, classId: any, printable: boolean) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getAllMinorsSelected', {
			params: { collegeId, classId, printable },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface InvoiceManagementProps {
	onClose: () => void;
}

function StudentResponds({ onClose }: InvoiceManagementProps) {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [printable, setPritable] = useState(true);
	const { data: tableData = [], refetch } = useQuery(['getAllMinorsSelected', collegeId, selectedClass, printable], getAllMinorsSelected(collegeId, selectedClass, printable));
	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId));
	const renderTableHeaders = useMemo(() => {
		const headers = ['Sl.No.', 'Phone No', 'Name', 'RC Mark', 'RC Group', 'AEC OL'];
		for (let i = 1; i <= (printable ? 1 : 30); i++) {
			headers.push(`DSC_B_${i}`);
		}
		for (let i = 1; i <= (printable ? 1 : 30); i++) {
			headers.push(`DSC_C_${i}`);
		}
		for (let i = 1; i <= (printable ? 1 : 30); i++) {
			headers.push(`MDC_${i}`);
		}
		return headers.map((header, index) => (
			<Th key={index} position={1}>
				{header}
			</Th>
		));
	}, [printable]);
	const renderTableRows = useMemo(() => {
		return tableData.map((x: any, i: number) => (
			<Tr key={i}>
				<Td index={i} position={1}>
					{i + 1}
				</Td>
				<Td index={i} position={1}>
					{x?.phoneNumber?.slice(-10)}
				</Td>
				<Td index={i} position={1}>
					{x.name}
				</Td>
				<Td index={i} position={1}>
					{String(x.rcMark).slice(0, 10)}
				</Td>
				<Td index={i} position={1}>
					{x.rcGroup}
				</Td>
				<Td index={i} position={1}>
					{x.secondLanguage}
				</Td>
				{Array.from({ length: printable ? 1 : 30 }, (_, index) => (
					<Td key={index} index={i} position={1}>
						{x[`dsc_b_${index + 1}`]}
					</Td>
				))}
				{Array.from({ length: printable ? 1 : 30 }, (_, index) => (
					<Td key={index + 30} index={i} position={1}>
						{x[`dsc_c_${index + 1}`]}
					</Td>
				))}
				{Array.from({ length: printable ? 1 : 30 }, (_, index) => (
					<Td key={index + 60} index={i} position={1}>
						{x[`mdc_${index + 1}`]}
					</Td>
				))}
			</Tr>
		));
	}, [tableData, printable]);

	return (
		<>
			<Main title='Student Selected Subject List FYUGP' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
					</div>
					<div className='flex'>
						<Button type={printable ? 'search' : 'update'} onClick={() => setPritable(!printable)}>
							{printable ? 'Hide Printable' : 'Show Printable'}
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width={printable ? '100%' : '7000px'} innerWidth={['100px', '100px']}>
							<Thead>
								<Tr>{renderTableHeaders}</Tr>
							</Thead>
							<Tbody height='auto'>{renderTableRows}</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>
		</>
	);
}

export default StudentResponds;
