import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getFieldDropDowns } from './ClubCellsAccounts';
import { PercentageCalculatorProfile, SectionHeader } from '../Form/Component';

const getFieldDropDownsPri = (data: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getClubCellPriority', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface invoiceManagementProps {
	onClose: () => void;
}

export const SubjectInputSet = ({ fieldName, returnKey, values, setValues, classData, tableData }: any) => (
	<div className='w-full flex mb-1'>
		<div className='w-3/5 '>
			<Input fieldName={fieldName} setState={setValues} state={values} didntShowKey returnKey={returnKey} optionDisplay='name' optionKey='_id' options={classData} optionsAll={tableData} type='drop' width='100%' />
		</div>
		<div className='w-2/5 '>
			<Input width='100%' fieldName='Have you held same post before ?' returnKey={returnKey + 'PreMember'} state={values} setState={setValues} options={['Yes', 'No']} type='drop' />
		</div>
	</div>
);

function CordinatorShipForm({ onClose }: invoiceManagementProps) {
	const [values, setValues] = useState<any>({
		prefered1: '',
		prefered2: '',
		prefered3: '',
		prefered4: '',
		prefered5: '',
		prefered6: '',
		prefered7: '',
		prefered8: '',
		prefered9: '',
		prefered10: '',
	});
	const [showForm, setShowForm] = useState(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);

	const percentageFilled = PercentageCalculatorProfile({
		values,
		keyName: ['prefered1', 'prefered1PreMember', 'prefered2', 'prefered2PreMember', 'prefered3', 'prefered3PreMember', 'prefered4', 'prefered4PreMember', 'prefered5', 'prefered5PreMember'],
	});

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const { data: tableData = [], refetch } = useQuery('hhhhhhh', getFieldDropDowns({ collegeId }));
	const { data: tableDa = [] } = useQuery('hhhhooohhh', getFieldDropDownsPri({ collegeId, userId: user?.uid }));

	useEffect(() => {
		if (tableDa && tableDa.length > 0) {
			setValues({ ...tableDa[0] });
		}
	}, [tableDa]);

	const valuesForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postPriorityClubCell', { ...values, collegeId, userId: user?.uid })
				.then(() => {
					setValues({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	const [classData, setClassData] = useState([]);

	const courseKeys = ['prefered1', 'prefered2', 'prefered3', 'prefered4', 'prefered5', 'prefered6', 'prefered7', 'prefered8', 'prefered9', 'prefered10'];

	const dataValues = useMemo(() => {
		return courseKeys?.map((key) => values[key])?.filter(Boolean);
	}, [values]);

	const newClassData = useMemo(() => {
		return tableData?.filter((classItem: any) => !dataValues.includes(classItem._id));
	}, [tableData, dataValues]);

	useEffect(() => {
		if (newClassData?.length !== classData?.length) {
			setClassData(newClassData);
		}
	}, [newClassData, classData?.length]);

	console.log({ percentageFilled });

	return (
		<>
			<Main title='Willingness Form' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-full flex col pt-2 printable overflow-auto'>
						<div className='w-[100%] m-2'>
							<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
								{[
									{ fieldName: 'Priority 1 *', returnKey: 'prefered1' },
									{ fieldName: 'Priority 2 *', returnKey: 'prefered2' },
									{ fieldName: 'Priority 3 *', returnKey: 'prefered3' },
									{ fieldName: 'Priority 4 *', returnKey: 'prefered4' },
									{ fieldName: 'Priority 5 *', returnKey: 'prefered5' },
									{ fieldName: 'Priority 6', returnKey: 'prefered6' },
									{ fieldName: 'Priority 7', returnKey: 'prefered7' },
									{ fieldName: 'Priority 8', returnKey: 'prefered8' },
									{ fieldName: 'Priority 9', returnKey: 'prefered9' },
									{ fieldName: 'Priority 10', returnKey: 'prefered10' },
								]?.map((terms: any) => (
									<SubjectInputSet fieldName={terms?.fieldName} returnKey={terms.returnKey} values={values} setValues={setValues} classData={classData} tableData={tableData} />
								))}
							</div>
							<SectionHeader title={'Mandatory Field'} keyName={['prefered1', 'prefered1PreMember', 'prefered2', 'prefered2PreMember', 'prefered3', 'prefered3PreMember', 'prefered4', 'prefered4PreMember', 'prefered5', 'prefered5PreMember']} values={values} />

							<Button
								type='save'
								disabled={percentageFilled < 100}
								onClick={() => {
									valuesForm();
								}}>
								Save Preferences
							</Button>
						</div>
					</div>
				</Body>
			</Main>
		</>
	);
}

export default CordinatorShipForm;
