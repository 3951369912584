import React, { useEffect } from 'react';
import Input from '../../NecttosComp/Input/Input';
import Button from '../../NecttosComp/Button/Button';
import { Header, Main } from '../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import moment from 'moment';

function CustomKey({ currentList, setCurrentList, isBucket = false, dropdown }) {
	const [optionModal, setOptionModal] = React.useState(null);

	const sampleFOption = [
		{ type: 'gender', options: ['male', 'female'] },
		{ type: 'bloodGroup', options: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'], newItem: '', filter: '' },
	];

	useEffect(() => {
		if (optionModal) {
			const selectedOption = currentList.find((x) => x._id === optionModal._id);
			setOptionModal(selectedOption?.options || sampleFOption);
		}
	}, [currentList]);

	const handleToggleNeeded = (_id, neededStatus) => {
		const updatedData = currentList?.map((item) => (item._id === _id ? { ...item, needed: neededStatus } : item));
		setCurrentList(updatedData);
	};

	const handleAddItem = () => {
		if (optionModal.type === 'dropdown') {
			if (optionModal?.newItem?.trim() !== '') {
				setOptionModal({
					...optionModal,
					options: [...optionModal.options, optionModal.newItem.trim()],
					newItem: '',
				});
			}
		} else if (optionModal.type === 'text' || optionModal.type === 'number') {
			
			setOptionModal({
				...optionModal,
				options: optionModal.newItem,
				newItem: '',
			});
		}
	};

	const handleRemoveItem = (index) => {
		setOptionModal({
			...optionModal,
			options: optionModal.options.filter((_, i) => i !== index),
		});
	};

	const handleSaveOption = () => {
		setCurrentList((prevData) => prevData?.map((x) => (x._id === optionModal._id ? { ...x, options: optionModal.options } : x)));
		setOptionModal(false);
	};

	return (
		<>
			{Array.isArray(currentList) && currentList.length > 0 && (
				<Table width='1200' innerWidth={isBucket ? ['5%', '20%', '25%', '15%', '10%', '25%'] : ['5%', '15%', '15%', '10%', '10%', '8%', '13%', '14%', '10%']}>
					<Thead>
						<Tr>
							<Th position={0}>Sl.No.</Th>
							<Th position={1}>_id</Th>
							<Th position={2}>Name</Th>
							<Th position={3}>Table Head</Th>
							<Th position={4}>Type</Th>
							{!isBucket ? (
								<>
									<Th position={5}>Option</Th>
									<Th position={6}>Priority</Th>
									<Th position={7}>Start</Th>
									<Th position={8}>Action</Th>
								</>
							) : (
								<Th position={5}>Action</Th>
							)}
						</Tr>
					</Thead>
					<Tbody height='auto'>
						{currentList
							?.filter((x) => x.needed === !isBucket)
							?.map((item, index) => (
								<Tr key={index}>
									<Td index={index} position={0}>
										{index + 1}
									</Td>
									{item && (
										<>
											<Td index={index} position={1}>
												{item?._id.length > 20 ? item?._id.slice(0, 20) + '...' : item?._id}
											</Td>
											<Td index={index} position={2}>
												{item?.name.length > 20 ? item?.name.slice(0, 20) + '...' : item?.name}
											</Td>
											<Td index={index} position={3}>
												{item?.shortName}
											</Td>
											<Td index={index} position={4}>
												{item?.type}
											</Td>
										</>
									)}
									{!isBucket && (
										<>
											<Td index={index} position={5}>
												<Button type='close' color='green' onClick={() => setOptionModal(item)}>
													Select
												</Button>
											</Td>
											<Td index={index} position={6}>
												<Input
													key={index + 1}
													state={item}
													setState={(state) => {
														setCurrentList((prevData) => prevData?.map((x) => (x._id === item._id ? { ...x, priority: state.priority } : x)));
													}}
													className='m-0 p-0'
													width='100%'
													fieldName='Priority'
													returnKey='priority'
													options={['Emergency', 'High', 'Medium', 'Low']}
													type='drop'
												/>
											</Td>
											<Td index={index} position={7}>
												<Input
													key={index + 1}
													state={item}
													setState={(state) => {
														setCurrentList((prevData) => prevData?.map((x) => (x._id === item._id ? { ...x, start: state } : x)));
													}}
													className='m-0 p-0'
													width='100%'
													fieldName='Start'
													returnKey='start'
													type='date'
												/>
											</Td>
										</>
									)}
									{isBucket ? (
										<Td index={index} position={8}>
											<Button type='close' color='green' onClick={() => handleToggleNeeded(item._id, true)}>
												Add
											</Button>
										</Td>
									) : (
										<Td index={index} position={5}>
											<Button type='close' color='red' onClick={() => handleToggleNeeded(item._id, false)}>
												Delete
											</Button>
										</Td>
									)}
								</Tr>
							))}
					</Tbody>
				</Table>
			)}
			{optionModal && (
				<div className='fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
					<div className='bg-white p-6 rounded-lg shadow-lg w-2/3'>
						<h2 className='text-2xl font-bold mb-4'>Edit Options for {optionModal.name}</h2>
						<p className='text-gray-600 mb-4'>Type: {optionModal?.type}</p>

						{/* Filter and Add New Item Section */}
						{optionModal.type === 'dropdown' && (
							<div className='flex flex-col sm:flex-row items-center gap-4 mb-6'>
								<input
									type='text'
									placeholder='Search options...'
									className='flex-grow px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500'
									onChange={(e) =>
										setOptionModal({
											...optionModal,
											filter: e.target.value,
										})
									}
								/>
								<div className='flex items-center gap-2 flex-grow'>
									<input type='text' placeholder='Add new option' className='w-56 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500' value={optionModal.newItem} onChange={(e) => setOptionModal({ ...optionModal, newItem: e.target.value })} />
									<button className='bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800 transition' onClick={handleAddItem}>
										Add
									</button>
								</div>
							</div>
						)}
						{(optionModal.type === 'text' || optionModal.type === 'number') && (
							<div className='flex flex-col sm:flex-row items-center gap-4 mb-6'>
								<div className='flex items-center gap-2 flex-grow'>
									<input type='number' placeholder='Maximum limit' className='w-56 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500' value={optionModal.newItem} onChange={(e) => setOptionModal({ ...optionModal, newItem: e.target.value })} />
									<button className='bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800 transition' onClick={handleAddItem}>
										Add
									</button>
								</div>
							</div>
						)}

						{/* Options List */}
						{optionModal.type === 'dropdown' && (
							<div className='bg-gray-50 p-4 rounded-lg shadow-inner max-h-80 overflow-y-auto'>
								<ul className='space-y-2'>
									{optionModal?.options
										?.filter((opt) => opt.toLowerCase().includes((optionModal.filter || '').toLowerCase()))
										?.map((item, index) => (
											<li key={index} className='flex justify-between items-center bg-white p-2 rounded-lg shadow-sm'>
												<span className='text-gray-700 flex-grow'>{item}</span>
												<button className='text-red-600 font-bold hover:text-red-800' onClick={() => handleRemoveItem(index)}>
													✕
												</button>
											</li>
										))}
								</ul>
								{optionModal?.options?.length === 0 && <p className='text-gray-500 text-center mt-4'>No options available.</p>}
							</div>
						)}
						{(optionModal.type === 'text' || optionModal.type === 'number') && (
							<div className='bg-gray-50 p-4 rounded-lg shadow-inner max-h-80 overflow-y-auto'>
								<ul className='space-y-2'>
									<li className='flex justify-between items-center bg-white p-2 rounded-lg shadow-sm'>
										<span className='text-gray-700 flex-grow'>{optionModal?.options}</span>
									</li>
								</ul>
							</div>
						)}

					
						<div className='flex justify-end mt-6'>
							<button className='bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition mr-2' onClick={() => setOptionModal(false)}>
								Close
							</button>
							<button className='bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-800 transition' onClick={handleSaveOption}>
								Save Options
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default CustomKey;
