import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';

function Edit(props) {
	const { collegeId } = useContext(FirebaseContext);
	const [takenDate, setTakenDate] = useState(moment(new Date(props?.data?.tookOn)).format('yyyy-MM-DD'));
	const [returnDate, setReturnDate] = useState(moment(new Date(props?.data?.returnedOn)).format('yyyy-MM-DD'));
	const [fine, setFine] = useState(0);
	const [days, setDays] = useState(0);
	const [holiday, setHoliday] = useState(0);
	const [holidaysReport, setHolidaysReport] = useState();
	const [submitBtn, setSumbitBtn] = useState(false);

	const updateEditedData = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			await instance.post('/college/library/updateEditBook', {
				tookOn: takenDate,
				returnedOn: returnDate,
				fine,
				days,
				holidaysReport,
				userId: props.data.bookId,
			});
			window.alert('successfully edited');
		} catch {
			window.alert('something went wrong');
		}
	};

	const daysCalculate = async (tookDate, returnDate, collegeId, userId, bookId) => {
		const instance = await getAxiosTokenInstance();
		try {
			let resp = await instance.get('/college/library/editBookDate', {
				params: { tookDate, returnDate, collegeId, userId, bookId },
			});
			console.log(resp);
			if (resp.data.days) {
				setHoliday(resp.data.daysOff);
				setFine(resp.data.fine);
				setDays(resp.data.days);
				setHolidaysReport(resp.data.holidaysReport);
			}
		} catch {}
	};

	return (
		<div>
			<Row>
				<h2 style={{ fontFamily: 'fantasy', color: 'GrayText' }}>View</h2>

				<Col>
					<div>
						<label htmlFor=''>Taken Date :-</label>
						<input
							type='date'
							value={takenDate}
							onChange={(e) => {
								setTakenDate(e.target.value);
							}}
						/>
					</div>

					<div>
						<label htmlFor=''>Return Date :-</label>
						<input
							type='date'
							value={returnDate}
							onChange={(e) => {
								setReturnDate(e.target.value);
							}}
						/>
					</div>

					<button
						className='editBtn'
						style={{ marginTop: '7px' }}
						onClick={async () => {
							await daysCalculate(takenDate, returnDate, collegeId, props.data.userId, props.data.bookId);
							setSumbitBtn(true);
						}}>
						Load
					</button>

					<div>
						<label htmlFor=''>Days</label>
						<h5 style={{ border: 'solid 0.5px', borderRadius: '4px' }}>{days}</h5>
					</div>
					<div>
						<label htmlFor=''>Holiday</label>
						<h5 style={{ border: 'solid 0.5px', borderRadius: '4px' }}>{holiday}</h5>
					</div>
					<div>
						<label htmlFor=''>Fine :- </label>
						<input type='text' pattern='[0-9]*' value={fine} style={{ borderRadius: '3px' }} onChange={(e) => setFine(e.target.value)} />
					</div>
				</Col>
			</Row>

			<div className='centerContent'>
				{submitBtn ? (
					<button
						className='editBtn animation-opacity'
						style={{ marginTop: '5px' }}
						onClick={() => {
							updateEditedData();
							setSumbitBtn(false);
						}}>
						Submit
					</button>
				) : null}
			</div>
		</div>
	);
}

export default Edit;
