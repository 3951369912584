import React, { useContext, useEffect, useState } from 'react';
import { signOut, getAuth } from 'firebase/auth';
import { QueryCache } from 'react-query';
import { FullScreenContext } from '../../context/FullScreenContext';
import styles from './dashLayout.module.scss';
import enterFs from '../../static/images/clipIcon/enterFS.png';
import exitFS from '../../static/images/clipIcon/exitFS.svg';
import logo from '../../static/images/logo.jpeg';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import { FirebaseContext } from '../../context/FirebaseContext';

const menu = () => {
	return (
		<Menu>
			<Menu.Item>
				<Link to='/profile'>profile</Link>
			</Menu.Item>

			<Menu.Item
				danger
				onClick={() => {
					// Cookies.remove("userToken")
					// localStorage.removeItem("userDetails")
					window.location.href = '/';
				}}>
				Logout
			</Menu.Item>
		</Menu>
	);
};

function Navbar({ title, superAdmin }) {
	const [loggedInUser, setLoggedInUser] = useState({});
	const { isFullScreen, togleFullScreen } = useContext(FullScreenContext);
	const { collegeId, collegeList } = useContext(FirebaseContext);

	const { data: userDetail } = useProfileData();

	useEffect(() => {
		setLoggedInUser(userDetail);
	}, [userDetail]);

	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	return (
		<div className={styles.navbar}>
			<img src={superAdmin ? logo : selectedCollege?.logo} alt='' className={styles.avatarCollege} />
			<div className={styles.title}>{selectedCollege?.collegeName}</div>
			<Dropdown overlay={menu} arrow>
				<div className={styles.btUser}>
					<span>{loggedInUser?.name || 'Admin'}</span>
					<CaretDownOutlined />
					<img src={loggedInUser?.photoURL || 'https://image.flaticon.com/icons/png/512/149/149071.png'} alt='' className={styles.avatarUser} />
				</div>
			</Dropdown>
			<img className={`${styles.expand} ${styles[isFullScreen]}`} src={isFullScreen ? exitFS : enterFs} alt='' onClick={togleFullScreen} />
		</div>
	);
}

export default Navbar;
