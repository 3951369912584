import { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import React from "react";

const getFieldDropDowns = (data: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getStudentsDropDown", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.result;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface LoastRegisterProps {
  onClose: () => void;
}

function LoastRegister({ onClose }: LoastRegisterProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const { collegeId } = useContext(FirebaseContext);
  const [width, setWidth] = useState("40vw");
  const ref = useRef<any>(null);

  const { data: students = [] } = useQuery(["vendorDropDown", addNew?.lostBy, "bookStocks"], getFieldDropDowns({ collegeId, search: addNew?.lostBy }), { keepPreviousData: true });

  const getData = async () => {
    try {
      const instance = await getAxiosTokenInstance();

      instance
        .get("/college/library/getlostRegister")
        .then((res) => {
          console.log(res?.data?.data);
          setTableData(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const fetchBook = async (accessionNumber?:string) => {
    try {
      const instance = await getAxiosTokenInstance();

      instance.get("/college/library/getBookData", { params: { collegeId, accessionNumber:accessionNumber || addNew?.accessionNumber } }).then((res) => {
        console.log(res?.data?.data);
        const val = res?.data?.data;
        setAddNew({ ...addNew, title: val?.nameOfBook, rateOfBook: val?.price, img: val?.image, author: val?.nameOfAuthor, subject: val?.subject, date: val?.yearOfPublication ,bookId:res.data?.data?._id});
        setWidth("80vw");
      }).catch((err)=>{
        setAddNew({ ...addNew, title: "", rateOfBook: "", img: "", author: "", subject: "", date: "",bookId:"" });
        setWidth("40vw");
        console.log(err);
        
      })
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (_id: string,bookId:string | null) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .delete("/college/library/deleteLostRegister/" + _id, { params: { collegeId,bookId } })
        .then(() => {
          getData();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();

      instance
        .post("/college/library/addLostRegister", { ...addNew, collegeId })
        .then(() => {
          setAddNew({});
          getData();
        })
        .catch((err: any) => {
          console.log(err);
        });
      closeAnimation();
      setTimeout(() => setShowForm(false), 250);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Main title="Lost Register" width="90vw" height="90vh">
        <Header>
          <Button
            type="update"
            onClick={() => {
              setShowForm(true);
              setAddNew({})
              setWidth("40vw")
            }}
          >
            New Bill
          </Button>
          <div className="flex">
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] printable overflow-auto">
            <Table width="100%" innerWidth={["4%", "9%", "9%", "9%", "9%", "9%", "9%", "9%", "9%", "9%", "15%"]}>
              <Thead>
                <Tr>
                  <Th position={0}>Sl.No.</Th>
                  <Th position={1}>Date of Lost</Th>
                  <Th position={2}>Acc.No.</Th>
                  <Th position={3}>Who Lost</Th>
                  <Th position={4}>Rate of Book</Th>
                  <Th position={5}>Assigned By</Th>
                  <Th position={6}>Penalty</Th>
                  <Th position={7}>Reciept No.</Th>
                  <Th position={8}>Date and Time of Amount Recieved</Th>
                  <Th position={9}>Collected by</Th>
                  <Th position={10}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {tableData?.map((x: any, i: number) => (
                  <Tr>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.dateOfLost}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.accessionNumber}
                    </Td>
                    <Td index={i} position={3}>
                      {x?.lostBy?.name}
                    </Td>
                    <Td index={i} position={4}>
                      {x?.rateOfBook}
                    </Td>
                    <Td index={i} position={5}>
                      {x?.username}
                    </Td>
                    <Td index={i} position={6}>
                      {x?.penaltyAmount}
                    </Td>
                    <Td index={i} position={7}>
                      {x?.recieptNo || "pending"}
                    </Td>
                    <Td index={i} position={8}>
                      {x?.dateOfAmountReciept || "pending"} {x?.timeOfAmountReciept}
                    </Td>
                    <Td index={i} position={9}>
                      {x?.collectedBy || "pending"}
                    </Td>
                    <Td index={i} position={10}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            console.log(x);
                            setAddNew(x);
                            fetchBook(x?.accessionNumber)
                            setShowForm(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button type="delete" onClick={() => handleDelete(x?._id,x?.bookId)}>
                          Delete
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        <Footer></Footer>
      </Main>

      {showForm && (
        <Main width={width}>
          <Header>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 250);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>
            <div className="grid grid-flow-col">
              <div className="flex flex-col">
              <Input width="100%" fieldName="Accession Number" returnKey="accessionNumber" state={addNew} setState={setAddNew} type="number" />
                <div>
                  <Button
                    type="fetch"
                    onClick={() => {
                      fetchBook();
                    }}
                  >
                    Fetch Data
                  </Button>
                </div>
                <Input width="100%" fieldName="Date of Lost" returnKey="dateOfLost" state={addNew} setState={setAddNew} type="date" />
                <Input width="100%" fieldName="Who Lost" returnKey="lostBy" valueShowKey="name" state={addNew} setState={setAddNew} options={students} optionDisplay="name" type="drop" />
                <Input width="100%" fieldName="Penalty Amount" returnKey="penaltyAmount" state={addNew} setState={setAddNew} type="number" />
                <Input width="100%" fieldName="Remarks" returnKey="remarks" state={addNew} setState={setAddNew} type="text" />
               
                <Button
                  type="save"
                  onClick={() => {
                    addNewForm();
                  }}
                >
                  Save
                </Button>
              </div>
              {addNew?.title && (
                <div className="flex p-4">
                  {addNew?.img && 
                  <img src={addNew?.img} alt="Photo of book" className="w-1/2 h-[600px]" />
                  }
                  <div className="ps-5">
                    <h6>Name : {addNew?.title}</h6>
                    <h6 className="flex">Rate Of Book : {addNew?.rateOfBook || <p className="text-red-500">no rate</p>}</h6>
                    <h6 className="flex">Author : {addNew?.author || <p className="text-red-500">no author</p>}</h6>
                    <h6 className="flex">Subject : {addNew?.subject || <p className="text-red-500">no subject</p>}</h6>
                    <h6 className="flex">Year : {addNew?.date || <p className="text-red-500">no year</p>}</h6>
                  </div>
                </div>
              )}
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default LoastRegister;
