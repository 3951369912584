


import React, { useContext, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import classNames from "./addBookModal.module.scss";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { List } from 'antd';

const getFieldDropDowns = (data) => async () => {

  console.log(data);
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/action/getBookNames', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data?.list[0].data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

function SearchableBookInput({
  filter,
  keyData,
  stateData,
  setStateData,
  titleData
}) {

  const { collegeId } = useContext(FirebaseContext);


  const [showList, setShowList] = React.useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowList(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }



  const { data: nameOfAuthorDrop = [], } = useQuery(['dataDataData', stateData[keyData]], getFieldDropDowns({ collegeId, search: stateData[keyData], filter }))

  const nameOfAuthor = useRef(null);
  useOutsideAlerter(nameOfAuthor);
  const ref = useRef(null);
  return (

    <div>
      <div style={{ margin: 10 }}>
        <div className={classNames.group}>
          <input
            type="text"
            onChange={(e) => setStateData({ ...stateData, [keyData]: e.target.value })}
            onFocus={() => setShowList(true)}
            value={stateData[keyData]}
          /><label>{titleData}</label>
        </div>
        <div ref={nameOfAuthor}>
          {showList && stateData[keyData] && nameOfAuthorDrop.length ?
            <List
              size="small"
              style={{ margin: 0, padding: 0 }}
              dataSource={nameOfAuthorDrop}
              renderItem={item => (<List.Item><div style={{ cursor: 'pointer', width: '100%', height: 20, color: 'green' }} onClick={() => setStateData({
                ...stateData,
                [keyData]: item.accessionNumber + ' | ' + item.nameOfBook,
                [keyData + 'bookId']: item.bookId,
                [keyData + 'bookStocksId']: item._id,
                [keyData + 'imageUrl']: item.image,
                [keyData + 'callNo']: item.callNo,
                [keyData + 'accessionNumber']: item.accessionNumber,
                [keyData + 'nameOfBook']: item.nameOfBook,
                [keyData + 'bookDetails']: item.bookDetails
              })}>
                {item.accessionNumber + ' | ' + item.callNo + ' | ' + item.nameOfBook}
              </div></List.Item>)} />
            : null}
        </div>


      </div>
    </div >

  )

}

export default SearchableBookInput;
