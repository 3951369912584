import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import moment from 'moment';
import { getReports } from '../../NecttosComp/APICaller/ApiServices';
import { useQuery } from 'react-query';
import { useAccountsHeads } from '../../NecttosComp/APICaller/queryHooks';
import { PulseLoader } from 'react-spinners';

export const CollectioReportsNew = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const componentRef = useRef();
	const newRef = useRef();
	const [date] = useState({});
	const [searchBy, setSearchBy] = useState('');
	const [filteredData, setFilterData] = useState('');
	const [collectedByArray, setCollectedByArray] = useState([]);

	const [state, setState] = React.useState({
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		startTime: '',
		endTime: '',
		mainAccount: '',
		subAccount: '',
		method: '',
		collegeId,
		check: false,
		collectedBy: 'All',
	});

	let queryData = {
		startDate: state?.startDate,
		endDate: state?.endDate,
		startTime: state?.startTime,
		endTime: state?.endTime,
		mainAccount: state?.mainAccount || '',
		subAccount: state?.subAccount || '',
		method: state?.method || '',
		isHideBills: state?.check ? '$eq' : '$ne',
		collegeId,
		searchBy: searchBy || '',
	};

	const { data = [], isLoading } = useQuery(['getReports', state?.startDate, state?.endDate, state?.startTime, state?.endTime, state?.mainAccount, state?.subAccount, state?.method, state?.check], getReports(queryData));

	useEffect(() => {
		if (data.collections) {
			setFilterData(data);
		}
		const collectedBy = [...new Set((data.collections || []).map((item) => item.collectedBy))];
		console.log({ collectedBy });
		setCollectedByArray(collectedBy);
	}, [data.collections]);

	useEffect(() => {
		if (data.collections && state.collectedBy && state.collectedBy !== 'All') {
			let filterBy = data.collections.filter((item) => item.collectedBy == state.collectedBy);

			setFilterData((prv) => ({
				...prv,
				collections: filterBy,
			}));
		} else if (state.collectedBy == 'All') {
			setFilterData(data);
		}
		if (data.collections && !state.collectedBy) {
			let filterBy = data?.collections.filter((item) => !item.collectedBy);
			setFilterData((prv) => ({
				...prv,
				collections: filterBy,
			}));
		}
	}, [state.collectedBy, data.collections]);

	console.log({ data, filteredData });

	useEffect(() => {
		if (searchBy && typeof searchBy === 'string') {
			const lowercasedSearch = searchBy.toLowerCase();
			const temp = data?.collections?.filter((stu) => (stu.name && stu.name.toLowerCase().includes(lowercasedSearch)) || (stu.admisionNo && stu.admisionNo.toString().toLowerCase().includes(lowercasedSearch)) || (stu.billNo && stu.billNo.toString().toLowerCase().includes(lowercasedSearch)) || (stu.amount && stu.amount.toString().toLowerCase().includes(lowercasedSearch)));
			setFilterData((prev) => ({
				...prev,
				collections: temp,
			}));
		} else {
			setFilterData(data);
		}
	}, [searchBy, data.collections]);

	const accounts = useAccountsHeads();
	let subAccountDrop =
		[
			{
				label: 'All Accounts',
				value: undefined,
			},
			...(accounts?.data?.subAccounts || []),
		] || [];

	let mainAccountDrop =
		[
			{
				label: 'All Accounts',
				value: undefined,
			},
			...(accounts?.data?.mainAccounts || []),
		] || [];

	if (state?.mainAccount) {
		subAccountDrop = subAccountDrop.filter((x) => x.mainId === state?.mainAccount);
	}
	const payMethods = [
		{
			title: 'All',
			key: 'All',
		},
		{
			title: 'Bank',
			key: 'Online',
		},
		{
			title: 'Cash',
			key: 'Normal',
		},
		{
			title: 'SCHOLARSHIP',
			key: 'Scholarship',
		},
	];

	const columns = [
		{
			title: 'Reciept No',
			dataIndex: 'billNo',
			key: 'billNo',
			columnWidth: '15%',
		},
		{
			title: 'Admn No',
			dataIndex: 'admisionNo',
			key: 'admisionNo',
			columnWidth: '15%',
		},
		{
			title: 'Student',
			dataIndex: 'name',
			key: 'name',
			columnWidth: '15%',
		},
		{
			title: 'Class',
			dataIndex: 'className',
			key: 'className',
			columnWidth: '5%',
		},

		...(state?.method === 'Online'
			? [
					{
						title: 'Bank Account',
						dataIndex: 'recievedBank',
						key: 'recievedBank',
						columnWidth: '15%',
					},
					{
						title: 'Transaction Id',
						dataIndex: 'transactionId',
						key: 'transactionId',
						columnWidth: '15%',
					},
					{
						title: 'Account Holder Name',
						dataIndex: 'accountHolderName',
						key: 'accountHolderName',
						columnWidth: '15%',
					},
					{
						title: 'Transaction Date',
						dataIndex: 'transactionDate',
						key: 'transactionDate',
						columnWidth: '15%',
					},
			  ]
			: [
					{
						title: 'Where',
						dataIndex: 'recievedBank',
						key: 'recievedBank',
						columnWidth: '15%',
					},
			  ]),
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			columnWidth: '15%',
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			columnWidth: '15%',
		},

		{
			title: 'Collected By',
			dataIndex: 'collectedBy',
			key: 'collectedBy',
			columnWidth: '15%',
		},
	];

	const [showSummary, setShowSummary] = useState(true);

	const toggleSummary = () => {
		setShowSummary(!showSummary);
	};

	console.log({ state });
	console.log({ accounts });
	console.log({ data });

	return (
		<>
			<Main title={'Collection Reports'} height={'100vh'} width={'100vw'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Input type='date' state={state} fieldName='Start Date' returnKey='startDate' setState={setState} />
						<Input type='date' state={state} returnKey='endDate' fieldName='End Date' setState={setState} />
						<Input type='drop' options={mainAccountDrop} optionDisplay='label' optionKey='value' didntShowKey state={state} fieldName='Main Head' returnKey='mainAccount' setState={setState} />
						<Input type='drop' options={subAccountDrop} optionDisplay='label' optionKey='value' didntShowKey state={state} fieldName='Sub Head' returnKey='subAccount' setState={setState} />
						<Input type='drop' options={payMethods} optionDisplay='title' optionKey='key' didntShowKey state={state} fieldName='Methods' returnKey='method' setState={setState} />
						<Input type='drop' options={['All', ...collectedByArray]} state={state} fieldName='Collected By' returnKey='collectedBy' setState={setState} />
						<Input type='text' state={searchBy} fieldName='Search' setState={setSearchBy} className='mb-3' />
					</div>
					<div className='flex flex-wrap items-center'>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>

						<Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button type='pdf' tableRef={componentRef.current} pdfId='accounts'>
							PDF
						</Button>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={componentRef} className='printable  w-full h-auto'>
						<div className='flex'>
							<div className='w-[100%]'>
								<div class='flex flex-row items-center justify-between p-4 border-b-2 h-36'>
									<div class='flex items-center'>
										<img class='w-16 h-16 mr-4 print:h-24 print:w-24' src={selectedCollege?.logo} alt='College Logo' />
										<div>
											<h1 class='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
											<p class='text-md font-semibold '>{selectedCollege?.accreditedGrade}</p>
											<p class='text-md font-semibold '>{selectedCollege?.address}</p>
										</div>
									</div>

									<div class='text-right'>
										<p class='text-xl font-medium '>Collection Reports</p>
										<p class='text-md font-medium '>
											{date.type} - {date.subType}
										</p>

										<p class='text-sm font-medium '>
											{state?.startDate && state?.endDate && (
												<span>
													{moment(state?.startDate).format('DD MMM YYYY')} to {moment(state?.endDate).format('DD MMM YYYY')}
												</span>
											)}
										</p>
										<p class='text-sm font-medium '>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
									</div>
								</div>
								<div className='flex justify-center w-[100%] h-[69vh] p-2 '>
									{data.collections ? (
										<div ref={newRef} className='flex gap-1 w-[100%] printable h-full overflow-auto print:h-auto print:overflow-visible'>
											<div className='flex-1 w-[100%]'>
												<Table id='accounts' width='100%' innerWidth={['10%', '50%', '20%', '20%']}>
													<Thead>
														<Tr>
															{columns.map((col, i) => (
																<Th key={i} width={col.innerWidth}>
																	{col.title}
																</Th>
															))}
														</Tr>
													</Thead>
													<Tbody>
														{filteredData.collections?.map((item, index) => (
															<Tr key={index}>
																{columns.map((col, i) => {
																	const cellValue = item[col.key];
																	return (
																		<Td key={i} width={col.innerWidth} index={index}>
																			{cellValue !== undefined ? cellValue : '--'}
																		</Td>
																	);
																})}
															</Tr>
														))}
														<Tr></Tr>
													</Tbody>
												</Table>
											</div>
											<div className='absolute bottom-1 right-4 print:hidden'>
												<button onClick={toggleSummary} className='px-2 py-1 bg-blue-500 text-white text-xs border-blue-500 font-medium rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50'>
													{showSummary ? 'Hide Summary' : 'Show Summary'}
												</button>
											</div>
										</div>
									) : (
										<div className='flex justify-center items-center pt-20'>{isLoading ? <PulseLoader color='#002147' size={15} /> : <img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/nodatafound.png?alt=media&token=75763b23-2b98-4570-b308-963bc28c070b' alt='' />}</div>
									)}
								</div>
							</div>
							{data.collections && showSummary && (
								<div className='w-96 h-[68vh] min-h-full rounded print:w-auto mt-36 pt-2'>
									<div className='flex-none p-6 bg-white border border-gray-300 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 overflow-auto max-h-full'>
										<label className='block text-lg font-bold mb-4 text-black dark:text-gray-100'>Details</label>

										<div className='flex justify-between items-center mb-3 '>
											<span className='text-black text-sm font-medium'>Total Entries</span>
											<span className='font-medium text-black text-sm'>{data?.collections?.length || '0'}</span>
										</div>

										<div className='flex justify-between items-center mb-3'>
											<span className='text-black text-sm font-medium'>Total Amount</span>
											<span className='font-medium text-black text-sm'>{data?.totalCash || '0'}</span>
										</div>

										{data?.dayWiseTotalArray?.map((day, index) => (
											<div key={index} className={`flex justify-between items-center mb-3 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'}`}>
												<span className='text-black text-sm font-medium'>{day?.type}</span>
												<div className='flex items-center justify-center space-x-2'>
													<span className='font-medium text-black text-sm'>{day?.count}</span>
													<span className='text-black text-sm font-medium'>/</span>
													<span className='font-medium text-black text-sm'>{day?.sum}</span>
												</div>
											</div>
										))}

										<div className='mt-4'>
											<label className='inline-flex items-center text-sm text-black font-medium'>
												<input type='checkbox' className='h-5 w-5 text-blue-600 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500' defaultChecked={state.check} onChange={() => setState({ ...state, check: !state.check })} />
												<span className='ml-2'>Check</span>
											</label>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Body>
			</Main>
		</>
	);
};
