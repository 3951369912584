import React, { useContext, useState } from "react";
import classNames from "./uploadMultipleUserCsv.module.scss";
import { CSVReader } from "react-papaparse";
import * as Yup from "yup";
import { useMutation } from "react-query";
import message from "antd/lib/message";

import { MOBILE_REGEX } from "../../config/regex";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { ArrowRightOutlined } from "@ant-design/icons";

const addUser = async (data) => {
  const instance = await getAxiosTokenInstance();
  const resp = await instance.post("/college/users/addUserCsv", data);
  return resp.data;
};

const LINKS = {
  staff: "https://firebasestorage.googleapis.com/v0/b/necttosapp-f3cc4.appspot.com/o/Sample%20Csv%2FstaffSample.csv?alt=media&token=8a60b256-1753-474f-813d-8d678d5f34ad",
  student: "https://firebasestorage.googleapis.com/v0/b/necttosapp-f3cc4.appspot.com/o/Sample%20Csv%2FstudentSample.csv?alt=media&token=d66b4bbb-5884-40d1-8028-01dab0b13cf3",
  teacher: "https://firebasestorage.googleapis.com/v0/b/necttosapp-f3cc4.appspot.com/o/Sample%20Csv%2FteacherSample.csv?alt=media&token=fc372ff7-635e-4b1d-b4f0-3f012341873f",
};

function UploadMultipleUserCsv(props) {
  const [userList, setUserList] = useState([]);
  const [errorMessage, updateErrorMessage] = useState("");
  const { collegeId } = useContext(FirebaseContext);

  const { mutate } = useMutation(addUser, {
    onSuccess: (data) => {
      if (data.statusCode === 400) {
        let text = data.message;
        navigator.clipboard.writeText(text);
        var errorData = new Blob([text], { type: "text/plain" });
        var url = window.URL.createObjectURL(errorData);
        window.open(url, "_blank");
        updateErrorMessage("Some Users Not Created");
      } else {
        message.success("Users Added");
        props?.onCancel();
      }
    },
    onError: (error) => {
      updateErrorMessage(error?.response?.data?.message || error?.message || "API Error");

      setUserList([]);
    },
  });

  function addUsers() {
    const data = { collegeId, type: props?.userType, userList };
    VALIDATION_SCHEMA.validate(data)
      .then((data) => {
        mutate(data);
      })
      .catch((er) => {
        updateErrorMessage("Invalid Data");
      });
  }

  function parseData(csvData) {
    let userListTemp = [];
    csvData?.forEach((row) => {
      userListTemp.push(row.data);
    });
    if (userListTemp.length) {
      switch (props?.userType) {
        case "student":
          USERLIST_VALIDATION_SCHEMA_STUDENT.validate(userListTemp)
            .then(() => {
              setUserList(userListTemp);
            })
            .catch((reason) => {
              updateErrorMessage(reason.errors[0] || "Invalid or Misformatted Csv");
            });
          break;
        case "teacher":
          USERLIST_VALIDATION_SCHEMA_TEACHER.validate(userListTemp)
            .then(() => {
              setUserList(userListTemp);
            })
            .catch((reason) => {
              updateErrorMessage(reason.errors[0] || "Invalid or Misformatted Csv");
            });
          break;
        case "staff":
          USERLIST_VALIDATION_SCHEMA_STAFF.validate(userListTemp)
            .then(() => {
              setUserList(userListTemp);
            })
            .catch((reason) => {
              updateErrorMessage(reason.errors[0] || "Invalid or Misformatted Csv");
            });
          break;

        default:
          updateErrorMessage("Invalid Type");
          break;
      }
    } else {
      updateErrorMessage("No Data Or Invalid Data On File");
    }
  }

  return (
    <div div className={classNames.uploadMultipleUserCsv}>
      <div className={classNames.title}>
        <a href={LINKS[props?.userType]} download>
          Please download sample {props?.userType} CSV form?{" "}
        </a>
      </div>
      <label
        className={classNames.enterCsv}
        onClick={() => {
          updateErrorMessage("");
        }}
      >
        <CSVReader onDrop={parseData} onError={() => {}} onRemoveFile={() => {}} config={{ header: true }} />
        <div className={classNames.label}>{userList.length ? `${userList.length} Users Read` : "Select CSV file here"} </div>
        <div className={classNames.btnBrowse}>Browse</div>
      </label>

      {userList && userList?.length > 0 && (
        <>
          <div className={classNames.messageLabel}>Message</div>
          <div className={classNames.message}>
            Ready to upload {userList?.length} {props?.userType === "student" ? "Students" : props?.userType === "teacher" ? "Teachers" : "Staffs"}
          </div>
        </>
      )}
      {errorMessage && errorMessage !== "" && <div className={`${classNames.message} ${classNames.error}`}>** {errorMessage} **</div>}

      <div className={classNames.btns}>
        <div className={classNames.btnClear} onClick={props?.onCancel}>
          Clear
        </div>
        <div className={classNames.formButtonWidth}>
          <button
            type="primary"
            onClick={() => {
              if (userList.length) {
                addUsers();
                updateErrorMessage("");
              } else {
                updateErrorMessage("Upload A Valid CSV");
              }
            }}
          >
            Submit <ArrowRightOutlined style={{ fontSize: 22, margin: "auto 0 auto 5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadMultipleUserCsv;

const USERLIST_VALIDATION_SCHEMA_STUDENT = Yup.array().of(
  Yup.object({
    fullName: Yup.string().trim().required("Please Select A Name"),
    phoneNumber: Yup.string().trim().optional(),
    classId: Yup.string().trim().required("Class ID Reqired"),
    rollNo: Yup.string().trim().optional(),
    admisionNo: Yup.string().trim().optional(),
    hallTicketNo: Yup.string().trim().optional(),
    capId: Yup.string().trim().optional(),
    fatherName: Yup.string().trim().optional(),
    motherName: Yup.string().trim().optional(),
    houseName: Yup.string().trim().optional(),
    postOffice: Yup.string().trim().optional(),
    pinCode: Yup.string().trim().optional(),
    admissionType: Yup.string().trim().optional(),
    tcDate: Yup.string().trim().optional(),
    tcNumber: Yup.string().trim().optional(),
    activeStatus: Yup.string().trim().optional(),
    guardianMobile: Yup.string().trim().optional(),
    dateOfJoin: Yup.string().trim().optional(),
    language: Yup.string().trim().optional(),
    gender: Yup.string().trim().optional(),
  })
);
const USERLIST_VALIDATION_SCHEMA_TEACHER = Yup.array().of(
  Yup.object({
    fullName: Yup.string().trim().required("Please Select A Name"),
    phoneNumber: Yup.string().trim().optional(),
    departmentId: Yup.string().trim().required("Depaartment ID Reqired"),
    univercityRegisterId: Yup.string().trim().optional(),
    dateOfJoin: Yup.string().trim().optional(),
    gender: Yup.string().trim().optional(),
  })
);
const USERLIST_VALIDATION_SCHEMA_STAFF = Yup.array().of(
  Yup.object({
    fullName: Yup.string().trim().required("Please Select A Name"),
    phoneNumber: Yup.string().trim().optional(),
    dateOfJoin: Yup.string().trim().optional(),
    gender: Yup.string().trim().optional(),
  })
);

const VALIDATION_SCHEMA = Yup.object({
  collegeId: Yup.string().trim().required("CollegeId Required"),
  type: Yup.string().trim().required("Please Select User Type"),
  userList: Yup.array().required(),
});
