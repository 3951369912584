import React, { useContext, useEffect, useRef, useState } from 'react';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import Input from '../../NecttosComp/Input/Input';
import Button from '../../NecttosComp/Button/Button';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { Select } from 'antd';
import { getSubAccounts, getMainAccounts } from '../../NecttosComp/APICaller/ApiServices';

export const SubAccounts = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const { data: accountData = [] } = useQuery(['getMainAccounts', collegeId], getMainAccounts(collegeId));
	let { data = [], refetch } = useQuery('getSubAccounts', getSubAccounts(collegeId));
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState({});
	const [showForm, setShowForm] = useState(false);
	const [selectedMainId, setSelectedMainId] = useState('');
	const [filteredData, setFilteredData] = useState(data);

	const manageSubAccount = async (deleteData, _id) => {
		console.log(deleteData, _id);
		try {
			setIsLoading(true);
			if (!state.name && !_id) {
				throw new Error('Account Name is Empty');
			}
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postSubAccounts', {
				collegeId,
				name: state?.name,
				deleteData,
				amount: state?.amount,
				subsidiaryRegisterOrder: state?.subsidiaryRegisterOrder || '',
				dailyFeeCollectionOrder: state?.dailyFeeCollectionOrder || '',
				shortName: state?.shortName || '',
				mainId: state?.mainId || '',
				_id: state?._id || _id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					name: '',
					amount: '',
					subsidiaryRegisterOrder: '',
					dailyFeeCollectionOrder: '',
					mainId: '',
					shortName: '',
					_id: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};
	const handleFilterChange = (value) => {
		if (value === 'All') {
			setFilteredData(data);
		} else {
			const filtered = data.filter((item) => item.mainId === value);
			setFilteredData(filtered);
		}
	};

	const handleSearch = (value) => {
		const filteredOptions = data.filter((item) => {
			const name = typeof item.name === 'string' ? item.name.toLowerCase() : '';
			const mainName = typeof item.mainName === 'string' ? item.mainName.toLowerCase() : '';
			const shortName = typeof item.shortName === 'string' ? item.shortName.toLowerCase() : '';
			const amount = typeof item.amount === 'string' ? item.amount.toLowerCase() : '';
			const subsidiaryRegisterOrder = typeof item.subsidiaryRegisterOrder === 'string' ? item.subsidiaryRegisterOrder.toLowerCase() : '';
			const dailyFeeCollectionOrder = typeof item.dailyFeeCollectionOrder === 'string' ? item.dailyFeeCollectionOrder.toLowerCase() : '';

			return name.includes(value.toLowerCase()) || mainName.includes(value.toLowerCase()) || shortName.includes(value.toLowerCase()) || amount.includes(value.toLowerCase()) || subsidiaryRegisterOrder.includes(value.toLowerCase()) || dailyFeeCollectionOrder.includes(value.toLowerCase());
		});
		setFilteredData(filteredOptions);
	};

	useEffect(() => {
		setFilteredData(data);
	}, [data]);

	const { tableItems, requestSort, getIcon } = useSortableData(filteredData);

	return (
		<Main zInd={100} width='100%' height='100%' title='Sub Accounts Management'>
			{isLoading ? (
				<LoadingAnimation dark />
			) : (
				<div className='flex flex-col md:flex-row w-full h-full'>
					<div className='flex flex-col md:w-1/5 w-full p-2'>
						<Button type='update' width='100%' onClick={() => setShowForm(!showForm)}>
							{showForm ? 'Hide Form' : 'Add New'}
						</Button>
						{showForm && (
							<>
								<Input typable width='100%' didntShow fieldName='Select Main A/C' returnKey='mainId' optionDisplay='name' optionKey='_id' state={state} setState={setState} options={Array.isArray(accountData) ? accountData : []} type='drop' />
								<Input typable width='100%' didntShow fieldName='Account Name' returnKey='name' state={state} setState={setState} distinctKey='name' collectionName={'subAccounts'} type='drop' />
								<Input width='100%' didntShow fieldName='Short Name' returnKey='shortName' state={state} setState={setState} type='text' />
								<Input width='100%' didntShow fieldName='Amount' returnKey='amount' state={state} setState={setState} type='number' />
								<Input width='100%' didntShow fieldName='SRRO' returnKey='subsidiaryRegisterOrder' state={state} setState={setState} type='text' />
								<Input width='100%' didntShow fieldName='DFCRO' returnKey='dailyFeeCollectionOrder' state={state} setState={setState} type='text' />
								<div className='flex flex-row gap-2'>
									<Button
										color='red'
										width='100px'
										onClick={() => {
											setState({
												name: '',
												amount: '',
												subsidiaryRegisterOrder: '',
												dailyFeeCollectionOrder: '',
												shortName: '',
												mainId: '',
												mainIdval: '',
												_id: '',
												mainName: '',
											});
										}}>
										Clear Form
									</Button>
									<Button
										type='submit'
										width='100px'
										onClick={() => {
											manageSubAccount();
										}}>
										Submit
									</Button>
								</div>
							</>
						)}
					</div>
					<div className='md:w-4/5 w-full mt-2 h-full md:ml-4'>
						<Header>
							<div className='flex'>
								<Select value={selectedMainId === '' ? undefined : selectedMainId} showSearch style={{ width: 250, marginBottom: 16 }} placeholder='Search...' onChange={handleFilterChange} filterOption={false} onSearch={handleSearch}>
									<Select.Option value='All'>See All</Select.Option>
									{accountData.map((account) => (
										<Select.Option key={account._id} value={account._id}>
											{account.name}
										</Select.Option>
									))}
								</Select>
							</div>
							<div className='flex'>
								<Button type='fetch' onClick={() => window.location.reload()}>
									Reload
								</Button>
								<Button type='print' tableRef={componentRef.current}>
									Print
								</Button>
								<Button width='100%' type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</Header>
						<Thead>
							<Tr>
								<Th position={0}>S.N</Th>
								<Th position={1} onClick={() => requestSort('mainName')} icon={getIcon('mainName')}>
									Main Account Name
								</Th>
								<Th position={1} onClick={() => requestSort('name')} icon={getIcon('name')}>
									Account Name
								</Th>
								<Th position={3} onClick={() => requestSort('shortName')} icon={getIcon('shortName')}>
									Short Name
								</Th>
								<Th position={3} onClick={() => requestSort('amount')} icon={getIcon('amount')}>
									Unit Amount
								</Th>
								<Th position={3} onClick={() => requestSort('subsidiaryRegisterOrder')} icon={getIcon('subsidiaryRegisterOrder')}>
									SRRO
								</Th>
								<Th position={3} onClick={() => requestSort('dailyFeeCollectionOrder')} icon={getIcon('dailyFeeCollectionOrder')}>
									DFCRO
								</Th>
								<Th position={2}>Action</Th>
							</Tr>
						</Thead>
						<Body height='85%' width='100%'>
							<div ref={componentRef} className='h-full w-full'>
								<Table width='100%' innerWidth={['5%', '15%', '15%', '10%']}>
									<Tbody height='auto'>
										{tableItems?.length === 0 ? (
											<Tr>
												<Td textAlign='center' colSpan={8}>
													SubAccount Data Is Empty
												</Td>
											</Tr>
										) : (
											tableItems.map((item, i) => (
												<Tr key={item._id}>
													<Td index={i} position={0} textAlign='center' fontSize='14px'>
														{i + 1}
													</Td>
													<Td index={i} position={1} textAlign='center' fontSize='14px'>
														{item.mainName}
													</Td>
													<Td index={i} position={1} textAlign='center' fontSize='14px'>
														{item.name}
													</Td>
													<Td index={i} position={3} textAlign='center' fontSize='14px'>
														{item.shortName}
													</Td>
													<Td index={i} position={3} textAlign='center' fontSize='14px'>
														{item.amount}
													</Td>
													<Td index={i} position={3} textAlign='center' fontSize='14px'>
														{item.subsidiaryRegisterOrder}
													</Td>
													<Td index={i} position={3} textAlign='center' fontSize='14px'>
														{item.dailyFeeCollectionOrder}
													</Td>
													<Td index={i} position={2} textAlign='center' fontSize='14px'>
														<div className='flex flex-row justify-center gap-2'>
															<Button
																width='50px'
																type='edit'
																onClick={() => {
																	setState({
																		...state,
																		name: item?.name || '',
																		subsidiaryRegisterOrder: item?.subsidiaryRegisterOrder || '',
																		dailyFeeCollectionOrder: item?.dailyFeeCollectionOrder || '',
																		shortName: item?.shortName || '',
																		amount: item.amount || '',
																		mainId: item?.mainId || '',
																		mainIdval: item?.mainName || '',
																		_id: item?._id || '',
																	});
																	setShowForm(true);
																}}>
																Edit
															</Button>
															<Button
																width='50px'
																type='delete'
																onClick={() => {
																	manageSubAccount('Delete', item?._id);
																}}>
																Delete
															</Button>
														</div>
													</Td>
												</Tr>
											))
										)}
									</Tbody>
								</Table>
							</div>
						</Body>
					</div>
				</div>
			)}
		</Main>
	);
};
