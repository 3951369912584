import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DropableComponents = ({ array, setValues, values, retunPreFix }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (items?.length !== array?.length) {
      const data = [];

      array?.forEach((x, i) => {
        data.push(`${retunPreFix}${i + 1}`);
      });

      const initialItems = [...array];

      initialItems?.forEach((a, b) => {
        data?.forEach((dd, ii) => {
          if (values[dd] === a._id) {
            a.sortKey = ii;
          }
        });
      });
      
      initialItems.sort((a, b) => a.sortKey - b.sortKey);
      setItems(initialItems);
    }
  }, [array, items?.length, items, values]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems?.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);
    const newValues = { ...values };
    reorderedItems?.forEach((item, index) => {
      newValues[`${retunPreFix}${index + 1}`] = item?._id;
    });
    setValues(newValues);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items?.map((x, index) => (
              <Draggable key={x?._id} draggableId={x?._id} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps} className='border-blue-900 bg-blue-100 border-double rounded m-1 flex row '>
                    <div className='flex'>
                      <div className='font-extrabold text-white fs-4 w-10 h-10 m-1 p-1  bg-red-500 rounded-sm '> {(index + 1).toString().padStart(2, '0')}</div>
                      <div className='font-extrabold text-green-900 fs-6'>{x?.subjectName?.toUpperCase()}</div>
                    </div>
                    <div className='font-bold text-orange-700 text-end'>{x?.departmentName || 'General Department'}</div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided?.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DropableComponents;
