import React, { useContext, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import classNames from './addBookModal.module.scss';
import { getAxiosTokenInstance } from '../../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../../context/FirebaseContext';
import { List } from 'antd';

const getFieldDropDowns = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFieldDropDowns', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

function SearchableImput({ keyData, collection, stateData, setStateData, titleData }) {
	const { collegeId } = useContext(FirebaseContext);

	const [showList, setShowList] = React.useState(false);

	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowList(false);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	const { data: nameOfAuthorDrop = [] } = useQuery(['nameOfAuthorData', stateData?.[keyData], collection], getFieldDropDowns({ collegeId, search: stateData?.[keyData], collection, key: keyData }));

	const nameOfAuthor = useRef(null);
	useOutsideAlerter(nameOfAuthor);
	const ref = useRef(null);
	return (
		<div>
			<div style={{ margin: 10 }}>
				<div className={classNames.group}>
					<input
						type='text'
						onKeyPress={(e) => {
							e.which === 13 && e.preventDefault();
						}}
						onChange={(e) => setStateData({ ...stateData, [keyData]: e.target.value })}
						onFocus={() => setShowList(true)}
						value={stateData?.[keyData]}
					/>
					<label>{titleData}</label>
				</div>

				<div ref={nameOfAuthor}>
					{showList && stateData?.[keyData] && nameOfAuthorDrop?.filter((data) => data?.toLowerCase().includes(stateData?.[keyData].toLowerCase())).length ? (
						<List
							size='small'
							style={{ margin: 0, padding: 0 }}
							dataSource={nameOfAuthorDrop?.filter((data) => data?.toLowerCase().includes(stateData?.[keyData].toLowerCase())).slice(0, 5)}
							renderItem={(item) => (
								<List.Item>
									<div style={{ cursor: 'pointer', width: '100%', height: 20, color: 'green' }} onClick={() => setStateData({ ...stateData, [keyData]: item })}>
										{item}
									</div>
								</List.Item>
							)}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default SearchableImput;
