
import { Select } from "antd";
import React from "react";
import classNames from "./addBookModal.module.scss";
function CustomDropDown({
  keyData,
  stateData,
  conversionMap,
  setStateData,
  dropValues,
  titleData
}) {
  const { Option } = Select;
  return (
    <div>
      <div style={{ margin: 10 }}>
        <div className={classNames.group} >
          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            value={stateData?.[keyData]}
            onChange={(val) => { setStateData({ ...stateData, [keyData]: val, page: 1 }) }} >
            {dropValues?.map((item, i) => (
              <Option key={i} value={item}>
                {conversionMap ? conversionMap[item] : item}
              </Option>
            ))}
          </Select>
          <label>{titleData}</label>
        </div>
      </div>
    </div >
  )
}
export default CustomDropDown;

