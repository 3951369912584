import React, { useContext, useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAttendanceData, getFeeDueList, getUserListNew } from '../../NecttosComp/APICaller/ApiServices';
import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import Button from '../../NecttosComp/Button/Button';
import Scrollbars from 'react-custom-scrollbars';

export const Attendance = ({ onClose }) => {
	const allClasses = useAllClasses({ needAll: true });
	const [headerData, setHearData] = useState([]);
	const [showData, setShowData] = useState([]);

	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const { data } = useQuery(['attendanceData', state.classId], getFeeDueList(collegeId, state?.startDate, state?.endDate, state?.classId));
	const { data: studentsList = [], refetch } = useQuery([`userListNew${state?.classId}`, { type: 'student', classId: state?.classId, collegeId }], getUserListNew);
	const { data: attendanceData = [], isLoading: loading } = useQuery(['attendanceData666', state.userId, state?.classId, state?.startDate, state?.endDate], getAttendanceData(collegeId, state?.startDate, state?.endDate, state?.userId, state?.classId));

	useEffect(() => {
		const updatedAttendanceData = attendanceData.map((attendanceRecord) => {
			const updatedAttendanceUsers = headerData.map((headerItem) => {
				const userAttendance = attendanceRecord?.attendaceUsers?.find((user) => user?.date === headerItem?.date);

				return userAttendance || {};
			});

			return { ...attendanceRecord, attendaceUsers: updatedAttendanceUsers };
		});

		setShowData(updatedAttendanceData);
	}, [headerData]);
	data?.data?.forEach((element) => {
		element.groups.forEach((item) => {
			element[item.feeType] = item.totalCurrentDue === 0 ? '' : item.totalCurrentDue;
		});
	});

	useEffect(() => {
		if (attendanceData.length === 0) {
			setHearData([]);
			return;
		}
		const itemWithMaxAttendance = attendanceData.reduce((maxItem, currentItem) => (currentItem.attendaceUsers.length > maxItem.attendaceUsers.length ? currentItem : maxItem));

		setHearData(itemWithMaxAttendance.attendaceUsers);
	}, [attendanceData]);

	const { Option } = Select;

	return (
		<Main title='Attendance Register' height='80vh' width='90vw'>
			<Header>
				<div className='flex items-center'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
					<Input fieldName='Start Date' returnKey='startDate' type='date' state={state} setState={setState} />
					<Input fieldName='End Date' returnKey='endDate' type='date' state={state} setState={setState} />
					<Formik enableReinitialize>
						<div className='flex flex-col'>
							<label>Select Class</label>
							<Select
								showSearch
								style={{ overflow: 'hidden', width: '200px' }}
								onChange={(val) => {
									setState({ ...state, classId: (allClasses?.data?.find((x, i) => x.className + i === val)).classId, className: (allClasses?.data?.find((x, i) => x.className + i === val)).className });
								}}>
								{allClasses?.data?.map((item, i) => (
									<Option key={i} value={item.className + i}>
										{item.className}
									</Option>
								))}
							</Select>
						</div>
					</Formik>
					<Formik enableReinitialize>
						<div className='flex flex-col '>
							<label>Select Student</label>
							<Select
								showSearch
								style={{ overflow: 'hidden', width: '200px' }}
								onChange={(val) => {
									setState({ ...state, userId: (studentsList?.list?.find((x, i) => x.name + i === val))._id, userName: (studentsList?.list?.find((x, i) => x.name + i === val)).name });
								}}>
								{studentsList?.list?.map((item, i) => (
									<Option key={i} value={item.name + i}>
										{item.rollNo + ' ' + item.name}
									</Option>
								))}
							</Select>
						</div>
					</Formik>
					<Button
						type='fetch'
						onClick={() => {
							refetch();
						}}>
						Load
					</Button>
					<Button tableRef={componentRef.current} type='print'>
						Print
					</Button>
					<Button pdfId='emp' type='excel'>
						Excell
					</Button>
				</div>
			</Header>
			<Body>
				<Scrollbars style={{ margin: '2%' }} ref={componentRef}>
					<div ref={componentRef} className='printable' style={{ flexDirection: 'row', display: 'flex' }}>
						<img width='6%' height='6%' src={selectedCollege?.logo} />

						<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
							<span style={{ fontSize: '20px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
							<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
							<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>
								{' '}
								{state?.userName} [{state?.className}] Attendance Report
							</p>
						</div>
					</div>
					<div style={{ flexDirection: 'row', display: 'flex' }}>
						<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />

						<div style={{ width: '49%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 20, margin: 0, backgroundColor: 'black', marginBottom: 3 }}>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Sn</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '30%' }}>Date</h5>
								<h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '30%' }}>Day</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>1</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>2</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>3</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>4</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>5</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
							</div>

							{attendanceData[0]?.attendaceUsers?.slice(0, 45)?.map((item, i) => (
								<>
									<div style={{ width: '100%', display: 'flex', height: '30px', flexDirection: 'row' }}>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', textAlign: 'center', fontSize: 14, width: '10%' }}>{i + 1}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '30%' }}>{item?.date}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '30%' }}>{item.day}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status1}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status2}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status3}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status4}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status5}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
									</div>
									<div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'grey' }}></div>
								</>
							))}
						</div>
						<div style={{ width: '49%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 20, margin: 0, backgroundColor: 'black', marginBottom: 3 }}>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Sn</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '30%' }}>Date</h5>
								<h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '30%' }}>Day</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>1</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>2</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>3</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>4</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>5</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
							</div>

							{attendanceData[0]?.attendaceUsers?.slice(46, 90)?.map((item, i) => (
								<>
									<div style={{ width: '100%', display: 'flex', height: '30px', flexDirection: 'row' }}>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', textAlign: 'center', fontSize: 14, width: '10%' }}>{i + 46}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '30%' }}>{item.date}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '30%' }}>{item.day}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status1}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status2}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status3}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status4}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.status5}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
									</div>
									<div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'grey' }}></div>
								</>
							))}
						</div>
					</div>
				</Scrollbars>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
					<img width='6%' height='6%' src={selectedCollege?.logo} />
					<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
						<span style={{ fontSize: '20px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
						<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
						<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}> {state?.className}'s Attendance Register</p>
					</div>
				</div>
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
					<h5 style={{ marginTop: 2, color: 'black', alignSelf: 'center', fontSize: 12, width: '180px' }}>Name</h5>

					{headerData?.slice(0, 10)?.map((item, i) => (
						<>
							{console.log('dddd===>', { item })}
							<div>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
								<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', fontSize: 14, width: '100%' }}>{item.date}</h5>
								<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', fontSize: 14, width: '100%' }}>{item.day}</h5>
								<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 1, backgroundColor: 'grey' }}></div>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 10, margin: 0, marginBottom: 3 }}>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 0, marginRight: 1, backgroundColor: 'grey' }}></div>
									<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 8, width: '10px' }}>P1</h5>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
									<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 8, width: '10px' }}>P2</h5>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
									<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 8, width: '10px' }}>P3</h5>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
									<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 8, width: '10px' }}>P4</h5>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
									<h5 style={{ marginTop: 2, color: 'black', textAlign: 'center', alignSelf: 'center', fontSize: 8, width: '10px' }}>P5</h5>
									<div style={{ width: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 0, backgroundColor: 'grey' }}></div>
								</div>
							</div>
						</>
					))}
				</div>

				<div style={{ flexDirection: 'column', display: 'flex' }}>
					<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
					{attendanceData?.map((users, i) => (
						<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
							<h5 style={{ marginTop: 0, color: 'black', alignSelf: 'center', height: attendanceData.length > 65 ? '14px' : attendanceData.length > 60 ? '16px' : attendanceData.length > 55 ? '18px' : attendanceData.length > 50 ? '20px' : attendanceData.length > 45 ? '23px' : attendanceData.length > 40 ? '32px' : '35px', fontSize: 12, width: '180px' }}>
								{users.rollNo} {users.name}
							</h5>
							{users?.attendaceUsers?.slice(0, 10)?.map((item, i) => (
								<div>
									<div style={{ height: '100%', display: 'flex', height: 1, backgroundColor: 'grey' }}></div>
									<div style={{ height: '100%', display: 'flex', height: attendanceData.length > 65 ? '16px' : attendanceData.length > 60 ? '18px' : attendanceData.length > 55 ? '20px' : attendanceData.length > 50 ? '22px' : attendanceData.length > 45 ? '25px' : attendanceData.length > 40 ? '32px' : '35px', flexDirection: 'row' }}>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 0, marginRight: 1, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, color: item.status1 === 'P' ? 'green' : item.status1 === 'L' ? '#806f00' : 'red', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10px' }}>{item.status1}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, color: item.status2 === 'P' ? 'green' : item.status2 === 'L' ? '#806f00' : 'red', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10px' }}>{item.status2}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, color: item.status3 === 'P' ? 'green' : item.status3 === 'L' ? '#806f00' : 'red', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10px' }}>{item.status3}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, color: item.status4 === 'P' ? 'green' : item.status4 === 'L' ? '#806f00' : 'red', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10px' }}>{item.status4}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 4, marginRight: 4, backgroundColor: 'grey' }}></div>
										<h5 style={{ marginTop: 7, color: item.status5 === 'P' ? 'green' : item.status5 === 'L' ? '#806f00' : 'red', textAlign: 'center', alignSelf: 'center', fontSize: 14, width: '10px' }}>{item.status5}</h5>
										<div style={{ height: '100%', display: 'flex', width: 1, marginLeft: 1, marginRight: 0, backgroundColor: 'grey' }}></div>
									</div>
									<div style={{ height: '100%', display: 'flex', height: 1, backgroundColor: 'grey' }}></div>
								</div>
							))}
						</div>
					))}
				</div>
			</Body>
		</Main>
	);
};
