import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select, Table } from 'antd';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';

import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { getFeeDueList } from '../../../NecttosComp/APICaller/ApiServices';
import { useAllClasses } from '../../../NecttosComp/APICaller/queryHooks';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

export const DuesReport = ({ onClose }) => {
	const allClasses = useAllClasses({ needAll: true, classOnly: true });

	const [loading, setLoading] = useState(false);

	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	let skip = 0;
	let limit = 70;
	let search = '';
	const noNeedTotals = true;

	const { data, status, error, isFetching, refetch } = useQuery(['feeDueList', state.classId], getFeeDueList(collegeId, skip, limit, search, noNeedTotals, state?.classId), { keepPreviousData: true });

	console.log(data);

	data?.data?.forEach((element) => {
		element.groups.forEach((item) => {
			element[item.feeType] = item.totalCurrentDue === 0 ? '' : item.totalCurrentDue;
		});
	});

	let issuedData = data?.data?.sort((a, b) => a.userName?.localeCompare(b.userName)) || [];

	const columns = [
		{
			title: 'Admn No',
			dataIndex: 'admnNo',
			key: 'admnNo',
			columnWidth: '15%',
		},
		{
			title: 'Name',
			dataIndex: 'userName',
			key: 'userName',
			columnWidth: '15%',
		},
		{
			title: 'Tuition Fee',
			dataIndex: 'TuitionFee',
			key: 'TuitionFee',
			columnWidth: '15%',
		},
		{
			title: 'Exam Fee',
			dataIndex: 'ExamFee',
			key: 'ExamFee',
			columnWidth: '5%',
		},
		{
			title: 'Others Fee',
			dataIndex: 'Others',
			key: 'Others',
			columnWidth: '5%',
		},
	];

	const { Option } = Select;

	let dropDown = [];

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer2}>
					<div className={classNames.headerPanel}>
						<div className={classNames.paginationController}></div>
					</div>
					{loading ? (
						<LoadingAnimation customHeight={50} customWidth={50} />
					) : (
						<Scrollbars style={{ height: '90%' }}>
							<div ref={componentRef} className='printable'>
								<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
								<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
										<img width='6%' height='6%' src={selectedCollege?.logo} />
										<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
											<span style={{ fontSize: '20px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
											<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
											<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>
												{' '}
												{state?.className}'s Fee Due Report as on {moment(new Date()).format('DD MMM YYYY hh:mm A')}
											</p>
										</div>
									</div>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 20, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 3 }}>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Sn</h5>
										<h5 style={{ marginTop: 2, color: 'white', alignSelf: 'center', fontSize: 14, width: '10%' }}>Admn No</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'left', fontSize: 14, width: '32%' }}>Name</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'left', fontSize: 14, width: '16%' }}>Mobile</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Tuition Fee</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Exam Fee</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Other Fee</h5>
										<h5 style={{ marginTop: 2, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Last Pay Date</h5>
									</div>
									{issuedData?.map((item, i) => (
										<>
											<div style={{ width: '100%', display: 'flex', height: data?.data.length > 60 ? '20px' : data?.data.length > 50 ? '25px' : '30px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'renew' ? '#c0faeb' : 'white' }}>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{i + 1}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.admnNo}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'left', fontSize: 14, width: '32%' }}>{item.userName}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '16%' }}>{item.sMobile}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.TuitionFee}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.ExamFee}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.Others}</h5>
												<h5 style={{ marginTop: 7, marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '10%' }}></h5>
											</div>
											<div
												style={{
													width: '100%',
													display: 'flex',
													height: 1,
													backgroundColor: 'black',
												}}></div>
										</>
									))}
									<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
								</div>
							</div>
						</Scrollbars>
					)}

					<div>
						<Scrollbars>
							<table className={classNames.userTable} id='emp'>
								<Table dataSource={issuedData} pagination={false} columns={columns} />
							</table>
						</Scrollbars>
					</div>
				</div>
			</div>

			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Select
							showSearch
							className={classNames.formFullWidth2}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, classId: (allClasses?.data?.find((x, i) => x.className + i === val)).classId, className: (allClasses?.data?.find((x, i) => x.className + i === val)).className });
							}}>
							{allClasses?.data?.map((item, i) => (
								<Option key={i} value={item.className + i}>
									{item.className}
								</Option>
							))}
						</Select>
						<label>{state?.filterType}</label>
					</div>
				</Formik>
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						refetch();
					}}>
					Load
				</Button>
				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						refetch();
					}}>
					<ReactHtmlTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='collectionReport' sheet='tablexls' buttonText='EXCEL' />
				</Button>
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
