import React, { useCallback, useContext, useState, StyleSheet, useEffect } from 'react';
import message from 'antd/lib/message';
import classNames from './addBookModal.module.scss';


import { Body, Footer, Header, Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import Input from '../../../../NecttosComp/Input/Input';

function SettupQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {
	const { collegeId } = useContext(FirebaseContext);
	const [option, setOption] = useState(['']);
	const [state, setState] = useState({
		mainQ: 1,
	});
	const loadOldData = useCallback(async () => {
		let newData = {};
		newData.mainQ = parseInt(itemData?.questionnaire?.length);
		itemData?.questionnaire?.forEach((item, c) => {
			newData[c + '~question'] = item.question;
			newData[c + 'subQ'] = parseInt(item?.subQuestions?.length);
			item?.subQuestions?.forEach((data, i) => {
				newData[c + '' + (i + '') + '~subQuestion'] = data.subQuestion;
				newData[c + '' + (i + '') + '~type'] = data.type;
				newData[c + '' + (i + '') + '~nature'] = data.nature;
				if (data.type === 'Options') {
					console.log('------>', data.option);
					setOption([...data.option]);
				}
				if (data.type === 'Likert Scale') {
					newData[c + '' + (i + '') + '~likert1'] = data.likert1;
					newData[c + '' + (i + '') + '~likert5'] = data.likert5;
				}
			});
		});
		setState({ ...newData });
	}, [state]);
	let mainCount = Array(state?.mainQ || []).fill(1);
	mainCount?.forEach((item, i) => {
		if (state[i + 'subQ'] > 0) {
		} else {
			setState({
				...state,
				[i + 'subQ']: 1,
			});
		}
	});
	const postQuestionnaire = useCallback(async () => {
		let finalData = [];
		mainCount?.forEach((item, c) => {
			let subQuestions = [];
			Array(state[c + 'subQ'])
				.fill(1)
				?.forEach((data, i) => {
					let subQuestion = state[c + '' + (i + '') + '~subQuestion'];
					let type = state[c + '' + (i + '') + '~type'];
					let nature = state[c + '' + (i + '') + '~nature'];
					let likert1 = state[c + '' + (i + '') + '~likert1'];
					let likert5 = state[c + '' + (i + '') + '~likert5'];
					subQuestions.push({
						subQuestion,
						type,
						nature,
						option,
					});
					console.log({ subQuestions });
				});
			finalData.push({
				question: state[c + '~question'],
				subQuestions,
			});
		});

		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/iqac/postQuestionnair', {
				collegeId,
				_id: questionnaireId,
				questionnaire: finalData,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				onSuccess();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			refectOrgin();
			onSuccess();
			setState({});
		}
	}, [state]);

	useEffect(() => {
		loadOldData();
	}, []);

	return (
		<Main title={itemData.title} height='90vh' width='90vw'>
			<Header className={classNames.head}>
				<Button type='close' onClick={onCloseModal}>
					Cancel
				</Button>
			</Header>
			<Body>
				<div className='h-[75vh] overflow-auto'>
					{mainCount.map((x, c) => (
						<div className={classNames.titlesSub} style={{ width: '100%' }}>
							<Input width='100%' fieldName='Title of Questionnaire' type='text' returnKey={c + '~question'} state={state} setState={setState} />
							{(Array(state[c + 'subQ']) || 1).fill(1).map((x, i) => (
								<div style={{ flexDirection: 'column', display: 'flex' }}>
									<div className='flex'>
										<div style={{ width: 700 }}>
											<Input width='100%' fieldName='Question' type='text' returnKey={c + '' + (i + '') + '~subQuestion'} state={state} setState={setState} />
										</div>
										<div style={{ width: 200, marginLeft: '5px' }}>
											<Input width='100%' fieldName='Catogory' options={['Paragraph', 'Date', 'Period', 'Text', 'Number', 'PDF', 'Image', 'Options', 'Multiple Answer']} type='drop' returnKey={c + '' + (i + '') + '~type'} state={state} setState={setState} />
										</div>
										<div style={{ width: 200, marginLeft: '5px' }}>
											<Input width='100%' fieldName='Nature' options={['Mandatory', 'Optional']} type='drop' returnKey={c + '' + (i + '') + '~nature'} state={state} setState={setState} />
										</div>
										{state[c + '' + (i + '') + '~type'] === 'Likert Scale' && (
											<>
												<div style={{ width: 200 }}>
													<Input width='100%' fieldName='Value 1' type='text' returnKey={c + '' + (i + '') + '~likert1'} state={state} setState={setState} />
												</div>

												<div style={{ width: 200 }}>
													<Input width='100%' fieldName='Value 5' type='text' returnKey={c + '' + (i + '') + '~likert5'} state={state} setState={setState} />
												</div>
											</>
										)}
									</div>
									{state[c + '' + (i + '') + '~type'] === 'Options' && (
										<>
											{option?.map((x, c) => (
												<div className='flex justify-start items-center'>
													<Input width='60%' fieldName={'Option' + (c + 1)} type='text' returnKey={c} state={option} setState={setOption} />

													{c === option.length - 1 ? (
														<>
															<Button
																type='update'
																onClick={() => {
																	setOption([...option, '']);
																	console.log(option);
																}}>
																+
															</Button>
															{option.length > 1 ? (
																<Button
																	type='update'
																	onClick={() => {
																		const temp = option;
																		temp.pop();
																		setOption([...temp]);
																	}}>
																	-
																</Button>
															) : null}
														</>
													) : null}
												</div>
											))}
										</>
									)}
								</div>
							))}
							<div className='flex'>
								<Button
									type='search'
									onClick={() =>
										setState({
											...state,
											[c + 'subQ']: parseInt(state[c + 'subQ']) - 1,
										})
									}>
									-
								</Button>
								<Button
									type='search'
									onClick={() =>
										setState({
											...state,
											[c + 'subQ']: parseInt(state[c + 'subQ']) + 1,
										})
									}>
									+
								</Button>
							</div>
						</div>
					))}
					<div style={{ flexDirection: 'row', display: 'flex' }}>
						<Button type='download' onClick={() => setState({ ...state, mainQ: parseInt(state.mainQ) - 1 })}>
							-
						</Button>
						<Button type='download' onClick={() => setState({ ...state, mainQ: parseInt(state.mainQ) + 1 })}>
							+
						</Button>
					</div>
					<Button type='save' onClick={() => postQuestionnaire()}>
						Submit
					</Button>
				</div>
			</Body>
			<Footer />
		</Main>
	);
}

export default SettupQuestionnaire;

const styles = {
	buttonStyle: {
		backgroundColor: 'green',
		color: 'white',
		fontSize: 40,
		borderRadius: 12,
		height: 50,
	},
};
