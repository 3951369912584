import React, { useEffect, useState } from "react";
import classNames from "./addUserPage.module.scss";

import { Button } from "antd";
import AddStudentTableStudents from "./AddStudentTableStudents";
import AddUserFormStudents from "./AddUserFormStudents";


export const CreateAccount = ({ onClose }) => {
  const [refreshStatus, setRefreshStatus] = useState(false);


  const [userType, setUserType] = useState("student");
  const [classId, setClassId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [admisionNo, setAdmisionNo] = useState("");
  const [id, setId] = useState("");


  const selectUser = (data) => {
    setUserType("student")
    setClassId(data.classId)
    setName(data.name)
    setPhoneNumber(data.phoneNumber?.replace('+91', ''))
    setId(data.id)
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classNames.container}>
        <div className={classNames.modal} >
          <AddUserFormStudents
            classId={classId}
            setClassId={(e) => setClassId(e)}
            departmentId={departmentId}
            userType={userType}
            setUserType={(e) => setUserType(e)}
            setDepartmentId={(e) => setDepartmentId(e)}
            name={name}
            setName={(e) => setName(e)}
            phoneNumber={phoneNumber}
            setPhoneNumber={(e) => setPhoneNumber(e)}
            admisionNo={admisionNo}
            setAdmisionNo={(e) => setAdmisionNo(e)}
            setId={(e) => setId(e)}
            id={id}
            toggleRefresh={() => setRefreshStatus(!refreshStatus)}
          />
          <AddStudentTableStudents
            classId={classId}
            selectUser={selectUser}
            toggleRefresh={() => setRefreshStatus(!refreshStatus)}
            refreshStatus={refreshStatus}
          />

        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
}

