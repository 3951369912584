import React, { useContext, useEffect, useState } from 'react';
import classNames from './exportModal.module.scss';
import './TC.css';
import { Button, Table } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import EditTc from './EditTc';
import InstantTc from './InstantTc';
import ApplyTc from './ApplyTc';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';
import { useAllClasses } from '../../../NecttosComp/APICaller/queryHooks';
import { debounce } from 'lodash';

export const exportPdfCollectionReport = async ({ postData }) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post(`/college/reportGeneration/generateTcCc`, postData, {
			responseType: 'blob',
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (e) {
		throw new Error();
	}
};

const getAllMembers = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/requestAprovals/getTcCcRequest', {
			params: {
				...data,
			},
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

let days;

export const TransferSertificateCC = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const [Edit, setEdit] = useState({ value: [] });
	const [instTc, setInstTc] = useState(false);
	const [apply, setapply] = useState(false);
	const allClasses = useAllClasses({ needAll: true });

	const [advancedSearchQuery, setAdvancedSearchQuery] = useState('');
	const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
	const [filteredMembers, setFilteredMembers] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');

	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = React.useState({
		startDate: '',
		endDate: '',
		lastTcNumber: '',
	});

	const { data = [], refetch } = useQuery(['allTCCC', state?.startDate, state?.classId, state?.endDate], getAllMembers({ collegeId, startDate: state?.startDate, classId: state?.classId, endDate: state?.endDate, searchQuery: advancedSearchQuery, setLoading }));

	useEffect(() => {
		setState({ ...state, lastTcNumber: data?.lastTcNumber });
	}, [data]);

	const aproveTcCc = async (data) => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		let resp;
		resp = await instance.post('/college/requestAprovals/addToMessageTcCcRequests', {
			collegeId,
			messageData: '',
			checkRole: '',
			action: 'aprove',
			classId: '',
			departmentId: '',
			userId: data?.studentsDetails?.uid,
		});
		if (resp.data.statusCode !== 200) {
			setLoading(false);

			refetch();
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);

			refetch();
		}
		setLoading(false);
	};
	const deleteTC = async (data) => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		let resp;
		resp = await instance.post('/college/requestAprovals/addToMessageTcCcRequests', {
			collegeId,
			messageData: '',
			checkRole: '',
			action: 'delete',
			classId: '',
			departmentId: '',
			userId: data?.studentsDetails?.uid,
		});
		if (resp.data.statusCode !== 200) {
			setLoading(false);

			refetch();
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);

			refetch();
		}
		setLoading(false);
	};
	const updateTCNumber = async (data) => {
		const instance = await getAxiosTokenInstance();
		let resp;
		resp = await instance.post('/college/requestAprovals/updateTcNumber', {
			collegeId,
			lastTcNumber: state?.lastTcNumber,
		});
		if (resp.data.statusCode !== 200) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			refetch();
		}
	};

	const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

	const onTcCc = async (data) => {
		setLoading(false);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				startsNumber: data?.tcNumber,
				userId: data?.studentsDetails?.uid,
				applicationDate: data?.appliedOn,
				leavingDate: data?.dateOfLeaving,
				reasonForLeaving: data?.reasonForLeaving,
				exam: data?.exam,
				joinedSem: data?.joinedSem,
				leaving: data?.leaving,
				signatureDate: data?.aprovedOn,
			};
			link.download = data?.studentsDetails?.name.split('.').join(' ');
			const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData });
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
		} catch (e) {}
	};

	const columns = [
		{
			title: 'S.N.',
			dataIndex: '',
			render: (a, data, i) => <span>{i + 1}</span>,
		},
		{
			title: 'TC Number',
			render: (a, data, i) => <div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 30, color: data?.aprovedOn ? 'green' : 'red', fontWeight: 900 }}>{data?.tcNumber}</div>,
		},
		{
			title: 'AdmissionNo',
			dataIndex: '',
			render: (a, data, i) => <div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 30, color: data?.aprovedOn ? 'green' : 'red', fontWeight: 900 }}>{data?.studentsDetails?.admisionNo}</div>,
		},
		{
			title: '',
			dataIndex: '',
			render: (_, data, i) =>
				loading ? (
					<span>Loading...</span>
				) : (
					<button
						className={data?.aprovedOn ? 'editBtn' : 'aproveBtn'}
						onClick={() => {
							data?.aprovedOn ? onTcCc(data) : aproveTcCc(data);
						}}>
						{' '}
						{data?.aprovedOn ? 'Download' : 'Aprove'}
					</button>
				),
		},
		{
			title: '',
			dataIndex: '',
			render: (_, data, i) => (
				<button
					className={data?.aprovedOn ? 'editBtn' : 'aproveBtn'}
					onClick={async (e) => {
						data?.aprovedOn ? setEdit({ ...Edit, value: data, isEdit: true }) : deleteTC(data);
					}}>
					{' '}
					{data?.aprovedOn ? 'Edit' : 'Delete'}
				</button>
			),
		},
		{
			title: 'Name',
			render: (a, data, i) => <div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 20, color: data?.aprovedOn ? 'green' : 'red', fontWeight: 900 }}>{data?.studentsDetails?.name?.toUpperCase()}</div>,
		},
		{
			title: 'Class Name',
			dataIndex: 'className',
			key: 'className',
			columnWidth: '15%',
		},
		{
			title: 'Reason ',
			dataIndex: 'reasonForLeaving',
			key: 'reasonForLeaving',
			columnWidth: '15%',
		},
		{
			title: 'Last Sem ',
			dataIndex: 'exam',
			key: 'exam',
			columnWidth: '15%',
		},
		{
			title: 'Last Exam ',
			dataIndex: 'exam',
			key: 'exam',
			columnWidth: '15%',
		},
		{
			title: 'Date of Birth',
			dataIndex: '',
			render: (a, data, i) => <span>{moment(new Date(data?.studentsDetails?.dob)).format('DD MMM YYYY')}</span>,
		},
		{
			title: 'Date of Admn',
			dataIndex: '',
			render: (a, data, i) => <span>{moment(new Date(data?.studentsDetails?.dateOfAdmission)).format('DD MMM YYYY')}</span>,
		},
		{
			title: 'Aproved On',
			dataIndex: 'aprovedOn',
			render: (a, data, i) => <span>{data?.aprovedOn ? moment(new Date(data?.aprovedOn)).format('DD MMM YYYY') : ''}</span>,
		},
		{
			title: 'Leaving Date',
			dataIndex: 'dateOfLeaving',
			render: (a, data, i) => <span>{data?.aprovedOn ? moment(new Date(data?.dateOfLeaving)).format('DD MMM YYYY') : ''}</span>,
		},
	];

	// Debounced refetch for the advanced search
	const debouncedRefetch = debounce(() => {
		refetch();
	}, 1000);

	useEffect(() => {
		if (isAdvancedSearch) {
			debouncedRefetch();
		}
	}, [advancedSearchQuery]);

	// Local search filter
	useEffect(() => {
		if (!isAdvancedSearch) {
			const result = data?.list?.filter((member) => member?.studentsDetails?.name?.includes(searchQuery) || member?.studentsDetails?.admisionNo?.includes(searchQuery));
			if (searchQuery === '') {
				setFilteredMembers(data?.list);
			} else {
				setFilteredMembers(result);
			}
		}
	}, [searchQuery, data, isAdvancedSearch]);

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				{instTc ? (
					<div>
						<div className='cover'></div>{' '}
						<div className='instTc'>
							<InstantTc setLoading={setLoading} refetch={refetch} onClose={() => setInstTc(false)} />
							<button className='closes' onClick={() => setInstTc(!instTc)}>
								X
							</button>
						</div>
					</div>
				) : null}
				{Edit?.isEdit ? (
					<div>
						<div className='cover'></div>{' '}
						<div className='instTc'>
							<EditTc setLoading={setLoading} refetch={refetch} onClose={() => setEdit({ ...Edit, value: [], isEdit: false })} data={Edit.value} />
							<button className='closes' onClick={() => setEdit({ ...Edit, value: [], isEdit: false })}>
								X
							</button>
						</div>
					</div>
				) : null}
				{apply ? (
					<div>
						<div className='cover'></div>{' '}
						<div className='instTc'>
							<ApplyTc setLoading={setLoading} refetch={refetch} onClose={() => setapply(false)} />
							<button className='closes' onClick={() => setapply(!apply)}>
								X
							</button>
						</div>
					</div>
				) : null}
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<>
						<div className={classNames.listContainer2}>
							<Scrollbars>
								<table className={classNames.userTable} id='emp'>
									<Table columns={columns} dataSource={filteredMembers || []} pagination={false} />
								</table>
							</Scrollbars>
						</div>
					</>
				)}
			</div>

			<div className={classNames.absoluteDiv}>
				<input type='text' value={state?.lastTcNumber} style={{ fontSize: 20, fontWeight: 900, width: 150, borderRadius: 20, height: 40, margin: 5, padding: 12 }} placeholder='Next TCN' onChange={(e) => setState({ ...state, lastTcNumber: e.target.value })} />
				<button className='tcForm' onClick={() => updateTCNumber()}>
					Update
				</button>

				<input
					type='text'
					style={{ fontSize: 20, fontWeight: 900, width: 200, borderRadius: 20, height: 40, margin: 5, padding: 12 }}
					placeholder='Search...'
					value={isAdvancedSearch ? advancedSearchQuery : searchQuery}
					onChange={(e) => {
						if (isAdvancedSearch) {
							setAdvancedSearchQuery(e.target.value);
						} else {
							setSearchQuery(e.target.value);
						}
					}}
				/>

				<input type='checkbox' style={{ width: 30, height: 40, margin: 5, padding: 12 }} checked={isAdvancedSearch} onChange={() => setIsAdvancedSearch(!isAdvancedSearch)} />

				<button className='tcForm' onClick={() => setInstTc(!instTc)}>
					Old TC Form
				</button>
				<button className='tcForm' onClick={() => setapply(!apply)}>
					New TC Form
				</button>

				<view style={{ width: 250 }}>
					<CustomDropDownKey keyData={'classId'} keyObject={'className'} keyReturn={'_id'} titleData={'Select Class'} dropValues={allClasses?.data || []} stateData={state} setStateData={setState} />
				</view>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Field
							type='date'
							name='startDate'
							id='startDate'
							value={state.startDate}
							onChange={(e) => {
								setState({ ...state, startDate: e.target.value });
							}}
						/>
						<label>Start Date</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Field
							type='date'
							name='endDate'
							id='endDate'
							value={state.endDate}
							onChange={(e) => {
								setState({ ...state, endDate: e.target.value });
							}}
						/>
						<label>End Date</label>
					</div>
				</Formik>

				<Button
					className={classNames.reloadButton}
					onClick={() => {
						refetch();
					}}>
					<ReactHTMLTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='collectionReport' sheet='tablexls' buttonText='EXCEL' />e
				</Button>
			</div>

			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
