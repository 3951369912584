import React, { useContext, useState } from "react";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import toast, { Toaster } from "react-hot-toast";

function DeleteUser({ onClose }: { onClose: () => void }) {
  const { collegeId } = useContext(FirebaseContext);
  const [users, setUsers] = useState<any[]>([{ name: "faris" }]);
  const [selectedUser, setSelectedUser] = useState<any>({});

  const Confirmation = (selectedUser: any) => {
    toast(
      (t) => (
        <div className="w-full p-4 text-gray-500 bg-white rounded-lg  dark:bg-gray-800 dark:text-gray-400">
          <div className="flex">
            <div className="ms-3 text-sm font-normal">
              <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Confirmation</span>
              <div className="mb-2 text-sm font-normal">
                Warning: You are about to delete <span className="font-bold text-black">{selectedUser?.name}</span>. This action is irreversible and will permanently remove all data associated with this user, including their personal information, account settings, and any related records. Please ensure that this is the intended action before proceeding.
              </div>
              <label className="mb-2 flex text-sm items-center justify-start font-normal">
                <input type="checkbox" id="checkboxInput" /> By checking this box you confirm that the above points are red
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <button onClick={() => toast.dismiss(t.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                    Close
                  </button>
                </div>
                <div>
                  <button
                    onClick={async() => {
                      const checkbox: any = document.getElementById("checkboxInput");
                      if (checkbox?.checked) {
                        const instance = await getAxiosTokenInstance()
                        instance.delete("/college/users/deleteUserConfirm/"+selectedUser._id,{params:{collegeId}}).then(()=>{
                            toast.dismiss(t.id)
                        }).catch(()=>{
                            toast.dismiss(t.id)
                        })
                      }
                    }}
                    className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-red-400 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 "
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button type="button" onClick={() => toast.dismiss(t.id)} className="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-red-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-interactive" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
      ),
      { style: { width: "80vw" } }
    );
  };

  const handleDelete = async (selectedUser: any) => {
    toast(
      (t) => (
        <div className="w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg  dark:bg-gray-800 dark:text-gray-400">
          <div className="flex">
            <div className="ms-3 text-sm font-normal">
              <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Confirmation</span>
              <div className="mb-2 text-sm font-normal">
                Your are deleting <span className="text-black font-bold">{selectedUser?.name}</span>.Are you sure you want to delete
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <button onClick={() => toast.dismiss(t.id)} className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                    Close
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      toast.dismiss(t.id);
                      Confirmation(selectedUser);
                    }}
                    className="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center text-red-400 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 "
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <button type="button" onClick={() => toast.dismiss(t.id)} className="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-red-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-interactive" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
        </div>
      ),
      { duration: 99999 }
    );
  };
  const getDeletingUsers = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .get("/college/users/getDeletingUser", { params: { collegeId } })
      .then((res) => {
        console.log(res.data?.deletingUsers);
        setUsers(res.data?.deletingUsers);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  return (
    <>
      <Toaster />
      <Main width="80vw" height="80vh">
        <Header>
          <div></div>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </Header>
        <Body>
          <Table width="100%" innerWidth={["5%", "20%", "20%", "10%", "10%", "10%", "10%", "15%"]}>
            <Thead>
              <Tr>
                <Th position={0}>SN</Th>
                <Th position={1}>Image</Th>
                <Th position={2}>Name</Th>
                <Th position={3}>Adm.No.</Th>
                <Th position={4}>Date Of Birth</Th>
                <Th position={5}>Date Of Join</Th>
                <Th position={6}>Class Name</Th>
                <Th position={7}>Action</Th>
              </Tr>
            </Thead>
            <Tbody height="auto">
              {users?.map((x, i) => (
                <Tr>
                  <Td index={i} position={0}>
                    {i + 1}
                  </Td>
                  <Td index={i} position={1}>
                    <img src={x?.photoURL} alt="User Image" width={"80%"} />
                  </Td>
                  <Td index={i} position={2}>
                    {x?.name}
                  </Td>
                  <Td index={i} position={3}>
                    {x?.admisionNo}
                  </Td>
                  <Td index={i} position={4}>
                    {x?.dob}
                  </Td>
                  <Td index={i} position={5}>
                    {x?.dateOfJoin}
                  </Td>
                  <Td index={i} position={6}>
                    {x?.className}
                  </Td>
                  <Td index={i} position={7}>
                    <div className="flex">
                      <Button
                        type="delete"
                        onClick={() => {
                          setSelectedUser(x);
                          handleDelete(x);
                        }}
                      >
                        Delete
                      </Button>
                      <Button type="update">Undo</Button>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Body>
      </Main>
    </>
  );
}

export default DeleteUser;
