import React, { useContext, useRef, useState, useMemo } from 'react';
import { Body, Footer, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getNewClasses } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getClassName } from '../Form/Component';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;
	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

const getAllMinorsSelected =
	({ collegeId, classId, seatConformed, setLoading }: any) =>
	async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/fyugp/allotmentReportClassWise', {
				params: { collegeId, classId, seatConformed },
			});
			setLoading(false);
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				return resp?.data?.list;
			}
		} catch (error) {
			setLoading(false);
			throw new Error('API Error');
		}
	};

interface InvoiceManagementProps {
	onClose: () => void;
}

function ConfirmedClassWiseAllotment({ onClose }: InvoiceManagementProps) {
	const { collegeId } = useContext(FirebaseContext);
	const [isLoading, setLoading] = useState<boolean>(false);
	const ref = useRef<any>(null);
	const [showAdvance, setAdvance] = useState<boolean>(false);
	const [selectedClass, setSelectClass] = useState('');
	const seatConformed = true;
	const { data: tableData = [] } = useQuery(['getAllMinorcsdssSelected', collegeId, selectedClass, seatConformed], getAllMinorsSelected({ collegeId, classId: selectedClass, seatConformed, setLoading }));
	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId));
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	return isLoading ? (
		<Main title='Confirmed Course Wise Allotment' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
					<p className='text-6xl font-bold my-10 animate-scroll'>Getting FYUGP allotment List...</p>
					<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time.</p>
					<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
					<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
				</div>
			</Body>
		</Main>
	) : (
		<>
			<Main title='Student Selected Subject List FYUGP' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
					</div>
					<div className='flex'>
						<Button
							type={showAdvance ? 'save' : 'fetch'}
							onClick={() => {
								setAdvance(!showAdvance);
							}}>
							{showAdvance ? 'Show Normal Mode' : 'Show Advance Mode'}
						</Button>
						<Button type='print' fileName={getClassName(selectedClass, classes?.list).replace(/\./g, '')} tableRef={ref.current}>
							Print
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<table className='min-w-full table-auto'>
							<thead>
								<tr>
									<td className='p-1 bg-white' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='p-1 text-center text-black bg-white text-xl font-bold' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										Course: {getClassName(selectedClass, classes?.list)}
									</td>
								</tr>
								<tr>
									<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										FYUGP 2024 - CONFIRMED COURSE ALLOTMENT LIST FROM NECTTOS @ {moment(new Date()).format('DD-MM-YYYY hh:mm A')}
									</td>
								</tr>
							</tbody>
						</table>

						<Table width={'100%'} innerWidth={['10%', '18%', '6%']}>
							<Thead>
								<Tr>
									<Th textAlign='center' position={2}>
										SL.
									</Th>

									<Th textAlign='left' position={1}>
										Name
									</Th>
									{/* <Th textAlign='center' position={1}>
										Minor 1
									</Th>
									<Th textAlign='center' position={1}>
										Minor 2
									</Th> */}
									<Th textAlign='center' position={1}>
										MDC
									</Th>
									{showAdvance && (
										<>
											<Th textAlign='center' position={0}>
												Phone No
											</Th>
											<Th textAlign='center' position={2}>
												Index %
											</Th>
											<Th textAlign='center' position={2}>
												Quota
											</Th>
										</>
									)}
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableData.map((x: any, i: number) => (
									<Tr key={i}>
										<Td textAlign='center' index={i} position={2}>
											{i + 1}
										</Td>

										<Td index={i} position={1}>
											{x?.name?.toUpperCase()}
										</Td>
										{/* {x.dsc_b_a ? (
											<Td textAlign='center' index={i} position={1}>
												{x.dsc_b_a}
											</Td>
										) : (
											<Td textAlign='center' color='red' index={i} position={1}>
												------- Confirmation Pending -------
											</Td>
										)}

										{x.dsc_c_a ? (
											<Td textAlign='center' index={i} position={1}>
												{x.dsc_c_a}
											</Td>
										) : (
											<Td textAlign='center' color='red' index={i} position={1}>
												------- Confirmation Pending -------
											</Td>
										)} */}

										{x.mdc_a ? (
											<Td textAlign='left' index={i} position={1}>
												{x.mdc_a}
											</Td>
										) : (
											<Td textAlign='center' color='red' index={i} position={1}>
												------- Confirmation Pending -------
											</Td>
										)}

										{showAdvance && (
											<>
												<Td textAlign='center' index={i} position={0}>
													{x?.phoneNumber?.slice(-10)}
												</Td>
												<Td textAlign='center' index={i} position={2}>
													{String(x.rcMark).slice(0, 7)}
												</Td>
												<Td textAlign='center' index={i} position={2}>
													{x.rcGroup}
												</Td>
											</>
										)}
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>
		</>
	);
}

export default ConfirmedClassWiseAllotment;
