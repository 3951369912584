import React, { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Input from '../../NecttosComp/Input/Input';
interface FormState {
	phoneNumber: string;
	name: string;
	department: any;
	roles: any;
	role: any;
}

const Enquiry: React.FC = () => {
	const [formState, setFormState] = useState<FormState>({
		phoneNumber: '',
		name: '',
		department: '',
		roles: '',
		role: '',
	});

	const params: { id: string; type: string } = useParams();
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [values, setValues] = useState<any>({});

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	interface AxiosError {
		response?: {
			data?: {
				error?: string;
			};
			status?: number;
		};
	}

	const validateForm = () => {
		const requiredFields = ['name', 'name', 'firstMobile', 'secondMobile', 'email', 'gender', 'place', 'district', 'pinCode', 'discoverySource', 'enquiryFor'];
		let isValid = true;
		if (values.disabilities === 'Yes' && !values.disabilityStatus) {
			toast.error('Please select your disability.');
			isValid = false;
		}

		requiredFields.forEach((field) => {
			if (!values[field]) {
				toast.error(`"${field}" is required.`);
				isValid = false;
			}
		});

		return isValid;
	};

	const saveData = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (!validateForm()) {
				return;
			}
			const instance = await getAxiosInstance();
			try {
				const resp = await instance.post('/college/users/addEnquriy', { collegeId: params?.id, type: params?.type, values });

				if (resp?.data?.statusCode === 400) {
					toast.error(resp.data.message || 'There was a problem with your submission.');
				} else {
					setValues({});
					toast.success('Data saved successfully!');
				}
			} catch (error) {
				const axiosError = error as AxiosError;
				if (!axiosError.response) {
					toast.error('Network error. Please try again.');
				} else {
					const errorMessage = axiosError.response?.data?.error || 'An error occurred. Please try again.';
					toast.error(errorMessage);
				}
			}
		},
		[values, params.id],
	);

	return (
		<div className='flex items-center justify-center min-h-screen bg-gray-100 p-4'>
			<Toaster />
			<div className='max-w-xl p-6 bg-white rounded-lg shadow-md w-full'>
				<h2 className='mb-4 text-lg font-semibold text-center bg-blue-100 text-blue-800 rounded-lg shadow py-2 px-4'>ENQUIRY!</h2>
				<form className='space-y-4' onSubmit={saveData}>
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
						<Input required={true} width='100%' fieldName='Name' returnKey='name' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='Enquiry for' returnKey='enquiryFor' state={values} setState={setValues} options={['Self', 'My Child', 'My Friend', 'My Relative', 'My Student', 'A Client', 'Other']} type='drop' />
						<Input required={true} width='100%' fieldName='How did you hear about our College?' returnKey='discoverySource' state={values} setState={setValues} options={['Social Media (Facebook, Twitter, Instagram, etc.)', 'Search Engine (Google, Bing, etc.)', 'Word of Mouth', 'Educational Fair', 'Print Advertisements (Newspapers, Magazines, Flyers, etc.)', 'Online Advertisements', 'Email Campaigns', 'Radio or Television Ads', 'Alumni Network', 'Other']} type='drop' />
						<Input required={true} width='100%' fieldName='Top Course Priority' returnKey='coursePriority' state={values} setState={setValues} options={['Bachelor of Arts (B.A.)', 'Bachelor of Science (B.Sc.)', 'Bachelor of Commerce (B.Com.)', 'Bachelor of Technology (B.Tech)', 'Master of Arts (M.A.)', 'Master of Science (M.Sc.)', 'Master of Commerce (M.Com.)', 'Master of Business Administration (MBA)', 'Other']} type='drop' />
						<Input required={true} width='100%' fieldName='Mobile 1' returnKey='firstMobile' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='Mobile 2' returnKey='secondMobile' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='Email' returnKey='email' state={values} setState={setValues} type='email' />
						<Input required={true} width='100%' fieldName='Gender' returnKey='gender' state={values} setState={setValues} options={['Female', 'Male', 'Other']} type='drop' />
						<Input required={true} width='100%' fieldName='Place' returnKey='place' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='District' returnKey='district' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='State' returnKey='state' state={values} setState={setValues} type='text' />
						<Input required={true} width='100%' fieldName='Pincode' returnKey='pinCode' state={values} setState={setValues} type='number' />
					</div>
					<div className='flex justify-center mt-6'>
						<button type='submit' className='btn-primary'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Enquiry;
