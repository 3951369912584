import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAllAccademics } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';

const getFieldDropDowns = (data: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getFieldDropDowns', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface invoiceManagementProps {
	onClose: () => void;
}

function FalseNumbering({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);

	const { data: tableData = [], refetch } = useQuery('getAllAccadamicHolidays', getAllAccademics(collegeId));

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteAccadamicHoliday/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postAccadamicHolidays', { ...addNew, collegeId })
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	return (
		<>
			<Main title='Exam Registration' width='100vw' height='100vh'>
				<Header>
					<Button
						type='update'
						onClick={() => {
							setShowForm(true);
						}}>
						Preview
					</Button>
					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto flex items-center justify-center'>
						<div className='text-center bg-gray-100 p-6 rounded-lg shadow-lg'>
							<h1 className='text-3xl font-bold mb-4 text-indigo-600'>We are working on this module</h1>
							<p className='text-lg mb-4 text-gray-700'>Once completed, this will open soon</p>
							<div className='mt-6'>
								<h2 className='text-2xl font-semibold text-gray-900'>Work Assigned To:</h2>
								<p className='text-lg text-gray-800'>....</p>
								<p className='text-lg text-gray-800'>....</p>
								<p className='text-lg text-gray-800'>....</p>
							</div>
							<div className='mt-6'>
								<img style={{ height: '300px', width: '800px' }} src='https://theproductmanager.com/wp-content/uploads/sites/4/2023/07/PRD-Keyword-software-development-life-cycle_Infographic-Software-development-cycle.png' alt='Software Development Life Cycle' className='mx-auto' />
							</div>
						</div>
					</div>
				</Body>
			</Main>
			{showForm && (
				<Main width='40vw'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'>
							<Input width='100%' fieldName='Holiday Title' returnKey='title' state={addNew} setState={setAddNew} type='text' />

							<Input width='100%' fieldName='Start Date' returnKey='startDate' state={addNew} setState={setAddNew} type='date' />

							<Input width='100%' fieldName='End Date' returnKey='endDate' state={addNew} setState={setAddNew} type='date' />

							<Input width='100%' fieldName='Who Is Effected' returnKey='effected' state={addNew} setState={setAddNew} options={['All', 'First Year', 'Second Year', 'Third Year']} type='drop' />

							<Button type='save' onClick={addNewForm}>
								Save
							</Button>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default FalseNumbering;
