import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Table, Thead, Tbody, Td, Td2, Tfoot, TfootTd, TfootTr, Th, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import Voucher from './Voucher';
import { Main } from '../../NecttosComp/Layout/Layout';

function DetailedReportTable({ date, setDate, account }) {
	const { collegeId } = useContext(FirebaseContext);

	const [reciept, setReciept] = useState(0);
	const [payment, setPayment] = useState(0);
	const [printing, setPrinting] = useState(false);

	const tableRef = useRef(null);

	console.log(date.data);

	useEffect(() => {
		let reciept = 0;
		let payment = 0;
		for (let i in date?.data) {
			const item = date?.data[i];
			reciept = reciept + (parseInt(item.debitCredit === 'Credit' ? item.type === 'reciept' && item.amount : item.type === 'payment' && item.amount) || 0);
			payment = payment + (parseInt(item.debitCredit === 'Credit' ? item.type === 'payment' && item.amount : item.type === 'reciept' && item.amount) || 0);
		}
		setReciept(reciept);
		setPayment(payment);
	}, [date]);

	const callPostFunction = async (linkId) => {
		const instance = await getAxiosTokenInstance();
		console.log({ linkId });

		try {
			const resp = await instance.post('/college/action/deleteAccounts', { collegeId, linkId });

			if (resp?.data?.statusCode === 400) {
				throw new Error(resp?.data?.message || 'API Error');
			} else {
				setDate({ ...date, data: [] });
				return resp?.data;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error?.message || 'API Error');
		}
	};

	return (
		<Main>
			<div className='bg-gray-100 p-6 w-[80vw] rounded-lg shadow-xl overflow-auto h-[90vh]'>
				<div className='flex justify-between z-1'>
					<h3 className='capitalize text-green-950'>{account}</h3>
					<div className='flex items-center'>
						<Button
							type='save'
							onClick={() => {
								setDate({
									...date,

									showEdit: true,
								});
							}}>
							Post New Journal Entry
						</Button>
						<Button type='excel' tableRef={tableRef?.current} fileName='account'>
							Excel{' '}
						</Button>
						<Button type='pdf' pdfId='emp' fileName='account'>
							PDF{' '}
						</Button>
						<Button type='print'>Print</Button>

						<Button type='close' onClick={() => setDate({ ...date, data: [] })}>
							Close
						</Button>
					</div>
				</div>
				<div className='overflow-x-auto z-1'>
					<Table width={'100%'} id='emp' ref={tableRef}>
						<Thead>
							<Tr>
								<Th width={'5%'}>SN</Th>
								<Th width={'10%'}>Date</Th>
								<Th width={'10%'}>Account</Th>
								<Th width={'10%'}>Paid To</Th>
								<Th width={'25%'}>Particulars</Th>
								<Th width={'10%'}>Receipt</Th>
								<Th width={'10%'}>Payment</Th>
								<Th width={'15%'}>Actions</Th>
							</Tr>
						</Thead>
						<Tbody height={'70vh'}>
							{date?.data?.map((item, i) => (
								<Tr index={i}>
									<Td index={i} width={'5%'}>
										{i + 1}
									</Td>
									<Td index={i} width={'10%'}>
										{moment(item.date).format('DD MMM YYYY')}
									</Td>
									<Td index={i} width={'10%'} textAlign={'start'}>
										{item.accountName || 'Remitance'}
									</Td>
									<Td index={i} width={'10%'} textAlign={'start'}>
										{item?.paidTo?.toUpperCase()}
									</Td>
									<Td index={i} width={'25%'} textAlign={'start'}>
										{item.naration}
									</Td>
									<Td index={i} width={'10%'}>
										{item.debitCredit === 'Credit' ? item.type === 'reciept' && item.amount : item.type === 'payment' && item.amount}
									</Td>
									<Td index={i} width={'10%'}>
										{item.debitCredit === 'Credit' ? item.type === 'payment' && item.amount : item.type === 'reciept' && item.amount}
									</Td>
									<Td index={i} width={'15%'}>
										<div className='flex'>
											<Button
												type='save'
												onClick={() => {
													setPrinting(true);
													setDate({
														...date,
														printData: {
															...item,
															date: moment(new Date(item.date)).format('YYYY-MM-DD'),
															from: item.type === 'payment' ? item.accountId : item.wereId,
															fromval: item.type === 'payment' ? item.accountName : item.wereName,
															to: item.type === 'payment' ? item.wereId : item.accountId,
															toval: item.type === 'payment' ? item.wereName : item.accountName,
															linkId: item.linkId,
															amount: item.amount,
															naration: item.naration,
															billNumber: item.recieptNo,
														},
													});
												}}>
												Print
											</Button>
											<Button
												type='edit'
												onClick={() => {
													setDate({
														...date,
														values: {
															date: moment(new Date(item.date)).format('YYYY-MM-DD'),
															from: item.type === 'payment' ? item.accountId : item.wereId,
															fromval: item.type === 'payment' ? item.accountName : item.wereName,
															to: item.type === 'payment' ? item.wereId : item.accountId,
															toval: item.type === 'payment' ? item.wereName : item.accountName,
															linkId: item.linkId,
															amount: item.amount,
															naration: item.naration,
															billNumber: item.recieptNo,
															item: item,
														},
														linkId: item.linkId,
														showEdit: true,
													});
												}}>
												Edit
											</Button>
											<Button type='delete' onClick={() => callPostFunction(item?.linkId)}>
												Delete
											</Button>
										</div>
									</Td>
								</Tr>
							))}
						</Tbody>
						<Tfoot>
							<TfootTr>
								<TfootTd width={'40%'}>Grand Total</TfootTd>
								<TfootTd width={'10%'}>{reciept}</TfootTd>
								<TfootTd width={'10%'}>{payment}</TfootTd>
								<TfootTd width={'15%'}></TfootTd>
							</TfootTr>
						</Tfoot>
					</Table>
				</div>
			</div>
			{printing && <Voucher z={100} data={date.printData} onClose={() => setPrinting(false)} />}
		</Main>
	);
}

export default DetailedReportTable;
