import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';

function C3_3_2({ val, onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		naacFetchData({
			data: { collegeId, academicYear },
			setLoading,
			setValues,
			url: '/college/NAAC/getNAAC_C3_3_2',
		});
	}, []);
	return (
		<>
			<div className='  z-10 w-4/5 bg-slate-100 h-4/5 p-3'>
				<table className='table'>
					<thead>
						<th colSpan={11} style={{ background: 'rgb(241 245 249)' }}>
							<h1 className='text-xl flex'>
								3.3.2 Number of research papers per teachers in the Journals notified on UGC website during the year
								<div className='flex'>
									<AcademicYears setAcademicYear={setAcademicYear} />
									<button
										className='bg-green-500 text-sm'
										onClick={() =>
											naacExcelGenerator({
												data: { collegeId, academicYear: '2022-23' },
												url: '/college/NAAC/getNAAC_C2_7',
												setLoading,
											})
										}>
										Dowload
									</button>
									<button className='closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]' onClick={onClose}>
										X
									</button>
								</div>
							</h1>
						</th>
						<tr>
							<th className='w-1/4'>Title of paper</th>
							<th className='w-1/4'>Name of the author/s</th>
							<th className='w-1/4'>Department of the teacher</th>
							<th className='w-1/4'>Name of journal</th>
							<th className='w-1/4'>Year of publication</th>
							<th className='w-1/4'>ISSN number</th>
							<th className='w-1/4'>Link to the recognition in UGC enlistment of the Journal</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
}

export default C3_3_2;
