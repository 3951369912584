import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";



const getFieldDropDowns = (data: any) => async () => {


  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/action/getFieldDropDowns', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error(
      'API Error',
    );
  }
}

interface invoiceManagementProps {
  onClose: () => void
}

function InvoiceManagement({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>()
  const [showForm, setShowForm] = useState(false)
  const [tableData, setTableData] = useState<any[]>([])
  const { collegeId } = useContext(FirebaseContext)
  const ref = useRef<any>(null)


  const { data: vendorDropDown = [], } = useQuery(['vendorDropDown', addNew?.vendor, "bookStocks"], getFieldDropDowns({ collegeId, search: addNew?.vendor, collection: "bookStocks", key: "vendor" }), { keepPreviousData: true })
  const { data: fundDropDown = [], } = useQuery(['fundDropDown', addNew?.funded, "bookStocks"], getFieldDropDowns({ collegeId, search: addNew?.funded, collection: "bookStocks", key: "fund" }), { keepPreviousData: true })


  const getData = async () => {
    try {
      const instance = await getAxiosTokenInstance();

      instance.get("/college/library/getInvoiceManagement").then((res) => {
        console.log(res?.data?.data)
        setTableData(res?.data?.data)
      }).catch((err) => {
        console.log(err)
      })
    } catch (err) {
      throw new Error(
        'API Error',
      );
    }
  }

  const handleDelete = async(_id:string)=>{
    try{
      const instance = await getAxiosTokenInstance();
      instance.delete("/college/library/deleteInvoiceManagement/"+_id,{params:{collegeId}}).then(()=>{
        getData()
      }).catch((err:any)=>{
        console.error(err)
      })
    }catch(err){
      console.log(err)
    }
  }

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();

      instance.post("/college/library/addInvoiceMangement", { ...addNew, collegeId }).then(() => {
        setAddNew({})
        getData()
      }).catch((err:any)=>{
        console.error(err)
      })
      closeAnimation()
      setTimeout(() => setShowForm(false), 500)
    } catch (err) {
      throw new Error(
        'API Error',
      );
    }
  }



  useEffect(() => {
    getData()
  }, [])


  return <>
    <Main title="Invoice Management" width="90vw" height="90vh">
      <Header>
        <Button type="update" onClick={() =>{
           setShowForm(true)
           }}>New Bill</Button>
           <div className="flex">

        <Button type='print' tableRef={ref.current}>Print</Button>
        <Button type='excel' tableRef={ref.current}>Excel</Button>
        <Button type="close" onClick={onClose}>Close</Button>
           </div>
      </Header>
      <Body>
        <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
          <Table  width="100%" innerWidth={["3%", "10%", "10%", "15%", "20%", "10%","8%","7%","7%" ,"10%"]}>
            <Thead>
              <Tr>
                <Th position={0} fontSize="10px">Sl.No.</Th>
                <Th position={1}>Invoice No.</Th>
                <Th position={2}>Invoice Date</Th>
                <Th position={3}>Vendor</Th>
                <Th position={4}>Funded</Th>
                <Th position={5}>Bill Amount</Th>
                <Th position={6}>Acc.No.</Th>
                <Th position={7} fontSize="12px">Payable</Th>
                <Th position={8}>Remarks</Th>
                <Th position={9}>Action</Th>
              </Tr>
            </Thead>
            <Tbody height="auto">
              {tableData?.map((x: any, i: number) => (
                <Tr>
                  <Td index={i} position={0}>{i + 1}</Td>
                  <Td index={i} position={1}>{x?.invoiceNumber}</Td>
                  <Td index={i} position={2}>{x?.invoiceDate}</Td>
                  <Td index={i} position={3}>{x?.vendor}</Td>
                  <Td index={i} position={4}>{x?.funded}</Td>
                  <Td index={i} position={5}>{x?.amount}</Td>
                  <Td index={i} position={6}>{x?.from} - {x?.to}</Td>
                  <Td index={i} position={7}>{x?.payable}</Td>
                  <Td index={i} position={8}>{x?.remarks}</Td>
                  <Td index={i} position={9}><div className="flex">
                    <Button type="update" onClick={()=>{
                      console.log(x)
                      setAddNew(x)
                      setShowForm(true)
                    }}>Edit</Button><Button type="delete" onClick={()=>handleDelete(x?._id)}>Delete</Button>
                  </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Body>
      <Footer></Footer>
    </Main>

    {showForm && <Main width="40vw">
      <Header>
        <Button type="close" onClick={() => {
          closeAnimation()
          setTimeout(() => setShowForm(false), 500)
        }}>Close</Button>
      </Header>
      <Body>
        <div className="flex flex-col w-full">

          <Input width="100%" fieldName="Invoice No." returnKey="invoiceNumber" state={addNew} setState={setAddNew} type="text" />
          <Input width="100%" fieldName="Invoice Date" returnKey="invoiceDate" state={addNew} setState={setAddNew} type="date" />
          <Input width="100%" fieldName="Vendor" returnKey="vendor" state={addNew} setState={setAddNew} options={vendorDropDown} type="drop" />
          <div className="relative z-1">
            <Input width="100%" fieldName="Funded" returnKey="funded" state={addNew} setState={setAddNew} options={fundDropDown} type="drop" />
          </div>
          <Input width="100%" fieldName="Bill Amount" returnKey="amount" state={addNew} setState={setAddNew} type="number" />
          <Input width="100%" fieldName="Accession Number From" returnKey="from" state={addNew} setState={setAddNew} type="number" />
          <Input width="100%" fieldName="Accession Number To" returnKey="to" state={addNew} setState={setAddNew} type="number" />
          <Input width="100%" fieldName="Payable" returnKey="payable" state={addNew} setState={setAddNew} type="text" />
          <Input width="100%" fieldName="Remarks" returnKey="remarks" state={addNew} setState={setAddNew} type="text" />
          <Button type="save" onClick={() => {
            addNewForm()
          }}>Save</Button>
        </div>
      </Body>
    </Main>}

  </>;
}

export default InvoiceManagement;
