import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import CustomInput from '../../../myComp/CustomInput';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getMainAccounts, getElectionPost } from '../../../NecttosComp/APICaller/ApiServices';

export const PostElection = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { data: accountData = [] } = useQuery(['getMainAccounts', collegeId], getMainAccounts(collegeId));

	let { data = [], refetch } = useQuery('getElectionPost', getElectionPost(collegeId));
	const [isLoading, setIsLoading] = useState(false);

	const [state, setState] = useState({});

	const manageHolidays = async () => {
		try {
			setIsLoading(true);
			if (!state.postName) {
				throw new Error('Account Name is Empty');
			}
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/electionPost', {
				collegeId,
				postName: state?.postName,
				included: state?.included,
				excluded: state?.excluded,
				priority: state?.priority,
				_id: state?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					postName: '',
					included: '',
					excluded: '',
					priority: '',
					_id: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	if (state.aname) {
		data = data?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [];
	} else if (state.sname) {
		data = data?.sort((a, b) => a?.shortName?.localeCompare(b?.shortName)) || [];
	} else if (state.srro) {
		data = data?.sort((a, b) => a?.subsidiaryRegisterOrder?.localeCompare(b?.subsidiaryRegisterOrder)) || [];
	} else if (state.dfcro) {
		data = data?.sort((a, b) => a?.dailyFeeCollectionOrder?.localeCompare(b?.dailyFeeCollectionOrder)) || [];
	} else if (state.mname) {
		data = data?.sort((a, b) => a?.mainName?.localeCompare(b?.mainName)) || [];
	} else {
		data = data?.sort((a, b) => a?.priority?.localeCompare(b?.priority)) || [];
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<>
							<div className={classNames.list}>
								{state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}

								{/* < SearchableImput
                  keyData={'shortName'}
                  titleData={'Short Name'}
                  collection={'subAccounts'}
                  stateData={state}
                  setStateData={setState}
                /> */}
								{/* <CustomDropDownKey
                  keyData={'mainId'}
                  keyObject={'name'}
                  keyReturn={'_id'}
                  titleData={'Select Main A/C'}
                  dropValues={accountData || []}
                  stateData={state}
                  setStateData={setState}
                /> */}

								<CustomInput keyData={'postName'} titleData={'Name of the post'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomInput keyData={'included'} titleData={'Included'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomInput keyData={'excluded'} titleData={'Excluded'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomInput keyData={'priority'} titleData={'Priority Order'} typeData={'text'} stateData={state} setStateData={setState} />

								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												postName: '',
												included: '',
												excluded: '',
												priority: '',
												_id: '',
											});
										}}>
										{' '}
										Cancel{' '}
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											manageHolidays();
										}}>
										Submit
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} className='printable'>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Election Post Management</h5>
								<div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>

									<input type='checkbox' style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={state.mname} onChange={() => setState({ ...state, mname: !state.mname })} />
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Name of the Post</h5>
									<input type='checkbox' style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={state.aname} onChange={() => setState({ ...state, aname: !state.aname })} />
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Included</h5>

									<input type='checkbox' style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={state.srro} onChange={() => setState({ ...state, srro: !state.srro })} />
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Excluded</h5>
									<input type='checkbox' style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={state.sname} onChange={() => setState({ ...state, sname: !state.sname })} />
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '20%' }}>Priority Order</h5>
									<input type='checkbox' style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={state.dfcro} onChange={() => setState({ ...state, dfcro: !state.dfcro })} />
								</div>
								{data?.map((item, i) => (
									<>
										<div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '30%' }}>{item.postName}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '30%' }}>{item.included}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '20%' }}>{item.excluded}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '20%' }}>{item.priority}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<Button
												className={classNames.submitBotton}
												onClick={() => {
													setState({
														...state,
														postName: item?.postName || '',
														included: item?.included || '',
														excluded: item?.excluded || '',
														priority: item?.priority || '',
														_id: item?._id || '',
													});
												}}>
												Edit
											</Button>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												height: 1,
												backgroundColor: 'black',
											}}></div>
									</>
								))}
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>

			<Button
				className={classNames.reloadButton}
				onClick={() => {
					refetch();
				}}>
				Reload
			</Button>

			<ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />

			{!isLoading && (
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			)}
		</div>
	);
};
