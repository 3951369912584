import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Published/Login';
import DashRoutes from './DashRoutes';
import DownloadPage from './Published/AppStore';
import Register from './Published/Register/register';
import CollegeList from './NecttosApp/windows/InstitutesHub';
import AdmissionDetails from './Published/work-processing';
import Herberium from './Published/herbarium/herbarium';
import ScienceFest from './Published/scienceFest/scienceFest';
import RedirectToExternalUrl from './Published/Redirect';
import CollegeLogin from './NecttosApp/CollegeLogin';
import Onboarding from './CollegeApp/Form/Onboarding';
import PaymentReturn from './NecttosApp/PaymentReturn';
import Enquiry from './CollegeApp/Form/Enquiry';
import NirmalaCollege from './CollegeApp/Form/NirmalaCollege';
import StXaviersAluva from './CollegeApp/Form/StXaviersAluva';
import ShCollegeChalakudy from './CollegeApp/Form/ShCollegeChalakudy';
import BasicCollege from './CollegeApp/Form/BasicCollege';
import LastIndexMark from './CollegeApp/Admission/LastIndexMark';
import FYUGPMain from './CollegeApp/Form/FYUGPMain';
import FYUGPM1 from './CollegeApp/FYUGP/FYUGPM1';
import OnboardingDataLose from './CollegeApp/Form/OnboardingDataLose';
import PortFolio from './components/Website/portFolio';

// import io from "socket.io-client";
// import { NECTOTS_API_URL } from "./config";

// const socket = io(NECTOTS_API_URL);

function Routes() {
	return (
		<Switch>
			<Route exact path='/' component={Login} />
			<Route path='/home' component={Login} />
			<Route path='/ceo' component={PortFolio} />
			<Route path='/herbarium/:collegeId' component={Herberium} />
			<Route path='/shashtrayan' component={ScienceFest} />
			<Route path='/login/:id' component={CollegeLogin} />
			<Route path='/login' component={Login} />
			<Route path='/nirmala-college-admissions/:type' component={NirmalaCollege} />
			<Route path='/college-admissions/:collegeName/:type' component={BasicCollege} />
			<Route path='/st-xaviers-college-admissions/:type' component={StXaviersAluva} />
			<Route path='/sacred-heart-college-admissions/:type' component={ShCollegeChalakudy} />
			<Route path='/FYUGP' component={FYUGPMain} />
			<Route path='/FYUGPM1/:id/' component={FYUGPM1} />
			<Route path='/admission/:id/' component={AdmissionDetails} />
			<Route path='/lastIndexMark/:id/:year/:date' component={LastIndexMark} />
			<Route path='/register' component={Register} />
			<Route path='/download' component={DownloadPage} />
			<Route path='/app' component={DashRoutes} />
			<Route path='/college-list' component={CollegeList} />
			<Route path='/onboardin/:id/:role' component={Onboarding} />
			<Route path='/onboarding/:id/:role' component={Onboarding} />
			<Route path='/onboardingData/:id/:role' component={OnboardingDataLose} />
			<Route path='/enquiry/:id/:type' component={Enquiry} />
			<Route path='*' component={Login} />
			<Route path='/refund-policy' component={RedirectToExternalUrl} />
			<Route path='/privacy-policy' component={RedirectToExternalUrl} />
			<Route path='/terms-and-conditions' component={RedirectToExternalUrl} />
			<Route path='/contact-us' component={RedirectToExternalUrl} />
			<Route path='/paymentreturn' component={PaymentReturn} />
		</Switch>
	);
}

export default Routes;
