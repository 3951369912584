import React, { useContext } from "react";
import Dropdown from "antd/lib/dropdown";
import message from "antd/lib/message";
import Menu from "antd/lib/menu";
import deleteIcn from "../../static/images/clipIcon/delete.svg";
import { ROLES } from "../../config/constants";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useMutation } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import LoadingAnimation from "../../components/LoadingAnimation";

const addRole = async (data) => {
  const instance = await getAxiosTokenInstance();
  const resp = await instance.post("/college/role/addRole", data);
  return resp.data;
};

function UserRow({ classNames, user, role, refetch }) {
  const { collegeId } = useContext(FirebaseContext);
  const { mutate, isLoading } = useMutation(addRole, {
    onSuccess: (data) => {
      if (data.statusCode === 400) {
        message.error(
          data.response.data.message || data.message || "API Error"
        );
      } else {
        message.success("Roles Added");
        refetch();
      }
    },
    onError: (error) => {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    },
  });


  const removeUser = async (data) => {
    if (window.confirm('Do you really need to delete this user?')) {
      const instance = await getAxiosTokenInstance();
      const resp = await instance.put("/college/role/removeUserRole", { userId: user._id, role });
      // return resp.data;
      if (resp?.data?.statusCode === 400) {
        message.error(
          resp?.data?.response.data.message || resp?.data.message || "API Error"
        );
      } else {
        message.success("User Removed");
        refetch();
      }
    }
  };


  function addRoleFn(role) {
    if (!collegeId) {
      message.info("Wait College Id Is Not Loaded");
    } else {
      mutate({
        collegeId,
        userId: user._id,
        role,
      });
      message.info("Adding Role.....");
    }
  }

  const menu = (
    <Menu>
      {ROLES?.filter(
        (role) =>
          !user?.collegeRoles?.map((roleIn) => roleIn?.type).includes(role)
      )?.map((role) => (
        <Menu.Item key="1" onClick={() => addRoleFn(role)}>
          {role}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["contextMenu"]}>
        <tr>
          <td>
            <div className={classNames.user}>
              <img
                src={
                  user?.photoURL ||
                  `https://avatars.dicebear.com/api/initials/${user.name}.svg`
                }
                alt=""
                className={classNames.dp}
              />
              <span>{user.name}</span>
            </div>
          </td>
          <td>
            {user?.collegeRoles
              ?.filter((role) => role.collegeId === collegeId)
              ?.map((role) => `${role?.type}, `)}
          </td>
          <td className={classNames.action}>
            <div onClick={() => removeUser()} className={classNames.imgWrap}>
              <img src={deleteIcn} alt="" />
            </div>
          </td>
        </tr>
      </Dropdown>
      {isLoading && (
        <div className={classNames.loading}>
          <LoadingAnimation />
        </div>
      )}
    </>
  );
}

export default UserRow;
