import React, { useState } from 'react';
import file1 from '../../static/images/naac/File 1.png';
import file2 from '../../static/images/naac/File 2.png';
import file3 from '../../static/images/naac/File 3.png';
import file4 from '../../static/images/naac/File 4.png';
import file5 from '../../static/images/naac/File 5.png';
import file6 from '../../static/images/naac/File 6.png';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import ModalWrapper from '../../components/ModalWrapper';
import C_1_1_3 from './CriteriaOne/C_1_1_3';
import { C1_1 } from './C1_1';

interface C1props {
	onClose: () => void;
}

function C1({ onClose }: C1props) {
	const [criteria, setCriteria] = useState<any>();
	const folders = [
		{ classification: 'c1', keyData: 'c1_1', title: '1.1', img: file1, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
		{ classification: 'c1', keyData: 'c1_1_3', title: '1.1.3', img: file2, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
		{ classification: 'c1', keyData: 'c1', title: '1.2.1', img: file3, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
		{ classification: 'c1', keyData: 'c1', title: '1.2.2 & 1.2.3', img: file4, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
		{ classification: 'c1', keyData: 'c1', title: '1.3.2', img: file5, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
		{ classification: 'c1', keyData: 'c1', title: '1.3.3', img: file6, instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'principal', 'iqac'] },
	];
	return (
		<>
			<Main width='auto' height='auto' title='Criteria One'>
				<Header>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</Header>
				<Body>
					<div className='h-[65vh] overflow-auto'>
						{folders?.map((item) => (
							<>
								<div onClick={() => setCriteria({ [item.keyData]: true })} className={'roleCards m-[2vh]  float-left'} id={item?.keyData} draggable={true}>
									<div className={'icons'}>
										<div key={item.keyData}>
											<img src={item?.img} />
										</div>
									</div>
									<h2 className='text-center mt-[.11vh] text-shadow'>{item?.title}</h2>
								</div>
							</>
						))}
					</div>
				</Body>
			</Main>

			{criteria?.c1_1 && (
				<ModalWrapper>
					<C1_1 onClose={() => setCriteria({ ...criteria, c1_1: false })} />
				</ModalWrapper>
			)}

			{criteria?.c1_1_3 && (
				<ModalWrapper>
					<C_1_1_3 onClose={() => setCriteria({ ...criteria, c1_1_3: false })} />
				</ModalWrapper>
			)}
		</>
	);
}

export default C1;
