import React from "react";
import classNames from "./modalWrapper.module.scss";

function ModalWrapper({ children }) {
  return (
    <div className={classNames.modalWrapper}>
      <div
        className={classNames.overlay}
      />
      {children}
    </div>
  );
}

export default ModalWrapper;
