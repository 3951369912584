import React, { useContext, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Grid } from 'react-loader-spinner';

interface InvoiceManagementProps {
	onClose: () => void;
}

function SubjectAllocationAuto({ onClose }: InvoiceManagementProps) {
	const { collegeId } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(false);

	const fetchAutoIndex = async () => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/fyugp/subjectAllocation', {
				collegeId,
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else if (resp?.data?.list?.length > 0) {
				// Handle the case where the list is populated
			} else {
				// Handle the case where the list is empty
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Main title='Student Selected Subject List FYUGP' width='100%' height='100%'>
				<Header>
					<div className='flex'></div>
					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					{loading ? (
						<div className='m-5 p-5 flex justify-center items-center'>
							<Grid height={'250'} width='250' color='#0083f5' visible={loading} />
						</div>
					) : (
						<Button type='close' onClick={fetchAutoIndex}>
							Post Course Allocation
						</Button>
					)}
				</Body>
			</Main>
		</>
	);
}

export default SubjectAllocationAuto;
