import React, { useContext, useState } from 'react';
import classNames from './addFeesForm.module.scss';
import Select from 'antd/lib/select';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { useAccountsHeads } from '../../../NecttosComp/APICaller/queryHooks';
import { Button } from 'antd';
import { getFeeStructure, getUserListNew } from '../../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { v4 as uuidv4 } from 'uuid';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDown from '../../../myComp/CustomDropDown';
import { useProfileData } from '../../../NecttosComp/APICaller/queryHooks';

const createRadomId = (prefix = '') => {
	const uuid = uuidv4().toUpperCase();
	const parsedUuid = uuid.replace('-', '');

	return `${prefix ? `${prefix}-` : ''}${parsedUuid}`;
};

const { Option } = Select;

const years = [
	{ name: 'First Year', key: '1y' },
	{ name: 'Second Year', key: '2y' },
	{ name: 'Third Year', key: '3y' },
	{ name: 'Year not Specified', key: undefined },
];

function sumWithKey(data, key) {
	return data?.reduce((x, y) => x + y[key], 0);
}

export const EarnLeaveCalculator = ({ onClose, userId }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const allClasses = useQuery(['userList', { type: 'teacher', collegeId }], getUserListNew);
	const [earnLeave, setEarnLeave] = useState([]);

	const classList = allClasses?.data?.list || [];
	const [isLoading, setIsLoading] = useState(false);

	const [stateConst, setStateConst] = useState({ list: { dues: [] } });

	const [state, setState] = useState({
		_id: '',
		limit: 1,
	});
	let isStaff = true;

	const { data: userDetail, status } = useProfileData(state?.payee?.uid, isStaff);

	let data = userDetail;

	console.log({ userDetail });
	const setConst = async () => {
		setStateConst({ ...userDetail });
	};
	const feeClearence = async () => {
		setEarnLeave([]);
	};
	const deleteItems = async (_id) => {
		stateConst.list.dues.forEach((x, i) => {
			if (x._id === _id) {
				stateConst.list.dues.splice(i, 1);
			}
		});
		setStateConst({ ...stateConst });
	};
	const onAddFees = async () => {
		try {
			setIsLoading(true);

			let filterData = [];

			years.forEach((item) => {
				if (state[item.name]) {
					filterData.push(item.key);
				}
			});

			console.log({ filterData });

			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postFeeStructure', {
				dues: stateConst?.list?.dues,
				collegeId,
				userId,
				classId: state.payee.classId,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					feesType: '',
					titleOfFee: '',
					description: '',
					dueId: '',
					isStudent: true,
					payee: '',
					amount: '',
					dueDate: '',
					date: '',
					recieptNo: '',
					_id: '',
				});
				onClose();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	let count = Array(parseInt(state?.limit) || 1).fill(1);
	let dataOfArrays = [];
	const processData = async () => {
		let arraData = [];
		count.forEach((item, i) => {
			let data = {};
			if (state['type' + i] === 'EARN') {
				data.earnLeave = (state['days' + i] * 30) / state['vecation' + i];
			} else {
				data.startDate = new Date(state['startDate' + i]);
				data.endDate = new Date(state['startDate' + i]);
			}
			arraData.push(data);
		});
		setEarnLeave([
			...earnLeave,
			{
				key: `1st year ${moment(userDetail.dateOfJoin, 'DD MMMM YYYY').format('DD.MM.YYYY')} to ${moment(new Date(moment(userDetail.dateOfJoin, 'DD MMMM YYYY')).setDate(new Date(moment(userDetail.dateOfJoin, 'DD MMMM YYYY')).getDate() + 365)).format('DD.MM.YYYY')}`,
				key2: `Block year is ${moment(userDetail.dateOfJoin, 'DD MMMM YYYY').format('DD.MM.YYYY')} to ${moment(new Date(moment(userDetail.dateOfJoin, 'DD MMMM YYYY')).setDate(new Date(moment(userDetail.dateOfJoin, 'DD MMMM YYYY')).getDate() + (365 + 13))).format('DD.MM.YYYY')}`,
				data: arraData,
			},
		]);
		setState({ limit: 1 });
	};
	console.log({ earnLeave });

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.addFeeForm}>
					<view style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<view style={{ width: 350, margin: 10 }}>
							<Select
								showSearch
								value={state.payee?.className}
								id='payee'
								className={classNames.formFullWidth}
								style={{ overflow: 'hidden', width: 350 }}
								placeholder='Select Class'
								onChange={(val) => {
									setState({ ...state, payee: classList?.find((x) => x.name === val) });
								}}>
								<label>Select class to pay this fees?</label>
								{classList?.map((item) => (
									<Option key={item?.name} value={item?.name}>
										{item.name}
									</Option>
								))}
							</Select>
						</view>

						<div
							className={classNames.btnClear2}
							onClick={() => {
								setConst();
							}}>
							SET
						</div>
						<div
							className={classNames.btnClear3}
							onClick={() => {
								setState({
									...state,
									feesType: '',
									feesTypeval: '',
									dueId: '',
									feesYear: '',
									feesYearval: '',
									dueIdval: '',
									description: '',
									amount: '',
									dueDate: '',
									date: '',
									recieptNo: '',
								});
							}}>
							Clear
						</div>

						<div
							className={classNames.btnClear}
							onClick={() => {
								feeClearence();
							}}>
							Add
						</div>
					</view>

					<div className={classNames.mainTitle}>{selectedCollege.collegeName}</div>
					<div className={classNames.mainTitle}>Block Year statement of Earned Leave Surrender (Terminal) of </div>
					<div className={classNames.mainTitle}>{userDetail?.name} (Principal) </div>
					<div className={classNames.dateTitle}>Entered service: {moment(userDetail?.dateOfJoin, 'DD MMMM YYYY').format('DD.MM.YYYY')} </div>

					<div className={classNames.title}>Calculation of Earned Leave</div>

					<Scrollbars>
						<view style={{ display: 'flex', flexDirection: 'column', margin: 0, alignSelf: 'center', margin: 12 }}>
							<div style={{ margin: 12 }}>
								{earnLeave.map((item, i) => (
									<>
										<div style={{ width: '100%', height: '200px', display: 'flex', flexDirection: 'row', margin: 0, alignItems: 'center', alignSelf: 'center', borderColor: 'black', borderWidth: '1px', borderStyle: 'solid' }}>
											<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', margin: 0, alignItems: 'center', alignSelf: 'center' }}>
												<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', margin: 0, alignItems: 'center', alignSelf: 'center' }}>
													<h5 style={{ width: '90%', color: 'black', textAlign: 'left', fontSize: 20 }}>{item.key}</h5>
												</div>
												<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', margin: 0, alignItems: 'center' }}>
													<h5 style={{ width: '90%', color: 'black', textAlign: 'left', fontSize: 20, fontWeight: '900' }}>{item.key2}</h5>
												</div>
											</div>
											<div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', margin: 0, alignItems: 'center', alignSelf: 'center' }}>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '10%' }}>AAAA</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '30%' }}>EEEE</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '40%' }}>UUUU</h5>
											</div>
										</div>
									</>
								))}
							</div>
						</view>
						<div className={classNames.listContainer}>
							<div className={classNames.list}>
								{count.map((item, i) => (
									<div className={classNames.list2}>
										<div style={{ width: 300 }}>
											<CustomDropDown keyData={'type' + i} titleData={'Type'} dropValues={['HPL', 'EL', 'CTL', 'Enjoy']} stateData={state} setStateData={setState} />
										</div>
										{state['type' + i] === 'EL' ? (
											<>
												<CustomInput keyData={'days' + i} titleData={'Days'} typeData={'number'} stateData={state} setStateData={setState} />
												<CustomInput keyData={'vecation' + i} titleData={'Vecation'} typeData={'number'} stateData={state} setStateData={setState} />
											</>
										) : (
											<>
												<CustomInput keyData={'startDate' + i} titleData={'Start Date'} typeData={'date'} stateData={state} setStateData={setState} />
												<CustomInput keyData={'endDate' + i} titleData={'End Date'} typeData={'date'} stateData={state} setStateData={setState} />
											</>
										)}
									</div>
								))}

								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												limit: parseInt(state.limit) - 1,
											});
										}}>
										{' '}
										-{' '}
									</Button>

									<Button
										className={classNames.submitBotton}
										onClick={() => {
											setState({
												...state,
												limit: parseInt(state.limit) + 1,
											});
										}}>
										+
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											processData();
										}}>
										Compute
									</Button>
								</div>
							</div>
						</div>
					</Scrollbars>
					{isLoading && (
						<div className={classNames.loading}>
							<LoadingAnimation />
						</div>
					)}
				</div>
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
				<Button
					className={classNames.closeButton2}
					onClick={() => {
						onAddFees();
					}}>
					FInal Submit
				</Button>
			</div>
		</div>
	);
};
