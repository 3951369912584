import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAllAccademics, initializePaymentRazorpay } from '../../NecttosComp/APICaller/ApiServices';
import SearchableUserInputPhoto from '../../myComp/SearchableUserInputPhoto';
import { getProfile } from '../../NecttosComp/APICaller/queryHooks';
import RenderRazorpay from '../Form/Razorpay';
import { error } from 'console';
import toast, { Toaster } from 'react-hot-toast';

const getExaminationDetails = (collegeId: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/examProcess/getExamInit', {
			params: { collegeId },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface Amount {
	amount: number;
	currencyCode: string;
}

interface invoiceManagementProps {
	onClose: () => void;
}

function ExamRegistration({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>({});
	const [showForm, setShowForm] = useState(false);

	const [refresh, setRefresh] = useState<boolean>(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [values, setValues] = useState<any>({});
	const { data: examData = [], refetch } = useQuery('getExamInit', getExaminationDetails(collegeId));
	const { data } = useQuery([`profileData`, collegeId, addNew?._id], getProfile);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [examSubjectData, setExamSubjectData] = useState<any>({});
	const [examRegisterData, setExamRegisterData] = useState<any>([]);
	const [examRegistrations, setExamRegistrations] = useState<any>([]);
	const [collegeData, setCollegeData] = useState<any>([]);
	const [showModalPayment, setShowModalPayment] = useState(false);

	useEffect(() => {
		if (data && data?._id !== 'NSF-F192725B994B-4761-9E3A-BCBAFF576695') {
			setValues(data);
		}
	}, [data]);

	const [exam, setExam] = useState<any>('');
	const handleExamSelect = async (e: any) => {
		const { name, value } = e.target;
		setExam(value);
		await getExamSubjectData();
	};

	const getExamSubjectData = async () => {
		setIsLoading(true);
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.get('/college/examProcess/getExamSubjectData', {
					params: {
						collegeId,
						userId: user.uid,
						studentId: values?._id,
						examId: exam,
					},
				})
				.then((res: any) => {
					console.log('examSubData', res.data);
					setExamSubjectData(res.data.list);

					setIsLoading(false);
					setRefresh(!refresh);
				})
				.catch((err: any) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			throw new Error('API Error Bundle Data');
		}
	};

	const getExamRegisterData = async () => {
		setIsLoading(true);
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.get('/college/examProcess/getExamRegisterData', {
					params: { collegeId, userId: user.uid, studentId: values?._id, examId: exam },
				})
				.then((res: any) => {
					console.log('examRegData', res.data);
					setExamRegisterData(res.data.list);
					setIsLoading(false);
				})
				.catch((err: any) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			throw new Error('API Error Bundle Data');
		}
	};
	// const getAllExamRegistrations = async () => {
	//   setIsLoading(true);
	//   try {
	//     const instance = await getAxiosTokenInstance();
	//     instance
	//       .get('/college/examProcess/getExamRegisterData', {
	//         params: { collegeId, userId: user.uid, studentId: values?._id, examId: exam },
	//       })
	//       .then((res: any) => {
	//         console.log('examRegData', res.data);
	//         setExamRegisterData(res.data.list);
	//         setIsLoading(false);
	//       })
	//       .catch((err: any) => {
	//         setIsLoading(false);
	//         console.log(err);
	//       });
	//   } catch (err) {
	//     throw new Error('API Error Bundle Data');
	//   }
	// };

	useEffect(() => {
		getExamRegisterData();
	}, [exam, values?._id]);

	//
	const [razorpay, setRazorpay] = useState<any>({ displayRazorpay: false });
	const [isLoadingObj, setIsLoadingObj] = useState<any>({});
	const [billAmount, setBillAmount] = useState<any>(300);

	const handlePaymentRazorpay = async () => {
		setIsLoadingObj((prev: any) => ({ ...prev, paymentLoadingRazor: false }));
		const amount: Amount = {
			amount: billAmount,
			currencyCode: 'INR',
		};
		const order = await initializePaymentRazorpay(amount, collegeId, values?._id, collegeData?.onlineFeeId);
		setRazorpay((prev: any) => ({ ...prev, order, displayRazorpay: true }));
		setIsLoadingObj((prev: any) => ({ ...prev, paymentLoading: false }));
	};

	const handleOpenModal = () => {
		setShowModalPayment(true);
	};

	const handleCloseModal = () => {
		setShowModalPayment(false);
	};

	const handleRegistration = async () => {
		const registrationData = { ...examRegisterData[0], registered: true };

		try {
			const instance = await getAxiosInstance();
			await instance
				.post('/college/examProcess/examRegistration', { registrationData })
				.then((res: any) => {
					toast.success('Registered Successfully');
				})
				.catch((error: any) => {
					console.error('error', error.message);
				});
		} catch (error: any) {
			console.error('error', error.message);
			throw new Error('Error registering data');
		}
	};
	const getAllRegistrations = async () => {
		try {
			const instance = await getAxiosInstance();
			await instance
				.get('/college/examProcess/examRegistration', { params: { collegeId } })
				.then((res: any) => {
					setExamRegistrations(res.data.list);
					console.log('egreg', res.data);
				})
				.catch((error: any) => {
					console.error('error', error.message);
				});
		} catch (error: any) {
			console.error('error', error.message);
			throw new Error('Error registering data');
		}
	};

	useEffect(() => {
		getAllRegistrations();
	}, [handleRegistration]);

	return (
		<>
			<Main title='Exam Registration' width='100vw' height='100vh'>
				<Header>
					<div>
						<select className='w- h-10 my-2 shadow rounded' name='exam' id='' onChange={handleExamSelect}>
							̦<option value=''>Choose Examination</option>
							{examData.map((exam: any) => (
								<option key={exam._id} value={exam._id}>
									{exam._id}
								</option>
							))}
						</select>
					</div>

					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Toaster />
					<div className='flex bg-gray-200'>
						<div>
							{exam ? (
								<div className='my-1 pl-1 font-bold'>
									<div className='w-full h-auto pb-2 bg-gray-200 rounded flex'>
										<div className=' p-2'>
											<div className='mb-1 flex w-50%'>
												<SearchableUserInputPhoto primoryKey={'applicationNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} collection={'users'} stateData={addNew} setStateData={setAddNew} titleData={'Search Admision No or Name'} />
											</div>

											{examRegisterData && values?._id && (
												<table>
													<thead>
														<tr>
															<th className='border-1 p-1'>SL</th>
															<th className='border-1 p-1'>CATEGORY</th>
															<th className='border-1 p-1'>SUBJECT CODE</th>
															<th className='border-1 p-1'>SUB NAME</th>
															<th className='border-1 p-1'>ATTENDANCE PERCENT</th>
														</tr>
													</thead>
													<tbody>
														{examRegisterData.length > 0 &&
															examRegisterData[0].subjectData?.map((subject: any, index: any) => (
																<tr key={index}>
																	<td className='border-1 p-1'>{index + 1}</td>
																	<td className='border-1 p-1'>{subject?.type}</td>
																	<td className='border-1 p-1'>{subject?.subjectCode}</td>
																	<td className='border-1 p-1'>{subject?.subjectName}</td>
																	<td className='border-1 p-1'>{subject?.attendancePercent}%</td>
																</tr>
															))}
													</tbody>
												</table>
											)}
										</div>
									</div>
								</div>
							) : (
								<div className=' bg-gray-200 rounded p-2 items-center'>
									<h5>Select Exam & Student to register for Exam</h5>
								</div>
							)}
						</div>
						<div>
							{examRegisterData.length > 0 && values?._id && (
								<div className='m-2 p-2 pt-4 text-center'>
									<img src={values?.photoURL} alt='photo' className='w-28 h-36 object-cover rounded' />
									<p className='mt-1 text-center'>{values?.name}</p>
									{['COL-08B6B8276F75-46EE-A69C-77177AAA488B'].includes(collegeId) ? (
										<div className='z-30'>
											<button onClick={handleOpenModal} className='mt-6 w-full bg-cyan-600 text-white py-3 px-6 rounded hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-300 focus:ring-opacity-50 transition duration-150 ease-in-out text-md'>
												View Payment QR Code
											</button>
											<p className='text-sm mt-4 text-gray-700'>Please note: After successfully making your payment, kindly send a screenshot of the payment confirmation along with your application number and name to the WhatsApp number 8281128840. Allow up to 1 hour for processing time. After this period, please check the payment status on our site. We appreciate your cooperation. Thank you!</p>
										</div>
									) : (
										// <button onClick={handlePaymentRazorpay} className="mt-2 w-full bg-cyan-600 text-white py-3 px-6 rounded hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-300 focus:ring-opacity-50 transition duration-150 ease-in-out text-md">
										//   Pay Application Fee ₹{billAmount}/-
										// </button>
										<button onClick={handleRegistration} className='mt-2 w-full bg-cyan-600 text-white py-3 px-6 rounded hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-300 focus:ring-opacity-50 transition duration-150 ease-in-out text-md'>
											Register
										</button>
									)}
								</div>
							)}
						</div>
					</div>

					{!values?.examFeeAmount?.recieved && razorpay?.displayRazorpay && <RenderRazorpay userId={values?.uid} refech={refetch} collegeId={collegeId} accountId={collegeData?.onlineFeeId} name={values?.name} billAmount={billAmount} amount={razorpay?.order?.amount} currency={razorpay?.order?.currency} orderId={razorpay?.order?.id} keyId={`${collegeData?.paymentGateway?.key_id}`} keySecret={`${collegeData?.paymentGateway?.key_secret}`} handleClickbackButton={{}} data={{}} />}
					{examRegistrations && (
						<div className='my-2'>
							<Table width='100%' innerWidth={['12%', '25%']}>
								<Thead>
									<Tr>
										<Th position={0}>SL</Th>
										<Th position={0}>Hall Ticket No</Th>
										<Th position={0}>Name</Th>
										<Th position={0}>Type</Th>
										<Th position={0}>Semester</Th>
										<Th position={0}>Amount</Th>
										<Th position={1}>Subjects</Th>
									</Tr>
								</Thead>
								<Tbody height='auto'>
									{examRegistrations.map((regData: any, index: number) => (
										<Tr key={index}>
											<Td index={index} position={0}>
												{index + 1}
											</Td>
											<Td index={index} position={0}>
												{regData.hallTicket}
											</Td>
											<Td index={index} position={0}>
												{regData.name}
											</Td>
											<Td index={index} position={0}>
												{regData.type}
											</Td>
											<Td index={index} position={0}>
												{regData.semester}
											</Td>
											<Td index={index} position={0}>
												{' '}
												{/* {regData.amount} */}
											</Td>
											<Td index={index} position={1}>
												{regData?.subjectData && (
													<>
														{Object.entries(regData?.subjectData).map(([key, subject]: any, index: any) => (
															<p className='leading-3'>{subject.subjectName}</p>
															// <ul key={index}>
															//   <li className="border-1 p-1">{index + 1}</li>
															//   <li className="border-1 p-1">{key}</li>
															//   <li className="border-1 p-1">
															//     {subject.subjectId}
															//   </li>
															//   <li className="border-1 p-1">
															//     {subject.subjectName}
															//   </li>
															//   <li className="border-1 p-1">
															//     {subject.attendencePercent}
															//   </li>
															// </ul>
														))}
													</>
												)}
												''
											</Td>
										</Tr>
									))}
									<Tr>
										<Td index={1}>1</Td>
									</Tr>
								</Tbody>
							</Table>
						</div>
					)}
				</Body>
			</Main>
		</>
	);
}

export default ExamRegistration;
