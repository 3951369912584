import React, { useContext, useEffect, useState } from 'react';
import { DegreeSubjectInputSet, RenderFilePreview, SectionHeader, SubjectInputSet, degree, getClassName, plustwo, renderFilePreview } from '../Form/Component';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import { uploadFileToFirebase } from '../../NecttosComp/APICaller/APICaller';
import { getCollegeDetails } from '../Form/Register';
import { useQuery } from 'react-query';

function BasicCertificateVerificationPG({ showWindow, selClass, refectd, date, setDate }) {
	const [dropDowns, setDropDowns] = useState([{}]);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const { collegeId, user } = useContext(FirebaseContext);
	const [diclarationFinal, setDiclarationFinal] = useState(false);
	console.log({ data: date.details });
	const [notEligible, setNotEligible] = useState(date?.details?.notEligible);
	const { data: userDetail } = useProfileData(user?.uid, false);
	const [values, setValues] = useState({});
	const [indexMarks, setIndexMarks] = useState({});
	const [indexData, setIndexData] = useState({});
	const certificateBonusOptions = dropDowns?.[21]?.bonusCertificate || [];
	const { data: classes } = useQuery([`collegeDdddataddd`, collegeId, '2024-2026'], getCollegeDetails);
	console.log({ values });

	useEffect(() => {
		setNotEligible(date?.details?.notEligible);
	}, [date?.details?.notEligible]);

	useEffect(() => {
		setIndexMarks(date?.details?.customIndexMarks);
	}, [date?.details?.customIndexMarks]);

	useEffect(() => {
		if (date && date.details) {
			const newValue = {
				certificates: date?.details?.certificates,
				degreeInstitutionName: date?.details?.degreeInstitutionName,
				degreeRegisterNo: date?.details?.degreeRegisterNo,
				degreeUniversity: date?.details?.degreeUniversity,
				degreeCourse: date?.details?.degreeCourse,
				degreeSpecialisation: date?.details?.degreeSpecialisation,
				preferedCourse1: date?.details?.preferedCourse1,
				preferedCourse2: date?.details?.preferedCourse2,
				preferedCourse3: date?.details?.preferedCourse3,
				degreeMarkSystem: date?.details?.degreeMarkSystem,
				degreeScheme: date?.details?.degreeScheme,
				degreeInstState: date?.details?.degreeInstState,
				degreeYearOfPass: date?.details?.degreeYearOfPass,
				degreeNoOfChance: date?.details?.degreeNoOfChance,
				isBonusMark: date?.details?.isBonusMark,
				subject1Name: date?.details?.subject1Name,
				subject1MarksAwardedCCPA: date?.details?.subject1MarksAwardedCCPA,
				subject1MaximumMarksCredit: date?.details?.subject1MaximumMarksCredit,
				subject2Name: date?.details?.subject2Name,
				subject2MarksAwardedCCPA: date?.details?.subject2MarksAwardedCCPA,
				subject2MaximumMarksCredit: date?.details?.subject2MaximumMarksCredit,
				subject3Name: date?.details?.subject3Name,
				subject3MarksAwardedCCPA: date?.details?.subject3MarksAwardedCCPA,
				subject3MaximumMarksCredit: date?.details?.subject3MaximumMarksCredit,
				subject32Name: date?.details?.subject32Name,
				subject32MarksAwardedCCPA: date?.details?.subject32MarksAwardedCCPA,
				subject32MaximumMarksCredit: date?.details?.subject32MaximumMarksCredit,
				subject33Name: date?.details?.subject33Name,
				subject33MarksAwardedCCPA: date?.details?.subject33MarksAwardedCCPA,
				subject33MaximumMarksCredit: date?.details?.subject33MaximumMarksCredit,
				subject4Name: date?.details?.subject4Name,
				subject4MarksAwardedCCPA: date?.details?.subject4MarksAwardedCCPA,
				subject4MaximumMarksCredit: date?.details?.subject4MaximumMarksCredit,
				subject5Name: date?.details?.subject5Name,
				subject5MarksAwardedCCPA: date?.details?.subject5MarksAwardedCCPA,
				subject5MaximumMarksCredit: date?.details?.subject5MaximumMarksCredit,
				subject6Name: date?.details?.subject6Name,
				subject6MarksAwardedCCPA: date?.details?.subject6MarksAwardedCCPA,
				subject6MaximumMarksCredit: date?.details?.subject6MaximumMarksCredit,
				subject7Name: date?.details?.subject7Name,
				subject7MarksAwardedCCPA: date?.details?.subject7MarksAwardedCCPA,
				subject7MaximumMarksCredit: date?.details?.subject7MaximumMarksCredit,
				subject8Name: date?.details?.subject8Name,
				subject8MarksAwardedCCPA: date?.details?.subject8MarksAwardedCCPA,
				subject8MaximumMarksCredit: date?.details?.subject8MaximumMarksCredit,
				subject9Name: date?.details?.subject9Name,
				subject9MarksAwardedCCPA: date?.details?.subject9MarksAwardedCCPA,
				subject9MaximumMarksCredit: date?.details?.subject9MaximumMarksCredit,
				degreeTotalMarkObtained: date?.details?.degreeTotalMarkObtained,
				degreeTotalMarks: date?.details?.degreeTotalMarks,
			};
			setValues((prevValues) => ({ ...prevValues, ...newValue }));
			setIndexData(date?.details);
		}
	}, [date?.details]);

	console.log(date?.details);

	const [uploadProgress, setUploadProgress] = useState();
	const [isLoadingObj, setIsLoadingObj] = useState({});
	const [documentBonafiedKey, setBonafied] = useState([]);

	const toFixedTwo = (value) => {
		if (typeof value === 'number') {
			return value.toFixed(2);
		}
		const parsed = parseFloat(value);
		return isNaN(parsed) ? 'N/A' : parsed.toFixed(2);
	};

	const bonusCertificate = ['degree_marksheet', 'ex_military_certificate', 'ncc_a_certificate', 'ncc_b_certificate', 'nss_certificate', 'scouts_guides_certificate', 'student_police_cadet_certificate'];

	if (!showWindow) {
		return null;
	}

	console.log({ values });

	const handleCheckboxChange = (event) => {
		setValues({
			...values,
			certificates: {
				...values?.certificates,
				[event.target.name]: event.target.checked,
			},
		});
	};

	const addNewForm = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/markCorrection', {
				studentId: date?.details?._id,
				collegeId,
				values: { ...values, notEligible, customIndexMarks: indexMarks },
				userId: user?.uid,
			});
			if (resp?.data?.statusCode === 200) {
				setDate('');
				refectd();
			} else {
				setDate('');
				refectd();
			}
		} catch (error) {
			setDate('');
			refectd();
		}
	};
	const handleFileChange = (event, documentId) => {
		const files = event.target.files;
		if (files && files[0]) {
			setIsLoadingObj((prev) => ({ ...prev, [documentId]: true }));
			setUploadProgress((prev) => ({ ...prev, [documentId]: 0 }));
			(async () => {
				try {
					const { url, fileId } = await uploadFileToFirebase(files[0], values?._id, documentId, (progress) => {
						setUploadProgress((prev) => ({ ...prev, [documentId]: progress }));
					});
					setValues((prev) => ({
						...prev,
						certificates: {
							...prev.certificates,
							[documentId]: url,
						},
					}));
					setUploadProgress((prev) => ({ ...prev, [documentId]: 100 }));
					setIsLoadingObj((prev) => ({ ...prev, [documentId]: false }));
				} catch (error) {
					console.error('Error uploading file:', error);
					setUploadProgress((prev) => ({ ...prev, [documentId]: 0 }));
					setIsLoadingObj((prev) => ({ ...prev, [documentId]: false }));
				}
			})();
		} else {
			setIsLoadingObj((prev) => ({ ...prev, [documentId]: false }));
		}
	};

	const recalculate = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/calculateIndexMarkPG', {
				studentId: date?.details?._id,
				collegeId,
				values: { certificates: date?.details?.certificates, ...values, notEligible },
				classId: selClass,
				userId: user?.uid,
			});
			if (resp?.data?.statusCode === 200) {
				setIndexData(resp?.data.list);
			} else {
			}
		} catch (error) {}
	};
	const certificateOptions = dropDowns?.[21]?.certificatesPG || [];

	return (
		<>
			<Main width='100%' height='100%'>
				<div className='flex justify-between items-center mb-4'>
					<h2 className='text-xl font-semibold'>Certificate and Marks</h2>

					<button onClick={() => setDate({ ...date, showBasicCertificatePg: false })} className='text-sm bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-3 rounded'>
						Close
					</button>
				</div>
				<div className='flex flex-col lg:flex-row overflow-x-auto'>
					<div className='bg-white w-2/3 '>
						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
							<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
								<label className='block text-sm font-medium text-gray-700'>Upload Degree Certificate *</label>
								<input type='file' onChange={(event) => handleFileChange(event, `degree_marksheetURL`)} accept='image/*' className='file-input' />
							</div>
						</div>
						{values?.isBonusMark === 'Yes' && (
							<>
								<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
									{certificateBonusOptions?.map((certificate, index) => (
										<React.Fragment key={index}>
											{values?.certificates?.[certificate?.key] && (
												<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
													{isLoadingObj[`${certificate?.key}URL`] ? (
														<div className='flex justify-center items-center h-full'>
															<progress value={uploadProgress[`${certificate?.key}URL`] || 0} max='100'></progress>
															<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
															<p className='text-gray-700 text-lg mt-2'>Uploading Image...{uploadProgress[`${certificate?.key}URL`].toFixed(0)}%</p>
														</div>
													) : (
														<>
															<label className='block text-sm font-medium text-gray-700'>Upload {certificate?.title} *</label>
															<input type='file' onChange={(event) => handleFileChange(event, `${certificate?.key}URL`)} accept='image/*' className='file-input' />
														</>
													)}
												</div>
											)}
										</React.Fragment>
									))}
								</div>
							</>
						)}
						<div className='w-full bg-white shadow rounded p-4'>
							<h1 className='text-lg font-semibold mb-4 self-center text-red-600'> Name of student: {date?.details?.name}</h1>
							<h3 className='text-lg font-semibold mb-4'>Certificate</h3>

							{bonusCertificate?.map((certificate, index) => (
								<React.Fragment key={index}>
									{values?.certificates?.[certificate] && (
										<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
											<label className='block text-sm font-medium text-gray-700'> {certificate} </label>
											{values?.certificates?.[certificate + 'URL'] && <RenderFilePreview fileURL={values?.certificates?.[certificate + 'URL']} />}
										</div>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
					<div className=' w-1/3 p-3'>
						<div className='w-full bg-white shadow-lg rounded-lg p-4'>
							<h3 className='text-lg font-semibold mb-4 text-gray-800'>Degree Mark List</h3>
							<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2'>
								<Input width='100%' fieldName='Institution Name *' returnKey='degreeInstitutionName' state={values} setState={setValues} type='text' />
								<Input width='100%' fieldName='Register No *' returnKey='degreeRegisterNo' state={values} setState={setValues} type='text' />
								<Input width='100%' fieldName='Name of University *' returnKey='degreeUniversity' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.University} type='drop' />
								<Input width='100%' fieldName='Programme Stream *' returnKey='degreeCourse' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.Course} type='drop' />
								<Input width='100%' fieldName='Specialisation *' returnKey='degreeSpecialisation' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.Specialisation} type='drop' />
								<Input width='100%' fieldName='Mark System *' returnKey='degreeMarkSystem' state={values} setState={setValues} options={['CBCSS Pattern 2009 (CGPA out of 4)', 'Pre CBCSS Pattern', 'Conventional Mark System', 'CBCSS Pattern 2013/17 (CCPA out of 10)', 'Others']} type='drop' />
								<Input width='100%' fieldName='Scheme of the Degree *' returnKey='degreeScheme' state={values} setState={setValues} options={['Single Main', 'Double Main', 'Triple Main']} type='drop' />
								<Input width='100%' fieldName='Intituition State *' returnKey='degreeInstState' state={values} setState={setValues} options={['Kerala', 'Outside Kerala', 'Outside India']} type='drop' />
								<Input width='100%' fieldName='Year of Pass *' returnKey='degreeYearOfPass' state={values} setState={setValues} type='text' />
								<Input width='100%' fieldName='No of Chances  *' returnKey='degreeNoOfChance' state={values} setState={setValues} options={['1', '2', '3']} type='drop' />
								<Input width='100%' fieldName='Are You Eligible for Bonus Marks? *' returnKey='isBonusMark' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />
							</div>
							{values?.isBonusMark === 'Yes' && (
								<>
									<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 bg-gray-200 p-6'>
										{certificateBonusOptions?.map((certificate, index) => (
											<label key={index} className='flex items-center space-x-3'>
												<input type='checkbox' name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className='form-checkbox h-5 w-5' />
												<span className='text-gray-700'>{certificate?.title}</span>
											</label>
										))}
									</div>
								</>
							)}

							<p>
								<abbr title='Marks Awarded'>MA/CCPA</abbr> = Marks Awarded or Cumilative Credit Point Average
							</p>
							<p>
								<abbr title='Maximum Marks'>MM/CR</abbr> = Maximum Marks or Credits
							</p>
							<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
								{degree?.map((terms, i) => (
									<DegreeSubjectInputSet fieldName={terms?.fieldName} positionIndex={i} returnKey={terms.returnKey} state={values} setState={setValues} options={terms?.options || []} />
								))}
							</div>
							<input
								type='checkbox'
								name='diclaration'
								checked={notEligible?.[selClass]}
								onChange={() => {
									setNotEligible({ ...notEligible, [selClass]: !notEligible?.[selClass] });
								}}
								className='form-checkbox h-[30px] w-[30px]'
							/>

							<span className='text-gray-700'>This Student Not Eligible for this course</span>
							{/* 
              <div className="p-4 bg-white shadow rounded-lg">
                <Button
                  type="save"
                  onClick={() => {
                    recalculate();
                  }}
                >
                  Re Calculate
                </Button>
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Index Mark Calculation Details</h2>
                <table className="w-full text-sm text-left text-gray-700">
                  <tbody>
                    <tr>
                      <td className="px-4 py-2  bg-green-100 font-semibold text-lg">Particulars:</td>
                      <td className="px-4 py-2 bg-green-100 font-bold text-lg">Actual Mark</td>
                      <td className="px-4 py-2 bg-green-100 font-bold text-lg">Final Index Mark</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Total Possible:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.totalPossible}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.totalPossibleCon}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Total Obtained:</td>
                      <td className="px-4 py-2  bg-gray-50 font-bold text-lg">{indexData?.totalObtained?.toFixed(2)}</td>
                      <td className="px-4 py-2  bg-gray-50 font-bold text-lg">+{indexData?.totalObtainedCon?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Add Additional Subjects:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.indexMarkOfAddSub?.toFixed(2)}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.indexMarkOfAddSubCon?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Add Second Additional Subjects:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.indexMarkOfAddSub2?.toFixed(2)}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.indexMarkOfAddSubCon2?.toFixed(2)}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50  font-semibold text-lg">Bonus Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.bonusMark}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Handicap Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">-{indexData?.handicapedMark}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Index Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{toFixedTwo(indexData?.earnedMark)}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
							<div className='p-4 bg-white shadow rounded-lg'>
								<Button
									type='save'
									onClick={() => {
										recalculate();
									}}>
									Re Calculate
								</Button>
								<h2 className='text-lg font-semibold text-gray-800 mb-4'>Index Mark Calculation Details</h2>

								<table className='w-full text-sm text-left text-gray-700'>
									<tbody>
										<tr className='bg-gray-50'>
											<td className='px-4 py-2 bg-gray-50 font-semibold text-lg'>Index Mark:</td>
											<td className='px-4 py-2 bg-gray-50 font-bold text-lg'></td>
											<td className='px-4 py-2 bg-gray-50 font-bold text-lg'>{toFixedTwo(indexData?.earnedMark)}</td>
										</tr>
									</tbody>
								</table>
								<Input width='100%' fieldName='Custom Index Insert' returnKey={selClass} state={indexMarks} setState={setIndexMarks} type='text' />
							</div>
							<div className='bg-white p-6 rounded-lg shadow-lg'>
								<div className='flex items-center space-x-4'>
									<img src={userDetail?.photoURL} alt={userDetail?.name} className='w-16 h-16 rounded-full' />
									<span className='font-semibold'>{userDetail?.name}</span>
								</div>
								<div className='mt-4 text-gray-600'>
									<p className='text-sm'>I hereby certify that the marks detailed above have been accurately verified against the Certificate / Mark List provided. I understand that any discrepancies may lead to disciplinary actions or the revocation of any benefits derived from these results.</p>
								</div>

								<Input width='100%' fieldName='Further Details (Notes)' returnKey='details' state={values} setState={setValues} type='text' />

								<label className='flex items-center space-x-3'>
									<input
										type='checkbox'
										name='diclaration'
										checked={diclarationFinal}
										onChange={() => {
											setDiclarationFinal(!diclarationFinal);
										}}
										className='form-checkbox h-[30px] w-[30px]'
									/>
									<span className='text-gray-700'>I Verified</span>
								</label>
								<Button
									type='save'
									disabled={!diclarationFinal}
									onClick={() => {
										addNewForm();
									}}>
									Submit Verified Details
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Main>
		</>
	);
}

export default BasicCertificateVerificationPG;
