import React from 'react';
import classNames from './detailView.module.scss';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import LoadingAnimation from '../LoadingAnimation';
import StudentAttendance from './StudentAttendance';
import CulturalActivities from './CulturalActivities';

function LibraryStudentDetails({ uid, isStudent }) {
	const { data: userDetail, status } = useProfileData(uid, !isStudent);

	if (!uid) {
		return (
			<div className={classNames.detailView}>
				<div className={classNames.selectmessage}>Select A User First</div>
			</div>
		);
	}

	return (
		<div className={classNames.detailView}>
			{status === 'loading' && <LoadingAnimation dark />}
			{status === 'success' && (
				<>
					<div className={classNames.main}>
						<img src={userDetail?.photoURL || 'https://image.flaticon.com/icons/png/512/149/149071.png'} alt='' className={classNames.dp} />
						<div className={classNames.title}>{userDetail?.name}</div>
						<div className={classNames.title2}>Profile Status : {userDetail?.percentageOfCompletion}% Completed</div>
					</div>

					<div className={classNames.dataWraper}>
						{userDetail?.groupedData?.map((group) => (
							<>
								<div className={classNames.subTitle}>{group?.header}</div>

								{group?.data?.map((item) =>
									item ? (
										<div className={classNames.detailItem}>
											<span className={classNames.label}>{item?.title}</span>:<span className={classNames.value}>{item?.value}</span>
										</div>
									) : (
										''
									),
								)}
							</>
						))}

						{isStudent && (
							<>
								<StudentAttendance uid={uid} />
								<CulturalActivities activities={userDetail?.activities} />
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}

export default LibraryStudentDetails;
