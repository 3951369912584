import React, { useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useQuery } from 'react-query';
import classNames from './editBook.module.scss';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { getBookDetailsData } from '../../../../NecttosComp/APICaller/ApiServices';

function ViewBookDetails({ onCloseModal, stockId }) {
	const { data } = useQuery(['stockSataIndex'], getBookDetailsData(stockId));
	let bookDetails = data;
	console.log({ bookDetails });

	return (
		<>
			<div className={classNames.addUserForm}>
				<div className={classNames.head}>
					<span className={classNames.textHead}>Basic Details of Book</span>
					<div className={classNames.closeHead} onClick={onCloseModal}>
						{' '}
						<div className={classNames.btnClear}>X</div>
					</div>
				</div>

				<div className={classNames.masterDiv}>
					<Scrollbars className={classNames.mainDiv}>
						<img style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 12, width: '30%', height: '30%', backgroundColor: '#012536', borderRadius: 10 }} src={bookDetails?.image} />

						{bookDetails?.nameOfBook && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Title of the Book</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.nameOfBook}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.nameOfAuthor && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Name of Authors</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>
										{bookDetails?.nameOfAuthor} [{bookDetails?.authorType}], {bookDetails?.nameOfAuthor2} [{bookDetails?.authorType2}], {bookDetails?.nameOfAuthor3} [{bookDetails?.authorType3}]
									</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.yearOfPublication && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Year of Publication</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.yearOfPublication}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.nameOfPublisher && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Name of Publication</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.nameOfPublisher}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.placeOfPublication && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Place of Publication</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.placeOfPublication}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.edition && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Edition Statement</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.edition}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.category && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Category By</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.category}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.subject && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Subject Includes</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.subject}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.volume && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Volume Number</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.volume}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.issue && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Issue Number</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.issue}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.price && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Price of the Book</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>
										{bookDetails?.price} [{bookDetails?.discount}]
									</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.callNo && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Call Number</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.callNo}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.accessionNumber && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Accession Number</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.accessionNumber}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.isbn && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>ISBN</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.isbn}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.bookDetails && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Book Summery</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.bookDetails}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.pages && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Pages</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.pages}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.language && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Language</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.language}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.series && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Series</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.series}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.volume && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Volume</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.volume}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.issue && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Issue</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.issue}</label>{' '}
								</div>
							</div>
						)}
						{bookDetails?.ddcSubject && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>DDC Subject</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>
										[{bookDetails.ddcNumber}] {bookDetails?.ddcSubject}
									</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.fund && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Fund</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.fund}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.invoiceNumber && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>InvoiceNumber</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.invoiceNumber}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.date && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Date</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.date}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.vendor && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Vendor</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.vendor}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.status && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Status</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.status}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.issue && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Issue</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.issue}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.category && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>Category</label>
								<div className={classNames.subMatter}>
									{' '}
									<label>{bookDetails?.category}</label>{' '}
								</div>
							</div>
						)}

						{bookDetails?.bookDetails && (
							<div className={classNames.subDiv}>
								<label className={classNames.subTitle}>BookDetails</label>
								<div className={classNames.subMatter}>
									{' '}
									<textarea value={bookDetails?.bookDetails}></textarea>{' '}
								</div>
							</div>
						)}
					</Scrollbars>

					<Scrollbars className={classNames.mainDiv}>
						<div className={classNames.subDiv}>
							<label className={classNames.subTitle}>Contents</label>
							<textarea value={bookDetails?.contents} style={{ height: '700px' }}></textarea>
						</div>
					</Scrollbars>
				</div>
			</div>
		</>
	);
}

export default ViewBookDetails;
