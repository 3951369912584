import React, { useContext, useEffect, useState } from 'react';
import classNames from './detailView.module.scss';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { useDropDowns, useProfileData } from '../../../NecttosComp/APICaller/queryHooks';
import CustomInput from '../../../myComp/CustomInput';
import Scrollbars from 'react-custom-scrollbars';
import CustomDropDown from '../../../myComp/CustomDropDown';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { Button, message } from 'antd';
import { getAdmissionValidator } from '../../../NecttosComp/APICaller/ApiServices';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import ViewCertificates from '../ViewCertificates';
import ModalWrapper from '../../../components/ModalWrapper';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';

function ViewStudentData({ uid, onClose }) {
	const { collegeId } = useContext(FirebaseContext);
	const { data: userDetail, status, refetch } = useProfileData(uid, true);
	let memberData = userDetail;

	const [state, setState] = useState({});
	const { data: dropDown } = useDropDowns('eGrantz');

	useEffect(() => {
		let eGrantzId = '';
		let eGrantzIdval = '';
		let eGrantz = false;
		dropDown?.[collegeId]?.forEach((item) => {
			if (item?._id === userDetail?.eGrantz?._id) {
				eGrantzIdval = item?.type;
				eGrantzId = item?._id;
				eGrantz = true;
			}
		});

		userDetail?.collegeRoles?.forEach((element) => {
			if (element.type === 'student') {
				setState({
					...state,
					classId: element?.classId,
					index: userDetail?.index,
					admType: userDetail?.admType,
					emergency: userDetail?.emergency,
					reason: userDetail?.reason,
					remarks: userDetail?.remarks,
					eGrantzIdval,
					eGrantzId,
					eGrantz,
					...userDetail?.certificates,
				});
			}
		});
	}, [userDetail, dropDown]);

	if (!uid) {
		return (
			<div className={classNames.detailView}>
				<div className={classNames.selectmessage}>Select A User First</div>
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			</div>
		);
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<Scrollbars>
					<div className={classNames.detailView}>
						{status === 'loading' && <LoadingAnimation dark />}
						{status === 'success' && (
							<>
								<div style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
									<div className={classNames.dataWraper} style={{ width: '22%' }}>
										<img
											src={userDetail?.photoURL}
											style={{
												border: '3px solid navy',
												borderRadius: '10%',
												width: '90%',
												margin: 20,
											}}
										/>

										<div className={classNames.typoContainer}>
											<h5 className={classNames.userName}>{memberData?.name}</h5>
										</div>
										{memberData?.sslcRegisterNo && (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													SSLC Qualification Details
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Register No.</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcRegisterNo}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Percentage</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcPercentage}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Board</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcBoard}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>N. of Chance </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcNoOfChance}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Year of Pass</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcYearOfPass}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Stream</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.sslcYearOfPass}</span>{' '}
												</div>
											</>
										)}
										{memberData?.plusTwoRegisterNo && (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Plus Two Qualification Details
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Register No.</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoRegisterNo}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Percentage</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoPercentage}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Board</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoBoard}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>No. of Chance </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoNoOfChance}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Year of Pass</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoYearOfPass}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Stream</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.plusTwoStream}</span>{' '}
												</div>
											</>
										)}
										{memberData?.degreeRegisterNo && (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Degree Qualification Details
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Register Number</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreeRegisterNo}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Percentage</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreePercentage}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Course </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreeCourse}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Specialisation</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreeSpecialisation}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>University</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreeUniversity}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Year of Pass</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.degreeYearOfPass}</span>{' '}
												</div>
											</>
										)}
									</div>
									<div className={classNames.dataWraper} style={{ margin: 5, width: '28%' }}>
										<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
											Basic Details
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Name</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.name}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Date of Birth</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.dob}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Gender</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.gender}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Blood Group</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.bloodGroup}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Religion</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.religion}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>R.Category</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.category}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Caste</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.caste}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>M. Status</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.meritalStatus}</span>{' '}
										</div>
										<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
											Official Details
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Index Mark.</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.index}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Phone No.</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{userDetail?.phoneNumber}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Anual Income</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.annualIncome}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Type Stay</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.stay}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Cap ID</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.capId}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Aadhar</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.aadhaarNumber}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Disability</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.disabilities}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Ration Card</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.provertyLine}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>S.Language</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.secondLanguage}</span>{' '}
										</div>
										<div className={classNames.detailItem}>
											{' '}
											<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Admitted to</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{userDetail?.admType}</span>{' '}
										</div>
										{memberData?.houseName && (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Local Address
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>House</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.houseName}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Post</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.postOffice}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Place </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.place}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>District</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.district}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>State</span>:{' '}
													<span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>
														{memberData?.state} - {memberData?.pinCode}
													</span>{' '}
												</div>
											</>
										)}
										{memberData?.permanentHouseName && (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Permenent Address
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>House</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.permanentHouseName}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Post</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.permanentPostOffice}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Place </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.permanentPlace}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>District</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{memberData?.permanentDistrict}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>State</span>:{' '}
													<span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>
														{memberData?.permanentState} - {memberData?.permanentPinCode}
													</span>{' '}
												</div>
											</>
										)}
									</div>
									<div className={classNames.dataWraper} style={{ margin: 5, width: '26%' }}>
										{memberData?.travelDetails.map((x) => (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Travel Details - {x?.typeOfVehicle}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Start</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x?.travelStart}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>End </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x.travelTo}</span>{' '}
												</div>
											</>
										))}
										{memberData?.family.map((x) => (
											<>
												<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
													Family Details - {x.relation}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Name</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x?.name}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Income</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x?.income}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Qualification </span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x.qualification}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Occupation</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x.job}</span>{' '}
												</div>
												<div className={classNames.detailItem}>
													{' '}
													<span style={{ color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }}>Mobile</span>: <span style={{ marginLeft: 12, color: 'navy', fontSize: 20 }}>{x?.mobile}</span>{' '}
												</div>
											</>
										))}
										<div className={classNames.group} style={{ width: 200, height: 70 }}>
											<label>Remarks</label>
											<textarea value={state?.remarks} onChange={(e) => setState({ ...state, remarks: e.target.value })} />
										</div>
									</div>
									<div className={classNames.dataWraper} style={{ margin: 5, width: '22%' }}>
										<>
											<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
												Check Certificates Attached{' '}
											</div>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.admitCard} /> Admit Card{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.sslc} /> SSLC Certificate{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.plusTwo} /> Plus Two Certificate{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.degree} /> Degree Certificate{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.tc} /> TC{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.cc} /> CC{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.pwdLwdpTlm} /> PwD,LWDP,TLM{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.community} /> Community{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.nativity} /> Nativity{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.income} /> Income/Non-Creamy Layer{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.ews} /> EWS{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.nss} /> NSS{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.ncc} /> NCC{' '}
											</label>
											<label style={{ fontSize: 20, fontFamily: 'bold', color: '#004518', margin: 20 }}>
												{' '}
												<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={state.mandatory} /> Mandatory Fee Paid{' '}
											</label>

											<div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>
												Course Details
											</div>

											<label style={{ fontSize: 40, fontFamily: 'bold', color: 'red', margin: 20, alignSelf: 'center' }}>
												<input type='checkbox' style={{ height: 30, width: 30, marginRight: 30 }} defaultChecked={state.eGrantz} />
												Sanctioning E-Grantz
											</label>
										</>
									</div>
								</div>
							</>
						)}
					</div>
				</Scrollbars>
			</div>
			{state.certificatesView && (
				<ModalWrapper>
					<ViewCertificates data={memberData?.documents} stateData={state} onClose={() => setState({ ...state, certificatesView: false })} setStateData={setState} />
				</ModalWrapper>
			)}
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
}

export default ViewStudentData;
