import { useEffect } from 'react';
import {useLocation} from "react-router-dom"

function RedirectToExternalUrl() {
    const location = useLocation()
    useEffect(() => {
      console.log(location.pathname)
      if(location.pathname==="/refund-policy"){
        window.location.href = "https://merchant.razorpay.com/policy/NGpfjTcNQxRBbM/refund";
      }else if(location.pathname==="/terms-and-conditions"){
        window.location.href = "https://merchant.razorpay.com/policy/NGpfjTcNQxRBbM/terms";
      }else if(location.pathname==="/contact-us"){
        window.location.href = "https://merchant.razorpay.com/policy/NGpfjTcNQxRBbM/contact_us";
      }else if(location.pathname==="/privacy-policy"){
        window.location.href = "https://merchant.razorpay.com/policy/NGpfjTcNQxRBbM/privacy";
      }
    }, []);

    return null; // or you can return a loader or any placeholder content
}

export default RedirectToExternalUrl;
