import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';

function C3_5_1({ val, onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// naacFetchData({
		//   data: { collegeId, academicYear },
		//   setLoading,
		//   setValues,
		//   url: "/college/NAAC/getNAAC_C3_5_1",
		// });
	}, []);
	return (
		<>
			<div className='  z-10 w-4/5 bg-slate-100 h-4/5 p-3'>
				<table className='table'>
					<thead>
						<th colSpan={8} style={{ background: 'rgb(241 245 249)' }}>
							<h1 className='text-xl flex'>
								3.5.1 Number of Collaborative activities for research, Faculty exchange, Student exchange/ internship during the year
								<div className='flex'>
									<AcademicYears setAcademicYear={setAcademicYear} />
									<button
										className='bg-green-500 text-sm'
										onClick={() =>
											naacExcelGenerator({
												data: { collegeId, academicYear: '2022-23' },
												url: '/college/NAAC/getNAAC_C2_7',
												setLoading,
											})
										}>
										Dowload
									</button>
									<button className='closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]' onClick={onClose}>
										X
									</button>
								</div>
							</h1>
						</th>

						<tr>
							<th className='w-1/4'>Sl. No.</th>
							<th className='w-1/4'>Title of the collaborative activity</th>
							<th className='w-1/4'>Name of the collaborating agency with contact details</th>
							<th className='w-1/4'>Name of the participant </th>
							<th className='w-1/4'>Year of collaboration</th>
							<th className='w-1/4'>Duration</th>
							<th className='w-1/4'>Nature of the activity</th>
							<th className='w-1/4'>Link to the relavant document</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
}

export default C3_5_1;
