import React, { useContext, useEffect, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import toast, { Toaster } from 'react-hot-toast';
import { uploadPhotoToFirebase } from '../../NecttosComp/APICaller/APICaller';
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';

function TagOrder({ onClose }: { onClose: () => void }) {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	let collegeData = collegeList.find((x: any) => x._id === collegeId);
	console.log({ collegeData });
	const [unitPrice, setUnitPrice] = useState<any>('');
	const [addNew, setAddNew] = useState(false);
	useEffect(() => {
		if (collegeId == 'COL-D209492D4FB7-4E07-848E-A317A1BC9196' || collegeId == 'COL-3FAA19D25E23-4558-AE0C-D89C4D37D418') {
			setUnitPrice(92.8);
		} else if (collegeId == 'COL-4E56593776BE-41C3-982A-0B6DC20E44A0') {
			setUnitPrice(80);
		} else if (collegeId == 'COL-BB2631888FF3-4B9E-8DD2-59FF3CA88846') {
			setUnitPrice(80);
		} else if (collegeId == 'COL-9E8AF41AB347-4BD6-8A1D-43D02D39738E') {
			setUnitPrice(90);
		} else if (collegeId == 'COL-E67980529419-4EFA-BAF8-ABD89D79284D') {
			setUnitPrice(90);
		} else if (collegeId == 'COL-5F9623C234F8-45AA-A2A3-C5F5734E1F77' || collegeId == 'COL-A611B639AA92-4288-81DB-BA777E497A59') {
			setUnitPrice(85);
		} else if (collegeId == 'COL-BB271658898E-4DE0-BC30-B58B89594F80') {
			setUnitPrice(110);
		} else {
			setUnitPrice(110);
		}
	}, [collegeId, addNew]);

	useEffect(() => {
		setPrice(unitPrice);
	}, [unitPrice]);
	// const initialState = {
	// 	tieLogo: '',
	// 	text: collegeData?.collegeName || 'Default College Name',
	// 	count: 3,
	// 	textColor: 'white',
	// 	gradientColors: ['#000000'],
	// 	quantity: 1,
	// 	price: ,
	// 	get amount() {
	// 		return this.quantity * this.price;
	// 	},
	// };

	const [textColor, setTextColor] = useState('white');
	const [orderName, setOrderName] = useState('Order-' + new Date().toISOString());
	const [orderData, setOrderData] = useState<any>([]);
	const [quantity, setQuantity] = useState(1);
	const [count, setCount] = useState(3);
	const [price, setPrice] = useState(unitPrice);
	const [amount, setAmount] = useState(110);
	const [view, setView] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [tieLogo, setLogo] = useState('');
	const [id, setId] = useState('');
	const [text, setText] = useState(collegeData?.collegeName);
	const [colorCount, setColorCount] = useState(1);
	const [gradientColors, setGradientColors] = useState(Array(colorCount).fill('#000000'));

	useEffect(() => {}, [count]);

	// const handleLogoUpload = (e: any) => {
	// 	const file = e.target.files[0];
	// 	const logoUrl = URL?.createObjectURL(file);
	// 	setLogo(logoUrl);
	// };

	const handleColorChange = (index: number, newColor: string) => {
		const updatedColors = [...gradientColors];
		updatedColors[index] = newColor;
		setGradientColors(updatedColors);
		console.log('Updated Colors: ', updatedColors);
	};

	const handleReset = () => {
		setId('');
		setColorCount(1);
		setTextColor('white');
		setGradientColors(Array(colorCount).fill('#000000'));
		setText(collegeData.collegeName);
		setQuantity(1);
	};

	useEffect(() => {
		if (colorCount > gradientColors.length) {
			setGradientColors([...gradientColors, ...Array(colorCount - gradientColors.length).fill('#000000')]);
		} else {
			setGradientColors(gradientColors.slice(0, colorCount));
		}
	}, [colorCount]);

	const handleLogoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		const files = event.target.files;
		if (files && files[0]) {
			try {
				const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/${Date.now() + name}.jpg`, (progress) => {});
				setLogo(url);
			} catch (error) {
				console.error('Error uploading file:', error);
			}
		} else {
			console.error('no uploading file:');
		}
	};
	const handleSubmit = async () => {
		const instance = getAxiosInstance();
		try {
			const { data } = await instance.post('/college/action/updateTieColours', { collegeId, gradientColors, textColor, quantity, amount: quantity * price, price, count, text, tieLogo, _id: id, orderName });
			console.log(data);
			toast.success('submitted succesfully');
			setAddNew(false);
			setId('');
			handleReset();
			setText(collegeData.collegeName);
			setLogo('');
			setCount(1);
			setTextColor('#000000');
			setGradientColors(['#000000']);
			setColorCount(1);
			setQuantity(1);
			setPrice(unitPrice);
			setOrderName('Order-' + new Date().toISOString());
		} catch (error) {
			toast.error('not saved');
			console.error('Error uploading file:', error);
		}
	};
	const handleConfirm = async (order: any) => {
		if (window.confirm('Are you sure you want to confirm this order?')) {
			try {
				const instance = getAxiosInstance();
				const { data } = await instance.post('/college/action/confirmTieOrder', {
					_id: order._id,
					status: 'confirmed',
				});
				toast.success('confirmed succesfully');
				setRefresh(!refresh);
			} catch (error: any) {
				console.log(error.message);
				toast.error('error confirming');
			}
		}
	};
	const getDetails = async () => {
		try {
			const instance = await getAxiosInstance();
			const { data } = await instance.get('/college/action/getTieDetails', {
				params: { collegeId,type:"tagSettings" },
			});
			if (data?.statusCode === 200) {
				console.log(data?.getDetails);
				setOrderData([data.getDetails]);
				setOrderData(data.getDetails);
			} else {
				throw new Error(data?.message || 'Failed to fetch tieDetails details.');
			}
		} catch (error) {
			console.error('Error in getDetails:', error);
			throw error;
		}
	};

	const columns = [
		{
			header: 'S.N',
			width: '3%',
			key: 'sn',
			render: (order: any, i: any) => i + 1,
		},
		{
			header: 'Order Name',
			width: '12%',
			key: 'orderName',
			render: (order: any) => <span className='text-wrap'>{order.orderName}</span> || 'N/A',
		},
		{
			header: 'Logo',
			width: '6%',
			key: 'tieLogo',
			render: (order: any) => (order.tieLogo ? <img src={order.tieLogo} alt='tieLogo' style={{ width: '50px', height: '50px', objectFit: 'contain' }} /> : 'N/A'),
		},
		{
			header: 'Text',
			width: '18%',
			key: 'text',
			render: (order: any) => order.text || 'N/A',
		},
		{
			header: 'Text Color',
			width: '5%',
			key: 'textColor',
			render: (order: any) =>
				order.textColor ? (
					<div
						style={{
							backgroundColor: order.textColor,
							width: '24px',
							height: '24px',
							borderRadius: '4px',
							border: '1px solid #ccc',
						}}></div>
				) : (
					'N/A'
				),
		},
		{
			header: 'Text Count',
			width: '4%',
			key: 'count',
			render: (order: any) => order.count || 'N/A',
		},
		{
			header: 'Gradient Colors',
			width: '10%',
			key: 'gradientColors',
			render: (order: any) =>
				order.gradientColors && order.gradientColors.length > 0 ? (
					<div
						style={{
							width: '100px',
							height: '24px',
							background: `linear-gradient(to right, ${order.gradientColors.join(', ')})`,
							borderRadius: '4px',
							border: '1px solid #ccc',
						}}></div>
				) : (
					'N/A'
				),
		},
		{
			header: 'Quantity',
			width: '6%',
			key: 'quantity',
			render: (order: any) => order.quantity || 'N/A',
		},
		{
			header: 'Price',
			width: '6%',
			key: 'price',
			render: (order: any) => (order.price ? `₹ ${order.price.toFixed(2)}` : 'N/A'),
		},
		{
			header: 'Order Amount',
			width: '8%',
			key: 'amount',
			render: (order: any) => (order.amount ? `₹ ${order.amount.toFixed(2)}` : 'N/A'),
		},
		{
			header: 'Actions',
			width: '7%',
			key: 'actions',
			render: (order: any, i: any) => (
				<div className='flex justify-around items-center'>
					<span onClick={() => handleEdit(order, i)} className='h-12 w-auto text-dark pt-1 font-bold text-2xl cursor-pointer'>
						<FiEdit />
					</span>
					<span onClick={() => handleDelete(order, i)} className='h-12 w-auto text-dark pt-1 font-bold text-2xl cursor-pointer'>
						<MdDelete />
					</span>
					{/* <button onClick={() => handleEdit(order, i)} className=' bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-500' disabled={order.status !== 'submitted'}>
						<span className='h-12 w-auto text-dark font-bold'><FiEdit /></span>
					</button>
					<button onClick={() => handleDelete(order, i)} className='px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-gray-500' disabled={order.status !== 'submitted'}>
					<MdDelete />
					</button> */}
				</div>
			),
		},
		{
			header: 'Select',
			width: '8%',
			key: 'select',
			render: (order: any, i: any) => (
				<button onClick={() => handleSelect(order)} className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'>
					preview
				</button>
			),
		},
		{
			header: 'Status',
			width: '8%',
			key: 'status',
			render: (order: any) =>
				order.status !== 'submitted' ? (
					`${order.status}`
				) : (
					<button onClick={() => handleConfirm(order)} className='px-2 py-1 bg-orange-400 text-dark rounded hover:bg-orange-600'>
						Confirm
					</button>
				),
		},
	];

	const handleEdit = (order: any, index: any) => {
		setId(order._id);
		setAddNew(true);
		setView(false);
		setLogo(order.tieLogo || '');
		setText(order.text || '');
		setCount(order.count || 1);
		setTextColor(order.textColor || '#000000');
		setGradientColors([...order.gradientColors]);
		setColorCount(order.gradientColors.length);
		setQuantity(order.quantity || 1);
		setPrice(order.price || unitPrice);
	};

	const handleDelete = async (order: any, index: any) => {
		if (window.confirm('Are you sure you want to delete this order?')) {
			try {
				const instance = getAxiosInstance();
				const { data } = await instance.delete('/college/action/deleteTie', {
					params: { _id: order._id },
				});
				toast.success('deleted succesfully');
				setRefresh(!refresh);
			} catch (error: any) {
				console.log(error.message);
				toast.error('error deleting');
			}
		}
	};

	const handleSelect = (order: any) => {
		setView(true);
		setAddNew(false);
		setLogo(order.tieLogo || '');
		setText(order.text || '');
		setCount(order.count || 1);
		setTextColor(order.textColor || '#000000');
		setGradientColors(order.gradientColors || ['#000000']);
		setQuantity(order.quantity || 1);
		setPrice(order.price || unitPrice);
		setOrderName(order.orderName || '');
	};
	const handleCancel = () => {
		handleReset();
		setAddNew(false);
		setText(collegeData.collegeName);
		setLogo('');
		setCount(1);
		setTextColor('#000000');
		setGradientColors(['#000000']);
		setColorCount(1);
		setQuantity(1);
		setPrice(unitPrice);
		setOrderName('Order-' + new Date().toISOString());
	};

	useEffect(() => {
		getDetails();
	}, [addNew, refresh]);

	useEffect(() => {
		// if (collegeId == "COL-AD4FA2404FEA-4F27-AB6F-6EFFA439FB60") {
		// 	setPrice(80);
		// } else {
		// 	setPrice(110);
		// }
		switch (collegeId) {
			case 'COL-D209492D4FB7-4E07-848E-A317A1BC9196':
				setPrice(92.8);
				break;
			case 'COL-AD4FA2404FEA-4F27-AB6F-6EFFA439FB60':
				setPrice(80);
				break;
			default:
				setPrice(110);
				break;
		}
	}, [collegeId]);

	return (
		<>
			<Main width='100vw' height='100%' title='Tag Order'>
				<Toaster />
				<div className='p-6 bg-gray-100 h-full overflow-y-scroll'>
					<div className='flex justify-between'>
						<div></div>
						<h3 className='text-xl font-bold mb-1 text-center'>Customize Your Tag</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<section className='mb-1'>
						{orderData.length > 0 ? (
							<Table width='100%'>
								<Thead>
									{columns.map((col, idx) => (
										<Th key={col.key} width={col.width}>
											{col.header}
										</Th>
									))}
								</Thead>
								<Tbody height='30vh'>
									{orderData.map((order: any, i: number) => (
										<Tr key={i} height='auto'>
											{columns.map((col, idx) => (
												<Td key={col.key} index={idx + 1} width={col.width}>
													{col.render(order, i)}
												</Td>
											))}
										</Tr>
									))}
								</Tbody>
							</Table>
						) : (
							!addNew && (
								<>
									<div className='h-[40] w-full flex justify-center items-end'>
										<h5>Click Add New to design and submit order for tie</h5>
									</div>
								</>
							)
						)}
					</section>
					{view && (
						<div className='mt-6 p-3 bg-gray-400 rounded-lg shadow-lg h-auto'>
							<div className='flex justify-between'>
								<div className='w-10'></div>
								<h2 className='text-lg font-bold mb-1 text-center'>Tie Preview</h2>
								<Button type='close' onClick={() => setView(false)}>
									Close
								</Button>
							</div>

							{/* Tie Preview Section */}
							{gradientColors?.length === 1 ? (
								<section className='flex flex-col items-center space-y-6'>
									<div
										className='w-auto min-w-[80%] h-12 flex items-center justify-center bg-gray-300 rounded-md overflow-x-scroll'
										style={{
											background: gradientColors[0] ?? 'black',
											position: 'relative',
											overflow: 'hidden',
										}}>
										<div className='absolute inset-0 flex items-center justify-around text-xs font-bold gap-10'>
											{[...Array(count || 1)]?.map((_, i) => (
												<React.Fragment key={i}>
													{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' />}
													<span key={i} className='mx-2 whitespace-nowrap' style={{ color: textColor }}>
														{text}
													</span>
												</React.Fragment>
											))}
											{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' key='last-tieLogo' />}
										</div>
									</div>
								</section>
							) : (
								<section className='flex flex-col items-center space-y-6'>
									<div
										className='w-auto min-w-[80%] h-12 flex items-center justify-center bg-gray-300 rounded-md overflow-x-scroll'
										style={{
											background: `linear-gradient(to right, ${gradientColors.join(', ')})`,
											backgroundRepeat: 'no-repeat',
											position: 'relative',
											overflow: 'hidden',
										}}>
										<div className='absolute inset-0 flex items-center justify-around text-xs font-bold gap-10' style={{ color: textColor }}>
											{[...Array(count || 1)]?.map((_, i) => (
												<React.Fragment key={i}>
													{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' />}
													<span key={i} className='mx-2 whitespace-nowrap' style={{ color: textColor }}>
														{text}
													</span>
												</React.Fragment>
											))}
											{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' key='last-tieLogo' />}
										</div>
									</div>
								</section>
							)}
						</div>
					)}

					{addNew ? (
						<section className='h-full overflow-y-scroll absolute inset-0 p-4'>
							<div className='rounded-md border border-gray-300 p-6 mb-6 shadow-lg bg-white'>
								{/* Section: Customize Your Tag */}
								<h2 className='text-2xl font-semibold mb-6 text-center'>Customize Your Tag</h2>

								<div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
									{/* Left Column */}
									<div className='space-y-6'>
										{/* Color Selection */}
										<div className='bg-gray-50 p-4 rounded-md shadow'>
											<h3 className='text-lg font-medium mb-4'>Select Gradient Colors</h3>
											{/* Color Count Selection */}
											<div className='flex items-center space-x-4 mb-4'>
												<span className='text-sm font-medium text-gray-700'>Number of Colors:</span>
												{[1, 2, 3].map((countValue) => (
													<label key={countValue} className='inline-flex items-center'>
														<input type='radio' name='colorCount' value={countValue} checked={colorCount === countValue} onChange={(e) => setColorCount(parseInt(e.target.value))} className='form-radio text-indigo-600' />
														<span className='ml-1 text-sm'>{countValue}</span>
													</label>
												))}
											</div>

											{/* Color Pickers */}
											<div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
												{[...Array(colorCount)].map((_, i) => (
													<div key={i} className='flex flex-col items-center'>
														<label htmlFor={`color-${i}`} className='block text-sm font-medium text-gray-700 mb-1'>
															Color {i + 1}
														</label>
														<input id={`color-${i}`} type='color' value={gradientColors[i] ?? '#000000'} onChange={(e) => handleColorChange(i, e.target.value)} className='w-14 h-14 border border-gray-300 rounded-md' />
													</div>
												))}
											</div>
										</div>

										{/* Logo Upload */}
										<div className='bg-gray-50 p-4 rounded-md shadow'>
											<h3 className='text-lg font-medium mb-4'>Upload Logo</h3>
											<div className='flex items-center'>
												<input id='logo-upload' type='file' accept='image/*' name='logo' onChange={handleLogoChange} className='block w-full text-sm text-gray-500' aria-label='Upload your logo' />
												{tieLogo && <img src={tieLogo} alt='Logo preview' className='ml-4 h-16 w-16 object-contain border border-gray-300 rounded-md' />}
											</div>
										</div>
									</div>

									{/* Right Column */}
									<div className='space-y-6'>
										{/* Text Settings */}
										<div className='bg-gray-50 p-4 rounded-md shadow'>
											<h3 className='text-lg font-medium mb-4'>Text Settings</h3>
											{/* Tag Text */}
											<div className='mb-4'>
												<label htmlFor='tag-text' className='block text-sm font-medium text-gray-700 mb-1'>
													Tag Text
												</label>
												<input id='tag-text' type='text' value={text} onChange={(e) => setText(e.target.value)} placeholder='Enter tag text' className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm' />
											</div>

											{/* Text Count and Color */}
											<div className='grid grid-cols-2 gap-4'>
												{/* Text Count */}
												<div>
													<label htmlFor='text-count' className='block text-sm font-medium text-gray-700 mb-1'>
														Text Repeat Count
													</label>
													<input id='text-count' type='number' min={1} max={10} value={count} onChange={(e) => setCount(parseInt(e.target.value))} className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm' />
												</div>
												{/* Text Color */}
												<div className='flex flex-col items-center'>
													<label htmlFor='text-color' className='block text-sm font-medium text-gray-700 mb-1'>
														Text Color
													</label>
													<input id='text-color' type='color' value={textColor} onChange={(e) => setTextColor(e.target.value)} className='w-14 h-14 border border-gray-300 rounded-md' />
												</div>
											</div>
										</div>

										{/* Action Buttons */}
										<div className='flex flex-col justify-center items-center '>
											<div className='mb-4 w-[70%]'>
												<label htmlFor='tag-text' className='block text-lg font-bold text-gray-700 mb-1'>
													Order Name
												</label>
												<input id='tag-text' type='text' value={orderName} onChange={(e) => setOrderName(e.target.value)} placeholder='Enter order name' className='mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm' />
											</div>
											<button type='button' onClick={handleReset} className='inline-flex items-center px-16 py-2 mt-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600'>
												Reset
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-6 p-3 bg-gray-400 rounded-lg shadow-lg'>
								<h2 className='text-lg font-bold mb-1 text-center'>Tie Preview</h2>

								{/* Tie Preview Section */}
								{gradientColors?.length === 1 ? (
									<section className='flex flex-col items-center space-y-6'>
										<div
											className='w-auto min-w-[80%] h-12 flex items-center justify-center bg-gray-300 rounded-md overflow-x-scroll'
											style={{
												background: gradientColors[0] ?? 'black',
												position: 'relative',
												overflow: 'hidden',
											}}>
											<div className='absolute inset-0 flex items-center justify-around text-xs font-bold gap-10'>
												{[...Array(count || 1)]?.map((_, i) => (
													<React.Fragment key={i}>
														{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' />}
														<span key={i} className='mx-2 whitespace-nowrap' style={{ color: textColor }}>
															{text}
														</span>
													</React.Fragment>
												))}
												{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' key='last-tieLogo' />}
											</div>
										</div>
									</section>
								) : (
									<section className='flex flex-col items-center space-y-6'>
										<div
											className='w-auto min-w-[80%] h-12 flex items-center justify-center bg-gray-300 rounded-md overflow-x-scroll'
											style={{
												background: `linear-gradient(to right, ${gradientColors.join(', ')})`,
												backgroundRepeat: 'no-repeat',
												position: 'relative',
												overflow: 'hidden',
											}}>
											<div className='absolute inset-0 flex items-center justify-around text-xs font-bold gap-10' style={{ color: textColor }}>
												{[...Array(count || 1)]?.map((_, i) => (
													<React.Fragment key={i}>
														{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' />}
														<span key={i} className='mx-2 whitespace-nowrap' style={{ color: textColor }}>
															{text}
														</span>
													</React.Fragment>
												))}
												{tieLogo && <img className='w-auto h-full px-1' src={tieLogo} alt='tieLogo' key='last-tieLogo' />}
											</div>
										</div>
									</section>
								)}
							</div>

							<div>
								<div className='flex bg-gray-400 rounded-md p-3 justify-around mt-4 shadow-lg'>
									<div className='w-1/4'>
										<label className='block text-sm font-bold text-white '>Quantity</label>
										<input type='number' min='1' value={quantity} onChange={(e: any) => setQuantity(e.target.value)} className='mt-1 block w-full border border-gray-300 rounded-lg font-bold' />
									</div>
									<div className='w-1/4'>
										<label className='block text-sm font-bold text-white '>Unit Ptice</label>
										<p>{price}</p>
										{/* <input type='number' min='1' value={price} onChange={(e: any) => setPrice(e.target.value)} className='mt-1 block w-full border border-gray-300 rounded-lg font-bold text-white' disabled /> */}
									</div>
									<div className='w-1/4'>
										<label className='block text-sm font-bold text-white'>Total Amount</label>
										<h5 className='text-bold ml-2 text-white '>{quantity * price}</h5>
									</div>
								</div>
							</div>
							<div className='mt-6 flex justify-center'>
								<Button type='close' onClick={handleCancel}>
									Cancel
								</Button>
								{id ? (
									<Button type='save' onClick={handleSubmit}>
										Update Order
									</Button>
								) : (
									<Button type='save' onClick={handleSubmit}>
										Submit Order
									</Button>
								)}
							</div>
						</section>
					) : (
						<div className='flex justify-center'>
							<Button type='edit' onClick={() => setAddNew(true)}>
								Add New
							</Button>
						</div>
					)}
				</div>
			</Main>
		</>
	);
}

export default TagOrder;
