import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAllAccademics } from '../../NecttosComp/APICaller/ApiServices';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';

interface InvoiceManagementProps {
	onClose: () => void;
}

interface Student {
	id: number;
	name: string;
	hallticketNo: string;
	exam: string;
	classId: string;
	subjectId: string;
}

interface Bench {
	bench: number;
	left: Student | null;
	center: Student | null;
	right: Student | null;
}

interface Room {
	_id: string;
	roomNo: string;
	noOfBenches: number;
	noOfSeatsOnRoom: number;
	capacity: number;
	roomName: string;
	nameOfBlock: string;
}

interface AssignedRoom {
	room: string;
	// roomName: string;
	nameOfBlock: string;
	benches: Bench[];
}

interface SeatingPlan {
	roomNo: string;
	roomName: string;
	nameOfBlock: string;
	benches: {
		benchNo: number;
		left: Student | null;
		center: Student | null;
		right: Student | null;
	}[];
}

function SeatingPlan({ onClose }: InvoiceManagementProps) {
	const [refresh, setRefresh] = useState<boolean>(false);
	const [roomData, setRoomData] = useState<Room[]>([]);
	const [selectedRoom, setSelectedRoom] = useState<Room[]>([]);
	const [selectedStudentsCount, setSelectedStudentsCount] = useState<number>(0);
	// const [roomView, setRoomView] = useState<Room | null>(null);
	const [viewAll, setViewAll] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const [seatingPlan, setSeatingPlan] = useState<any>([]);
	const [savedSeatingPlan, setSavedSeatingPlan] = useState<any>([]);
	const [savedPlan, setSavedPlan] = useState<any>([]);

	console.log('college', collegeId);

	const ref = useRef<any>(null);
	const refSave = useRef<any>(null);

	const { data: tableData = [], refetch } = useQuery('getAllAccadamicHolidays', () => getAllAccademics(collegeId));

	// console.log(data);

	const selectedCollege = collegeList?.find((x: any) => x.collegeId === collegeId);

	const getRoomData = async () => {
		setIsLoading(true);
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.get('/college/examProcess/getRoomManagement', {
					params: { collegeId },
				})
				.then((res: any) => {
					console.log('roomData', res.data);
					setRoomData(res.data.list);
					setIsLoading(false);
				})
				.catch((err: any) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			throw new Error('API Error Bundle Data');
		}
	};

	const handleSelectedRoom = (room: Room) => {
		setSelectedRoom((prevSelectedRoom: Room[]) => {
			let newArray = [...prevSelectedRoom];
			const roomIndex = newArray.findIndex((r: any) => r._id === room._id);
			if (roomIndex !== -1) {
				newArray.splice(roomIndex, 1);
			} else {
				newArray.push(room);
			}

			return newArray;
		});
	};

	useEffect(() => {
		let newCount = selectedRoom.reduce((acc: number, el: any) => acc + parseInt(el.noOfSeatsOnRoom), Number(0));
		setSelectedStudentsCount(newCount);
	}, [selectedRoom]);

	const generateDummyData = (numStudents: number): Student[] => {
		const classIds = ['aa1', 'ab1', 'ac1', 'ad1'];
		const subjectIds = ['s1', 's2', 's3', 's4'];
		const subjectCounts: { [key: string]: number } = {};
		const dummyData: Student[] = [];

		for (let i = 1; i <= numStudents; i++) {
			const name = `student${i}`;
			const exam = `exam${1}`;
			const classId = classIds[i % classIds.length];
			const subjectId = subjectIds[i % subjectIds.length];

			if (!subjectCounts[subjectId]) {
				subjectCounts[subjectId] = 0;
			}
			subjectCounts[subjectId] += 1;
			const hallticketNo = `${subjectId}-${subjectCounts[subjectId]}`;

			dummyData.push({
				id: i,
				name,
				hallticketNo,
				exam,
				classId,
				subjectId,
			});
		}

		return dummyData;
	};

	const studentData = generateDummyData(100);

	// console.log("dummy student data", studentData);

	const handleGenerateSeatingPlan = () => {
		const plans: any = assignStudents(selectedRoom, studentData);
		console.log('plans', plans);
		setSeatingPlan(plans);
		setViewAll(true);
	};
	console.log('seatingPlan', seatingPlan);

	const handleSaveSeatingPlan = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/examProcess/seatingPlan', {
					seatingPlan: seatingPlan,
					collegeId,
				})
				.then((res: any) => {
					console.log('seatingsave', res.data);
					setSeatingPlan(res.data.list);
					setIsLoading(false);
					toast.success('Data saved successfully!');
				})
				.catch((err: any) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			throw new Error('API Error Bundle Data');
		}
	};

	const getSavedSeatingPlan = async () => {
		try {
			setIsLoading(true);
			const instance = await getAxiosTokenInstance();
			instance
				.get('/college/examProcess/seatingPlan', {
					params: { collegeId },
				})
				.then((res: any) => {
					console.log('seatingPlan', res);
					setSavedSeatingPlan(res.data.seatingPlan);
					setIsLoading(false);
				})
				.catch((err: any) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			throw new Error('API Error Bundle Data');
		}
	};

	const assignStudents = (rooms: Room[], students: Student[]): AssignedRoom[] => {
		let studentIndex = 0;

		const fillSeats = (assignedRooms: AssignedRoom[], seatType: 'left' | 'center' | 'right') => {
			assignedRooms.forEach((assignedRoom) => {
				assignedRoom.benches.forEach((bench) => {
					if (studentIndex >= students.length) {
						// setSelectedStudentsCount(studentIndex)
						return;
					}
					if (!bench[seatType]) {
						bench[seatType] = students[studentIndex++];
					}
				});
			});
		};

		const assignedRooms: AssignedRoom[] = rooms.map((room) => ({
			room: room.roomNo,
			nameOfBlock: room.nameOfBlock,
			benches: Array.from({ length: room.noOfBenches }).map((_, index) => ({
				bench: index + 1,
				left: null,
				center: null,
				right: null,
			})),
		}));

		fillSeats(assignedRooms, 'left');
		fillSeats(assignedRooms, 'center');
		fillSeats(assignedRooms, 'right');

		return assignedRooms;
	};

	const [assignedRooms, setAssignedRooms] = useState<AssignedRoom[]>([]);
	useEffect(() => {
		if (selectedRoom.length > 0) {
			const assigned: AssignedRoom[] = assignStudents(selectedRoom, studentData);
			setAssignedRooms(assigned);
		}
	}, [selectedRoom]);

	useEffect(() => {
		getRoomData();
		getSavedSeatingPlan();
	}, [seatingPlan]);

	console.log('SavedSeatingPlan', savedSeatingPlan);

	const renderBenches = (benches: Bench[]) => {
		const half = Math.ceil(benches.length / 2);
		const leftBenches = benches.slice(0, half);
		const rightBenches = benches.slice(half);

		// const calculateIndexRanges = (benches: Bench[], position: keyof Bench) => {
		//   const studentsBySubject: { [key: string]: string[] } = {};

		//   benches.forEach(bench => {
		//     const student:any = bench[position];
		//     if (student) {
		//       if (!studentsBySubject[student.subjectId]) {
		//         studentsBySubject[student.subjectId] = [];
		//       }
		//       studentsBySubject[student.subjectId].push(student.name);
		//     }
		//   });

		//   const subjectIndexRanges = Object.entries(studentsBySubject).map(([subjectId, students]) => {
		//     students.sort((a, b) => parseInt(a.replace('student', '')) - parseInt(b.replace('student', ''))); // Sort students numerically
		//     const range = `${students[0]} - ${students[students.length - 1]}`;
		//     return `${subjectId}: ${range}`;
		//   });

		//   return subjectIndexRanges;
		// };

		// const leftIndexRanges = calculateIndexRanges(leftBenches, 'left');
		// const centerIndexRanges = calculateIndexRanges(leftBenches, 'center').concat(calculateIndexRanges(rightBenches, 'center'));
		// const rightIndexRanges = calculateIndexRanges(rightBenches, 'right');

		return (
			<>
				<div className='flex'>
					<div className='w-1/2'>
						{leftBenches.map((bench, index) => (
							<div key={index} className='w-full p-2'>
								<div className='flex flex-column justify-between bg-gray-200 pt-0 h-16'>
									<div className='text-center'>{`Bench ${bench.bench}`}</div>
									<div className='flex justify-between items-end bg-blue-200'>
										<div>{bench.left ? bench.left.name : ''}</div>
										<div>{bench.center ? bench.center.name : ''}</div>
										<div>{bench.right ? bench.right.name : ''}</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className='w-1/2'>
						{rightBenches.map((bench, index) => (
							<div key={index} className='w-full p-2'>
								<div className='flex flex-column justify-between bg-gray-200 pt-0 h-16'>
									<div className='text-center'>{`Bench ${bench.bench}`}</div>
									<div className='flex justify-between items-end bg-blue-200'>
										<div>{bench.left ? bench.left.name : ''}</div>
										<div>{bench.center ? bench.center.name : ''}</div>
										<div>{bench.right ? bench.right.name : ''}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				{/* <p>Left Position Index: {leftIndexRanges.join(', ')}</p>
        <p>Center Position Index: {centerIndexRanges.join(', ')}</p>
        <p>Right Position Index: {rightIndexRanges.join(', ')}</p> */}
			</>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => ref.current,
	});
	const handlePrintSave = useReactToPrint({
		content: () => refSave.current,
	});

	return (
		<>
			<Main title='Seating Plan' width='100vw' height='100vh'>
				<Header>
					<div></div>
					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Toaster />
					<div className='min-h-auto p-4 overflow-auto flex-column items-center bg-gray-300 justify-center rounded'>
						<div className='flex flex-column items-center'>
							<h5>Total Students: {studentData.length}</h5>
							<h5>Total Rooms available: {roomData.length}</h5>
						</div>
						<div className='flex-column w-auto h-auto rounded'>
							<div className='flex w-auto h-auto rounded gap-2 mt-4 text-center items-center justify-center flex-wrap'>
								{roomData &&
									roomData.map((room: Room, index: any) => (
										<div key={index} className='relative flex flex-column items-center gap-2 justify-center pointer'>
											<div className={`relative border w-16 h-16 rounded-lg flex items-center  hover:bg-red-400 justify-center ${selectedRoom.includes(room) ? 'bg-green-800 text-white' : 'bg-gray text-dark'}`}>
												<label htmlFor={index} className={`border w-16 h-16 rounded-lg flex items-center justify-center relative ${selectedRoom.includes(room) ? 'bg-green-800 text-white' : 'bg-gray text-dark'}`}>
													<span className='w-full text-center'>{room.roomNo}</span>
													<input type='checkbox' id={index} className='hidden' onChange={() => handleSelectedRoom(room)} />
												</label>
											</div>
											<div className='h-14'>
												{/* <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-red-500 text-xs font-semibold rounded z-10 shadow-lg opacity-100 hover:opacity-100 transition-opacity duration-300">
                            <p>{`Benches: ${room.noOfBenches}`}</p>
                            <p>{`Seats: ${room.noOfSeatsOnRoom}`}</p>
                          </div> */}
											</div>
											{/* <div className="opacity-10 hover:opacity-90">hello</div> */}
										</div>
									))}
							</div>
						</div>
						{selectedRoom.length > 0 && (
							<div>
								<div>
									<div className='flex gap-20 bg-gray-400 pt-3 pl-2 rounded-lg'>
										<p className='leading-[10px] text-lg font-semibold'>Total Selected Rooms: {selectedRoom.length}</p>
										<p className='leading-[10px] text-lg font-semibold'>Total Selected seats: {selectedStudentsCount}</p>
										<p className='leading-[10px] text-lg font-semibold'>Remaining students: {studentData.length - selectedStudentsCount > 0 ? studentData.length - selectedStudentsCount : 0}</p>
									</div>
									<div className='flex justify-center mt-4 '>
										<h3>Selected Rooms</h3>
									</div>
									<div className='flex gap-1 flex-wrap justify-center'>
										{selectedRoom.map((room: Room, index: any) => (
											<div key={index} className='relative flex flex-column items-center gap-2 justify-center'>
												<label className={`border w-16 h-16 rounded-lg flex items-center justify-center relative ${selectedRoom.includes(room) ? 'bg-green-800 text-white' : 'bg-gray text-dark'}`}>
													<span className='w-full text-center'>{room.roomNo}</span>
												</label>
											</div>
										))}
									</div>
									<div className='flex justify-center'>
										{selectedStudentsCount >= studentData.length && (
											<button type='button' className='rounded bg-blue-600 text-white h-10 text-xl m-2' onClick={handleGenerateSeatingPlan}>
												Generate seat plan
											</button>
										)}
									</div>
									{/* {selectedStudentsCount >= studentData.length && (
                <button
                  className="rounded bg-green-600 text-white h-8 m-2 w-24"
                  onClick={handlePrint}
                >
                  Print
                </button>
              )} */}
								</div>
								{viewAll === true && (
									<div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center'>
										<div className='relative'>
											<button className='absolute rounded bg-green-600 text-white h-8 m-2 w-24 left-10 top-2' onClick={handlePrint}>
												Print
											</button>
											<button className='absolute rounded bg-green-600 text-white h-8 m-2 w-24 left-[150px] top-2' onClick={handleSaveSeatingPlan}>
												Save Seating Plan
											</button>
											<button className='text-white border-red-400 bg-red-400 rounded absolute right-2 top-2' onClick={() => setViewAll(false)}>
												close
											</button>
											<div className='bg-white rounded-lg w-[220mm] h-[90vh] overflow-scroll'>
												{selectedStudentsCount >= studentData.length && (
													<div ref={ref} className=' mt-6 h-auto w-[210mm] px-4 bg-white printable text-center m-2'>
														{viewAll &&
															assignedRooms.map((assignedRoom) => (
																<div key={assignedRoom.room} className='w-auto h-auto pt-6'>
																	<h3 className='pt-4'>{selectedCollege.collegeName}</h3>

																	<h5>Seat Arrangement for Room {assignedRoom.room}</h5>
																	<div className='flex gap-4 text-left'>
																		<div className='w-[50%]'>
																			<p>Date :</p>
																			<p>Exam :</p>
																		</div>
																		<div className='w-[50%]'>
																			<p>Room No.: {assignedRoom.room}</p>

																			<p>Block Name: {assignedRoom.nameOfBlock}</p>
																		</div>
																	</div>
																	{renderBenches(assignedRoom.benches)}
																	<div className='pageBreak'></div>
																</div>
															))}
													</div>
												)}
											</div>
										</div>
									</div>
								)}
								<div>
									{selectedStudentsCount >= studentData.length && (
										<div ref={ref} className=' mt-6 h-auto w-[210mm] px-4 bg-white printable text-center m-2'>
											{viewAll &&
												assignedRooms.map((assignedRoom) => (
													<div key={assignedRoom.room} className='w-auto h-auto'>
														<h3 className='pt-4'>{selectedCollege.collegeName}</h3>
														<p className='text-lg'>(Autonomous)</p>
														<h5>Seat Arrangement for Room {assignedRoom.room}</h5>
														<div className='flex gap-4 text-left'>
															<div className='w-[50%]'>
																<p>Date :</p>
																<p>Exam :</p>
															</div>
															<div className='w-[50%]'>
																<p>Room No.: {assignedRoom.room}</p>

																<p>Block Name: {assignedRoom.nameOfBlock}</p>
															</div>
														</div>
														{renderBenches(assignedRoom.benches)}
														<div className='pageBreak'></div>
													</div>
												))}
										</div>
									)}
								</div>
							</div>
						)}
					</div>

					<div className='bg-gray-200 p-4 relative'>
						{savedSeatingPlan.length > 0 &&
							savedSeatingPlan.map((plan: any, index: any) => (
								<div key={index} className='flex justify-center items-center mt-4 gap-2 m-0 p-0'>
									<p className='pt-6 text-lg leading-3'>
										Seating Plan {index + 1}, Generated on: {moment(plan.createdOn).format('DD/MM/YYYY hh:mm A')}
									</p>
									<button className='rounded bg-blue-600 text-white w-24 h-10' onClick={() => setSavedPlan(plan)}>
										View
									</button>
								</div>
							))}
					</div>
					<div>
						{savedPlan._id && (
							<div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center overflow-auto'>
								<div className='relative bg-white p-4 rounded w-[210mm] max-h-[90vh] overflow-y-auto mt-8 mb-8'>
									<button className='absolute rounded bg-green-600 text-white h-8 m-2 w-24 left-8 top-2' onClick={handlePrintSave}>
										Print
									</button>
									<button className='text-white border-red-400 bg-red-400 rounded absolute right-2 top-2' onClick={() => setSavedPlan([])}>
										Close
									</button>
									<div ref={refSave} className='printable w-[100%] p-4 align-center'>
										{savedPlan.seatingPlan.map((room: any, index: any) => (
											<div key={index}>
												<h3 className='pt-4 text-center'>{selectedCollege.collegeName}</h3>
												<h5 className='text-center'>Seat Arrangement for Room {room.room}</h5>
												<div className='flex gap-4 text-left'>
													<div className='w-[50%]'>
														<p>Date :</p>
														<p>Exam :</p>
													</div>
													<div className='w-[50%]'>
														<p>Room No.: {room.room}</p>
														<p>Block Name: {room.nameOfBlock}</p>
													</div>
												</div>
												<div>
													<div className='flex gap-3'>
														<div className='w-1/2'>
															{room?.benches?.slice(0, Math.ceil(room.benches.length / 2)).map((bench: any, i: any) => (
																<div key={i} className='mt-4'>
																	<div className='flex flex-column justify-between bg-gray-200 pt-0 h-16'>
																		<div className='text-center'>{`Bench ${bench.bench}`}</div>
																		<div className='flex justify-between items-end bg-blue-200'>
																			<div>{bench.left ? bench.left.name : ''}</div>
																			<div>{bench.center ? bench.center.name : ''}</div>
																			<div>{bench.right ? bench.right.name : ''}</div>
																		</div>
																	</div>
																</div>
															))}
														</div>
														<div className='w-1/2'>
															{room?.benches?.slice(Math.ceil(room.benches.length / 2), room.benches.length).map((bench: any, i: any) => (
																<div key={i} className='mt-4'>
																	<div className='flex flex-column justify-between bg-gray-200 pt-0 h-16'>
																		<div className='text-center'>{`Bench ${bench.bench}`}</div>
																		<div className='flex justify-between items-end bg-blue-200'>
																			<div>{bench.left ? bench.left.name : ''}</div>
																			<div>{bench.center ? bench.center.name : ''}</div>
																			<div>{bench.right ? bench.right.name : ''}</div>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
												<div className='pageBreak'></div>
											</div>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</Body>
			</Main>
		</>
	);
}

export default SeatingPlan;
