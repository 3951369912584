// DashBoard.js
import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { PrgressCircle, calculateProfilepPercentage } from '../NewProfile';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { CiEdit } from 'react-icons/ci';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import PersonalInfo from './PersonalInfo';
import AddressInfo from './AddressInfo';
import OfficialInfo from './OfficialInfo';
import BankDetails from './BankDetails';
import Qualification from './Qualification';
import Experience from './Experiance';
import Publications from './Publications';
import Workshops from './workshop';
import FDP from './FDP';
import AddtionalInformation from './AddtionalResponsibilities';
import Members from './Members';
import Traveling from './Traveling';
import BookChapters from './BooksChapters';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import Seminars from './Seminars';
import CulturalActivies from './CulturalActivies';

const DashBoard = ({ setSelectedPage, pages, onClose, refresh, setRefresh }) => {
	const { data, refetch } = useProfileData();

	const {
		phoneNumber,

		photoURL,

		name, //pe
		dob,
		gender,
		bloodGroup,
		disabilities,
		meritalStatus,
		email,
		aadhaarNumber,
		religion,
		category,
		caste,
		annualIncome,
		povertyLine,

		penNumber, //of
		dateOfJoin,
		designationStatus,
		designation,
		stay,
		secondLanguage,
		capId,
		rollNo,
		lastSchoolName,
		lastTcNumber,
		lastTcDate,
		hallTicketNo,
		shortName,
		universityId,

		houseName, //add
		place,
		postOffice,
		village,
		panchayatMunicipalityCorporation,
		district,
		state,
		nationality,
		pinCode,
		permanentHouseName,
		permanentPlace,
		permanentPostOffice,
		permanentVillage,
		permanentPanchayatMunicipalityCorporation,
		permanentDistrict,
		permanentState,
		permanentNationality,
		permanentPinCode,

		qualifications,

		experiences,

		aditionalResponsibilities, // Note the typo in the data

		membersBodies,

		seminar,

		workshops,

		fdps,

		bankDetails,

		culturalActivity,

		travelDetails,

		publications,

		bookChapter,

		percentageOfCompletion,

		admisionNo,
		collegeRoles,
		guardianName,
		guardianMobile,
		guardianRelation,
		dateOfAdmission,
		index,
		rcGroup,
		rcMark,
		hungerCordinator, // Note the typo in the data
		verification,
		percentageFilled,
		admissionto,
		certificates,
		classId,
		hungryCordinator, // Corrected field
		serviceCourse,
		photoURL: avatarURL,
		// ... add any other fields you want to include
	} = data;

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const [classes, setClasses] = useState([]);
	const [editModal, setEditModal] = useState({});
	const [seeMore, setSeeMore] = useState(null);
	const colourData = {
		primaryTextColourLite: 'black',
		primaryBgColourLite: 'white',
		primaryBgColourLite2: '#CCCCCC',
		primaryHoverColourLite: 'gray',
		primarySelectColourLite: '#697565',

		bgColour: 'white',
		textColour: 'black',
		hoverColour: '#eaeafa',
		hoverTextColour: 'black',
		selectBgColour: '#191970',
		selectTextColour: 'white',
	};
	const [colours, setColours] = useState(colourData);

	const uniqueColleges = Array.from(
		new Set(
			collegeRoles.map((role) =>
				JSON.stringify({
					collegeId: role.collegeId,
					classId: role.classId,
				}),
			),
		),
	).map((str) => JSON.parse(str));

	const getClassesList = async ({ queryKey }) => {
		const [_, { collegeId }] = queryKey;
		const instance = await getAxiosTokenInstance();
		let resp;
		if (collegeId) {
			try {
				resp = await instance.get('/college/classes/getClass', {
					params: { collegeId, needAll: true, classOnly: true },
				});

				if (resp.data.statusCode === 400) {
					throw new Error(resp.data.message || 'API Error');
				}
			} catch (error) {
				throw new Error('API Error');
			}
		}
		setClasses(resp?.data.list);
		return resp?.data;
	};

	const classQuery = useQuery(['classes', { collegeId }], getClassesList);

	const Section = ({ title, children, handleClick, percentageFilled, id }) => (
		<div id={id} className='py-8 px-8 border group shadow-[-1px_0px_4px_0px_#212233] rounded-lg m-6 '>
			<div className='flex items-cente relative'>
				<h2 className='text-xl font-semibold mb-4 flex-grow '>{title}</h2>
				{/* view only when hovered */}
				{percentageFilled && (
					<div className='absolute right-3 top-3'>
						<PrgressCircle percent={percentageFilled || 0} />
					</div>
				)}

				<span className='opacity-10 group-hover:opacity-100 text-2xl font-bold cursor-pointer' onClick={handleClick}>
					<CiEdit />
				</span>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>{children}</div>
		</div>
	);

	const Section2 = ({ title, children }) => (
		<div className='py-4 px-8 m-6 rounded-2xl border-b shadow-[-1px_0px_4px_0px_#212233]'>
			<h2 className='text-xl font-semibold mb-4'>{title}</h2>
			<div className='grid grid-cols-1 gap-6'>{children}</div>
		</div>
	);

	const InfoRow = ({ label, value }) => (
		<div className='flex flex-col'>
			<span className='text-gray-500'>{label}</span>
			<span className='font-medium text-gray-800'>{value || 'N/A'}</span>
		</div>
	);

	const DetailSection = ({ title, data, renderItem, handleClick }) => (
		<div className='py-4 px-8 m-6 rounded-2xl border-b shadow-[-1px_0px_4px_0px_#212233] group'>
			<div className='flex items-center justify-between'>
				<h2 className='text-xl font-semibold mb-4'>{title}</h2>
				<span className='opacity-10 group-hover:opacity-100 text-2xl font-bold cursor-pointer' onClick={handleClick}>
					<CiEdit />
				</span>
			</div>
			{data && data.length > 0 ? (
				data.map((item) => (
					<div key={item._id} className='mb-4'>
						{renderItem(item)}
					</div>
				))
			) : (
				<p className='text-gray-600'>No {title.toLowerCase()} added.</p>
			)}
		</div>
	);
	const DetailSection2 = ({ title, data, renderItem, handleClick, keyName, seeMore, setSeeMore }) => (
		<div className='py-4 px-8 m-6 rounded-2xl border-b shadow-[-1px_0px_4px_0px_#212233] group'>
			<div className='flex items-center justify-between'>
				<h2 className='text-xl font-semibold mb-4'>{title}</h2>
				<span className='opacity-10 group-hover:opacity-100 text-2xl font-bold cursor-pointer' onClick={handleClick}>
					<CiEdit />
				</span>
			</div>
			{data && data.length > 0 ? (
				<div className='flex flex-wrap gap-2 w-full'>
					{seeMore !== keyName
						? data.slice(0, 3).map((item) => (
								<div
									key={item._id}
									className='mb-4 shadow-[0px_0px_6px_0px_#857a7a] rounded-xl 
						   w-full sm:w-[calc(50%-0.5rem)] lg:w-[calc(33.33%-0.5rem)]'>
									{renderItem(item)}
								</div>
						  ))
						: data.map((item) => (
								<div
									key={item._id}
									className='mb-4 shadow-[0px_0px_6px_0px_#857a7a] rounded-xl
						   w-full sm:w-[calc(50%-0.5rem)] lg:w-[calc(33.33%-0.5rem)]'>
									{renderItem(item)}
								</div>
						  ))}
				</div>
			) : (
				<p className='text-gray-600'>No {title.toLowerCase()} added.</p>
			)}
			{data && data.length > 3 && seeMore !== keyName && <p onClick={() => setSeeMore(keyName)}>see more</p>}
			{seeMore === keyName && <p onClick={() => setSeeMore(null)}>see less</p>}
		</div>
	);

	return (
		<div className='bg-gray-50 min-h-screen'>
			<div className='max-w-6xl mx-auto p-0'>
				<div className='bg-white shadow-md rounded-lg overflow-hidden'>
					{/* Header Section */}
					<div className='flex items-center py-4 px-8 m-6 rounded-2xl border shadow-[-1px_0px_4px_0px_#212233] '>
						{/* <img className='w-24 h-24 rounded-full object-cover' src={avatarURL} alt={name} /> */}
						<div className='my-6'>
							<h1 className='text-3xl font-bold text-gray-800'>{name}</h1>
							<p className=' leading-4 text-lg text-gray-500'>{designation}</p>
							<p className=' leading-4 text-gray-600'>{email}</p>
							<p className=' leading-4 text-gray-600'>{phoneNumber}</p>
						</div>
						<div className='ml-auto'>
							<PrgressCircle percent={percentageOfCompletion || 0} />
						</div>
					</div>

					{/* Personal Information */}
					<Section title='Personal Information' handleClick={() => setEditModal(pages.find((page) => page.keyData === 'profileInformation'))} id={'personalInfo'}>
						<InfoRow label='Date of Birth' value={dob} />
						<InfoRow label='Gender' value={gender} />
						<InfoRow label='Marital Status' value={meritalStatus} />
						<InfoRow label='Blood Group' value={bloodGroup} />
						<InfoRow label='Aadhaar Number' value={aadhaarNumber} />
						<InfoRow label='Religion' value={religion} />
						<InfoRow label='Caste' value={caste} />
						<InfoRow label='Category' value={category} />
						<InfoRow label='Disabilities' value={disabilities} />
						{povertyLine && <InfoRow label='Disabilities' value={povertyLine} />}
						{annualIncome && <InfoRow label='Disabilities' value={annualIncome} />}
					</Section>

					{/* Address */}
					<Section title='Address' handleClick={() => setEditModal(pages.find((page) => page.keyData === 'addressDetails'))}>
						<InfoRow label='House Name' value={houseName} />
						<InfoRow label='Place' value={place} />
						<InfoRow label='Post Office' value={postOffice} />
						<InfoRow label='District' value={district} />
						<InfoRow label='State' value={state} />
						<InfoRow label='Pin Code' value={pinCode} />
						<InfoRow label='Panchayat/Municipality/Corporation' value={panchayatMunicipalityCorporation} />
					</Section>
					{/* Permanent Address */}
					<Section title='Permanent Address' handleClick={() => setEditModal(pages.find((page) => page.keyData === 'addressDetails'))}>
						<InfoRow label='House Name' value={permanentHouseName} />
						<InfoRow label='Place' value={permanentPlace} />
						<InfoRow label='Post Office' value={permanentPostOffice} />
						<InfoRow label='District' value={permanentDistrict} />
						<InfoRow label='State' value={permanentState} />
						<InfoRow label='Pin Code' value={permanentPinCode} />
						<InfoRow label='Panchayat/Municipality/Corporation' value={panchayatMunicipalityCorporation} />
					</Section>

					{/* Professional Information */}
					<Section title='Professional Information' handleClick={() => setEditModal(pages.find((page) => page.keyData === 'officialInformation'))}>
						{/* <InfoRow label="Designation Status" value={designationStatus} /> */}
						{dateOfJoin && <InfoRow label='Date of Joining' value={dateOfJoin} />}
						{universityId && <InfoRow label='University ID' value={universityId} />}
						{penNumber && <InfoRow label='PEN Number' value={penNumber} />}
						{designation && <InfoRow label='Designation' value={designation} />}
						{designationStatus && <InfoRow label='Designation Status' value={designationStatus} />}
						{stay && <InfoRow label='Stay' value={stay} />}
						{secondLanguage && <InfoRow label='Second Language' value={secondLanguage} />}
						{capId && <InfoRow label='Cap ID' value={capId} />}
						{rollNo && <InfoRow label='Roll No' value={rollNo} />}
						{lastSchoolName && <InfoRow label='Last School Name' value={lastSchoolName} />}
						{lastTcNumber && <InfoRow label='Last TC Number' value={lastTcNumber} />}
						{lastTcDate && <InfoRow label='Last TC Date' value={lastTcDate} />}
						{hallTicketNo && <InfoRow label='Hall Ticket Number' value={hallTicketNo} />}
						{shortName && <InfoRow label='Short Name' value={shortName} />}
						{universityId && <InfoRow label='University Id' value={universityId} />}
					</Section>

					{/* Bank Details */}
					{bankDetails && (
						<Section title='Bank Details' handleClick={() => setEditModal(pages.find((page) => page.keyData === 'bankInformations'))}>
							<InfoRow label='Beneficiary Name' value={bankDetails.beneficiary} />
							<InfoRow label='Account Number' value={bankDetails.accountNo} />
							<InfoRow label='IFSC Code' value={bankDetails.ifcs} />
							<InfoRow label='PAN Card' value={bankDetails.panCard} />
						</Section>
					)}

					{/* Guardian Details */}
					{/* {guardianName && (
						<Section title='Guardian Details'>
							<InfoRow label='Guardian Name' value={guardianName} />
							<InfoRow label='Guardian Mobile' value={guardianMobile} />
							<InfoRow label='Relation' value={guardianRelation} />
						</Section>
					)} */}

					{/* Roles Across Colleges */}
					<Section2 title='Roles Across Colleges'>
						<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
							{uniqueColleges?.slice(0, 3)?.map((college, i) => {
								const collegeName = collegeList?.find((x) => x._id === college?.collegeId)?.collegeName || 'Unknown College';
								const classs = classes?.find((x) => x._id === college?.classId) || 'Unknown Class';

								return (
									<div key={college?.collegeId} className='bg-gray-50 p-4 shadow-[0px_0px_12px_0px_#857a7a] rounded-xl w-full'>
										<h3 className='font-medium text-lg'>{collegeName}</h3>
										<div className='flex items-center space-x-2 mt-2'>
											<p className='font-medium'>{classs?.shortName}</p>
											<p className='text-sm text-gray-500'>{classs?.batchName}</p>
										</div>
										<ul className='mt-2 list-disc list-inside text-gray-600'>
											{collegeRoles
												.filter((role) => role.collegeId === college?.collegeId)
												.map((role) => (
													<li key={`${collegeId}-${i}-${role.type}`}>{role.type.toUpperCase()}</li>
												))}
										</ul>
									</div>
								);
							})}
						</div>
					</Section2>

					{/* Qualifications */}
					{qualifications && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'educationalQualification'))}
							title='Qualifications'
							data={qualifications}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'educationalQualification'}
							renderItem={(q) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>
										{q.type}: {q.course} ({q.specialisation})
									</p>
									<p className='text-sm text-gray-500'>
										{q.university} | Year: {q.yearOfPass}
									</p>
								</div>
							)}
						/>
					)}

					{/* Experiences */}
					{experiences && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'experienceAndServiceDetails'))}
							title='Experiences'
							data={experiences}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'experienceAndServiceDetails'}
							renderItem={(exp) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{exp.nameOfTheInstitution}</p>
									<p className='text-sm text-gray-500'>
										{exp.startDate} - {exp.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Publications */}
					{publications && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'publications'))}
							title='Publications'
							data={publications}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'publications'}
							renderItem={(pub) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{pub.articleTitle}</p>
									<p className='text-sm text-gray-500'>{pub.type}</p>
								</div>
							)}
						/>
					)}

					{/* Workshops */}
					{workshops && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'workshops'))}
							title='Workshops'
							data={workshops}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'workshops'}
							renderItem={(workshop) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{workshop.title}</p>
									<p className='text-sm text-gray-500'>{workshop.organiser}</p>
									<p className='text-sm text-gray-500'>
										{workshop.startDate} - {workshop.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Seminars */}
					{seminar && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page?.keyData === 'seminar'))}
							title='Seminars'
							data={seminar}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'seminar'}
							renderItem={(seminar) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{seminar.title}</p>
									<p className='text-sm text-gray-500'>{seminar.organiser}</p>
									<p className='text-sm text-gray-500'>
										{seminar.startDate} - {seminar.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Seminars */}
					{culturalActivity && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page?.keyData === 'culturalActivity'))}
							title='Co-Curricular Activity'
							data={culturalActivity}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'culturalActivity'}
							renderItem={(culturalActivity) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{culturalActivity.type}</p>
									<p className='text-sm text-gray-500'>{culturalActivity.item}</p>
									<p className='text-sm text-gray-500'>{culturalActivity.specialised}</p>
									<p className='text-sm text-gray-500'>{culturalActivity.strength}</p>
									{/* <p className='text-sm text-gray-500'>
										{culturalActivity.startDate} - {culturalActivity.endDate}
									</p> */}
								</div>
							)}
						/>
					)}

					{/* FDPs */}
					{fdps && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'fdp'))}
							title='FDPs'
							data={fdps}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'fdp'}
							renderItem={(fdp) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{fdp.title}</p>
									<p className='text-sm text-gray-500'>{fdp.organiser}</p>
									<p className='text-sm text-gray-500'>
										{fdp.startDate} - {fdp.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Additional Responsibilities */}
					{aditionalResponsibilities && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'additionResponsibilities'))}
							title='Additional Responsibilities'
							data={aditionalResponsibilities}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'additionResponsibilities'}
							renderItem={(resp) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{resp.title}</p>
									<p className='text-sm text-gray-500'>{resp.organiser}</p>
									<p className='text-sm text-gray-500'>
										{resp.startDate} - {resp.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Members Bodies */}
					{membersBodies && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'membershipInAcademic'))}
							title='Member Bodies'
							data={membersBodies}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'membershipInAcademic'}
							renderItem={(member) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{member.name}</p>
									<p className='text-sm text-gray-500'>{member.type}</p>
									<p className='text-sm text-gray-500'>
										{member.startDate} - {member.endDate}
									</p>
								</div>
							)}
						/>
					)}

					{/* Travel Details */}
					{travelDetails && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'travelingHistory'))}
							title='Travel Details'
							data={travelDetails}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'travelingHistory'}
							renderItem={(travel) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>
										{travel.typeOfVehicle} ({travel.typeOfEnergy})
									</p>
									<p className='text-sm text-gray-500'>
										From: {travel.travelStart} To: {travel.travelTo} - {travel.km} km
									</p>
									{travel.coTraveler1 && (
										<p className='text-sm text-gray-500'>
											Co-travelers: {travel.coTraveler1}, {travel.coTraveler2}, {travel.coTraveler3}
										</p>
									)}
								</div>
							)}
						/>
					)}

					{/* Books Chapters */}
					{bookChapter && (
						<DetailSection2
							handleClick={() => setEditModal(pages.find((page) => page.keyData === 'booksChapters'))}
							title='Books & Chapters'
							data={bookChapter}
							seeMore={seeMore}
							setSeeMore={setSeeMore}
							keyName={'booksChapters'}
							renderItem={(book) => (
								<div className='p-4 w-full'>
									<p className='font-medium'>{book.titleBookChapter}</p>
									<p className='text-sm text-gray-500'>{book.type}</p>
								</div>
							)}
						/>
					)}

					{/* Other Information */}
					{/* <Section title='Other Information'>
						<InfoRow label='Second Language' value={secondLanguage} />
						<InfoRow label='Annual Income' value={annualIncome} />
						<InfoRow label='Hungry Coordinator' value={hungryCordinator ? 'Yes' : 'No'} />
					</Section> */}

					{/* Verification Status */}
					{/* <Section title='Verification Status'>
						<InfoRow label='Department Verification' value={verification.department ? 'Verified' : 'Pending'} />
						<InfoRow label='Nodal Verification' value={verification.nodal ? 'Verified' : 'Pending'} />
						<InfoRow label='Principal Verification' value={verification.principal ? 'Verified' : 'Pending'} />
						<InfoRow label='PTA Verification' value={verification.pta ? 'Verified' : 'Pending'} />
						<InfoRow label='Office Verification' value={verification.office ? 'Verified' : 'Pending'} />
						<InfoRow label='University Verification' value={verification.university ? 'Verified' : 'Pending'} />
					</Section> */}

					{/* Admission Details */}
					{/* {(admisionNo || dateOfAdmission || admissionto) && (
						<Section title='Admission Details'>
							<InfoRow label='CAP ID' value={capId} />
							<InfoRow label='Admission Number' value={admisionNo} />
							<InfoRow label='Admission Type' value={admissionto?.meritQuata ? 'Merit' : 'Other'} />
							<InfoRow label='Date of Admission' value={dateOfAdmission} />
							<InfoRow label='Index' value={index} />
							<InfoRow label='RC Group' value={rcGroup} />
							<InfoRow label='RC Mark' value={rcMark} />
						</Section>
					)} */}

					{/* Certificates */}
					{/* <Section title='Certificates'>
						{certificates && (
							<ul className='list-disc list-inside'>
								{Object.entries(certificates).map(([key, value]) => (
									<li key={key}>
										{key.replace('_', ' ')}: {value ? 'Submitted' : 'Pending'}
									</li>
								))}
							</ul>
						)}
					</Section> */}
				</div>
			</div>
			{editModal?.keyData && (
				<Main width='100vw' height='100vh'>
					{editModal?.keyData === 'profileInformation' && (
						<PersonalInfo
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'addressDetails' && (
						<AddressInfo
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'officialInformation' && (
						<OfficialInfo
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'bankInformations' && (
						<BankDetails
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'educationalQualification' && (
						<Qualification
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'experienceAndServiceDetails' && (
						<Experience
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'publications' && (
						<Publications
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'workshops' && (
						<Workshops
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'seminar' && (
						<Seminars
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'culturalActivity' && (
						<CulturalActivies
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'fdp' && (
						<FDP
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'additionResponsibilities' && (
						<AddtionalInformation
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'membershipInAcademic' && (
						<Members
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'travelingHistory' && (
						<Traveling
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
					{editModal?.keyData === 'booksChapters' && (
						<BookChapters
							modal={true}
							studentId={null}
							onClose={() => {
								setEditModal('');
								refetch();
							}}
						/>
					)}
				</Main>
			)}
		</div>
	);
};

export default DashBoard;
