import React, { useContext, useRef, useState, useMemo } from 'react';
import { Body, Footer, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getNewClasses } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getClassName } from '../Form/Component';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;
	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

const getAllMinorsSelected = (collegeId: any, classId: any, printable: boolean) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fyugp/allotmentReportClassWise', {
			params: { collegeId, classId, printable },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface InvoiceManagementProps {
	onClose: () => void;
}

function UniversityUploadList({ onClose }: InvoiceManagementProps) {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [printable, setPritable] = useState(true);
	const { data: tableData = [] } = useQuery(['getAllMinorcsdssSelected', collegeId, selectedClass, printable], getAllMinorsSelected(collegeId, selectedClass, printable));
	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId));
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const renderTableRows = useMemo(() => {
		return tableData.map((x: any, i: number) => (
			<Tr key={i}>
				<Td index={i} position={2}>
					{x.capId}
				</Td>
				<Td index={i} position={1}>
					{x.name}
				</Td>
				<Td index={i} position={2}>
					{String(x.rcMark).slice(0, 7)}
				</Td>
				<Td index={i} position={2}>
					{x.rcGroup}
				</Td>
				<Td index={i} position={2}>
					{x.secondLanguage}
				</Td>
				<Td index={i} position={1}>
					{x.dsc_b_a}
				</Td>
				<Td index={i} position={1}>
					{x.dsc_b_ad}
				</Td>
				<Td index={i} position={1}>
					{x.dsc_c_a}
				</Td>
				<Td index={i} position={1}>
					{x.dsc_c_ad}
				</Td>
				<Td index={i} position={1}>
					{x.mdc_a}
				</Td>
				<Td index={i} position={1}>
					{x.mdc_ad}
				</Td>
			</Tr>
		));
	}, [tableData, printable]);

	return (
		<>
			<Main title='Student Selected Subject List FYUGP' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
					</div>
					<div className='flex'>
						<Button type={printable ? 'search' : 'update'} onClick={() => setPritable(!printable)}>
							{printable ? 'Hide Printable' : 'Show Printable'}
						</Button>
						<Button type='print' fileName={getClassName(selectedClass, classes?.list).replace(/\./g, '')} tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<table className='min-w-full table-auto'>
							<thead>
								<tr>
									<td className='p-1 bg-white' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='p-1 text-center text-black bg-white text-xl font-bold' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										Course: {getClassName(selectedClass, classes?.list)}
									</td>
								</tr>
								<tr>
									<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										First Trial Allotment
									</td>
								</tr>
								<tr>
									<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
										The first trial allotment was successfully retrieved from NECTTOS on - {moment(new Date()).format('DD-MM-YYYY hh:mm A')}
									</td>
								</tr>
							</tbody>
						</table>
						<Table width={printable ? '100%' : '2000px'} innerWidth={['10%', '10%', '5%']}>
							<Thead>
								<Tr>
									<Th textAlign='center' position={2}>
										CAP ID
									</Th>
									<Th textAlign='center' position={1}>
										Name
									</Th>
									<Th textAlign='center' position={2}>
										Mark
									</Th>
									<Th textAlign='center' position={2}>
										RC
									</Th>
									<Th textAlign='center' position={2}>
										AEC_OL
									</Th>
									<Th textAlign='center' position={1}>
										Minor 1
									</Th>
									<Th textAlign='center' position={1}>
										Minor 1 Dept
									</Th>
									<Th textAlign='center' position={1}>
										Minor 2
									</Th>
									<Th textAlign='center' position={1}>
										Minor 2 Dept
									</Th>
									<Th textAlign='center' position={1}>
										MDC
									</Th>
									<Th textAlign='center' position={1}>
										MDC Dept
									</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>{renderTableRows}</Tbody>
						</Table>
					</div>
				</Body>
			</Main>
		</>
	);
}

export default UniversityUploadList;
