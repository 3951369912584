import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import './style.css'


const Loader = () => {
  const [currentWord, setCurrentWord] = useState([
    "Please Wait: Ensuring Your Safety with Comprehensive Security Checks...",
  ]);
  const { user } = useContext(FirebaseContext);

  useEffect(() => {
    const words = [
      "Please Wait: Ensuring Your Safety with Comprehensive Security Checks...",
      "Please Wait: Confirming Your Session for a Secure Login Experience...",
      "Please Wait: Establishing a Protected Connection with Our Secure Server...",
      "Please Wait: Verifying All Security Standards are Met with Excellence...",
      user?.username
        ? "Welcome to Necttos. Thank you for Waiting. Your Secure Login is Successful..."
        : "Your Session expired. Please Login again",
    ];
    let currentWordIndex = 0;

    const intervalId = setInterval(() => {
      setCurrentWord(prevWords => [...prevWords, words[currentWordIndex]]);
      currentWordIndex = (currentWordIndex + 1) % words.length;
    }, 1000);

    return () => clearInterval(intervalId);
  }, [user?.username]);

  return (
    <div className="flex justify-center flex-col items-center h-screen">
      {currentWord.map((word, index) => (
        <h1 key={index} className="text-6xl font-bold valid-color text-center transition-opacity duration-1000 fade-in">
          {word}
        </h1>
      ))}
    </div>
  );
};

export default Loader;
