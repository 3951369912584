import React, { useContext, useEffect, useState } from 'react';
import { Body, Header, Main, closeAnimation } from '../NecttosComp/Layout/Layout';
import Button from '../NecttosComp/Button/Button';
import { validateRole } from '../utils';
import { useProfileData } from '../NecttosComp/APICaller/queryHooks';
import { FirebaseContext } from '../context/FirebaseContext';
import AddressInfo from './ProfileComponents/AddressInfo';
import PersonalInfo from './ProfileComponents/PersonalInfo';
import OfficialInfo from './ProfileComponents/OfficialInfo';
import Qualification from './ProfileComponents/Qualification';
import Experience from './ProfileComponents/Experiance';
import AddtionalInformation from './ProfileComponents/AddtionalResponsibilities';
import Members from './ProfileComponents/Members';
import Seminars from './ProfileComponents/Seminars';
import BankDetails from './ProfileComponents/BankDetails';
import CulturalActivies from './ProfileComponents/CulturalActivies';
import Traveling from './ProfileComponents/Traveling';
import Publications from './ProfileComponents/Publications';
import BooksChapters from './ProfileComponents/BooksChapters';
import Photo from './ProfileComponents/Photo';
import FDP from './ProfileComponents/FDP';
import Workshops from './ProfileComponents/workshop';
import DashBoard from './ProfileComponents/Dashboard';
import { Progress } from 'antd';
import { RiImageEditFill } from 'react-icons/ri';
import Document from './ProfileComponents/Documents';
import Family from './ProfileComponents/Family';

interface ProfileProps {
	onClose: () => void;
	windows: any;
	setWindows: (val: any) => void;
}

export default function NewProfile({ onClose, windows, setWindows }: ProfileProps) {
	const { collegeId } = useContext(FirebaseContext);
	const [editModal, setEditModal] = useState<any>({});
	const [refresh, setRefresh] = useState<any>(false);

	const { data, refetch } = useProfileData();

	useEffect(() => {
		refetch();
	}, [refresh]);

	console.log({ data });

	const pages = [
		{ classification: 'Profile', keyData: 'dashboard', title: 'Dashboard Info', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'profileInformation', title: 'Personal Info', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'officialInformation', title: 'Official Info', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'addressDetails', title: 'Address', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'profilePhoto', title: 'Profile Photo', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'educationalQualification', title: 'Qualifications', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'family', title: 'Family Members', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },
		{ classification: 'Profile', keyData: 'experienceAndServiceDetails', title: 'Experience and Service Details', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'additionResponsibilities', title: 'Additional Responsibilities', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'membershipInAcademic', title: 'Members in Academic and Professional Bodies', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'seminar', title: 'Seminars, Symposia, Conferences Attended', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'workshops', title: 'Workshops Attended', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'fdp', title: 'FDPs Attended', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'bankInformations', title: 'Bank Information', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'culturalActivity', title: 'Co-curricular Activities', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'travelingHistory', title: 'Travel Info', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
		{ classification: 'Profile', keyData: 'publications', title: 'Publications and Patents', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'booksChapters', title: 'Books and Chapters', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'principal'] },
		{ classification: 'Profile', keyData: 'document', title: 'Document', instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },
	];

	const ProgressBar: React.FC<any> = ({ progress, title = 'Completed' }) => {
		return (
			<div className='relative h-[16px] border-spacing-1 rounded'>
				<div className='bg-[#007bffec] border-spacing-1 border-solid border-blue-700  h-full rounded-xl' style={{ width: `${progress}%` }} />
				<div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
					<span className={`text-sm font-medium text-[1.2vh] ${progress > 48 ? 'text-white' : 'text-gray-800'}`}>{`${progress}% ${title}`}</span>
				</div>
			</div>
		);
	};

	const [selectedPage, setSelectedPage] = useState<any>(pages[0]);
	const [hoveredItem, setHoveredItem] = React.useState<any>(null);
	console.log({ selectedPage });

	const handlePageSelect = (page: any) => {
		setSelectedPage(page);
		console.log({ page });
	};
	const handleNextPage = () => {
		let pageIndex = pages.findIndex((x) => x?.keyData === selectedPage?.keyData);
		if (pageIndex === -1) {
			setSelectedPage(pages[0]);
			return;
		}

		let nextPageIndex = pageIndex;
		let found = false;
		for (let i = 0; i < pages.length; i++) {
			nextPageIndex = (nextPageIndex + 1) % pages.length;
			const nextPage = pages[nextPageIndex];

			if (validateRole(nextPage.allowedUsers, data?.collegeRoles, collegeId)) {
				setSelectedPage(nextPage);
				found = true;
				break;
			}
		}

		if (!found) {
			setSelectedPage(pages[0]);
		}
	};

	const handleCardClick = (keyData: string) => {
		setWindows({ ...windows, [keyData]: true });
	};

	const colourData = {
		primaryTextColourLite: 'black',
		primaryBgColourLite: 'white',
		primaryBgColourLite2: '#CCCCCC',
		primaryHoverColourLite: 'gray',
		primarySelectColourLite: '#697565',

		bgColour: 'white',
		textColour: 'black',
		hoverColour: '#eaeafa',
		hoverTextColour: 'black',
		selectBgColour: '#191970',
		selectTextColour: 'white',
	};
	const [colours, setColours] = useState<any>(colourData);

	return (
		<>
			<Main title={'Profile Management'} height={'100vh'} width={'100vw'}>
				<Header width='100%' height='8%' title='Profile' className='mb-2'>
					<div className='flex items-center justify-between w-full h-full mb-10 rounded-md' style={{ backgroundColor: colours?.bgColour }}>
						<div className='flex justify-between items-center h-auto w-full'>
							<header className='w-full text-dark pl-4'>
								<h1 className='text-2xl font-bold'>Profile</h1>
							</header>
							<div className='pr-3'>
								<Button
									type='close'
									onClick={() => {
										closeAnimation();
										setTimeout(onClose, 250);
									}}>
									Close
								</Button>
							</div>
						</div>
					</div>
				</Header>
				<Body width='100%' height='90%'>
					<div className='flex flex-col w-full h-full pt-2 rounded-md'>
						<main className='flex w-full h-full gap-2'>
							<aside className='w-[300px] h-full shadow-md rounded-md' style={{ backgroundColor: colours?.bgColour }}>
								<div className='flex justify-center items-center w-full h-auto p-3 relative group'>
									<img src={data?.photoURL} alt={data?.name} className='rounded' style={{ width: '220px', height: '220px', objectFit: 'cover' }} />
									<span className='absolute right-14 top-5 opacity-5 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer' onClick={() => setEditModal(pages.find((page) => page.keyData === 'profilePhoto'))}>
										<RiImageEditFill />
									</span>
								</div>

								<nav className='w-full h-[calc(100%-280px)] overflow-hidden flex flex-col'>
									<ul className='flex-1 overflow-y-auto m-0 p-0'>
										{pages?.map(
											(item, _i) =>
												validateRole(item.allowedUsers, data?.collegeRoles, collegeId) && (
													<li
														key={item.keyData}
														style={{
															backgroundColor: selectedPage?.keyData === item.keyData ? colours?.selectBgColour : hoveredItem === item.keyData ? colours?.hoverColour : '',
															color: selectedPage?.keyData === item.keyData ? colours?.selectTextColour : hoveredItem === item.keyData ? colours.hoverTextColour : '',
														}}
														className='cursor-pointer font-bold shadow-md transition px-3 py-2 flex items-center h-16 overflow-hidden'
														onMouseEnter={() => setHoveredItem(item?.keyData)}
														onMouseLeave={() => setHoveredItem(null)}
														onClick={() => handlePageSelect(item)}>
														{item?.title}
													</li>
												),
										)}
									</ul>
								</nav>
							</aside>

							<section className='flex-grow w-full h-full  p-0 overflow-y-scroll rounded-md' style={{ backgroundColor: colours?.bgColour }}>
								{selectedPage?.keyData === 'profileInformation' && (
									<PersonalInfo
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'addressDetails' && (
									<AddressInfo
										modal={false}
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'membershipInAcademic' && (
									<Members
										modal={false}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'officialInformation' && (
									<OfficialInfo
										modal={false}
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'experienceAndServiceDetails' && (
									<Experience
										modal={false}
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'additionResponsibilities' && (
									<AddtionalInformation
										modal={false}
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'seminars' && (
									<Seminars
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'workshops' && (
									<Workshops
										modal={false}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'travelingHistory' && (
									<Traveling
										modal={false}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'educationalQualification' && (
									<Qualification
										modal={false}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'publications' && (
									<Publications
										refresh={refresh}
										setRefresh={setRefresh}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'booksChapters' && (
									<BooksChapters
										refresh={refresh}
										setRefresh={setRefresh}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'culturalActivity' && (
									<CulturalActivies
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'bankInformations' && (
									<BankDetails
										modal={false}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'family' && (
									<div className='fixed inset-0 bg-[#00000045] flex justify-center windows-center'>
										<Family
											onClose={() => {
												setSelectedPage(pages[0]);
												setRefresh(!refresh);
											}}
										/>
									</div>
								)}
								{selectedPage?.keyData === 'document' && (
									<div className='fixed inset-0 bg-[#00000045] flex justify-center windows-center'>
										<Document
											onClose={() => {
												setSelectedPage(pages[0]);
												setRefresh(!refresh);
											}}
										/>
									</div>
								)}
								{selectedPage?.keyData === 'fdp' && (
									<FDP
										modal={false}
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'profilePhoto' && (
									<Photo
										studentId={null}
										onNext={() => {
											handleNextPage();
											setRefresh(!refresh);
										}}
										onClose={() => {
											setSelectedPage(pages[0]);
											setRefresh(!refresh);
										}}
									/>
								)}
								{selectedPage?.keyData === 'dashboard' && data && (
									<DashBoard
										setSelectedPage={setSelectedPage}
										refresh={refresh}
										setRefresh={setRefresh}
										pages={pages}
										onClose={() => {
											setSelectedPage(pages[0]);
										}}
									/>
								)}
							</section>
						</main>
					</div>
				</Body>
			</Main>
			{editModal?.keyData && (
				<Main width='80vw' height='90vh'>
					{editModal?.keyData === 'profilePhoto' && (
						<Photo
							studentId={null}
							onNext={() => {
								handleNextPage();
								setRefresh(!refresh);
							}}
							onClose={() => {
								setEditModal('');
							}}
						/>
					)}
				</Main>
			)}
		</>
	);
}

export const ProfileDataDisplayKeyVlaue = ({ temp, placeHoldValues, title, needVerification }: any) => {
	return (
		<div className='bg-white p-6 rounded-lg  w-full'>
			<h5 className='text-xl font-semibold text-gray-800 mb-4'>{title}</h5>
			<div id='scrollBarEnable' className='space-y-4 max-h-[68vh] overflow-auto'>
				{Object.entries(placeHoldValues).map(([key, label]: any) => {
					const value = temp?.[key];
					if (!value) {
						return null;
					}

					return (
						<div key={key} className='grid grid-cols-[1fr,1fr] gap-2 w-full'>
							<label className='text-xs font-medium text-gray-600 leading-3'>{label}</label>
							<p className='text-sm font-semibold text-gray-800 leading-3'>{value || '—'}</p>
						</div>
					);
				})}
			</div>
			{needVerification && <p className='text-sm text-red-600 mt-4'>Please wait for the verification process to complete for your updated data.</p>}
		</div>
	);
};

interface ProgressBarProps {
	progress: number;
	title?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, title = 'Completed' }) => {
	return (
		<div className='w-full'>
			<div className='flex justify-between mb-1'>
				<span className='text-sm font-medium text-gray-700'>{title}</span>
				<span className='text-sm font-medium text-gray-700'>{`${progress}%`}</span>
			</div>
			<div className='flex w-full h-4 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700' role='progressbar' aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100}>
				<div className='flex flex-col justify-center rounded-full bg-blue-600 text-xs text-white text-center whitespace-nowrap transition-all duration-500 ease-in-out' style={{ width: `${progress}%` }}>
					{progress}% {title}
				</div>
			</div>
		</div>
	);
};

export const calculateProfilepPercentage = (entries: Record<string, any>, keys: any) => {
	const totalValues = Object?.keys(keys)?.length;
	let filled = 0;
	for (let i in keys) {
		if (entries?.[i]) {
			filled++;
		}
	}
	return {
		percentage: ((filled / totalValues) * 100).toFixed(0),
		totalValuesUnfilled: filled,
	};
};

export const PrgressCircle: React.FC = ({ percent }: any, text) => (
	<div className='flex flex-col justify-center items-center'>
		<Progress type='circle' percent={percent} strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
		{percent >= 100 ? <p className='font-semmibold mt-2'>{percent}% completed</p> : <p className='font-semmibold mt-2'>{percent}% filled</p>}
	</div>
);

export const ProfileDataDisplay: React.FC<any> = ({ temp }) => {
	return (
		<div className='p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto'>
			<h5 className='text-blue-900 font-bold text-lg mb-2'>Your Current Data</h5>
			{Object.entries(temp || {}).map(([key, value]) => {
				if (['_id', 'matchKey', 'deleted', 'new', 'edited'].includes(key)) {
					return null;
				}

				let formattedKey = key === 'yearOfPass' ? 'Year of Pass' : key.replace(/([A-Z])/g, ' $1').trim();
				formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

				const displayValue = typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value);

				return (
					<div key={key} className='flex flex-col items-start my-2 p-2 bg-gray-50 rounded-lg shadow-sm w-full'>
						<label className='text-xs font-semibold text-gray-600 mb-1'>{formattedKey}:</label>
						<p className='text-base font-medium text-gray-800 pl-2'>{displayValue || '—'}</p>
					</div>
				);
			})}
		</div>
	);
};
