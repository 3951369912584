import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { useQuery, useQueryClient } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import MissingNumbersModal from '../../NecttosComp/MissingNumberModal';
import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
type AddNewEntry = {
	admisionNo?: string;
	name?: string;
	dob?: string;
	dateOfAdmission?: string;
	classId?: string;
	gender?: string;
	religion?: string;
	category?: string;
	caste?: string;
	tcNumber?: string;
	tcDate?: string;
	activeStatus?: string;
};

export const getAllClasses = (collegeId: string) => async () => {
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/classes/getClass', {
			params: { collegeId, needAll: true, classOnly: true },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data;
	} else {
		throw new Error(data?.message);
	}
};

export const getUsersDataBy = (collegeId: string, filterData: {}) => async () => {
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/users/getUsersDataBy', {
			params: { collegeId, ...filterData },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data;
	} else {
		throw new Error(data?.message);
	}
};

function BasicDataVerification({ onClose }: { onClose: () => void }) {
	const { collegeId } = useContext(FirebaseContext);
	const [values, setValues] = useState<any[]>([]);
	const [isSaving, setIsSaving] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [addNew, setAddNew] = useState<AddNewEntry>({});
	const [addNewModal, setAddNewModal] = useState(false);
	const [isDupplicate, setBuplicate] = useState(false);
	const [isOffline, setIsOffline] = useState(!navigator.onLine);

	const datadsdsd = { needAll: true, classOnly: true, subId: false };
	const allClasses = useAllClasses(datadsdsd);

	const classList = allClasses?.data || [];

	const [filterData, setOptions] = useState<{}>(() => {
		try {
			const savedData = localStorage.getItem('filterData');
			return savedData ? JSON.parse(savedData) : {};
		} catch (error) {
			console.error('Failed to parse local storage data for filterData:', error);
			return {};
		}
	});

	useEffect(() => {
		try {
			localStorage.setItem('filterData', JSON.stringify(filterData));
		} catch (error) {
			console.error('Failed to save filterData to local storage:', error);
		}
	}, [filterData]);

	const [currentPage, setCurrentPage] = useState(1);
	const rowsPerPage = 100;

	const { data: dataData } = useQuery(['getUsersDataBy', collegeId, filterData], getUsersDataBy(collegeId, filterData), {
		staleTime: 1000 * 60 * 50,
		cacheTime: 1000 * 60 * 100,
	});

	const queryClient = useQueryClient();

	const handleReload = async () => {
		await queryClient.fetchQuery(['getUsersDataBy', collegeId, filterData], () => getUsersDataBy(collegeId, filterData));
	};

	useEffect(() => {
		if (Array.isArray(dataData?.list)) {
			setValues(dataData.list);
		}
	}, [dataData]);

	const totalPages = Math.ceil(values.length / rowsPerPage);
	const currentRows = values.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	const handleChange = (e: any, i: number) => {
		const { name, value } = e.target;
		const updatedValues = [...values];
		updatedValues[i] = { ...updatedValues[i], [name]: value };
		setValues(updatedValues);
	};

	const handleChangeClasses = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
		const { value } = e.target;
		const selectedOption = classList.find((item: any) => item._id === value);

		const updatedValues = [...values];
		updatedValues[i] = {
			...updatedValues[i],
			classId: selectedOption?._id || '',
			className: selectedOption?.className || '',
		};
		setValues(updatedValues);
	};

	const handleSave = async () => {
		setIsSaving(true);
		const instance = await getAxiosTokenInstance();
		try {
			await instance.post('/college/users/updateBulkUsers', { data: values, collegeId });
			console.log('Save successful');
			queryClient.invalidateQueries(['getUsersDataBy', collegeId, filterData]);
			window.location.reload();
		} catch (err) {
			console.error('Save failed', err);
		} finally {
		}
	};
	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			console.log(val);
		};

		getDropDowns();
	}, [collegeId]);

	// Render Pagination Controls
	const renderPaginationControls = () => (
		<div className='flex justify-between items-center mt-4'>
			<Button type='fetch' disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
				Previous
			</Button>
			<span>
				Page {currentPage} of {totalPages}
			</span>
			<Button type='save' disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
				Next
			</Button>
		</div>
	);

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postMissingStudents', { ...addNew, collegeId })
				.then(() => {
					queryClient.invalidateQueries(['getUsersDataBy', collegeId, filterData]);
					window.location.reload();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			throw new Error('API Error');
		}
	};

	// Calculate filled counts for all fields
	const calculateFilledCounts = () => {
		const fieldCounts: { [key: string]: number } = {
			admisionNo: 0,
			name: 0,
			dob: 0,
			dateOfAdmission: 0,
			gender: 0,
			religion: 0,
			category: 0,
			caste: 0,
			tcNumber: 0,
			tcDate: 0,
			activeStatus: 0,
		};

		values.forEach((row) => {
			Object.keys(fieldCounts).forEach((key) => {
				if (row[key] && row[key] !== '') {
					fieldCounts[key]++;
				}
			});
		});

		return fieldCounts;
	};

	// Use the function to get filled counts
	const filledCounts = calculateFilledCounts();
	useEffect(() => {
		const handleOffline = () => setIsOffline(true);
		const handleOnline = () => setIsOffline(false);

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
		};
	}, []);

	return (
		<Main title='Bulk Student Data Editor' height='90vh' width='98vw'>
			<Header>
				<div className='flex'>
					<Input width='300px' didntShowKey fieldName='Select Class' optionDisplay='className' returnKey='classId' optionKey='_id' state={filterData} setState={setOptions} options={classList} type='drop' />
					<Input width='200px' fieldName='Year' returnKey='year' state={filterData} setState={setOptions} options={['2020', '2021', '2022', '2023', '2024', '2025', '2026']} type='drop' />
					<Input width='200px' fieldName='Mode' returnKey='mode' state={filterData} setState={setOptions} options={['Self', 'Aided']} type='drop' />
					<Input width='200px' fieldName={'Start Adm No'} returnKey='admisionStarts' state={filterData} setState={setOptions} type='number' />
					<Input width='200px' fieldName={'Ends Adm No'} returnKey='admisionEnds' state={filterData} setState={setOptions} type='number' />
					<Input width='200px' fieldName='Search' returnKey='name' state={filterData} setState={setOptions} type='text' />
				</div>
				<div className='flex'>
					<Button type='fetch' onClick={handleReload}>
						Remove Cache and Reload
					</Button>
					<Button type='save' onClick={handleSave}>
						Save
					</Button>
					<Button
						type='save'
						onClick={() => {
							setAddNewModal(true);
						}}>
						Add New
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>

			<Table width='100%' innerWidth={['200px', '170px', '160px', '120px', '90px', '250px']}>
				<Thead>
					<Tr>
						<Th position={4}>SN</Th>
						<Th position={3}>Adm.No.</Th>
						<Th position={0}>Name</Th>
						<Th position={5}>Class Name</Th>
						<Th position={2}>Date of Birth</Th>
						<Th position={2}>Date of Adm.</Th>
						<Th position={3}>Gender</Th>
						{/* <Th position={3}>Admitted</Th> */}
						<Th position={3}>Religion</Th>
						<Th position={4}>Catogory</Th>
						<Th position={5}>Caste</Th>
						<Th position={2}>Status</Th>
						<Th position={4}>TC Number</Th>
						<Th position={2}>TC Date</Th>
					</Tr>
				</Thead>

				<Tbody height='69vh'>
					<Header>
						<div className='container mx-auto p-6'>
							<h1 className='text-3xl font-extrabold mb-8 text-center text-gray-800'>Missing Details</h1>
							<div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-5'>
								<div className='bg-gradient-to-r from-blue-200 to-blue-400 text-gray-800 p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center'>
									<h2 className='text-lg font-medium mb-2'>Start Number</h2>
									<p className='text-5xl font-extrabold'>{dataData?.start}</p>
								</div>
								<div className='bg-gradient-to-r from-green-200 to-green-400 text-gray-800 p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center'>
									<h2 className='text-lg font-medium mb-2'>End Number</h2>
									<p className='text-5xl font-extrabold'>{dataData?.end}</p>
								</div>
								<div
									onClick={() => {
										setIsModalOpen(true);
									}}
									className='bg-gradient-to-r from-yellow-200 to-yellow-300 text-gray-800 p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center'>
									<h2 className='text-lg font-medium mb-2'>Missing</h2>
									<p className='text-5xl font-extrabold'>{dataData?.missingNumbers?.length}</p>
								</div>
								<div
									onClick={() => {
										setBuplicate(true);
									}}
									className='bg-gradient-to-r from-yellow-200 to-yellow-300 text-gray-800 p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center'>
									<h2 className='text-lg font-medium mb-2'>Duplicate</h2>
									<p className='text-5xl font-extrabold'>{dataData?.duplicateNumbers?.length}</p>
								</div>
								<div className='bg-gradient-to-r from-red-200 to-red-400 text-gray-800 p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center'>
									<h2 className='text-lg font-medium mb-2'>Total Count</h2>
									<p className='text-5xl font-extrabold'>{dataData?.count}</p>
								</div>
							</div>
							<div className='flex flex-wrap items-center justify-between gap-2 p-2'>
								{Object.entries(filledCounts).map(([key, count]) => (
									<div key={key} className='bg-blue-900 border border-blue-900 rounded-md p-1 text-center flex flex-col items-center justify-center w-[80px]'>
										<span className='text-xs font-medium'>{key}</span>
										<span className='text-sm font-bold'>{count}</span>
									</div>
								))}
							</div>
						</div>

						{isModalOpen && (
							<MissingNumbersModal
								missingNumbers={dataData?.missingNumbers}
								title={'Missing Numbers'}
								message={''}
								onClose={() => {
									setIsModalOpen(false);
								}}
							/>
						)}
						{isOffline && (
							<MissingNumbersModal
								missingNumbers={[]}
								title='No Network Connection'
								message='It seems you are offline. Please check your internet connection.'
								onClose={() => setIsOffline(false)} // Optional, if you want a close button
							/>
						)}

						{isDupplicate && (
							<MissingNumbersModal
								missingNumbers={dataData?.duplicateNumbers}
								message={''}
								title={'Duplicate Numbers'}
								onClose={() => {
									setBuplicate(false);
								}}
							/>
						)}
						{isSaving && (
							<MissingNumbersModal
								missingNumbers={[]}
								title='Saving'
								message='Saving Items'
								onClose={() => {
									setIsSaving(false);
								}}
							/>
						)}
					</Header>
					{currentRows?.map((x: any, i: number) => (
						<Tr>
							<Td position={4} padding={'1px'} index={i}>
								{i + 1}
							</Td>
							<Td position={3} padding={'1px'} index={i}>
								<input type='text' value={x?.admisionNo || ''} name='admisionNo' onChange={(e) => handleChange(e, i)} />
							</Td>
							<Td position={0} padding={'1px'} index={i}>
								<input type='text' value={x?.name || ''} name='name' onChange={(e) => handleChange(e, i)} />
							</Td>
							<Td position={5} padding={'1px'} index={i}>
								<select value={x.classId || ''} onChange={(e) => handleChangeClasses(e, i)} style={{ width: '100%', padding: '5px' }}>
									<option value='' disabled>
										Select Class
									</option>
									{classList.map((option: any) => (
										<option key={option._id} value={option._id}>
											{option.className}
										</option>
									))}
								</select>
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								<input type='date' name='dob' onChange={(e) => handleChange(e, i)} value={x?.dob ? (Date.parse(x.dob) ? new Date(x.dob).toISOString().split('T')[0] : '') : ''} />
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								<input type='date' name='dateOfAdmission' onChange={(e) => handleChange(e, i)} value={x?.dateOfAdmission ? (Date.parse(x.dateOfAdmission) ? new Date(x.dateOfAdmission).toISOString().split('T')[0] : '') : ''} />
							</Td>
							<Td position={3} padding={'1px'} index={i}>
								<select value={x?.gender || ''} name='gender' onChange={(e) => handleChange(e, i)}>
									{dataData?.gender?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							{/* <Td position={3} padding={'1px'} index={i}>
										<select value={x?.admType || ''} name='admType' onChange={(e) => handleChange(e, i)}>
											{dataData?.adminType?.map((z: any) => (
												<option value={z}>{z}</option>
											))}
										</select>
									</Td> */}
							<Td position={3} padding={'1px'} index={i}>
								<select value={x?.religion || ''} name='religion' onChange={(e) => handleChange(e, i)}>
									{dataData?.religion?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={4} padding={'1px'} index={i}>
								<select value={x?.category || ''} name='category' onChange={(e) => handleChange(e, i)}>
									{dataData?.category?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={5} padding={'1px'} index={i}>
								<select value={x?.caste || ''} name='caste' onChange={(e) => handleChange(e, i)}>
									{dataData?.caste?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={3} padding={'1px'} index={i}>
								<select value={x?.activeStatus || ''} name='activeStatus' onChange={(e) => handleChange(e, i)}>
									{['.......', 'active', 'TC Issued', 'Roll Out']?.map((z: any) => (
										<option value={z}>{z}</option>
									))}
								</select>
							</Td>
							<Td position={4} padding={'1px'} index={i}>
								{x?.activeStatus !== 'active' && <input type='text' value={x?.tcNumber || ''} name='tcNumber' onChange={(e) => handleChange(e, i)} />}
							</Td>
							<Td position={2} padding={'1px'} index={i}>
								{x?.activeStatus !== 'active' && <input type='date' value={x?.tcDate || ''} name='tcDate' onChange={(e) => handleChange(e, i)} />}
							</Td>
						</Tr>
					))}
					{renderPaginationControls()}
				</Tbody>
			</Table>
			{addNewModal && (
				<Main width='40vw' height='100%'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								setAddNewModal(false);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<h2 className='text-lg font-bold mb-4'>Add New Entry</h2>
						<Input width='100%' fieldName='Admission No' returnKey='admisionNo' state={addNew} setState={setAddNew} type='text' />
						<Input width='100%' fieldName='Name' returnKey='name' state={addNew} setState={setAddNew} type='text' />
						<Input width='100%' fieldName='Date of Birth' returnKey='dob' state={addNew} setState={setAddNew} type='date' />
						<Input width='100%' fieldName='Date of Admission' returnKey='dateOfAdmission' state={addNew} setState={setAddNew} type='date' />
						<Input width='100%' didntShowKey fieldName='Select Class' optionDisplay='className' returnKey='classId' optionKey='_id' state={addNew} setState={setAddNew} options={classList} type='drop' />
						<Input width='100%' fieldName='Gender?*' returnKey='gender' state={addNew} setState={setAddNew} options={dataData?.gender} type='drop' />
						<Input width='100%' fieldName='Religion?*' returnKey='religion' state={addNew} setState={setAddNew} options={dataData?.religion} type='drop' />
						<Input width='100%' fieldName='Category?*' returnKey='category' state={addNew} setState={setAddNew} options={dataData?.category} type='drop' />
						<Input width='100%' fieldName='Caste?*' returnKey='caste' state={addNew} setState={setAddNew} options={dataData?.caste} type='drop' />
						<Input width='100%' fieldName='Status?*' returnKey='activeStatus' state={addNew} setState={setAddNew} options={['active', 'TC Issued', 'Roll Out']} type='drop' />
						{addNew?.activeStatus === 'TC Issued' && (
							<>
								<Input width='100%' fieldName='TC Number' returnKey='tcNumber' state={addNew} setState={setAddNew} type='text' />
								<Input width='100%' fieldName='TC Date' returnKey='tcDate' state={addNew} setState={setAddNew} type='date' />
							</>
						)}
						<Button
							type='save'
							onClick={() => {
								addNewForm();
							}}>
							Save
						</Button>
					</Body>
				</Main>
			)}
		</Main>
	);
}

export default BasicDataVerification;
