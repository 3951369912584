import React, { useContext, useState } from 'react';

import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useMutation } from 'react-query';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import CustomDropDownKey from '../../myComp/CustomDropDownKey';
import moment from 'moment';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

export const exportPdfExam = async (olddata) => {
	const instance = await getAxiosTokenInstance();
	let data = {
		collegeId: olddata.collegeId,
		classId: olddata.classId,
		semester: olddata.semester,
		sortBy: olddata.sortBy,
	};
	let url = [`/college/reportGeneration/apcGenerator`];
	try {
		const resp = await instance.post(...url, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const APC = ({ onClose }) => {
	const exportPdfMutationExam = useMutation(exportPdfExam);
	const [loading, setLoading] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const allClasses = useAllClasses({ needAll: true, classOnly: true });
	const allSemester = [
		{ _id: '1', name: 'Semester 1' },
		{ _id: '2', name: 'Semester 2' },
		{ _id: '3', name: 'Semester 3' },
		{ _id: '4', name: 'Semester 4' },
		{ _id: '5', name: 'Semester 5' },
		{ _id: '6', name: 'Semester 6' },
		{ _id: '7', name: 'Semester 7' },
		{ _id: '8', name: 'Semester 8' },
		{ _id: '9', name: 'Semester 9' },
		{ _id: '10', name: 'Semester 10' },
	];
	const sortBy = [
		{ _id: 'name', name: 'Name of the student' },
		{ _id: 'rollNo', name: 'Attendance / Roll Number' },
		{ _id: 'admisionNo', name: 'College Admision Number' },
		{ _id: 'hallTicketNo', name: 'Halticket / Registeration Number' },
		{ _id: 'capId', name: 'CAP/Application Number' },
	];
	const onInternalMark = async () => {
		setLoading(true);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				classId: state.classId,
				semester: state.semester,
				sortBy: state.sortBy,
			};

			link.download = ('APC ' + allClasses?.data?.find((x) => x?._id === state?.classId)?.batchName + ' ' + allClasses?.data?.find((x) => x?._id === state?.classId)?.shortName + ' (Sem ' + state.semester + ') ' + moment(new Date()).format('DD-MM-YYYY')).replace('.', ' ');
			const pdfData = await exportPdfMutationExam.mutateAsync(postData);
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
			setLoading(false);
			onClose();
		} catch (e) {
			setLoading(false);
		}
	};

	const [state, setState] = useState({});
	return (
		<Main width='500px' height='500px'>
			<Button type='close' onClick={onClose}>
				Close
			</Button>

			<div>
				<label className='text-lg font-semibold text-gray-700'>DOWNLOAD APC</label>

				{loading ? (
					<label className='text-gray-500'>Your request is being processed… waiting for preparation…</label>
				) : (
					<>
						<view style={{ width: 550 }}>
							<CustomDropDownKey keyData={'classId'} keyObject={'className'} keyReturn={'_id'} titleData={'Select Class'} dropValues={allClasses?.data || []} stateData={state} setStateData={setState} />
						</view>
						<view style={{ width: 550 }}>
							<CustomDropDownKey keyData={'semester'} keyObject={'name'} keyReturn={'_id'} titleData={'Select Semester'} dropValues={allSemester} stateData={state} setStateData={setState} />
						</view>
						<view style={{ width: 550 }}>
							<CustomDropDownKey keyData={'sortBy'} keyObject={'name'} keyReturn={'_id'} titleData={'Sort By'} dropValues={sortBy} stateData={state} setStateData={setState} />
						</view>
						<Button type='save' onClick={onInternalMark}>
							Download
						</Button>
					</>
				)}
			</div>
		</Main>
	);
};
