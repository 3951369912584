import React, { useContext, useEffect, useRef, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import { AcademicYears, naacExcelGenerator } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { naacGET } from '../../NecttosComp/APICaller/APICaller';
import { useQuery } from 'react-query';
import MissingDataFinder from '../../NecttosComp/MissingDataFinder/MissingDataFinder';

function C2_1({ onClose }) {
	const [loading, setLoading] = useState(false);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [analyze, setAnalyze] = useState();
	const ref = useRef(null);
	const { collegeId } = useContext(FirebaseContext);

	const { data: values = [] } = useQuery(['criteria2_1', academicYear], naacGET({ endPoint: 'criteria2_1', params: { collegeId, academicYear } }));

	return (
		<>
			<Main title='2.1 Number of students during the year' height='80vh' width='80vw'>
				<Header>
					<div className='flex items-center'>
						<AcademicYears setAcademicYear={setAcademicYear} />
					</div>
					<div className='flex'>
						<Button type='update' onClick={() => setAnalyze(true)}>
							Analyze
						</Button>
						<Button pdfId='emp' type='pdf'>
							PDF
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button
							type='fetch'
							onClick={() =>
								naacExcelGenerator({
									data: { collegeId, academicYear: '2022-23' },
									url: '/college/NAAC/getNAAC_C2_1',
									setLoading,
								}).catch((err) => {
									console.log(err);
									setLoading(false);
								})
							}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='printable h-[60vh] overflow-x-auto'>
						<Table id='emp' width='100%' innerWidth={['25%', '25%', '25%', '25%']}>
							<Thead>
								<Tr>
									<Th position={0}>Year of Enrollment</Th>
									<Th position={1}>Name</Th>
									<Th position={2}>Student enrollment number</Th>
									<Th position={3}>Date of enrollment</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{values?.map((x, i) => (
									<Tr>
										<Td position={0} index={i}>
											{x?.yearOfEnrolment}
										</Td>
										<Td position={1} index={i}>
											{x?.name}
										</Td>
										<Td position={2} index={i}>
											{x?.studentEnrolmentNumber}
										</Td>
										<Td position={3} index={i}>
											{x?.dateOfEnrolment}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
			</Main>

			{analyze && (
				<MissingDataFinder
					data={values}
					onClose={() => setAnalyze(false)}
					values={[
						{ importance: true, name: 'yearOfEnrolment', title: 'Year of Enrollment' },
						{ importance: true, name: 'name', title: 'Name' },
						{ importance: true, name: 'studentEnrolmentNumber', title: 'Student enrollment number' },
						{ importance: true, name: 'dateOfEnrolment', title: 'Date of enrollment' },
					]}
				/>
			)}
			{/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto">
        <table className="table border-none">
          <thead>
           
            <tr>
              <th className="w-1/4">Year of Enrollment</th>
              <th className="w-1/4">Name</th>
              <th className="w-1/4">Student enrollment number</th>
              <th className="w-1/4">Date of enrollment</th>
            </tr>
          </thead>
          <tbody>
            {values?.map((x, i) => (
              <tr>
                <td>{x?.yearOfEnrolment}</td>
                <td>{x?.name}</td>
                <td>{x?.studentEnrolmentNumber}</td>
                <td>{x?.dateOfEnrolment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

			{loading ? (
				<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
					<LoadingAnimation />
				</div>
			) : null}
		</>
	);
}

export default C2_1;
