import React from 'react';
import './TC.css';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import Select from 'antd/lib/select';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { useState } from 'react';
import { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

const { Option } = Select;

function EditTc({ data, refetch, onClose }) {
	const { collegeId } = useContext(FirebaseContext);
	const [ClsName, setClsName] = useState(data?.classData?.classId);
	const [SemesterfromLeaving, setSemesterfromLeaving] = useState(data?.leaving);
	const [JoinedSem, setJoinedSem] = useState(data?.joinedSem);
	const [semester, setsemester] = useState(['l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [reason, setReason] = useState(['Course Completed', 'Discontinued', 'college Transfer', 'Higher Studies']);
	const [name, setName] = useState(data?.studentsDetails?.name);
	const [Exams, setExams] = useState(['Not attended', 'l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [Dob, setDob] = useState(moment(new Date(data?.studentsDetails?.dob)).format('yyyy-MM-DD'));
	const [DoAdmission, setDoAdmission] = useState(moment(new Date(data?.studentsDetails?.dateOfAdmission)).format('yyyy-MM-DD'));
	const [DoLeaving, setDoLeaving] = useState(moment(new Date(data?.dateOfLeaving)).format('yyyy-MM-DD'));
	const [RegisteNo, setRegisterNo] = useState(data?.studentsDetails?.hallTicketNo);
	const [Exam, setExam] = useState(data?.exam);
	const [AdNumber, setAdnumber] = useState(data?.studentsDetails?.admisionNo);
	const [Streason, setStreason] = useState(data?.reasonForLeaving);
	const [Letter, setLetter] = useState(data?.requestLetter);
	const [tcNumber, setTcNumber] = useState(data?.tcNumber);
	const [appliedOn, setAppliedOn] = useState(moment(new Date(data?.appliedOn)).format('yyyy-MM-DD'));
	const [aprovedOn, setAprovedOn] = useState(moment(new Date(data?.appliedOn)).format('yyyy-MM-DD'));
	const [Catogorys, setCatogorys] = useState(['GENERAL', 'OBC', 'OBC (H)', 'OEC', 'ST', 'SC']);
	const [Catogory, setCatogory] = useState(data?.studentsDetails?.catogory);

	const adsUserTc = async () => {
		const instance = await getAxiosTokenInstance();
		instance.post('/college/requestAprovals/editForTcCc', {
			collegeId: collegeId,
			fullName: name,
			dob: Dob,
			classId: ClsName,
			doa: DoAdmission,
			userId: data.studentsDetails.uid,
			requestLetterId: data?._id,
			admisionNo: AdNumber,
			category: Catogory,
			dol: DoLeaving,
			regNo: RegisteNo,
			tcNumber: tcNumber,
			joinedSem: JoinedSem,
			requestLetter: Letter,
			reasonForLeaving: Streason,
			exam: Exam,
			leaving: SemesterfromLeaving,
			appliedOn,
			aprovedOn,
		});
		refetch();
		onClose();
	};

	const getClassesList = async ({ queryKey }) => {
		// eslint-disable-next-line
		const [_, { collegeId }] = queryKey;
		const instance = await getAxiosTokenInstance();
		let resp;
		if (collegeId)
			try {
				resp = await instance.get('/college/classes/getClass', {
					params: { collegeId, needAll: true, classOnly: true },
				});

				if (resp.data.statusCode === 400) {
					throw new Error(resp.data.message || 'API Error');
				}
			} catch (error) {
				throw new Error(error?.response?.data?.message || error.message || 'API Error');
			}
		return resp?.data;
	};
	const classQuery = useQuery(['classes', { collegeId }], getClassesList);

	return (
		<div>
			<Row>
				<h2 style={{ fontFamily: 'fantasy' }}>Edit Tc</h2>
				<Col>
					<div>
						{' '}
						<label>Class Name</label>
						<br />
						<Select className='inputBox1' value={ClsName} onChange={(e) => setClsName(e)}>
							{classQuery?.data?.list?.map((classes) => (
								<Option key={classes.className} value={classes.classId}>
									{classes.className}
								</Option>
							))}
						</Select>
					</div>
					<div>
						{' '}
						<label>Full Name(as per SSLC)</label>
						<br />
						<input type='text' className='inputBox' placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)} />
						<br />
					</div>
					<div style={{ marginBottom: 20 }}>
						<label>Category</label>
						<br />
						<Select className='inputBox1' value={Catogory} onChange={(e) => setCatogory(e)}>
							{Catogorys?.map((classes) => (
								<Option key={classes} value={classes}>
									{classes}
								</Option>
							))}
						</Select>
					</div>
					<label htmlFor='Admission Number'>Admission Number</label>
					<br />
					<input type='text' className='inputBox1' placeholder='Admission Number' value={AdNumber} onChange={(e) => setAdnumber(e.target.value)} />
					<br />
					<div>
						<label htmlFor=''>Register Number</label>
						<br />
						<input type='text' className='inputBox' placeholder='Ex:-Hallticket Number' value={RegisteNo} onChange={(e) => setRegisterNo(e.target.value)} />
					</div>
					<div>
						<label htmlFor=''>Tc Number</label>
						<br />
						<input type='text' className='inputBox' placeholder='Tc Number' pattern='[0-9]*' value={tcNumber} onChange={(e) => setTcNumber(e.target.value)} />
					</div>

					<div>
						<label htmlFor=''>Date of Admission</label>
						<br />
						<input type='date' className='inputBox' placeholder='Date of Admission' value={DoAdmission} onChange={(e) => setDoAdmission(e.target.value)} />
					</div>
				</Col>
				<Col>
					<div>
						<label htmlFor=''>Date of Leaving</label>
						<br />
						<input type='date' className='inputBox' placeholder='Date of Leaving' value={DoLeaving} onChange={(e) => setDoLeaving(e.target.value)} />
					</div>

					<div>
						<label htmlFor=''>Date of Birth</label>
						<br />
						<input type='date' className='inputBox' placeholder='Date of Birth' value={Dob} onChange={(e) => setDob(e.target.value)} />
					</div>
					<div>
						<label htmlFor=''>Applied On</label>
						<br />
						<input type='date' className='inputBox' value={appliedOn} onChange={(e) => setAppliedOn(e.target.value)} />
					</div>
					<div>
						<label htmlFor=''>Aproved On</label>
						<br />
						<input type='date' className='inputBox' value={aprovedOn} onChange={(e) => setAprovedOn(e.target.value)} />
					</div>

					<div>
						<label htmlFor=''>Semester Joined</label>
						<br />
						<Select className='inputBox1' onChange={(e) => setJoinedSem(e)} value={JoinedSem}>
							{semester?.map((classes) => (
								<Option key={classes} value={classes}>
									{classes}
								</Option>
							))}
						</Select>
					</div>

					<div>
						<label htmlFor=''>Semester from Leaving</label>
						<br />

						<Select className='inputBox1' onChange={(e) => setSemesterfromLeaving(e)} value={SemesterfromLeaving}>
							{semester?.map((classes) => (
								<Option key={classes} value={classes}>
									{classes}
								</Option>
							))}
						</Select>
					</div>

					<div>
						<label>Examination last Appeared</label>
						<br />
						<Select className='inputBox1' value={Exam} onChange={(e) => setExam(e)}>
							{Exams?.map((classes) => (
								<Option key={classes} value={classes}>
									{classes}
								</Option>
							))}
						</Select>
					</div>
				</Col>
				<Col>
					<label htmlFor=''>Reason for Leaving</label>
					<br />
					<Select className='inputBox1' value={Streason} onChange={(e) => setStreason(e)}>
						{reason?.map((classes) => (
							<Option key={classes} value={classes}>
								{classes}
							</Option>
						))}
					</Select>

					<label>Write a Letter for release TC and CC to the Principal</label>
					<br />
					<input type='text' className='letter' value={Letter} onChange={(e) => setLetter(e.target.value)} />
					<br />
				</Col>
			</Row>
			<div className='editTc'>
				<button
					className='submitBtn'
					style={{ marginTop: 10 }}
					type='submit'
					onClick={(e) => {
						e.preventDefault();
						adsUserTc();
					}}>
					Submit
				</button>
			</div>
		</div>
	);
}

export default EditTc;
