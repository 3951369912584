import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../context/FirebaseContext";
import LoadingAnimation from "../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import moment from "moment";

const getFieldDropDowns = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/library/getClassesLibrary", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.classesData;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const getAllMembers = (data) => async () => {
  let { setLoading } = data;
  setLoading(true);
  const instance = await getAxiosTokenInstance();
  try {
    if (!data.classId) {
      throw new Error("ClassId Required");
    }
    const resp = await instance.get("/college/classes/getRollView", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    } else {
      setLoading(false);
      return resp?.data?.classList;
    }
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const NameSlip = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const [state, setState] = React.useState({});

  const componentRef = useRef();

  const { data = [] } = useQuery(["opacData"], getFieldDropDowns({ collegeId }), { keepPreviousData: true });
  let { data: allData = [], refetch } = useQuery(["members", state.classId, state.searchValue], getAllMembers({ collegeId: state.collegeId, classId: state.classId, setLoading }), { keepPreviousData: true });

  if (state.classId === "PhD" && collegeId === "COL-4E56593776BE-41C3-982A-0B6DC20E44A0") {
    allData.classData = {
      hod: "__",
      classTeacher: "__",
      className: "Ph.D Scholars",
    };
    allData.studentsRolles = [
      { activeStatus: "active", name: "Abhilash K S", admisionNo: "21991", dept: "Botany" },
      { activeStatus: "active", name: "Ambika. V", admisionNo: "19970", dept: "Botany" },
      { activeStatus: "active", name: "Aswani V J", admisionNo: "17943", dept: "Botany" },
      { activeStatus: "active", name: "Athira S", admisionNo: "20004", dept: "Botany" },
      { activeStatus: "active", name: "Gayathri T S", admisionNo: "221081", dept: "Botany" },
      { activeStatus: "active", name: "Jayalekshmi C V", admisionNo: "18897", dept: "Botany" },
      { activeStatus: "active", name: "Kanakambika G", admisionNo: "20005", dept: "Botany" },
      { activeStatus: "active", name: "Karthika M.", admisionNo: "20159", dept: "Botany" },
      { activeStatus: "active", name: "Maya Ammathil Manoharan", admisionNo: "18001", dept: "Botany" },
      { activeStatus: "active", name: "Muhsina A A", admisionNo: "191009", dept: "Botany" },
      { activeStatus: "active", name: "Remya S", admisionNo: "191008", dept: "Botany" },
      { activeStatus: "active", name: "Reshma K Ramesh", admisionNo: "18896", dept: "Botany" },
      { activeStatus: "active", name: "Smitha M", admisionNo: "221088", dept: "Botany" },
      { activeStatus: "active", name: "Snehalatha V R", admisionNo: "17942", dept: "Botany" },
      { activeStatus: "active", name: "Anugraha S N", admisionNo: "21990", dept: "Chemistry" },
      { activeStatus: "active", name: "Athul Gabriel K M", admisionNo: "221101", dept: "Chemistry" },
      { activeStatus: "active", name: "DIVYA P", admisionNo: "221096", dept: "Chemistry" },
      { activeStatus: "active", name: "Gopika K Y", admisionNo: "221098", dept: "Chemistry" },
      { activeStatus: "active", name: "Kavya P.", admisionNo: "20006", dept: "Chemistry" },
      { activeStatus: "active", name: "Priya S", admisionNo: "221099", dept: "Chemistry" },
      { activeStatus: "active", name: "AISWARYA M", admisionNo: "221109", dept: "Commerce" },
      { activeStatus: "active", name: "AKHIL C C", admisionNo: "221111", dept: "Commerce" },
      { activeStatus: "active", name: "ATHIRA C R", admisionNo: "221110", dept: "Commerce" },
      { activeStatus: "active", name: "FAZAL P", admisionNo: "21978", dept: "Commerce" },
      { activeStatus: "active", name: "PRASITHA A", admisionNo: "221108", dept: "Commerce" },
      { activeStatus: "active", name: "SREERAJ K R", admisionNo: "221107", dept: "Commerce" },
      { activeStatus: "active", name: "UMAMAHESWARI R", admisionNo: "21981", dept: "Commerce" },
      { activeStatus: "active", name: "E Asha", admisionNo: "221091", dept: "economics" },
      { activeStatus: "active", name: "Jithesh V T k", admisionNo: "221094", dept: "economics" },
      { activeStatus: "active", name: "Krishnapriya R", admisionNo: "221093", dept: "economics" },
      { activeStatus: "active", name: "Shifa A", admisionNo: "221092", dept: "economics" },
      { activeStatus: "active", name: "Abdul Haseeb. T", admisionNo: "21973", dept: "English" },
      { activeStatus: "active", name: "Abhinaya", admisionNo: "21381", dept: "English" },
      { activeStatus: "active", name: "Adhila Abdul Hameed", admisionNo: "221105", dept: "English" },
      { activeStatus: "active", name: "Arjun K Anil", admisionNo: "221085", dept: "English" },
      { activeStatus: "active", name: "Chaitra Sukumaran", admisionNo: "221086", dept: "English" },
      { activeStatus: "active", name: "Harshitha VS", admisionNo: "221104", dept: "English" },
      { activeStatus: "active", name: "Hima PM", admisionNo: "221080", dept: "English" },
      { activeStatus: "active", name: "Jaleena JS", admisionNo: "221082", dept: "English" },
      { activeStatus: "active", name: "Jasna Nafeesa PK", admisionNo: "21382", dept: "English" },
      { activeStatus: "active", name: "Kavya T", admisionNo: "221087", dept: "English" },
      { activeStatus: "active", name: "Roshima Uday", admisionNo: "20001", dept: "English" },
      { activeStatus: "active", name: "SHANI.A.MOPILA", admisionNo: "20003", dept: "English" },
      { activeStatus: "active", name: "Shibila A", admisionNo: "191011", dept: "English" },
      { activeStatus: "active", name: "Shilpa B", admisionNo: "20002", dept: "English" },
      { activeStatus: "active", name: "Sirajudheen CT", admisionNo: "221100", dept: "English" },
      { activeStatus: "active", name: "Athulprasad A J", admisionNo: "221115", dept: "Physics" },
      { activeStatus: "active", name: "Athulya Jayakumar", admisionNo: "21994", dept: "Physics" },
      { activeStatus: "active", name: "Nandhitha Pauly", admisionNo: "21985", dept: "Physics" },
      { activeStatus: "active", name: "NIMISHA O K", admisionNo: "17941", dept: "Physics" },
      { activeStatus: "active", name: "GREESHMA VARMA", admisionNo: "221097", dept: "Zoology" },
      { activeStatus: "active", name: "SAJINI K.P", admisionNo: "18002", dept: "Zoology" },
      { activeStatus: "active", name: "SHAHILA ISMAIL. K.I.", admisionNo: "17945", dept: "Zoology" },
    ];
  }

  let allMembers = allData.studentsRolles;
  let classData = allData.classData;

  let newRolesData = [];

  allMembers?.forEach((item) => {
    if (item?.activeStatus === "active") {
      item.admisionNo = item?.admisionNo ? item?.admisionNo + "" : "Nill";
      item.rollNo = item?.rollNo ? toString((item?.rollNo < 10 ? "0" + item?.rollNo : item?.rollNo + "") + "") : "Nill";
      newRolesData.push(item);
    }
  });

  let half = Math.round(newRolesData.length / 2);

  if (state.sortByAdmisionNo) {
    allMembers = allMembers?.sort((a, b) => a?.admisionNo?.localeCompare(b?.admisionNo));
  }
  if (state.sortByName) {
    allMembers = allMembers?.sort((a, b) => a.name?.localeCompare(b.name));
  }

  if (state.sortByRoll) {
    allMembers = allMembers?.sort((a, b) => a?.rollNo?.localeCompare(b?.rollNo));
  }

  console.log({ state });
  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <>
          <div className={classNames.listContainer2}>
            <div style={{ width: "200px" }}>
              <div>
                <div style={{ height: "90%", width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                  <div style={{ width: "100%", paddingLeft: 5, paddingRight: 5, display: "flex", flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 14, width: "20%" }}>S.N.</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 14, width: "70%" }}>Class Name</h5>
                  </div>
                  <div
                    style={{
                      height: "600px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Scrollbars>
                      {[
                        {
                          classId: "PhD",
                          className: "Phd",
                          collegeId: "college",
                        },
                        ...data,
                      ]?.map((item, i) => (
                        <>
                          <div
                            onClick={() => {
                              setState({
                                ...state,
                                classId: item.classId,
                                className: item.className,
                                collegeId: item.collegeId,
                              });
                              refetch();
                            }}
                            style={{ cursor: "pointer", width: "100%", paddingLeft: 5, paddingRight: 5, display: "flex", height: "30px", flexDirection: "row", alignItems: "center", alignSelf: "center", backgroundColor: item.status === "active" ? "white" : "#ff8f87" }}
                          >
                            <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 12, width: "20%" }}>{i + 1}</h5>
                            <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "70%" }}>{item.className}</h5>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              height: 1,
                              backgroundColor: "black",
                            }}
                          ></div>
                        </>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ width: "1080px" }}>
              <Scrollbars style={{}}>
                <div ref={componentRef} className="printable">
                  <img style={{ position: "absolute", width: "80%", marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
                  <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                    <div style={{ width: "1000px", display: "flex", flexDirection: "row", borderRadius: "25px" }}>
                      <img width="12%" height="12%" src={selectedCollege?.logo} />
                      <div style={{ width: "80%", alignItems: "center", margin: 0, textAlign: "center" }}>
                        <span style={{ fontSize: "30px", margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
                        <p style={{ fontSize: "25px", margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
                        <p style={{ fontSize: "14px", margin: 0, fontWeight: 900 }}>
                          {selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
                        </p>
                      </div>
                    </div>
                    <div style={{ width: "1000px", display: "flex", flexDirection: "row", height: 30, margin: 0, alignItems: "center", alignSelf: "center", marginBottom: 0 }}>
                      <h5 style={{ marginTop: 6, color: "black", textAlign: "left", fontSize: 16, width: "250px" }}>Tutor: {classData?.classTeacher}</h5>
                      <h5 style={{ marginTop: 6, color: "black", textAlign: "center", fontSize: 16, width: "580px" }}>{classData?.className}</h5>
                      <h5 style={{ marginTop: 6, color: "black", textAlign: "right", fontSize: 16, width: "250px" }}>HoD: {classData?.hod}</h5>
                    </div>
                    <div style={{ width: "1080px", display: "flex", flexDirection: "row", borderRadius: "25px", padding: "1%" }}>
                      <div style={{ margin: 10, width: "530px" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                          <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 16, width: "53px" }}>SN</h5>
                          <h5 style={{ marginTop: 6, color: "white", alignSelf: "center", fontSize: 16, width: "90px" }}>Ad. No.</h5>
                          <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "250px" }}>Name</h5>
                          {/* <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "250px" }}> Actual Catogory</h5> */}

                          {state.classId === "PhD" && <h5 style={{ marginTop: 6, color: "white", textAlign: "right", marginRight: 10, fontSize: 16, width: "250px" }}>Department</h5>}
                          <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "100px" }}>Checkbox</h5>
                        </div>
                        {newRolesData?.slice(0, half)?.map((item, i) => (
                          <>
                            <div style={{ marginTop: 6, width: "100%", display: "flex", height: " 28px", flexDirection: "row", alignItems: "center", alignSelf: "center", backgroundColor: item.elective === "Discountined" ? "#ff8f87" : "" }}>
                              <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 16, width: "53px" }}>{i + 1}</h5>
                              <h5 style={{ marginRight: 4, color: "black", alignSelf: "center", fontSize: 16, width: "90px" }}>{item.admisionNo}</h5>
                              <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 16, width: "250px" }}>{item.name.toUpperCase()}</h5>
                              {/* <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 16, width: "250px" }}>{item.category}</h5> */}
                              {state.classId === "PhD" && <h5 style={{ marginTop: 6, color: "black", textAlign: "right", marginRight: 10, fontSize: 16, width: "250px" }}>{item.dept.toUpperCase()}</h5>}

                              <h5 style={{ marginRight: 4, display: "flex", flexDirection: "row", color: "black", textAlign: "left", fontSize: 10, width: "100px" }}>
                                <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                height: 1,
                                backgroundColor: "black",
                              }}
                            ></div>
                          </>
                        ))}
                      </div>

                      <div style={{ margin: 10, width: "530px" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                          <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 16, width: "53px" }}>SN</h5>
                          <h5 style={{ marginTop: 6, color: "white", alignSelf: "center", fontSize: 16, width: "90px" }}>Ad. No.</h5>
                          <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "250px" }}>Name</h5>
                          {/* <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "250px" }}>Actual Catogory</h5> */}
                          {state.classId === "PhD" && <h5 style={{ marginTop: 6, color: "white", textAlign: "right", marginRight: 10, fontSize: 16, width: "250px" }}>Department</h5>}
                          {/* <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '100px' }}>Checkbox</h5> */}
                        </div>
                        {newRolesData?.slice(half, newRolesData?.length + 1)?.map((item, i) => (
                          <>
                            <div style={{ marginTop: 6, width: "100%", display: "flex", height: " 28px", flexDirection: "row", alignItems: "center", alignSelf: "center", backgroundColor: item.elective === "Discountined" ? "#ff8f87" : "" }}>
                              <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 16, width: "53px" }}>{i + 1 + half}</h5>
                              <h5 style={{ marginRight: 4, color: "black", alignSelf: "center", fontSize: 16, width: "90px" }}>{item.admisionNo}</h5>

                              <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 16, width: "250px" }}>{item.name.toUpperCase()}</h5>
                              {/* <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 16, width: "250px" }}>{item.category}</h5> */}
                              {state.classId === "PhD" && <h5 style={{ marginTop: 6, color: "black", textAlign: "right", marginRight: 10, fontSize: 16, width: "20px" }}>{item.dept.toUpperCase()}</h5>}

                              <h5 style={{ marginRight: 4, display: "flex", flexDirection: "row", color: "black", textAlign: "left", fontSize: 10, width: "100px" }}>
                                <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                                <input type="checkbox" style={{ height: 25, width: 25, marginLeft: 5 }} defaultChecked={false} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                height: 1,
                                backgroundColor: "black",
                              }}
                            ></div>
                          </>
                        ))}
                      </div>
                    </div>
                    <h5 style={{ marginTop: 12, textAlign: "right", color: "black", fontSize: 16 }}>Printed by Necttos on {moment(new Date()).format("DD MMM YYYY, hh:mm A")}</h5>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </>
      </div>

      <div className={classNames.absoluteDiv}>
        {/* <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: "70%" }}  >
            <Field
              type="text"
              style={{ width: "70%" }}
              name="year"
              id="year"
              value={state.searchValue}
              onChange={(e) => { setState({ ...state, searchValue: e.target.value }) }} />
            <label>Search includes keys title author</label></div>
        </Formik> */}

        {loading ? (
          <LoadingAnimation dark />
        ) : (
          <Button className={classNames.reloadButton} onClick={() => refetch()}>
            Reload
          </Button>
        )}

        <ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />

        <label style={{ fontSize: 25, color: "green", marginLeft: 20 }}>
          <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={state.sortByAdmisionNo} onChange={() => setState({ ...state, sortByAdmisionNo: !state.sortByAdmisionNo })} />
          Sort By Admn No
        </label>

        <label style={{ fontSize: 25, color: "green", marginLeft: 20 }}>
          <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={state.sortByName} onChange={() => setState({ ...state, sortByName: !state.sortByName })} />
          Sort By Name
        </label>

        <label style={{ fontSize: 25, color: "green", marginLeft: 20 }}>
          <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={state.sortByRoll} onChange={() => setState({ ...state, sortByRoll: !state.sortByRoll })} />
          Sort By Roll No
        </label>

        <label style={{ fontSize: 25, color: "green", marginLeft: 20 }}>
          <input type="checkbox" style={{ height: 25, width: 25 }} defaultChecked={state.sortByHalticket} onChange={() => setState({ ...state, sortByHalticket: !state.sortByHalticket })} />
          Sort By Halticket No
        </label>
      </div>

      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
