import React, { useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import styles from "./signUp.module.scss";
import Select from "antd/lib/select/index";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { EMAIL_REGEX, MOBILE_REGEX, PIN_REGEX } from "../../config/regex";
import { Button, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Option } = Select;
const instance = getAxiosInstance();

async function getColleges() {
  const { data } = await instance.get("/register/forRegistration");
  return data;
}

function SelectCollege({ setCollegeId }) {
  const { values, setFieldValue } = useFormikContext();
  const [collegeList, setCollegeList] = useState([]);
  useEffect(() => {
    getColleges()
      .then((data) => {
        if (data.statusCode === 200) {
          setCollegeList(data.colleges);
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  }, []);
  return (
    <Select
      showSearch
      value={values.college}
      className={styles.formFullWidth}
      style={{ overflow: "hidden" }}
      onChange={(val, op) => {
        setCollegeId(op.key);
        setFieldValue("college", val);
      }}
    >
      <Option value="">Select Your College</Option>
      {collegeList?.map((college) => (
        <Option key={college._id} value={college.collegeName}>
          {college.collegeName}
        </Option>
      ))}
    </Select>
  );
}

function LogoInput() {
  const { values, setFieldValue } = useFormikContext();
  console.log({ values });

  return (
    <div className={styles.formFullWidth}>
      <label className={styles.formDistWidth}>
        <div className={`${styles.button} ${styles.uploadButton}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.476 25.03">
            <path
              id="Path_563"
              data-name="Path 563"
              d="M25.292,16.112H27.71V28.139a.274.274,0,0,0,.262.284h1.963a.275.275,0,0,0,.262-.284V16.112h2.425a.286.286,0,0,0,.206-.459l-3.665-5.041a.269.269,0,0,0-.091-.081.244.244,0,0,0-.229,0,.268.268,0,0,0-.092.081L25.086,15.65a.288.288,0,0,0,.206.462Zm15.641,10.96H38.969a.274.274,0,0,0-.262.284v5.475h-19.5V27.357a.275.275,0,0,0-.262-.284H16.981a.274.274,0,0,0-.262.284V34.4a1.094,1.094,0,0,0,1.047,1.138H40.147A1.094,1.094,0,0,0,41.194,34.4V27.357A.275.275,0,0,0,40.933,27.072Z"
              transform="translate(-16.719 -10.503)"
              fill="rgba(0,0,0,0.85)"
            />
          </svg>{" "}


          {values.logoFile?.name || "Upload Your Logo"}
          <input
            type="file"
            className={`${styles.button} ${styles.uploadButton}`}
            value={values.logoFile?.url}
            onChange={(e) => {
              setFieldValue("logoFile", e.target.files[0]);
            }}
            accept="image/png"
          />
        </div>
        <div className={styles.error}>
          <ErrorMessage name="logoFile" />
        </div>
      </label>
    </div>
  );
}

// function SignUpCollege({ onSuccess }) {
function UploadPhoto({ uid, onClose, stateData, setStateData, }) {
  const [collegeId, setCollegeId] = useState("");

  // Uploading Image
  const storage = getStorage();

  function signupCollege(values) {
    message.loading({ content: "Uploading Logo...", key: "signup" }, 0);
    const logoImagesRef = ref(storage, `profilePhoto/${uid}/photoURL.png`);
    uploadBytes(logoImagesRef, values.logoFile, {
      contentType: "image/png",
    })
      .then((snapshot) => {
        getDownloadURL(ref(storage, logoImagesRef))
          .then((url) => {
            message.loading({ content: "Uploading Data...", key: "signup" }, 0);
            instance
              .post("/college/profile/updateAvatarNew", {
                photoURL: url,
                userId: uid,
              })
              .then(({ data }) => {
                if (data.statusCode !== 200) {
                  throw new Error(data.message);
                }

                message.success({
                  content: "College Added",
                  key: "signup",
                });
                setStateData({ ...stateData, uploadImage: false, })
              })
              .catch((error) => {
                message.error(
                  error?.response?.data?.message ||
                  error?.message ||
                  "API Error"
                );
              });
          })
          .catch((error) => {
            message.error(error.message);
          });
      })
      .catch((e) => {
        console.log(`e.message`, e.message);
      });
  }

  // New

  return (


    <div className={styles.container}>
      <div className={styles.modal} style={{ overflow: 'scroll', margin: 20 }}>


        <div className={styles.signUp}>
          <div className={styles.title}>Upload Photo</div>
          <Formik
            initialValues={{
              college: "",
              email: "",
              principalName: "",
              address: "",
              mobile: "",
              website: "",
              district: "",
              pincode: "",
            }}
            onSubmit={(values) => {
              signupCollege(values);
            }}
          >
            <Form className={styles.forms}>
              <LogoInput />``
              <div className={styles.formButtonWidth}>
                <button type="primary">
                  Register{" "}
                  <ArrowRightOutlined
                    style={{ fontSize: 22, margin: "auto 0 auto 5px" }}
                  />
                </button>
              </div>
              <div className={styles.formButtonWidth} style={{ marginTop: 30 }}>
                <Link type="primary" to={"/home"}>
                  Back to Home{" "}
                  <ArrowRightOutlined
                    style={{ fontSize: 22, margin: "auto 0 auto 5px" }}
                  />
                </Link>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <Button className={styles.closeButton} onClick={onClose}> Close </Button>
    </div>
  );
}



export default UploadPhoto;
