import { AxiosInstance, AxiosResponse } from "axios";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import endPort from "./EndPointManager.json";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
interface EndPoints {
  [key: string]: string;
}

interface Data {
  endPoint: string;
  body?: object;
  params?: object;
}
export interface UploadResult {
  url: string;
  fileId: string;
}
export interface UploadPhotoResult {
  url: string;
}
interface ResponseData {
  departmentWiseList: any;
  mainData: any;
  list: ResponseData | PromiseLike<ResponseData>;
  statusCode: number;
  message?: string;
}
/**
 * Uploads a file to Firebase Storage and returns the downloadable URL.
 * If a uniqueFileId is provided, updates the file at that ID instead of creating a new one.
 *
 * @param {File} file - The file to upload.
 * @param {string} [uniqueFileId] - Optional. The unique ID of the file to update.
 * @returns {Promise<string>} The URL of the uploaded file.
 */
export const uploadFileToFirebase = (file: File, uid: string, uniqueFileId: string = "", onProgress: (percentage: number) => void): Promise<UploadResult> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided.");
      return;
    }
    console.log({ uniqueFileId });

    const storage = getStorage();
    const fileId = uniqueFileId || uuidv4();
    const fileRef = ref(storage, `certificates/${uid}/${fileId}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress); // Update progress callback
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Error uploading file:", error);
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve({ url: downloadURL, fileId });
        });
      }
    );
  });
};

export const uploadPhotoToFirebase = (file: File, uniqueFileId: string = "", onProgress: (percentage: number) => void): Promise<UploadPhotoResult> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No file provided.");
      return;
    }
    const storage = getStorage();
    const fileRef = ref(storage, uniqueFileId);
    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {
        console.error("Error uploading file:", error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve({ url: downloadURL });
        });
      }
    );
  });
};

export const naacGET = (data: Data) => async (): Promise<ResponseData> => {
  let instance: AxiosInstance;
  try {
    instance = await getAxiosTokenInstance();
  } catch (instanceError: unknown) {
    console.error("Error obtaining Axios instance:", instanceError);
    throw new Error("Error obtaining Axios instance");
  }

  try {
    const { endPoint, params } = data;
    console.log("CALLED", endPoint, params);

    const url = (endPort as EndPoints)[endPoint];

    if (!url) {
      throw new Error(`Endpoint URL not defined for ${endPoint}`);
    }

    const resp: AxiosResponse<ResponseData> = await instance.post(url, { ...params });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else if (resp.data.statusCode < 200 || resp.data.statusCode >= 300) {
      throw new Error(`Unexpected status code: ${resp.data.statusCode}`);
    }

    return resp.data?.list;
  } catch (axiosError: unknown) {
    let errorMessage = "API Error";
    if (axiosError instanceof Error) {
      errorMessage = axiosError.message;
      if ((axiosError as any)?.response?.data?.message) {
        errorMessage = (axiosError as any).response.data.message;
      }
    }
    console.error("Axios request error:", errorMessage);
    throw new Error(errorMessage);
  }
};
