import React, { useState, useEffect } from "react";
import moment from "moment";
import TimePicker from "antd/lib/time-picker";
import classNames from "./timesheduleCard.module.scss";

function TimeSheduleClassCard({
  data,
  index,
  setFieldValue,
  pos,
  setTimmerHide,
}) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    setFieldValue(`classes[${pos}].${index}.collegeId`, undefined);
    setFieldValue(`classes[${pos}].${index}._id`, undefined);
  }, [index, pos, setFieldValue]);

  useEffect(() => {
    data.periods.length && setCount(data.periods.length);
  }, [data]);

  useEffect(() => {
    if (count >= 1)
      if (data.periods.length > count) {
        setFieldValue(
          `classes[${pos}].${index}.periods`,
          data.periods.slice(0, count)
        );
      } else if (data.periods.length < count) {
        setFieldValue(`classes[${pos}].${index}.periods`, [
          ...data.periods,
          ...Array(count - data.periods.length).fill({
            startTime: "",
            endTime: "",
          }),
        ]);
      }
  }, [count, data, index, pos, setFieldValue]);

  const intervalHideHandler = (pos, i) => {
    setTimmerHide(true);

    setTimeout(() => {
      document.getElementById(`Inter-${pos}-${i + 1}`).innerHTML = "";
      setTimmerHide(false);
    }, 6000);
  };

  return (
    <div className={classNames.classCard} key={`${data._id}In`}>
      <div className={classNames.titleRow} key={`${data._id}Title`}>
        <span>{data.className?.substring(0, 3)}</span>
        <input
          type="number"
          placeholder="Count"
          min={1}
          value={count}
          onChange={(e) => {
            const { value } = e.target;
            if ((value >= 1 && value <= 10) || value === "") setCount(value);
          }}
        />
      </div>
      {data.periods?.map((_, i) => {
        return (
          <div className={classNames.periodTime} key={`${data._id}${i}`}>
            <span className={classNames.text}>Period - {i + 1}</span>
            <div>
              <TimePicker
                format="hh:mm A"
                minuteStep={5}
                className={classNames.time}
                defaultValue={
                  moment(data.periods[i].startTime, "hh:mm A").isValid()
                    ? moment(data.periods[i].startTime, "hh:mm A")
                    : undefined
                }
                onChange={(value) => {
                  const endTime = data.periods[i - 1]?.endTime;

                  if (endTime) {
                    const selectedTime = value?.format("hh:mm A");
                    const parsed = selectedTime.split(":");

                    const selD = parsed[1].split(" ")[1];
                    const selHR =
                      parseInt(parsed[0]) === 12 && selD === "AM"
                        ? parseInt("00")
                        : parseInt(parsed[0]) === 12 && selD === "PM"
                          ? 12
                          : selD === "AM"
                            ? parseInt(parsed[0])
                            : parseInt(parsed[0]) + 12;
                    const selMIN = parsed[1].split(" ")[0];
                    const parsedEdate = endTime.split(":");

                    const endD = parsedEdate[1].split(" ")[1];
                    const endHR =
                      parseInt(parsedEdate[0]) === 12 && endD === "AM"
                        ? parseInt("00")
                        : parseInt(parsedEdate[0]) === 12 && endD === "PM"
                          ? 12
                          : endD === "AM"
                            ? parseInt(parsedEdate[0])
                            : parseInt(parsedEdate[0]) + 12;
                    const endMIN = parsedEdate[1].split(" ")[0];

                    const hrDif = selHR - endHR;
                    if (hrDif > 0) {
                      const timeMIn = selHR * 60 + selMIN - endHR * 60 + endMIN;
                      const interHr = parseInt(selHR - endHR);
                      const interMIN = parseInt(timeMIn % 60);
                      document.getElementById(
                        `Inter-${pos}-${i + 1}`
                      ).innerHTML = `Interval ${interHr} -hr ${interMIN} -m found `;
                    } else if (hrDif < 0) {
                      document.getElementById(
                        `Inter-${pos}-${i + 1}`
                      ).innerHTML = `Please check the time ...`;
                    } else if (hrDif === 0) {
                      const interMIN = parseInt(selMIN - endMIN);
                      if (interMIN > 0)
                        document.getElementById(
                          `Inter-${pos}-${i + 1}`
                        ).innerHTML = `Interval ${interMIN} -m  found`;
                      else if (interMIN < 0)
                        document.getElementById(
                          `Inter-${pos}-${i + 1}`
                        ).innerHTML = `Please check the time .... `;
                    }
                    intervalHideHandler(pos, i);
                  }

                  setFieldValue(
                    `classes[${pos}].${index}.periods.${i}.startTime`,
                    value?.format("hh:mm A")
                  );
                }}
              />
              {/* <span className={classNames.text}>to</span> */}
              <TimePicker
                format="hh:mm A"
                minuteStep={5}
                className={classNames.time}
                defaultValue={
                  moment(data.periods[i].endTime, "hh:mm A").isValid()
                    ? moment(data.periods[i].endTime, "hh:mm A")
                    : undefined
                }
                onChange={(value) => {
                  setFieldValue(
                    `classes[${pos}].${index}.periods.${i}.endTime`,
                    value?.format("hh:mm A")
                  );
                }}
              />
            </div>
            <span
              style={{
                textAlign: "center",
                color: "#148E87",
                marginTop: "11px",
                fontWeight: "700",
              }}
              id={`Inter-${pos}-${i + 1}`}
            ></span>
          </div>
        );
      })}
    </div>
  );
}

export default TimeSheduleClassCard;
