// import React, { useContext, useLayoutEffect, useState } from "react";
// import styles from "./Login.module.scss";
// import SignIn from "../../components/SignIn";
// import SignUpCollege from "../../components/SignUpCollege";
// import Home from '../Website/home';
// import { useHistory } from 'react-router-dom';
// import Loader from "./Loader";
// import { FirebaseContext } from "../../context/FirebaseContext";

// function Login() {
//   const [signIn, setSignIn] = useState(true);
//   const [] = useState(false);
//   const history = useHistory();

//   const { userLoading } = useContext(FirebaseContext);

//   useLayoutEffect(() => {
//     document.body.style.overflow = "hidden"
//     return (() => {
//       document.body.style.overflow = "auto"
//     })
//   }, [])
//   return (
//     <>

//       {userLoading &&

//         <Loader />
//       }

//       <Home />

//       <div className={styles.loginPage}>

//         <span className={styles.heading}>Welcome Back</span>

//         <div className={styles.signInSignupWrap}>

//           {signIn ? (
//             <SignIn setSignIn={setSignIn} signIn={signIn} />
//           ) : (
//             <SignUpCollege onSuccess={() => setSignIn(true)} />
//           )}

//         </div>
//         <div><button onClick={() => history.push("/home")} className={styles.btnSendHome} >Home</button></div>
//       </div>

//     </>

//   );
// }

// export default Login;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getAxiosInstance } from "../../utils/axiosInstance";
import Loader from "../../NecttosComp/Loaders/Loader";
import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from "uuid";
import { socket } from "../../config";
import Cookies from "js-cookie";

interface UserDetails {
  phoneNumber?: string;
  username?: string;
  uid?: string;
  displayName?: string;
}

interface QrCodeData {
  userDetails: UserDetails;
  token: string;
  collegeId: string;
}

function Login() {
  const [isSendOtp, setSendOtp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [confirm, setConfirm] = useState<any>();
  const [filledData, setFilledData] = useState({ mobile: "" });
  const [collegeData, setCollegeData] = useState<any>();
  const { collegeList, setCollegeId } = useContext(FirebaseContext);
  const history = useHistory();
  const [qrCode, setQRCode] = useState(`NECTTOS_QR_${uuidv4()}`);

  const inputRefs: any = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleInputChange = (index: any, e: any) => {
    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1]?.current?.focus();
    }
    let temp = [...otp];
    temp[index] = e.target.value;
    setOtp([...temp]);
    if (index === 5) {
      verfiyOtp(temp.join(""));
    }
  };

  useEffect( () => {
    const token =  Cookies.get("userToken");
    if (token) {
      const user = localStorage.getItem("userDetails");
      if (user) {
        if (collegeList?.length === 1) history.push("/app");
        else history.push("/college-list");
      }
    }
  }, []);


  useEffect(() => {
    const handleQrCode = ({ userDetails, token, collegeId }: QrCodeData) => {
      Cookies.set("userToken", token,{ expires: 365 * 10 , sameSite: "strict", secure: true });
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          phoneNumber: userDetails?.phoneNumber,
          username: userDetails?.username,
          displayName: userDetails?.displayName,
          uid: userDetails?.uid,
        })
      );
      localStorage.setItem("collegeId", JSON.stringify(collegeId));
      setCollegeId(collegeId);
      toast.success("Login Successfully");
      window.location.reload();
    };
    socket.on(qrCode, handleQrCode);
    return () => {
      socket.off(qrCode, handleQrCode);
      socket.disconnect()
    };
  }, []);


  const verfiyOtp = async (otp: string) => {
    setIsLoading(true);
    const instance = await getAxiosInstance();
    instance
      .post(`/auth/verify-otp`, { token: confirm, otp })
      .then(async (response) => {
        Cookies.set("userToken", response.data.jwtToken,{ expires: 365 * 10 , sameSite: "strict", secure: true });

        console.log(response.data);

        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            phoneNumber: response.data.phoneNumber,
            username: response.data.userName,
            displayName: response.data.userName,
            uid: response.data.uid,
          })
        );
        setIsLoading(false);
        if (collegeList?.length === 1) history.push("/app");
        else history.push("/college-list");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  const login = async () => {
    const instance = await getAxiosInstance();
    if (filledData.mobile) {
      instance
        .post(`/auth/sent-otp`, {
          phoneNumber: `+91${filledData.mobile}`,
        })
        .then((response) => {
          const elem = document.getElementById("loginForm");
          if (elem) {
            elem.style.height = "400px";
            const otp = document.getElementById("otp");
            if (otp) {
              otp.style.display = "flex";
            }
          }
          let second = 300;
          let intervalId = setInterval(() => {
            second = second - 1;
            setSendOtp(second);
            if (second <= 0) {
              clearInterval(intervalId);
            }
          }, 1000);
          setConfirm(response.data.token);
          console.log(response);
          toast.success("OTP Sended");
        })
        .catch((err) => {
          toast.error("Your Not authorized ");
        });
    } else {
      toast.error("Please give Phone number");
    }
  };

  return (
    <>
      <div className="w-screen h-screen flex flex-col items-center justify-center bg-violet-600 p-4 sm:p-8" style={{
        backgroundImage: "url(https://github.com/bedimcode/login-form/blob/main/assets/img/login-bg.png?raw=true)",
        backgroundPosition: "center",
      }}>
        <Toaster />
        <div className="border border-white flex flex-col  sm:flex-row h-[600px] justify-end w-full sm:w-auto p-4 items-center bg-[#f1f1f12c] backdrop-blur-2xl rounded-2xl" style={{ backdropFilter: "blur(8px)" }}>

          <h3 className="absolute text-white top-4 w-full text-center text-lg sm:text-2xl">Welcome to {collegeData?.collegeName || "Necttos"}</h3>
          <div className="flex flex-col text-wrap w-[250px] sm:w-[200px] mr-4">
            <h3 className=" text-white w-full text-center text-lg sm:text-2xl m-0">Login With QR Code</h3>
            <QRCode size={256}
              className='w-[250px] sm:w-[200px] h-[200px] sm:h-[250px]'
              value={qrCode}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div id="loginForm" className="flex flex-col h-[260px] w-full sm:w-[500px] p-2 transition-all duration-100 backdrop-blur-2xl rounded-2xl" style={{ backdropFilter: "blur(8px)" }}>
            <div>
              <h1 className="text-white text-center text-lg sm:text-2xl pt-4">Login</h1>
              <h6 className="text-gray-300 font-thin text-center pt-2">Your data is securely protected with us.</h6>
            </div>
            <div>
              <label htmlFor="" className="border border-white flex justify-between items-center m-3 py-3 px-3 rounded-3xl">
                <input
                  type="text"
                  value={filledData.mobile}
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setFilledData({ ...filledData, mobile: e.target.value });
                  }}
                  className="bg-none text-white outline-none border-none placeholder:text-white"
                />
                <svg style={{ color: "white" }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" fill="white" />
                </svg>
              </label>
              <h6
                className={`text-end px-3 ${isSendOtp < 1 ? "text-white cursor-pointer" : "text-gray-400 cursor-default"}`}
                onClick={() => {
                  if (isSendOtp < 1) {
                    login();
                  }
                }}
              >
                Send OTP
              </h6>
              <label htmlFor="" id="otp" className="border-none transition-all duration-150 hidden justify-between items-center m-3 py-3 px-3 rounded-3xl">
                <div className="flex items-center justify-between w-3/4">
                  {inputRefs.map((inputRef: any, index: any) => (
                    <input
                      key={index}
                      type="text"
                      className="bg-none text-white rounded outline-none border border-white h-10 w-10 text-center placeholder:text-white"
                      maxLength={1}
                      value={otp[index]}
                      onChange={(e) => handleInputChange(index, e)}
                      onKeyDown={(e) => {
                        const keyPressed = e.key;
                        if (keyPressed === "Backspace") {
                          if (index > 0) {
                            inputRefs[index - 1]?.current?.focus();
                          }
                        }
                        console.log(keyPressed, "hello");
                      }}
                      ref={inputRef}
                    />
                  ))}
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="#ffffff">
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M18 8h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2V7a6 6 0 1 1 12 0v1zm-7 7.732V18h2v-2.268a2 2 0 1 0-2 0zM16 8V7a4 4 0 1 0-8 0v1h8z" />
                  </g>
                </svg>
              </label>
              {isSendOtp > 0 && (
                <div className="flex px-3 justify-between">
                  <p className="text-white text-end">{isSendOtp}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <h6 className="absolute right-3 bottom-3 text-end text-white">Powered by Necttos</h6>
      </div>
      {isLoading && <Loader loading={false} />}
    </>
  );
}

export default Login;
