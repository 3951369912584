import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileEdit, useProfileEditNew } from '../../NecttosComp/APICaller/queryHooks';
import { ProgressBar, calculateProfilepPercentage } from '../../NecttosComp/ProgressBar/ProgressBar';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Loader from '../../NecttosComp/Loader/Loader';
import { ColorAbbreviationListOfProfile, ProfileDataDisplay, getProfileUpdateColors, postProfileSubmission } from '../../FunctionalExport/Formate';
import { FirebaseContext } from '../../context/FirebaseContext';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}

function Experience({ onClose, studentId, onNext, modal }: Props) {
	const titleKey = 'experiences';
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [temp, setTemp] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>();
	const { user, collegeId } = useContext(FirebaseContext);
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });
	const [values, setValues] = useState<any[]>(profileData);
	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	console.log(values);

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);

	return (
		<>
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div ref={componentRef} className='w-full printable '>
								<Table width='100%' id={'profilePDF'} innerWidth={['70%', '10%', '10%', '10%']}>
									<Thead>
										<Tr>
											<Th position={0} children='Name of the Institution' />
											<Th position={1} children='Start Date' />
											<Th position={2} children='End Date' />
											<Th position={3} children='Action' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr>
												<Td position={0} index={index}>
													<p>{x?.nameOfTheInstitution}</p>
												</Td>
												<Td position={1} index={index}>
													<p>{x?.startDate}</p>
												</Td>
												<Td position={2} index={index}>
													<p>{x?.endDate}</p>
												</Td>
												<Td position={3} index={index}>
													<div className='flex'>
														<Button
															type='update'
															onClick={() => {
																setMode('edit');
																setAddNew(x);
																setTemp(x);
															}}>
															Edit
														</Button>

														<Button
															type='delete'
															onClick={() => {
																setMode('delete');
																setTemp(x);
															}}>
															Delete
														</Button>
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>

							<Button
								onClick={async () => {
									setMode(!mode);
									setTemp({});
								}}
								type='update'>
								Add New Experience
							</Button>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</div>

			{mode && (
				<Main width='90vw' height='70vh'>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='grid grid-flow-col p-2'>
							{['delete', 'edit'].includes(mode) && (
								<div className='  p-5 rounded'>
									<div className='bg-white rounded w-auto h-auto p-6'>
										<ProfileDataDisplay temp={temp} />
									</div>

									<div className='flex float-left items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh] bg-white rounded'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Name of the Institution' returnKey='nameOfTheInstitution' setState={setAddNew} state={addNew} type='text' />
									</div>
									<div className='relative z-[2]'>
										<Input width='98%' fieldName='Start Date' returnKey='startDate' setState={setAddNew} state={addNew} type='date' />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName='End Date' returnKey='endDate' setState={setAddNew} state={addNew} type='date' />
									</div>
									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { nameOfTheInstitution: '', startDate: '', endDate: '' })?.percentage)} />
									<Button
										width='100%'
										onClick={async () => {
											console.log({ temp });
											if (temp?._id) {
												callProfileSubmission('edit');
											} else {
												callProfileSubmission('add');
											}
											closeAnimation();
											setTimeout(() => setMode(false), 250);
										}}
										type='save'
										children='Save'
									/>
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}

			{isLoading && <Loader />}
		</>
	);
}

export default Experience;
