import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { useDropDowns } from '../../../NecttosComp/APICaller/queryHooks';
import Scrollbars from 'react-custom-scrollbars';
import { getIssuedBook, getMembers } from '../../../NecttosComp/APICaller/ApiServices';
import noPhotoAvailable from '../../../static/images/library/noPhotoAvailable.png';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import SearchableUserInput from '../../../myComp/SearchableUserInput';
import SearchableBookInput from '../../../myComp/SearchableBookInput';

export const IssueBook = ({ onClose }) => {
	const [loading, setLoading] = useState(false);

	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { data: membersList, isLoading: memberShort, refetch: memberRefetch } = useQuery(['membersListWW', state?._id], getMembers(state.collegeId, state?.searchMember, state?._id), { keepPreviousData: false });
	const { data: issuedBook, refetch: issuedBookRefetch } = useQuery(['issuedDataBooks'], getIssuedBook({ collegeId, state, setLoading }));
	const { data: dropDown } = useDropDowns('library');

	let memberData = membersList?.membersList || {};
	let issuedData = issuedBook?.issuedData || {};

	const { Option } = Select;

	const UpdateBookStatus = async () => {
		try {
			setLoading(true);
			let data = {
				data: state.data,
				collegeId,
				userId: memberData?.userId,
				action: 'taken',
				status: 'taken',
			};
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/library/takeBook', data);
			setState({
				admisionNo: '',
			});
			setLoading(false);
			issuedBookRefetch();
			return resp.data;
		} catch (err) {
			return new Error('Book not available');
		}
	};

	let limit = !memberData?.limit ? 0 : memberData?.limit;

	let booksCount = Array(limit).fill(1);

	const selectedBookView = async () => {
		let booksCount = Array(10).fill(1);
		const data = [];

		booksCount?.forEach((_, i) => {
			if (state[`keyData${i}bookStocksId`]) {
				data.push({
					bookId: state[`keyData${i}bookId`],
					bookStocksId: state[`keyData${i}bookStocksId`],
					nameOfBook: state[`keyData${i}nameOfBook`],
					accessionNumber: state[`keyData${i}accessionNumber`],
					callNo: state[`keyData${i}callNo`],
					image: state[`keyData${i}image`],
					bookDetails: state[`keyData${i}bookDetails`],
				});
			}
		});

		setState({ ...state, data });
	};
	const backReset = async () => {
		setState({ ...state, data: [] });
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<SearchableUserInput primoryKey={'admisionNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} onFocusData={true} collection={'users'} stateData={state} setStateData={setState} titleData={'Search Members'} />

				{state?.admisionNo && state?.admisionNo === memberData?.name && state?._id === memberData?.userId && (
					<div className={classNames.controllDiv}>
						<div className={classNames.mainCondainer}>
							<div className={classNames.titleContainer}>
								<div className={classNames.subDiv}>
									<div className={classNames.image}>
										<div className={classNames.avatar}>
											<img src={memberData?.photoURL || noPhotoAvailable} className={classNames.dp} />
										</div>
									</div>
								</div>
								<div className={classNames.typoContainer}>
									<h5 className={classNames.userName}>{memberData?.name}</h5>
									<p>{memberData?.className}</p>
									<p>Mn: {memberData?.admisionNo}</p>
									<p>Book in Hand: {memberData?.bookInHand}</p>
									<p>Limit: {memberData?.limit}</p>
								</div>
							</div>
							<div className={classNames.role_container} style={{ height: '70%' }}>
								<Scrollbars style={{ width: '100%', height: '90%' }}>
									<div className={classNames.card_container}>
										{memberData?.booksInHand?.map((item, i) => (
											<div className={classNames.role_card}>
												<div className={classNames.icon}>
													<img src={item.photoURL} className={classNames.iconSize} />
												</div>
												<h2>{item.accessionNumber}</h2>
												<h3>{item.callNo}</h3>
												<h4>{item.nameOfBook.length > 60 ? item.nameOfBook.slice(0, 26) + ' ... ' + item.nameOfBook.slice(-26) : item.nameOfBook}</h4>
											</div>
										))}
									</div>
								</Scrollbars>
							</div>
						</div>

						<div className={classNames.mainCondainer}>
							{state?.data?.length > 0 ? (
								<div className={classNames.role_container}>
									<Scrollbars style={{ width: '100%', height: '100%' }}>
										<div className={classNames.card_container}>
											{state?.data?.map((item, i) => (
												<div className={classNames.role_card}>
													<div className={classNames.icon}>
														<img src={item.photoURL} className={classNames.iconSize} />
													</div>
													<h2>{item.accessionNumber}</h2>
													<h3>{item.callNo}</h3>
													<h4>{item.nameOfBook.length > 60 ? item.nameOfBook.slice(0, 26) + ' ... ' + item.nameOfBook.slice(-26) : item.nameOfBook}</h4>
												</div>
											))}
										</div>
									</Scrollbars>
								</div>
							) : (
								booksCount.map((_, i) => (
									<div style={{ flexDirection: 'row', display: 'flex' }}>
										<div style={{ width: 600 }}>
											<SearchableBookInput filter={'taken'} stateData={state} setStateData={setState} keyData={`keyData${i}`} titleData={`Search Book ${i + 1}`} />
										</div>
										<div style={{ width: 200 }}>
											<Formik enableReinitialize>
												<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
													<Field
														type='text'
														name='year'
														id='year'
														value={state[`keyData${i}bookDetails`]}
														onChange={(e) => {
															setState({ ...state, [`keyData${i}bookDetails`]: e.target.value });
														}}
													/>
													<label>Remarks</label>
												</div>
											</Formik>
										</div>
										<Button
											className={classNames.clear}
											onClick={() => {
												setState({
													...state,
													[`keyData${i}`]: '',
													[`keyData${i}bookId`]: '',
													[`keyData${i}bookStocksId`]: '',
													[`keyData${i}imageUrl`]: '',
													[`keyData${i}callNo`]: '',
													[`keyData${i}accessionNumber`]: '',
													[`keyData${i}nameOfBook`]: '',
												});
											}}>
											Clear
										</Button>
									</div>
								))
							)}
							<div className={classNames.group2}>
								<div className={classNames.actionsButton}>
									{state?.data?.length > 0 && (
										<Button
											className={classNames.back}
											onClick={() => {
												backReset();
											}}>
											Back
										</Button>
									)}
									{!limit < 1 && state?.data?.length === 0 && (
										<Button
											className={classNames.next}
											onClick={() => {
												selectedBookView();
											}}>
											Next
										</Button>
									)}
									{state?.data?.length > 0 && (
										<Button
											className={classNames.issue}
											onClick={() => {
												UpdateBookStatus();
											}}>
											Issue {state?.data?.length} Books
										</Button>
									)}
									{limit < 1 && (
										<div className={classNames.oops}>
											<p>Oops! The limit is over, please return some books and get new books...</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}

				{!state?.admisionNo && !state?.accessionNumber && (
					<div className={classNames.listContainer2}>
						<div className={classNames.headerPanel}>
							<span className={classNames.heading}>Issue Register</span>
							<div className={classNames.paginationController}>
								<Button
									className={classNames.reloadButton}
									disabled={parseInt(state.page) < 1}
									onClick={() => {
										setState({ ...state, page: parseInt(state.page) - 1 });
									}}>
									Prev
								</Button>
								<Formik enableReinitialize>
									<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
										<Field
											type='number'
											name='year'
											id='year'
											value={state.page}
											onChange={(e) => {
												setState({ ...state, page: e.target.value });
											}}
										/>
										<label>Page</label>
									</div>
								</Formik>
								<Button
									className={classNames.reloadButton}
									disabled={parseInt(state.page) * 30 > parseInt(issuedData?.total?.[0]?.total) - 30}
									onClick={() => {
										setState({ ...state, page: parseInt(state.page) + 1 });
									}}>
									Next
								</Button>
								{loading ? (
									<LoadingAnimation customHeight={50} customWidth={50} />
								) : (
									<>
										<span className={classNames.heading}>
											Showing Result : {parseInt(state.page) * 30} - {parseInt(state.page) * 30 + 30} out of {issuedData?.total?.[0]?.total}{' '}
										</span>
										<Button
											className={classNames.reloadButton}
											onClick={() => {
												issuedBookRefetch();
											}}>
											Load
										</Button>
									</>
								)}
								<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
							</div>
						</div>
						<Scrollbars style={{ height: '90%' }}>
							<div ref={componentRef} className='printable'>
								<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
								<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
										<img width='12%' height='12%' src={selectedCollege?.logo} />
										<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
											<span style={{ fontSize: '30px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
											<p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
											<p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>
												{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
											</p>
										</div>
									</div>
									<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'left' }}>Issue Register</h5>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Sn</h5>
										<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 14, width: '10%' }}>Acc No</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '40%' }}>Title</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '16%' }}>Author</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>Time</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Mn No</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Class/Dept.</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Mn Name</h5>
										<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Issued By</h5>
									</div>
									{issuedData?.data?.map((item) => (
										<>
											<div style={{ width: '100%', display: 'flex', height: ' 40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.sn}</h5>
												<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.accessionNumber}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '40%' }}>{item.title}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '16%' }}>{item.author}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '8%' }}>{moment(new Date(item?.tookOn)).format('DD MMM YYYY hh:mm A')}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.admisionNo}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.className}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedTo}</h5>
												<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedBy}</h5>
											</div>
											<div
												style={{
													width: '100%',
													display: 'flex',
													height: 1,
													backgroundColor: 'black',
												}}></div>
										</>
									))}
									<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
								</div>
							</div>
						</Scrollbars>
					</div>
				)}
			</div>

			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Select
							showSearch
							className={classNames.formFullWidth2}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterType: val, accessionNumber: '', admisionNo: '' });
							}}>
							{dropDown?.['shortItems']?.map((item, i) => (
								<Option key={i} value={item.value}>
									{item.title}
								</Option>
							))}
						</Select>
						<label>Filter Type</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Select
							showSearch
							className={classNames.formFullWidth2}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterValue: val, accessionNumber: '', admisionNo: '' });
							}}>
							{dropDown?.[state?.filterType]?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
						<label>{state?.filterType}</label>
					</div>
				</Formik>

				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }}>
						<Field
							type='date'
							name='startDate'
							id='startDate'
							value={state.startDate}
							onChange={(e) => {
								setState({ ...state, startDate: e.target.value, accessionNumber: '', admisionNo: '' });
							}}
						/>
						<label>Start Date</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }}>
						<Field
							type='date'
							name='endDate'
							id='endDate'
							value={state.endDate}
							onChange={(e) => {
								setState({ ...state, endDate: e.target.value, accessionNumber: '', admisionNo: '' });
							}}
						/>
						<label>End Date</label>
					</div>
				</Formik>
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
