import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import Scrollbars from 'react-custom-scrollbars';

const getProfileGraph = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/profile/getStudentGraphData', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);

			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const StudentProfile = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [state, setState] = React.useState({});
	const { data: allData = [], refetch } = useQuery(['getStudentProfileGraph', state.classId, state.searchValue], getProfileGraph({ collegeId: selectedCollege.collegeId, classId: state.classId, setLoading }));
	if (allData.length > 0) {
		setState(false);
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<>
					<div className={classNames.listContainer2}>
						<div style={{ color: 'red', fontWeight: '900', textAlign: 'center', fontSize: 40 }}>
							{' '}
							{state.departmentName?.toUpperCase()} {state.departmentName ? 'students' : 'Students'} Profile Statistics
						</div>

						{/* {loading ?
              (<label className={classNames.notice}>Your request is being processed… waiting for preparation…</label>)
              :
              (<> */}
						<div style={{ height: '5%', width: '80%', flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
							<div style={{ height: '100%', margin: 10, backgroundColor: 'red', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Unverified Data {((state.unverified2 || state.unverified || allData?.percentageOfPendingData) - (state.verified2 || state.verified || allData?.percentageOfVerifiedData))?.toFixed(2)}%</div>
							<div style={{ height: '100%', margin: 10, backgroundColor: 'green', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Verified Data {(state.verified2 || state.verified || allData?.percentageOfVerifiedData)?.toFixed(2)}%</div>
							<div style={{ height: '100%', margin: 10, backgroundColor: '#007aa3', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Total Data {(state.unverified2 || state.unverified || allData?.percentageOfPendingData)?.toFixed(2)}%</div>
							{state?.deptBack && (
								<div onClick={() => setState({ ...state, intividualData: false, subDataData: false, deptBack: false, classBack: false, verified: '', unverified: '' })} style={{ height: '100%', margin: 10, backgroundColor: 'grey', width: '50%', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>
									back
								</div>
							)}
							{state?.classBack && (
								<div onClick={() => setState({ ...state, intividualData: true, deptBack: true, classBack: false, subDataData: false, subData: [], verified2: '', unverified2: '' })} style={{ height: '100%', margin: 10, backgroundColor: 'grey', width: '50%', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>
									back
								</div>
							)}
						</div>
						<Scrollbars>
							{state?.subDataData ? (
								<div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
									{state?.subData?.map((x) => (
										<div onClick={() => setState({ ...state, intividualData: false, subDataData: false, deptBack: false, classBack: false, departmentName: '', verified: '', unverified: '', verified2: '', unverified2: '' })} style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center' }}>
											<div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)' }}> {x.name}</div>
											<div style={{ height: `${x.percentageOfCompletion2 / 2}%`, backgroundColor: 'red', position: 'absolute', bottom: '19%', width: 52, color: '#c6ffbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageOfCompletion2?.toFixed(2)}%</div>
											<div style={{ height: `${x.percentageOfCompletion / 2}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageOfCompletion?.toFixed(2)}%</div>
										</div>
									))}
								</div>
							) : (
								<>
									{state?.intividualData ? (
										<div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
											{state?.dataData?.map((x) => (
												<div onClick={() => setState({ ...state, deptBack: false, classBack: true, intividualData: false, subDataData: true, subData: x?.data, verified2: x.percentageAprovedd, unverified2: x.percentagePendingd, departmentName: x?.title })} style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center' }}>
													<div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)' }}>{x.title}</div>
													<div style={{ height: `${x.percentagePending}%`, backgroundColor: 'red', position: 'absolute', bottom: '19%', width: 52, color: '#c6ffbf', fontWeight: '900', textAlign: 'center' }}>{x.percentagePendingd?.toFixed(2)}%</div>
													<div style={{ height: `${x.percentageAproved}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageAprovedd?.toFixed(2)}%</div>
												</div>
											))}
										</div>
									) : (
										<div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
											{allData?.list?.map((x) => (
												<div onClick={() => setState({ ...state, deptBack: true, classBack: false, intividualData: true, dataData: x?.data, verified: x.percentageAprovedd, unverified: x.percentagePendingd, departmentName: x?.title })} style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center' }}>
													<div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)' }}>{x.title.slice(14)}</div>
													<div style={{ height: `${x.percentagePending}%`, backgroundColor: 'red', position: 'absolute', bottom: '19%', width: 52, color: '#c6ffbf', fontWeight: '900', textAlign: 'center' }}>{x.percentagePendingd?.toFixed(2)}%</div>
													<div style={{ height: `${x.percentageAproved}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageAprovedd?.toFixed(2)}%</div>
												</div>
											))}
										</div>
									)}
								</>
							)}
						</Scrollbars>

						{/* </>)} */}
					</div>
				</>
			</div>
			<Button className={classNames.reloadButton} onClick={refetch()}>
				Refresh
			</Button>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
