import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getMainAccounts, getScholarship } from '../../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import SearchableImput from '../../Library/AddBook/DropDownComponent';
import SearchableUserInput from '../../../myComp/SearchableUserInput';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';

export const Scholarship = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { data = [], refetch } = useQuery('getScholarship', getScholarship(collegeId));
	const [isLoading, setIsLoading] = useState(false);

	const { data: accountData = [] } = useQuery(['getMainAccounts', collegeId], getMainAccounts(collegeId));

	const [state, setState] = useState({});

	console.log({ state });

	const manageHolidays = async () => {
		try {
			setIsLoading(true);

			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postScholarship', {
				collegeId,
				userId: state?._id,
				amount: state?.amount,
				naration: state?.naration,
				scholarshipType: state?.scholarshipType,
				mainAccountId: state?.mainAccountId,
				_id: state?.spId,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					userId: '',
					amount: '',
					naration: '',
					admisionNo: '',
					scholarshipType: '',
					name: '',
					mainAccountId: '',
					mainAccountIdval: '',
					spId: '',
					_id: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<>
							<div className={classNames.list}>
								{state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}
								<SearchableUserInput primoryKey={'admisionNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} collection={'users'} stateData={state} setStateData={setState} titleData={'Search Studnets'} />
								<CustomInput keyData={'amount'} titleData={'Amount'} typeData={'number'} stateData={state} setStateData={setState} />
								<CustomInput keyData={'scholarshipType'} titleData={'Scholarship Type'} typeData={'text'} stateData={state} setStateData={setState} />
								<CustomDropDownKey keyData={'mainAccountId'} keyObject={'name'} keyReturn={'_id'} titleData={'Select Account'} dropValues={accountData} stateData={state} setStateData={setState} />
								<CustomInput keyData={'naration'} titleData={'Naration'} typeData={'text'} stateData={state} setStateData={setState} />

								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												userId: '',
												amount: '',
												naration: '',
												admisionNo: '',
												scholarshipType: '',
												name: '',
												mainAccountId: '',
												mainAccountIdval: '',
												spId: '',
												_id: '',
											});
										}}>
										{' '}
										Cancel{' '}
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											manageHolidays();
										}}>
										Submit
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} className='printable'>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Scholarship Management</h5>
								<div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '15%' }}>AdmisionNo</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '15%' }}>Account Name</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Name</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '20%' }}>Class Name</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}>Amount</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Type of Scholarship</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '30%' }}>Naration</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '8%' }}></h5>
								</div>
								{data?.map((item, i) => (
									<>
										<div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '15%' }}>{item.admisionNo}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '15%' }}>{item.mainAccountName}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 14, width: '30%' }}>{item.name}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 12, width: '20%' }}>{item.className}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '10%' }}>{item.amount}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 12, width: '20%' }}>{item.scholarshipType}</h5>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 12, width: '30%' }}>{item.naration}</h5>

											<Button
												className={classNames.submitBotton}
												onClick={() => {
													setState({
														...state,
														_id: item?.userId || '',
														amount: item?.amount || '',
														mainAccountId: item?.mainAccountId || '',
														admisionNo: item?.name || '',
														scholarshipType: item?.scholarshipType || '',
														mainAccountIdval: item?.mainAccountName || '',
														naration: item?.naration || '',
														spId: item?.spId || '',
														alert: 'Edit as ' + item?.admisionNo || '',
													});
												}}>
												{' '}
												Edit{' '}
											</Button>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												height: 1,
												backgroundColor: 'black',
											}}></div>
									</>
								))}
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>
			<Button
				className={classNames.reloadButton}
				onClick={() => {
					refetch();
				}}>
				Reload
			</Button>
			<ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />
			{!isLoading && (
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			)}
		</div>
	);
};
