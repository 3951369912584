import React, { Fragment, useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
// import DatePicker from "antd/lib/date-picker";
import { useMutation, useQuery } from 'react-query';
import message from 'antd/lib/message';

import classNames from './timeShedulePage.module.scss';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import TimeSheduleClassCard from '../../../components/TimeSheduleClassCard';
import { Formik } from 'formik';
import LoadingAnimation from '../../../components/LoadingAnimation';

const getPeriodsList = async ({ queryKey }) => {
	// eslint-disable-next-line

	// eslint-disable-next-line no-unused-vars
	const [_, { collegeId }] = queryKey;
	const instance = await getAxiosTokenInstance();
	let resp;
	if (collegeId)
		try {
			resp = await instance.get('college/timetable/periods', {
				params: { collegeId },
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	return resp?.data;
};

const updatePeriodsList = async ({ data }) => {
	// data.collegeId = collegeId
	data?.classes?.forEach((e) => {
		delete e.collegeId;
	});

	const instance = await getAxiosTokenInstance();
	const resp = await instance.post('/college/timetable/periods', data);
	if (resp.data.statusCode === 400) {
		throw new Error(resp.data.message || 'API Error');
	}
	return resp.data;
};

function TimeShedulePage({ onClose }) {
	const [periodsValue, setPeriodsValue] = useState({});
	const [timmerHIde, setTimmerHide] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const periodsQuery = useQuery(['periods', { collegeId: collegeId }], getPeriodsList);

	const { mutate, isLoading } = useMutation(updatePeriodsList, {
		onSuccess: (data) => {
			periodsQuery.refetch();
			message.success(data.message);
		},
		onError: (error) => {
			message.error(error.message);
		},
	});

	useEffect(() => {
		let obj = {};

		periodsQuery.data?.list?.map((c) => {
			if (obj[c.className.charAt(0)]) return (obj[c.className.charAt(0)] = [...obj[c.className.charAt(0)], c]);
			else return (obj[c.className.charAt(0)] = [c]);
		});

		setPeriodsValue(obj);
	}, [periodsQuery.data]);
	return (
		<div className={classNames.timeShedulePage}>
			{periodsQuery?.isLoading ? (
				<div className={classNames.loading}>
					<LoadingAnimation />
				</div>
			) : (
				<Formik
					validateOnMount
					enableReinitialize
					initialValues={{
						collegeId,
						classes: periodsValue,
					}}
					onSubmit={async (values) => {
						let class1;
						let class2;
						let class3;
						Object.keys(values.classes)?.forEach(function (key) {
							if (key === '1') class1 = values.classes[key][0];
							if (key === '2') class2 = values.classes[key][0];
							if (key === '3') class3 = values.classes[key][0];
						});
						let classes = [];
						Object.keys(values.classes)?.map((t, k) => {
							if (k === 0) {
								values?.classes[t]?.map((cls, i) => {
									cls.periods = class1?.periods;
									classes.push(cls);
									return true;
								});
							}
							if (k === 1) {
								values?.classes[t]?.map((cls, i) => {
									cls.periods = class2?.periods;
									classes.push(cls);
									return true;
								});
							}
							if (k === 2) {
								values?.classes[t]?.map((cls, i) => {
									cls.periods = class3?.periods;
									classes.push(cls);
									return true;
								});
							}
							return true;
						});

						let data = { collegeId, classes };
						mutate({ data });
					}}>
					{({ values, setFieldValue, handleSubmit }) => (
						<Fragment key='formik'>
							<div className={classNames.title}>Period Management</div>
							<Scrollbars className={classNames.content}>
								{Object.keys(values.classes)?.map((t, k) => {
									return <div className={classNames.batchDiv}>{values?.classes[t]?.map((cls, i) => (i === 0 ? <TimeSheduleClassCard key={cls.classId} data={cls} index={i} pos={t} setTimmerHide={setTimmerHide} setFieldValue={setFieldValue} /> : null))}</div>;
								})}
							</Scrollbars>

							<div className={classNames.buttons}>
								<div className={classNames.btnClose} onClick={onClose} disable>
									Close
								</div>
								<div className={classNames.btnUpdate} onClick={() => (!timmerHIde ? handleSubmit() : '')}>
									Update
								</div>
							</div>
						</Fragment>
					)}
				</Formik>
			)}
			{isLoading && (
				<div className={classNames.loading}>
					<LoadingAnimation />
				</div>
			)}
		</div>
	);
}
export default TimeShedulePage;
