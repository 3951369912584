import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
import Input from '../../NecttosComp/Input/Input';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

interface FormState {
	phoneNumber: string;
	name: string;
	usersData: any;
	department: any;
	classes: any;
	roles: any;
	role: any;
}
const SuccessToastContent = () => (
	<div style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
		<span style={{ marginRight: '10px' }}>✔️</span>
		<span>Form submitted successfully!</span>
	</div>
);
interface GetUserListParams {
	queryKey: [string, { type: string; collegeId: string; classId?: string }];
}

const getUserList = async (context: any): Promise<any> => {
	const [queryKey, { type, collegeId, classId }] = context.queryKey as [string, { type: string; collegeId: string; classId?: string }];

	try {
		const instance = await getAxiosInstance();
		const resp: AxiosResponse<any> = await instance.get(`/college/users/getByClass?collegeId=${collegeId}${type === 'all' ? '' : `&type=${type}`}${classId ? `&classId=${classId}` : ''}`);
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
		return resp?.data?.list || [];
	} catch (err: any) {
		console.log({ err });
		throw new Error(err.message || 'API Error');
	}
};

const Onboarding: React.FC = () => {
	const [dropDown, setDropDown] = useState<any>([]);
	const params: { id: string; role: string } = useParams();
	const [droRoles, setDropRoles] = useState<any>([]);
	const [respondsData, setResponseData] = useState<any>({});
	const [success, setSuccess] = useState<any>({});

	const [formState, setFormState] = useState<FormState>({
		phoneNumber: '',
		name: '',
		department: '',
		classes: '',
		usersData: '',
		roles: '',
		role: params?.role || '',
	});

	const { data } = useQuery([`userList${formState?.classes?.classId}`, { type: params?.role, classId: formState?.classes?.classId, collegeId: params.id }], getUserList);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;

		setFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const departments = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/classes/getDepartmentForm?collegeId=${encodeURIComponent(params.id)}`;
			const res = await instance.get(url);
			setDropDown(res.data.data);
		} catch (error) {
			console.error(error);
		}
	};

	const allClasses = useAllClasses({ needAll: false, classOnly: true, subId: params.id });

	useEffect(() => {
		departments();
	}, [params]);

	const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const re = /^[0-9\b]+$/;
		if (value === '' || re.test(value)) {
			setFormState((prevState) => ({
				...prevState,
				phoneNumber: value,
			}));
		}
	};

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormState((prevState) => ({
			...prevState,
			name: event.target.value,
		}));
	};
	interface AxiosError {
		response?: {
			data?: {
				error?: string;
			};
			status?: number;
			message?: string;
		};
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (formState.phoneNumber.length !== 10) {
			toast.error('Please Enter 10 digits');
			return;
		}

		try {
			const instance = await getAxiosInstance();
			let data = { ...formState, ...params, userId: formState?.usersData?._id, collegeId: params.id };
			data.phoneNumber = data.phoneNumber.replace('+91', '');

			const dataValues = await instance.post('/college/users/addNewUserForm', data);

			if (dataValues) {
				setResponseData(dataValues);
			}

			toast.success(<SuccessToastContent />, {
				position: 'top-center',
				duration: 10000,
			});
			setFormState({
				phoneNumber: '',
				name: '',
				department: '',
				classes: '',
				usersData: '',
				roles: '',
				role: params?.role || '',
			});
		} catch (error) {
			console.error(error);

			const axiosError = error as AxiosError;

			if (axiosError.response && axiosError.response.data && axiosError.response.data.error) {
				toast.error(axiosError.response.data.error);
			} else {
				toast.error('Sorry...Something went wrong');
			}
		}
	};

	dropDown.sort((a: any, b: any) => a.name.localeCompare(b.name));

	const roles = [
		{ title: 'Director', _id: 'director' },
		{ title: 'Manager', _id: 'manager' },
		{ title: 'Office Staff', _id: 'office' },
		{ title: 'Librarian', _id: 'library' },
		{ title: 'Library Assistant', _id: 'libraryAssistant' },
		{ title: 'Mechanic', _id: 'mechanic' },
		{ title: 'Cashier', _id: 'cashier' },
		{ title: 'Clerk', _id: 'clerk' },
		{ title: 'Gardener', _id: 'gardner' },
		{ title: 'Head Accountant', _id: 'headAccountant' },
		{ title: 'Herbarium Keeper', _id: 'herbariumKeeper' },
		{ title: 'Lab Assistant', _id: 'labAssistant' },
		{ title: 'Office Attendant', _id: 'officeAttendant' },
		{ title: 'Superintendent', _id: 'superintend' },
		{ title: 'Sweeper', _id: 'sweepers' },
		{ title: 'Part time Sweeper', _id: 'partTimeSweeper' },
		{ title: 'LD Typist', _id: 'ldTypist' },
		{ title: 'Night Watchman', _id: 'watchman' },
		{ title: 'Technical Assistant', _id: 'technicalAssistant' },
		{ title: 'Computer Assistant', _id: 'computerAssistant' },
		{ title: 'Store Keeper', _id: 'storeKeeper' },
		{ title: 'Driver', _id: 'driver' },
		{ title: 'Senior Clerk', _id: 'seniorClerk' },
		{ title: 'Clerical Assistant', _id: 'clericalAssistant' },
		{ title: 'Cook', _id: 'cook' },
		{ title: 'Sanitation Worker', _id: 'sanitationWorker' },
		{ title: 'Cook', _id: 'cook' },
		{ title: 'Cook', _id: 'cook' },
		{ title: 'Marker', _id: 'marker' },
	];

	const rolesTeachers = [{ title: 'Head of Department', _id: 'hod' }];

	useEffect(() => {
		if (formState.role === 'teacher') {
			setDropRoles(rolesTeachers);
		} else if (formState.role === 'staff') {
			setDropRoles(roles);
		} else {
			setDropRoles([]);
		}
	}, [formState.role]);

	console.log({ respondsData });

	return respondsData?.data?.message === 'Successfully updated' ? (
		<div className='flex items-center justify-center mt-8'>
			<div className='max-w-lg w-full bg-white shadow-lg rounded-lg overflow-hidden'>
				<div className='px-8 py-6'>
					<div className='mb-6'>
						<div className='bg-green-100 border-l-4 border-green-500 text-green-700 p-4' role='alert'>
							<h3 className='font-bold text-lg'>Registration Successful!</h3>
							<p className='text-sm mt-2'>Please take a screenshot of the steps below for future reference.</p>
							<div className='mt-4'>
								<p className='font-bold'>What's next:</p>
								<ol className='list-decimal ml-5 mt-2 text-sm'>
									<li>Go to the Play Store or App Store.</li>
									<li>Search for "Necttos" to find the app.</li>
									<li>Download and install it.</li>
									<li>
										Login using your phone number: <span className='font-semibold'>{respondsData?.data?.isUser?.phoneNumber}</span>
									</li>
								</ol>
							</div>
						</div>
					</div>
					<div className='text-gray-700 text-base text-center'>
						<p>
							If you encounter any issues or have questions, please don't hesitate to
							<a href='tel:+919400001733' className='text-blue-700 hover:text-blue-900 ml-1'>
								contact support
							</a>
							for assistance.
						</p>
					</div>
				</div>
			</div>
		</div>
	) : (
		<>
			{respondsData?.data?.isUser?.phoneNumber ? (
				<div className='flex items-center justify-center mt-6'>
					<div className='max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden'>
						<div className='px-6 py-4'>
							<div className='mb-2'>
								<div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4' role='alert'>
									<p className='font-bold'>Oops!</p>
									<p>
										A user with the name"
										<span className='font-semibold'>
											{respondsData?.data?.isUser?.name} [ {respondsData?.data?.isUser?.phoneNumber}
										</span>
										]" already exists in our system.
									</p>
								</div>
							</div>
							<p className='text-gray-700 text-base mb-4'>Please try using a different Mobile Number, or if you believe this is an error, contact support for assistance.</p>
							<div className='flex items-center justify-between'>
								<button onClick={() => setResponseData('')} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-150 ease-in-out'>
									Try Again
								</button>
								<a href='tel:+919400001733' className='text-blue-700 hover:text-blue-900 font-semibold py-2 px-4 focus:outline-none focus:underline transition-colors duration-150 ease-in-out'>
									Contact Support
								</a>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='flex items-center justify-center p-4 bg-gray-100 min-h-screen'>
					<Toaster />
					<form className='w-full max-w-md bg-white rounded-lg shadow-md p-6' onSubmit={handleSubmit}>
						<span className='block text-lg font-semibold mb-4 text-center py-2 px-4 bg-blue-100 text-blue-800 rounded-lg shadow'>Welcome to Necttos Onboarding!</span>
						{params?.role === 'student' && <p className='mb-4 text-center text-md text-gray-700'>Dear students, we are excited to introduce a new app designed to streamline your academic and administrative tasks through automation. To ensure you have full access to its features, please register using your personal mobile number. This will allow us to tailor the experience specifically for you and keep you updated with important notifications.</p>}
						{['student', 'scholars', 'staff', 'teacher'].includes(params?.role) ? (
							<div></div>
						) : (
							<div className='mb-4'>
								<span className='block text-gray-700 text-sm font-bold mb-2'>Type of Users</span>
								<div className='flex items-center mb-2'>
									<input type='radio' id='student' name='role' value='student' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'student'} />
									<label htmlFor='student' className='ml-2 text-gray-700 text-sm'>
										UG & PG Students
									</label>
								</div>

								<div className='flex items-center'>
									<input type='radio' id='scholars' name='role' value='scholars' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'scholars'} />
									<label htmlFor='scholars' className='ml-2 text-gray-700 text-sm'>
										Research Scholars
									</label>
								</div>

								<div className='flex items-center'>
									<input type='radio' id='staff' name='role' value='staff' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'staff'} />
									<label htmlFor='staff' className='ml-2 text-gray-700 text-sm'>
										Non-Teaching Staff
									</label>
								</div>
								<div className='flex items-center'>
									<input type='radio' id='teacher' name='role' value='teacher' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'teacher'} />
									<label htmlFor='teacher' className='ml-2 text-gray-700 text-sm'>
										Teaching Staff
									</label>
								</div>
							</div>
						)}

						{formState.role === 'student' && <Input fieldName='Select Class' valueShowKey='className' setState={setFormState} state={formState} returnKey='classes' didntShowKey optionDisplay='className' options={allClasses?.data} type='drop' width='100%' required />}

						{formState.role === 'student' && formState?.classes?.classId && <Input fieldName='Select Name' valueShowKey='specialName' setState={setFormState} state={formState} returnKey='usersData' didntShowKey optionDisplay='specialName' options={data} type='drop' width='100%' required />}

						{formState.role === 'student' && formState?.usersData?._id && (
							<div className='mb-4'>
								<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
									Phone Number (for recieving OTP)
								</label>
								<input type='tel' id='phoneNumber' name='phoneNumber' value={formState.phoneNumber} onChange={handlePhoneNumberChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' maxLength={10} required />
							</div>
						)}

						{formState.role !== 'student' && (
							<div className='mb-4'>
								<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
									Phone Number (for recieving OTP)
								</label>
								<input type='tel' id='phoneNumber' name='phoneNumber' value={formState.phoneNumber} onChange={handlePhoneNumberChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' maxLength={10} required />
							</div>
						)}

						{['scholars', 'teacher']?.includes(formState.role) && (
							<div className='mb-4'>
								<div className='mb-4'>
									<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
										Name of the Teacher
									</label>
									<input type='text' id='name' name='name' value={formState.name} onChange={handleNameChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
								</div>

								<label htmlFor='department' className='block text-gray-700 text-sm font-bold mb-2'>
									Select Department
								</label>
								<select
									id='department'
									name='department'
									value={formState?.department?.name}
									onChange={(e) => {
										const selectedRole = dropDown.find((drop: any) => drop.name === e.target.value);
										setFormState((prevState) => ({
											...prevState,
											department: selectedRole,
										}));
									}}
									className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
									required>
									<option value=''>Choose...</option>
									{dropDown.map((role: any, index: number) => (
										<option key={index} value={role.name}>
											{role.name}
										</option>
									))}
								</select>
							</div>
						)}

						{['staff']?.includes(formState.role) && (
							<div className='mb-4'>
								<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
									Name of the Staff
								</label>
								<input type='text' id='name' name='name' value={formState.name} onChange={handleNameChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
							</div>
						)}

						{['staff', 'teacher']?.includes(formState.role) && (
							<div className='mb-4'>
								<label htmlFor='roles' className='block text-gray-700 text-sm font-bold mb-2'>
									Assign Additional Roles: Select Optional Secondary Positions
								</label>
								<select
									id='roles'
									name='roles'
									value={formState?.roles?.title}
									onChange={(e) => {
										const selectedRole = droRoles?.find((roles: any) => roles.title === e.target.value);
										setFormState((prevState) => ({
											...prevState,
											roles: selectedRole,
										}));
									}}
									className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
									<option value=''>Choose...</option>
									{droRoles?.map((roles: any, index: any) => (
										<option key={index} value={roles.title}>
											{roles.title}
										</option>
									))}
								</select>
							</div>
						)}

						<div className='flex items-center justify-center'>
							<button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
								Submit
							</button>
						</div>
					</form>
				</div>
			)}
			;
		</>
	);
};

export default Onboarding;
