import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import { useQuery, useMutation } from 'react-query';
import { useAllClasses } from '../../../NecttosComp/APICaller/queryHooks';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../../../utils/axiosInstance';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';
import moment from 'moment';
import CustomDropDown from '../../../myComp/CustomDropDown';

export const exportPdfExam = async (olddata) => {
	const instance = await getAxiosTokenInstance();
	let data = {
		collegeId: olddata.collegeId,
		classId: olddata.classId,
		subjectId: olddata.subjectId,
	};
	let url = [`/college/action/subjectWiseAttendanceExcel`];
	try {
		const resp = await instance.post(...url, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getActiveSubjects = async ({ queryKey }) => {
	const instance = await getAxiosTokenInstance();
	const [_, { year, base }] = queryKey;
	try {
		const resp = await instance.get('/college/subject/listActiveSubjects', { params: { year, base } });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data?.subjects;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const SubjectWiseAttendance = ({ onClose }) => {
	const exportPdfMutationExam = useMutation(exportPdfExam);
	const [loading, setLoading] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const allClasses = useAllClasses({ needAll: true, classOnly: true });
	const [state, setState] = useState({});

	const { data } = useQuery(['allActiveSubjectForAttendance', { collegeId, year: state.year, base: state.base }], getActiveSubjects);

	const onInternalMark = async () => {
		setLoading(true);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				subjectId: state.subjectId,
				classId: state.classId,
			};

			link.download = ('APC ' + allClasses?.data?.find((x) => x?._id === state?.classId)?.batchName + ' ' + allClasses?.data?.find((x) => x?._id === state?.classId)?.shortName + ' (Sem ' + state.semester + ') ' + moment(new Date()).format('DD-MM-YYYY')).replace('.', ' ');
			const pdfData = await exportPdfMutationExam.mutateAsync(postData);
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
			link.click();
			setLoading(false);
			onClose();
		} catch (e) {
			setLoading(false);
		}
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<Button className={classNames.closeButton} onClick={onClose}>
					X
				</Button>
				<div className={classNames.listContainer}>
					<label className={classNames.heading}>DOWNLOAD APC</label>
					{loading ? (
						<label className={classNames.notice}>Your request is being processed… waiting for preparation…</label>
					) : (
						<>
							<view style={{ width: 550 }}>
								<CustomDropDown keyData={'year'} titleData={'Select Accademic Year'} dropValues={['2020-21', '2021-22', '2022-23', '2023-24', '2024-25']} stateData={state} setStateData={setState} />
							</view>
							<view style={{ width: 550 }}>
								<CustomDropDown keyData={'base'} titleData={'Set of Semester'} dropValues={['1-3-5', '2-4-6']} stateData={state} setStateData={setState} />
							</view>
							<view style={{ width: 550 }}>
								<CustomDropDownKey keyData={'subjectId'} keyData2={'classId'} keyObject={'subjectName'} keyReturn={'subjectId'} keyReturn2={'classId'} titleData={'Select Subject'} dropValues={data || []} stateData={state} setStateData={setState} />
							</view>
							<Button className={classNames.button} onClick={onInternalMark}>
								{' '}
								Download{' '}
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
