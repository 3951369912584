import React, { useContext, useEffect, useState } from 'react';
import classNames from './detailView.module.scss';
import { useProfileData } from '../../../NecttosComp/APICaller/queryHooks';
import { getAxiosInstance, getAxiosTokenInstance } from '../../../utils/axiosInstance';
import styles from './signUp.module.scss';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { message } from 'antd';
import { getAdmissionValidator, getIsCall } from '../../../NecttosComp/APICaller/ApiServices';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { ErrorMessage, useFormikContext } from 'formik';

function UploadImage({ uid, isStudent, keyData, stateData, handleStartTimer, setStateData }) {
	const { data: userDetail, status } = useProfileData(uid, !isStudent);

	const [loading, setLoading] = useState(false);

	const { collegeId, user } = useContext(FirebaseContext);

	const { data, refetch } = useQuery(['getAdmissionValidator', collegeId, uid], getIsCall(collegeId, uid));

	const [state, setState] = useState({ type: true });
	const [isRunning, setIsRunning] = useState(false);
	const [currentTime, setCurrentTime] = useState(30);
	const [message, setMessage] = useState(false);

	const instance = getAxiosInstance();

	const storage = getStorage();

	function signupCollege(state) {
		const logoImagesRef = ref(storage, `profilePic/${uid}/photoURL.png`);
		uploadBytes(logoImagesRef, state.photoURL, {
			contentType: 'image/png',
		})
			.then((snapshot) => {
				getDownloadURL(ref(storage, logoImagesRef)).then((url) => {
					instance
						.post('/college/profile/updateAvatar', {
							photoURL: url,
							userId: uid,
						})
						.then(({ data }) => {
							if (data.statusCode !== 200) {
								throw new Error(data.message);
							}

							message.success({
								content: 'College Added',
								key: 'signup',
							});
							// onSuccess();
						});
				});
			})
			.catch((e) => {
				console.log(`e.message`, e.message);
			});
	}

	const handleProceed2 = async () => {
		try {
			let data = {
				_id: userDetail?._id,
				type: false,
				key: keyData,
			};
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/admissionProcess/callStudent', data);
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	useEffect(() => {
		let interval = null;
		if (isRunning) {
			interval = setInterval(() => {
				setCurrentTime(currentTime - 1);
			}, 1000);
			if (currentTime < 28) {
				if (!data.verification[keyData + 'Call']) {
					setState({ ...state, callingNow: false });
					setMessage(data.verification[keyData + 'CallMessage']);
					if (currentTime > 5) {
						setCurrentTime(5);
					}
					if (currentTime === 0) {
						setIsRunning(false);
						if (data.verification[keyData + 'CallMessage'] === 'Comming') {
							handleStartTimer();
						}
						setMessage(false);
						setCurrentTime(30);
						setStateData({ ...stateData, callToken: false });
					}
				}
			}

			refetch();
		}
		if (currentTime === 0) {
			setMessage(false);
			setCurrentTime(30);
			handleProceed2();
			setIsRunning(false);
		}
		return () => clearInterval(interval);
	}, [isRunning, currentTime, data]);

	const Button = ({ color, text, onClick }) => {
		return (
			<button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: 'white', fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
				{text}
			</button>
		);
	};
	if (!uid) {
		return (
			<div className={classNames.detailView}>
				<div className={classNames.selectmessage}>Select A User First</div>
			</div>
		);
	}
	const handleCancel = () => {
		handleProceed2();
		setStateData({ ...stateData, uploadImage: false, [data?._id + 'call']: false, [data?._id + 'timeStamp']: false });
	};

	let memberData = userDetail;

	function LogoInput() {
		return (
			<div className={styles.formFullWidth}>
				<label className={styles.formDistWidth}>
					<div className={`${styles.button} ${styles.uploadButton}`}>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24.476 25.03'>
							<path id='Path_563' data-name='Path 563' d='M25.292,16.112H27.71V28.139a.274.274,0,0,0,.262.284h1.963a.275.275,0,0,0,.262-.284V16.112h2.425a.286.286,0,0,0,.206-.459l-3.665-5.041a.269.269,0,0,0-.091-.081.244.244,0,0,0-.229,0,.268.268,0,0,0-.092.081L25.086,15.65a.288.288,0,0,0,.206.462Zm15.641,10.96H38.969a.274.274,0,0,0-.262.284v5.475h-19.5V27.357a.275.275,0,0,0-.262-.284H16.981a.274.274,0,0,0-.262.284V34.4a1.094,1.094,0,0,0,1.047,1.138H40.147A1.094,1.094,0,0,0,41.194,34.4V27.357A.275.275,0,0,0,40.933,27.072Z' transform='translate(-16.719 -10.503)' fill='rgba(0,0,0,0.85)' />
						</svg>{' '}
						<input
							type='file'
							onChange={(e) => {
								setState({ ...state, ['photoURL']: e.target.files[0] });
							}}
							accept='image/png'
						/>
					</div>
				</label>
			</div>
		);
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
				<div className={classNames.detailView}>
					<>
						<div style={{ overflow: 'scroll', margin: 20 }}>
							<div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
								<div className={classNames.typoContainer}>
									<h5 className={classNames.userName}>{memberData?.name}</h5>
								</div>

								<LogoInput />

								<view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
									<Button color='#FF4136' text='Cancel' onClick={handleCancel} />
									<Button color='#2ECC40' text='Call' onClick={signupCollege} />
								</view>
							</div>
						</div>
					</>
				</div>
			</div>
		</div>
	);
}

export default UploadImage;
