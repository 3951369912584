import moment from "moment";
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { getClassName } from "./Component";

interface AdmitCardProps {
  values: any;
  componentRef2: any;
  classes: any;
  type: string;
  collegeData: any;
  getQuotaTypes: (admissionTo: any) => string;
}

interface ResponseData {
  statusCode: number;
  list: {
    earnedMark: number;
  };
}

const TableHelper = ({ title, value }: { title: string; value: any }) => (
  <div className="flex h-[30px]">
    <div className="w-[10%] text-lg font-medium">{title}</div>
    <div className="w-[90%] text-lg">: {value}</div>
  </div>
);

const CoverPage: React.FC<AdmitCardProps> = ({ values, componentRef2, classes, type, collegeData, getQuotaTypes }) => {
  const tableContent = "px-3 py-2 bg-white whitespace-wrap text-left text-lg text-black border";
  const [enable, setEnable] = useState<boolean>(false);
  const [bonusClasses, setBonusClasses] = useState<any>({});
  const [appNo, setAppNo] = useState<string>("");

  useEffect(() => {
    let appNo = "";
    if (collegeData?.collegeId === "COL-08B6B8276F75-46EE-A69C-77177AAA488B") {
      appNo = values?.applicationNo?.toString();
    } else if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      appNo = type?.toUpperCase() === "UG" ? "241" + values?.applicationNo?.toString().padStart(4, "0") : "242" + values?.applicationNo?.toString().padStart(4, "0");
    } else {
      appNo = values?.applicationNo;
    }
    setAppNo(appNo);
  }, [values?.applicationNo, collegeData?.collegeId, type]);

  useEffect(() => {
    setEnable(collegeData?.collegeId !== "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F");
  }, [collegeData?.collegeId]);

  const recalculate = async ({ classId }: { classId: string }) => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post<ResponseData>("/college/admissionProcess/calculateIndexMark", {
        studentId: values?._id,
        collegeId: collegeData?.collegeId,
        values,
        classId: classId,
      });
      if (resp?.data?.statusCode === 200) {
        setBonusClasses((prev: any) => ({
          ...prev,
          [classId]: resp?.data?.list?.earnedMark,
        }));
      }
    } catch (error) {
      console.error("Error calculating index mark:", error);
    }
  };

  useEffect(() => {
    for (let priorityOrder = 1; priorityOrder <= 10; priorityOrder++) {
      const classId = values?.[`preferedCourse${priorityOrder}`];
      if (classId) {
        recalculate({ classId });
      }
    }
  }, [values]);

  return (
    <div ref={componentRef2} id="printable" className="printable page-break bg-white font-medium">
      <div className="watermark">SAMPLE WATERMARK</div>
      <div className="p-10">
        <table className="min-w-full">
          <tbody>
            <tr>
              <td className={tableContent} style={{ width: "70%" }}>
                <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto rounded-full" />
              </td>
              <td className={tableContent} style={{ width: "15%" }}>
                <div className="relative w-26 h-26">
                  <CircularProgressbar
                    value={values?.percentageFilled}
                    styles={buildStyles({
                      pathColor: "#4caf50",
                      trailColor: "#d6d6d6",
                      textColor: "#212529",
                      textSize: "0px",
                      pathTransitionDuration: 0.5,
                    })}
                  />
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-[80%]">
                      <div className="text-[15px] text-green-900 font-medium text-center">Appl. Completed {values?.percentageFilled?.toFixed(2)}%</div>
                    </div>
                  </div>
                </div>
              </td>
              <td className={tableContent} style={{ width: "15%" }}>
                <img src={values?.photoURL} alt="Student Photo" className="h-full w-auto rounded-md" />
              </td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-center text-xl font-semibold my-3">Admission Data Card and Affirmation</h2>
        <div>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Name. : {values?.name}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  PROGRAMME : {getClassName(values?.dsc_a, classes)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Admission No. : {values?.admisionNo}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  CAPID: {values?.capId}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Date : {moment(values?.applicationAmount?.date).format("DD-MM-YYYY HH:mm A")}
                </td>
                {enable && values?.bloodGroup && (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Blood Group : {values?.bloodGroup}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Date of Birth : {values?.dob}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Mob : {values?.phoneNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Aadhaar No : {values?.aadhaarNumber}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Gender : {values?.gender?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Email : {values?.email}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Name of Guardian : {values?.gardianName?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Guardian Mob : {values?.guardianMobile?.slice(-10)}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Whatsapp : {values?.whatsappNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Quota Applied for : {getQuotaTypes(values?.admissionTo)}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Religion : {values?.religion}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Category : {values?.category}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Caste : {values?.caste}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Mother Tongue Language : {values?.motherTongue}
                </td>
                {enable && values?.placeOfBirth && (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Place of Birth : {values?.placeOfBirth}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "40%" }}>
                  Disabilities : {values?.disabilities}
                </td>
                {values?.disabilities === "Yes" && (
                  <>
                    <td className={tableContent} style={{ width: "30%" }}>
                      Disability Status : {values?.disabilityStatus}
                    </td>
                    <td className={tableContent} style={{ width: "30%" }}>
                      Disability Percentage : {values?.disabilityPercentage}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "20%" }}>
                  Permanent Address
                </td>
                <td className={tableContent} style={{ width: "80%" }}>
                  {values?.permanentHouseName?.toUpperCase()}, {values?.permanentPlace?.toUpperCase()}, {values?.permanentPostOffice?.toUpperCase()}, {values?.permanentDistrict?.toUpperCase()}, {values?.permanentState?.toUpperCase()}, {values?.permanentNationality?.toUpperCase()}, Pin:{values?.permanentPinCode}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CoverPage;
