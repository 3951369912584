import React, { useRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

export function NumInWords(number) {
	const first = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen'];
	const tens = [' ', ' ', 'twenty ', 'thirty ', 'forty ', 'fifty ', 'sixty ', 'seventy ', 'eighty ', 'ninety '];
	const mad = [' ', 'thousand ', 'million ', 'billion', 'trillion '];
	let word = '';

	for (let i = 0; i < mad.length; i++) {
		let tempNumber = number % (100 * Math.pow(1000, i));
		if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
			if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
				word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + '' + word;
			} else {
				word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + ' ' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + '' + word;
			}
		}
		tempNumber = number % Math.pow(1000, i + 1);
		if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hundred ' + word;
	}
	return word + 'only';
}
function sumWithKey(data, key) {
	return data?.reduce((x, y) => x + y[key], 0);
}

export const BillNumberModal2 = ({ data, onClose, date, setStateData, stateData }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	console.log({ cprint: data });

	return (
		<Main title='Print Receipt' height='100%' width='50%' bgColor='white'>
			<Header>
				<Button onClick={onClose} type='close'>
					Cancel
				</Button>
				<ReactToPrint
					trigger={() => (
						<Button type='fetch' className='bg-purple-500 text-white px-4 py-2 rounded-md'>
							Print
						</Button>
					)}
					content={() => componentRef.current}
				/>
			</Header>
			<Body>
				<div>
					<div ref={componentRef} className='printable mt-7'>
						<div className='mx-4 p-2 font-sans'>
							<div className='text-center mb-0 font-bold'>
								<div className='flex justify-center items-center gap-2'>
									<img src={selectedCollege.logo} className='w-16 h-16' alt='logo' />
									<h2 className='text-xl font-bold'>{selectedCollege?.collegeName}</h2>
								</div>
								<p className='p-0 m-0'>
									{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
								</p>
								<p className='text-lg p-0 mb-0 mt-2 font-semibold'>Receipt</p>
							</div>
							<div className='hidden screen:flex relative justify-center items-center'>
								<img id='1' src={selectedCollege.logo} alt='Photo' className='absolute w-96 h-96 object-cover opacity-10 mx-auto' style={{ top: '50%' }} />
							</div>
							<hr className='bg-black h-[2px] mb-2' />
							<div className='flex w-full font-bold'>
								<div className='w-3/5'>
									<div className='w-full flex justify-between'>
										<div>
											Admn No.: <span>{data.admisionNo}</span>
										</div>
										<div>
											Batch: <span>{data.batchName}</span>
										</div>
									</div>
									<div>
										Name: <span>{data?.name}</span>
									</div>
									<div>
										Course: <span>{data?.longName}</span>
									</div>
								</div>
								<div className='w-2/5 text-right'>
									<div>
										Receipt No.: <span>{stateData.printType === 'cprint' ? 'Group receipt' : data.billNumber}</span>
									</div>
									<div>
										Collect Mode: <span>{data?.method}</span>
									</div>
									<div>
										Date: <span>{data?.date}</span>
									</div>
								</div>
							</div>

							<hr className='bg-black h-[2px] m-0 p-0 mt-2' />
							<table className='w-full mb-8'>
								<thead className='border-b-2 font-semibold'>
									<tr>
										<th className='px-4 py-2'>SlNo.</th>
										{stateData.printType === 'cprint' ? <th className='px-4 py-2'>Rt No.</th> : null}
										<th className='px-4 py-2'>Main Head</th>
										<th className='px-4 py-2'>Sub Head</th>
										<th className='px-4 py-2'>Year</th>
										<th className='text-right px-4 py-2'>Amount</th>
									</tr>
								</thead>

								<tbody className='font-semibold pt-4 min-h-96'>
									{data?.collectedData?.map((x, i) => (
										<tr key={i} className='py-[2px]'>
											<td className='px-4 py-[1px]'>{i + 1}</td>
											{stateData.printType === 'cprint' && <th className='px-4 py-2'>{x.billNumber}</th>}
											<td className='px-4 py-[1px]'>{stateData.printType === 'cprint' ? x.title : x.mainAccountName}</td>
											<td className='px-4 py-[1px]'>{stateData.printType === 'cprint' ? x.subTitle : x.accountName}</td>
											<td className='px-4 py-[1px]'>{x.year}</td>
											<td className='text-right px-4 py-[1px]'>{x.amount}</td>
										</tr>
									))}
								</tbody>
								<tr className='border-b-2 border-t-2 font-extrabold'>
									<td className='px-4 py-2'></td>
									<td className='px-4 py-2'></td>
									<td className='px-4 py-2'>Total</td>
									<td className='text-right px-4 py-2'>{stateData.printType === 'cprint' ? data.billAmount : data.amount}</td>
								</tr>
							</table>

							<div className='flex justify-between items-end mt-36'>
								<p>Seal</p>
								<p>Accountant</p>
							</div>
						</div>
					</div>
				</div>
			</Body>
		</Main>
	);
};
