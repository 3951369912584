import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { useQuery } from 'react-query';

const getAllDepartments = (selectedCollege) => async () => {
	const instance = getAxiosInstance();
	try {
		const resp = await instance.get('/college/classes/getDepartment', {
			params: { collegeId: selectedCollege },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
		return resp.data?.departmentList;
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const DepartmentMaster = ({ onClose }) => {
	const { collegeId: selectedCollege } = useContext(FirebaseContext);
	const [data, setData] = useState(false);

	const { data: departmentList } = useQuery(['getAllDepartments', selectedCollege], getAllDepartments(selectedCollege));

	console.log(departmentList);

	return (
		<Main title='Department Management' width='90vw' height='90vh'>
			<Header>
				<div className='flex'></div>
				<div className='flex'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className='flex flex-col p-3'>
					<ul className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-4'>
						{departmentList?.map((item) => (
							<li key={item._id} className='flex'>
								<div
									className='bg-gray-50 shadow-lg  hover:shadow-xl transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden flex flex-col items-center w-full cursor-pointer'
									onPress={() => {
										setData({
											departmentId: item.departmentId,
											departmentName: item.departmentName,
										});
									}}>
									<div className='w-full'>
										{item?.hodPhoto ? (
											<div className='w-full h-56'>
												<img src={item?.hodPhoto} alt={item?.hodName} className='w-full h-full object-cover' />
											</div>
										) : (
											<div className='bg-indigo-500 flex justify-center items-center w-full h-56'>
												<span className='text-white text-6xl'>{item?.hodName?.charAt(0) || 'N'}</span>
											</div>
										)}
									</div>
									<div className='p-4 w-full text-center'>
										<h3 className='text-lg font-semibold text-gray-900 '>{item?.departmentName}</h3>
										{item?.hodName ? <p className='text-sm text-gray-600'>{item?.hodName}</p> : <p className='text-sm text-red-500'>{true ? 'No HOD Assigned!' : 'No Tutor Assigned!'}</p>}
										<div className='w-full bg-gray-200 rounded-full h-2.5 mt-3'>
											<div className='bg-indigo-600 h-2.5 rounded-full' style={{ width: `${item?.progress}%` }}></div>
										</div>
										<span className='text-xs font-medium text-indigo-600'>{item?.progress}%</span>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</Body>
		</Main>
	);
};

export default DepartmentMaster;
