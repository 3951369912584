import React from 'react';

const MissingNumbersModal = ({ missingNumbers, onClose, title, message }) => {
	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
			<div className='bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-2xl'>
				<h2 className='text-xl font-bold mb-4 text-center text-blue-600'>{title}</h2>
				<div className='max-h-64 overflow-y-auto'>
					{missingNumbers?.length > 0 ? (
						<ul className='space-y-2'>
							{missingNumbers?.map((number, index) => (
								<li key={index} className='p-2 border-b border-gray-200 text-center text-gray-800'>
									{number}
								</li>
							))}
						</ul>
					) : (
						<p className='text-gray-500 text-center'>No {title} found.</p>
					)}
				</div>
				<div className='mt-6 text-center'>
					<button onClick={onClose} className='px-4 py-2 bg-red-500 text-white font-semibold rounded-lg shadow hover:bg-red-600 transition'>
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

export default MissingNumbersModal;
