import React, { useEffect, useRef, useState } from "react";

interface CustomProgressBarProps {
  progress: number;
  title?: string;
}

export const ProgressBar: React.FC<CustomProgressBarProps> = ({ progress, title = "Completed" }) => {
  const [bar, setBar] = useState<number>(0);
  useEffect(() => {
    setBar(progress);
  }, [progress]);

  return (
    <div className="relative h-[24px] border-spacing-1 border-solid bg-[#ffffff] rounded">
      <div className="bg-[#007bffec] border-spacing-1 border-solid  h-full rounded" style={{ width: `${bar}%` }} />
      <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center">
        <span className={`text-sm font-medium text-[1.5vh] ${bar > 48 ? "text-white" : "text-gray-800"}`}>{`${bar}% ${title}`}</span>
      </div>
    </div>
  );
};

export const calculateProfilepPercentage = (entries: Record<string, any>, keys: any) => {
  const totalValues = Object?.keys(keys)?.length;
  let filled = 0;
  for (let i in keys) {
    if (entries?.[i]) {
      filled++;
    }
  }
  return {
    percentage: ((filled / totalValues) * 100).toFixed(0),
    totalValuesUnfilled: filled,
  };
};
