import React, { useContext, useEffect, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import { naacFetchData } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';

function C2_10({ val, onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		naacFetchData({ data: { collegeId, academicYear }, setLoading, setValues, url: '/college/NAAC/getNAAC_C2_10' });
	}, []);
	return (
		<>
			{loading ? (
				<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
					<LoadingAnimation />
				</div>
			) : null}

			<Main title='MENTOR MENTEE 2022-23' width='80vw' height='80vh'>
				<Header>
					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Table width='100%' innerWidth={['4%', '18%', '20%', '16%', '14%', '16%', '12%']}>
						<Thead>
							<Tr>
								<Th position={0} fontSize='10px'>
									SI.NO
								</Th>
								<Th position={1}>Qualification {'(Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt)'} and Year of obtaining</Th>
								<Th position={2}>Whether recognised as research Guide for Ph.D./D.M/M.Ch./D.N.B Superspeciality/D.Sc./D.Lit</Th>
								<Th position={3}>Year of Recognition as Research Guide</Th>
								<Th position={4}>Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution</Th>
								<Th position={5}>Name of the scholar</Th>
								<Th position={6}>Title of the thesis for scholar</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td position={0}>1</Td>
								<Td position={1}>1</Td>
								<Td position={2}>1</Td>
								<Td position={3}>1</Td>
								<Td position={4}>1</Td>
								<Td position={5}>1</Td>
								<Td position={6}>1</Td>
							</Tr>
						</Tbody>
					</Table>
				</Body>
			</Main>
			{/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <button className="closeBtn fixed top-3 right-3" onClick={onClose}>
          Close
        </button>
        <h1 className="text-xl"></h1>

        <table className="table">
          <tr>
            <th className="w-1/4">SI.NO</th>
            <th className="w-1/4">Qualification {"(Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt)"} and Year of obtaining</th>
            <th className="w-1/4">Whether recognised as research Guide for Ph.D./D.M/M.Ch./D.N.B Superspeciality/D.Sc./D.Lit</th>
            <th className="w-1/4">Year of Recognition as Research Guide</th>
            <th className="w-1/4">Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution</th>
            <th className="w-1/4">Name of the scholar</th>
            <th className="w-1/4">Title of the thesis for scholar</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>2</th>
            <th>2</th>
            <th>1</th>
            <th>2</th>
            <th>2</th>
          </tr>
        </table>
      </div> */}
		</>
	);
}

export default C2_10;
