import React, { useContext, useEffect, useRef, useState } from "react";
import { Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosInstance, getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";
import ReactToPrint from "react-to-print";

const getAllClasses =
  (collegeId: string, classOnly: boolean = true) =>
  async () => {
    const instance = getAxiosInstance();
    const { data } = await instance
      .get("/college/classes/getClass", {
        params: { collegeId, needProgress: true, classOnly },
      })
      .catch((error) => {
        throw new Error(error?.response?.data?.message || error.message || "API Error");
      });
    console.log(data, "classList");

    if (data?.statusCode === 200) {
      return data;
    } else {
      throw new Error(data?.message);
    }
  };


const getHalticketData = async ({ queryKey }: { queryKey: any }) => {
  const [_, { type, collegeId, classId }] = queryKey;
  try {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(`/college/users/halticketdata?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`);

    console.log("halticketata:", resp.data);

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data;
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

interface InvoiceManagementProps {
  onClose: () => void;
}

function HallTicketDistribution({ onClose }: InvoiceManagementProps) {
  const [selectedClass, setSelectClass] = useState("");
  const { collegeId } = useContext(FirebaseContext);
  const [collegeData, setCollegeData] = useState<any>([]);
  const componentRef = useRef<any>();
  

  const { data: classes } = useQuery(["allClasses", collegeId, true], getAllClasses(collegeId, true));
  const { data, status, error, refetch } = useQuery(["userList", { type: "student", classId: selectedClass, collegeId }], getHalticketData);

  useEffect(() => {
    if (selectedClass) {
      refetch();
    }
  }, [selectedClass, refetch]);

  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = data?.list?.filter((student: any) => student.name?.toLowerCase().includes(searchQuery.toLowerCase())) || [];


  const getCollege = async () => {
    try {
      const instance = await getAxiosInstance();
      const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
        params: { collegeId },
      });

      if (data?.statusCode === 200) {
        setCollegeData(data.collegeData);
        return data.collegeData;
      } else {
        throw new Error(data?.message || "Failed to fetch college details.");
      }
    } catch (error) {
      throw new Error("API Error");
    }
  };
  useEffect(()=>{
    getCollege();
  },[])
  console.log("vghvhhbshj", collegeData);

  return (
    <Main width="100%" height="100%" title="Hall Ticket">
      <div className="bg-white p-8 rounded-lg flex flex-row shadow-md text-center">
        <h4 className=" font-bold text-blue-600">{filteredData?.length || 0} Students Have HallTickets</h4>
        <div className="flex flex-row ms-[50%]">
          <ReactToPrint
            trigger={() => (
              <Button width="170px" type="fetch">
                {filteredData.length == 1 ? "Print" : "Print All"}
              </Button>
            )}
            content={() => componentRef.current}
          />

          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row ">
          <Input fieldName="Select Class" setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="300px" />
          <input placeholder="Search by Name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} type="text" className="w-72 h-[40px] mt-2 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
      </div>
      <div className=" printable overflow-auto" ref={componentRef}>
        {filteredData?.map((student: any, index: any) => (
          <div key={index} className="border w-full h-full bg-white p-4 overflow-auto ">
            <div className="w-full h-auto bg-white flex justify-center">
              <div className="flex flex-col items-center">
                <div className="w-full flex justify-between">
                  <div className="flex">
                    <img src={collegeData.logo} alt="College Logo" className="w-[220px] h-32 " />
                  </div>
                  <div className="flex flex-col items-center text-center">
                    <p className="text-xs font-bold">{collegeData.collegeName}</p>
                    <p className="text-xs ">HALL TICKET OF FOURTH SEMESTER CBCS EXAMINATION APRIL 2024HALL TICKET OF FOURTH SEMESTER CBCS EXAMINATION APRIL 2024HALL TICKET OF FOURTH SEMESTER CBCS EXAMINATION APRIL 2024</p>
                    <p className="text-xs font-semibold">{collegeData.slogan}</p>
                    <p className="text-xs font-semibold">HALL TICKET OF FOURTH SEMESTER CBCS EXAMINATION APRIL 2024</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-row justify-between items-center p-4 bg-white">
              <div className="flex-1">
                <div className="flex  ">
                  <p className="text-xs w-1/2  leading-[9px] font-semibold">Name Of the Candidate</p>
                  <p className="font-semibold  leading-[9px] text-left text-xs">: {student.name}</p>
                </div>
                <div className="flex ">
                  <p className="font-semibold leading-[9px] w-1/2 text-xs">Register Number</p>
                  <p className="font-semibold leading-[9px] text-left text-xs">: {student.rollNo}</p>
                </div>
                <div className="flex ">
                  <p className="font-semibold  leading-[9px] w-1/2 text-xs">Date Of Birth</p>
                  <p className="font-semibold  leading-[9px] text-xs">: {student.dob}</p>
                </div>
                <div className="flex ">
                  <p className="font-semibold  leading-[9px] w-1/2 text-xs">Semester</p>
                  <p className="font-semibold  leading-[9px] text-xs">: 4th</p>
                </div>
                <div className="flex ">
                  <p className="font-semibold   leading-[9px] w-1/2 text-xs">Center Of Examination</p>
                  <p className="font-semibold  leading-[9px] text-xs">: Kottayam, Kerala</p>
                </div>
                <div className="flex ">
                  <p className="font-semibold  leading-[9px] w-1/2 text-xs">Stream</p>
                  <p className="font-semibold  leading-[9px] text-xs">: B.A Pol</p>
                </div>
              </div>
              <div className="flex-1">
                <img src={student.photoURL} alt="Candidate" className="w-[100px] h-[120px] self-end justify-end ms-[60%]" />
              </div>
            </div>
            <div className="border w-full h-auto bg-white border-gray-400 p-3 flex flex-col">
              <div className="flex justify-center mt-[-8px]">
                <p className="font-semibold text-xs mt-[-4px]">Course Appearing</p>
              </div>
              <div className="w-full mt-[-7px]">
                <div className="flex flex-col">
                  <p className="font-semibold text-xs">Elective Course: EL4PHY01 - Quantum Physics (L)</p>
                  <p className="font-semibold text-xs">Core Course: CO4CRT11 - Corporate Accounting (T)</p>
                  <p className="font-semibold text-xs">Elective Course: EL4PHY01 - Quantum Physics (L)</p>
                  <p className="font-semibold text-xs">Core Course: CO4CRT11 - Corporate Accounting (T)</p>
                  <p className="font-semibold text-xs">Elective Course: EL4PHY01 - Quantum Physics (L)</p>
                  <p className="font-semibold text-xs">Core Course: CO4CRT11 - Corporate Accounting (T)</p>
                </div>
              </div>
            </div>
            <p className="font-semibold text-xs mt-2 ms-4">T-Theory;P-Principal; B-Theory & Practice</p>
            <div className="w-full p-8 mt-[-1%]">
              <div className="flex justify-between mb-10">
                <div className="flex-1 mr-5">
                  <p className="font-semibold text-xs">Candidate's Signature</p>
                  <p className="italic" style={{ fontSize: "10px" }}>
                    (To be signed in the presence of the Identifying Officer)
                  </p>
                  <div className="border-t border-black w-full mt-5 mb-5"></div>
                </div>
                <div className="flex-1 mx-5">
                  <p className="font-semibold text-xs">Seal and Signature</p>
                  <div className="flex flex-row">
                    <p className="italic" style={{ fontSize: "10px" }}>
                      (Head of the Department)
                    </p>
                    <p className="italic ms-[28%]">(Seal)</p>
                  </div>
                  <div className="border-t border-black w-full mt-5 mb-5"></div>
                </div>
                <div className="flex-1">
                  <p className="font-semibold text-xs">Principal's/Chief Superintendent's Signature</p>
                  <div className="border-t border-black w-full mt-5 mb-5"></div>
                </div>
              </div>
              <div className="flex justify-center mb-10">
                <div className="flex-1 mx-5 text-center">
                  <p className="text-xs ms-[40%] mt-[-5%]" style={{ fontSize: "10px" }}>
                    Controller of Examinations
                  </p>
                  <hr className="w-[1350px]" />
                </div>
              </div>
            </div>
            <div className="mt-[-9%]">
              <div>
                <p className="font-semibold text-center text-xs">INSTRUCTIONS TO CANDIDATES APPEARING FOR THE UNIVERSITY EXAMINATIONS</p>
                <ol className="text-justify p-0 m-0" style={{ fontSize: "12px" }}>
                  <li className="leading-[14px]">The candidate should ascertain that the hall ticket has been issued under the seal and signature of the institution in which the candidate is pursuing study.</li>
                  <li className="leading-[14px]">The candidate should also verify that the hall ticket pertains to the programme study, and that it bears the PERMANENT REGISTER NUMBER (PRN) of 12 digits.</li>
                  <li className="leading-[14px]">THE CANDIDATE SHOULD WRITE THE PROGRAMME CODE AND REGISTER NUMBER IN THE SPACE PROVIDED FOR THE SAME IN THE ANSWER BOOK. THE REGISTER NUMBER SHOULD ALSO BE WRITTEN IN WORDS IN THE SPACE PROVIDED. THE SPACE FOR WRITING THE SEMESTER DETAILS IN THE ANSWER BOOK SHOULD BE FILLED EXACTLY AS IN THE HALL TICKET.</li>
                  <li className="leading-[9px]">The bar code printed on the answer book should not be tampered with. Tampering with the bar code will be treated as malpractice in the exam.</li>
                  <li className="leading-[14px]">The candidate should write the Course Code and the name of the Course in the Answer Book as it appears in the Question Paper on each day of the examination.</li>
                  <li className="leading-[14px]">Candidates should take their seats in the Examination hall at least forty (40) minutes before the commencement of the examination. Candidates presenting themselves after the appointed time will not be admitted to the examination hall. Those who are undoubtedly suffering from infectious diseases of any kind will not be admitted. Candidates should present their hall tickets to the invigilator for inspection on each day of the examination.</li>
                  <li className="leading-[14px]">Candidates are not permitted to write anything on their hall tickets or question papers other than that instructed at (6) above. They are also prohibited from writing their name, PRN or anything which would reveal their identity in any other part of the answer book. The answer books of candidates who violate this instruction will be invalidated.</li>
                  <li className="leading-[9px]">Candidates will not be allowed to leave the examination hall during the first 30 minutes after the commencement of the examination.</li>
                  <li className="leading-[14px]">Candidates who do not behave properly to the Chief / Assistant Superintendent/ Invigilators of the Examinations or are found to have recourse to malpractice of any kind are liable to be sent out of the examination hall forthwith and will also have their examinations invalidated.</li>
                  <li className="leading-[14px]">Candidates are prohibited from bringing into the Examination Hall any type of electronic devices other than calculators permitted for certain specific subjects. Holding/use of cell phones in the examination hall is punishable by debarring for one semester. Candidates are also warned against bringing any kind of printed matter/manuscripts from outside which would be considered malpractice at the examination hall. Such candidates are liable to be sent out of the examination hall forthwith.</li>
                  <li className="leading-[9px]">Candidates are not allowed to leave the examination hall without handing over the answer book to the invigilator.</li>
                  <li className="leading-[14px]">Violation of these instructions may involve cancellation of the concerned examination and debarring from further appearance as decided by the University.</li>
                  <hr />
                </ol>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Main>
  );
}

export default HallTicketDistribution;
