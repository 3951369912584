import React, { useEffect, useState } from "react";
import "./Loader.css"

interface Props {
  loading: boolean
}

function Loader({ loading }: Props) {
  

  return <>
    <div className="fixed z-[999] bg-[#00000008] h-screen w-screen top-0 left-0 flex items-center justify-center">
      {loading ?

        <svg width={400} height={400}>
          <circle fill="none" stroke="#68E534" strokeWidth={20} cx={200} cy={200} r={190} className="circle" strokeLinecap="round" transform="rotate(-90 200 200) " />
          <polyline fill="none" stroke="#68E534" strokeWidth={24} points="88,214 173,284 304,138" strokeLinecap="round" strokeLinejoin="round" className="tick" />
        </svg>
        : <div className="lds-ring"><div /><div /><div /><div /></div>}

    </div>
  </>
    ;
}

export default Loader;
