import React, { useContext, useEffect, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import LoadingAnimation from '../../components/LoadingAnimation';
import { naacFetchData } from '../../NecttosComp/APICaller/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { naacGET } from '../../NecttosComp/APICaller/APICaller';

function C2_2({ onClose }) {
	const { collegeId } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(true);
	const [academicYear, setAcademicYear] = useState('2022-23');

	const { data: values = [] } = useQuery(['criteria2_1', academicYear], naacGET({ endPoint: 'criteria2_1', params: { collegeId, academicYear } }));

	return (
		<>
			{loading ? (
				<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
					<LoadingAnimation />
				</div>
			) : null}

			<Main height='80vh' width='80vw' title='2.2 Number of seats earmarked for reserved fo category as per GOI/State Govt.'>
				<Header>
					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<Table width='100%' innerWidth={['10%', '70%', '20%']}>
						<Thead>
							<Tr>
								<Th position={0}>Year</Th>
								<Th position={1}>Number of seat earmarked for reserved category as per GOI/State Govt. Rule during the year</Th>
								<Th position={2}>Link to supporting document</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td position={0}>1</Td>
								<Td position={1}>1</Td>
								<Td position={2}>1</Td>
							</Tr>
						</Tbody>
					</Table>
				</Body>
			</Main>
			{/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <h1 className="text-xl">2.2 Number of seats earmarked for reserved fo category as per GOI/Stae Govt.Rule during the year Upload supporting document</h1>
        <table className="table">
          <tr>
            <th className="w-1/4">Year</th>
            <th className="w-1/4">Number of seat earmarked for reserved category as per GOI/State Govt. Rule during the year</th>
            <th className="w-1/4">Link to supporting document</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>2</th>
          </tr>
        </table>
      </div> */}
		</>
	);
}

export default C2_2;
