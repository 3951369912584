import { useEffect, useRef } from "react";
import crypto from "crypto-js";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("Razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("Error in loading Razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({ orderId, keyId, collegeId, name, billAmount, userId, refech, accountId, data, keySecret, currency, amount, handleClickbackButton }) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const razorpayCharge = 0;

  const gst = 0;

  const totalAmount = amount + razorpayCharge + gst;

  const displayRazorpay = async (options) => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });
    rzp1.open();
  };

  const handlePayment = async (status, orderDetails = {}) => {
    console.log("payment init");
    const instance = await getAxiosTokenInstance();
    try {
      console.log({ orderDetails });
      if (!orderDetails.orderId || !orderDetails.paymentId || !orderDetails.signature) {
        console.log("Invalid order details:", orderDetails);
        throw new Error("Invalid order details");
      }
      const resp = await instance.post("/razorpay/payment", { status, orderDetails, collegeId, billAmount, userId, accountId, totalAmount });
      if (resp.status !== 200) {
        console.log("API Error:", resp.data.message || "Unknown error");
        throw new Error(resp.data.message || "API Error");
      }
      if (typeof handleClickbackButton === "function") {
        handleClickbackButton({
          collectedData: resp?.data?.collectedArray,
          paymentsArray: resp?.data?.paymentsArray,
          userName: name,
          admnNo: data?.admnNo,
          className: data?.className,
          photoURL: data?.photoURL,
        });
      }
      refech();
      return resp.data.order;
    } catch (error) {
      console.log("Payment API Error:", error.message);
      throw new Error(error.message || "API Error");
    }
  };

  const options = {
    key: keyId,
    amount: totalAmount,
    currency,
    name,
    order_id: orderId,
    handler: (response) => {
      console.log("Payment succeeded", response);
      paymentId.current = response.razorpay_payment_id;
      const signatureIsValid = crypto.HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret).toString() === response.razorpay_signature;
      if (signatureIsValid) {
        handlePayment("succeeded", {
          orderId,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        });
      } else {
        handlePayment("failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true,
      ondismiss: async (reason) => {
        if (!reason || reason === "timeout") {
          console.log(reason === "timeout" ? "Timed out" : "Cancelled");
          handlePayment(reason === "timeout" ? "timedout" : "cancelled");
        } else {
          console.log("Payment failed", reason);
          handlePayment("failed", reason.error);
        }
      },
    },
    retry: {
      enabled: false,
    },
    timeout: 900,
    theme: {
      color: "blue",
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, [orderId]);

  return null;
};

export default RenderRazorpay;
