import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getDcbData } from '../../NecttosComp/APICaller/ApiServices';
import Input from '../../NecttosComp/Input/Input';
import { SyncLoader } from 'react-spinners';

export const NEWDCB = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef(null);
	const ref2 = useRef(null);
	const [state, setState] = useState({});
	const [rotate, setRotate] = useState(false);
	const [settings, setSettings] = useState(false);
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
	const { data: dcbData, isFetching } = useQuery([`getLeaveReport`, collegeId, state], getDcbData({ collegeId, monthAndYear: state?.monthAndYear }));

	console.log({ dcbData });
	console.log({ state });

	const numberToWords = (num) => {
		if (num === 0) return 'Zero';

		const belowTwenty = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
		const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
		const thousands = ['', 'Thousand', 'Million', 'Billion'];

		const toWords = (n) => {
			if (n === 0) return '';
			if (n < 20) return belowTwenty[n] + ' ';
			if (n < 100) return tens[Math.floor(n / 10)] + ' ' + toWords(n % 10);
			return belowTwenty[Math.floor(n / 100)] + ' Hundred ' + toWords(n % 100);
		};

		let result = '';
		let i = 0;

		while (num > 0) {
			if (num % 1000 !== 0) {
				result = toWords(num % 1000) + thousands[i] + ' ' + result;
			}
			num = Math.floor(num / 1000);
			i++;
		}

		return result.trim();
	};

	//   const dcbData = {
	// 	lastRemiitedStartAcaYear: '1972-1973',
	// 	lastRemiitedEndAcaYear: '2015-2016',
	// 	curAcaYear: '2020-2021',

	// 	pastAcaYears: [
	// 		{ feeName: '2016-2017', arrearEndOfPrvMonth: 1000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 20000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 		{ feeName: '2017-2018', arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 		{ feeName: 'Duplicate TC', arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 		{ feeName: 'TC Late Fee', arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 		{ feeName: 'Re Admission Fee', arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 		{ feeName: 'Library Fine', arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '' },
	// 	],
	// 	pastAcaYearTotal: { arrearEndOfPrvMonth: 1722865, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1723635, collectionOfcurMonth: 372570, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 1351065, oldBalanceRemitted: 2000, curMonthColRemitted: 372570, totalCurrentRemitted: 372570, totalRemittedTillIncCurMonth: 1225707, pendingBalRemittance: 0, remarks: '' },
	// 	currentAcaYearData: [
	// 		{ batchYear: '1 DC', classStrengthAtBeginCurMonth: { fullFee: 403, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:1050,remittanceCertificate:[{amount:1050,count:12,purpose:'inter collegiate transfer'},{amount:525,count:3,purpose:'inter collegiate transfer'}] },
	// 		{ batchYear: '2 DC', classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:525 },
	// 		{ batchYear: '3 DC', classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:525 },
	// 		{ batchYear: '4 DC', classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:525 },
	// 		{ batchYear: '1 PG', classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:1890,remittanceCertificate:[{amount:1890,count:2,purpose:'inter collegiate transfer'},{amount:945,count:1,purpose:'inter collegiate transfer'}] },
	// 		{ batchYear: '2 PG', classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2000, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 1000, collectionOfcurMonth: 30000, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 0, oldBalanceRemitted: 2000, curMonthColRemitted: 500, totalCurrentRemitted: 2500, totalRemittedTillIncCurMonth: 22000, pendingBalRemittance: 0, remarks: '',feeAmount:945 },
	// 	],
	// 	currentAcaTotal: { classStrengthAtBeginCurMonth: { fullFee: 768, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 920010, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 920130, collectionOfcurMonth: 6420, collectionUptoIncCurMonth: 21000, feeAndArrearBalance: 913710, oldBalanceRemitted: 2000, curMonthColRemitted: 6420, totalCurrentRemitted: 6420, totalRemittedTillIncCurMonth: 656920, pendingBalRemittance: 0, remarks: '' },
	// 	grandTotal: { classStrengthAtBeginCurMonth: { fullFee: 68, halfFee: null, noFee: null }, AdmissionsCurMonth: { fullFee: 0, halfFee: null, noFee: null }, removalCurMonth: { fullFee: 0, halfFee: null, noFee: null }, classStrengthAtEndCurMonth: { fullFee: 403, halfFee: null, noFee: null }, arrearEndOfPrvMonth: 2642875, feeDueCurMonth: 0, miscFee: 0, totalDueIncArrear: 2643765, collectionOfcurMonth: 378990, collectionUptoIncCurMonth: 5311447, feeAndArrearBalance: 2264775, oldBalanceRemitted: 2000, curMonthColRemitted: 378990, totalCurrentRemitted: 378990, totalRemittedTillIncCurMonth: 5311447, pendingBalRemittance: 0, remarks: '' },
	// 	otherFeeAndFines: {
	// 		grandTotal:770,
	// 		feeDetails:[
	// 			{ feeName: 'Library fine', count:0,feeAmount:0,total:0 },
	// 			{ feeName: 'TC Late fee at the Month', count:2,feeAmount:55,total:110 },
	// 			{ feeName: 'Duplicate TC', count:0,feeAmount:55,total:0 },
	// 			{ feeName: 'Re-Admission Fee',feeSubName:"1st Term", count:0,feeAmount:55,total:0 },
	// 			{ feeName: 'Re-Admission Fee',feeSubName:"2nd Term", count:0,feeAmount:55,total:0 },
	// 			{ feeName: 'Re-Admission Fee',feeSubName:"1st & 2nd Term", count:0,feeAmount:55,total:0 },
	// 		]
	// 	}
	// };

	const [data, setData] = useState(dcbData);
	const [defaultTexts, setDefaultTexts] = useState({
		headers: [
			{
				type: 'headers',
				keyName: 'header2',
				text: `To be submitted on or before the fifteenth of every succeeding month`,
			},
		],
		certifications: [
			{
				type: 'certifications',
				keyName: 'certify1',
				text: `Certified that I have personally verified the entries with the attendance, fees, and admission registers, as well as the counterfoil of the fee receipts, and have found them correct.`,
			},
			{
				type: 'certifications',
				keyName: 'certify2',
				text: `Certified that a sum of Rs.${dcbData?.grandTotal?.totalCurrentRemitted} (Rupees ${numberToWords(dcbData?.grandTotal?.totalCurrentRemitted)}) has been remitted into the District Treasury during the month of ${moment(state?.monthAndYear).format('MMMM YYYY')}.`,
			},
		],
		notes: [
			{
				type: 'notes',
				keyName: 'note1',
				text: `Details of fines and the statement of remittance are to be accompanied.`,
			},
		],
		remittanceText: [
			{
				type: 'remittanceText',
				keyName: 'remittanceText',
				text: `Tuition fees for the academic year ${dcbData?.lastRemittedStartAcaYear} to ${dcbData?.lastRemittedEndAcaYear} have been remitted to the Government account.`,
			},
		],
	});

	const PrintStyles3 = () => {
		return (
			<style>
				{`
					@media print {
						@page {
							size: A3 landscape;
							margin: 0; /* Optional: Remove default margins */
						}
						body {
							-webkit-print-color-adjust: exact; /* Ensures colors print accurately */
						}
					}
				`}
			</style>
		);
	};
	const getPreviousAcademicYear = (currentYear) => {
		if (!currentYear) return 'N/A';
		const [startYear, endYear] = currentYear.split('-').map(Number);
		if (!startYear || !endYear) return 'Invalid Format';
		return `${startYear - 1}-${endYear - 1}`;
	};

	return (
		<Main title='Leave Reports' width='100vw' height='100vh'>
			<Header>
				<div className='row flex'>
					{/* <div style={{ width: 550 }}>
						<Input type='drop' options={STAFF_TYPE || []} state={state} fieldName='Select Role' optionKey='_id' didntShowKey optionDisplay='name' returnKey='staffType' setState={setState} width='90%' />
					</div> */}
					<div className='flex w-auto'>
						<Input type='month' fieldName='Month & Year' state={state} setState={setState} returnKey='monthAndYear' width='20vw' />
						{/* <Input type='date' fieldName='End Date' returnKey='endDate' state={state} setState={setState} width='10vw' /> */}
					</div>
				</div>
				<div className='flex gap-2'>
					{/* <Button type='fetch' onClick={()=>setSettings(true)}>Settings</Button> */}

					<Button type='print' tableRef={ref2.current}>
						print
					</Button>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
			</Header>

			<Body width='100vw' height='90vh'>
				<div className='p-4 w-full h-full overflow-y-scroll flex justify-center items-start'>
					{state?.monthAndYear ? (
						isFetching ? (
							<div className='w-[80%] h-[80%] flex justify-center items-center bg-white rounded shadow'>
								<SyncLoader color='#002147' size={15} />
							</div>
						) : (
							<section className='w-full printable' ref={ref2}>
								<PrintStyles3 />

								<article className='p-10 max-w-screen-2xl mx-auto bg-white rounded-lg mb-8 print:p-0 print:m-10 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold' }}>
									<div className='text-center mb-1'>
										<h5 className='font-bold mb-2' contentEditable suppressContentEditableWarning>
											{collegeData?.collegeName}
										</h5>
										<h6 className='font-semibold ' contentEditable suppressContentEditableWarning>
											DEMAND, COLLECTION AND BALANCE STATEMENT OF FEE AND OTHER COLLECTION FOR THE MONTH OF {moment(state?.monthAndYear).format('MMMM YYYY')}
										</h6>
										{defaultTexts?.headers.map((x, i) => (
											<p className='m-0 p-o leading-3' contentEditable suppressContentEditableWarning>
												{x.text}
											</p>
										))}
									</div>
									<table className='w-full border-collapse border border-black text-xs print:text-[8px]'>
										<thead>
											<tr>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3' style={{ height: '70px' }}></th>
												<th rowSpan={2} colSpan={3} className='border border-black px-1 text-center print:py-0 print:leading-3 whitespace-normal'>
													Strength of the class at the beginning of the Month
												</th>
												<th rowSpan={2} colSpan={3} className='border border-black px-1 text-center print:py-0 print:leading-3 whitespace-pre-line'>{`Admissions & withdrawals \n +Admissions\n -Removals`}</th>
												<th rowSpan={2} colSpan={3} className='border border-black px-1 text-center print:py-0 print:leading-3 whitespace-normal'>{`Strength at the class at the end of the Month`}</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
													Rate of Fees
												</th>
												<th rowSpan={1} colSpan={4} className='border border-black px-1 text-center print:py-0 print:leading-3 font-bold'>
													DEMAND
												</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3'>
													Collection
												</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3'>
													Collection up to and including the month
												</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3'>
													Balance
												</th>
												<th rowSpan={1} colSpan={3} className='border border-black px-1 text-center print:py-0 print:leading-3 font-bold'>
													Remitted to the Treasury
												</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3'>
													Remittance in to the Treasury up to the end of the month
												</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3 print:whitespace-normal' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-rl', whiteSpace: 'normal', wordBreak: 'break-word', height: '100px' }}>{`Balance pending remittance`}</th>
												<th rowSpan={3} colSpan={1} className='border border-black px-1 text-center print:py-0 print:leading-3 whitespace-normal' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}>
													Remarks
												</th>
											</tr>
											<tr>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Arrear up to the end of previous Month
												</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Fees due for the current month/ Addi. Demand
												</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>{`Lab. / Lib. Fee, \n Fine and Misc`}</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Total
												</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Out of unremitted balnce of the previous Month
												</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Out of the collection of the Month
												</th>
												<th rowSpan={2} colSpan={1} className='border border-black px-1 text-center'>
													Total
												</th>
											</tr>
											<tr>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Full Fess
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Half Fees
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													No Fees
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Full Fess
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Half Fees
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													No Fees
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Full Fess
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													Half Fees
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													No Fees
												</th>
											</tr>
											<tr>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													1
												</th>
												<th rowSpan={1} colSpan={3} className='border border-black px-1 text-center'>
													2
												</th>
												<th rowSpan={1} colSpan={3} className='border border-black px-1 text-center'>
													3
												</th>
												<th rowSpan={1} colSpan={3} className='border border-black px-1 text-center'>
													4
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													5
												</th>
												<th rowSpan={1} colSpan={4} className='border border-black px-1 text-center'>
													6
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													7
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													8
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													9
												</th>
												<th rowSpan={1} colSpan={3} className='border border-black px-1 text-center'>
													10
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													11
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													12
												</th>
												<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
													13
												</th>
											</tr>
											<tr>
												{Array.from({ length: 10 }).map((_, index) => (
													<th key={index} className='border border-black px-1 text-center' style={{ width: '50px' }}></th>
												))}
												<th className='border border-black px-1 text-center' style={{ width: '30px' }}></th>
												{Array.from({ length: 12 }).map((_, index) => (
													<th key={index} className='border border-black px-1 text-center' style={{ width: '100px' }}>
														<div className='flex justify-evenly'>
															<span>Rs.</span>
															<span>Ps.</span>
														</div>
													</th>
												))}
												<th className='border border-black px-1 text-center' style={{ width: '50px' }}></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td rowSpan={1} colSpan={24} className='border border-black px-1 text-left' contentEditable suppressContentEditableWarning>
													{defaultTexts?.remittanceText[0]?.text}
												</td>
											</tr>

											<tr>
												<td rowSpan={1} colSpan={4} className='border border-black px-1 text-left'>
													{'2016-2017'}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={100} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{1000}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{0}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{''}
												</td>
											</tr>
											{dcbData &&
												dcbData?.pastAcaYears?.map((x, i) => (
													<tr>
														<td rowSpan={1} colSpan={4} className='border border-black px-1 text-left'>
															{x?.feeName}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.arrearEndOfPrvMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.feeDueCurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.miscFee}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.totalDueIncArrear}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.collectionOfcurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.collectionUptoIncCurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.feeAndArrearBalance}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.oldBalanceRemitted}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.curMonthColRemitted}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.totalCurrentRemitted}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.totalRemittedTillIncCurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.pendingBalRemittance}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
															{x?.remarks}
														</td>
													</tr>
												))}
											<tr>
												<td rowSpan={1} colSpan={4} className='border border-black px-1 text-left'>
													{'TOTAL'}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.arrearEndOfPrvMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.feeDueCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.miscFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.totalDueIncArrear}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.collectionOfcurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.collectionUptoIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.feeAndArrearBalance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.oldBalanceRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.curMonthColRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.totalCurrentRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.totalRemittedTillIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.pendingBalRemittance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.pastAcaYearTotal?.remarks}
												</td>
											</tr>

											<tr>
												<td rowSpan={1} colSpan={4} className='border border-black px-1 text-left'>
													{dcbData?.curAcaYear}
												</td>
											</tr>

											{dcbData?.currentAcaYearData.map((y, j) => (
												<tr>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.batchYear}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtBeginCurMonth?.fullFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtBeginCurMonth?.halfFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtBeginCurMonth?.noFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														<p className='leading-3 p-0 m-0'>{y?.AdmissionsCurMonth?.fullFee}</p>
														<p className='leading-3 p-0 m-0'>{y?.removalCurMonth?.fullFee}</p>
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														<p className='leading-3 p-0 m-0'>{y?.AdmissionsCurMonth?.halfFee}</p>
														<p className='leading-3 p-0 m-0'>{y?.removalCurMonth?.halfFee}</p>
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														<p className='leading-3 p-0 m-0'>{y?.AdmissionsCurMonth?.noFee}</p>
														<p className='leading-3 p-0 m-0'>{y?.removalCurMonth?.noFee}</p>
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtEndCurMonth?.fullFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtEndCurMonth?.halfFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
														{y?.classStrengthAtEndCurMonth?.noFee}
													</td>

													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.arrearEndOfPrvMonth}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.feeDueCurMonth}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.miscFee}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.totalDueIncArrear}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.collectionOfcurMonth}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.collectionUptoIncCurMonth}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.feeAndArrearBalance}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.oldBalanceRemitted}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.curMonthColRemitted}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.totalCurrentRemitted}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.totalRemittedTillIncCurMonth}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.pendingBalRemittance}
													</td>
													<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
														{y?.remarks}
													</td>
												</tr>
											))}
											<tr>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{'TOTAL'}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtBeginCurMonth?.fullFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtBeginCurMonth?.halfFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtBeginCurMonth?.noFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.AdmissionsCurMonth?.fullFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.removalCurMonth?.fullFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.AdmissionsCurMonth?.halfFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.removalCurMonth?.halfFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.AdmissionsCurMonth?.noFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.currentAcaTotal?.removalCurMonth?.noFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtEndCurMonth?.fullFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtEndCurMonth?.halfFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.currentAcaTotal?.classStrengthAtEndCurMonth?.noFee}
												</td>

												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.arrearEndOfPrvMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.feeDueCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.miscFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.totalDueIncArrear}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.collectionOfcurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.collectionUptoIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.feeAndArrearBalance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.oldBalanceRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.curMonthColRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.totalCurrentRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.totalRemittedTillIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.pendingBalRemittance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.currentAcaTotal?.remarks}
												</td>
											</tr>
											<tr>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{'GRAND TOTAL'}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtBeginCurMonth?.fullFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtBeginCurMonth?.halfFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtBeginCurMonth?.noFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.AdmissionsCurMonth?.fullFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.removalCurMonth?.fullFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.AdmissionsCurMonth?.halfFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.removalCurMonth?.halfFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.AdmissionsCurMonth?.noFee}</p>
													<p className='leading-3 p-0 m-0'>{dcbData?.grandTotal?.removalCurMonth?.noFee}</p>
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtEndCurMonth?.fullFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtEndCurMonth?.halfFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-left'>
													{dcbData?.grandTotal?.classStrengthAtEndCurMonth?.noFee}
												</td>

												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.arrearEndOfPrvMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.feeDueCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.miscFee}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.totalDueIncArrear}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.collectionOfcurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.collectionUptoIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.feeAndArrearBalance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.oldBalanceRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.curMonthColRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.totalCurrentRemitted}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.totalRemittedTillIncCurMonth}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.pendingBalRemittance}
												</td>
												<td rowSpan={1} colSpan={1} className='border border-black px-1 text-right'>
													{dcbData?.grandTotal?.remarks}
												</td>
											</tr>
										</tbody>
									</table>
									<div className='mt-3'>
										{defaultTexts?.certifications.map((x, i) => (
											<p className='m-0 p-o leading-3' contentEditable suppressContentEditableWarning>
												({i + 1 + ') ' + x.text}
											</p>
										))}
									</div>
									<section className='flex  gap-6'>
										<div className='w-[400px] my-2'>
											<table className='w-full border-collapse border border-black text-sm print:text-xs'>
												<thead>
													<tr>
														<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															Item
														</th>
														<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															Demand
														</th>
														<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															Collection
														</th>
														<th rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															Balance
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															UPTO {getPreviousAcademicYear(dcbData?.curAcaYear)}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.pastAcaYearTotal?.totalDueIncArrear}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.pastAcaYearTotal?.collectionOfcurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.pastAcaYearTotal?.feeAndArrearBalance}
														</td>
													</tr>
													<tr>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.curAcaYear}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.currentAcaTotal?.totalDueIncArrear}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.currentAcaTotal?.collectionOfcurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.currentAcaTotal?.feeAndArrearBalance}
														</td>
													</tr>
													<tr>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{'TOTAL'}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.grandTotal?.totalDueIncArrear}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.grandTotal?.collectionOfcurMonth}
														</td>
														<td rowSpan={1} colSpan={1} className='border border-black px-1 text-center'>
															{dcbData?.grandTotal?.feeAndArrearBalance}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div className='mt-3 flex gap-4 items-start'>
											<div>Note:</div>
											<div>
												{defaultTexts?.notes.map((x, i) => (
													<p className='m-0 p-o leading-3' contentEditable suppressContentEditableWarning>
														{x.text}
													</p>
												))}
												{defaultTexts?.remittanceText.map((x, i) => (
													<p className='m-0 p-o leading-3' contentEditable suppressContentEditableWarning>
														{x.text}
													</p>
												))}
											</div>
										</div>
									</section>
								</article>

								<article className='p-10 max-w-screen-2xl mx-auto bg-white rounded-lg mb-8 print:p-0  print:m-10 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold' }}>
									<div className='text-center mb-1'>
										<h5 className='font-bold mb-2' contentEditable suppressContentEditableWarning>
											{collegeData?.collegeName}
										</h5>
									</div>

									<table className='w-full border-collapse text-xs print:text-[8px]'>
										<thead>
											<tr>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '270px' }}></th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '100px' }}></th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '100px' }}></th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '100px' }}>
													No.
												</th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '100px' }}>
													AMOUNT
												</th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '100px' }}>
													TOTAL
												</th>
												<th rowSpan={1} colSpan={1} className='px-1 text-center print:py-0 print:leading-3' style={{ width: '270px' }}>
													GRAND TOTAL
												</th>
											</tr>
										</thead>
										{dcbData?.currentAcaYearData?.map((x, i) => (
											<>
												<tbody className='border border-black'>
													<tr>
														<td rowSpan={1} colSpan={1} className='px-1 text-left'>
															{x?.batchYear} students strength at the beginning of the month
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															:
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{Object.values(x?.classStrengthAtBeginCurMonth).reduce((acc, x) => acc + x, 0)}
														</td>
														<td rowSpan={1} colSpan={4} className='px-1 text-left' contentEditable suppressContentEditableWarning>
															{x?.remittanceCertificate && <span>Remittance Certificate @ {x?.remittanceCertificate?.reduce((acc, el) => acc + el?.amount + ' for ' + el?.count + ' students for ' + el?.purpose + ', ', ' ')}</span>}
														</td>
													</tr>
													<tr>
														<td rowSpan={1} colSpan={1} className='px-1 text-left'>
															Admission During the Period
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															:
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{Object.values(x?.AdmissionsCurMonth).reduce((acc, x) => acc + x, 0)}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
													</tr>
													<tr>
														<td rowSpan={1} colSpan={1} className='px-1 text-left'>
															TC Issued During the Period
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															:
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{Object.values(x?.removalCurMonth).reduce((acc, x) => acc + x, 0)}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
													</tr>
													<tr>
														<td rowSpan={1} colSpan={1} className='px-1 text-left'>
															Demand of Fee
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															:
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{x?.feeAmount}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
														<td rowSpan={1} colSpan={1} className='px-1 text-center'>
															{''}
														</td>
													</tr>
												</tbody>
												<tr>
													<td colSpan={7} style={{ height: '20px' }}></td> {/* Spacer Row */}
												</tr>
											</>
										))}
										<tbody className='pl-10'>
											{dcbData?.otherFeeAndFines?.feeDetails?.map((x, i) => (
												<tr>
													<td rowSpan={1} colSpan={1} className=' pl-10 text-left'>
														{x.feeName} <span>{x?.feeSubName}</span>
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														:
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														{''}
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														{x?.count}
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														{x?.feeAmount}
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														{x?.total}
													</td>
													<td rowSpan={1} colSpan={1} className='px-1 text-center'>
														{''}
													</td>
												</tr>
											))}
											<tr>
												<td rowSpan={1} colSpan={1} className=' pl-10 text-left'>
													{'GRAND TOTAL'}{' '}
												</td>
												<td rowSpan={1} colSpan={1} className='px-1 text-center'>
													:
												</td>
												<td rowSpan={1} colSpan={4} className='px-1 text-center'>
													{''}
												</td>
												<td rowSpan={1} colSpan={1} className='px-1 text-center'>
													{dcbData?.otherFeeAndFines?.grandTotal}
												</td>
											</tr>
										</tbody>
									</table>
									<div className='w-full h-full flex justify-around'>
										<div className='w-1/2'></div>
										<p className='text-right pr-[100px] w-1/2'>PRINCIPAL</p>
									</div>
								</article>
							</section>
						)
					) : (
						<div className='w-[80%] h-[80%] flex justify-center items-center bg-white rounded shadow'>
							<h3>Select Month</h3>
						</div>
					)}
				</div>
			</Body>
			{settings && (
				<Main width='60vw' height='70vh'>
					<Header>
						<div></div>
						<Button type='close' onClick={() => setSettings(false)}>
							close
						</Button>
					</Header>
					<Body width='full' height='full'>
						<div className='w-full h-full'></div>
					</Body>
				</Main>
			)}
		</Main>
	);
};
