/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import classNames from './studentTable.module.scss';
import { Button, Dropdown, Menu, Tooltip, Table } from 'antd';
import { DownloadOutlined, EditFilled, SettingTwoTone } from '@ant-design/icons';
import MobileNumberUpdateModal from './MobileNumberUpdateModal';
import { ReactComponent as DownCircle } from '../../static/images/clipIcon/downCircle.svg';
import Scrollbars from 'react-custom-scrollbars';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { DeletePopUp, SuspendPopUp, DismissPopUp, DiscontinePopUp, TranferPopUp, ChangePopUp } from './ActionPopup/PopUp';
import { MASTER_TABLE_KEYS } from '../../config/constants';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useMutation } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import UserDetails from '../../CollegeApp/Office/MasterTable/UserDetails';

export const exportPdfBillPrinting = async (olddata) => {
	let url = ['/college/reportGeneration/generateApplicationForm'];
	if (olddata.userType === 'teacher') {
		url = ['/college/reportGeneration/generateLessonPlanReport'];
	}
	const instance = await getAxiosTokenInstance();
	try {
		let data = {
			collegeId: olddata.collegeId,
			userId: olddata.userId,
		};
		const resp = await instance.post(...url, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

function StudentTable({ list = [], index, setIndex, refetch, search, userType }) {
	const { collegeId } = useContext(FirebaseContext);
	const exportPdfMutationBillPrint = useMutation(exportPdfBillPrinting);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isNameModalOpen, setIsNameModalOpen] = useState(false);
	const [modalData, setModalData] = useState('');
	const [nameModalData, setNameModalData] = useState('');
	const [userAction, setUserAction] = useState('');
	const [count, setCount] = useState(50);
	const [columnIndex, setColumnIndex] = useState(0);
	const [tableColumn, updateTableColumn] = useState([]);
	const [columns, updateColumns] = useState(MASTER_TABLE_KEYS);

	useEffect(() => {
		setIsModalOpen(false);
		setModalData('');
	}, [list]);

	useEffect(() => {
		setIsNameModalOpen(false);
		setNameModalData('');
	}, [list]);

	useEffect(() => {
		generateTableColumns();
	}, [columns, index]);

	useEffect(() => {
		setCount(50);
	}, [search, userType]);

	const onMobileEditClick = (userId, currentNumber, name) => {
		setModalData({ uid: userId, currentNumber, name });
		setIsModalOpen(true);
	};
	const onNameEditClick = (userId, currentNumber, name) => {
		setNameModalData({ uid: userId, currentNumber, name });
		setIsNameModalOpen(true);
	};

	const handlePrint = async (userId, user) => {
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				userId,
				userType,
			};

			link.download = userId;
			const pdfData = await exportPdfMutationBillPrint.mutateAsync(postData);
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
		} catch (e) {}
	};
	const handleColumnChange = (event) => {
		event?.domEvent?.stopPropagation();

		let newArray = [];
		let columnId = event?.item?.props?.value?.columnId;

		let columnValue = JSON.parse(JSON.stringify(columns)).splice(columnId, 1);
		columnValue[0].show = false;

		columns?.map((item, key) => {
			if (key === columnId) {
				newArray.push({
					...event?.item?.props?.value,
					show: true,
				});
			} else if (event?.item?.props?.value?.key === item?.key) {
				newArray.push(columnValue[0]);
			} else {
				newArray.push(item);
			}
		});
		updateColumns(newArray);
	};

	let exportPdf = async () => {
		await html2canvas(document.querySelector('#capture'), {
			onclone: function (doc) {
				doc.getElementById('capture').style.display = 'block';
			},
		}).then((canvas) => {
			// document.body.appendChild(canvas);
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF();
			pdf.addImage(imgData, 'PNG', 0, 0);
			pdf.save('download.pdf');
		});
	};
	list = list?.sort((a, b) => a?.activeStatus?.localeCompare(b?.activeStatus));

	const generateTableColumns = () => {
		let columnList = [];
		columns?.map((item, key) => {
			if (key >= 3 && key < 6) {
				columnList.push({
					show: item.show,
					exportDisabled: item.exportDisabled,
					title: () => {
						return (
							<div>
								{item.title}
								<Dropdown
									overlay={
										<Menu onClick={handleColumnChange}>
											{columns?.map((column) => (
												<>
													{!column?.show && (
														<Menu.Item key={column?.key} value={{ ...column, columnId: key }}>
															{column?.title}
														</Menu.Item>
													)}
												</>
											))}
										</Menu>
									}
									trigger={['click']}>
									<>
										<Button
											onClick={(e) => {
												e.preventDefault();

												e.stopPropagation();
												// handleColumnHover(e, key);
											}}
											style={{
												border: 'none',
												background: 'transparent',
												marginLeft: '5px',
												width: '26px',
											}}
											icon={<DownCircle style={{ width: '22px', height: '22px' }} />}
										/>
									</>
								</Dropdown>
							</div>
						);
					},
					dataIndex: item?.dataIndex,
					title2: item?.title,
					key: 'columnlist',
					render: (_text, user, _i) => (
						<div>
							{user[item?.dataIndex]}
							{item?.key === 'number' && (
								<Tooltip title='search'>
									<Button style={{ marginLeft: 10 }} type='primary' shape='circle' icon={<EditFilled />} size='small' onClick={() => onMobileEditClick(user._id || user.uid, user.phoneNumber, user.name)} />
									{['scholars', 'student'].includes(userType) && <Button style={{ marginLeft: 10 }} type='default' shape='default' icon={<img src='https://cdn-icons-png.flaticon.com/512/10994/10994372.png' alt='Edit Profile Icon' style={{ width: '16px', height: '16px' }} />} size='small' onClick={() => onNameEditClick(user._id || user.uid, user.phoneNumber, user.name)} />}

									<Button style={{ marginLeft: 10 }} type='primary' shape='circle' icon={<DownloadOutlined />} size='small' onClick={() => handlePrint(user._id || user.uid, user)} />
								</Tooltip>
							)}
						</div>
					),
					sorter: (a, b) => (a[item?.dataIndex] > b[item?.dataIndex] ? 1 : b[item?.dataIndex] > a[item?.dataIndex] ? -1 : 0),
				});
			} else {
				switch (item?.key) {
					case 'select':
						columnList.push({
							show: item.show,
							exportDisabled: item.exportDisabled,
							title: 'Select',
							key: 'operation',
							render: (_text, record, _i) => (
								<div className={classNames.tik}>
									<input
										name='isGoing'
										type='checkbox'
										checked={record._id === index}
										onChange={() => {
											setIndex(record._id);
										}}
									/>
								</div>
							),
						});
						break;
					case 'action': {
						columnList.push({
							show: item.show,
							exportDisabled: item.exportDisabled,
							title: 'Action',
							key: 'operation',
							render: () => (
								<Dropdown overlay={menu} trigger={['contextMenu', 'click']}>
									<Button style={{ border: 'none' }} icon={<SettingTwoTone />} />
								</Dropdown>
							),
						});
						break;
					}
					case 'status': {
						columnList.push({
							title: 'Status',
							show: item.show,
							exportDisabled: item.exportDisabled,
							dataIndex: 'status',
							render: (_, data, _i) => <div className={`${classNames.status_tag} ${data.status === 'Active' ? classNames.active_status : data.status === 'Suspended' ? classNames.suspend_status : classNames.delete_status}`}>{data.status || '-'}</div>,
						});
						break;
					}
					default: {
						columnList.push({
							show: item.show,
							exportDisabled: item.exportDisabled,
							title: item.title,
							dataIndex: item?.dataIndex,
							key: item.key,
							sorter: (a, b) => (a[item?.dataIndex] > b[item?.dataIndex] ? 1 : b[item?.dataIndex] > a[item?.dataIndex] ? -1 : 0),
						});
						break;
					}
				}
			}
		});
		updateTableColumn(columnList);
	};

	const actionHandler = ({ key }) => setUserAction(key);

	const menu = <Menu onClick={actionHandler}></Menu>;
	return (
		<Scrollbars
			className={classNames.tableWrap}
			onScrollFrame={(data) => {
				data.top >= 0.999 && setCount((count) => count + 10);
			}}>
			<div className={classNames.userTable}>
				{/* <Button onClick={exportPdf}>Print</Button> */}

				<ReactHTMLTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='tablexls' sheet='tablexls' buttonText='Export To XLS' />

				<Table
					columns={tableColumn?.filter((column) => column.show)}
					dataSource={list?.filter((user) => user?.admisionNo?.toLowerCase().includes(search?.toLowerCase()) || user?.phoneNumber?.toLowerCase().includes(search.toLowerCase()) || user?.name?.toLowerCase().includes(search.toLowerCase())).slice(0, 80)}
					pagination={false}
					onRow={(record, _recordIndex) => ({
						onClick: (_event) => setIndex(record._id),
					})}
					// bordered
				/>
			</div>
			{userAction === 'Delete' && <DeletePopUp setAction={setUserAction} uid={index} refetch={refetch} />}
			{userAction === 'Suspent' && <SuspendPopUp setAction={setUserAction} uid={index} isStudent={userType === 'student'} />}
			{userAction === 'Dismiss' && <DismissPopUp setAction={setUserAction} />}
			{userAction === 'Discontined' && <DiscontinePopUp setAction={setUserAction} uid={index} isStudent={userType === 'student'} />}
			{userAction === 'Transfer' && <TranferPopUp setAction={setUserAction} uid={index} isStudent={userType === 'student'} />}
			{userAction === 'Change' && <ChangePopUp setAction={setUserAction} uid={index} isStudent={userType === 'student'} refetch={refetch} />}

			<MobileNumberUpdateModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} modalData={modalData} refetch={refetch} />
			<UserDetails isOpen={isNameModalOpen} onClose={() => setIsNameModalOpen(false)} modalData={nameModalData} userType={userType} refetch={refetch} />

			<div id='capture' style={{ display: 'none' }}>
				<table id='emp'>
					<thead>
						<tr>{tableColumn?.map((item, key) => !item.exportDisabled && <th key={key}>{item.title2 ? item.title2 : item.title}</th>)}</tr>
					</thead>
					<tbody>
						{list?.map((listItem, listKey) => (
							<tr key={listKey}>{tableColumn?.map((item, key) => !item.exportDisabled && <td key={key}>{item.dataIndex ? listItem[item.dataIndex] : ''}</td>)}</tr>
						))}
					</tbody>
				</table>
			</div>
		</Scrollbars>
	);
}

export default StudentTable;
