import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PieChartComponent = () => {
	const data = [
		{ name: 'Students', value: 150, color: '#4CAF50' },
		{ name: 'Teachers', value: 100, color: '#FF9800' },
		{ name: 'Scholars', value: 50, color: '#9C27B0' },
		{ name: 'Staff', value: 25, color: '#90A4AE' },
	];

	const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

	return (
		<ResponsiveContainer width='100%' height='100%'>
			<PieChart>
				<Pie data={data} dataKey='value' nameKey='name' innerRadius='0%' outerRadius='90%' startAngle={0} endAngle={360} paddingAngle={1} animationDuration={3000} animationEasing='ease-out'>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={entry.color} />
					))}
				</Pie>
				<Tooltip
					content={({ payload }) => {
						if (payload && payload.length) {
							const { name, value } = payload[0].payload;
							const percentage = ((value / totalValue) * 100).toFixed(1);
							return (
								<div style={{ padding: '10px', backgroundColor: 'white' }}>
									<strong>{name}</strong>: {percentage}% ({value})
								</div>
							);
						}
						return null;
					}}
				/>
				<Legend verticalAlign='top' align='center' iconType='circle' />
			</PieChart>
		</ResponsiveContainer>
	);
};

export default PieChartComponent;
