import React, { useContext, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";

interface invoiceManagementProps {
  onClose: () => void;
}

const getFieldDropDowns = (data: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getNewAllUsers", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list[0].data;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function RecheckPayments({ onClose }: invoiceManagementProps) {
  const [state, setState] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const { collegeId } = useContext(FirebaseContext);
  const ref = useRef<any>(null);

  const { data: nameOfAuthorDrop = [] } = useQuery(
    ["dataDataData000", state?.userId],
    getFieldDropDowns({
      collegeId,
      collection: "users",
      primoryKey: "phoneNumber",
      secondaryKey: "name",
      tertiaryKey: "phoneNumber",
      search: state?.userId,
    })
  );

  const addNewForm = async () => {
    setIsLoading(true);
    const instance = await getAxiosTokenInstance();
    try {
      await instance.post("/razorpay/preApiForTransferFees", {
        ...state,
        collegeId,
      });
      setIsSuccessful(true);
    } catch (err) {
      console.error("Error during API call:", err);
      throw new Error("Failed to submit form:");
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsSuccessful(false);
    closeAnimation();
    setTimeout(onClose, 250);
  };

  return (
    <>
      <Main title="Academic Holiday Management" width="60%" height="60%">
        <Header>
          <div className="flex justify-between">
            <Button type="save" onClick={addNewForm} pdfId="accounts" disabled={isLoading}>
              {isLoading ? "Checking..." : "Check"}
            </Button>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(onClose, 250);
              }}
            >
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <Input width="100%" type="drop" options={nameOfAuthorDrop} typable fieldName="Student Name" optionDisplay="name" optionKey="_id" returnKey="userId" didntShowKey state={state} setState={setState} />
          <Input width="100%" fieldName="Amount" returnKey="amount" state={state} setState={setState} type="number" />
          <Input width="100%" fieldName="OrderId" returnKey="id" state={state} setState={setState} type="text" />
        </Body>
      </Main>

      {isSuccessful && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Success!</h2>
            <p className="mb-4">The payment recheck was successful.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default RecheckPayments;
