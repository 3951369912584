import React, { useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import styles from "./signUp.module.scss";
import Select from "antd/lib/select/index";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { EMAIL_REGEX, MOBILE_REGEX, PIN_REGEX } from "../../config/regex";
import { message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import bgImage from "../../static/images/bg/registerbg.png";
import logo from "../../static/images/logo.png";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../NecttosComp/Loaders/Loader";

const { Option } = Select;
const instance = getAxiosInstance();

async function getColleges() {
  const { data } = await instance.get("/register/forRegistration");
  return data;
}

function SelectCollege({ setCollegeId }) {
  const { values, setFieldValue } = useFormikContext();
  const [collegeList, setCollegeList] = useState([]);
  useEffect(() => {
    getColleges()
      .then((data) => {
        if (data.statusCode === 200) {
          setCollegeList(data.colleges);
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  }, []);

  return (
    <Select
      showSearch
      value={values.college}
      className={styles.formFullWidth}
      style={{ overflow: "hidden" }}
      onChange={(val, op) => {
        setCollegeId(op.key);
        setFieldValue("college", val);
      }}
    >
      <Option value="">Select Your College</Option>
      {collegeList?.map((college) => (
        <Option key={college._id} value={college.collegeName}>
          {college.collegeName}
        </Option>
      ))}
    </Select>
  );
}

function LogoInput() {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div className={styles.formFullWidth}>
      <label className={styles.formDistWidth}>
        <div className={`${styles.button} ${styles.uploadButton}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.476 25.03">
            <path id="Path_563" data-name="Path 563" d="M25.292,16.112H27.71V28.139a.274.274,0,0,0,.262.284h1.963a.275.275,0,0,0,.262-.284V16.112h2.425a.286.286,0,0,0,.206-.459l-3.665-5.041a.269.269,0,0,0-.091-.081.244.244,0,0,0-.229,0,.268.268,0,0,0-.092.081L25.086,15.65a.288.288,0,0,0,.206.462Zm15.641,10.96H38.969a.274.274,0,0,0-.262.284v5.475h-19.5V27.357a.275.275,0,0,0-.262-.284H16.981a.274.274,0,0,0-.262.284V34.4a1.094,1.094,0,0,0,1.047,1.138H40.147A1.094,1.094,0,0,0,41.194,34.4V27.357A.275.275,0,0,0,40.933,27.072Z" transform="translate(-16.719 -10.503)" fill="rgba(0,0,0,0.85)" />
          </svg>{" "}
          {values.logoFile?.name || "Upload Your Logo"}
          <input
            type="file"
            onChange={(e) => {
              setFieldValue("logoFile", e.target.files[0]);
            }}
            accept="image/png"
          />
        </div>
        <div className={styles.error}>
          <ErrorMessage name="logoFile" />
        </div>
      </label>
    </div>
  );
}

// function SignUpCollege({ onSuccess }) {
function SignUpCollege() {
  const [collegeId, setCollegeId] = useState("");
  const [textInput, setText] = useState("");
  const [currentQn, setCurrentQn] = useState("");
  const [answers, setAnswers] = useState("");
  const [completeNumber, setCompleteNumber] = useState(0);
  const [warning, setWarning] = useState("");
  const [submit,setSubmit] = useState(false)

  const assitantQn = ["Hi I am here to Assist you,Lets Begin ", "What is your College Name? ", "What is your College Email? ", "What is your College Principal Name? ", "What is your College Address? ", "What is your College Mobile Number? ", "We have sent you a OTP(One Time Password),Please Enter the OTP ", "What is your College Website? ", "What is your College District? ", "What is your College Pincode? "];
  const qnStoreValues = ["college", "email", "principalName", "address", "phoneNumber", "otp", "website", "district", "pincode"];

  // Uploading Image
  const storage = getStorage();

  useEffect(() => {
    const input = assitantQn[completeNumber].split("");
    let value = "";
    const elem = document.getElementById("giveValues");
    const interval = setInterval(() => {
      if (elem) {
        elem.style.display = "none";
      }
      setText(value);
      value = value + input.shift();
      if (!input.length) {
        if (elem) {
          elem.style.display = "flex";
          elem.style.transform = "translateX(0)";
        }
        clearInterval(interval);
      }
    }, 30);

    return () => {
      clearInterval(interval);
    };
  }, [completeNumber]);

  function signupCollege(values) {
    // message.loading({ content: "Uploading Logo...", key: "signup" }, 0);
    // const logoImagesRef = ref(storage, `colleges/${collegeId}/logo.png`);
    // uploadBytes(logoImagesRef, values.logoFile, {
    //   contentType: "image/png",
    // })
    //   .then((snapshot) => {
    //     getDownloadURL(ref(storage, logoImagesRef))
    //       .then((url) => {
    //         message.loading({ content: "Uploading Data...", key: "signup" }, 0);
    //         instance
    //           .post("/register/registerCollege", {
    //             collegeName: values.college,
    //             email: values.email,
    //             principalName: values.principalName,
    //             phoneNumber: values.phoneNumber,
    //             website: values.website,
    //             district: values.district,
    //             pin: values.pincode,
    //             address: values.address,
    //             logo: url,
    //           })
    //           .then(({ data }) => {
    //             if (data.statusCode !== 200) {
    //               throw new Error(data.message);
    //             }

    //             message.success({
    //               content: "College Added",
    //               key: "signup",
    //             });
    //             // onSuccess();
    //           })
    //           .catch((error) => {
    //             message.error(error?.response?.data?.message || error?.message || "API Error");
    //           });
    //       })
    //       .catch((error) => {
    //         message.error(error.message);
    //       });
    //   })
    //   .catch((e) => {
    //     console.log(`e.message`, e.message);
    //   });

    const instance = getAxiosInstance()
    setSubmit(true)
    instance
      .post("/register/registerCollege", {
        collegeName: values.college,
        email: values.email,
        principalName: values.principalName,
        phoneNumber: values.phoneNumber,
        website: values.website,
        district: values.district,
        pin: values.pincode,
        address: values.address,
        otp:values.otp,
        token:values.token
        // logo: url,
      })
      .then(({ data }) => {
        console.log(data);
        const input = `Congratulations, your college has been successfully registered!  `.split("");
        let value = "";
        const interval = setInterval(() => {
          setWarning(value);
          value = value + input.shift();
          if (!input.length) {
            clearInterval(interval);
          }
        }, 30);
      })
      .catch((error) => {
        console.log(error)
        const input = `The OTP you typed is incorrect `.split("");
        let value = "";
        const interval = setInterval(() => {
          setWarning(value);
          value = value + input.shift();
          if (!input.length) {
            clearInterval(interval);
          }
        }, 30);
      });
  }

  const sendOTP = () => {
    const instance = getAxiosInstance();
    instance
      .post("/auth/newCollege-send-otp", { phoneNumber: answers?.phoneNumber })
      .then((res) => {
        console.log(res.data);
        setAnswers({ ...answers, token: res?.data?.token });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // New

  return (
    // <div className={styles.signUp}>
    //   <div className={styles.title}>REGISTER NOW</div>
    //   <Formik
    //     initialValues={{
    //       college: "",
    //       email: "",
    //       principalName: "",
    //       address: "",
    //       phoneNumber: "",
    //       website: "",
    //       district: "",
    //       pincode: "",
    //     }}
    //     validationSchema={VALIDATION_SCHEMA}
    //     onSubmit={(values) => {
    //       signupCollege(values);
    //     }}
    //   >
    //     <Form className={styles.forms}>
    //       <SelectCollege setCollegeId={setCollegeId} />
    //       <div className={styles.error}>
    //         <ErrorMessage name="college" />
    //       </div>
    //       <div className={`${styles.group} ${styles.formFullWidth}`}>
    //         <Field type="text" name="email" required="required" />
    //         <label>Email Id</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="email" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formFullWidth}`}>
    //         <Field type="text" name="principalName" required="required" />
    //         <label>Principal Name</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="principalName" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formFullWidth}`}>
    //         <Field type="text" name="address" required="required" />
    //         <label>Address</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="address" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formFullWidth}`}>
    //         <Field type="text" name="phoneNumber" required="phoneNumber" maxLength={10} />
    //         <label>Mobile Number</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="phoneNumber" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formFullWidth}`}>
    //         <Field type="text" name="website" required="required" />
    //         <label>Website</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="website" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formDistWidth}`}>
    //         <Field type="text" name="district" required="required" />
    //         <label>District</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="district" />
    //         </div>
    //       </div>
    //       <div className={`${styles.group} ${styles.formPinWidth}`}>
    //         <Field
    //           type="text"
    //           name="pincode"
    //           required="required"
    //           maxLength={6}
    //         />
    //         <label>Pincode</label>
    //         <div className={styles.error}>
    //           <ErrorMessage name="pincode" />
    //         </div>
    //       </div>

    //       <LogoInput />

    //       <div className={styles.formButtonWidth}>
    //         <button type="primary">
    //           Register{" "}
    //           <ArrowRightOutlined
    //             style={{ fontSize: 22, margin: "auto 0 auto 5px" }}
    //           />
    //         </button>
    //       </div>
    //       <div className={styles.formButtonWidth} style={{ marginTop: 30 }}>
    //         <Link type="primary" to={"/home"}>
    //           Back to Home{" "}
    //           <ArrowRightOutlined
    //             style={{ fontSize: 22, margin: "auto 0 auto 5px" }}
    //           />
    //         </Link>
    //       </div>
    //     </Form>
    //   </Formik>
    // </div>
    <div className="h-screen w-screen flex justify-center  items-center" style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: "center", backgroundSize: "cover" }}>
      <Toaster />
      <div className="w-[80vw] h-[80vh] blur-custom  shadow-2xl rounded-3xl  grid md:grid-cols-2 gap-2  p-4">
        <div className="md:flex hidden flex-col justify-center items-center">
          <img src={logo} alt="" />
          <h3 className="drop-shadow-md text-white">Welcome to Necttos</h3>
          <p className="drop-shadow-md text-[#c4c4c4] text-center w-1/2">Revolutionize college management with Necttos. Experience efficiency, integration, and a seamless academic journey in just one click.</p>
        </div>
        <div className="shadow-lg shadow-slate-100 bg-[#8b0045af] rounded-3xl  md:rounded-[0_20px_20px_0px] flex justify-center items-center">
          <form
            className="flex flex-col justify-center w-3/4"
            onSubmit={(e) => {
              e.preventDefault();
              if (answers?.[qnStoreValues?.[completeNumber - 1]]?.length > 0 || completeNumber <= 0) {
                if (qnStoreValues?.[completeNumber - 1] === "phoneNumber") {
                  sendOTP();
                }
                if (completeNumber < qnStoreValues?.length - 1) {
                  setText("");
                  setCompleteNumber(completeNumber + 1);
                } else {
                  signupCollege(answers);
                }
                setCurrentQn(answers?.[qnStoreValues?.[completeNumber]]);
              } else {
                const input = "Please fill the field then only I can go further. ".split("");
                let value = "";
                const interval = setInterval(() => {
                  setWarning(value);
                  value = value + input.shift();
                  if (!input.length) {
                    clearInterval(interval);
                  }
                }, 30);
              }
            }}
          >
            <h6 className="flex text-xl self-center text-white">
              {textInput} <div className="custom-input-cursor">|</div>
            </h6>
            {(warning && !submit) && (
              <h6 className="flex text-sm self-center text-red-400">
                {warning} <div className="custom-input-cursor">|</div>
              </h6>
            )}
            {completeNumber - 1 >= 0 && (
              <label className="hidden justify-between transition ease-in-out delay-150 items-center" id="giveValues">
                {qnStoreValues?.[completeNumber - 1] === "website" && <div className="text-white pt-[5px] me-1">https:// </div>}{" "}
                <input
                  type={qnStoreValues?.[completeNumber - 1] === "email" ? "email" : "text"}
                  className="assitant-input-box"
                  name={qnStoreValues?.[completeNumber - 1]}
                  value={currentQn || ""}
                  onChange={(e) => {
                    setWarning("");
                    setAnswers({ ...answers, [e.target.name]: e.target.value });
                    setCurrentQn(e.target.value);
                  }}
                />
              </label>
            )}
            <div className="w-full flex justify-end">
              {/* {completeNumber > 1 ? (
                <button
                  className="self-end mt-10 text-white bg-[#054646]"
                  type="button"
                  onClick={() => {
                    setText("");
                    if (completeNumber > 1) {
                      setCompleteNumber(completeNumber - 1);
                    }
                    setCurrentQn(answers?.[qnStoreValues?.[completeNumber - 2]])
                  }}
                >
                 <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="me-2" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" /> </svg>

                  back
                </button>
              ) : (
                <div></div>
              )} */}
              <button className="self-end mt-10 text-white bg-[#054646]" type="submit">
                {completeNumber === qnStoreValues?.length - 1 ? "Submit" : "Next"}

                <svg xmlns="http://www.w3.org/2000/svg" className="ms-2" width={16} height={16} fill="white" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                  {"{"}" "{"}"}
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      {submit && (warning ? <div className="fixed z-[999] bg-[#00000081] h-screen w-screen top-0 left-0 flex flex-col items-center justify-center">
        
              <div className="flex flex-col md:w-1/2 p-4 w-full">

        <h3 className={warning?.split("")[0]==="C" ? "text-green-500 flex" :"text-red-500 flex"}>{warning}<div className="custom-input-cursor">|</div></h3>
        <button className="self-end mt-10 text-white bg-[#054646]" onClick={()=>{
          if(warning?.split("")[0]==="C"){
            window.location.href = "/login"
          }else{
            window.location.reload()
          }
        }}>
              {warning?.split("")[0]==="C" ? "Start Using Necttos":"Resubmit"}
              </button>
            </div>

    </div> :<Loader loading={false}/>)}
    </div>
  );
}

const VALIDATION_SCHEMA = Yup.object({
  college: Yup.string().trim().required("Please Select The College"),
  email: Yup.string().matches(EMAIL_REGEX, "Enter Valid Email Id").required("Enter Email Id"),
  principalName: Yup.string().trim().required("Enter Principal Name"),
  address: Yup.string().trim().required("Enter Address"),
  phoneNumber: Yup.string().matches(MOBILE_REGEX, "Enter Valid Mobile").required("Enter Mobile Number"),
  website: Yup.string().trim().required("Enter Website"),
  district: Yup.string().trim().required("Enter District"),
  pincode: Yup.string().matches(PIN_REGEX, "Enter Valid PIN").required("Enter PIN Code"),
  logoFile: Yup.mixed().required("A file is required"),
});

export default SignUpCollege;
