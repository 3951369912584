import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select, Table } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import EditBook from './EditBook';
import ModalWrapper from '../../../components/ModalWrapper';
const getFieldDropDowns = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/library/getOpac', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data?.opacData[0];
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const Master = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});
	const componentRef = useRef();
	const [loading, setLoading] = useState(false);

	let [items, setItems] = React.useState({
		addBook: false,
	});

	const { data = [], refetch } = useQuery(['opacData', state.searchType, state.searchValue, state.page], getFieldDropDowns({ collegeId, searchType: state.searchType, searchValue: state.searchValue, skip: state.page * 50, setLoading }), { keepPreviousData: true });
	const { Option } = Select;

	let filterTypeDrops = ['All', 'Title', 'Author', 'Subjects', 'Call No', 'Accession No', 'Contents'];

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<>
						<div className={classNames.listContainer2}>
							<div className={classNames.headerPanel}>
								<div className={classNames.paginationController}>
									<Button
										className={classNames.reloadButton}
										disabled={parseInt(state.page) < 1}
										onClick={() => {
											setState({ ...state, page: parseInt(state.page) - 1 });
										}}>
										Prev
									</Button>
									<Formik enableReinitialize>
										<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
											<Field
												type='number'
												name='year'
												id='year'
												value={state.page + 1}
												onChange={(e) => {
													setState({ ...state, page: e.target.value });
												}}
											/>
											<label>Page</label>
										</div>
									</Formik>
									<Button
										className={classNames.reloadButton}
										disabled={parseInt(state.page) * 50 > parseInt(data?.total?.[0]?.total) - 50}
										onClick={() => {
											setState({ ...state, page: parseInt(state.page) + 1 });
										}}>
										Next
									</Button>
									{loading ? (
										<LoadingAnimation customHeight={50} customWidth={50} />
									) : (
										<>
											<span className={classNames.heading}>
												Showing Result : {parseInt(state.page) * 50 + 1} - {parseInt(state.page) * 50 + 50} out of {data?.total?.[0]?.total}{' '}
											</span>
										</>
									)}
								</div>
							</div>
							<div style={{ height: '90%' }}>
								<div ref={componentRef} className='printable'>
									<div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
										<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '4%' }}>Sn</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '40%' }}>Title</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Author</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '4%' }}>Ed</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Publisher</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Call No</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '10%' }}>Acc No</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '10%' }}>Status</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '10%' }}>Edit Button</h5>
										</div>

										<div
											style={{
												height: '600px',
												width: '100%',
												position: 'relative',
											}}>
											<Scrollbars>
												{data?.data?.map((item, i) => (
													<>
														<div style={{ width: '100%', display: 'flex', height: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.callNo === '' ? '#ff8f87' : item.status === 'available' ? 'white' : '#ff8f87' }}>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '4%' }}>{parseInt(state.page) * 50 + 1 + i}</h5>
															<img style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '5%', height: '100%', backgroundColor: '#012536', borderRadius: 10 }} src={item?.image} />
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '40%' }}>{item.nameOfBook}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.nameOfAuthor}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '4%' }}>{item.edition}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.nameOfPublisher}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.callNo}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '10%' }}>{item.accessionNumber}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '10%' }}>{item.status === 'unAvailable' ? 'Unavailable' : item.status === 'available' ? 'Available' : item.status}</h5>
															<Button
																className={classNames.reloadButton}
																onClick={() => {
																	setItems({ ...items, addBook: true, stockId: item._id });
																}}>
																Edit
															</Button>
														</div>
														<div
															style={{
																width: '100%',
																display: 'flex',
																height: 1,
																backgroundColor: 'black',
															}}></div>
													</>
												))}
											</Scrollbars>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '30%' }}>
						<Select
							showSearch
							className={classNames.formFullWidth}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, searchType: val });
							}}>
							{filterTypeDrops?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
						<label>Filter Type</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '60%' }}>
						<Field
							type='text'
							style={{ width: '100%' }}
							name='year'
							id='year'
							value={state.searchValue}
							onChange={(e) => {
								setState({ ...state, searchValue: e.target.value });
							}}
						/>
						<label>Search by {state?.searchType ? state?.searchType : 'All'}</label>
					</div>
				</Formik>

				<Button className={classNames.reloadButton} onClick={() => refetch()}>
					Reload
				</Button>

				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
			</div>

			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>

			{items.addBook && (
				<ModalWrapper>
					{' '}
					<EditBook
						onCloseModal={() => setItems({ ...items, addBook: false })}
						refectOrgin={refetch}
						stockId={items.stockId}
						onSuccess={() => {
							setItems({ ...items, addBook: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
		</div>
	);
};
