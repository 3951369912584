import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { v4 as uuid } from 'uuid';
import { EditDetails } from '../../NecttosComp/APICaller/ApiServices';

function Document({ onClose }) {
	const [profileData, setProfileData] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [changes, setChanges] = useState(true);

	const { user, collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	// Function to upload file
	const uploadFile = async (file) => {
		const storage = getStorage();
		const fileRef = ref(storage, `files/${uuid()}/${file.name}`);
		await uploadBytes(fileRef, file);
		return getDownloadURL(fileRef);
	};

	// Function to handle adding new document
	const handleAddNewDocument = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const url = await uploadFile(file);
			setProfileData((prevState) => ({
				...prevState,
				documents: [...prevState.documents, { url }],
			}));
		}
	};

	// Function to handle document update
	const handleDocumentUpdate = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
			const url = await uploadFile(file);
			let updatedDocuments = [...profileData.documents];
			updatedDocuments[index] = { url };
			setProfileData({ ...profileData, documents: updatedDocuments });
		}
	};

	// Function to delete a document
	const deleteDocument = (index) => {
		let updatedDocuments = [...profileData.documents];
		const fileRef = ref(getStorage(), updatedDocuments[index].url);
		deleteObject(fileRef)
			.then(() => {
				updatedDocuments.splice(index, 1);
				setProfileData({ ...profileData, documents: updatedDocuments });
			})
			.catch((error) => {
				console.error('Error deleting file:', error);
			});
	};

	// Fetch user data
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const instance = await getAxiosTokenInstance();
				const { data } = await instance.get('/college/profile/get', {
					params: { profileId: user?.uid, groupData: true, isStaff: false, collegeId, isGrand: false },
				});
				setProfileData({ documents: data?.profileData?.documents });
				const keys = ['url'];
				for (let i in data?.profileData?.documents) {
					const elem = data?.profileData?.documents[i];
					const pendingdata = data?.profileData?.pendingData?.documents[i];
					for (let j of keys) {
						if (elem[j] !== pendingdata[j]) {
							setChanges({ ...changes, elem });
						}
					}
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		if (user?.uid) {
			fetchUserData();
		}
	}, [user, collegeId]);

	console.log(profileData);

	return (
		<div className='bg-white overflow-auto shadow-lg grid grid-flow-col rounded-lg h-[90vh] w-[70vw] max-w-4xl'>
			<div className='px-5 py-4'>
				<button onClick={onClose} className='float-right text-lg'>
					✖
				</button>
				<h3 className='text-xl font-bold mb-4'>Documents</h3>
				{isEditing && <input type='file' onChange={handleAddNewDocument} className='mb-4' />}
				{profileData?.documents?.map((doc, index) => (
					<div key={index} className='mb-2'>
						<img src={doc?.url} alt={`Document ${index}`} />
						{isEditing && (
							<>
								<input type='file' onChange={(e) => handleDocumentUpdate(e, index)} />
								<button onClick={() => deleteDocument(index)} className='bg-red-500 text-white py-1 px-2 rounded ml-2'>
									Delete
								</button>
							</>
						)}
					</div>
				))}
				<button
					onClick={async () => {
						if (isEditing) {
							await EditDetails(profileData, selectedCollege);
						}
						setIsEditing(!isEditing);
					}}
					className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mt-4'>
					{isEditing ? 'Save' : 'Edit'}
				</button>
			</div>
			{changes?.[0] && (
				<div className='px-5 py-4'>
					<h3 className='text-xl font-bold mb-4'>Updated</h3>
					{changes?.map((x, index) => (
						<>
							<img src={x?.url} alt={`Document ${index}`} />
						</>
					))}
				</div>
			)}
		</div>
	);
}

export default Document;
