import React, { useContext, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import moment from "moment";

const getExaminationDetails = (collegeId: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/examProcess/getExamInit", {
      params: { collegeId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function ExamInit({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const [selectedBatchSem, setSelectedBatchSem] = useState<any>([]);

  const { data: tableData = [], refetch } = useQuery("getExamInit", getExaminationDetails(collegeId));

  const fields = [
    { id: "attendanceClosingDate", label: "Attendance Closing Date" },
    { id: "examNotificationDate", label: "Examination Notification Date" },
    { id: "condonationFeeDate", label: "Condonation with Fee Apply Date" },
    { id: "condonationLateFeeDate", label: "Condonation with Late Fee Apply Date" },
    { id: "examRegistrationStartDate", label: "Examination Registration Start Date" },
    { id: "registrationFineDate", label: "Examination Registration with Fine Date" },
    { id: "registrationSuperFineDate", label: "Examination Registration with Super Fine Date" },
    { id: "timeTableDistributionDate", label: "Time Table Distribution Date" },
    { id: "hallTicketDistributionDate", label: "Hall Ticket Distribution Date" },
  ];

  const addNewForm = async (mode?: any, ddd?: any) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postExamInit", { ...addNew, _id: ddd, mode, userId: user.uid, collegeId, batches: selectedBatchSem })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const handleInputChange = (event: any) => {
    const { id, value } = event.target;
    setAddNew((prevState: any) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    addNewForm();
    console.log("Form Data:", addNew);
  };

  const batch_array: any[] = ["2024-2028:S1", "2024-2026:S1", "2024-2029:S1", "2024-2027:S1"];

  const removeSubject = (subject: any) => {
    setSelectedBatchSem(selectedBatchSem.filter((s: any) => s !== subject));
  };
  const handleSubjectChange = (event: any) => {
    const value = event.target.value;
    if (!selectedBatchSem?.includes(value)) {
      setSelectedBatchSem([...(selectedBatchSem || []), value]);
    }
  };

  return (
    <>
      <Main title="Exam Conducted or Init Details" width="100vw" height="100vw">
        <Header>
          <Button
            type="update"
            onClick={() => {
              setShowForm(true);
            }}
          >
            New Examination Init
          </Button>
          <div className="flex">
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="2230px" innerWidth={["5%", "200px", "230px", "15%", "10%", "10%"]}>
              <Thead>
                <Tr>
                  <Th position={1}>SN</Th>
                  <Th position={1}>Batches</Th>
                  {fields.map((field) => (
                    <Th position={1}>{field.label}</Th>
                  ))}
                  <Th position={2}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {tableData?.map((item: any, i: number) => (
                  <Tr key={item._id}>
                    <Td index={i} position={1}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.batches?.join(", ")}
                    </Td>
                    {fields.map((field) => (
                      <Td key={field.id + "rrr"} index={i} position={1}>
                        {moment(item[field.id]).format("DD-MM-YYYY HH:MM")}
                      </Td>
                    ))}
                    <Td index={i} position={2}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            setAddNew(item);
                            setSelectedBatchSem(item?.batches);
                            setShowForm(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          type="delete"
                          onClick={() => {
                            setAddNew(item);
                            addNewForm("delete", item._id);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main title="Exam Initiation" width="40vw">
          <Header>
            <div></div>
            <div className="flex">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setShowForm(false), 500);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div ref={ref} className="bg-gray-100 p-6 rounded-lg shadow-md max-w-4xl mx-auto my-10">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-4">
                  {selectedBatchSem?.map((subject: any, index: any) => (
                    <div key={index} className="flex justify-between items-center bg-blue-100 p-2 rounded">
                      <span>{subject}</span>
                      <button onClick={() => removeSubject(subject)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                        Remove
                      </button>
                    </div>
                  ))}
                  <div className="mt-4">
                    <label htmlFor="subjects" className="block text-sm font-medium text-gray-700 mb-1">
                      Batch With Semester
                    </label>
                    <select id="subjects" className="form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" onChange={handleSubjectChange} value="">
                      <option value="">Select a Batch with Semester...</option>
                      {batch_array?.map((subject, index) => (
                        <option key={index} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {fields.map((field) => (
                  <div key={field.id} className="flex flex-col">
                    <label htmlFor={field.id} className="mb-1 font-medium">
                      {field.label}:
                    </label>
                    <input type="datetime-local" id={field?.id} name={field?.id} value={addNew?.[field?.id] || ""} onChange={handleInputChange} className="p-2 border rounded" required />
                  </div>
                ))}
                <label htmlFor="discription" className="mb-1 font-medium">
                  Discription
                </label>
                <input type="text" id="discription" name="discription" value={addNew?.discription || ""} onChange={handleInputChange} className="p-2 border rounded" />
                <div className="flex justify-end mt-4">
                  <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default ExamInit;
