import React, { useContext, useState } from 'react';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { FirebaseContext } from '../../context/FirebaseContext';
import CustomDropDownKey from '../../myComp/CustomDropDownKey';
import { AdmissionRoll } from './AdmissionRoll';
import { Body, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';

function Controller({ keyData, onClose }) {
	const { collegeId, collegeList } = useContext(FirebaseContext);

	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const [state, setState] = useState({
		...selectedCollege.verificationWindows,
		giveAdmissionNumberval: 'When ' + selectedCollege.verificationWindows?.giveAdmissionNumber + ' Verification Process Completed',
	});

	const [message, setMessage] = useState(false);

	const handleProceed = async () => {
		try {
			let data = {
				collegeId,
				...state,
			};
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/admissionProcess/controller', data);
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				onClose();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	// const Button = ({ color, text, onClick }) => {
	//   return (
	//     <button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: "white", fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
	//       {text}
	//     </button>
	//   );
	// };

	const handleCancel = () => {
		onClose();
	};

	let admissionNumberCreation = [
		{
			_id: 'principal',
			value: 'When Principal Verification Completed',
		},
		{
			_id: 'nodal',
			value: 'When Nodal Verification Completed',
		},
		{
			_id: 'department',
			value: 'When Department Verification Completed',
		},
		{
			_id: 'office',
			value: 'When Office Verification Completed',
		},
		{
			_id: 'fees',
			value: 'When Goverment Fees Collected',
		},
	];

	return (
		<Main width='100%' height='100%' title='Admission Controller'>
			<h5>Restart Application Will Be Effect this changed controlls</h5>

			<Body>
				<div className='flex flex-wrap flex-grow bg-gray-100 p-3 rounded-lg shadow-md'>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'enquiriesReceived',
									title: 'Enquiries Received',
								});
							}}
						/>
						<Input width='100%' fieldName='Enquiries Received' returnKey='enquiriesReceived' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'applicationsInitiated',
									title: 'Applications Initiated',
								});
							}}
						/>
						<Input width='100%' fieldName='Applications Initiated' returnKey='applicationsInitiated' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'pendingApplicationswithFeesPaid',
									title: 'Pending Applications with Fees Paid',
								});
							}}
						/>
						<Input width='100%' fieldName='Pending Applications with Fees Paid' returnKey='pendingApplicationswithFeesPaid' state={state} setState={setState} type='checkbox' />
					</div>

					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'completedApplications',
									title: 'Completed Applications',
								});
							}}
						/>
						<Input width='100%' fieldName='Completed Applications' returnKey='completedApplications' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'courseWiseRankList',
									title: 'Course Wise Rank List',
								});
							}}
						/>
						<Input width='100%' fieldName='Course Wise Rank List' returnKey='courseWiseRankList' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'allotmentList',
									title: 'AllotmentList',
								});
							}}
						/>
						<Input width='100%' fieldName='Allotment List' returnKey='allotmentList' state={state} setState={setState} type='checkbox' />
					</div>

					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'seatReservationDetails',
									title: 'Seat Reservation Details',
								});
							}}
						/>
						<Input width='100%' fieldName='Seat Reservation Details' returnKey='seatReservationDetails' state={state} setState={setState} type='checkbox' />
					</div>

					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'applicationForm',
									title: 'Application Form',
								});
							}}
						/>
						<Input width='100%' fieldName='Application Form' returnKey='applicationForm' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'departmentVerfication',
									title: 'Department Verification',
								});
							}}
						/>
						<Input width='100%' fieldName='Department Verification' returnKey='departmentVerfication' state={state} setState={setState} type='checkbox' />
					</div>

					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'nodalVerification',
									title: collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' ? 'Department Senior Verification' : 'Nodal Verification',
								});
							}}
						/>
						<Input width='100%' fieldName={collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' ? 'Department Senior Verification' : 'Nodal Verification'} returnKey='nodalVerification' state={state} setState={setState} type='checkbox' />
					</div>

					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'collegeFundCollection',
									title: 'PTA Fund Collection',
								});
							}}
						/>
						<Input width='100%' fieldName='PTA Fund Collection' returnKey='collegeFundCollection' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'principalVerification',
									title: 'Principal Verification',
								});
							}}
						/>
						<Input width='100%' fieldName='Principal Verification' returnKey='principalVerification' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'officeVerification',
									title: 'Office Verification',
								});
							}}
						/>
						<Input width='100%' fieldName='Office Verification' returnKey='officeVerification' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'grandVerification',
									title: 'Grand Verification',
								});
							}}
						/>
						<Input width='100%' fieldName='Grand Verification' returnKey='grandVerification' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'govermentFeeCollection',
									title: 'Government Fees Collection',
								});
							}}
						/>

						<Input width='100%' fieldName='Government Fees Collection' returnKey='govermentFeeCollection' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-1/5 bg-white  flex-grow p-3 mb-4 rounded-lg shadow-sm m-3'>
						<Button
							type='fetch'
							children='Assign Role'
							onClick={() => {
								setState({
									...state,
									isModalOpen: true,
									keyData: 'universityUploads',
									title: 'University Uploads Verification',
								});
							}}
						/>
						<Input width='100%' fieldName='University Uploads Verification' returnKey='universityUploads' state={state} setState={setState} type='checkbox' />
					</div>
					<div className='w-full bg-white p-5 mb-4 rounded-lg shadow-sm'>
						<CustomDropDownKey keyData={'giveAdmissionNumber'} keyObject={'value'} keyReturn={'_id'} titleData={'When admission number is provided, select from the dropdown menu.'} dropValues={admissionNumberCreation} stateData={state} setStateData={setState} />
					</div>
					<div className='flex flex-row w-full m-5'>
						<Button color='#FF4136' children='Cancel' onClick={handleCancel} />
						<Button color='#2ECC40' children='Save' onClick={handleProceed} />
					</div>
				</div>
			</Body>
			{state.isModalOpen && <AdmissionRoll role={state?.keyData} title={state.title} onClose={() => setState({ ...state, isModalOpen: false })} />}
		</Main>
	);
}

export default Controller;
