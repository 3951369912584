import React, { useContext, useEffect, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import PasteFromWord from "./PastArear";

const getFieldDropDowns = (subjectId: any) => async () => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/examProcess/getSubjectPoits", {
      params: { subjectId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.points;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

const getAllReadyQuestion = (subjectId: string, module: string, topic: string, collegeId: string) => async () => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/examProcess/getAllReadyQuestions", {
      params: { subjectId, module, topic, collegeId },
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.data;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function QuestionBankCreator({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [addQ, setAddQ] = useState("<p><!-- START --></p><p><!-- END --></p>");
  const [showForm, setShowForm] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const { data: dropSubjectPoints = [] } = useQuery(["getFdieldDropDowns", addNew?.subjectId?._id], getFieldDropDowns(addNew?.subjectId?._id));
  const { data: givenQuestions = [], refetch } = useQuery(["getModudles", addNew?.subjectId?._id, addNew?.module?.name, addNew?.topic], getAllReadyQuestion(addNew?.subjectId?._id, addNew?.module?.name, addNew?.topic, collegeId));
 
  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postQuestionBank", {
          level: addNew.level,
          subjectId: addNew.subjectId?._id,
          question: addNew?.type === "Theory" ? addNew.question : addQ,
          mark: addNew?.mark,
          module: addNew?.module?.name,
          taxonomy: addNew?.taxonomy,
          topic: addNew?.topic,
          co: addNew?.co,
          type: addNew?.type,
          important: addNew.important,
          difficulty: addNew.difficulty,
          collegeId,
        })
        .then(() => {
          setAddNew({
            ...addNew,
            level: "",
            question: "",
            mark: "",
            taxonomy: "",
            co: "",
            type: "",
            important: "",
            difficulty: "",
          });
          refetch();
          setShowForm(false);
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };
  const [data, setData] = useState([]);
  const getData = async () => {
    const instance = await getAxiosTokenInstance();
    const data = await instance.get("/college/subject/getSubjectByFilter", {
      params: { collegeId },
    });
    setData(data?.data?.finalArray);
  };
  useEffect(() => {
    getData();
  }, []);
  const taxonomyData = [
    { key: "Creating", value: 41, color: "bg-red-500" },
    { key: "Evaluating", value: 5, color: "bg-orange-500" },
    { key: "Analysing", value: 6, color: "bg-yellow-500" },
    { key: "Applying", value: 9, color: "bg-green-500" },
    { key: "Understanding", value: 11, color: "bg-teal-500" },
    { key: "Remembering", value: 1, color: "bg-indigo-500" },
    { key: "Challenging", value: 2, color: "bg-purple-500" },
    { key: "Medium", value: 4, color: "bg-pink-500" },
    { key: "Easy", value: 5, color: "bg-gray-500" },
    { key: "VeryImportant", value: 4, color: "bg-red-700" },
    { key: "Important", value: 2, color: "bg-red-400" },
    { key: "Normal", value: 34, color: "bg-red-200" },
    { key: "Theory", value: 2, color: "bg-green-700" },
    { key: "Application", value: 3, color: "bg-green-400" },
  ];

  return (
    <>
      <Main title="Question Bank Creator" width="100%" height="100%">
        <Header>
          <div className="flex m-2">
            <div className="flex self-end">
              <Button type="close" onClick={onClose}>
                Close
              </Button>
            </div>
          </div>
        </Header>
        <Body>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-4">
                <div className="bg-white p-4 rounded-lg shadow-lg h-full overflow-auto">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">Syllabus</h3>
                  <ul className="space-y-4">
                    {dropSubjectPoints?.map((x: any, i: number) => (
                      <>
                        <li key={x.id} className="text-green-700 font-serif text-xl p-2 rounded-md ">
                          {x?.name}
                        </li>
                        <ul className="space-y-0">
                          {x.points.map((xx: any, ii: any) => (
                            <li key={ii} className="text-black font-serif text-sm p-2 rounded-md ">
                              {xx}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="w-full lg:w-1/3 px-4">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">Add New Question</h3>
                  <div className="space-y-1">
                    <Input width="100%" fieldName="Choose Subject" returnKey="subjectId" optionDisplay="name" valueShowKey="name" state={addNew} setState={setAddNew} options={data} type="drop" />
                    <Input width="100%" fieldName="Choose Module" returnKey="module" optionDisplay="name" valueShowKey="name" state={addNew} setState={setAddNew} options={dropSubjectPoints} type="drop" />
                    <Input width="100%" fieldName="Connect Unit" returnKey="topic" state={addNew} setState={setAddNew} options={addNew?.module?.points || []} type="drop" />
                    <Input width="100%" fieldName="Type" returnKey="type" state={addNew} setState={setAddNew} options={["Theory", "Application"]} type="drop" />
                    {addNew?.type === "Application" ? <PasteFromWord setState={setAddQ} state={addQ} /> : <Input width="100%" fieldName="Question" returnKey="question" state={addNew} setState={setAddNew} type="text" />}
                    <Input width="100%" fieldName="Mark" returnKey="mark" state={addNew} setState={setAddNew} options={["1", "2", "3", "4", "5", "6", "8", "10", "12", "15", "20", "25"]} type="drop" />
                    <Input width="100%" fieldName="Taxonomy" returnKey="taxonomy" state={addNew} setState={setAddNew} options={["Creating", "Evaluating", "Analysing", "Applying", "Understanding", "Remembering"]} type="drop" />
                    <Input width="100%" fieldName="Difficulty" returnKey="difficulty" state={addNew} setState={setAddNew} options={["Challenging", "Medium", "Easy"]} type="drop" />
                    <Input width="100%" fieldName="Important" returnKey="important" state={addNew} setState={setAddNew} options={["Very Important", "Important", "Normal"]} type="drop" />
                    <Input width="100%" fieldName="CO to which the Q belong" returnKey="co" state={addNew} setState={setAddNew} options={["CO1", "CO2", "CO3", "CO3", "CO4", "CO5", "CO6", "CO7", "CO8"]} type="drop" />
                    <Button
                      type="save"
                      onClick={() => {
                        setShowForm(true);
                      }}
                    >
                      Finalise
                    </Button>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-4 mb-4 lg:mb-0">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">Questions</h3>
                  <ul className="space-y-2">
                    {givenQuestions?.map(({ id, question, difficulty, mark, taxonomy, important, type, co }: any) => (
                      <li key={id} className="text-black font-serif text-lg p-2 rounded-md">
                        <>{type === "Theory" ? <div className="mb-2">{question}</div> : <div dangerouslySetInnerHTML={{ __html: question }}></div>}</>
                        <div className="text-sm">
                          <p>
                            <strong>Difficulty:</strong> {difficulty}
                          </p>
                          <p>
                            <strong>Mark:</strong> {mark}
                          </p>
                          <p>
                            <strong>Taxonomy:</strong> {taxonomy}
                          </p>
                          <p>
                            <strong>Important:</strong> {important ? "Yes" : "No"}
                          </p>
                          <p>
                            <strong>Type:</strong> {type}
                          </p>
                          <p>
                            <strong>Co:</strong> {co}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="bg-white p-4 rounded-lg shadow-lg"></div>
            </div>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main width="40vw">
          <Header>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 500);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>
            <div className="flex flex-col w-full max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Review Your Details</h3>

              <div className="mb-2">
                <span className="font-medium">Subject:</span> {addNew.subjectId?.name}
              </div>
              <div className="mb-2">
                <span className="font-medium">Question:</span> {addNew?.question}
                <div
                  dangerouslySetInnerHTML={{ __html: addQ }} // Render the external state
                ></div>
              </div>
              <div className="mb-2">
                <span className="font-medium">Mark:</span> {addNew?.mark}
              </div>
              <div className="mb-2">
                <span className="font-medium">Taxonomy:</span> {addNew?.taxonomy}
              </div>
              <div className="mb-4">
                <span className="font-medium">Connected Module:</span> {addNew?.module?.name}
              </div>
              <div className="mb-4">
                <span className="font-medium">Connected Topic:</span> {addNew?.topic}
              </div>
              <div className="mb-4">
                <span className="font-medium">Difficulty:</span> {addNew?.difficulty}
              </div>
              <div className="mb-4">
                <span className="font-medium">Important:</span> {addNew?.important}
              </div>
              <div className="mb-4">
                <span className="font-medium">Type:</span> {addNew?.type}
              </div>
              <div className="mb-4">
                <span className="font-medium">CO to which the Q belong:</span> {addNew?.co}
              </div>

              <Button
                type="save"
                onClick={() => {
                  addNewForm();
                }}
              >
                Confirm & Save
              </Button>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default QuestionBankCreator;
