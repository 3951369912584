import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getProfile } from '../../NecttosComp/APICaller/queryHooks';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getAllMinor } from '../../NecttosComp/APICaller/ApiServices';
import Input from '../../NecttosComp/Input/Input';
import { getCollegeDetails } from './Register';
import { SectionHeader, getClassName, plustwo } from './Component';
import 'react-circular-progressbar/dist/styles.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import DropableComponents from './DropableComponents';
import Button from '../../NecttosComp/Button/Button';
import Cookies from 'js-cookie';

interface ApplicationOpenProps {
	handleLogout?: () => void;
	type?: any;
	userId?: string;
	viewMode?: boolean;
	managedBy?: string;
	paramsRecieved?: any;
}
interface Amount {
	amount: number;
	currencyCode: string;
}

const FYUGP: React.FC<ApplicationOpenProps> = ({ userId, viewMode }) => {
	const { collegeId } = useContext(FirebaseContext);

	const [dropDowns, setDropDowns] = useState<any[]>([{}]);

	const [values, setValues] = useState<any>({});

	const { data, refetch } = useQuery([`profileData`], getProfile);

	useEffect(() => {
		const classRole = data?.collegeRoles?.find((x: any) => x.type === 'student');
		const classId = classRole ? classRole?.classId : null;
		setValues({
			...data,
			classId: classId,
		});
	}, [data]);

	const saveData = useCallback(async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/profile/autoUpdateProfile', values);
			if (resp?.data?.statusCode === 400) {
			} else {
				return resp?.data;
			}
		} catch (error) {}
	}, [values]);

	const { data: classes } = useQuery([`collegeData`, collegeId], getCollegeDetails);

	const componentRef2 = useRef<any>();

	const { data: minorData = [] } = useQuery('getAttllMinor', getAllMinor('COL-AF7BBD488D81-439A-BC17-53A7FC0DC496'));

	const DSC_B = minorData?.filter((x: any) => x.type === 'DSC_B' && x.semester === 'Semester 1');
	const DSC_C = minorData?.filter((x: any) => x.type === 'DSC_C' && x.semester === 'Semester 1');
	const MDC = minorData?.filter((x: any) => x.type === 'MDC' && x.semester === 'Semester 1');

	const filteredSubjects = useMemo(() => {
		let subjects = DSC_B.find((x: any) => x._id === values?.dsc_b_1);
		let subjects2 = DSC_C.find((x: any) => x._id === values?.dsc_c_1);

		return [values?.subject3Name, values?.subject4Name, values?.subject5Name, values?.subject6Name, values?.subject7Name, subjects?.blockSubject, subjects2?.blockSubject].filter(Boolean).map((subject) => subject.toLowerCase().replace(/\s+/g, ''));
	}, [values?.subject3Name, values?.subject4Name, values?.subject5Name, values?.subject6Name, values?.subject7Name, values?.dsc_b_1, values?.dsc_c_1, DSC_C, DSC_B]);

	const filterMDC = useMemo(() => {
		const filtered = MDC.filter((x: any) => {
			const blockSubject = x?.blockSubject?.toLowerCase().replace(/\s+/g, '');
			console.log({ blockSubject });
			console.log({ filteredSubjects });
			return !filteredSubjects?.includes([undefined, null, '']?.includes(blockSubject) ? 'no Subject' : blockSubject);
		});
		return filtered;
	}, [MDC, filteredSubjects]);
	const handleLogout = () => {
		localStorage.clear();
		Cookies.remove('userToken');
		window.location.reload();
	};
	return (
		<div className='flex justify-center lg:h-[100%] row'>
			<div className='bg-[#013E7F] shadow-lg sticky top-0 z-50 w-full'></div>
			<>
				<div className='flex justify-center row '>
					<div className='flex flex-col  w-full max-w-6xl h-auto bg-white shadow-xl rounded-lg'>
						<div className='w-full p-1 bg-gray-50 '>
							<div className='space-y-6 pt-5'>
								<h2 className='flex justify-between items-center lg:text-xl text-sm font-semibold px-6 py-1 rounded'>Name: {values?.name}</h2>
								<h2 className='flex justify-between items-center lg:text-xl text-sm font-semibold px-6 py-1 rounded'>Major: {getClassName(values?.classId, classes)}</h2>
								<Input disabled={viewMode} width='100%' fieldName='Index Mark *' returnKey='index' state={values} setState={setValues} type='number' />
								<SectionHeader key='d59' title={'Plus Two Subjects'} />
								<>
									<p>
										<abbr title='Obtained Marks'>OM</abbr> = Obtained Marks | <abbr title='Total Marks'>TM</abbr> = Total Marks
									</p>
									<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
										{plustwo?.map((terms: any) => (
											<Input required={terms?.required} disabled={viewMode} width='100%' fieldName={terms?.fieldName + ' Name'} returnKey={terms.returnKey + 'Name'} state={values} setState={setValues} options={terms?.options} type='drop' />
										))}
									</div>
								</>
								<SectionHeader key='d18' title={'Minor 1'} />
								<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
									<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='dsc_b_1' optionDisplay='subjectName' optionKey='_id' options={DSC_B} type='drop' width='100%' />
								</div>
								<SectionHeader key='d59' title={'Minor 2'} />
								<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
									<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='dsc_c_1' optionDisplay='subjectName' optionKey='_id' options={DSC_C} type='drop' width='100%' />
								</div>
								<SectionHeader key='d20' title={'MDC (MDC: Please Drag and Drop to Order Your Preferences)'} />
								<DropableComponents array={filterMDC} setValues={setValues} retunPreFix={'mdc_'} values={values} />
								<SectionHeader key='d19' title={'AEC OL'} />
								<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
									<Input disabled={viewMode} fieldName='AEC OL (Second Language)' setState={setValues} state={values} returnKey='secondLangugae' options={['Malayalam', 'Hindi']} type='drop' width='100%' />
								</div>
							</div>
						</div>
					</div>
					<Button
						type='save'
						onClick={() => {
							saveData();
						}}>
						Save
					</Button>
					<Button
						type='close'
						onClick={() => {
							handleLogout();
						}}>
						Logout
					</Button>
				</div>
			</>
		</div>
	);
};
export default FYUGP;
