import { getAuth, getIdToken } from 'firebase/auth';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getAxiosTokenInstance } from './axiosInstance';
import { useState, useEffect } from 'react';
import { useProfileData } from '../NecttosComp/APICaller/queryHooks';

export const convertToNormalWords = (variableName) => {
	let normalWords = variableName?.replace(/([A-Z])/g, ' $1')?.replace(/^./, function (str) {
		return str.toUpperCase();
	});
	const abbreviationMap = {
		Adm: 'Admission',
		No: 'Number',
		Tc: 'TC',
		Dob: 'DOB',
		Sslc: 'SSLC',
		PlusTwo: 'Plus Two',
		Pen: 'PEN',
		Cap: 'CAP',
		Panchayat: 'Panchayat',
		Municipality: 'Municipality',
		Corporation: 'Corporation',
		District: 'District',
		Nationality: 'Nationality',
		Pin: 'PIN',
		Id: 'ID',
		House: 'House',
		State: 'State',
		Village: 'Village',
		Place: 'Place',
		Post: 'Post',
		Office: 'Office',
		Degree: 'Degree',
		Course: 'Course',
		Percentage: 'Percentage',
		Register: 'Register',
		Specialisation: 'Specialisation',
		University: 'University',
		Year: 'Year',
		Pass: 'Pass',
		Board: 'Board',
		Chance: 'Chance',
		Stream: 'Stream',
		Blood: 'Blood',
		Group: 'Group',
		Gender: 'Gender',
		Disabilities: 'Disabilities',
		Aadhaar: 'Aadhaar',
		Annual: 'Annual',
		Income: 'Income',
		Proverty: 'Poverty',
		Line: 'Line',
		Caste: 'Caste',
		Category: 'Category',
		Merital: 'Marital',
		Status: 'Status',
		Religion: 'Religion',
		Phone: 'Phone',
		Batch: 'Batch',
		Name: 'Name',
		Class: 'Class',
		Date: 'Date',
		Of: 'of',
		Join: 'Join',
		Roll: 'Roll',
		Short: 'Short',
		Index: 'Index',
		Last: 'Last',
		School: 'School',
		Second: 'Second',
		Language: 'Language',
		Stay: 'Stay',
		Travel: 'Travel',
		Details: 'Details',
		Designation: 'Designation',
		Type: 'Type',
		Email: 'Email',
		eGrands: 'E Grands Category',
	};
	normalWords = normalWords
		?.split(' ')
		?.map((word) => abbreviationMap[word] || word)
		.join(' ');

	return normalWords;
};

export const getIdTokens = async () => {
	const auth = getAuth();
	let idToken = '';
	idToken = await getIdToken(auth.currentUser);
	return idToken;
};

export const getCurrentUser = async () => {
	const auth = getAuth();
	let currentUser = '';
	currentUser = await auth.currentUser;
	return currentUser;
};

export const usePersistentState = (key, initialValue) => {
	const [state, setState] = useState(() => {
		const storedValue = localStorage.getItem(key);
		return storedValue !== null ? JSON.parse(storedValue) : initialValue;
	});
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
};

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const getColleges = async () => {
	try {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.get('/college/users/getByUser');
		return data;
	} catch (err) {
		throw new Error();
	}
};

export const useMyColleges = () => {
	try {
		const query = useQuery('myColleges', getColleges);
		return query;
	} catch (err) {
		throw new Error();
	}
};

export const getFormattedDateWithMonth = (date) => {
	if (date) return moment(date).format('DD MMMM YYYY');
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export const validateRole = (roles, profileRoles = [], collegeId) => {
	if (!roles) {
		return true;
	}

	let isValid = false;

	profileRoles.every((item) => {
		if (item.collegeId !== collegeId) {
			return true;
		}

		if (roles.includes(item.type)) {
			isValid = true;
			return false;
		}
		return true;
	});

	return isValid;
};

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

export const useRoles = () => {
	const { data } = useProfileData();
	const roles = [];
	for (let i in data?.collegeRoles) {
		const val = data?.collegeRoles[i];
		if (!roles.includes(val?.type)) {
			roles.push(val?.type);
		}
	}

	return roles;
};

export function sumWithKey(data, key) {
	if (!Array.isArray(data) || !key) {
		throw new Error('Invalid input');
	}

	return data.reduce((sum, item) => {
		const value = item[key];
		if (typeof value !== 'number') {
			throw new Error('Non-numeric value encountered');
		}
		return sum + value;
	}, 0);
}

export const CheckboxGroup = ({ title, data, stateData, setStateData, prefix }) => {
	const handleCheckboxChange = (categoryKey) => {
		const key = prefix + (categoryKey?._id || categoryKey);
		setStateData({
			...stateData,
			[key]: !stateData[key],
		});
	};

	const formatLabel = (categoryKey) => {
		// Check if categoryKey is a string and contains information
		if (typeof categoryKey === 'string') {
			// Attempt to split the key by colon ":" to separate the identifier from the title
			let parts = categoryKey.split(':');
			if (parts.length > 1) {
				// If there's a title part, use the first part as the identifier and the beginning of the title for the label
				let identifier = parts[0].trim();
				let titleStart = parts[1].split(' ')[1]; // Attempt to get the first word of the title
				return `${identifier}: ${titleStart}...`; // Combine for a concise label
			} else {
				// If no colon is found, just return the beginning of the string
				return categoryKey.length > 30 ? categoryKey.substring(0, 27) + '...' : categoryKey;
			}
		}
		// Fallback if the categoryKey is not a string or doesn't match expected format
		return categoryKey._id || 'Unknown';
	};

	return (
		<div className='border-2 border-indigo-300 rounded-lg p-5 m-3 bg-indigo-50 shadow-xl'>
			<h2 className='text-2xl font-bold text-indigo-600 mb-5'>{title}</h2>
			<div className='flex flex-wrap justify-around'>
				{data?.map((item, index) => (
					<label key={index} className='flex items-center mb-2 cursor-pointer'>
						<input type='checkbox' className='form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400' checked={stateData[prefix + item]} onChange={() => handleCheckboxChange(item)} />
						<span className='text-lg text-gray-800 ml-2'>{item}</span>
					</label>
				))}
			</div>
		</div>
	);
};

export const lightBackgroundColors = [
	'#f0f8ff', // AliceBlue
	'#faebd7', // AntiqueWhite
	'#f5f5dc', // Beige
	'#ffe4c4', // Bisque
	'#f0ffff', // Azure
	'#f5f5f5', // WhiteSmoke
	'#fffaf0', // FloralWhite
	'#f0fff0', // HoneyDew
	'#fdf5e6', // OldLace
	'#fffff0', // Ivory
	'#f5fffa', // MintCream
	'#fff5ee', // Seashell
	'#f0e68c', // Khaki
	'#e6e6fa', // Lavender
	'#fff0f5', // LavenderBlush
	'#faf0e6', // Linen
	'#fafad2', // LightGoldenRodYellow
	'#d3d3d3', // LightGray
	'#e0ffff', // LightCyan
	'#f0fff0', // Honeydew
	'#e6e6fa', // Lavender
	'#fffacd', // LemonChiffon
	'#f5deb3', // Wheat
	'#ffefd5', // PapayaWhip
	'#ffebcd', // BlanchedAlmond
	'#fff8dc', // Cornsilk
	'#f8f8ff', // GhostWhite
	'#fff5ee', // Seashell
	'#f5f5f5', // WhiteSmoke
	'#fefefe', // Very light gray (almost white)
];
export const darkBackgroundColors = [
	'#d0d8df', // Darkened AliceBlue
	'#dabdb7', // Darkened AntiqueWhite
	'#d5d5bc', // Darkened Beige
	'#d0c4a4', // Darkened Bisque
	'#d0dfdf', // Darkened Azure
	'#d5d5d5', // Darkened WhiteSmoke
	'#dfdfd0', // Darkened FloralWhite
	'#d0dfd0', // Darkened HoneyDew
	'#dfd5c6', // Darkened OldLace
	'#dedecc', // Darkened Ivory
	'#d5dfda', // Darkened MintCream
	'#dfd5ce', // Darkened Seashell
	'#d0c66c', // Darkened Khaki
	'#c6c6da', // Darkened Lavender
	'#dfc0d5', // Darkened LavenderBlush
	'#d0d0c6', // Darkened Linen
	'#dfd0b2', // Darkened LightGoldenRodYellow
	'#b3b3b3', // Darkened LightGray
	'#c0dfdf', // Darkened LightCyan
	'#d0dfd0', // Darkened Honeydew
	'#c6c6da', // Darkened Lavender
	'#dfd2ad', // Darkened LemonChiffon
	'#d5b493', // Darkened Wheat
	'#dfcdb5', // Darkened PapayaWhip
	'#dfcdbd', // Darkened BlanchedAlmond
	'#dfd8bc', // Darkened Cornsilk
	'#d8d8df', // Darkened GhostWhite
	'#dfd2ce', // Darkened Seashell
	'#d5d5d5', // Darkened WhiteSmoke
	'#dedede', // Darkened Very light gray (almost white)
];
export const mediumBackgroundColors = [
	'#404850', // Lightened AliceBlue
	'#5a4b47', // Lightened AntiqueWhite
	'#55554c', // Lightened Beige
	'#503c24', // Lightened Bisque
	'#404f4f', // Lightened Azure
	'#555555', // Lightened WhiteSmoke
	'#4f4f40', // Lightened FloralWhite
	'#405040', // Lightened HoneyDew
	'#4f4536', // Lightened OldLace
	'#4e4e3c', // Lightened Ivory
	'#555f5a', // Lightened MintCream
	'#4f453e', // Lightened Seashell
	'#50466c', // Lightened Khaki
	'#46466a', // Lightened Lavender
	'#4f3055', // Lightened LavenderBlush
	'#505046', // Lightened Linen
	'#4f4f32', // Lightened LightGoldenRodYellow
	'#333333', // Lightened LightGray
	'#405f5f', // Lightened LightCyan
	'#405040', // Lightened Honeydew
	'#46466a', // Lightened Lavender
	'#4f3d4d', // Lightened LemonChiffon
	'#554333', // Lightened Wheat
	'#4f3d35', // Lightened PapayaWhip
	'#4f3d3d', // Lightened BlanchedAlmond
	'#4f483c', // Lightened Cornsilk
	'#48484f', // Lightened GhostWhite
	'#4f3d3e', // Lightened Seashell
	'#555555', // Lightened WhiteSmoke
	'#4e4e4e', // Lightened Very light gray (almost black)
];
