import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { AcademicYears, naacExcelGenerator, naacFetchData } from '../../NecttosComp/APICaller/ApiServices';

function C3_4_2({ val, onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(true);
	const [academicYear, setAcademicYear] = useState('2022-23');

	useEffect(() => {
		naacFetchData({
			data: { collegeId, academicYear },
			setLoading,
			setValues,
			url: '/college/NAAC/getNAAC_C3_4_2',
		});
	}, []);
	return (
		<>
			<div className='  z-10 w-4/5 bg-slate-100 h-4/5 p-3'>
				<table className='table'>
					<thead>
						<th colSpan={4} style={{ background: 'rgb(241 245 249)' }}>
							<h1 className='text-xl flex'>
								3.4.2 Number of awards and recognitions received for extension activities from government/ government recognized bodies during the year
								<div className='flex'>
									<AcademicYears setAcademicYear={setAcademicYear} />
									<button
										className='bg-green-500 text-sm'
										onClick={() =>
											naacExcelGenerator({
												data: { collegeId, academicYear: '2022-23' },
												url: '/college/NAAC/getNAAC_C2_7',
												setLoading,
											})
										}>
										Dowload
									</button>
									<button className='closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]' onClick={onClose}>
										X
									</button>
								</div>
							</h1>
						</th>
						<tr>
							<th className='w-1/4'>Name of the activity</th>
							<th className='w-1/4'>Name of the Award/ recognition </th>
							<th className='w-1/4'>Name of the Awarding government/ government recognised bodies</th>
							<th className='w-1/4'>Year of award </th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>2</td>
							<td>2</td>
							<td>2</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
}

export default C3_4_2;
