import React, { useContext, useState } from 'react';
import { Select } from 'antd';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import Alert from 'react-popup-alert';
import { useAccountsHeads } from '../../NecttosComp/APICaller/queryHooks';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { Body, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getReports } from '../../NecttosComp/APICaller/ApiServices';
export const exportPdfCollectionReport = async ({ posTdata, setLoading, setAlert }) => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, posTdata, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (e) {
		throw new Error(
			setLoading(false),
			setAlert({
				type: 'Message',
				text: 'Chack Date / Time / Main account / Sub account' + e,
				show: true,
			}),
		);
	}
};

export const CollectionReports = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = React.useState({
		startDate: '',
		endDate: '',
		startDate: '',
		endTime: '',
		mainAccount: '',
		subAccount: '',
	});
	const [alert, setAlert] = React.useState({
		type: 'error',
		text: 'This is a alert message',
		show: false,
	});
	const accounts = useAccountsHeads();

	const onCloseAlert = () => {
		setAlert({
			type: '',
			text: '',
			show: false,
		});
	};
	let quaryData = {
		startDate: state?.startDate,
		endDate: state?.endDate,
		startTime: state?.startTime,
		endTime: state?.endTime,
		mainAccount: state?.mainAccount || '',
		subAccount: state?.subAccount || '',
		method: state?.method === 'All' ? '' : state?.method || '',
		isHideBills: state?.check ? '$eq' : '$ne',
		collegeId,
	};

	const { data = [], isLoading, refetch } = useQuery(['getReports', state?.startDate, state?.endDate, state?.startTime, state?.endTime, state?.mainAccount, state?.subAccount, state?.method, state.check], getReports(quaryData));
	const [loading, setLoading] = useState(false);

	let subAccount =
		[
			{
				label: 'All Accounts',
				value: undefined,
			},
			...(accounts?.data?.subAccounts || []),
		] || [];
	let mainAccount =
		[
			{
				label: 'All Accounts',
				value: undefined,
			},
			...(accounts?.data?.mainAccounts || []),
		] || [];

	if (state?.mainAccount) {
		subAccount = subAccount.filter((x) => x.mainId === state?.mainAccount);
	}
	const { Option } = Select;

	console.log({ data });

	subAccount = subAccount.concat({ label: 'All', value: '' });
	const [searchTerm, setSearchTerm] = useState('');
	const filteredData = data?.collections?.filter((item) => item.name?.toLowerCase().includes(searchTerm.toLowerCase()) || item.billNo?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || item.admisionNo?.toString().toLowerCase().includes(searchTerm.toLowerCase())) || [];

	const { tableItems, requestSort, getIcon } = useSortableData(filteredData);
	return (
		<Main width='100%' height='100%' title='CollectionReports'>
			<div className='flex flex-col h-full overflow-hidden'>
				<div className='flex items-center justify-between'>
					<div className='flex flex-col'>
						<label className='font-bold mb-2'>Search Student by Admn No, Name, or Bill No</label>
						<div className='relative'>
							<input style={{ width: '350px', height: '30px', paddingLeft: '32px' }} type='text' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
							<div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
								<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' className='w-4 h-4 text-gray-500'>
									<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-4.35-4.35m2.8-5.65A7.5 7.5 0 1112 4.5a7.5 7.5 0 017.5 7.5z' />
								</svg>
							</div>
						</div>
					</div>
					<div className='flex flex-row gap-2'>
						<Button
							type='excel'
							width='100%'
							onClick={() => {
								refetch();
							}}>
							Excel
						</Button>
						{loading ? <LoadingAnimation dark /> : null}
						<Button width='100%' type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</div>

				<div className='flex flex-row gap-4'>
					<div className='flex flex-col'>
						<label className='font-bold'>Start Date</label>
						<input className='w-[200px] h-8' type='date' name='startDate' id='startDate' value={state.startDate} onChange={(e) => setState({ ...state, startDate: e.target.value })} />
					</div>
					<div className='flex flex-col'>
						<label className='font-bold'>End Date</label>
						<input className='w-[200px] h-8' type='date' name='endDate' id='endDate' value={state.endDate} onChange={(e) => setState({ ...state, endDate: e.target.value })} />
					</div>
					<div className='flex flex-col'>
						<label className='font-bold'>Main Account</label>
						<Select showSearch className='w-[200px] h-10' onChange={(val) => setState({ ...state, mainAccount: mainAccount?.find((x) => x.label === val).value })}>
							{mainAccount?.map((item) => (
								<Option key={item.label} value={item.label}>
									{item.label}
								</Option>
							))}
						</Select>
					</div>
					<div className='flex flex-col'>
						<label className='font-bold'>Sub Account</label>
						<Select showSearch className='w-[200px] h-10' onChange={(val) => setState({ ...state, subAccount: subAccount?.find((x) => x.label === val).value })}>
							{subAccount?.map((item) => (
								<Option key={item.label} value={item.label}>
									{item.label}
								</Option>
							))}
						</Select>
					</div>
					<div className='flex flex-col'>
						<label className='font-bold'>Methods</label>
						<Select showSearch className='w-[200px] h-10' onChange={(val) => setState({ ...state, method: val })}>
							{['All', 'Online', 'Normal'].map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</div>
				</div>

				<div className='flex flex-grow overflow-hidden'>
					<div className='flex flex-row gap-5 flex-grow overflow-hidden'>
						<Body>
							<div className='h-[94%] pt-2 printable overflow-auto custom-scrollbar'>
								<div className='sticky top-[-9px] bg-white z-10'>
									<Table width='2700px'>
										<Thead>
											<Tr>
												<Th width='100px' onClick={() => requestSort('SN')}>
													SN
												</Th>
												<Th width='100px' onClick={() => requestSort('billNo')} icon={getIcon('billNo')}>
													Bill No
												</Th>
												<Th width='150px' onClick={() => requestSort('Admn No')} icon={getIcon('Admn No')}>
													Admn No
												</Th>
												<Th width='200px' onClick={() => requestSort('Student Name')} icon={getIcon('Student Name')}>
													Student Name
												</Th>
												<Th width='250px' onClick={() => requestSort('ClassName')} icon={getIcon('ClassName')}>
													ClassName
												</Th>
												<Th width='150px' onClick={() => requestSort('Amount')} icon={getIcon('Amount')}>
													Amount
												</Th>

												{state?.method !== 'Normal' && (
													<>
														<Th width='200px' onClick={() => requestSort('Bank Account')} icon={getIcon('Bank Account')}>
															Bank Account
														</Th>
														<Th width='250px' onClick={() => requestSort('Transaction ID')} icon={getIcon('Transaction ID')}>
															Transaction ID
														</Th>
														<Th width='250px' onClick={() => requestSort('Account Holder')} icon={getIcon('Account Holder')}>
															Account Holder
														</Th>
														<Th width='150px' onClick={() => requestSort('Transaction Date')} icon={getIcon('Transaction Date')}>
															Transaction Date & Time
														</Th>
													</>
												)}
												{state?.method !== 'Online' && (
													<>
														<Th width='100px' onClick={() => requestSort('Where')} icon={getIcon('Where')}>
															Where
														</Th>
														<Th width='100px' onClick={() => requestSort('Date & Time')} icon={getIcon('Date & Time')}>
															Date & Time
														</Th>
														<Th width='100px' onClick={() => requestSort('Refund On')} icon={getIcon('Refund On')}>
															Refund On
														</Th>
														<Th width='150px' onClick={() => requestSort('Refund Amount')} icon={getIcon('Refund Amount')}>
															Refund Amount
														</Th>
														<Th width='150px' onClick={() => requestSort('Collected By')} icon={getIcon('Collected By')}>
															Collected By
														</Th>
													</>
												)}
											</Tr>
										</Thead>
									</Table>
								</div>
								<Table width='2700px'>
									<Tbody>
										{tableItems?.map((item, index) => (
											<Tr key={index}>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='100px'>
													{index + 1}
												</Td>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='100px'>
													{item.billNo}
												</Td>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='150px'>
													{item.admisionNo}
												</Td>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='200px'>
													{item.name}
												</Td>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='250px'>
													{item.className}
												</Td>
												<Td textAlign='center' fontSize='14px' fontWeight='bold' width='150px'>
													{item.amount}
												</Td>
												{state?.method !== 'Normal' && (
													<>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='200px'>
															{item.recievedBank}
														</Td>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='250px'>
															{item.transactionId}
														</Td>

														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='250px'>
															{item.accountHolderName}
														</Td>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='150px'>
															{item.transactionDate}
														</Td>
													</>
												)}
												{state?.method !== 'Online' && (
													<>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='100px'>
															{item.recievedBank}
														</Td>

														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='100px'>
															{item.date}
														</Td>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='100px'>
															{item.refundOn}
														</Td>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='150px'>
															{item.refundAmount}
														</Td>
														<Td textAlign='center' fontSize='14px' fontWeight='bold' width='150px'>
															{item.collectedBy}
														</Td>
													</>
												)}
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>
						</Body>
						<div className='w-[25%] h-full overflow-auto custom-scrollbar'>
							<div className='w-full bg-slate-50 p-3'>
								<h5 className='text-center rounded bg-green-400'>Collection Report</h5>
								<div className='flex flex-col mt-4 space-y-2'>
									<div className='grid grid-cols-3 items-center overflow-auto'>
										<p className='font-bold text-red-600 text-lg'>Total:</p>
										<p className='font-bold text-red-600 text-lg text-center'>{data?.collections?.length}:</p>
										<p className='font-bold text-red-600 text-lg text-right'>{data?.totalCash}</p>
										<hr className='w-[300%]' />
									</div>
									<div className='flex flex-col space-y-2 max-h-[50vh] overflow-auto'>
										{data?.dayWiseTotalArray?.map((day, index) => (
											<div key={index} className='grid grid-cols-3 items-center '>
												<p className='font-bold text-xs'>{day?.type}:</p>
												<p className='font-bold text-xs text-center'>{day?.count}</p>
												<p className='font-bold text-xs text-right'>{day?.sum}</p>
												<hr className='w-[300%]' />
											</div>
										))}
									</div>
								</div>
								<div className='flex flex-row gap-4 items-center'>
									<label className='font-bold text-red-600 text-xl'>Check</label>
									<input type='checkbox' className='form-checkbox h-8 w-8' checked={state.check} onChange={() => setState({ ...state, check: !state.check })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				{alert.show && <Alert header={alert.type} btnText={'Close'} text={alert.text} type={alert.type} show={alert.show} onClosePress={onCloseAlert} pressCloseOnOutsideClick={true} showBorderBottom={true} alertStyles={{ height: 200, width: 800, margin: '50%', backgroundColor: 'red', borderRadius: 10 }} headerStyles={{ color: 'white', width: '100%', textAlign: 'center' }} textStyles={{ color: 'white', width: '100%', textAlign: 'center', fontSize: 20 }} buttonStyles={{ color: 'white', backgroundColor: 'green', padding: 20, borderRadius: 10, margin: '47%' }} />}
			</div>
		</Main>
	);
};
