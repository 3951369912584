import React from 'react';
import Lottie from 'react-lottie';
import classNames from './loading.module.scss';
import animationData from '../../static/images/loader.json';
function LoadingAnimation({ customHeight, customWidth }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,

    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className={classNames.loadingWrapper}>
      <Lottie options={defaultOptions} height={customHeight || 150} width={customWidth || 150} />
    </div>
  );
}

export default LoadingAnimation;
