import React, { useContext, useRef, useState } from 'react';
import classNames from './addUserForm.module.scss';
import Select from 'antd/lib/select';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { useQuery } from 'react-query';
import { Field, ErrorMessage, Form, Formik } from 'formik';
import { ArrowRightOutlined } from '@ant-design/icons';
import LoadingAnimation from '../../../../components/LoadingAnimation';

const { Option } = Select;

const getClassesList = async ({ queryKey }) => {
	// eslint-disable-next-line
	const [_, { collegeId }] = queryKey;
	const instance = await getAxiosTokenInstance();
	let resp;
	if (collegeId)
		try {
			resp = await instance.get('/college/classes/getClass', {
				params: { collegeId },
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	return resp?.data;
};

function AddUserFormStudents({ toggleRefresh, userType, setUserType, classId, setClassId, setDepartmentId, name, phoneNumber, admisionNo, setAdmisionNo, setPhoneNumber, setName, id, setId }) {
	const ref = useRef();

	const [isLoading, setIsLoading] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const classQuery = useQuery(['classes', { collegeId }], getClassesList);

	const onAddUser = async (data, actions) => {
		try {
			if (data.fullName) {
				setIsLoading(true);
				const instance = await getAxiosTokenInstance();
				let resp = await instance.post('/college/admissionProcess/createStudentAccount', {
					collegeId,
					id,
					fullName: data.fullName,
					phoneNumber: data?.phoneNumber?.replace('+91', ''),
					classId: classId,
				});
				if (resp.data.statusCode !== 200) {
					throw new Error(resp.data.message || 'API Error');
				}
				toggleRefresh();
				message.success(resp.data.message);
				actions.resetForm();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className={classNames.addUserForm}>
			<div className={classNames.title}>Create Student Account Carefully </div>

			<Formik
				initialValues={{
					collegeId,
					type: userType,
					fullName: name,
					phoneNumber: phoneNumber,
					classId: '',
					departmentId: '',
					admisionNo: admisionNo,
				}}
				enableReinitialize
				onSubmit={(values, formikActions) => {
					onAddUser(values, formikActions);
					setClassId('');
					setDepartmentId('');
					setName('');
					setPhoneNumber('');
					setAdmisionNo('');
					setId('');
				}}>
				{({ resetForm }) => (
					<Form ref={ref} className={classNames.userForm}>
						<div style={{ margin: 10, width: '10%' }}>
							<div
								className={classNames.btnClear}
								onClick={() => {
									setUserType('');
									setClassId('');
									setDepartmentId('');
									setName('');
									setPhoneNumber('');
									setAdmisionNo('');
									setId('');
									resetForm();
								}}>
								Clear
							</div>
						</div>

						<div style={{ margin: 10, width: '25%' }}>
							<Select showSearch value={classId || undefined} placeholder='Select Class' className={classNames.formFullWidth} style={{ overflow: 'hidden' }} onChange={(val, op) => setClassId(val)} key='classId'>
								{classQuery?.data?.list?.map((classes) => (
									<Option key={classes._id} value={classes._id}>
										{classes.className}
									</Option>
								))}
							</Select>
							<div className={classNames.error}>
								<ErrorMessage name='classId' />
							</div>
						</div>
						<div style={{ margin: 10, width: '25%' }}>
							<div className={`${classNames.group} ${classNames.formFullWidth}`}>
								<Field type='text' name='fullName' required />
								<label>Full Name (As per SSLC)</label>
								<div className={classNames.error}>
									<ErrorMessage name='fullName' />
								</div>
							</div>
						</div>
						<div style={{ margin: 10, width: '25%' }}>
							<div className={`${classNames.group} ${classNames.formFullWidth}`}>
								<Field type='text' maxLength={10} name='phoneNumber' required />
								<label>Official Mobile Number</label>
								<div className={classNames.error}>
									<ErrorMessage name='phoneNumber' />
								</div>
							</div>
						</div>

						<div style={{ margin: 10, width: '10%' }}>
							<div className={classNames.formButtonWidth}>
								<button type='primary' value='Submit'>
									{id ? 'Update' : 'Create '}
									<ArrowRightOutlined style={{ fontSize: 22, margin: 'auto 0 auto 5px' }} />
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			{isLoading && <LoadingAnimation />}
		</div>
	);
}

export default AddUserFormStudents;
