import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import { toast, Toaster } from 'react-hot-toast';

export const getAllClasses =
	(collegeId: string, classOnly: boolean = true) =>
	async () => {
		const instance = getAxiosInstance();
		const { data } = await instance
			.get('/college/classes/getClass', {
				params: { collegeId, needAll: true, classOnly: true },
			})
			.catch((error) => {
				throw new Error(error?.response?.data?.message || error.message || 'API Error');
			});

		if (data?.statusCode === 200) {
			return data;
		} else {
			throw new Error(data?.message);
		}
	};

function TutionFeeData({ onClose }: { onClose: () => void }) {
	const { collegeId } = useContext(FirebaseContext);
	const [values, setValues] = useState<any[]>([]);
	const [feeNameArray, setFeeNameArray] = useState<any[]>([]);
	const [selectedClass, setSelectClass] = useState('');
	const [changeTrue, setChangeTrue] = useState(false);
	const [selectedMainAccount, setSelectedMainAccount] = useState<any>('');
	const [selectedSubAccount, setSelectedSubAccount] = useState<any>('');
	const [mainAccountDropDowns, setMainAccountDropDowns] = useState<any[]>([]);
	const [subAccountDropDowns, setSubAccountDropDowns] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { data: classes } = useQuery(['allClasses', collegeId, true], getAllClasses(collegeId, true));

	const handleChange = (e: any, i: number) => {
		const { name, value } = e.target;
		const temp = [...values];
		temp[i] = { ...temp[i], [name]: value };
		setValues(temp);
		setChangeTrue(true);
	};

	const handleChange2 = (e: any, studentIndex: number, dueIndex: number) => {
		const { name, value } = e.target;
		console.log({ values });
		const temp = [...values];
		temp[studentIndex] = {
			...temp[studentIndex],
			dues: temp[studentIndex].dues.map((due: any, index: number) => {
				if (index === dueIndex) {
					const updatedDue = {
						...due,
						[name]: parseInt(value),
					};
					if (name === 'amount') {
						updatedDue.balance = parseFloat(value) - parseFloat(due.paid);
					}
					return updatedDue;
				}
				return due;
			}),
		};
		setValues(temp);
		setChangeTrue(true);
	};

	const getStudents = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const { data } = await instance.get('/college/users/feeDataStudent', { params: { collegeId, classId: selectedClass, accountId: selectedMainAccount } });
			setValues(data.data);
		} catch (error: any) {
			console.log(error);
		}
	};
	const getMainAccounts = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const { data } = await instance.get('/college/users/getMainAccounts', { params: { collegeId, classId: selectedClass } });
			setMainAccountDropDowns(data?.mainAccounts);
		} catch (error: any) {
			console.log(error);
		}
	};
	const getSubAccounts = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const { data } = await instance.get('/college/users/getSubAccounts', { params: { collegeId, mainAccountId: selectedMainAccount } });
			setSubAccountDropDowns(data?.subAccounts);
		} catch (error: any) {
			console.log(error);
		}
	};

	const handleSave = async () => {
		const instance = await getAxiosTokenInstance();

		try {
			setIsLoading(true);
			const payload = values.map((student) => ({
				_id: student._id,
				userId: student.uid,
				collegeId: collegeId,
				dues: student.dues.map((due: any) => ({
					_id: due._id,
					dueDate: due.dueDate,
					amount: parseFloat(due.amount),
					paid: due.paid,
					balance: due.balance,
					updatedOn: new Date().toISOString(),
					accountId: due.accountId,
				})),
			}));

			const response = await instance.post('/college/users/updateBulkFees', { data: payload, collegeId });

			console.log('Save successful:', response);
			setIsLoading(false);
			toast.success('succesfully saved');
		} catch (error) {
			toast.error('unable to update');

			console.log('Save failed:', error);
			setIsLoading(false);
		}
	};

	const handleSaveConfirmation = () => {
		toast(
			(t) => (
				<div>
					<p>Are you sure you want to save?</p>
					<div className='flex justify-end mt-2'>
						<button
							onClick={() => {
								toast.dismiss(t.id);
								handleSave();
							}}
							className='bg-blue-500 hover:bg-blue-400 text-white font-bold py-1 px-3 rounded mr-2'>
							Yes
						</button>
						<button onClick={() => toast.dismiss(t.id)} className='bg-gray-500 hover:bg-gray-400 text-white font-bold py-1 px-3 rounded'>
							No
						</button>
					</div>
				</div>
			),
			{
				duration: 5000,
			},
		);
	};

	useEffect(() => {
		getStudents();
	}, [selectedMainAccount]);
	useEffect(() => {
		// getStudents();
		getMainAccounts();
		setMainAccountDropDowns([]);
		getStudents();
	}, [collegeId, selectedClass]);
	useEffect(() => {
		getSubAccounts();
	}, [collegeId, selectedMainAccount]);

	console.log({ values });
	console.log({ values });

	return (
		<Main title='Bulk Student Fee Editor' height='90vh' width='98vw'>
			<Header>
				<div className='flex'>
					<Input fieldName='Select Class' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
					<Input fieldName='Select Main Accounts' state={selectedMainAccount} setState={setSelectedMainAccount} didntShowKey optionDisplay='name' optionKey='_id' options={mainAccountDropDowns} type='drop' width='20vw' />
				</div>
				{/* <Input fieldName='Select Sub Accounts' setState={setSelectedSubAccount} didntShowKey optionDisplay='name' optionKey='_id' options={subAccountDropDowns} type='drop' width='15vw' /> */}

				<div className='flex'>
					{changeTrue && (
						<button className='bg-blue-500 hover:bg-blue-400 text-white font-bold py-0 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded' onClick={handleSaveConfirmation} disabled={isLoading}>
							{isLoading ? 'Updating...' : 'Save'}
						</button>
					)}
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className=' overflow-auto'>
					<Toaster />
					<Table width='100%' innerWidth={['10%', '20%', '30%', 'auto']}>
						<Thead>
							<Tr>
								<Th position={0}>SN</Th>
								<Th position={1}>Adm.No.</Th>
								<Th position={2}>Name</Th>
								{subAccountDropDowns && subAccountDropDowns?.map((sub) => <Th position={3}>{sub.name}</Th>)}
							</Tr>
						</Thead>
						<Tbody height='69vh'>
							{values?.map((x: any, i: number) => (
								<Tr>
									<Td position={0} padding={'1px'} index={i}>
										{i + 1}
									</Td>
									<Td position={1} padding={'1px'} index={i}>
										<input type='text' value={x?.admisionNo || ''} name='admisionNo' onChange={(e) => handleChange(e, i)} disabled />
									</Td>
									<Td position={2} padding={'1px'} index={i}>
										<input type='text' value={x?.name || ''} name='name' onChange={(e) => handleChange(e, i)} disabled />
									</Td>

									{x?.dues?.map((f: any, j: number) =>
										subAccountDropDowns.some((sub) => sub._id === f?.accountId) ? (
											<Td position={4} padding={'1px'} index={i} key={j}>
												<input type='number' value={x?.dues[j]?.amount || ''} name='amount' onChange={(e) => handleChange2(e, i, j)} />
											</Td>
										) : null,
									)}

									{/* {x?.dues?.map((f: any, j: number) => (
										<Td position={4} padding={'1px'} index={i} key={j}>
											<input type='number' value={x?.dues[j]?.amount || ''} name='amount' onChange={(e) => handleChange2(e, i, j)} />
										</Td>
									))} */}
								</Tr>
							))}
						</Tbody>
					</Table>
				</div>
			</Body>
		</Main>
	);
}

export default TutionFeeData;
