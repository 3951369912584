import React, { createContext, useState, useEffect } from "react";

export const FullScreenContext = createContext();
function FullScreenContextProvider({ children }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const elem = window.nectosElement;
  function handleFullscreenChange() {
    let isFullscreen = document.fullscreenElement === elem;
    setIsFullScreen(isFullscreen);
  }
  function togleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    }; // eslint-disable-next-line
  }, []);
  return (
    <FullScreenContext.Provider value={{ isFullScreen, togleFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  );
}

export default FullScreenContextProvider;
