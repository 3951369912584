import React, { useState, useContext } from 'react';
import Select from 'antd/lib/select';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

const { Option } = Select;

function EditTcFinal({ setLoading, data, refetch, onClose }) {

	const { collegeId } = useContext(FirebaseContext);
	const [Exams] = useState(['Not attended', 'l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [semester] = useState(['l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [reason] = useState(['Course Completed', 'Discontinued', 'College Transfer', 'Higher Studies']);
	const [Catogorys] = useState(['GENERAL', 'OBC', 'OBC (H)', 'OEC', 'ST', 'SC']);

	const [Exam, setExam] = useState(data?.tcData[0]?.exam);
	const [Letter, setLetter] = useState(data?.tcData[0]?.requestLetter);
	const [JoinedSem, setJoinedSem] = useState(data?.tcData[0]?.joinedSem);
	const [tcNumber, setTcNumber] = useState(data?.tcNumber);
	const [name, setName] = useState(data?.name);
	const [Streason, setStreason] = useState(data?.tcData[0]?.reasonForLeaving);
	const [ClsName, setClsName] = useState(data?.classId);
	const [Catogory, setCatogory] = useState(data?.category);
	const [AdNumber, setAdnumber] = useState(data?.admisionNo);
	const [SemesterfromLeaving, setSemesterfromLeaving] = useState(data?.tcData[0]?.leaving);
	const [RegisteNo, setRegisterNo] = useState(data?.hallTicketNo);
	const [DoAdmission, setDoAdmission] = useState(moment(new Date(data?.dateOfAdmission)).format('YYYY-MM-DD'));
	const [DoLeaving, setDoLeaving] = useState(moment(data?.tcData[0]?.dateOfLeaving).format('YYYY-MM-DD'));
	const [tcDate, setTcDate] = useState(data?.tcDate ? moment(data?.tcDate).format('YYYY-MM-DD'):null);
	const [tcIssueDate, setTcIssueDate] = useState(data?.tcDate ? moment(data?.tcIssueDate).format('YYYY-MM-DD'):null);
	const [appliedOn, setAppliedOn] = useState(moment(new Date(data?.tcData[0]?.appliedOn)).format('YYYY-MM-DD'));
	const [Dob, setDob] = useState(moment(new Date(data?.dob)).format('YYYY-MM-DD'));
	
	const adsUserTc = async () => {
		const instance = await getAxiosTokenInstance();
		await instance.post('/college/transferCertificate/editForTcCcNew', {
			collegeId: collegeId,
			fullName: name,
			dob: Dob,
			classId: ClsName,
			doa: DoAdmission,
			userId: data.uid,
			requestLetterId: data?._id,
			admisionNo: AdNumber,
			category: Catogory,
			...(tcDate && {tcDate: tcDate}),
			...(tcNumber && {tcNumber: tcNumber}),
			regNo: RegisteNo,
			joinedSem: JoinedSem,
			requestLetter: Letter,
			reasonForLeaving: Streason,
			tcIssueDate,
			exam: Exam,
			leaving: SemesterfromLeaving,
			appliedOn,
		});
		refetch();
		onClose();
	};

	const getClassesList = async ({ queryKey }) => {
		const [_, { collegeId }] = queryKey;
		const instance = await getAxiosTokenInstance();
		const resp = await instance.get('/college/classes/getClass', {
			params: { collegeId, needAll: true, classOnly: true },
		});
		return resp?.data;
	};

	const classQuery = useQuery(['classes', { collegeId }], getClassesList);

	return (
		<Main width='60vw' height='100vh'>
			<div className='bg-white p-6 rounded-lg shadow-lg  mx-auto relative w-full h-full overflow-y-scroll'>
				<div className='absolute right-0 top-0'>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
				<h2 className='text-2xl font-semibold mb-6 text-center'>Edit TC</h2>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
					<div>
						<label className='block mb-2 font-medium'>Class Name</label>
						<Select className='w-full p-2 border rounded-md' value={ClsName} onChange={(e) => setClsName(e)}>
							{classQuery?.data?.list?.map((classes) => (
								<Option key={classes.className} value={classes.classId}>
									{classes.className}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Full Name (as per SSLC)</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Category</label>
						<Select className='w-full p-2 border rounded-md' value={Catogory} onChange={(e) => setCatogory(e)}>
							{Catogorys.map((cat) => (
								<Option key={cat} value={cat}>
									{cat}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Admission Number</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Admission Number' value={AdNumber} onChange={(e) => setAdnumber(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Register Number</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Ex:- Hallticket Number' value={RegisteNo} onChange={(e) => setRegisterNo(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Date of Admission</label>
						<input type='date' className='w-full p-2 border rounded-md' value={DoAdmission} onChange={(e) => setDoAdmission(e.target.value)} />
						

						<label className='block mt-4 mb-2 font-medium'>TC Number</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='TC Number' value={tcNumber} onChange={(e) => setTcNumber(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>TC Date</label>
						<input type='date' className='w-full p-2 border rounded-md' value={tcDate} onChange={(e) => setTcDate(e.target.value)} />

						<label className='block mb-2 font-medium'>Date of Issue</label>
						<input type='date' className='w-full p-2 border rounded-md' value={tcIssueDate} onChange={(e) => setTcIssueDate(e.target.value)} />
					</div>

					<div>
					<label className='block mt-4 mb-2 font-medium'>Reason for Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={Streason} onChange={(e) => setStreason(e)}>
							{reason.map((res) => (
								<Option key={res} value={res}>
									{res}
								</Option>
							))}
						</Select> 

						<label className='block mb-2 font-medium'>Date of Leaving</label>
						<input type='date' className='w-full p-2 border rounded-md' value={DoLeaving} onChange={(e) => setDoLeaving(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Date of Birth</label>
						<input type='date' className='w-full p-2 border rounded-md' value={Dob} onChange={(e) => setDob(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Applied On</label>
						<input type='date' className='w-full p-2 border rounded-md' value={appliedOn} onChange={(e) => setAppliedOn(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Semester Joined</label>
						<Select className='w-full p-2 border rounded-md' value={JoinedSem} onChange={(e) => setJoinedSem(e)}>
							{semester.map((sem) => (
								<Option key={sem} value={sem}>
									{sem}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Semester from Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={SemesterfromLeaving} onChange={(e) => setSemesterfromLeaving(e)}>
							{semester.map((sem) => (
								<Option key={sem} value={sem}>
									{sem}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Examination Last Appeared</label>
						<Select className='w-full p-2 border rounded-md' value={Exam} onChange={(e) => setExam(e)}>
							{Exams.map((exam) => (
								<Option key={exam} value={exam}>
									{exam}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Write a Letter for release TC and CC to the Principal</label>
						<textarea className='w-full p-2 border rounded-md' placeholder='Write your letter here...' value={Letter} onChange={(e) => setLetter(e.target.value)} rows='5' />
					</div>
				</div>

				<div className='mt-6 flex justify-center'>
					<button
						className='bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition duration-200'
						onClick={(e) => {
							e.preventDefault();
							adsUserTc();
						}}>
						Submit
					</button>
				</div>
			</div>
		</Main>
	);
}

export default EditTcFinal;
