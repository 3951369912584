import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAllAccademics, getAllRollouts, getStudents } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { Popconfirm, Select } from 'antd';
import SelectField from '../../NecttosComp/SelectField';
import toast, { Toaster } from 'react-hot-toast';

const getFieldDropDowns = (collegeId: any, type: any, search: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getHolidaysDrops', {
			params: { collegeId, type, search },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface invoiceManagementProps {
	onClose: () => void;
}

function NewRolloutManagement({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [filteredItems, setItems] = useState<any>([]);
	const [search, setSearch] = useState<any>('');
	const [selectedStudent, setSelectedStudent] = useState<any>('');
	const [mode, setMode] = useState<any>('');

	const removeItems = (subject: any) => {
		setItems(filteredItems.filter((s: any) => s !== subject));
	};

	const handleItems = (event: any) => {
		const value = event.target.value;
		if (!filteredItems?.includes(value)) {
			setItems([...(filteredItems || []), value]);
		}
	};

	const { data: tableData = [], refetch } = useQuery('getAllRollouts', getAllRollouts(collegeId));
	const { data: dropItems = [] } = useQuery(['getFieldDropDowns', addNew?.effected], getFieldDropDowns(collegeId, addNew?.effected, search));

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteRolloutManagement', { params: { collegeId, mode, _id } })
				.then(() => {
					toast.success('deleted succesfully');
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
					toast.error('delete failed');
				});
		} catch (err) {
			console.log(err);
			toast.error('cannot call delete');
		}
	};

	const addNewForm = async () => {
		try {
			if (!addNew.userId || !addNew.rolloutDate || !addNew.rolloutReason) {
				toast.error('fill required data');
				return;
			}
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postRolloutManagement', { ...addNew, collegeId, updatedBy: user.uid, mode })
				.then(() => {
					toast.success('Added successfully');
					setAddNew({});
					refetch();
					setSelectedStudent('');
				})
				.catch((err: any) => {
					console.error(err);
					toast.error('adding rollout failed');
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			toast.error('cannot add');
			throw new Error('API Error');
		}
	};

	const { data: students = [] } = useQuery(['getStudents', collegeId], getStudents(collegeId));

	const studentsOption = students?.map((studentItem: { _id: string; name: string; admisionNo: string }) => ({
		value: studentItem._id,
		label: studentItem.admisionNo + ' _ ' + studentItem.name,
		_id: studentItem._id,
	}));

	useEffect(() => {
		if (addNew?.rolloutDate && addNew.rolloutDate !== moment(addNew.rolloutDate).format('YYYY-MM-DD')) {
			setAddNew((prv: any) => ({
				...prv,
				rolloutDate: moment(addNew.rolloutDate).format('YYYY-MM-DD'),
			}));
		}
		if (addNew?.rejoinDate && addNew.rejoinDate !== moment(addNew.rejoinDate).format('YYYY-MM-DD')) {
			setAddNew((prv: any) => ({
				...prv,
				rejoinDate: moment(addNew.rejoinDate).format('YYYY-MM-DD'),
			}));
		}
	}, [addNew]);

	const { Option } = Select;

	return (
		<>
			<Main title='Rollout Management' width='100vw' height='100vh'>
				<Toaster />
				<Header>
					<Button
						type='update'
						onClick={() => {
							setShowForm(true);
						}}>
						Add Rollout
					</Button>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%'>
							<Thead>
								<Tr>
									<Th width={'80px'}>Sl.No.</Th>
									<Th width={'100px'}>Admission No</Th>
									<Th width={'200px'}>Name</Th>
									<Th width={'200px'}>Rollout Reason</Th>
									<Th width={'200px'}>Rollout Date</Th>
									<Th width={'200px'}>Rejoining Date</Th>
									<Th width={'200px'}>Action</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableData?.map((x: any, i: number) => (
									<Tr>
										<Td index={i} width={'80px'}>
											{i + 1}
										</Td>

										<Td index={i} width={'100px'}>
											{x?.admisionNo}
										</Td>
										<Td index={i} width={'200px'}>
											{x?.name}
										</Td>
										<Td index={i} width={'200px'}>
											{x?.rolloutReason}
										</Td>
										<Td index={i} width={'200px'}>
											{x?.rolloutDate ? moment(x?.rolloutDate).format('DD-MM-YYYY') : '-'}
										</Td>
										<Td index={i} width={'200px'}>
											{x?.rejoinDate ? moment(x?.rejoinDate).format('DD-MM-YYYY') : '-'}
										</Td>
										<Td index={i} width={'200px'}>
											<div className='flex'>
												<Button
													width='80px'
													type='update'
													onClick={() => {
														setAddNew(x);
														setShowForm(true);
													}}>
													Edit
												</Button>
												{/* <Button
													type='delete'
													onClick={() => {
														setMode('delete');
														handleDelete(x._id)
													}}>
													Delete
												</Button> */}
												<Popconfirm
													placement='topRight'
													title='Are you sure you want to delete?'
													okText='Yes'
													cancelText='No'
													onConfirm={() => {
														setMode('delete');
														handleDelete(x._id);
													}}>
													<Button type='delete'>Delete</Button>
												</Popconfirm>
											</div>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>

			{showForm && (
				<Main width='40vw' height='70%'>
					<Toaster />
					<Header>
						<div></div>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
								setAddNew({});
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'>
							<div className='flex flex-col w-full pl-1 pb-2 h-auto'>
								<label>Select Student</label>
								<Select
									showSearch
									className='rounded h-12'
									style={{ width: '100%' }}
									onChange={(val) => {
										const selectedStudent = students?.find((x: any) => x._id === val);
										if (selectedStudent) {
											setAddNew({
												...addNew,
												userId: selectedStudent._id,
											});
										}
									}}
									value={addNew?.userId ? students.find((x: any) => x._id === addNew.userId)?.admisionNo + ' ' + students.find((x: any) => x._id === addNew.userId)?.name : undefined}>
									{students?.map((item: any, i: any) => (
										<Option key={i} value={item._id}>
											{item.admisionNo + ' ' + item.name}
										</Option>
									))}
								</Select>
							</div>

							{/* <SelectField id='students' name='class' classNameInset='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' label='Select Students' options={studentsOption} value={studentsOption?.find((option: any) => option?._id === '') || null} onChange={(selectedOption: any) => handleMannualStudentChangeDrop(selectedOption)} placeholder='Select students' /> */}
							{/* <Input width='100%' fieldName='Holiday Tittle.' returnKey='userId' state={addNew} setState={setAddNew} type='text' /> */}
							<Input width='100%' fieldName='Rollout Date' returnKey='rolloutDate' state={addNew} setState={setAddNew} type='date' required />
							<Input width='100%' fieldName='Rejoin Date' returnKey='rejoinDate' state={addNew} setState={setAddNew} type='date' />
							<Input width='100%' fieldName='Rollout Reason' returnKey='rolloutReason' state={addNew} setState={setAddNew} type='text' required />
							{/* <Input width='100%' fieldName='Who Is Effect' returnKey='effected' state={addNew} setState={setAddNew} options={['All', 'Departmenet Wise', 'Batch Wise', 'Class Wise', 'Students Wise']} type='drop' /> */}

							<div className='flex justify-center'>
								<Button
									width='150px'
									type='save'
									onClick={() => {
										addNewForm();
									}}>
									Save
								</Button>
							</div>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default NewRolloutManagement;
