import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { useQuery } from "react-query";
import Input from "../../NecttosComp/Input/Input";
import PasteFromWord from "./PastArear";

const getFieldDropDowns = (subjectId: any) => async () => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/examProcess/getSubjectPoits", {
      params: { subjectId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.points;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function QuetionBankManager({ onClose }: { onClose: () => void }) {
  const { collegeId } = useContext(FirebaseContext);
  const [values, setValues] = useState<any[]>([]);
  const [selectedClass, setSelectClass] = useState("");
  const [addNew, setAddNew] = useState<any>({});
  const [addQ, setAddQ] = useState("<p><!-- START --></p><p><!-- END --></p>");

  const { data: dropSubjectPoints = [] } = useQuery(["getFdieldDropDowns", selectedClass], getFieldDropDowns(selectedClass));

  const [data, setData] = useState([]);
  const getData = async () => {
    const instance = await getAxiosTokenInstance();
    const data = await instance.get("/college/examProcess/getSubjects", {
      params: { collegeId },
    });
    setData(data?.data?.finalArray);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e: any, i: number) => {
    const { name, value } = e.target;
    const temp = [...values];
    temp[i][name] = value;
    setValues([...temp]);
  };

  const getNoDataStudents = async () => {
    const instance = await getAxiosTokenInstance();

    instance
      .get("/college/examProcess/getAllQuestions", { params: { collegeId, subjectId: selectedClass } })
      .then((res: any) => {
        console.log(res.data);
        setValues(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleSave = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .post("/college/examProcess/updateBulkQuestions", { data: values, collegeId })
      .then((res: any) => {
        console.log(res.data);
        onClose();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNoDataStudents();
  }, [collegeId, selectedClass]);

  console.log({ addNew });
  function filterValues(values: any[], userSelections: { [s: string]: unknown } | ArrayLike<unknown>) {
    return values.filter((entry) => {
      return Object.entries(userSelections).every(([key, value]) => {
        // If the user did not select an option for this field, ignore it
        if (value === undefined || value === null || value === "") return true;

        // Special handling for fields that require deeper inspection or comparison, like 'module' or 'mark'
        if (key === "module" && entry.module) {
          return entry.module.includes(value); // Example for partial match (if needed)
        } else {
          return entry[key] === value;
        }
      });
    });
  }
  const filteredValues = filterValues(values, addNew);
  async function deleteQuestion(question: { question: any; _id: any }, collegeId: any) {
    if (window.confirm(`Are you sure you want to delete this question: ${question.question}?`)) {
      try {
        const instance = await getAxiosTokenInstance();
        const response = await instance.post("/college/examProcess/deleteQuestion", {
          _id: question._id,
          collegeId,
        });

        if (response.status === 200) {
          console.log("Item deleted successfully");
          getNoDataStudents();
        } else {
          console.error("Deletion failed");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  }

  const { tableItems, requestSort, getIcon } = useSortableData(filteredValues);

  return (
    <Main title="Question Bank Manager" height="90vh" width="98vw">
      <Header>
        <div className="flex">
          <Input fieldName="Select Subject" setState={setSelectClass} didntShowKey optionDisplay="name" optionKey="_id" options={data} type="drop" width="15vw" />
          <Input width="130px" fieldName="Select Module" returnKey="module" state={addNew} setState={setAddNew} options={dropSubjectPoints.map((x: { name: string }) => x.name)} type="drop" />
          <Input width="200px" fieldName="Select Unit" returnKey="topic" state={addNew} setState={setAddNew} options={dropSubjectPoints?.find((xx: { name: any }) => xx?.name === addNew?.module)?.points} type="drop" />
          <Input width="130px" fieldName="Type" returnKey="type" state={addNew} setState={setAddNew} options={["Theory", "Application"]} type="drop" />
          <Input width="130px" fieldName="Mark" returnKey="mark" state={addNew} setState={setAddNew} options={["1", "2", "3", "4", "5", "6", "8", "10", "12", "15", "20", "25"]} type="drop" />
          <Input width="130px" fieldName="Taxonomy" returnKey="taxonomy" state={addNew} setState={setAddNew} options={["Creating", "Evaluating", "Analysing", "Applying", "Understanding", "Remembering"]} type="drop" />
          <Input width="130px" fieldName="Difficulty" returnKey="difficulty" state={addNew} setState={setAddNew} options={["Challenging", "Medium", "Easy"]} type="drop" />
          <Input width="130px" fieldName="Important" returnKey="important" state={addNew} setState={setAddNew} options={["Very Important", "Important", "Normal"]} type="drop" />
          <Input width="130px" fieldName="CO" returnKey="co" state={addNew} setState={setAddNew} options={["CO1", "CO2", "CO3", "CO3", "CO4", "CO5", "CO6", "CO7", "CO8"]} type="drop" />
        </div>
        <div className="flex">
          <Button type="save" onClick={handleSave}>
            Save
          </Button>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <Body>
        <div className=" overflow-auto">
          <Table width="2800px" innerWidth={["60px", "90px", "160px", "40px"]}>
            <Thead>
              <Tr>
                <Th position={0}>S.N.</Th>
                <Th position={1} onClick={() => requestSort("module")} icon={getIcon("module")} children={"Module"} />
                <Th position={3} onClick={() => requestSort("co")} icon={getIcon("co")} children={"Co"} />
                <Th position={1} onClick={() => requestSort("topic")} icon={getIcon("topic")} children={"Unit"} />
                <Th position={0}>action</Th>
                <Th position={2} onClick={() => requestSort("question")} icon={getIcon("question")} children={"Question"} />
                <Th position={0} onClick={() => requestSort("mark")} icon={getIcon("mark")} children={"Mark"} />
                <Th position={1} onClick={() => requestSort("taxonomy")} icon={getIcon("taxonomy")} children={"Taxonomy"} />
                <Th position={1} onClick={() => requestSort("difficulty")} icon={getIcon("difficulty")} children={"Difficulty"} />
                <Th position={1} onClick={() => requestSort("important")} icon={getIcon("important")} children={"Important"} />
                <Th position={1} onClick={() => requestSort("type")} icon={getIcon("type")} children={"Type"} />
              </Tr>
            </Thead>
            <Tbody height="69vh">
              {tableItems?.map((x: any, i: number) => (
                <Tr>
                  <Td position={0} padding={"1px"} index={i}>
                    {i + 1}
                  </Td>

                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.module || ""} name="module" onChange={(e) => handleChange(e, i)}>
                      {dropSubjectPoints
                        .map((x: { name: string }) => x.name)
                        ?.map((z: any) => (
                          <option value={z}>{z}</option>
                        ))}
                    </select>
                  </Td>
                  <Td position={3} padding={"1px"} index={i}>
                    <select value={x?.co || ""} name="co" onChange={(e) => handleChange(e, i)}>
                      {["CO1", "CO2", "CO3", "CO3", "CO4", "CO5", "CO6", "CO7", "CO8"]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.topic || ""} name="topic" onChange={(e) => handleChange(e, i)}>
                      {dropSubjectPoints
                        ?.find((xx: { name: any }) => xx?.name === x?.module)
                        ?.points?.map((z: any) => (
                          <option value={z}>{z}</option>
                        ))}
                    </select>
                  </Td>

                  <Td position={0} padding={"1px"} index={i}>
                    <div onClick={() => deleteQuestion(x, collegeId)} className="w-[53px] bg-red-600 text-slate-100 px-[5px] rounded-sm">
                      Delete
                    </div>
                  </Td>

                  <Td position={2} padding={"1px"} index={i}>
                    {x?.type === "Application" ? <PasteFromWord setState={setAddQ} state={addQ} /> : <input type="text" value={x?.question || ""} name="question" onChange={(e) => handleChange(e, i)} />}
                  </Td>

                  <Td position={0} padding={"1px"} index={i}>
                    <input type="text" value={x?.mark || ""} name="mark" onChange={(e) => handleChange(e, i)} />
                  </Td>

                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.taxonomy || ""} name="taxonomy" onChange={(e) => handleChange(e, i)}>
                      {["Creating", "Evaluating", "Analysing", "Applying", "Understanding", "Remembering"]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>

                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.difficulty || ""} name="difficulty" onChange={(e) => handleChange(e, i)}>
                      {["Challenging", "Medium", "Easy"]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.important || ""} name="important" onChange={(e) => handleChange(e, i)}>
                      {["Very Important", "Important", "Normal"]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                  <Td position={1} padding={"1px"} index={i}>
                    <select value={x?.type || ""} name="type" onChange={(e) => handleChange(e, i)}>
                      {["Theory", "Application"]?.map((z: any) => (
                        <option value={z}>{z}</option>
                      ))}
                    </select>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Body>
    </Main>
  );
}

export default QuetionBankManager;
