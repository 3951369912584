
import React from "react";
import classNames from "./addBookModal.module.scss";

function CustomInput({
  keyData,
  typeData,
  stateData,
  setStateData,
  titleData,
  color,
  fontSize
}) {
  return (

    <div>
      <div style={{ margin: 10 }}>
        <div className={classNames.group}>
          <input
            type={typeData}
            style={{ ...fontSize && { fontSize: 22 }, ...color && { color } }}
            onChange={(e) => setStateData({ ...stateData, [keyData]: e.target.value })}
            value={stateData?.[keyData]}
          />
          <label>{titleData}</label>
        </div>
      </div>
    </div >
  )
}
export default CustomInput;

