import React, { useContext, useEffect } from 'react';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import AddUserPage from './CollegeApp/Office/AddUserPage';

import { FirebaseContext } from './context/FirebaseContext';
import LoadingAnimation from './components/LoadingAnimation';

import LibraryReports from './NecttosApp/windows';
import Loader from './Published/Login/Loader';
import Cookies from 'js-cookie';

function DashRoutes() {
	const { userLoading, user } = useContext(FirebaseContext);
	const history = useHistory();

	useEffect(() => {
		if (!Cookies.get('userToken')) {
			window.location.href = '/';
		}
	}, []);

	if (userLoading) {
		return <LoadingAnimation />;
	} else if (!user) {
		history.push('/');
	}
	return (
		<BrowserRouter basename='/app'>
			{userLoading ? (
				<Loader />
			) : (
				<Switch>
					<Route exact path='/' component={LibraryReports} />
					<Route exact path='/addUser' component={AddUserPage} />
				</Switch>
			)}
		</BrowserRouter>
	);
}

export default DashRoutes;
