import React, { useEffect, useState } from "react";
import { Body, Header, Main } from "../Layout/Layout";
import Button from "../Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../Table/Table";

interface ValuesType {
  name: string;
  importance: Boolean;
  width?: string;
  title: string;
}

interface Props {
  onClose: () => void;
  values: ValuesType[];
  data: any[];
  size?: "px" | "%" | "vh" | "vw";
}

function MissingDataFinder({ onClose, values, data, size = "px" }: Props) {
  const [missingFieldData, setMissingFieldData] = useState<any[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(0);

  useEffect(() => {
    let calculatewidth = 0;
    const keys = values.map((x) => {
      calculatewidth += parseInt(x?.width || "150");
      return x?.name;
    });
    console.log(keys);
    const reFormat = data?.filter((x) => {
      let didRetun = false;
      const dataKeys: string[] = [];
      Object.entries(x).forEach(([key, value]) => {
        dataKeys.push(key);
        if (keys.includes(key) && !value) {
          console.log([key, value], "hello");
          didRetun = true;
        }
      });
      keys.map((x) => {
        if (!dataKeys.includes(x)) {
          didRetun = true;
        }
      });
      if (didRetun) {
        return x;
      }
    });

    if (reFormat) setMissingFieldData([...reFormat]);
    setTableWidth(calculatewidth);
    console.log(reFormat);
  }, [data]);
  return (
    <Main height="80vh">
      <Header>
        <Button type="close" onClick={onClose}>
          Close
        </Button>
      </Header>
      <Body>
        <div className="w-full overflow-auto h-[60vh]">
          <Table width={tableWidth + size}>
            <Thead>
              <Tr>
                {values?.map((item) => (
                  <Th width={item?.width || "150px"}>
                    <p className="flex text-white">
                      {item?.title}
                      {item?.importance && <p className="text-red-500">*</p>}
                    </p>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody height="60vh">
              {missingFieldData?.map((x, i) => (
                <Tr>
                  {values?.map((item) => (
                    <Td fontSize="12px" index={i} width={item?.width || "150px"}>
                      {typeof x[item?.name]==="number"? Math.round(x[item?.name]) :x[item?.name]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Body>
    </Main>
  );
}

export default MissingDataFinder;
