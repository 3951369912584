import React, { useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import toast, { Toaster } from 'react-hot-toast';

const { Option } = Select;

function ApplyTc({ refetch, onClose, setLoading }) {
	const history = useHistory();
	const { collegeId } = useContext(FirebaseContext);
	const [name, setName] = useState({});
	const [DoLeaving, setDoLeaving] = useState('');
	const [SemesterfromLeaving, setSemesterfromLeaving] = useState('');
	const [Exam, setExam] = useState('');
	const [Streason, setStreason] = useState('');
	const [Letter, setLetter] = useState('');

	const semesters = ['l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester'];
	const reasons = ['Course Completed', 'Discontinued', 'College Transfer', 'Higher Studies'];
	const exams = ['Not attended', 'l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester'];

	const getClassesList = async () => {
		const instance = await getAxiosTokenInstance();
		const resp = await instance.get('/college/classes/getClass', {
			params: { collegeId },
		});
		return resp?.data;
	};

	const getUserList = async () => {
		const instance = await getAxiosTokenInstance();
		const resp = await instance.get(`college/transferCertificate/get?collegeId=${collegeId}&type=student`);
		return resp?.data;
	};

	const classQuery = useQuery(['classes', { collegeId }], getClassesList);
	const { data: userList } = useQuery(['userListstudent', { collegeId }], getUserList);

	return (
		<Main width='50%' height='80%'>
			<Toaster />
			<div className='bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto relative w-full h-full overflow-y-scroll'>
				<div className='absolute right-0 top-0'>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
				<h2 className='text-2xl font-semibold mb-4'>Apply for TC</h2>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
					<div>
						<label className='block mb-2 font-medium'>Full Name</label>
						<Select
							showSearch
							value={name.name}
							className='w-full p-2 border rounded-md'
							placeholder='Full Name'
							onChange={(val) => {
								const selectedUser = userList?.list.find((x, i) => i + '-' + x.name + '-' + x.admisionNo === val);
								setName({ name: selectedUser.name, userId: selectedUser._id });
							}}>
							{userList?.list?.map((item, i) => (
								<Option key={i + '-' + item.name + '-' + item.admisionNo} value={i + '-' + item.name + '-' + item.admisionNo}>
									{item.admisionNo} - {item.name}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Date of Leaving</label>
						<input type='date' className='w-full p-2 border rounded-md' value={DoLeaving} onChange={(e) => setDoLeaving(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Semester from Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={SemesterfromLeaving} onChange={(val) => setSemesterfromLeaving(val)}>
							{semesters.map((sem, index) => (
								<Option key={index} value={sem}>
									{sem}
								</Option>
							))}
						</Select>
					</div>

					<div>
						<label className='block mb-2 font-medium'>Examination last Appeared</label>
						<Select className='w-full p-2 border rounded-md' value={Exam} onChange={(val) => setExam(val)}>
							{exams.map((exam, index) => (
								<Option key={index} value={exam}>
									{exam}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Reason for Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={Streason} onChange={(val) => setStreason(val)}>
							{reasons.map((reason, index) => (
								<Option key={index} value={reason}>
									{reason}
								</Option>
							))}
						</Select>
					</div>
				</div>

				<label className='block mt-4 mb-2 font-medium'>Write a Letter for release TC and CC to the Principal</label>
				<textarea className='w-full p-2 border rounded-md' value={Letter} onChange={(e) => setLetter(e.target.value)} rows='5' placeholder='Write your letter here...' />

				<div className='mt-6 text-center'>
					<button
						className='bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200'
						onClick={async () => {
							try {
								setLoading(true);
								const instance = await getAxiosTokenInstance();
								const response = await instance.post('/college/transferCertificate/requestForTcCcNew', {
									collegeId,
									userId: name.userId,
									dateOfLeaving: DoLeaving,
									requestLetter: Letter,
									reasonForLeaving: Streason,
									exam: Exam,
									leaving: SemesterfromLeaving,
								});

								console.error({ response });
								if (response.status !== 200) {
									throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
								}
								setName('');
								setDoLeaving('');
								setSemesterfromLeaving('');
								setExam('');
								setStreason('');
								setLetter('');

								refetch();
								onClose();
								toast.success(`succesfully requested`);
							} catch (error) {
								console.error('Error submitting the form:', error);
								toast.error(`Failed to submit: ${error.message}`);
							} finally {
								setLoading(false);
							}
						}}>
						Submit
					</button>
				</div>
			</div>
		</Main>
	);
}

export default ApplyTc;
