import React, { useContext, useState } from 'react';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { Body, Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import Input from '../../../../NecttosComp/Input/Input';
import { FirebaseContext } from '../../../../context/FirebaseContext';

function AddSubject({ onClose, data }) {
	const [val, setVal] = useState({
		name: data?.name || '',
		shortName: data?.shortName || '',
		subjectCode: data?.subjectCode || '',
		lectureHour: data?.lectureHour || '',
		credit: data?.credit || '',
		internalMark: data?.internalMark || '',
		externalMark: data?.externalMark || '',
		examHour: data?.examHour || '',
		year: data?.year || '',
		partA: data?.partA || { questions: '', skip: '', mark: '' },
		partB: data?.partB || { questions: '', skip: '', mark: '' },
		partC: data?.partC || { questions: '', skip: '', mark: '' },
	});

	const { collegeId } = useContext(FirebaseContext);

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/subject/addSubjects', {
					syllabus: {
						internalMark: val.internalMark,
						externalMark: val.externalMark,
						examHour: val.examHour,
						lectureHour: val.lectureHour,
						credit: val.credit,
					},
					partA: val.partA,
					partB: val.partB,
					partC: val.partC,
					shortName: val.shortName,
					name: val.name,
					year: val.year,
					subjectCode: val.subjectCode,
					collegeId,
					id: data?._id,
				})
				.then(() => {
					onClose();
				})
				.catch((err) => {
					console.error(err);
				});
		} catch (err) {
			throw new Error('API Error');
		}
	};
	const handleNestedChange = (part, key, valueObj) => {
		const value = valueObj.value;
		setVal((currentVal) => ({
			...currentVal,
			[part]: {
				...currentVal[part],
				[key]: value,
			},
		}));
	};

	return (
		<Main width='auto' height='auto' title='Add Subject'>
			<div className='flex justify-end p-4'>
				<Button type='close' onClick={onClose}>
					Close
				</Button>
			</div>
			<Body>
				<div className='w-[100vw] lg:w-[60vw]   md:w-[80vw]  max-w-4xl mx-auto bg-white p-8 rounded-lg shadow overflow-hidden'>
					<Input width='100%' fieldName='Subject Name' returnKey='name' state={val} setState={setVal} type='text' placeholder='Enter Subject Name' className='w-full' />
					<form className='grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6'>
						<Input width='100%' fieldName='Subject Short Name' returnKey='shortName' state={val} setState={setVal} type='text' placeholder='Enter Short Name' />
						<Input width='100%' fieldName='Subject Code' returnKey='subjectCode' state={val} setState={setVal} type='text' placeholder='Enter Subject Code' />
						<Input width='100%' fieldName='Lecture Hours per week' returnKey='lectureHour' state={val} setState={setVal} type='number' placeholder='Enter Lecture Hours' />
						<Input width='100%' fieldName='Credits' returnKey='credit' state={val} setState={setVal} type='number' placeholder='Enter Credits' />
						<Input width='100%' fieldName='Internal Mark out of' returnKey='internalMark' state={val} setState={setVal} type='number' placeholder='Enter Internal Marks' />
						<Input width='100%' fieldName='External Mark out of' returnKey='externalMark' state={val} setState={setVal} type='number' placeholder='Enter External Marks' />
						<Input width='100%' fieldName='Examination Hour' returnKey='examHour' state={val} setState={setVal} type='number' placeholder='Enter Exam Duration' />
						<Input width='100%' fieldName='Syllabus Year' returnKey='year' state={val} setState={setVal} type='number' placeholder='Enter Syllabus Year' />
					</form>

					<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
						{['A', 'B', 'C'].map((part) => (
							<div key={part} className='space-y-4'>
								<Input width='100%' fieldName={`Part ${part} Total Questions`} state={val[`part${part}`].questions} onChange={(value) => handleNestedChange(`part${part}`, 'questions', value)} type='number' />
								<Input width='100%' fieldName={`Part ${part} Each Q Mark`} state={val[`part${part}`].mark} onChange={(value) => handleNestedChange(`part${part}`, 'mark', value)} type='number' />
								<Input width='100%' fieldName={`Part ${part} Skip`} state={val[`part${part}`].skip} onChange={(value) => handleNestedChange(`part${part}`, 'skip', value)} type='number' />
							</div>
						))}
					</div>
				</div>
			</Body>
			<div className='flex justify-center mt-8'>
				<Button type='save' onClick={addNewForm}>
					Submit
				</Button>
			</div>
		</Main>
	);
}

export default AddSubject;
