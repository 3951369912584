import React, { useState, useEffect } from 'react';

const NetworkSpeedModal = ({ isOpen, downloadSpeed, uploadSpeed, networkProvider, signalStrength, isOffline, closeModal, cpuSpeed, ramCapacity, ramAvailable, diskSpeed, cpuUsage, cpuCores, processorSpeed }) => {
	const [availableRam, setAvailableRam] = useState(null);
	const [cpuUsagePercentage, setCpuUsagePercentage] = useState(null);
	const [processorSpeedEstimate, setProcessorSpeedEstimate] = useState(null);
	const [cpuSpeedGHz, setCpuSpeedGHz] = useState(null);

	const estimateAvailableRam = () => {
		if (performance.memory) {
			setAvailableRam(performance.memory.totalJSHeapSize / 1024 / 1024);
		}
	};

	const estimateCpuUsage = () => {
		const start = performance.now();
		const count = 10000000;
		let iterations = 0;
		while (performance.now() - start < 500) {
			iterations++;
		}
		const usagePercentage = (iterations / count) * 100;
		setCpuUsagePercentage(usagePercentage.toFixed(2));
	};

	const estimateProcessorSpeed = () => {
		const start = performance.now();
		let count = 0;
		while (performance.now() - start < 1000) {
			count++;
		}
		setProcessorSpeedEstimate(count);
		convertIterationsToGHz(count);
	};

	const convertIterationsToGHz = (iterations) => {
		const ghz = iterations / 1_000_000_000;
		setCpuSpeedGHz(ghz.toFixed(6));
	};

	useEffect(() => {
		if (isOpen) {
			estimateAvailableRam();
			estimateCpuUsage();
			estimateProcessorSpeed();
		}
	}, [isOpen]);

	if (!isOpen) return null;

	const downloadSpeedClass = downloadSpeed < 2 ? 'text-red-600' : downloadSpeed < 5 ? 'text-yellow-600' : 'text-green-600';
	const signalStrengthClass = signalStrength === 'Strong' ? 'text-green-500' : signalStrength === 'Moderate' ? 'text-yellow-500' : 'text-red-500';

	return (
		<div className='fixed inset-0  bg-gray-500 bg-opacity-50 flex justify-center items-center z-100'>
			<div className='bg-white p-6 rounded-lg shadow-lg w-96 text-center'>
				<h2 className='text-2xl font-semibold mb-4'>System and Network Status</h2>

				{isOffline ? (
					<div className='mb-4'>
						<p className='text-lg text-red-600'>
							<span className='font-bold'>No Internet Connection</span>
						</p>
						<p className='text-sm text-gray-600'>It looks like you're offline. Please check your internet connection and try again.</p>
					</div>
				) : (
					<>
						<p className='text-lg mb-2'>
							<span className='font-semibold'>Download Speed:</span> <span className={downloadSpeedClass}>{downloadSpeed} Mbps</span>
						</p>

						<p className='text-lg mb-2'>
							<span className='font-semibold'>Network Provider:</span> {networkProvider || 'Not Available'}
						</p>

						<div className='flex justify-center items-center mb-4'>
							<span className='font-semibold'>Signal Strength:</span>
							<div className={`w-16 ml-2 rounded-full ${signalStrengthClass}`} title={`Signal Strength: ${signalStrength}`} children={signalStrength} />
						</div>
						{/* 
						<div className='text-lg mb-2'>
							<span className='font-semibold'>CPU Speed (GHz):</span> {cpuSpeedGHz ? `${cpuSpeedGHz} GHz` : 'Not Available'}
						</div>

						<div className='text-lg mb-2'>
							<span className='font-semibold'>Available RAM:</span> {ramAvailable ? `${ramAvailable.toFixed(2)} MB` : 'Not Available'}
						</div>

						<div className='text-lg mb-2'>
							<span className='font-semibold'>CPU Usage:</span> {cpuUsagePercentage ? `${cpuUsagePercentage}%` : 'Not Available'}
						</div> */}

						<div className='text-lg mb-2'>
							<span className='font-semibold'>Processor Cores:</span> {cpuCores || 'Not Available'}
						</div>

						<div className='text-sm text-gray-500 mt-4'>
							<p>
								<strong>Why this matters:</strong> A stable and fast connection ensures a smooth experience.
							</p>
							<p>
								<strong>Tips:</strong> If you notice slow speeds, try reconnecting to Wi-Fi or switching to a stronger signal source.
							</p>
						</div>
					</>
				)}

				<button onClick={closeModal} className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none mt-4'>
					Close
				</button>
			</div>
		</div>
	);
};

export default NetworkSpeedModal;
