import React, { useContext, useEffect, useState } from 'react';
import classNames from './detailView.module.scss';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { useProfileData } from '../../../NecttosComp/APICaller/queryHooks';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { message } from 'antd';
import { FirebaseContext } from '../../../context/FirebaseContext';
import Input from '../../../NecttosComp/Input/Input';
import CustomInput from '../../../myComp/CustomInput';

function Verification({ uid, isStudent, stateData, TimerFuncion, isTemp, refectData, whoAdmin, setStateData, keyData }) {
	const { collegeId, user } = useContext(FirebaseContext);

	const { data: userDetail, status } = useProfileData(uid, !isStudent);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState('');
	const [dateFast, setDateFast] = useState('');

	const Button = ({ color, text, onClick }) => {
		return (
			<button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: 'white', fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
				{text}
			</button>
		);
	};
	if (!uid) {
		return (
			<div className={classNames.detailView}>
				<div className={classNames.selectmessage}>Select A User First</div>
			</div>
		);
	}
	const handleCancel = () => {
		setStateData({ ...stateData, [userDetail._id + 'proceed']: false, verification: false });
	};

	let memberData = keyData === 'department' ? userDetail?.pendingData : userDetail;

	const handleProceed = async () => {
		setLoading(true);
		try {
			let data = {
				_id: uid,
				startDate: stateData[[userDetail?._id + 'timeStamp']],
				endDate: new Date(),
				collegeId,
				customeAdmission: values?.admission,
				customeDate: values?.dateFast,
				whoAdmin,
				key: keyData,
			};
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/admissionProcess/verify', data);
			if (resp.data.statusCode !== 200) {
				setLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				refectData();
				setStateData({
					verification: false,
				});
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	if (isTemp) {
		return (
			<div className={classNames.container}>
				<div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
					<div className={classNames.detailView}>
						<div style={{ overflow: 'scroll', margin: 20 }}>
							<div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
								<view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
									<Button color='#FF4136' text='Cancel' onClick={handleCancel} />
									<Button color='#2ECC40' text='Verify' onClick={handleProceed} />
								</view>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
				<div className={classNames.detailView}>
					{status === 'loading' && <LoadingAnimation dark />}
					{status === 'success' && (
						<>
							<div style={{ overflow: 'scroll', margin: 20 }}>
								<div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
									<>
										<div className={classNames.typoContainer}>
											<h5 className={classNames.userName}>{memberData?.name}</h5>
										</div>

										{keyData === 'fees' && (
											<view>
												<view style={{ width: 300 }}>
													<CustomInput keyData={'admission'} titleData={'Custome Admission Number'} typeData={'text'} fontSize={20} stateData={values} setStateData={setValues} />
												</view>
												<view style={{ width: 300 }}>
													<CustomInput keyData={'dateFast'} titleData={'Custome Admission Date'} typeData={'date'} fontSize={20} stateData={values} setStateData={setValues} />
												</view>
											</view>
										)}

										<TimerFuncion style={{ fontSize: 80, fontFamily: 'bold', color: '#004518', alignSelf: 'center' }} start={true} date={stateData[userDetail._id + 'timeStamp']} />
										{loading ? (
											<LoadingAnimation dark />
										) : (
											<view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
												<Button color='#FF4136' text='Cancel' onClick={handleCancel} />
												<Button color='#2ECC40' text='Verify' onClick={handleProceed} />
											</view>
										)}
										<img
											src={userDetail?.photoURL}
											style={{
												border: '3px solid navy',
												borderRadius: '10%',
												width: '90%',
												margin: 20,
											}}
										/>
									</>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Verification;
