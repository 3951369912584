import React, { useContext, useEffect, useRef } from 'react';
import classNames from './exportModal.module.scss';
import { Button, message, Select } from 'antd';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import Scrollbars from 'react-custom-scrollbars';

import CustomInput from '../../../myComp/CustomInput';
import { FirebaseContext } from '../../../context/FirebaseContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getNextSequenceNumber } from '../../../NecttosComp/APICaller/ApiServices';
import CustomDropDown from '../../../myComp/CustomDropDown';

export const ShortEdit = ({ item, onClose, setStateData, keys, isBillDelete, stateData, refetchDemo }) => {
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});

	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { data } = useQuery('getPenddingAdmissions', getNextSequenceNumber(collegeId));

	useEffect(() => {
		setState({
			newAdmisionNo: item?.admisionNo,
			newFalseAdmission: data?.admisionNo,
			newFalseApplication: data?.applicationNo,
			newApplicationNo: item?.applicationNo,
			capIdNew: item?.capId,
			indexNew: item?.index,
			admTypeNew: item?.admType,
			newDateOfAdmission: moment(item?.dateOfAdmission).format('YYYY-MM-DDTHH:MM'),
		});
	}, [item, data]);

	const editFalseNumber = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/admissionProcess/editFalseNumber', {
				collegeId,
				admisionNo: state?.newFalseAdmission + '',
				applicationNo: state?.newFalseApplication + '',
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({});
				refetchDemo();
				setStateData({ ...stateData, openModal: false });
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	const editRecieptNumber = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/admissionProcess/editAdmissionNo', {
				collegeId,
				_id: item?._id,
				admisionNo: state?.newAdmisionNo + '',
				applicationNo: state?.newApplicationNo + '',
				dateOfAdmission: state?.newDateOfAdmission,

				admType: state?.admTypeNew,
				capId: state?.capIdNew,
				index: state?.indexNew,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({});
				refetchDemo();
				setStateData({ ...stateData, openModal: false });
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} style={{ overflow: 'scroll' }}>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
									<img width='8%' height='8%' src={selectedCollege?.logo} />

									{item?.editNextAdmn ? (
										<div style={{ width: '80%', margin: 20, alignItems: 'center', textAlign: 'center' }}>
											<h5 style={{ fontSize: '20px', width: '100%', alignSelf: 'center' }}>OLD AdmisionNo : {data?.admisionNo}</h5>

											<CustomInput keyData={'newFalseAdmission'} titleData={`Next Admission No`} stateData={state} setStateData={setState} />
											<h5 style={{ fontSize: '20px', width: '100%', alignSelf: 'center' }}>OLD Application : {data?.applicationNo}</h5>

											<CustomInput keyData={'newFalseApplication'} titleData={`Next Application No`} stateData={state} setStateData={setState} />

											<Button className={classNames.submitBotton2} onClick={onClose}>
												Cancel
											</Button>
											<Button
												className={classNames.submitBotton}
												onClick={() => {
													editFalseNumber();
												}}>
												Update
											</Button>
										</div>
									) : (
										<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
											<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
												<h5 style={{ marginTop: -10, fontSize: '40px', width: '100%', alignSelf: 'center' }}> {item?.name}</h5>
												<h5 style={{ marginTop: -10, fontSize: '20px', width: '100%', alignSelf: 'center' }}>Current AdmisionNo : {item?.admisionNo}</h5>
											</div>

											<CustomInput keyData={'newAdmisionNo'} titleData={`New Admission No ` + item?.admisionNo} stateData={state} setStateData={setState} />
											<CustomInput keyData={'newApplicationNo'} titleData={`New Application No ` + item?.applicationNo} stateData={state} setStateData={setState} />
											<CustomInput keyData={'newDateOfAdmission'} titleData={'New Date Of Admission'} typeData={'datetime-local'} stateData={state} setStateData={setState} />
											<CustomInput keyData={'capIdNew'} titleData={`CAP ID New`} stateData={state} setStateData={setState} />
											<CustomInput keyData={'indexNew'} titleData={`Index New`} stateData={state} setStateData={setState} />

											<CustomDropDown keyData={'admTypeNew'} titleData={'New Reservation Catogory'} dropValues={keys || []} stateData={state} setStateData={setState} />

											<Button className={classNames.submitBotton2} onClick={onClose}>
												Cancel
											</Button>
											<Button
												className={classNames.submitBotton}
												onClick={() => {
													editRecieptNumber();
												}}>
												Update
											</Button>
										</div>
									)}
								</div>
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>
		</div>
	);
};
