import React, { createContext, useEffect, useState } from "react";
import { ADMIN_ROLES } from "../config/constants";
import { useMyColleges, usePersistentState } from "../utils";
import firebaseApp from "../utils/firebase";
import Cookies from "js-cookie";

export const FirebaseContext = createContext();

function FirebaseContextProvider({ children }) {
  const auth = JSON.parse(localStorage.getItem("userDetails"));

  const [user, setUser] = useState();
  const [userRole, setUserRole] = useState();
  const [userLoading, setUserLoading] = useState(true);

  const [collegeId, setCollegeId] = usePersistentState("collegeId", "");

  const [collegeList, setCollegeList] = useState([]);
  let { data: myColleges } = useMyColleges();
  
  useEffect(() => {
    setUser({ ...auth, accessToken: Cookies.get("userToken") });
    let admin;
    ADMIN_ROLES?.forEach((role) => {
      if (myColleges?.colleges?.filter((college) => college?.roles?.includes(role))[0]) {
        admin = myColleges?.colleges?.filter((college) => college?.roles?.includes(role))[0];
      }
    });
    setCollegeList(myColleges?.colleges || []);
    if (myColleges?.colleges?.length === 1) {
      setCollegeId(myColleges?.colleges[0]?.collegeId);
    }
    setTimeout(() => {
      setUserLoading(false);
    }, 1000);
    return () => {};
  }, [myColleges?.colleges]);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        userLoading,
        user,
        userRole,
        collegeId,
        collegeList,
        setCollegeId,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseContextProvider;
