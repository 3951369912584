import { Field, Formik } from 'formik';
import React, { useContext, useRef, useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import message from 'antd/lib/message';
import Select from 'antd/lib/select';
import { useMutation, useQuery } from 'react-query';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import classNames from './addBookModal.module.scss';
import bookCoverPlaceholder from '../../../static/images/placeholders/bookCoverPlaceholder.png';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { Button, List } from 'antd';
import AddModalView from './AddModal';
import { useDropDowns } from '../../../NecttosComp/APICaller/queryHooks';
import LoadingAnimation from '../../../components/LoadingAnimation';
import SearchableImput from './DropDownComponent';
import moment from 'moment';

const getDDCSubjects = (collegeId, key) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getDDCSubjects', {
			params: { collegeId, key },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getSubjects = (collegeId, key) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getSubjects', {
			params: { collegeId, key },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getTitles = (collegeId, key, isbn) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getTitels', {
			params: { collegeId, key, isbn },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			console.log(resp?.data?.list[0].data);
			return resp?.data?.list[0].data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const { Option } = Select;
async function addBook(data) {
	const storage = getStorage();
	let bookId = data?.bookId;

	const logoImagesRef = ref(storage, `bookCovers/${data.collegeId}/${data?.nameOfBook + moment(new Date()).format('ddd, dd-mm-yyyy hh:mm A')}.png`);
	await uploadBytes(logoImagesRef, data.bookCoverfile, {
		contentType: 'image/png',
	});
	const url = await getDownloadURL(ref(storage, logoImagesRef));
	const instance = await getAxiosTokenInstance();
	data.bookCoverfile = url;

	const resp = await instance.post('/college/libraryOld/addBook', {
		...data,
	});
	return resp.data;
}
async function addToDropDownCollection({ values, title }) {
	const instance = await getAxiosTokenInstance();
	const resp = await instance.post('/college/action/addToDropDowns', {
		key: 'library',
		subKey: title,
		values,
	});
	return resp.data;
}

function AddBook({ onSuccess, allBookList, onCloseModal, editableData }) {
	const { data: dropDown, refetch } = useDropDowns('library');
	const [addModal, setAddModal] = useState(false);

	const { collegeId } = useContext(FirebaseContext);
	const { mutate, isLoading } = useMutation(addBook, {
		onSuccess: (data) => {
			if (data.statusCode === 400) {
				message.error(data.message || 'API Error');
			} else {
				message.success('Book Added');
				onSuccess();
			}
		},
		onError: (error) => {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		},
	});

	const { mutate: dropsMutate } = useMutation(addToDropDownCollection, {
		onSuccess: (data) => {
			if (data.statusCode === 400) {
				message.error(data.message || 'API Error');
			} else {
				message.success('Added');
				refetch();
				setAddModal(false);
			}
		},
		onError: (error) => {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		},
	});

	const [showList, setShowList] = useState(false);
	const [showIsbnList, setShowIsbnList] = useState(false);

	const [costom, setCostom] = useState(false);
	const [search, setSearchKey] = useState('');

	const [addModalValue, setAddModalValue] = useState('');
	const [initialVal, setInitialVal] = useState({});
	const [searchBook, setSearch] = useState('');
	const [isbnVal, setIsbn] = useState('');
	const [bookCover, setbookCover] = useState('');

	const [stateData, setStateData] = React.useState({});

	const handleISBNSearch = async ({ bookId }) => {
		try {
			const instance = await getAxiosTokenInstance();
			const resp = await instance.get(`/college/libraryOld/getBookDetails?bookId=${bookId}`);
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			}
			if (resp.data.statusCode === 200) {
				if (resp.data.bookDetail.bookId !== bookId) {
					setInitialVal({});
					setStateData({});
				}

				setInitialVal(resp.data.bookDetail);
				setStateData(resp.data.bookDetail);
				setSearch(resp.data.bookDetail && resp.data.bookDetail.nameOfBook);
				setIsbn(resp.data.bookDetail && resp.data.bookDetail.isbn);
				setbookCover(resp.data.bookDetail && resp.data.bookDetail.image);
			}
		} catch (err) {
			// throw new Error(err || "API Error");
			message.error('No books found');
		}
	};

	const { data: subjects = [] } = useQuery(['SubjectDrops'], getSubjects(collegeId), { keepPreviousData: true });

	const { data: ddcDrops = [] } = useQuery(['ddcDrops', stateData.subject], getDDCSubjects(collegeId, stateData.subject), { keepPreviousData: true });

	const { data: titlesDropDowns = [] } = useQuery(['titlesDropDowns', stateData.nameOfBook, stateData.isbn], getTitles(collegeId, stateData?.nameOfBook, stateData.isbn), { keepPreviousData: true });

	const fnAddBook = async (data, actions) => {
		console.log(data);

		data.bookCoverfile = bookCover;
		data.bookId = initialVal._id;
		mutate(data);
		actions.setSubmitting(false);
	};

	const useOutsideAlerter = (ref) => {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowList(false);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	const useOutsideAlerter2 = (ref) => {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowIsbnList(false);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);
	const wrapperRef2 = useRef(null);
	useOutsideAlerter2(wrapperRef2);
	const ref = useRef(null);

	const onKeyDown = (keyEvent) => {
		if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
			keyEvent.preventDefault();
		}
	};

	return (
		<>
			{isLoading ? (
				<div className={classNames.loading}>
					<LoadingAnimation />
				</div>
			) : (
				<div className={classNames.addBookModal}>
					<div className={classNames.head}>
						<span>Add New Book</span>
						<button onClick={onCloseModal} className={classNames.cancel}>
							Cancel
						</button>
					</div>
					<Scrollbars className={classNames.content}>
						<Formik
							innerRef={ref}
							initialValues={{
								collegeId,
								isbn: stateData.isbn,
								nameOfBook: stateData.nameOfBook,
								category: stateData.category,
								nameOfAuthor: stateData.nameOfAuthor,
								nameOfAuthor2: stateData.nameOfAuthor2,
								nameOfAuthor3: stateData.nameOfAuthor3,

								authorType: stateData.authorType,
								authorType2: stateData.authorType2,
								authorType3: stateData.authorType3,

								edition: stateData.edition,
								volume: stateData.volume,
								series: stateData.series,
								pages: stateData.pages,

								nameOfPublisher: stateData.nameOfPublisher,
								placeOfPublication: stateData.placeOfPublication,
								yearOfPublication: stateData.yearOfPublication,

								subject: stateData.subject,
								ddcSubject: stateData.ddcSubject,
								ddcNumber: stateData.ddcNumber,
								callNo: stateData.callNo,
								language: stateData.language,
								price: stateData.price,
								discount: stateData.discount,
								invoiceNumber: stateData.invoiceNumber,
								date: stateData.date,
								vendor: stateData.vendor,
								fund: stateData.fund,
								bookDetails: stateData.bookDetails,
								accessionNumber: stateData.accessionNumber,
								status: stateData.status,
								count: stateData.count,
								contents: stateData.contents,
								bookCoverfile: bookCover,
							}}
							enableReinitialize
							onSubmit={fnAddBook}>
							{(props) => (
								<form onSubmit={props.handleSubmit}>
									<div style={{ flexDirection: 'row', display: 'flex' }}>
										<div className={classNames.titlesSub} style={{ width: 400 }}>
											<div style={{}}>
												<div className={classNames.group} style={{ width: 380 }}>
													<input
														type='text'
														onKeyPress={(e) => {
															e.which === 13 && e.preventDefault();
														}}
														onChange={(e) => setStateData({ ...stateData, isbn: e.target.value })}
														onFocus={() => setShowIsbnList(true)}
														value={stateData.isbn}
													/>
													<label>ISBN</label>
												</div>
												<div ref={wrapperRef2}>
													{showIsbnList && stateData?.isbn && titlesDropDowns.length ? (
														<List
															size='small'
															style={{ margin: 0, padding: 0 }}
															dataSource={titlesDropDowns}
															renderItem={(item) => (
																<List.Item>
																	<div
																		style={{ cursor: 'pointer', width: '100%', height: 20, fontSize: '12px', lineHeight: '12px', color: 'green' }}
																		onClick={() => {
																			setStateData({ ...stateData, isbn: item.isbn });
																			handleISBNSearch({ bookId: item._id });
																		}}>
																		{item?.isbn}
																	</div>
																</List.Item>
															)}
														/>
													) : null}
												</div>
											</div>
											<div style={{}}>
												<div className={classNames.group} style={{ width: 380 }}>
													<input
														type='text'
														onKeyPress={(e) => {
															e.which === 13 && e.preventDefault();
														}}
														onChange={(e) => setStateData({ ...stateData, nameOfBook: e.target.value })}
														onFocus={() => setShowList(true)}
														value={stateData.nameOfBook}
													/>
													<label>Title of the Book</label>
												</div>
												<div ref={wrapperRef}>
													{showList && stateData?.nameOfBook && titlesDropDowns?.filter((data) => data?.nameOfBook?.toLowerCase().includes(stateData?.nameOfBook?.toLowerCase())).length ? (
														<List
															size='small'
															style={{ margin: 0, padding: 0 }}
															dataSource={titlesDropDowns?.filter((data) => data?.nameOfBook?.toLowerCase().includes(stateData?.nameOfBook?.toLowerCase())).slice(0, 12)}
															renderItem={(item) => (
																<List.Item>
																	<div
																		style={{ cursor: 'pointer', width: '100%', fontWeight: '600', fontSize: '12px', lineHeight: '12px', color: 'green' }}
																		onClick={() => {
																			setStateData({ ...stateData, nameOfBook: item.nameOfBook });
																			handleISBNSearch({ bookId: item._id });
																		}}>
																		{item?.nameOfBook + ',' + (item?.nameOfAuthor ? item?.nameOfAuthor : '') + ' [' + (item?.volume ? item?.volume : '') + '] [' + (item?.edition ? item?.edition : '') + ']'}
																	</div>
																</List.Item>
															)}
														/>
													) : null}
												</div>
											</div>
											<div style={{ flexDirection: 'row', display: 'flex' }}>
												<div style={{ width: 300 }}>
													<SearchableImput keyData={'nameOfAuthor'} titleData={'First Author'} collection={'books'} stateData={stateData} setStateData={setStateData} />
												</div>
												<div style={{ width: 150 }}>
													<SearchableImput keyData={'authorType'} titleData={'Type'} collection={'books'} stateData={stateData} setStateData={setStateData} />
												</div>
											</div>
											<div style={{ flexDirection: 'row', display: 'flex' }}>
												<div style={{ width: 300 }}>
													<SearchableImput keyData={'nameOfAuthor2'} titleData={'Second Author'} collection={'books'} stateData={stateData} setStateData={setStateData} />
												</div>
												<div style={{ width: 150 }}>
													<SearchableImput keyData={'authorType2'} titleData={'Type'} collection={'books'} stateData={stateData} setStateData={setStateData} />
												</div>
											</div>
											<div style={{ flexDirection: 'row', display: 'flex' }}>
												<div style={{ width: 300 }}>
													<SearchableImput keyData={'gender'} titleData={'Third Author'} collection={'users'} stateData={stateData} setStateData={setStateData} />
												</div>
												<div style={{ width: 150 }}>
													<SearchableImput keyData={'authorType3'} titleData={'Type'} collection={'books'} stateData={stateData} setStateData={setStateData} />
												</div>
											</div>
											<div className={classNames.group}>
												<Select
													showSearch
													className={classNames.formFullWidth2}
													style={{ overflow: 'hidden' }}
													value={stateData?.subject}
													onChange={(val) => {
														setStateData({ ...stateData, subject: val });
													}}>
													{subjects?.map((item, i) => (
														<Option key={i} value={item}>
															<div>{item}</div>
														</Option>
													))}
												</Select>
												<label>Subject</label>
											</div>

											<div className={classNames.group}>
												<Select
													showSearch
													className={classNames.formFullWidth2}
													style={{ overflow: 'hidden' }}
													value={stateData?.ddcSubject}
													onChange={(val) => {
														setStateData({ ...stateData, ddcSubject: val, ddcNumber: ddcDrops?.find((x) => x?.ddcSubject === val)?.ddcNumber, callNo: ddcDrops?.find((x) => x?.ddcSubject === val)?.ddcNumber + ' ' + stateData?.nameOfAuthor?.toUpperCase()?.slice(0, 3) + '/' + stateData?.nameOfBook?.toUpperCase()?.slice(0, 1) });
													}}>
													{ddcDrops?.map((item, i) => (
														<Option key={i} value={item?.ddcSubject}>
															{item?.ddcNumber + ' - ' + item?.ddcSubject}
														</Option>
													))}
												</Select>
												<label>DDC subject</label>
											</div>
											<SearchableImput keyData={'nameOfPublisher'} titleData={'Name of Publisher'} collection={'books'} stateData={stateData} setStateData={setStateData} />
											<SearchableImput keyData={'placeOfPublication'} titleData={'Place of Publication'} collection={'books'} stateData={stateData} setStateData={setStateData} />
											<div className={classNames.group}>
												<Field
													type='number'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.yearOfPublication}
													onChange={(e) => setStateData({ ...stateData, yearOfPublication: e.target.value })}
												/>
												<label>Year of Publication</label>
											</div>
										</div>

										<div className={classNames.titlesSub} style={{ width: 250 }}>
											<div className={classNames.group}>
												<Field
													type='text'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.callNo}
													onChange={(e) => setStateData({ ...stateData, callNo: e.target.value })}
												/>
												<label>Call Number</label>
											</div>

											<div className={classNames.group}>
												<Field
													type='text'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.edition}
													onChange={(e) => setStateData({ ...stateData, edition: e.target.value })}
												/>
												<label>Edition</label>
											</div>

											<div className={classNames.group}>
												<Field
													type='text'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.volume}
													onChange={(e) => setStateData({ ...stateData, volume: e.target.value })}
												/>
												<label>Volume</label>
											</div>

											<div className={classNames.group}>
												<Field
													type='text'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.series}
													onChange={(e) => setStateData({ ...stateData, series: e.target.value })}
												/>
												<label>Series</label>
											</div>

											<div className={classNames.group}>
												<Field
													type='number'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.pages}
													onChange={(e) => setStateData({ ...stateData, pages: e.target.value })}
												/>
												<label>Pages</label>
											</div>

											<div className={classNames.group}>
												<Field
													type='number'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.price}
													onChange={(e) => setStateData({ ...stateData, price: e.target.value })}
												/>
												<label>Actual Price</label>
											</div>
											<div className={classNames.group}>
												<Field
													type='number'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.discount}
													onChange={(e) => setStateData({ ...stateData, discount: e.target.value })}
												/>
												<label>Discount Price</label>
											</div>
											<div className={classNames.group}>
												<Field
													type='text'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.invoiceNumber}
													onChange={(e) => setStateData({ ...stateData, invoiceNumber: e.target.value })}
												/>
												<label>Invoice Number</label>
											</div>
											<div className={classNames.group}>
												<Field
													type='date'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.date}
													onChange={(e) => setStateData({ ...stateData, date: e.target.value })}
												/>
												<label>Invoice Date</label>
											</div>
											<SearchableImput keyData={'vendor'} titleData={'Vendor'} collection={'bookStocks'} stateData={stateData} setStateData={setStateData} />
											<SearchableImput keyData={'fund'} titleData={'Fund'} collection={'bookStocks'} stateData={stateData} setStateData={setStateData} />
										</div>

										<div className={classNames.titlesSub} style={{ width: 230 }}>
											<SearchableImput keyData={'category'} titleData={'Category'} collection={'bookStocks'} stateData={stateData} setStateData={setStateData} />
											<SearchableImput keyData={'language'} titleData={'Language'} collection={'books'} stateData={stateData} setStateData={setStateData} />
											<div className={classNames.group} style={{ width: 200 }}>
												<Field
													type='number'
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													value={stateData?.accessionNumber}
													onChange={(e) => setStateData({ ...stateData, accessionNumber: e.target.value })}
												/>

												<label>Accession Number</label>
											</div>

											<div className={classNames.group} style={{ width: 200 }}>
												<Field
													type='number'
													value={stateData?.count}
													onKeyPress={(e) => {
														e.which === 13 && e.preventDefault();
													}}
													onChange={(e) => setStateData({ ...stateData, count: e.target.value })}
												/>
												<label>Stock</label>
											</div>

											<SearchableImput keyData={'status'} titleData={'Status'} collection={'bookStocks'} stateData={stateData} setStateData={setStateData} />

											<div className={classNames.group} style={{ width: 200, height: 210 }}>
												<label style={{ zIndex: 122 }}>Contents</label>
												<textarea value={stateData?.contents} style={{ width: 200, height: 210, zIndex: 99 }} onChange={(e) => setStateData({ ...stateData, contents: e.target.value })} />
											</div>

											<div>
												<div className={classNames.group} style={{ width: 200, height: 70 }}>
													<label>Remarks</label>
													<textarea value={stateData?.bookDetails} onChange={(e) => setStateData({ ...stateData, bookDetails: e.target.value })} />
												</div>
											</div>
										</div>

										<div className={classNames.titlesSub} style={{ width: 150 }}>
											<label className={classNames.imgWrap}>
												<img src={props.values.bookCoverfile ? (typeof props.values.bookCoverfile === 'string' ? props.values.bookCoverfile : URL.createObjectURL(props.values.bookCoverfile)) : bookCoverPlaceholder} alt='' />
												<input
													type='file'
													accept='image/*'
													name='bookCoverfile'
													onChange={(event) => {
														setbookCover(event.currentTarget.files[0]);
														props.setFieldValue('bookCoverfile', event.currentTarget.files[0]);
													}}
												/>
											</label>
											<div style={{ width: 260, height: 200 }}>
												<button type='submit' className={classNames.add}>
													Add Book
												</button>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</Scrollbars>
				</div>
			)}
			{addModal && (
				<AddModalView
					title={addModalValue}
					onClose={() => setAddModal(false)}
					onExport={(values, title) => {
						dropsMutate({ values, title });
					}}
				/>
			)}
		</>
	);
}

export default AddBook;
