import SignUpCollege from '../../components/SignUpCollege';

const Register = () => {
	return (
		<div>
			<SignUpCollege />
		</div>
	);
};
export default Register;
