import React, { useState } from 'react';
import { Body, Header, Main } from '../../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import Button from '../../../NecttosComp/Button/Button';

function Withdraw({ userData, onClose }: { userData: any[]; onClose: () => void }) {
	const [data, setData] = useState(userData);
	console.log(userData);
	return (
		<Main height='80vh' width='80vw'>
			<Header>
				<Button type='close' onClick={onClose}>
					Close
				</Button>
			</Header>
			<Body>
				<Table width='100%' innerWidth={['5%', '25%', '10%', '20%', '10%', '10%', '10%', '10%']}>
					<Thead>
						<Tr>
							<Th position={0} fontSize='12px'>
								Sl.No.
							</Th>
							<Th position={1}>Name</Th>
							<Th position={2}>Adm.No.</Th>
							<Th position={3}>Class</Th>
							<Th position={4}>Total Due</Th>
							<Th position={5} width='20%'>
								Sanction Amount
							</Th>
							<Th position={7}>Balance</Th>
						</Tr>
						<Tr>
							<Th position={0}> </Th>
							<Th position={1}> </Th>
							<Th position={2}> </Th>
							<Th position={3}> </Th>
							<Th position={4}> </Th>
							<Th position={5} fontSize='12px'>
								Student reimbursement
							</Th>
							<Th position={6} fontSize='12px'>
								College reimbursement
							</Th>
							<Th position={7}> </Th>
						</Tr>
					</Thead>
					<Tbody height='60vh'>
						{data?.map((x, i) => (
							<Tr>
								<Td position={0} index={i}>
									{i + 1}
								</Td>
								<Td position={1} index={i}>
									{x?.name}
								</Td>
								<Td index={i} position={2}>
									{x?.admisionNo}
								</Td>
								<Td index={i} position={3}>
									{x?.batchName + ' ' + x?.shortName}
								</Td>
								<Td index={i} position={4}>
									100000
								</Td>
								<Td index={i} position={5}>
									{x?.eGrantz?.sanctionedAmount}
								</Td>
								<Td index={i} position={6}>
									{x?.eGrantz?.sanctionedAmount}
								</Td>
								<Td index={i} position={7}>
									{100000 - parseInt(x?.eGrantz?.sanctionedAmount)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Body>
		</Main>
	);
}

export default Withdraw;
