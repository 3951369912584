import React, { useContext, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import moment from "moment";

const getExaminationDetails = (collegeId: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/examProcess/getExamInit", {
      params: { collegeId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function ExamNotification({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const [selectedBatchSem, setSelectedBatchSem] = useState<any>([]);

  const { data: tableData = [], refetch } = useQuery("getExamInit", getExaminationDetails(collegeId));

  const fields = [
    { id: "attendanceClosingDate", label: "Attendance Closing Date" },
    { id: "examNotificationDate", label: "Examination Notification Date" },
    { id: "condonationFeeDate", label: "Condonation with Fee Apply Date" },
    { id: "condonationLateFeeDate", label: "Condonation with Late Fee Apply Date" },
    { id: "examRegistrationStartDate", label: "Examination Registration Start Date" },
    { id: "registrationFineDate", label: "Examination Registration with Fine Date" },
    { id: "registrationSuperFineDate", label: "Examination Registration with Super Fine Date" },
    { id: "timeTableDistributionDate", label: "Time Table Distribution Date" },
    { id: "hallTicketDistributionDate", label: "Hall Ticket Distribution Date" },
  ];

  const addNewForm = async (mode?: any) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postExamInit", { ...addNew, mode, userId: user.uid, collegeId, batches: selectedBatchSem })
        .then(() => {
          setAddNew({});
          refetch();
          closeAnimation();
        })
        .catch((err: any) => {
          console.error(err);
        });
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const [formData, setFormData] = useState<any>({
    attendanceClosingDate: "",
    examNotificationDate: "",
    condonationFeeDate: "",
    condonationLateFeeDate: "",
    examRegistrationStartDate: "",
    registrationFineDate: "",
    registrationSuperFineDate: "",
    timeTableDistributionDate: "",
    hallTicketDistributionDate: "",
    description: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Save or process the formData here
    console.log("Form Data Submitted: ", formData);
  };

  const batch_array: any[] = ["2024-2028:S1", "2024-2026:S1", "2024-2029:S1", "2024-2027:S1"];

  const removeSubject = (subject: any) => {
    setSelectedBatchSem(selectedBatchSem.filter((s: any) => s !== subject));
  };
  const handleSubjectChange = (event: any) => {
    const value = event.target.value;
    if (!selectedBatchSem?.includes(value)) {
      setSelectedBatchSem([...(selectedBatchSem || []), value]);
    }
  };

  return (
    <>
      <Main title="Exam Notification" width="100vw" height="100vw">
        <Header>
          <div></div>
          <div className="flex">
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["5%", "200px", "230px", "15%", "10%", "10%"]}>
              <Thead>
                <Tr>
                  <Th position={1}>SN</Th>
                  <Th position={1}>Discription</Th>
                  <Th position={1}>Batches</Th>
                  <Th position={1}>Examination Notification Date</Th>
                  <Th position={2}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {tableData?.map((item: any, i: number) => (
                  <Tr key={item?._id}>
                    <Td index={i} position={1}>
                      {i + 1}
                    </Td>

                    <Td index={i} position={1}>
                      {item?.discription}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.batches?.join(", ")}
                    </Td>

                    <Td index={i} position={1}>
                      {moment(item?.examNotificationDate).format("DD-MM-YYYY HH:MM")}
                    </Td>

                    <Td index={i} position={2}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            setFormData(item);
                            setShowForm(true);
                          }}
                        >
                          Modify Notification
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main title="Exam Initiation" width="40vw">
          <Header>
            <div></div>
            <div className="flex">
              <Button type="print" tableRef={ref2.current}>
                Print
              </Button>
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setShowForm(false), 500);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div ref={ref2} className="bg-gray-100 p-6 rounded-lg shadow-md max-w-4xl mx-auto my-10">
              <header className="text-center mb-8">
                <h1 className="text-2xl font-bold">Nirmala College, Muvattupuzha (Autonomous)</h1>
                <p>Kolenchery Road, Muvattupuzha, Kerala 686661</p>
                <p>Phone: 0485 283 2225</p>
                <p>
                  Website:{" "}
                  <a href="http://www.nirmalacollege.ac.in" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    www.nirmalacollege.ac.in
                  </a>
                </p>
              </header>
              <section>
                <h2 className="text-xl font-bold mb-4">Examination Notification for {formData.batches.join(",  ")}</h2>

                <p className="mb-4">Dear Students,</p>
                <p className="mb-4">Please find below the important dates and deadlines for the upcoming examination. Make sure to adhere to these dates to avoid any inconvenience.</p>
                <table className="min-w-full mb-4 border">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="py-2 px-4 border">Event</th>
                      <th className="py-2 px-4 border">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((field) => (
                      <tr key={field.id}>
                        <td className="py-2 px-4 border bg-white font-medium">{field.label}</td>
                        <td className="py-2 px-4 bg-white border text-center">{moment(formData[field.id]).format("DD-MM-YYYY hh:mm A")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Description</label>
                  <p className="p-2 border rounded bg-gray-200">{formData.discription}</p>
                </div>
                <section className="mb-4">
                  <h2 className="text-lg font-bold mb-2">Contact Details:</h2>
                  <p className="mb-1">Examination Office: Room No. 101</p>
                  <p className="mb-1">Email: examoffice@nirmalacollege.ac.in</p>
                  <p className="mb-1">Phone: 0485 283 2225</p>
                </section>
                <section className="mb-4">
                  <h2 className="text-lg font-bold mb-2">Instructions:</h2>
                  <ol className="list-decimal ml-5">
                    <li>Submit your examination forms and fee before the deadlines.</li>
                    <li>Collect your hall tickets from the examination office as per the schedule.</li>
                    <li>Follow the time table distributed for the examination dates.</li>
                    <li>Ensure that your attendance is complete and up-to-date before the closing date.</li>
                  </ol>
                </section>
                <p className="mt-6">We wish you all the best for your examinations.</p>
              </section>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default ExamNotification;
