import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import FYUGPRegister from "./FYUGPRegister";
import FYUGPM from "./FYUGPM";
import Cookies from "js-cookie";

const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get(
      "/college/admissionProcess/getCollegeDetails",
      {
        params: { collegeId },
      }
    );

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

const FYUGPM1: React.FC = () => {
  const [initCollegeDetails] = useState({
    collegeId: "",
    bashBordLink: "",
  });

  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const { data: collegeData } = useQuery(
    [`collegeData`, initCollegeDetails?.collegeId],
    getCollegeDetails
  );

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };
    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("userToken");
    const collegeId = localStorage.getItem("collegeId");

    if (token) setLogin(true);
    else setLogin(false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove('userToken');
    window.location.reload();
  };

  return !internet ? (
    <div
      className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans"
      style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}
    >
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Necttos!
    </div>
  ) : successLogin ? (
    <>
      <FYUGPM handleLogout={handleLogout} />
    </>
  ) : (
    <div className="flex flex-col justify-center items-center lg:w-full lg:h-screen bg-no-repeat bg-cover bg-center">
      <FYUGPRegister collegeData={collegeData} setLogin={setLogin} />
    </div>
  );
};

export default FYUGPM1;
