import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getNumbers } from '../../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import CustomInput from '../../../myComp/CustomInput';

export const Numbers = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	let { data = [], refetch } = useQuery('getNumbers', getNumbers(collegeId));
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState({});

	const manageHolidays = async () => {
		try {
			setIsLoading(true);

			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postNumber', {
				_id: state?._id || '',
				collection: state?.collection || '',
				number: state?.number || '',
				type: state?.type || '',
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					_id: '',
					collection: '',
					number: '',
					type: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	data = data?.sort((a, b) => a?.name?.localeCompare(b?.name));

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<>
							<div className={classNames.list}>
								{state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}

								<label className={classNames.heading}>{state?.type?.toUpperCase()}</label>

								<CustomInput keyData={'number'} titleData={'Edit Number'} typeData={'number'} stateData={state} setStateData={setState} />

								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												_id: '',
												collection: '',
												number: '',
												type: '',
											});
										}}>
										{' '}
										Cancel{' '}
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											manageHolidays();
										}}>
										Submit
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classNames.listContainer2}>
					<Scrollbars>
						<div ref={componentRef} className='printable' style={{ overflow: 'scroll' }}>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Continuation Number Management</h5>
								<div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Type of Fees</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '20%' }}>Next Number</h5>

									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '7%' }}></h5>
								</div>
								{data?.map((item, i) => (
									<>
										<div style={{ width: '100%', overflow: 'scroll', paddingRight: 12, display: 'flex', minHeight: '70px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>

											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 30, width: '30%' }}>{item.type.toUpperCase()}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 30, width: '20%' }}>{item.number}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>

											<Button
												className={classNames.submitBotton}
												onClick={() => {
													setState({
														...state,
														_id: item?._id || '',
														collection: item?.collection || '',
														number: item?.number || '',
														type: item?.type || '',
													});
												}}>
												Edit
											</Button>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												height: 1,
												backgroundColor: 'black',
											}}></div>
									</>
								))}
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>

			<Button
				className={classNames.reloadButton}
				onClick={() => {
					refetch();
				}}>
				Reload
			</Button>

			<ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />

			{!isLoading && (
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			)}
		</div>
	);
};
