import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { SyncLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

const getAdmissionDetails = (collegeId) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fyugp/getAllotementStatus', { params: { collegeId } });
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

function FYUGPDashBoard({ onClose }) {
	const { collegeId } = useContext(FirebaseContext);
	const [searchQuery, setSearchQuery] = useState('');

	const { data, refetch, status } = useQuery('getAdmissionDetails', getAdmissionDetails(collegeId));

	useEffect(() => {
		let interval = null;
		interval = setInterval(() => {
			refetch();
		}, 10000);
		return () => clearInterval(interval);
	}, [collegeId]);

	const override = css`
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	`;

	const Loader = () => (
		<div style={{ display: 'flex', height: '100vh', width: '100%' }}>
			<SyncLoader color='#3498db' css={override} size={15} />
		</div>
	);

	const filteredAdmissionDetails = data?.admissionDetails?.filter((item) => item?.subjectName?.toLowerCase().includes(searchQuery.toLowerCase()));

	return (
		<Main width='100%' height='100%' title='FYUGP Dashboard'>
			<Header className='bg-gray-50 shadow-sm w-full'>
				<div className='flex justify-between items-center p-4 w-[50%]'>
					<input type='text' placeholder='Search by Subject Name...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className='border border-gray-300 rounded-md p-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500' />
				</div>

				<div className='flex justify-between items-center p-4'>
					<Button onClick={onClose} type='close'>
						Close
					</Button>
				</div>
			</Header>
			<Body className='flex-grow'>
				<div className='flex flex-col items-center p-6 w-full h-full'>
					{status === 'loading' ? (
						<Loader />
					) : (
						<div className='w-full h-full bg-white rounded-xl shadow-lg p-8 overflow-scroll'>
							<div className='mb-8'>
								<div className='flex items-center mb-6'>
									<img src={data?.collegeData?.dashBoardLink} alt='College Logo' className='w-[440PX] h-24' />
									<div className='flex-1 text-center'>
										<h2 className='text-5xl font-extrabold text-gray-800'>{data?.collegeName?.[0]?.collegeName}</h2>
										<h3 className='text-3xl text-gray-600'>FYUGP Allotment & Seat Confirmation Dashboard 2024</h3>
									</div>
								</div>
								<div className='flex justify-between mb-6'>
									<div className='flex-1 bg-green-900 rounded-lg m-2 p-8 text-center'>
										<h4 className='text-white text-xl font-semibold'>Total Number of Students</h4>
										<h2 className='text-white text-4xl font-bold'>{data?.totals?.inTakeAll || 0}</h2>
									</div>
									<div className='flex-1 bg-blue-900 rounded-lg m-2 p-8 text-center'>
										<h4 className='text-white text-xl font-semibold'>Total Confirmed Students</h4>
										<h2 className='text-white text-4xl font-bold'>{data?.totals?.inAdmittedAll || 0}</h2>
									</div>
									<div className='flex-1 bg-yellow-900 rounded-lg m-2 p-8 text-center'>
										<h4 className='text-white text-xl font-semibold'>Total Remaining Students</h4>
										<h2 className='text-white text-4xl font-bold'>{data?.totals?.inBalanceAll || 0}</h2>
									</div>
								</div>
							</div>

							{/* Minor Sections */}
							{['Minor 1 (DSC_B)', 'Minor 2 (DSC_C)', 'MDC'].map((minor, index) => {
								const minorData = {
									'Minor 1 (DSC_B)': {
										inTakeAll: data?.totals?.bInTakeAll,
										inAdmittedAll: data?.totals?.bInAdmittedAll,
										inBalanceAll: data?.totals?.bInBalanceAll,
									},
									'Minor 2 (DSC_C)': {
										inTakeAll: data?.totals?.cInTakeAll,
										inAdmittedAll: data?.totals?.cInAdmittedAll,
										inBalanceAll: data?.totals?.cInBalanceAll,
									},
									MDC: {
										inTakeAll: data?.totals?.mdcInTakeAll,
										inAdmittedAll: data?.totals?.mdcInAdmittedAll,
										inBalanceAll: data?.totals?.mdcInBalanceAll,
									},
								};

								return (
									<div key={index} className='mb-8'>
										<div className='flex items-center mb-4'>
											<div className='flex-1 text-center'>
												<h3 className='text-3xl font-semibold text-gray-800'>{minor}</h3>
											</div>
										</div>
										<div className='flex justify-between mb-6'>
											<div className='flex-1 bg-green-800 rounded-lg m-2 p-8 text-center'>
												<h4 className='text-white text-xl font-semibold'>Total Students</h4>
												<h2 className='text-white text-4xl font-bold'>{minorData[minor].inTakeAll || 0}</h2>
											</div>
											<div className='flex-1 bg-blue-800 rounded-lg m-2 p-8 text-center'>
												<h4 className='text-white text-xl font-semibold'>Confirmed Students</h4>
												<h2 className='text-white text-4xl font-bold'>{minorData[minor].inAdmittedAll || 0}</h2>
											</div>
											<div className='flex-1 bg-yellow-800 rounded-lg m-2 p-8 text-center'>
												<h4 className='text-white text-xl font-semibold'>Remaining Students</h4>
												<h2 className='text-white text-4xl font-bold'>{minorData[minor].inBalanceAll || 0}</h2>
											</div>
										</div>
									</div>
								);
							})}

							<h5 className='text-xl text-gray-700 text-center mb-8'>Last Updated: {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
							<div>
								{filteredAdmissionDetails?.map((item, i) => (
									<div key={i} className='bg-gray-100 rounded-lg mb-6 p-8'>
										<h2 className='text-gray-800 text-center text-4xl mb-6'>{item?.subjectName}</h2>
										<div className='grid grid-cols-1 md:grid-cols-4 gap-6'>
											<div className='flex flex-col items-center'>
												<div className='text-xl text-gray-600 mb-1'>Total Seats</div>
												<div className='text-3xl text-gray-800 font-bold'>{item?.inTake || '--'}</div>
											</div>
											<div className='flex flex-col items-center'>
												<div className='text-xl text-green-600 mb-1'>Confirmed</div>
												<div className='text-3xl text-gray-800 font-bold'>{item?.inAdmitted || '--'}</div>
											</div>
											<div className='flex flex-col items-center'>
												<div className='text-xl text-red-600 mb-1'>Balance Seats</div>
												<div className='text-3xl text-gray-800 font-bold'>{(item?.inTake || 0) - (item?.inAdmitted || 0)}</div>
											</div>
											<div className='flex flex-col items-center'>
												<div className='text-xl text-green-600 mb-1'>Allotment Received</div>
												<div className='text-3xl text-gray-800 font-bold'>{item?.alloted || '--'}</div>
											</div>
										</div>
										<h3 className='text-2xl text-gray-800 text-center mt-4 mb-4'>Available Seats</h3>
										<div className='grid grid-cols-1 md:grid-cols-8 gap-4 bg-gray-200 p-6 rounded-lg'>
											{data?.keys?.map((keyitem, index) =>
												item[keyitem]?.inTake ? (
													<div key={index} className='rounded-lg bg-white shadow p-6'>
														<div className='text-xl font-semibold text-gray-800 mb-2'>
															{keyitem}: {item[keyitem]?.inTake || 0}
														</div>
														<div className='text-xl font-semibold text-green-600 mb-2'>Seats Confirmed: {item[keyitem]?.inAdmitted || 0}</div>
														<div className='text-sm font-semibold text-red-600'>Allotment Received: {parseInt(item[keyitem]?.allotementRecieved || 0) || 0}</div>
														<div className='text-xs font-semibold text-gray-500 mt-2'>Last Index: {item[keyitem]?.inLastIndex?.toFixed(3) || '--'}</div>
													</div>
												) : null,
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</Body>
		</Main>
	);
}

export default FYUGPDashBoard;
