import React, { useState } from "react";
import classNames from "./detailView.module.scss";
import { Button } from "antd";
import Scrollbars from "react-custom-scrollbars";

function ViewCertificates({ data = [], priotiy = [], onClose }) {
  const [finalList, setFinalList] = useState([]);

  console.log({ priotiy });
  const bonusCertificate = ["class_12_marksheet", "degree_marksheet", "ex_military_certificate", "ncc_a_certificate", "ncc_b_certificate", "nss_certificate", "scouts_guides_certificate", "student_police_cadet_certificate", "class_10_marksheet", "income_certificate", "community_caste_certificate", "sports_quota_proof", "cultural_quota_proof", "disability_quota_certificate", "lakshadweep_nomination_letter", "non_creamy_layer_certificate", "ews_certificate", "andaman_nicobar_quota_certificate", "third_gender_quota_certificate", "community_quota_certificate", "other_boards_eligibility_certificate"];
  const renderFilePreview = (fileURL, title) => {
    if (fileURL.endsWith(".pdf")) {
      return (
        <object data={fileURL} type="application/pdf" width="100%" height="800px">
          <iframe src={fileURL} width="100%" height="800px">
            <p>
              Your browser does not support PDFs. <a href={fileURL}>Download the PDF</a>.
            </p>
          </iframe>
        </object>
      );
    } else {
      return <img src={fileURL} alt={`Uploaded ${title}`} className="max-w-full rounded-md border border-gray-200" />;
    }
  };
  return (
    <div className={classNames.container}>
      <div className={classNames.modal} style={{ margin: 20 }}>
        <Scrollbars>
          <>
            <div className="flex flex-col lg:flex-row overflow-x-auto">
              <div className="flex-1  p-4 space-y-4">
                <div className="w-full bg-white shadow rounded p-4">
                  <h3 className="text-lg font-semibold mb-4">Certificate</h3>
                  {bonusCertificate?.map((certificate, index) => (
                    <React.Fragment key={index}>
                      {data?.[certificate] && (
                        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col  items-center space-y-3">
                          <label className="block text-sm font-medium text-gray-700"> {certificate} </label>
                          {data?.[certificate + "URL"] && renderFilePreview(data?.[certificate + "URL"], certificate)}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row overflow-x-auto"></div>
          </>
        </Scrollbars>
      </div>
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
}

export default ViewCertificates;
