import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select, Table } from 'antd';
import { Field, Formik } from 'formik';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getReports, getReportsSum } from '../../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Alert from 'react-popup-alert';
import { useAccountsHeads } from '../../../NecttosComp/APICaller/queryHooks';
import Scrollbars from 'react-custom-scrollbars';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

export const exportPdfCollectionReport = async ({ postData, setLoading, setAlert }) => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, postData, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (e) {
		throw new Error(
			setLoading(false),
			setAlert({
				type: 'Message',
				text: 'Chack Date / Time / Main account / Sub account' + e,
				show: true,
			}),
		);
	}
};

export const CashBankReports = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = React.useState({
		startDate: '',
		endDate: '',
		startTime: '',
		endTime: '',
		mainAccount: '',
		subAccount: '',
	});
	const [alert, setAlert] = React.useState({
		type: 'error',
		text: 'This is a alert message',
		show: false,
	});
	const accounts = useAccountsHeads();

	const onCloseAlert = () => {
		setAlert({
			type: '',
			text: '',
			show: false,
		});
	};
	let quaryData = {
		startDate: state?.startDate,
		endDate: state?.endDate,
		startTime: state?.startTime,
		endTime: state?.endTime,
		mainAccount: state?.mainAccount || '',
		subAccount: state?.subAccount || '',
		method: state?.method || '',
		isHideBills: state?.check ? '$eq' : '$ne',
		collegeId,
	};
	const { data = [], isLoading, refetch } = useQuery(['getReports', state?.startDate, state?.endDate, state?.startTime, state?.endTime, state?.mainAccount, state?.subAccount, state?.method, state.check], getReportsSum(quaryData));

	const [loading, setLoading] = useState(false);

	let subAccount = accounts?.data?.subAccounts || [];
	let mainAccount = accounts?.data?.mainAccounts || [];
	if (state?.mainAccount) {
		subAccount = subAccount.filter((x) => x.mainId === state?.mainAccount);
	}
	const { Option } = Select;

	const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

	const onDateCollectionReport = async () => {
		setLoading(true);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				startDate: state?.startDate,
				endDate: state?.endDate,
				startTime: state?.startTime,
				endTime: state?.endTime,
				mainAccount: state?.mainAccount || '',
				subAccount: state?.subAccount || '',
				method: state?.method || '',
				isHideBills: state?.check ? '$eq' : '$ne',
				collegeId,
			};

			link.download = state?.endDate;
			const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData, setLoading, setAlert });
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
			setLoading(false);
		} catch (e) {}
	};

	const columns = [
		{
			title: 'Reciept No',
			dataIndex: 'billNo',
			key: 'billNo',
			columnWidth: '15%',
		},
		{
			title: 'Admn No',
			dataIndex: 'admisionNo',
			key: 'admisionNo',
			columnWidth: '15%',
		},
		{
			title: 'Student',
			dataIndex: 'name',
			key: 'name',
			columnWidth: '15%',
		},
		{
			title: 'Class',
			dataIndex: 'className',
			key: 'className',
			columnWidth: '5%',
		},

		...(state?.method === 'Online'
			? [
					{
						title: 'Bank Account',
						dataIndex: 'recievedBank',
						key: 'recievedBank',
						columnWidth: '15%',
					},
					{
						title: 'Transaction Id',
						dataIndex: 'transactionId',
						key: 'transactionId',
						columnWidth: '15%',
					},
					{
						title: 'Account Holder Name',
						dataIndex: 'accountHolderName',
						key: 'accountHolderName',
						columnWidth: '15%',
					},
					{
						title: 'Transaction Date',
						dataIndex: 'transactionDate',
						key: 'transactionDate',
						columnWidth: '15%',
					},
			  ]
			: [
					{
						title: 'Where',
						dataIndex: 'recievedBank',
						key: 'recievedBank',
						columnWidth: '15%',
					},
			  ]),
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			columnWidth: '15%',
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			columnWidth: '15%',
		},
		{
			title: 'Refund On',
			dataIndex: 'refundOn',
			key: 'refundOn',
			columnWidth: '15%',
		},
		{
			title: 'Refund Amount',
			dataIndex: 'refundAmount',
			key: 'refundAmount',
			columnWidth: '15%',
		},
		{
			title: 'Collected By',
			dataIndex: 'collectedBy',
			key: 'collectedBy',
			columnWidth: '15%',
		},
	];

	subAccount = subAccount.concat({ label: 'All', value: '' });
	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				{isLoading ? (
					<LoadingAnimation dark />
				) : !alert.show ? (
					<>
						<div className={classNames.listContainer2}>
							<Scrollbars>
								<table className={classNames.userTable} id='emp'>
									<Table dataSource={data.collections} pagination={false} columns={columns} />
								</table>
							</Scrollbars>
						</div>
						=
					</>
				) : (
					<Alert header={alert.type} btnText={'Close'} text={alert.text} type={alert.type} show={alert.show} onClosePress={onCloseAlert} pressCloseOnOutsideClick={true} showBorderBottom={true} alertStyles={{ height: 200, width: 800, margin: '50%', backgroundColor: 'red', borderRadius: 10 }} headerStyles={{ color: 'white', width: '100%', textAlign: 'center' }} textStyles={{ color: 'white', width: '100%', textAlign: 'center', fontSize: 20 }} buttonStyles={{ color: 'white', backgroundColor: 'green', padding: 20, borderRadius: 10, margin: '47%' }} />
				)}
			</div>

			<div className={classNames.absoluteDiv}>
				<label className={classNames.heading}>Collection Report</label>

				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Field
							type='date'
							name='startDate'
							id='startDate'
							value={state.startDate}
							onChange={(e) => {
								setState({ ...state, startDate: e.target.value });
							}}
						/>
						<label>Start Date</label>
					</div>
				</Formik>

				{/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="startTime"
              id="startTime"
              value={state.startTime}
              onChange={(e) => {
                setState({ ...state, startTime: e.target.value })
              }
              }
            />
            <label>Start Time</label>
          </div>
        </Formik> */}
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
						<Field
							type='date'
							name='endDate'
							id='endDate'
							value={state.endDate}
							onChange={(e) => {
								setState({ ...state, endDate: e.target.value });
							}}
						/>
						<label>End Date</label>
					</div>
				</Formik>

				{/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="endTime"
              id="endTime"
              value={state.endTime}
              onChange={(e) => {
                setState({ ...state, endTime: e.target.value })
              }
              }
            />
            <label>End Time</label>
          </div>
        </Formik> */}

				<div className={`${classNames.group} ${classNames.formFullWidth}`}>
					<Select
						showSearch
						id='from'
						className={classNames.formFullWidth2}
						style={{ overflow: 'hidden' }}
						onChange={(val) => {
							setState({ ...state, mainAccount: mainAccount?.find((x) => x.label === val).value });
						}}>
						{mainAccount?.map((item) => (
							<Option key={item.label} value={item.label}>
								{item.label}
							</Option>
						))}
					</Select>
					<label>Main Account</label>
				</div>
				<div className={`${classNames.group} ${classNames.formFullWidth}`}>
					<Select
						showSearch
						id='from'
						className={classNames.formFullWidth2}
						style={{ overflow: 'hidden' }}
						onChange={(val) => {
							setState({ ...state, subAccount: subAccount?.find((x) => x.label === val).value });
						}}>
						{subAccount?.map((item) => (
							<Option key={item.label} value={item.label}>
								{item.label}
							</Option>
						))}
					</Select>
					<label>Sub Account</label>
				</div>
				<div className={`${classNames.group} ${classNames.formFullWidth}`}>
					<Select
						showSearch
						className={classNames.formFullWidth2}
						style={{ overflow: 'hidden' }}
						onChange={(val) => {
							setState({ ...state, method: val });
						}}>
						{['Online', 'Normal']?.map((item) => (
							<Option key={item} value={item}>
								{item}
							</Option>
						))}
					</Select>
					<label>Methods</label>
				</div>
				<Button className={classNames.reloadButton} onClick={() => refetch()}>
					View
				</Button>
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						refetch();
					}}>
					<ReactHTMLTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='collectionReport' sheet='tablexls' buttonText='EXCEL' />
				</Button>
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<Button
						className={classNames.reloadButton}
						onClick={() => {
							onDateCollectionReport();
						}}>
						PDF
					</Button>
				)}
			</div>

			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
