import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Input, Select, Table } from 'antd';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getDDCSubjects } from '../../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import SearchableImput from '../AddBook/DropDownComponent';

export const DDCSubjects = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [searchTerm, setSearchTerm] = useState('');
	const { data = [], isFetching, refetch } = useQuery('getAllDDCSubjects', getDDCSubjects(collegeId));
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState({
		date: '',
		from: '',
		to: '',
		amount: '',
		naration: '',
	});

	const manageDDCSubject = async () => {
		try {
			setIsLoading(true);

			if (!state.ddcNumber) {
				throw new Error('DDC Number Field Is Empt');
			}
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postDDCSubjects', {
				collegeId,
				subject: state?.subject,
				ddcNumber: state?.ddcNumber,
				ddcSubject: state?.ddcSubject,
				mainDepartment: state?.mainDepartment,
				relativeDepartment: state?.relativeDepartment,
				_id: state?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({
					...state,
					ddcNumber: '',
					subject: '',
					ddcSubject: '',
					mainDepartment: '',
					relativeDepartment: '',
					_id: '',
					alert: '',
				});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	let filteredUserList = data;
	if (searchTerm) {
		filteredUserList = data.filter((user) => user.ddcNumber.toLowerCase().includes(searchTerm.toLowerCase()) || user.subject.toLowerCase().includes(searchTerm.toLowerCase()));
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<>
							<div className={classNames.list}>
								{state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}
								<SearchableImput keyData={'ddcNumber'} titleData={'Clasification No'} collection={'ddcSubjectsLibrary'} stateData={state} setStateData={setState} />
								<SearchableImput keyData={'subject'} titleData={'Subject'} collection={'ddcSubjectsLibrary'} stateData={state} setStateData={setState} />
								<SearchableImput keyData={'ddcSubject'} titleData={'Discription'} collection={'ddcSubjectsLibrary'} stateData={state} setStateData={setState} />
								<SearchableImput keyData={'mainDepartment'} titleData={'Main Department'} collection={'ddcSubjectsLibrary'} stateData={state} setStateData={setState} />
								<SearchableImput keyData={'relativeDepartment'} titleData={'Relative Department'} collection={'ddcSubjectsLibrary'} stateData={state} setStateData={setState} />

								<div style={{ height: '50px', width: '74%', alignSelf: 'center' }}>
									<Button
										className={classNames.cancelBotton}
										onClick={() => {
											setState({
												...state,
												ddcNumber: '',
												subject: '',
												ddcSubject: '',
												mainDepartment: '',
												relativeDepartment: '',
												_id: '',
												alert: '',
											});
										}}>
										{' '}
										Cancel{' '}
									</Button>
									<Button
										className={classNames.submitBotton}
										onClick={() => {
											manageDDCSubject();
										}}>
										Submit
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} className='printable'>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>DDC Management</h5>

								<div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '15%' }}>Class No</h5>
									<h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Subject</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '30%' }}>Description</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '30%' }}>Main Department</h5>
									<h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '30%' }}>Relative Depts</h5>
								</div>
								{filteredUserList?.map((item, i) => (
									<>
										<div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '15%' }}>{item.ddcNumber}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '30%' }}>{item.subject}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '30%' }}>{item.ddcSubject}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '30%' }}>{item.mainDepartment}</h5>
											<div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', fontSize: 19, backgroundColor: 'black' }}>{item.relativeDepartment}</div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '30%' }}>{}</h5>
											<Button
												className={classNames.submitBotton}
												onClick={() => {
													setState({
														...state,
														alert: `Edit as ${item.ddcNumber}`,
														ddcNumber: item.ddcNumber,
														subject: item.subject,
														ddcSubject: item.ddcSubject,
														relativeDepartment: item.relativeDepartment,
														mainDepartment: item.mainDepartment,
														_id: item._id,
													});
												}}>
												Edit
											</Button>
										</div>
										<div
											style={{
												width: '100%',
												display: 'flex',
												height: 1,
												backgroundColor: 'black',
											}}></div>
									</>
								))}
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>
			<Input style={{ position: 'absolute', top: 32, right: '28%', width: 500, height: 50, fontSize: 16, fontWeight: 900, borderRadius: 200 }} placeholder='Search by Class Number, Subject' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
			<Button
				className={classNames.reloadButton}
				onClick={() => {
					refetch();
				}}>
				Reload
			</Button>
			<ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />
			{!isLoading && (
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			)}
		</div>
	);
};
