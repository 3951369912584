import React, { useContext, useEffect, useState } from 'react';
import { CSVDownloader, CSVReader } from 'react-papaparse';
import { Main } from '../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import { MdDelete } from 'react-icons/md';
import { RiAddFill } from 'react-icons/ri';
import Input from '../../NecttosComp/Input/Input';
import * as Yup from 'yup';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useMutation, useQuery } from 'react-query';
import toast, { Toaster, closeToast } from 'react-hot-toast';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import Select from 'react-select';
import { getDepartments, getNewClasses } from '../../NecttosComp/APICaller/ApiServices';

export default function DataManagementSystem({ onClose }) {
	const students = [
		{ key: 'name', name: 'Full Name', tableHeads: 'Name', required: true },
		{ key: 'dob', name: 'Date of Birth', tableHeads: 'DOB', required: false },
		{ key: 'email', name: 'Email', tableHeads: 'Email', required: false },
		{ key: 'capId', name: 'CAP ID', tableHeads: 'CAP ID', required: false },
		{ key: 'gender', name: 'Gender', tableHeads: 'Gender', required: false },
	];
	const teachers = [
		{ key: 'name', name: 'Full Name', tableHeads: 'Name', required: true },
		{ key: 'dob', name: 'Date of Birth', tableHeads: 'DOB', required: false },
		{ key: 'email', name: 'Email', tableHeads: 'Email', required: false },
		{ key: 'gender', name: 'Gender', tableHeads: 'Gender', required: false },
	];
	const staff = [
		{ key: 'name', name: 'Full Name', tableHeads: 'Name', required: true },
		{ key: 'dob', name: 'Date of Birth', tableHeads: 'DOB', required: false },
		{ key: 'email', name: 'Email', tableHeads: 'Email', required: false },
		{ key: 'gender', name: 'Gender', tableHeads: 'Gender', required: false },
	];
	const scholars = [
		{ key: 'name', name: 'Full Name', tableHeads: 'Name', required: true },
		{ key: 'dob', name: 'Date of Birth', tableHeads: 'DOB', required: false },
		{ key: 'email', name: 'Email', tableHeads: 'Email', required: false },
		{ key: 'gender', name: 'Gender', tableHeads: 'Gender', required: false },
	];

	const [data, setData] = useState([]);
	const [validationErrors, setValidationErrors] = useState([]);
	const [fieldErrors, setFieldErrors] = useState();
	const [fetchedHeaders, setFetchedHeaders] = useState({ students: [], teachers: [], staff: [], scholars: [] });
	const [edit, setEdit] = useState(false);
	const [validate, setValidate] = useState(false);
	const [selectedHeaders, setSelectedHeaders] = useState(() => {
		const storedHeaders = localStorage.getItem('selectedHeaders');
		return storedHeaders ? JSON.parse(storedHeaders) : [];
	});
	const [isHeaderSelectionOpen, setIsHeaderSelectionOpen] = useState(false);
	const [headers, setHeaders] = useState([]);
	const [role, setRole] = useState(() => {
		const storedRole = localStorage.getItem('role');
		return storedRole ? JSON.parse(storedRole) : '';
	});
	const { collegeId, user } = useContext(FirebaseContext);
	const [selectedClass, setSelectClass] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [department, setDepartement] = useState('');

	const fetchData = async () => {
		const instance = await getAxiosTokenInstance();
		instance
			.get('/college/action/getHeadersDMS')
			.then((res) => {
				console.log(res.data.data);
				setFetchedHeaders(res?.data?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);
	const handleSubmit = async () => {
		const userList = transformData(data);
		console.log({ userList });
		const instance = await getAxiosTokenInstance();
		instance
			.post('/college/action/dataManagementSystem', { userList, collegeId, type: role, classId: selectedClass, departmentId: department })
			.then((res) => {
				console.log('response', res);
				setSuccessMessage(res.data.res.message);
				toast.success('added successfully');
				setData([]);
			})
			.catch((err) => {
				console.log(err);
				toast.error('error adding data');
			});
	};

	const transformData = (arrayOfArrays) => {
		const headers = arrayOfArrays[0];
		const dataRows = arrayOfArrays.slice(1);
		const arrayOfObjects = dataRows.map((row) => {
			const rowObject = {};
			headers.forEach((header, index) => {
				rowObject[header] = row[index] || null;
			});
			return rowObject;
		});

		return arrayOfObjects;
	};

	useEffect(() => {
		if (role === 'student') setHeaders(fetchedHeaders?.students);
		else if (role === 'teacher') setHeaders(fetchedHeaders?.teachers);
		else if (role === 'staff') setHeaders(fetchedHeaders?.staff);
		else if (role === 'scholars') setHeaders(fetchedHeaders?.scholars);
		else setHeaders([]);
	}, [role]);

	const buildValidationSchema = (headers) => {
		const shape = {};
		headers.forEach((header) => {
			let validator = Yup.string();
			if (header.required) {
				validator = validator.required(`${header.name} is required`);
			}
			shape[header.key] = validator;
		});
		return Yup.object().shape(shape);
	};

	const handleOnDrop = async (csvData) => {
		setValidate(false);
		const formattedData = csvData?.map((row) => row.data);
		console.log({ for: formattedData, csvData });
		const csvHeaders = formattedData[0];
		const csvDataRows = formattedData?.slice(1);
		const expectedHeaders = headers?.map((header) => header.key);
		const extraHeaders = csvHeaders?.filter((header) => !expectedHeaders?.includes(header));

		if (extraHeaders?.length > 0) {
			toast.error(`Invalid fields detected in CSV: ${extraHeaders.join(', ')}. Please ensure your CSV contains only the fields declared for the selected role.`);
			setFieldErrors(`Invalid fields detected in CSV: ${extraHeaders.join(', ')}. Please ensure your CSV contains only the fields declared for the selected role.`);
			setData(formattedData);
			return;
		}

		const requiredHeaders = headers?.filter((header) => header?.required).map((header) => header?.key);
		const missingRequiredHeaders = requiredHeaders?.filter((header) => !csvHeaders?.includes(header));

		if (missingRequiredHeaders?.length > 0) {
			toast.error(`Missing required fields in CSV: ${missingRequiredHeaders.join(', ')}. Please ensure your CSV contains all required fields for the selected role.`);
			setFieldErrors(`Missing required fields in CSV: ${missingRequiredHeaders.join(', ')}. Please ensure your CSV contains all required fields for the selected role.`);
			setData(formattedData);
			return;
		}

		const headerKeys = csvHeaders;
		const schema = buildValidationSchema(headers);
		const validationErrors = [];
		const validatedData = [];

		for (let i = 0; i < csvDataRows?.length; i++) {
			const row = csvDataRows[i];
			const rowData = {};
			headerKeys.forEach((key, index) => {
				rowData[key] = row[index];
			});
			console.log({ rowData });
			try {
				await schema.validate(rowData, { abortEarly: false });
				validatedData.push(row);
			} catch (err) {
				const errors = {};
				err.inner.forEach((validationError) => {
					errors[validationError.path] = validationError.message;
				});
				validationErrors.push({ row: i + 2, errors });
			}
		}

		if (validationErrors.length > 0) {
			setValidationErrors(validationErrors);
			console.log('Validation Errors:', validationErrors);
			toast.error('Validation failed. Please check your data.');
			setData(formattedData);
			setValidate(false);
			setEdit(true);
		} else {
			setFieldErrors('');
			setValidationErrors([]);
			setData([csvHeaders, ...validatedData]);
			setValidate(true);
			setEdit(false);
		}
	};

	const validateTable = async () => {
		setValidate(false);
		setFieldErrors('');
		setValidationErrors([]);
		const csvData = data;
		console.log({ csvData });
		const formattedData = csvData?.map((row) => row);
		console.log({ formattedData });
		const csvHeaders = formattedData[0];
		const csvDataRows = formattedData?.slice(1);
		const expectedHeaders = headers?.map((header) => header.key);
		const extraHeaders = csvHeaders?.filter((header) => !expectedHeaders?.includes(header));

		if (extraHeaders?.length > 0) {
			toast.error(`Invalid fields detected in CSV: ${extraHeaders.join(', ')}. Please ensure your CSV contains only the fields declared for the selected role.`);
			setValidationErrors([]);
			return;
		}
		const requiredHeaders = headers?.filter((header) => header?.required).map((header) => header?.key);
		const missingRequiredHeaders = requiredHeaders?.filter((header) => !csvHeaders?.includes(header));

		if (missingRequiredHeaders?.length > 0) {
			toast.error(`Missing required fields in CSV: ${missingRequiredHeaders.join(', ')}. Please ensure your CSV contains all required fields for the selected role.`);
			setValidationErrors([]);
			return;
		}
		const headerKeys = csvHeaders;
		const schema = buildValidationSchema(headers);
		const validationErrors = [];
		const validatedData = [];

		for (let i = 0; i < csvDataRows?.length; i++) {
			const row = csvDataRows[i];
			const rowData = {};
			headerKeys.forEach((key, index) => {
				rowData[key] = row[index];
			});
			console.log({ rowData });

			try {
				await schema.validate(rowData, { abortEarly: false });
				validatedData.push(row);
			} catch (err) {
				const errors = {};
				err.inner.forEach((validationError) => {
					errors[validationError.path] = validationError.message;
				});
				validationErrors.push({ row: i + 2, errors });
			}
		}

		if (validationErrors.length > 0) {
			setValidationErrors(validationErrors);
			console.log('Validation Errors:', validationErrors);
			toast.error('Validation failed. Please check your data.');
			setValidate(false);
			setEdit(true);
		} else {
			setValidationErrors([]);
			setData([csvHeaders, ...validatedData]);
			setValidate(true);
			setFieldErrors('');
			setEdit(false);
		}
	};

	const handleOnError = (err, file, inputElem, reason) => {
		console.log(err);
		toast.error(`Error parsing CSV file: ${reason}`);
	};

	const handleOnRemoveFile = () => {
		setData([]);
		setValidationErrors([]);
		setValidate(false);
	};

	const handleInputChange = (value, rowIndex, colIndex) => {
		setValidate(false);
		setData((prevData) => {
			const updatedData = [...prevData];
			updatedData[rowIndex][colIndex] = value;
			return updatedData;
		});
	};

	const handleAddRow = () => {
		if (data.length === 0) {
			setData([headers.map((header) => header.key)]);
		}
		const newRow = new Array(data[0].length).fill('');
		setData([...data, newRow]);
		setValidate(false);
	};

	const handleDeleteRow = (rowIndex) => {
		const updatedData = data.filter((_, index) => index !== rowIndex);
		setData(updatedData);
		setValidate(false);
	};

	const handleDeleteColumn = (colIndex) => {
		toast.custom('Are you sure');
		const updatedData = data.map((row) => row.filter((_, index) => index !== colIndex));
		setData(updatedData);
		setValidate(false);
	};
	const handleAddColumn = () => {
		const defaultHeader = `name`;
		const newHeader = [...data[0], defaultHeader];
		const updatedData = data.map((row, rowIndex) => {
			if (rowIndex === 0) return newHeader;
			return [...row, ''];
		});
		setData(updatedData);
		setValidate(false);
	};

	const handleDownloadTemplate = () => {
		setIsHeaderSelectionOpen(true);
	};

	const handleHeaderSelect = (header) => {
		setSelectedHeaders((prev) => {
			const headerExists = prev.some((h) => h.key === header.key);
			if (headerExists) {
				return prev.filter((h) => h.key !== header.key);
			} else {
				return [...prev, header];
			}
		});
	};

	useEffect(() => {
		localStorage.setItem('selectedHeaders', JSON.stringify(selectedHeaders));
	}, [selectedHeaders]);
	useEffect(() => {
		localStorage.setItem('role', JSON.stringify(role));
	}, [role]);

	const handleDownloadSelectedHeaders = () => {
		const template = [selectedHeaders.map((header) => header.key)];
		setIsHeaderSelectionOpen(false);
	};

	const handleCreateTable = () => {
		if (headers.length === 0) {
			toast.error('Please select a role to create a table.');
			return;
		}
		const headerKeys = headers?.filter((header) => header.required).map((header) => header.key);
		setData([headerKeys]);
	};

	const showConfirmationToast = (message, onConfirm) => {
		toast(
			(t) => (
				<div>
					<p>{message || 'Are you sure?'}</p>
					<div className='flex gap-2 mt-2'>
						<button
							onClick={() => {
								if (onConfirm) onConfirm();
								toast.dismiss(t.id);
							}}
							className='bg-green-500 text-white px-3 py-1 rounded'>
							Confirm
						</button>
						<button onClick={() => toast.dismiss(t.id)} className='bg-red-500 text-white px-3 py-1 rounded'>
							Cancel
						</button>
					</div>
				</div>
			),
			{
				duration: Infinity,
				position: 'top-center',
			},
		);
	};

	const handleReset = () => {
		setData([]);
		setFieldErrors('');
		setValidationErrors([]);
		setValidate(false);
		setRole('');
		setSelectClass('');
		setDepartement('');
		setSuccessMessage('');
	};

	useEffect(() => {
		setSelectClass('');
		setDepartement('');
	}, [role]);

	const options = headers?.map((optionHeader) => ({
		value: optionHeader?.key,
		label: optionHeader?.name,
	}));
	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId, user?.uid));
	const { data: departments } = useQuery(['getDepartments', collegeId], getDepartments(collegeId));

	console.log({ headers });
	console.log({ classes });
	console.log({ selectedHeaders });
	console.log({ data });
	console.log({ department });

	return (
		<Main width='100%' height='100%' title='Data Management System'>
			<Toaster />
			<div className='flex flex-col'>
				{!data.length > 0 ? (
					<div className='w-[100%] h-[84vh]'>
						<div className='flex justify-between items-center'>
							<div className='flex justify-between mb-4'>
								<div className='flex justify-start'>
									<Input type='drop' state={role} setState={setRole} options={['student', 'staff', 'teacher', 'scholars']} fieldName={'Select Role'} width='200px' />
									{role === 'student' && <Input fieldName='Filter by Class' setState={setSelectClass} state={selectedClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />}
									{(role === 'teacher' || role === 'scholars') && <Input fieldName='Choose Department *' optionDisplay='name' didntShowKey optionKey='_id' state={department} setState={setDepartement} options={departments} type='drop' width='20vw' />}
								</div>
							</div>
							<div className='flex'>
								{(data.length > 0 || selectedClass || role) && (
									<Button type='refresh' onClick={() => handleReset()}>
										Reset
									</Button>
								)}
								<Button type='save' onClick={handleDownloadTemplate} className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition '>
									Download Template
								</Button>
								{(role === 'student' ? selectedClass : role) && (
									<Button type='edit' onClick={handleCreateTable} className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition'>
										Create Table
									</Button>
								)}
								<Button type='close' onClick={onClose}>
									close
								</Button>
							</div>
						</div>
						<section>
							{isHeaderSelectionOpen && (
								<Main width='70%' height='90%'>
									<div className='flex justify-between pl-2'>
										<h3 className='italic'>Select Table Headers for Template Download</h3>
										<Button type='close' onClick={() => setIsHeaderSelectionOpen(false)}>
											Close
										</Button>
									</div>
									<div className='h-40 w-full flex gap-4 items-center'>
										<Input type='drop' state={role} setState={setRole} options={['student', 'staff', 'teacher', 'scholars']} fieldName={'Select Role'} width='20vw' />
										{selectedHeaders?.length > 0 && (
											// <Button type='download' onClick={handleDownloadSelectedHeaders}>
											// 	{' '}
											<CSVDownloader
												filename={`${role}Template`}
												bom={true}
												config={{
													delimiter: ',',
												}}
												data={[selectedHeaders?.map((header) => header.key)]}>
												<Button type='download' onClick={handleDownloadSelectedHeaders}>
													Download Template
												</Button>
											</CSVDownloader>
											// </Button>
										)}
									</div>{' '}
									<div className='pl-2 overflow-y-scroll pb-2'>
										<div className='grid grid-cols-4 gap-2'>
											{headers?.map((header, index) => (
												<div key={index} className='flex items-center space-x-2 border rounded-md py-1 px-2 shadow-md'>
													<input type='checkbox' className='h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500' checked={selectedHeaders.some((h) => h.key === header.key)} onChange={() => handleHeaderSelect(header)} />
													<label className='text-sm text-gray-800 pt-2 over'>{header.name}</label>
												</div>
											))}
										</div>
									</div>
								</Main>
							)}
						</section>

						{(role === 'student' ? selectedClass : role === 'teacher' || role === 'scholars' ? department : role) && (
							<div>
								<CSVReader onDrop={handleOnDrop} onError={handleOnError} onRemoveFile={handleOnRemoveFile}>
									<span className='text-lg font-bold w-full h-[70vh] text-center content-center'>Drop CSV file here or click to upload.</span>
								</CSVReader>
								{fieldErrors && (
									<div className='w-full mt-4 flex justify-center items-center' role='alert' aria-live='assertive'>
										<p className='text-red-700 font-medium'>{fieldErrors}</p>
									</div>
								)}
								{successMessage && (
									<div className='w-full mt-4 flex justify-center items-center' role='alert' aria-live='assertive'>
										<p className='text-green-700 font-medium'>{successMessage}</p>
									</div>
								)}
							</div>
						)}
					</div>
				) : (
					<section>
						<div className='flex justify-between'>
							<div className='flex justify-around gap-2'>
								<h6>USERTYPE: {role.toUpperCase()}</h6>
								{role === 'student' && <h6>CLASS: {classes?.list?.find((x) => x._id === selectedClass)?.className}</h6>}
							</div>
							<div className='flex justify-end'>
								{(data.length > 0 || selectedClass || role) && (
									<Button type='refresh' onClick={() => handleReset()}>
										Reset
									</Button>
								)}
								{!validate && data?.length > 1 ? (
									<Button type='update' onClick={validateTable}>
										Validate
									</Button>
								) : (
									data.length > 1 && (
										<Button type='submit' onClick={() => handleSubmit(data)}>
											Submit
										</Button>
									)
								)}
								{edit ? (
									<Button type='fetch' onClick={() => setEdit(false)}>
										Finish Edit
									</Button>
								) : (
									<>
										<CSVDownloader
											filename={'uploaded_data'}
											bom={true}
											config={{
												delimiter: ',',
											}}
											data={data}>
											<Button type='download'> Download</Button>
										</CSVDownloader>
										<Button type='fetch' onClick={() => setEdit(true)}>
											Edit
										</Button>
									</>
								)}
								<Button
									type='close'
									onClick={() => {
										setData([]);
										handleReset();
									}}>
									close
								</Button>
							</div>
						</div>
						<div className='overflow-scroll w-[100vw]'>
							<Table width={`${data[0]?.length * 150 + 180}px`} innerWidth={['150px', '15%']}>
								<Thead>
									<Tr>
										<Th position={0} width={'50px'}>
											#
										</Th>
										{data[0] &&
											data[0]?.map((header, colIndex) => (
												<Th key={colIndex} width={'150px'}>
													{edit ? (
														<div className='flex flex-col gap-1 justify-center'>
															<div className='flex justify-center items-center'>
																<Select value={options.find((option) => option.value === header)} onChange={(selectedOption) => handleInputChange(selectedOption?.value, 0, colIndex)} options={options} placeholder='Select option' classNamePrefix='react-select' className='bg-transparent text-dark overflow-visible h-auto text-xs' />
															</div>
															<button onClick={() => showConfirmationToast('Do you want delete this column?', () => handleDeleteColumn(colIndex))} className='text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-xs px-3 py-1 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'>
																Delete Col
															</button>
														</div>
													) : (
														<span>{headers.find((x) => x.key === header)?.name ?? <span className='text-red-500'>not valid</span>}</span>
													)}
												</Th>
											))}
										<Th position={0} width={'130px'}>
											<div className='flex flex-col justify-around items-center'>
												{edit && (
													<span className='text-lg cursor-pointer' onClick={handleAddColumn}>
														<RiAddFill />
													</span>
												)}
											</div>
										</Th>
									</Tr>
								</Thead>
								<Tbody height='75vh'>
									{data?.slice(1)?.map((row, rowIndex) => (
										<Tr key={rowIndex + 1}>
											<Td position={0} index={rowIndex} width={'50px'}>
												{rowIndex + 1}
											</Td>
											{row?.map((cell, colIndex) => {
												const fieldKey = data[0][colIndex];
												const errorForCell = validationErrors?.find((error) => error?.row === rowIndex + 2 && error?.errors[fieldKey]);
												return (
													<Td key={colIndex} width={'150px'}>
														{edit ? (
															<>
																<input type='text' value={cell} onChange={(e) => handleInputChange(e.target.value, rowIndex + 1, colIndex)} className={`border px-2 py-1 rounded-sm ${errorForCell ? 'border-red-500' : ''}`} />
																{errorForCell && <div className='text-red-500 text-xs'>{errorForCell?.errors[fieldKey]}</div>}
															</>
														) : (
															<span>{cell}</span>
														)}
													</Td>
												);
											})}
											<Td position={0} width={'130px'}>
												{edit && (
													<Button type='delete' onClick={() => showConfirmationToast('Do you want delete this row?', () => handleDeleteRow(rowIndex + 1))}>
														Delete Row
													</Button>
												)}
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</div>
						<div className='flex justify-start'>
							{edit ? (
								<Button type='update' onClick={handleAddRow}>
									Add Row
								</Button>
							) : !validate && data?.length > 1 ? (
								<Button type='update' onClick={validateTable} width='150px'>
									Validate
								</Button>
							) : (
								data.length > 1 && (
									<Button type='submit' onClick={() => handleSubmit(data)} width='150px'>
										Submit
									</Button>
								)
							)}
						</div>
						<div>
							{validationErrors?.length > 0 && (
								<div className='text-red-800 mt-4'>
									<h4>Validation Errors:</h4>
									<ul>
										{validationErrors?.map((error) => (
											<li key={error.row}>
												Row {error.row - 1}:
												<ul>
													{Object.values(error?.errors)?.map((msg, idx) => (
														<li key={idx}>{msg}</li>
													))}
												</ul>
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						<div>{fieldErrors && <p className='text-red-800'>{fieldErrors}</p>}</div>
					</section>
				)}
			</div>
		</Main>
	);
}
