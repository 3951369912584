import React, { useContext, useEffect, useState } from 'react';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Metric from './Metric';
import { FaCog } from 'react-icons/fa';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance } from '../../utils/axiosInstance';
import Select from 'react-select';
import { components } from 'react-select';
import { MdEdit } from 'react-icons/md';
import toast, { Toaster } from 'react-hot-toast';
import { ColorRing } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { getCollegeDetailsee } from '../Admission/RemoveAction';

const LoaderRing = () => {
	return (
		<div className='flex justify-center items-center'>
			<ColorRing visible={true} height='80' width='80' ariaLabel='color-ring-loading' wrapperStyle={{}} wrapperClass='color-ring-wrapper' colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />
		</div>
	);
};

function NAAC2024({ onClose }) {
	const [criteriaOpen, setCriteriaOpen] = useState(false);
	const [edit, setEdit] = useState(false);
	const [coordinatorSettings, setCoordinatorSettings] = useState(false);
	const [isPrincipal, setIsPrincipal] = useState(false);
	const [totalObtained, setTotalObtained] = useState(0);
	const [naacLoading, setNaacLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [selectedCriteria, setSelectedCriteria] = useState({});
	const [dropCoordinators, setDropCoordinators] = useState([]);
	const [naacData, setNaacData] = useState([
		{ keyName: 'attribute1', title: 'Attribute 1', subTitle: 'Curriculum', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 100 },
		{ keyName: 'attribute2', title: 'Attribute 2', subTitle: 'Faculty Resources', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 80 },
		{ keyName: 'attribute3', title: 'Attribute 3', subTitle: 'Infrastructure', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 100 },
		{ keyName: 'attribute4', title: 'Attribute 4', subTitle: 'Financial Resources & Management', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 90 },
		{ keyName: 'attribute5', title: 'Attribute 5', subTitle: 'Learning & Teaching', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 85 },
		{ keyName: 'attribute6', title: 'Attribute 6', subTitle: 'Extended Curricular Engagements', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 100 },
		{ keyName: 'attribute7', title: 'Attribute 7', subTitle: 'Governance and Administration', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 70 },
		{ keyName: 'attribute8', title: 'Attribute 8', subTitle: 'Student Outcomes', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 90 },
		{ keyName: 'attribute9', title: 'Attribute 9', subTitle: 'Research and Innovation Outcomes', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 80 },
		{ keyName: 'attribute10', title: 'Attribute 10', subTitle: 'Sustainability Outcomes and Green Initiatives', desc: 'This is a card criteria for naac reforms 2024', obtained: 0, max: 95 },
	]);
	const [coordinator, setCoordinator] = useState();
	const [subordinater, setSubordinater] = useState();
	const [collegeNaacData, setCollegeNaacData] = useState([]);
	const [chiefCoordinator, setChiefCoordinator] = useState(null);
	const { collegeId, user } = useContext(FirebaseContext);
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const getNaacReforms = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getNaacReforms?collegeId=${collegeId}&type=naacReform2024`;
			const res = await instance.get(url);
			setNaacData(res?.data?.list[0]?.naacData);
		} catch (error) {
			console.error(error);
		}
	};

	const checkPrincipalRole = async () => {
		try {
			const instance = await getAxiosInstance();
			const userId = user.uid;
			const url = `/college/naacReforms2024/checkPrincipalRole?collegeId=${collegeId}&user=${userId}`;
			const res = await instance.get(url);
			setIsPrincipal(res?.data?.isPrincipal);
		} catch (error) {
			console.error(error);
		}
	};

	const getCoordinators = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getCoordinators?collegeId=${collegeId}`;
			const res = await instance.get(url);
			setDropCoordinators(res?.data?.list);
		} catch (error) {
			console.error(error);
		}
	};

	const getNaacCoordinators = async () => {
		setNaacLoading(true);
		try {
			const instance = await getAxiosInstance();
			const url = `/college/naacReforms2024/getNaacCoordinators?collegeId=${collegeId}&type=naac2024Coordinators`;
			const res = await instance.get(url);
			const coordinatorsData = res?.data?.list[0]?.collegeNaacData;

			// Convert object to array if necessary
			let coordinatorsArray;
			if (Array.isArray(coordinatorsData)) {
				coordinatorsArray = coordinatorsData;
			} else if (coordinatorsData) {
				coordinatorsArray = Object.keys(coordinatorsData)?.map((key) => ({
					keyName: key,
					...coordinatorsData[key],
				}));
			} else {
				coordinatorsArray = [];
			}

			setCollegeNaacData(coordinatorsArray);
			setChiefCoordinator(res?.data?.list[0]?.chiefCoordinator);
			setNaacLoading(false);
		} catch (error) {
			console.error(error);
			setNaacLoading(false);
		}
	};

	useEffect(() => {
		const totalObtained = collegeNaacData?.reduce((acc, attr) => acc + (attr?.obtained || 0), 0);
		setTotalObtained(totalObtained);

		const totalMaxMarks = naacData?.reduce((acc, at) => {
			const metricMarks = at?.metric?.flatMap((metric) => metric?.details?.filter((detail) => detail?.institutionType === collegeData?.institutionType).map((detail) => detail?.max || 0));
			const maxMetricMarks = metricMarks?.reduce((sum, max) => sum + max, 0);
			return acc + maxMetricMarks;
		}, 0);

		setTotal(totalMaxMarks);
	}, [collegeNaacData, naacData, collegeData]);

	useEffect(() => {
		getNaacReforms();
		getCoordinators();
		getNaacCoordinators();
		checkPrincipalRole();
	}, []);
	// useEffect(() => {
	// 	handleSave();
	// }, [chiefCoordinator]);

	const customOption = (props) => {
		return (
			<components.Option {...props}>
				<div className='flex items-center'>
					<img src={props?.data?.photoURL} alt={props?.data?.name} className='w-6 h-6 rounded-full mr-2' />
					<span>{props?.data?.name}</span>
				</div>
			</components.Option>
		);
	};

	const handleSave = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = '/college/naacReforms2024/saveNaacCoordinators';
			const data = {
				updatedBy: user?.uid,
				collegeId,
				collegeNaacData,
				chiefCoordinator,
				type: 'naac2024Coordinators',
			};
			await instance?.post(url, data);
			toast.success('Saved successfully');
		} catch (error) {
			console.error(error);
			toast.error('Failed to save ');
		}
	};

	console.log({ collegeNaacData });

	const updateAttributeCoordinators = (keyName, coordinator, subordinates, obtained) => {
		setCollegeNaacData((prev) => {
			const index = prev?.findIndex((item) => item?.keyName === keyName);
			if (index >= 0) {
				const newArray = [...prev];
				newArray[index] = { ...newArray[index], coordinator, subordinates, obtained };
				return newArray;
			} else {
				return [...prev, { keyName, coordinator, subordinates, obtained }];
			}
		});
		// handleSave();
	};

	return (
		<Main width='100vw' height='100vh' title='NAAC REFORMS 2024'>
			<Toaster />
			{naacLoading ? (
				<LoaderRing />
			) : (
				<>
					<div className='flex justify-between  mb-2  bg-[#2F3645]  h-16 mt-[-20px] mx-[-5px] items-center pt-2 p-1 rounded '>
						<div></div>
						<div className='flex gap-3'>
							{isPrincipal ? (
								<div className='w-60 pt-0  content-right'>
									{edit ? (
										<div className='flex w-auto gap-1'>
											<label htmlFor='chiefCoordinator' className='block text-white text-sm font-normal mb-2 leading-none pt-2 m-0 w-auto '>
												Chief Coordinator
											</label>
											<Select
												id='chiefCoordinator'
												name='chiefCoordinator'
												value={dropCoordinators?.find((opt) => opt._id === chiefCoordinator)}
												options={dropCoordinators}
												onChange={(option) => {
													setChiefCoordinator(option._id);
													setEdit(false);
													handleSave();
												}}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option._id}
												components={{ Option: customOption }}
												isSearchable
												className='w-full text-gray-700 rounded p-0 mt-1'
											/>
										</div>
									) : (
										<div className='flex items-end justify-end gap-0'>
											{chiefCoordinator ? (
												<div className='flex flex-col gap-0 m-0 p-0'>
													<div className='flex items-center mt-0 space-x-3'>
														<img src={dropCoordinators?.find((x) => x?._id === chiefCoordinator)?.photoURL} alt={dropCoordinators?.find((x) => x._id === chiefCoordinator)?.name} className='w-10 h-10 rounded-full shadow-md hover:scale-105 transition-transform' />
														<div className='flex flex-col'>
															<label htmlFor='chiefCoordinator' className='block text-white text-xs font-normal mb-2 leading-none p-0 m-0 w-auto'>
																Chief Coordinator
															</label>
															<span className='text-sm font-bold text-white leading-none'>{dropCoordinators?.find((x) => x?._id === chiefCoordinator)?.name}</span>
														</div>
														<span onClick={() => setEdit(true)} className='cursor-pointer text-white'>
															<MdEdit />
														</span>
													</div>
												</div>
											) : (
												<div className='flex w-auto gap-1'>
													<label htmlFor='chiefCoordinator' className='block text-white text-sm font-bold mb-2 leading-none pt-2 m-0 w-auto '>
														Chief Coordinator
													</label>
													<Select
														id='chiefCoordinator'
														name='chiefCoordinator'
														value={dropCoordinators?.find((opt) => opt?._id === chiefCoordinator)}
														options={dropCoordinators}
														onChange={(option) => {
															setChiefCoordinator(option?._id);
															setEdit(false);
															handleSave();
														}}
														getOptionLabel={(option) => option?.name}
														getOptionValue={(option) => option?._id}
														components={{ Option: customOption }}
														isSearchable
														className='w-full  text-gray-700 rounded p-0 mt-1'
													/>
												</div>
											)}
										</div>
									)}
								</div>
							) : (
								chiefCoordinator && (
									<div className='flex flex-col'>
										<div className='flex items-center mt-0 space-x-3'>
											<img src={dropCoordinators?.find((x) => x._id === chiefCoordinator)?.photoURL} alt={dropCoordinators?.find((x) => x._id === chiefCoordinator)?.name} className='w-10 h-10 rounded-full shadow-md hover:scale-105 transition-transform' />
											<div className='flex flex-col'>
												<label htmlFor='chiefCoordinator' className='block text-white text-xs font-normal mb-2 leading-none p-0 m-0 w-auto'>
													Chief Coordinator
												</label>
												<span className='text-sm font-bold text-white leading-none'>{dropCoordinators?.find((x) => x?._id === chiefCoordinator)?.name}</span>
											</div>
										</div>
									</div>
								)
							)}
							{/* {isPrincipal && !edit && (
								<Button type='save' onClick={handleSave}>
									save
								</Button>
							)} */}
							<Button type='close' onClick={onClose}>
								close
							</Button>
						</div>
					</div>
					<div className='flex pt-2 h-[92vh]'>
						<div className='overflow-y-scroll'>
							<div className='flex flex-wrap justify-center'>
								{naacData.map((attribute, index) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === attribute?.keyName);
									return (
										<div
											key={index}
											style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
											onClick={() => {
												setCriteriaOpen(true);
												setSelectedCriteria(attribute);
											}}>
											<div
												style={{
													backgroundColor: 'white',
													boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
													borderRadius: '12px',
													padding: '20px',
													width: '480px',
													maxWidth: '480px',
													margin: '16px',
													display: 'flex',
													alignItems: 'flex-start',
													height: '340px',
												}}
												className='relative'>
												<div id='123' className='flex flex-col justify-between h-full w-full flex-grow'>
													<div className='flex justify-between w-full'>
														<p style={{ fontSize: '16px', fontWeight: 'semibold' }}>{attribute?.title}</p>
														<FaCog
															onClick={(e) => {
																e.stopPropagation();
																setCoordinatorSettings(true);
																setSelectedCriteria(attribute);
															}}
															className=' text-gray-800 absolute right-1 top-1 cursor-pointer'
															size={20}
														/>
													</div>
													<div className='flex-grow'>
														<p style={{ fontSize: '16px', fontWeight: 'bold' }}>{attribute?.subTitle}</p>
														<p style={{ fontSize: '12px', color: '#4B5563', marginTop: '8px' }}>{attribute?.desc}</p>
													</div>
													<div className='flex flex-col h-[100px]'>
														{attributeData && attributeData.coordinator && (
															<div className='flex items-center mt-2 space-x-3'>
																<img src={dropCoordinators?.find((x) => x._id === attributeData.coordinator)?.photoURL} alt={dropCoordinators?.find((x) => x?._id === attributeData?.coordinator)?.name} className='w-10 h-10 rounded-full shadow-md hover:scale-105 transition-transform' />
																<div className='flex flex-col'>
																	<span className='text-xs font-semibold italic text-gray-500'>Coordinator</span>
																	<span className='text-sm font-medium text-gray-700'>{dropCoordinators?.find((x) => x?._id === attributeData?.coordinator)?.name}</span>
																</div>
															</div>
														)}

														{attributeData && attributeData?.subordinates && (
															<div className='flex items-center mt-3 space-x-2 w-auto gap-4'>
																{attributeData?.subordinates?.slice(0, 3).map((subId, i) => {
																	const subordinate = dropCoordinators?.find((x) => x?._id === subId);
																	return (
																		<div key={i} className='flex items-center space-x-1 w-auto h-auto'>
																			<img src={subordinate?.photoURL} alt={subordinate?.name} className='w-8 h-8 rounded-full shadow-sm hover:scale-105 transition-transform object-cover aspect-square' />
																			<div className='flex flex-col'>
																				<span className='text-[7px] font-semibold italic text-gray-500'>Subordinate</span>
																				<span className='text-[9px] font-medium text-gray-700 whitespace-nowrap'>{subordinate?.name}</span>
																			</div>
																		</div>
																	);
																})}
																{attributeData?.subordinates?.length > 3 && <span className='text-xs text-gray-600 font-base'>+{attributeData?.subordinates?.length - 3} more</span>}
															</div>
														)}
													</div>
												</div>

												<div className='flex flex-col justify-between items-end w-full gap-2 h-full'>
													<div
														style={{
															width: '80px',
															height: '80px',
															borderRadius: '50%',
															border: '2px solid #10B981',
														}}
														className='flex flex-col justify-center items-center'>
														<span className='text-xs pt-2'>Obtained</span>
														<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.obtained ?? 0}</span>
													</div>
													<div
														style={{
															width: '80px',
															height: '80px',
															borderRadius: '50%',
															border: '2px solid #3B82F6',
														}}
														className='flex flex-col justify-center items-center'>
														<span className='text-xs pt-2'>Max Max</span>
														<span style={{ fontSize: '16px', fontWeight: '600' }}>{attribute?.max}</span>
													</div>
													<div
														style={{
															width: '80px',
															height: '80px',
															borderRadius: '50%',
															border: '2px solid #F59E0B',
														}}
														className='flex flex-col justify-center items-center'>
														<span className='text-xs pt-2'>Percentage</span>
														<span style={{ fontSize: '16px', fontWeight: '600' }}>{attributeData?.obtained > 0 ? ((parseFloat(attributeData?.obtained) / parseFloat(attribute?.max)) * 100).toFixed(2) : 0}%</span>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className='flex flex-col justify-start items-end w-48 gap-2 h-full pt-4'>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #2F4F4F',
								}}
								className='flex flex-col justify-center items-center shadow-lg text-dark'>
								<span className='text-xs pt-2'>Obtained</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{totalObtained}</span>
							</div>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #000080',
								}}
								className='flex flex-col justify-center items-center'>
								<span className='text-xs pt-2'>Max Mark</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{total}</span>
							</div>
							<div
								style={{
									width: '80px',
									height: '80px',
									borderRadius: '50%',
									border: '2px solid #A0522D',
								}}
								className='flex flex-col justify-center items-center'>
								<span className='text-xs pt-2'>Percentage</span>
								<span style={{ fontSize: '16px', fontWeight: '600' }}>{((totalObtained / total) * 100).toFixed(2)}%</span>
							</div>
						</div>
					</div>
				</>
			)}

			{coordinatorSettings && (
				<Main width='30%' height='60%'>
					<div className='flex flex-col justify-center items-center w-full h-full p-10'>
						<div className='absolute right-1 top-1'>
							<Button type='close' onClick={() => setCoordinatorSettings(false)}>
								close
							</Button>
						</div>

						<div className='w-full'>
							<label htmlFor='coordinator' className='block text-gray-700 text-sm font-bold mb-2'>
								Select Coordinator for {selectedCriteria?.title}
							</label>
							<Select
								id='coordinator'
								name='coordinator'
								value={dropCoordinators?.find((opt) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									return opt._id === attributeData?.coordinator;
								})}
								options={dropCoordinators}
								onChange={(option) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									updateAttributeCoordinators(selectedCriteria.keyName, option._id, attributeData?.subordinates, attributeData?.obtained);
								}}
								getOptionLabel={(option) => option?.name}
								getOptionValue={(option) => option?._id}
								components={{ Option: customOption }}
								isSearchable
								className='w-full rounded'
							/>
						</div>

						<div className='w-full mt-4'>
							<label htmlFor='subordinates' className='block text-gray-700 text-sm font-bold mb-2'>
								Select Subordinates for {selectedCriteria?.title}
							</label>
							<Select
								id='subordinates'
								name='subordinates'
								value={dropCoordinators?.filter((opt) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									return attributeData?.subordinates?.includes(opt._id);
								})}
								options={dropCoordinators}
								onChange={(options) => {
									const attributeData = collegeNaacData?.find((item) => item?.keyName === selectedCriteria?.keyName);
									updateAttributeCoordinators(
										selectedCriteria?.keyName,
										attributeData?.coordinator,
										options?.map((opt) => opt?._id),
										attributeData?.obtained,
									);
								}}
								getOptionLabel={(option) => option?.name}
								getOptionValue={(option) => option?._id}
								components={{ Option: customOption }}
								isMulti
								className='w-full rounded'
							/>
						</div>
						<div className='flex justify-center mt-4'>
							{' '}
							<Button type='submit' onClick={handleSave}>
								Save Settings
							</Button>
						</div>
					</div>
				</Main>
			)}
			{criteriaOpen && <Metric criteriaOpen={criteriaOpen} setCriteriaOpen={setCriteriaOpen} selectedCriteria={selectedCriteria} setSelectedCriteria={setSelectedCriteria} />}
		</Main>
	);
}

export default NAAC2024;
