import React from "react";
import DOMPurify from "dompurify";

const PasteFromWord = ({ setState, state }) => {
  const handlePaste = (e) => {
    e.preventDefault();
    const html = e.clipboardData.getData("text/html");
    const cleanHtml = DOMPurify.sanitize(html);

    setState(cleanHtml);
  };

  return (
    <div>
      <div onPaste={handlePaste} contentEditable={true} style={{ border: "1px solid #ccc", minHeight: "500px", padding: "10px" }} dangerouslySetInnerHTML={{ __html: state }}></div>
    </div>
  );
};

export default PasteFromWord;
