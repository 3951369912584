import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../../context/FirebaseContext';

const CollegeList = () => {
	const history = useHistory();
	const { collegeList, setCollegeId } = useContext(FirebaseContext);

	const onCollegeClick = (college) => () => {
		console.log(college);
		setCollegeId(college?.collegeId);
		history.push('/app');
	};

	return (
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-scroll'>
			{collegeList.map((college) => (
				<button key={college.collegeId} className='bg-white hover:bg-gray-100 shadow-md rounded-lg flex flex-col items-center p-4 transition-transform transform hover:scale-105' onClick={onCollegeClick(college)}>
					<div className='w-24 h-28 flex items-center justify-center mb-4'>
						<img src={college.logo} alt={college.collegeName} className='object-contain h-full w-full' />
					</div>
					<h2 className='text-lg font-semibold text-center text-gray-800'>{college.collegeName}</h2>
				</button>
			))}
		</div>
	);
};

export default CollegeList;
