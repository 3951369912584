import React, { useState } from 'react';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import classNames from './exportModal.module.scss';
import { ReactComponent as DownCircle } from '../../../../static/images/clipIcon/downCircle.svg';
import { Button, Input } from 'antd';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { getFormattedDateWithMonth } from '../../../../utils';

const typeOfData = ['Tag'];

export const getTemplateList = (type) => async () => {
	const instance = await getAxiosTokenInstance();

	try {
		const resp = await instance.get(`/college/tag/getAllTemplates`, {
			params: { type },
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data?.templates;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const ExportModal = ({ type, onExport, onClose }) => {
	const { data = [], status, error, isFetching } = useQuery('allTemplates', getTemplateList(type));
	const [selected, setSelected] = useState({});

	const typeOfDataMenu = (
		<Menu onClick={(e) => {}}>
			{typeOfData?.map((type) => (
				<Menu.Item key={type}>{type}</Menu.Item>
			))}
		</Menu>
	);

	const onTemplatePress = (index) => () => {
		setSelected(data[index]);
	};

	const onTemplateExport = (event) => {
		event?.preventDefault();
		if (selected?._id) {
			const target = event?.currentTarget;
			const additionalData = {
				startsNumber: target?.startsNumber?.value,
				applicationDate: getFormattedDateWithMonth(target?.applicationDate?.value),
				leavingDate: getFormattedDateWithMonth(target?.leavingDate?.value),
				signatureDate: getFormattedDateWithMonth(target?.signatureDate?.value),
				itemType: selected.itemType,
			};
			onExport(selected._id, additionalData);
		}
	};

	const FormField = ({ labelString, type, placeholder, ...props }) => {
		return (
			<div className={classNames.formField}>
				<label>{labelString}</label>
				<Input type={type} placeholder={placeholder} required {...props} />
			</div>
		);
	};

	const renderTCFormFields = () => (
		<>
			<FormField name='startsNumber' labelString={'TC number starts from*'} placeholder='1234' />
			<FormField name='applicationDate' labelString={'Date of application for TC*'} type={'date'} />
			<FormField name='leavingDate' labelString={'Date of leaving*'} type={'date'} />
			<FormField name='signatureDate' labelString={'Date of Signature*'} type={'date'} />
		</>
	);

	return (
		<div className={classNames.container}>
			{isFetching && <LoadingAnimation dark />}
			<form className={classNames.modal} onSubmit={onTemplateExport}>
				<div className={classNames.listContainer}>
					<label className={classNames.heading}>Settings</label>
					<Dropdown overlay={typeOfDataMenu}>
						<div className={classNames.dropdown}>
							<label>Tag</label>
							<DownCircle />
						</div>
					</Dropdown>
					<div className={classNames.list}>
						{data?.map((item, index) => (
							<Button className={classNames.listItem} onClick={onTemplatePress(index)}>
								{`Template ${index + 1}`}
							</Button>
						))}
					</div>
					<button className={classNames.button} type='submit'>
						EXPORT
					</button>
				</div>
				<div className={`${classNames.imageContainer} ${!selected?.backImage ? classNames.columnDisplay : ''}`}>
					{selected?.frontImage && <img alt='template-front' src={selected?.frontImage} className={!selected?.backImage ? classNames.fillImage : ''} />}
					{selected?.backImage && <img alt='template-back' src={selected?.backImage} />}
					{selected?.itemType === 'TC' && renderTCFormFields()}
					<Button className={classNames.closeButton} onClick={onClose}>
						Close
					</Button>
				</div>
			</form>
		</div>
	);
};
