import React, { useContext, useRef } from "react";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";

const getFieldDropDowns = (data: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/library/getMembersDueFine", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.data;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function DueRegister({ onClose }: invoiceManagementProps) {
  const { collegeId } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const { data: vendorDropDown = [] } = useQuery(["vendorddDrodddddpDown", collegeId], getFieldDropDowns({ collegeId }), { keepPreviousData: true });
  console.log({ vendorDropDown });

  return (
    <>
      <Main title="Due Register" width="100%" height="100%">
        <Header>
          <div className="flex">
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["3%", "10%", "30%", "15%", "20%"]}>
              <Thead>
                <Tr>
                  <Th position={0} fontSize="10px">
                    Sl.No.
                  </Th>
                  <Th position={1}>Admission No</Th>
                  <Th position={1}>Name</Th>
                  <Th position={2}>Class</Th>
                  <Th position={3}>Fine Due</Th>
                  <Th position={4}>Total Active Book</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {vendorDropDown?.map((x: any, i: number) => (
                  <Tr>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.admisionNo}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.name}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.className}
                    </Td>
                    <Td index={i} position={3}>
                      {x?.totalfine}
                    </Td>
                    <Td index={i} position={4}>
                      {x?.totalActiveBook}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
      </Main>
    </>
  );
}

export default DueRegister;
