import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select } from 'antd';
import { useMutation } from 'react-query';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { useAllClasses } from '../../../NecttosComp/APICaller/queryHooks';
import { Formik } from 'formik';

const exportPdf = async ({ olddata, setLoading }) => {
	try {
		const instance = await getAxiosTokenInstance();
		const { collegeId, classId } = olddata;

		const resp = await instance.post(
			'/college/reportGeneration/menteeProfilePDF',
			{ collegeId, classId },
			{
				responseType: 'blob',
			},
		);

		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		}

		return resp.data;
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};
const { Option } = Select;

const MenteePDF = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [custom, setCustom] = useState(false);
	const [stateData, setStateData] = useState({});
	const exportPdfMutation = useMutation(exportPdf);
	const [loading, setLoading] = useState(false);
	const allClasses = useAllClasses({ needAll: false, classOnly: false });

	const onExportExam = async () => {
		try {
			setLoading(true);
			const { classId, className } = stateData;
			const link = document.createElement('a');
			link.target = '_blank';
			link.download = `Electoral Roll ${className}`;

			const pdfData = await exportPdfMutation.mutateAsync({ olddata: { collegeId, classId }, setLoading });
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();

			setLoading(false);
			onClose();
		} catch (e) {}
	};

	return (
		<div className={classNames.container}>
			{loading ? (
				<LoadingAnimation dark />
			) : (
				<div className={classNames.modal}>
					<Button className={classNames.closeButton} onClick={onClose}>
						Close
					</Button>
					<div className={classNames.listContainer}>
						<label className={classNames.heading}>Mentee Profile PDF</label>
						<CustomOrStandard setCustom={setCustom} custom={custom} />
						{custom ? <CustomInput stateData={stateData} setStateData={setStateData} /> : <StandardInput allClasses={allClasses} stateData={stateData} setStateData={setStateData} />}
						<button className={classNames.button} onClick={onExportExam}>
							Mentee Profile PDF
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

const CustomOrStandard = ({ setCustom, custom }) => (
	<label style={{ fontSize: 30 }}>
		<input type='checkbox' style={{ height: 20, width: 20, marginRight: 10 }} defaultChecked={custom} onChange={() => setCustom(!custom)} />
		Custom
	</label>
);

const CustomInput = ({ stateData, setStateData }) => (
	<div className={classNames.group} style={{ width: 500, height: 100, margin: 10 }}>
		<input type='text' aria-multiline onChange={(e) => setStateData({ ...stateData, year: e.target.value })} value={stateData.year} />
		<label>START</label>
	</div>
);

const StandardInput = ({ allClasses, stateData, setStateData }) => (
	<div style={{ width: 400, height: 100 }}>
		<Formik enableReinitialize>
			<div className={`${classNames.group} ${classNames.formHalfWidth}`}>
				<Select className={classNames.formFullWidth} style={{ overflow: 'hidden' }} onChange={(val) => setStateData({ ...stateData, classId: allClasses.data.find((x, i) => x.className + i === val).classId, className: allClasses.data.find((x, i) => x.className + i === val).className })}>
					{allClasses?.data?.map((item, i) => (
						<Option key={i} value={item.className + i}>
							{item.className}
						</Option>
					))}
				</Select>
				<label>Select Class</label>
			</div>
		</Formik>
	</div>
);

export default MenteePDF;
