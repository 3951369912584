import React, { useEffect, useState } from 'react';
import { EditDetails, getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, widthChangeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
// import { ProgressBar, calculateProfilepPercentage } from "../../../NecttosComp/ProgressBar/ProgressBar";
import Loader from '../../NecttosComp/Loader/Loader';
import { ProfileDataDisplayKeyVlaue, ProgressBar, calculateProfilepPercentage } from '../NewProfile';
// import { ProfileDataDisplayKeyVlaue } from "../../../FunctionalExport/Formate";

interface Props {
	onClose: () => void;
	onNext: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}

function AddressInfo({ onClose, studentId, onNext, modal }: Props) {
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [isLoading, setIsLoading] = useState<boolean>();
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const { data: profileData, refetch } = useProfileData();

	const [values, setValues] = useState<any>({});

	useEffect(() => {
		setValues({
			houseName: profileData?.pendingData?.houseName || profileData?.houseName || '',
			place: profileData?.pendingData?.place || profileData?.place || '',
			postOffice: profileData?.pendingData?.postOffice || profileData?.postOffice || '',
			village: profileData?.pendingData?.village || profileData?.village || '',
			panchayatMunicipalityCorporation: profileData?.pendingData?.panchayatMunicipalityCorporation || profileData?.panchayatMunicipalityCorporation || '',
			district: profileData?.pendingData?.district || profileData?.district || '',
			state: profileData?.pendingData?.state || profileData?.state || '',
			nationality: profileData?.pendingData?.nationality || profileData?.nationality || '',
			pinCode: profileData?.pendingData?.pinCode || profileData?.pinCode || '',
			permanentHouseName: profileData?.pendingData?.permanentHouseName || profileData?.permanentHouseName || '',
			permanentPlace: profileData?.pendingData?.permanentPlace || profileData?.permanentPlace || '',
			permanentPostOffice: profileData?.pendingData?.permanentPostOffice || profileData?.permanentPostOffice || '',
			permanentVillage: profileData?.pendingData?.permanentVillage || profileData?.permanentVillage || '',
			permanentPanchayatMunicipalityCorporation: profileData?.pendingData?.permanentPanchayatMunicipalityCorporation || profileData?.permanentPanchayatMunicipalityCorporation || '',
			permanentDistrict: profileData?.pendingData?.permanentDistrict || profileData?.permanentDistrict || '',
			permanentState: profileData?.pendingData?.permanentState || profileData?.permanentState || '',
			permanentNationality: profileData?.pendingData?.permanentNationality || profileData?.permanentNationality || '',
			permanentPinCode: profileData?.pendingData?.permanentPinCode || profileData?.permanentPinCode || '',
		});
	}, [profileData]);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	const placeHoldValues = {
		houseName: 'House Name',
		place: 'Place',
		postOffice: 'Post Office',
		village: 'Village',
		panchayatMunicipalityCorporation: 'Pachayat Municipality Corporation',
		district: 'District',
		state: 'State',
		nationality: 'Nationality',
		pinCode: 'Pincode',
		permanentHouseName: 'Permanent House Name',
		permanentPlace: 'Permanent Place',
		permanentPostOffice: 'Permanent Post Office',
		permanentVillage: 'Permanent Village',
		permanentPanchayatMunicipalityCorporation: 'Permanent Panchayat Municipality Corporation',
		permanentDistrict: 'Permanent District',
		permanentState: 'Permanent State',
		permanentNationality: 'Permanent Nationality',
		permanentPinCode: 'Permanent Pin Code',
	};

	return (
		<>
			<div className='h-full w-full'>
				{/* <Main title="Personal Information" height="90vh" width={"60vw"}> */}

				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						{/* <Button type="print">Print</Button>
     					 <Button type="pdf" pdfId="accounts">PDF</Button> */}
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}

						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>

				<Body width='100%' height='92%'>
					<div className='grid grid-flow-col gap-6 w-full h-full '>
						<div className='p-4 w-[45vw] h-full flex flex-col justify-between rounded' style={{ backgroundColor: 'white', color: 'black' }}>
							<div className='w-full h-auto px-4'>
								<ProgressBar title='Verified Data' progress={parseInt(calculateProfilepPercentage(profileData, placeHoldValues)?.percentage) || 0} />
							</div>
							<div className='w-full h-auto overflow-y-scroll'>
								<ProfileDataDisplayKeyVlaue temp={profileData} placeHoldValues={placeHoldValues} title='Verified Data' />
							</div>
							{!isEditing ? (
								<Button
									type='update'
									onClick={async () => {
										setIsEditing(!isEditing);
									}}>
									Edit
								</Button>
							) : null}
						</div>

						{isEditing && (
							<div className='w-full h-full p-4 flex flex-col justify-between rounded' style={{ backgroundColor: 'white', color: 'black' }}>
								<div className='w-full h-auto px-4'>
									<ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
								</div>
								<h5 className='text-blue-900 text-[1.2vw] font-bold text-center'>You can edit your data here!</h5>

								<div id='scrollBarEnable' className='px-3 py-2 w-full z-50 overflow-auto h-full max-h-[65vh]'>
									<Input width='98%' fieldName='House Name' returnKey='houseName' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Place' returnKey='place' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Post Office' returnKey='postOffice' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Village' returnKey='village' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Panchayat Municipality Corporation' returnKey='panchayatMunicipalityCorporation' setState={setValues} state={values} type='text' />
									<Input
										width='98%'
										fieldName='Nationality'
										returnKey='nationality'
										options={dropDowns[3]?.address[0]?.nationality}
										setState={setValues}
										state={values}
										type='drop'
										onChange={() => {
											setValues({ ...values, state: '', district: '' });
										}}
									/>
									<div className='relative z-[5]'>
										<Input width='98%' fieldName='State' returnKey='state' options={dropDowns[3]?.address[0]?.state} setState={setValues} state={values} type={values?.nationality === 'INDIA' ? 'drop' : 'text'} />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName={values?.nationality === 'INDIA' ? 'District' : 'Province'} returnKey='district' options={dropDowns[3]?.address[0]?.district} setState={setValues} state={values} type={values?.nationality === 'INDIA' ? 'drop' : 'text'} />
									</div>
									<Input width='98%' fieldName='Pincode' returnKey='pinCode' setState={setValues} state={values} type='text' />
									<label>
										<Button
											type='update'
											onClick={() => {
												setValues({
													...values,
													permanentHouseName: values.houseName,
													permanentPlace: values.place,
													permanentPostOffice: values.postOffice,
													permanentVillage: values.village,
													permanentPanchayatMunicipalityCorporation: values.panchayatMunicipalityCorporation,
													permanentDistrict: values.district,
													permanentState: values.state,
													permanentNationality: values.nationality,
													permanentPinCode: values.pinCode,
												});
											}}>
											Current Address is same as Permanent Address
										</Button>
									</label>
									<Input width='98%' fieldName='Permanent House Name' returnKey='permanentHouseName' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Permanent Place' returnKey='permanentPlace' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Permanent Post Office' returnKey='permanentPostOffice' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Permanent Village' returnKey='permanentVillage' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Permanent Panchayat Municipality Corporation' returnKey='permanentPanchayatMunicipalityCorporation' setState={setValues} state={values} type='text' />
									<Input
										width='98%'
										fieldName='Permanent Nationality'
										returnKey='permanentNationality'
										options={dropDowns[3]?.address[0]?.nationality}
										setState={setValues}
										state={values}
										type='drop'
										onChange={() => {
											setValues({ ...values, permanentState: '', permanentDistrict: '' });
										}}
									/>
									<div className='relative z-[5]'>
										<Input width='98%' fieldName='Permanent State' returnKey='permanentState' options={dropDowns[3]?.address[0]?.state} setState={setValues} state={values} type={values?.permanentNationality === 'INDIA' ? 'drop' : 'text'} />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName={values?.permanentNationality === 'INDIA' ? 'Permanent District' : 'Permanent Province'} returnKey='permanentDistrict' options={dropDowns[3]?.address[0]?.district} setState={setValues} state={values} type={values?.permanentNationality === 'INDIA' ? 'drop' : 'text'} />
									</div>
								</div>

								<Button
									width='100%'
									type='save'
									onClick={async () => {
										setIsLoading(true);
										await EditDetails(values);
										await refetch();
										setIsEditing(!isEditing);
										setIsLoading(false);
									}}>
									Save
								</Button>
							</div>
						)}
					</div>
				</Body>
				{/* </Main> */}
				{isLoading && <Loader />}
			</div>
		</>
	);
}

export default AddressInfo;
