import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select, Table } from 'antd';
import { Field, Formik } from 'formik';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';
import LoadingAnimation from '../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import ModalWrapper from '../../../components/ModalWrapper';
import NewQuestionnaire from './NewQuestionnaire';
import { getQuestionnair } from '../../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import SettupQuestionnaire from './SettupQuestionnaire';
import AnalysisQuestionnaire from './AnalysisQuestionnaire';
import { Statistics } from './Statistics';

export const FeedBackReports = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});
	const componentRef = useRef();
	const [loading, setLoading] = useState(false);

	let [items, setItems] = React.useState({
		addBook: false,
	});
	const { data = [], refetch } = useQuery(['getQuestionnair', collegeId, state.type], getQuestionnair(collegeId, state.filterType));

	const { Option } = Select;

	let filterTypeDrops = ['Student', 'Teacher', 'Statf'];

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<>
						<div className={classNames.listContainer2}>
							<div style={{ height: '90%' }}>
								<div ref={componentRef} className='printable'>
									<div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
										<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}>Sn</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '30%' }}>Title of Feed Back</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Collected From</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>No of Q</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Type</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Who Fill</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Priority</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>End Date</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Responds</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '35%' }}></h5>
										</div>
										<div
											style={{
												height: '600px',
												width: '100%',
												position: 'relative',
											}}>
											<Scrollbars>
												{data?.map((item, i) => (
													<>
														<div style={{ width: '100%', display: 'flex', padding: '20px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '4%' }}>{parseInt(state.page) * 50 + 1 + i}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '30%' }}>{item.title}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{moment(item.effectFrom).format('DD MMM YYYY')}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.length}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.type}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '20%' }}>{item.whoFillName}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.nature}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{moment(item.lastDate).format('DD MMM YYYY')}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.responds || '0%'}</h5>
															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	setItems({
																		...items,
																		questionnaireAnalysis: true,
																		questionnaireId: item._id,
																		itemData: {
																			_id: item._id,
																			collegeId: item.collegeId,
																			title: item.title,
																			effectFrom: moment(item.effectFrom).format('YYYY-MM-DD'),
																			type: item.type,
																			mode: item.mode,
																			semester: item.semester,
																			whoFill: item.whoFill,
																			academicYear: item.academicYear,
																			questionnaire: item.questionnaire,
																			nature: item.nature,
																			lastDate: moment(item.lastDate).format('YYYY-MM-DD'),
																		},
																	});
																}}>
																Analysis
															</Button>
															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	setItems({
																		...items,
																		statistics: true,
																		questionnaireId: item._id,
																	});
																}}>
																Frequency
															</Button>
															<Button
																className={classNames.reloadButton}
																onClick={() => {
																	setItems({
																		...items,
																		newQuestionnaire: true,
																		questionnaireId: item._id,
																		itemData: {
																			_id: item._id,
																			collegeId: item.collegeId,
																			title: item.title,
																			effectFrom: moment(item.effectFrom).format('YYYY-MM-DD'),
																			type: item.type,
																			mode: item.mode,
																			semester: item.semester,
																			whoFill: item.whoFill,
																			academicYear: item.academicYear,
																			questionnaire: item.questionnaire,
																			nature: item.nature,
																			lastDate: moment(item.lastDate).format('YYYY-MM-DD'),
																		},
																	});
																}}>
																Edit
															</Button>
															<Button
																className={classNames.reloadButton}
																onClick={() => {
																	setItems({
																		...items,
																		settupQuestionnaire: true,
																		questionnaireId: item._id,
																		itemData: {
																			_id: item._id,
																			collegeId: item.collegeId,
																			title: item.title,
																			effectFrom: moment(item.effectFrom).format('YYYY-MM-DD'),
																			type: item.type,
																			mode: item.mode,
																			semester: item.semester,
																			whoFill: item.whoFill,
																			academicYear: item.academicYear,
																			questionnaire: item.questionnaire,
																			nature: item.nature,
																			lastDate: moment(item.lastDate).format('YYYY-MM-DD'),
																		},
																	});
																}}>
																Add Q
															</Button>
														</div>
														<div
															style={{
																width: '100%',
																display: 'flex',
																height: 1,
																backgroundColor: 'black',
															}}></div>
													</>
												))}
											</Scrollbars>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '30%' }}>
						<Select
							showSearch
							className={classNames.formFullWidth}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterType: val });
							}}>
							{filterTypeDrops?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
						<label>Filter Type</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '60%' }}>
						<Field
							type='text'
							style={{ width: '100%' }}
							name='year'
							id='year'
							value={state.searchValue}
							onChange={(e) => {
								setState({ ...state, searchValue: e.target.value });
							}}
						/>
						<label>Search by {state?.searchType ? state?.searchType : 'All'}</label>
					</div>
				</Formik>
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						setItems({ ...items, newQuestionnaire: true });
					}}>
					New
				</Button>
				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
			{items.newQuestionnaire && (
				<ModalWrapper>
					{' '}
					<NewQuestionnaire
						onCloseModal={() => setItems({ ...items, newQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, newQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.settupQuestionnaire && (
				<ModalWrapper>
					{' '}
					<SettupQuestionnaire
						onCloseModal={() => setItems({ ...items, settupQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, settupQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.questionnaireAnalysis && (
				<ModalWrapper>
					{' '}
					<AnalysisQuestionnaire
						onCloseModal={() => setItems({ ...items, questionnaireAnalysis: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, settupQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.statistics && (
				<ModalWrapper>
					{' '}
					<Statistics
						onClose={() => setItems({ ...items, statistics: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, statistics: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
		</div>
	);
};
