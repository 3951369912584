import moment from "moment";
import React, { useEffect, useState } from "react";
import { degree, getClassName, plustwo, priorityFields } from "./Component";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
interface RecalculateProps {
  classId: string;
}

interface ResponseData {
  statusCode: number;
  list: {
    earnedMark: number;
  };
}
function AllotmentMemo({ values, componentRef, classes, classId, className, type, collegeData, getQuotaTypes, indexMark, quota }: any) {
  console.log({ classId });

  const tableContent = "px-3 py-0 bg-white whitespace-wrap text-left fs-6 text-black bor";
  const tableContent2 = "px-3 py-0 bg-gray-300 whitespace-wrap text-left fs-6  text-black";

  const [enable, setEnable] = useState<boolean>(false);
  const [bonasClasses, setBonasClasses] = useState<any>({});

  const Tablehelper = ({ title, value }: any) => {
    return (
      <div className="flex h-[30px] fs-6">
        <div className="w-[40%]"> {title} </div>
        <div className="w-[60%]">: {value} </div>
      </div>
    );
  };

  const [appNo, setAppNo] = useState<string>("");
  useEffect(() => {
    let appNio = "";
    if (collegeData?.collegeId === "COL-08B6B8276F75-46EE-A69C-77177AAA488B") {
      appNio = values?.applicationNo?.toString();
    } else if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      appNio = collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F" ? (type?.toUpperCase() === "UG" ? "241" + values?.applicationNo?.toString().padStart(4, "0") : "242" + values?.applicationNo?.toString().padStart(4, "0")) : values?.applicationNo;
    } else {
      setAppNo(values?.applicationNo);
    }
    setAppNo(appNio);
  }, [values?.applicationNo, collegeData?.collegeId, type]);

  useEffect(() => {
    if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [collegeData?.collegeId]);

  const recalculate = async ({ classId }: RecalculateProps) => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post<ResponseData>("/college/admissionProcess/calculateIndexMark", {
        studentId: values?._id,
        collegeId: collegeData?.collegeId,
        values,
        classId: classId,
      });
      if (resp?.data?.statusCode === 200) {
        setBonasClasses((prevBonasClasses: any) => ({
          ...prevBonasClasses,
          [classId]: resp?.data?.list?.earnedMark,
        }));
      }
    } catch (error) {
      console.error("Error calculating index mark:", error);
    }
  };

  useEffect(() => {
    for (let priorityOrder = 1; priorityOrder <= 10; priorityOrder++) {
      const classId = values?.[`preferedCourse${priorityOrder}`];
      if (classId) {
        recalculate({ classId });
      }
    }
  }, [values]);

  return (
    <div ref={componentRef} id="printable" className="printable page-break bg-white">
      <div className="watermark">SAMPLE WATERMARK</div>
      <div className="p-10">
        <table className="min-w-full">
          <tbody>
            <tr>
              <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto rounded-full" />
              </td>

              <td className={tableContent} style={{ width: "10%", fontFamily: "Times New Roman" }}>
                <img src={values?.photoURL} alt="Student Photo" className="h-full w-auto rounded-md" />
              </td>
            </tr>
          </tbody>
        </table>

        <h2 className="text-center text-[15px] m-3">{type?.toUpperCase()}-2024 Allotment Memo (Provisional)</h2>
        <h2 className="text-center text-[12px] m-3">TO BE PRODUCED BEFORE THE PRINCIPAL</h2>

        <div>
          <table className="min-w-full">
            <tbody className="min-w-full border-white ">
              <tr className="min-w-full border-white ">
                <td className="px-3 py-2 bg-white whitespace-wrap text-left text-lg text-black" style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  <Tablehelper title="Application No." value={appNo} />
                  <Tablehelper title="Name" value={values?.name?.toUpperCase()} />
                  <Tablehelper title="Date of Birth" value={values?.dob} />
                  <Tablehelper title="Gender" value={values?.gender} />
                </td>
                <td className="px-3 py-2 bg-white whitespace-wrap text-left text-lg text-black" style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  <Tablehelper title="Name of Parent/Guardian" value={values?.gardianName?.toUpperCase()} />
                  <Tablehelper title="Religion" value={values?.religion?.toUpperCase()} />
                  <Tablehelper title="Category" value={values?.category?.toUpperCase()} />
                  <Tablehelper title="Caste" value={values?.caste?.toUpperCase()} />
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                  Disabilities : {values?.disabilities}
                </td>
                {values?.disabilities === "Yes" && (
                  <>
                    <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Disability Status : {values?.disabilityStatus}
                    </td>
                    <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Disability Percentage : {values?.disabilityPercentage}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
          {enable && (
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                    Siblings College : {values?.siblingsCollege}
                  </td>
                  {values?.siblingsCollege === "Yes" && (
                    <>
                      <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                        Program of Sibling : {values?.programOfSibling}
                      </td>
                      <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                        Name of Sibling : {values?.nameOfSibling}
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          )}
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  Permanent Address
                </td>
                <td className={tableContent} style={{ width: "80%", fontFamily: "Times New Roman" }}>
                  {values?.permanentHouseName}, {values?.permanentPlace}, {values?.permanentPostOffice}, {values?.permanentDistrict}, {values?.permanentState}, {values?.permanentNationality}, Pin:{values?.permanentPinCode}
                </td>
              </tr>
            </tbody>
          </table>

          {type?.toUpperCase() === "UG" && (
            <>
              <div className="mt-4 mb-2 fs-5">12th Qualification</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                      <Tablehelper title="Reg No :" value={values?.plusTwoRegisterNo} />
                      <Tablehelper title="Board :" value={values?.plusTwoBoard} />
                    </td>
                    <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                      <Tablehelper title="Month & Year of Pass :" value={values?.plusTwoYearOfPass} />
                      <Tablehelper title="No of Chances :" value={values?.plusTwoNoOfChance} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Subject
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Obtained Mark
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Total Mark
                    </td>
                  </tr>
                </tbody>
              </table>
              {plustwo?.map((x: any) => (
                <table className="min-w-full">
                  <tbody>
                    <tr>
                      <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "Name"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "ObtainedMark"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "TotalMark"]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </>
          )}
          {type?.toUpperCase() === "PG" && (
            <>
              <div className="mt-4 mb-2 fs-5">Degree Qualification</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeRegisterNo && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Reg No : {values?.degreeRegisterNo}
                      </td>
                    )}
                    {values?.degreeYearOfPass && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Month & Year of Pass : {values?.degreeYearOfPass}
                      </td>
                    )}
                    {values?.degreeNoOfChance && (
                      <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                        No of Chances : {values?.degreeNoOfChance}
                      </td>
                    )}
                    {values?.degreeScheme && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Stream : {values?.degreeScheme}
                      </td>
                    )}
                    {values?.degreeInstState && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Institution State : {values?.degreeInstState}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeUniversity && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        University : {values?.degreeUniversity}
                      </td>
                    )}
                    {values?.degreeInstitutionName && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Institution Name : {values?.degreeInstitutionName}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeSpecialisation && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Specialisation : {values?.degreeSpecialisation}
                      </td>
                    )}
                    {values?.degreeMarkSystem && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Mark System : {values?.degreeMarkSystem}
                      </td>
                    )}
                    {values?.degreeCourse && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Programme Stream : {values?.degreeCourse}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <div className="mt-4 mb-2 fs-5">Degree Grade List</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Field Name
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Marks Awarded / CCPA
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Maximum Marks / Credit
                    </td>
                  </tr>
                </tbody>
              </table>
              {degree?.map(
                (x: any) =>
                  values?.[x?.returnKey + "MaximumMarksCredit"] && (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                            {x?.fieldName}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MarksAwardedCCPA"]}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MaximumMarksCredit"]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
              )}
            </>
          )}

          <div className="mt-4 mb-2 fs-5">Allotment Details</div>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent2} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Course
                </td>
                <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                  Quota
                </td>
                <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  Index Mark
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  {className ? className : getClassName(classId, classes)}
                </td>
                <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                  {quota}
                </td>

                <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  {indexMark}
                </td>
              </tr>
            </tbody>
          </table>

          <footer className="mt-2 p-2 bg-gray-100 border border-gray-200 rounded text-justify">
            <p className="mb-2 leading-tight">The provisional admission secured by you is based on the details furnished by you online viz. marks secured for the qualifying examination, reservation category, bonus marks/handicap marks etc. You must submit the original documents relating to the above before the Principal/Head of the Institution concerned at the time of admission. If discrepancies are detected at the time of admission or subsequent stages during the course period your allotment will be cancelled and appropriate punitive action will be taken against you. You alone will be responsible for the consequences of not deleting the higher options in the case of taking permanent admission on a lower option (college/academic programme).</p>
            <p className="mb-2 leading-tight">We have read the above and agreed to abide by the rules.</p>
            <div className="d-flex justify-content-between">
              <p>Signature of the parent/guardian</p>
              <p>Signature of the candidate</p>
            </div>
          </footer>

          <footer className="mt-2 p-2 bg-gray-100 border border-gray-200 rounded leading-tight text-justify">
            <p className="mb-1">
              <strong>For Office Use Only</strong>
            </p>
            <p className="mb-1 leading-tight">Verified the original documents submitted by the applicants with the online data and found correct and necessary entries regarding the reporting of the applicant are made in the online portal. The candidate have been permanently/temporarily admitted. Discrepancies are found in the online data furnished by the applicant and rejected admission and necessary entries have been made in the online portal.</p>
            <div className="d-flex justify-content-between">
              <div>
                <p>Signature of the Principal/Admission Officer authorised by the Principal</p>
                <p>Place:</p>
                <p>Date:</p>
              </div>
              <div>
                <p>College Seal</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default AllotmentMemo;
