import React, { useEffect, useContext, useState } from 'react';
import classNames from './studentTable.module.scss';
import { Table, Popconfirm } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { useQuery } from 'react-query';
import { getNewClasses, getPenddingAdmission } from '../../../NecttosComp/APICaller/ApiServices';
import LoadingAnimation from '../../../components/LoadingAnimation';
import moment from 'moment';
import { Checkmark } from 'react-checkmark';
import StudentData from '../StudentData';
import ModalWrapper from '../../../components/ModalWrapper';
import GiveWay from '../GiveWay';
import Verification from '../Verification';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Button } from 'antd';
import DetailsToUpload from '../DetailsToUpload';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDownKey from '../../../myComp/CustomDropDownKey';
import ViewStudentData from '../ViewStudentData';
import { ShortEdit } from '../ShortEdit';

function University({ refreshStatus, onClose }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const { data: classData } = useQuery('getNewClasses', getNewClasses(collegeId, user?.uid));
	let classId = '';
	let keyData = 'university';
	const [state, setState] = useState({});

	const { data, refetch, status } = useQuery(['getPenddingAdmissions', state.startDate, state.endingDate, state.classId], getPenddingAdmission(collegeId, classId, user.uid, keyData, state.startDate, state.endingDate, state.classId));
	useEffect(() => {
		let start = moment(new Date()).format('YYYY-MM-DD') + 'T00:01';
		let end = moment(new Date()).format('YYYY-MM-DD') + 'T23:59';
		if (!state.startDate) {
			setState({ ...state, startDate: start, endingDate: end });
		}
	}, []);

	let userList = data?.list || [];

	useEffect(() => {
		let interval = null;
		interval = setInterval(() => {
			refetch();
		}, 10000);
		return () => clearInterval(interval);
	}, [collegeId]);

	const buttonStyle = {
		height: '40px',
		width: '100px',
		position: 'absolute',
		boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
		right: '6.9%',
		top: '2%',
		padding: '1px 0px',
		backgroundColor: '#f1574a',
		color: 'white',
		fontSize: '16px',
		fontWeight: 'bold',
		border: 'none',
		borderRadius: '20px',
		cursor: 'pointer',
		transition: 'background-color 0.3s',
	};

	const Timer = ({ start, stop, date, style }) => {
		const [startTime, setStartTime] = useState(null);
		const [currentTime, setCurrentTime] = useState(null);
		const [isRunning, setIsRunning] = useState(false);

		useEffect(() => {
			let interval = null;

			if (isRunning) {
				interval = setInterval(() => {
					setCurrentTime(new Date());
				}, 1000);
			}

			return () => clearInterval(interval);
		}, [isRunning]);

		const handleStart = (date) => {
			setStartTime(new Date(date));
			setCurrentTime(new Date());
			setIsRunning(true);
		};

		const handleStop = () => {
			setIsRunning(false);
			setStartTime(null);
			setCurrentTime(null);
		};

		useEffect(() => {
			if (start) {
				handleStart(date);
			}
		}, [start, date]);

		useEffect(() => {
			if (stop) {
				handleStop();
			}
		}, [stop]);

		const getElapsedTime = () => {
			if (!startTime || !currentTime) {
				return '00:00:00';
			}

			const elapsedMilliseconds = currentTime - startTime;
			const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
			const hours = Math.floor(elapsedSeconds / 3600);
			const minutes = Math.floor((elapsedSeconds % 3600) / 60);
			const seconds = elapsedSeconds % 60;

			return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		};

		return (
			<div>
				<label style={{ ...style }}>{getElapsedTime()}</label>
			</div>
		);
	};
	const [timerStart, setTimerStart] = useState(false);

	const handleStartTimer = () => {
		setTimerStart(true);
	};

	const handleStopTimer = () => {
		setTimerStart(false);
	};

	let tableColumns = [
		{
			title: 'SL.No',
			render: (_, item, i) => i + 1,
			key: 'createdOn',
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'CAP ID',
			dataIndex: 'capId',
			key: 'capId',
		},
		{
			title: 'RC',
			dataIndex: 'admType',
			key: 'admType',
		},
		{
			title: 'Action',
			render: (_, data) =>
				data?.statusUpload === 'Uploaded' ? (
					'Uploaded'
				) : (
					<button
						onClick={() => {
							setState({ ...state, verification: true, isTemp: data.temp, studentId: data._id });
						}}
						style={{ cursor: 'pointer', width: 190, height: 50, borderRadius: 20, padding: 10, textAlign: 'center', backgroundColor: 'green' }}>
						<div>
							<div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 20, color: 'white', fontWeight: 800 }}>Upload</div>
						</div>
					</button>
				),
		},
		{
			render: (_, data) => (
				<button
					style={{ cursor: 'pointer', width: 40, height: 40, borderRadius: 20, padding: 12, textAlign: 'center', backgroundColor: 'red' }}
					onClick={() => {
						setState({ ...state, data: data, studentId: data?._id, openModal: true });
					}}>
					<div>
						<div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 20, color: 'white', fontWeight: 900 }}>E</div>
					</div>
				</button>
			),
		},
		{
			render: (_, data) => (
				<button
					style={{ cursor: 'pointer', width: 40, height: 40, borderRadius: 20, padding: 12, textAlign: 'center', backgroundColor: 'green' }}
					onClick={() => {
						setState({ ...state, items: data, studentId: data?._id, viewMode: true });
					}}>
					<div>
						<div style={{ alignContent: 'center', cursor: 'pointer', fontSize: 20, color: 'white', fontWeight: 900 }}>V</div>
					</div>
				</button>
			),
		},
		{
			title: 'Admission No.',
			render: (_, item, i) => (item?.admisionNo === 'undefined' ? '--' : item?.admisionNo),
			key: 'admisionNo',
		},

		{
			title: 'Mobile Number',
			dataIndex: 'phoneNumber',
			key: 'number',
		},
		{
			title: 'Date Of Admn',
			render: (_, item, i) => moment(new Date(item?.dateOfAdmission || item?.verification.nodalVerifyOn)).format('DD MMM YYYY'),
			key: 'createdOn',
		},
		{
			title: 'Index',
			dataIndex: 'index',
			key: 'number',
		},

		{
			title: 'Course',
			dataIndex: 'shortName',
			key: 'shortName',
		},
		{
			title: 'Course',
			render: (_, item, i) => item?.status,
			key: 'shortName',
		},
	];
	const getRowStyle = (record) => {
		const status = record.status;
		let style = {};

		if (status === 'Temporory') {
			style = { backgroundColor: 'yellow' };
		} else if (status === 'Uploaded') {
			style = { backgroundColor: 'green' };
		} else if (status === 'Finished') {
			style = { backgroundColor: 'blue' };
		}

		return style;
	};
	return (
		<>
			<div className={classNames.container}>
				<div className={classNames.modal}>
					<div className={classNames.tableView} style={{ backgroundColor: 'white' }}>
						<div className={classNames.tableHeader}>
							<h2>Pending University Uploads</h2>
							<CustomInput keyData={'startDate'} titleData={'From Date and Time'} typeData={'datetime-local'} stateData={state} setStateData={setState} />
							<CustomInput keyData={'endingDate'} titleData={'To Date and Time'} typeData={'datetime-local'} stateData={state} setStateData={setState} />
							<view style={{ width: 550 }}>
								<CustomDropDownKey keyData={'classId'} keyObject={'className'} keyReturn={'_id'} titleData={'Course wise'} dropValues={classData?.list || []} stateData={state} setStateData={setState} />
							</view>
							<div style={{ width: '30%' }}></div>
						</div>

						{status === 'loading' && <LoadingAnimation dark />}
						{status === 'success' && (
							<Scrollbars className={classNames.tableWrap}>
								<table className={classNames.userTable} id='emp'>
									<Table columns={tableColumns} dataSource={userList && userList?.length > 0 ? userList : []} pagination={false} />
								</table>
							</Scrollbars>
						)}
					</div>
					{state.viewMode && (
						<ModalWrapper>
							<ViewStudentData
								uid={state?.studentId}
								onClose={() => {
									setState({ ...state, viewMode: false });
								}}
							/>
						</ModalWrapper>
					)}
					{state.openModal && (
						<ShortEdit
							item={state?.data}
							refetchDemo={refetch}
							setStateData={setState}
							stateData={state}
							onClose={() => {
								setState({ ...state, openModal: false });
							}}
						/>
					)}{' '}
					{state.verification && (
						<ModalWrapper>
							<DetailsToUpload uid={state?.studentId} isTemp={state?.isTemp} isStudent={true} keyData={keyData} refectData={refetch} TimerFuncion={Timer} stateData={state} setStateData={setState} />
						</ModalWrapper>
					)}
				</div>
				<button className={classNames.reloadButton} style={buttonStyle}>
					<ReactHTMLTableToExcel id='test-table-xls-button' className='download-table-xls-button' table='emp' filename='collectionReport' sheet='tablexls' buttonText='EXCEL' />
				</button>
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			</div>
		</>
	);
}

export default University;
