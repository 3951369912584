import React, { useContext, useState } from 'react';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

const getProfileGraph = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/profile/getGraphData', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);

			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const TeacherProfile = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [dataSelected, setDataSelected] = useState({});
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [state, setState] = React.useState({});

	console.log({ dataSelected });

	const { data: allData = [], refetch } = useQuery(['getteacherProfileGraph', state.classId, state.searchValue], getProfileGraph({ collegeId: selectedCollege.collegeId, classId: state.classId, setLoading }));

	if (allData.length > 0) {
		setState(false);
	}

	const DataBar = ({ name, percentage, onClick }) => {
		const getColor = (percentage) => {
			if (percentage < 25) {
				return 'bg-red-600';
			} else if (percentage < 50) {
				return 'bg-yellow-600';
			} else if (percentage < 75) {
				return 'bg-blue-600';
			} else {
				return 'bg-green-600';
			}
		};

		const bgColor = getColor(percentage);

		return (
			<div className={`cursor-pointer p-4 rounded-lg shadow-lg overflow-hidden ${bgColor} transition-all duration-300 hover:scale-105 hover:shadow-md`} onClick={onClick}>
				<div className='text-xl font-semibold text-white text-opacity-90'>{name}</div>
				<div className={`text-3xl font-bold text-white mt-2 leading-none ${percentage < 10 ? 'mb-4' : 'mb-0'}`}>{percentage?.toFixed(2)}%</div>
				<div className={`h-2 w-full bg-[#851d1d] bg-opacity-20 mt-3`} style={{ width: `${100}%` }}>
					<div className={`h-2 w-full bg-white bg-opacity-20 mt-3`} style={{ width: `${percentage}%` }} />
				</div>
			</div>
		);
	};

	const DataCard = ({ label, value, bgColor }) => (
		<div className={`flex flex-row items-center text-white text-center p-4 rounded-lg ${bgColor} shadow-lg`}>
			<div className='font-bold text-xl'>{label}</div>
			<div className='ml-2 text-lg'>{value?.toFixed(2)}%</div>
		</div>
	);

	return (
		<>
			<Main width='auto' height='auto' title='Teachers Profile Statistics'>
				<>
					<div className='p-4'>
						<div className='flex flex-wrap justify-center gap-4 mb-8'>
							<DataCard label='Unverified Data' value={allData.percentageOfPendingData - allData.percentageOfVerifiedData} bgColor='bg-red-500' />
							<DataCard label='Verified Data' value={allData.percentageOfVerifiedData} bgColor='bg-green-500' />
							<DataCard label='Total Data' value={allData.percentageOfPendingData} bgColor='bg-blue-500' />
						</div>
						<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-11 overflow-scroll'>
							{allData?.list?.map((item) => (
								<DataBar
									key={item?.title}
									name={item?.title}
									percentage={item?.percentagePendingd}
									onClick={() => {
										setShowForm(true);
										setDataSelected(item);
									}}
									color={item.color}
								/>
							))}
						</div>
					</div>
				</>

				<Button
					type='fetch'
					onClick={() => {
						refetch();
					}}>
					Refresh
				</Button>

				<Button type='close' onClick={onClose}>
					Close
				</Button>
			</Main>
			{showForm && (
				<Main width='auto' height='auto' title={dataSelected.title + ' Profile Statistics'}>
					<>
						<div className='p-4'>
							<div className='flex flex-wrap justify-center gap-4 mb-8'>
								<DataCard label='Unverified Data' value={dataSelected.percentagePendingd - dataSelected.percentageAprovedd} bgColor='bg-red-500' />
								<DataCard label='Verified Data' value={dataSelected.percentageAprovedd} bgColor='bg-green-500' />
								<DataCard label='Total Data' value={dataSelected.percentagePendingd} bgColor='bg-blue-500' />
							</div>
							<div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-11 overflow-scroll'>
								{dataSelected?.data?.map((item) => (
									<DataBar
										key={item?.name}
										name={item?.name}
										percentage={item?.percentageOfCompletion2}
										onClick={() => {
											setShowForm(true);
											setDataSelected(item);
										}}
										color={item.color}
									/>
								))}
							</div>
						</div>
					</>

					<Button
						type='fetch'
						onClick={() => {
							refetch();
						}}>
						Refresh
					</Button>

					<Button
						type='close'
						onClick={() => {
							setShowForm(false);
							setDataSelected({});
						}}>
						Close
					</Button>
				</Main>
			)}
		</>
	);
};
