import React from 'react'
import Scrollbars from 'react-custom-scrollbars';
import classNames from "./collectFee.module.scss";

export default function CollectFee({ feeSummary, setTotalAmount }) {

    const fees = Object.values(feeSummary).flat();

    const sumOffee = () => {
        setTotalAmount(fees.reduce((x, y) => x + Number(y.fee), 0))
        return fees.reduce((x, y) => x + Number(y.fee), 0)
    }

    const otherFees = Object.entries(feeSummary)?.filter(([key, _]) => !['TuitionFee', 'ExamFee'].includes(key));

    return (
        <div className={classNames.collectFeeModule}>
            <div className={classNames.outerDiv}>
                <div className={classNames.listItem}>
                    <div className={classNames.optionBox}>
                        <p> <strong> Total Amount</strong> </p>
                        <span> {sumOffee()}</span>
                    </div>
                </div>
            </div>
            <div className={classNames.list}>
                {
                    Object.entries(feeSummary)?.filter(([key, _]) => ['TuitionFee'].includes(key))?.map(([key, value]) =>
                        <>
                            <span style={{ alignSelf: 'center', fontFamily: 'arial', fontSize: '14px', color: 'Green' }}>Tution Fee</span>

                            <div className={classNames.subList}>
                                {
                                    value?.filter(x => x.fee)?.map(option => (
                                        <div className={classNames.listItem}>
                                            <div className={classNames.optionBox}>
                                                <p> {option.title}</p>
                                                <span> {option.fee}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
   {
                    Object.entries(feeSummary)?.filter(([key, _]) => ['Scholarship'].includes(key))?.map(([key, value]) =>
                        <>
                            <span style={{ alignSelf: 'center', fontFamily: 'arial', fontSize: '14px', color: 'Green' }}>Tution Fee</span>

                            <div className={classNames.subList}>
                                {
                                    value?.filter(x => x.fee)?.map(option => (
                                        <div className={classNames.listItem}>
                                            <div className={classNames.optionBox}>
                                                <p> {option.title}</p>
                                                <span> {option.fee}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
                {Object.entries(feeSummary)?.filter(([key, _]) => ['ExamFee'].includes(key))?.map(([key, value]) =>
                    < >
                        <span style={{ alignSelf: 'center', fontFamily: 'arial', fontSize: '14px', color: 'Green' }}>Exam Fee</span>

                        <div className={classNames.subList}>
                            {
                                value?.filter(x => x.fee)?.map(option => (
                                    <div className={classNames.listItem}>
                                        <div className={classNames.optionBox}>
                                            <p> {option.title}</p>
                                            <span> {option.fee}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )}

                {
                    otherFees?.length ? (
                        <span style={{ alignSelf: 'center', fontFamily: 'arial', fontSize: '14px', color: 'Green' }}>Other Fees</span>

                    ) : null
                }
                <div className={classNames.subList}>

                    {
                        otherFees?.map(([key, value]) =>
                            value?.filter(x => x.fee)?.map(option => (
                                <div className={classNames.listItem}>
                                    <div className={classNames.optionBox}>
                                        <p> {option.title}</p>
                                        <span> {option.fee}</span>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
                <div className={classNames.outerDiv}>
                    <div className={classNames.listItem}>
                        <div className={classNames.optionBox}>
                            <p> <strong> Total Amount</strong> </p>
                            <span> {sumOffee()}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
