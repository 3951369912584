import React, { useContext, useEffect, useRef, useState } from 'react';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import LoadingAnimation from '../../components/LoadingAnimation';
import Input from '../../NecttosComp/Input/Input';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Tr, Thead, useSortableData } from '../../NecttosComp/Table/Table';
import { Select } from 'antd';
import Button from '../../NecttosComp/Button/Button';
import { getMainAccounts, getSettings } from '../../NecttosComp/APICaller/ApiServices';
import SettingsList from '../../NecttosComp/Settings';
import { CollegeHeader } from '../../NecttosComp/CollegeHeader';

export const MainAccounts = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const componentRef = useRef();

	let { data = [], refetch } = useQuery('getMainAccounts', getMainAccounts(collegeId));
	let { data: drops = [] } = useQuery('getAccounts', getSettings(collegeId, 'accounts'));

	const typeOfAccounts = drops?.settings?.find((x) => x?.keyTitle === 'typeOfAccounts')?.options || [];
	const mainGroup = drops?.settings?.find((x) => x?.keyTitle === 'mainCategory')?.options || [];
	const subGroup = drops?.settings?.find((x) => x?.keyTitle === 'subCategory')?.options || [];

	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState({
		name: '',
		category: '',
		subCategory: '',
		accountClassifications: '',
		accountCategories: '',
		debitCredit: '',
		showAccount: false,
	});
	const [filteredData, setFilteredData] = useState(data);
	const [tableIstems, setTableItems] = useState(filteredData);
	const [showModal, setShowModal] = useState(false);
	const [edit, setEdit] = useState(false);
	const [settings, setSettings] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const typeOfGroups = drops?.settings?.find((x) => x?.title === state.accountClassifications)?.options || [];

	const manageMainAccounts = async (deleteData, _id) => {
		try {
			setIsLoading(true);
			if (!state.name && !_id) {
				throw new Error('Account Name is Empty');
			}

			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postMainAccounts', {
				collegeId,
				name: state?.name,
				category: state?.category,
				subCategory: state?.subCategory,
				accountCategories: state?.accountCategories,
				accountClassifications: state?.accountClassifications,
				debitCredit: state?.debitCredit,
				showAccount: state?.showAccount,
				deleteData,
				_id: state?._id || _id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({});
				refetch();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	data = data?.sort((a, b) => a?.name?.localeCompare(b?.name));

	useEffect(() => {
		setFilteredData(data);
		setTableItems(data);
	}, [data]);
	const [loading, setLoading] = useState(false);
	// Enhanced Filter Handler
	const handleFilterChange = (key, value) => {
		if (value === 'All') {
			setFilteredData(data);
			setTableItems(data);
		} else {
			const filtered = data.filter((item) => item[key]?.toLowerCase() === value.toLowerCase());
			setFilteredData(filtered);
			setTableItems(filtered);
		}
	};

	// Unified Search Functionality
	const handleSearch = (searchText) => {
		setLoading(true);
		const filtered = data.filter((item) => Object.values(item).some((val) => String(val).toLowerCase().includes(searchText.toLowerCase())));
		setFilteredData(filtered);
		setTableItems(filtered);
		setLoading(false);
	};

	const { tableItems, requestSort, getIcon } = useSortableData(filteredData);
	return (
		<Main zInd={100} width='100%' height='100%' title='Main Accounts Management'>
			<Header>
				<div className='flex flex-wrap items-center justify-between gap-4 mb-4'>
					{/* Filter by Primary Group */}
					<Select showSearch className='w-64' placeholder='Filter by Primary Group...' onChange={(value) => handleFilterChange('category', value)} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} loading={loading}>
						<Select.Option value='All'>See All</Select.Option>
						{mainGroup.map((account) => (
							<Select.Option key={account} value={account}>
								{account}
							</Select.Option>
						))}
					</Select>

					{/* Filter by Secondary Group */}
					<Select showSearch className='w-64' placeholder='Filter by Secondary Group...' onChange={(value) => handleFilterChange('subCategory', value)} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} loading={loading}>
						<Select.Option value='All'>See All</Select.Option>
						{subGroup.map((account) => (
							<Select.Option key={account} value={account}>
								{account}
							</Select.Option>
						))}
					</Select>

					{/* Filter by Account Type */}
					<Select showSearch className='w-64' placeholder='Filter by Account Type...' onChange={(value) => handleFilterChange('typeOfAccount', value)} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} loading={loading}>
						<Select.Option value='All'>See All</Select.Option>
						{typeOfAccounts.map((account) => (
							<Select.Option key={account} value={account}>
								{account}
							</Select.Option>
						))}
					</Select>

					<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} onChange={(e) => handleSearch(e.value)} type='text' width='15vw' />
				</div>

				<div className='flex'>
					<Button type='save' onClick={() => setEdit(!edit)}>
						{edit ? 'Print Mode' : 'Action Mode'}
					</Button>
					<Button type='save' onClick={() => setShowModal(true)}>
						Add Account
					</Button>
					<Button type='save' onClick={() => setSettings(true)}>
						Settings
					</Button>
					<Button type='print' tableRef={componentRef.current}>
						Print
					</Button>
					<Button color='orange' onClick={() => window.location.reload()}>
						Reload
					</Button>
					{!isLoading && (
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					)}
				</div>
			</Header>

			<Thead>
				<Tr>
					<Th position={0}>S.N</Th>
					<Th position={3} onClick={() => requestSort('_id')} icon={getIcon('_id')}>
						Account ID
					</Th>
					<Th position={2} onClick={() => requestSort('name')} icon={getIcon('name')}>
						Account Name
					</Th>
					<Th position={1} onClick={() => requestSort('category')} icon={getIcon('category')}>
						Primary Account Groups
					</Th>
					<Th position={1} onClick={() => requestSort('subCategory')} icon={getIcon('subCategory')}>
						Secondary Account Groups
					</Th>
					<Th position={1} onClick={() => requestSort('subCategory')} icon={getIcon('subCategory')}>
						Account Classifications
					</Th>
					<Th position={1} onClick={() => requestSort('priority')} icon={getIcon('priority')}>
						Account Categories
					</Th>
					{edit && <Th position={1}>Action</Th>}
				</Tr>
			</Thead>
			<Body>
				<Table width='100%' innerWidth={['5%', '10%', '15%', '8%']}>
					{isLoading ? (
						<LoadingAnimation dark />
					) : (
						<Tbody style={{ display: 'block', height: '400px', overflowY: 'scroll' }}>
							{tableItems?.length === 0 ? (
								<Tr>
									<Td colSpan={9} className='text-center'>
										No Data Found
									</Td>
								</Tr>
							) : (
								tableItems?.map((item, index) => (
									<Tr key={index} style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
										<Td index={index} position={0} textAlign='center' fontSize='14px'>
											{index + 1}
										</Td>
										<Td index={index} position={3} textAlign='center' fontSize='14px'>
											<button
												onClick={() => navigator.clipboard.writeText(item._id)}
												style={{
													backgroundColor: 'transparent',
													border: 'none',
													cursor: 'pointer',
													fontSize: '14px',
													color: 'blue',
													textDecoration: 'underline',
												}}>
												Copy ID
											</button>
										</Td>

										<Td index={index} position={2} textAlign='left' fontSize='14px'>
											{item.name}
										</Td>
										<Td index={index} position={1} textAlign='left' fontSize='14px'>
											{item.category}
										</Td>
										<Td index={index} position={1} textAlign='left' fontSize='14px'>
											{item.subCategory}
										</Td>
										<Td index={index} position={1} textAlign='left' fontSize='14px'>
											{item.accountClassifications}
										</Td>
										<Td index={index} position={1} textAlign='left' fontSize='14px'>
											{item.accountCategories}
										</Td>

										{edit && (
											<Td index={index} position={1} textAlign='center' fontSize='14px'>
												<div className='flex justify-center gap-2'>
													<Button
														width={!item.used ? '60px' : '135px'}
														type='save'
														onClick={() => {
															setState({
																...state,
																name: item?.name || '',
																category: item?.category || '',
																subCategory: item?.subCategory || '',
																priority: item?.priority || '',
																showAccount: item?.showAccount || '',
																debitCredit: item?.debitCredit || '',
																_id: item?._id || '',
															});
															setShowModal(true);
														}}>
														Edit
													</Button>
													{!item.used && (
														<Button
															width={'60px'}
															type='delete'
															onClick={() => {
																manageMainAccounts(true, item?._id);
															}}>
															Delete
														</Button>
													)}
												</div>
											</Td>
										)}
									</Tr>
								))
							)}
						</Tbody>
					)}
				</Table>
				<Main title={state?._id ? 'Edit Account' : 'Add New Account'} width='30%' height='auto' bgColor='white' visible={showModal}>
					<div className='p-4'>
						<Input typable width='100%' didntShow fieldName='Account Name' returnKey='name' state={state} setState={setState} distinctKey='name' collectionName={'mainAccounts'} type='text' />
						<Input typable width='100%' didntShow fieldName='Primary Account Groups' returnKey='category' state={state} setState={setState} options={mainGroup} type='drop' />
						<Input typable width='100%' didntShow fieldName='Secondary Account Groups' returnKey='subCategory' state={state} setState={setState} options={subGroup} type='drop' />
						<Input typable width='100%' didntShow fieldName='Account Classifications' returnKey='accountClassifications' state={state} setState={setState} options={typeOfAccounts} type='drop' />
						<Input typable width='100%' didntShow fieldName='Account Categories' returnKey='accountCategories' state={state} setState={setState} options={typeOfGroups} type='drop' />
						<Input typable width='100%' didntShow fieldName='Debit/Credit' returnKey='debitCredit' state={state} setState={setState} options={['Credit']} type='drop' />
						<div className='flex items-center justify-between p-3 border border-gray-300 rounded-lg bg-gray-50 shadow-sm hover:shadow-md transition-shadow duration-200'>
							<label htmlFor='showAccount' className='text-lg font-semibold text-gray-600 flex-grow'>
								Show Account
							</label>
							<div>
								<input id='showAccount' type='checkbox' className='w-6 h-6 cursor-pointer rounded border-gray-400 focus:ring-blue-500 focus:ring-2' checked={state.showAccount} onChange={() => setState({ ...state, showAccount: !state.showAccount })} />
							</div>
						</div>
						<div className='flex justify-evenly mt-4 gap-2'>
							<Button
								width='100px'
								type='close'
								onClick={() => {
									setShowModal(false);
								}}>
								Close
							</Button>
							<Button
								width='100px'
								type='doc'
								onClick={() => {
									setState({});
								}}>
								Clear
							</Button>
							<Button
								type='submit'
								width='100px'
								color='blue'
								onClick={() => {
									manageMainAccounts();
								}}>
								Submit
							</Button>
						</div>
					</div>
				</Main>
				<Main title={'Settings'} width='99%' height='99%' bgColor='white' visible={settings}>
					<SettingsList
						onClose={() => {
							setSettings(false);
						}}
					/>
				</Main>
			</Body>
		</Main>
	);
};
