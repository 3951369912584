import { Modal, Input, message } from "antd";
import { useEffect, useState } from "react";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";

const MobileNumberUpdateModal = ({ isOpen, onClose, modalData, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneInput, setPhoneInput] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
      setPhoneInput("");
    }
  }, [isOpen]);

  const updatePhoneNumber = async () => {
    const phoneNumber = phoneInput.trim();

    if (!/^\d{10}$/.test(phoneNumber)) {
      return message.error("Please Enter A Valid 10 digit Phone Number");
    }
    try {
      setIsLoading(true);
      const instance = await getAxiosTokenInstance();
      const { data } = await instance.post("/college/profile/updatePhone", {
        userId: modalData?.uid,
        newNumber: phoneNumber,
      });

      if (data?.statusCode !== 200) {
        throw new Error(data.message);
      }

      message.success(data.message);
      onClose();
      refetch && refetch();
    } catch (error) {
      message.error(
        error?.response?.data?.message || error.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={`Update ${modalData?.name}'s Phone Number`}
      visible={isOpen}
      onOk={updatePhoneNumber}
      confirmLoading={isLoading}
      onCancel={onClose}
      okText="Update"
    >
      <p>
        <h4 style={{ marginBottom: 20 }}>
          Current Phone Number : {modalData?.currentNumber}
        </h4>
        <Input
          placeholder="New PhoneNumber without counrty code"
          value={phoneInput}
          onChange={(e) => setPhoneInput(e.target.value)}
        />
      </p>
    </Modal>
  );
};

export default MobileNumberUpdateModal;
