import React, { useEffect, useState } from 'react';
import { EditDetails, getDropdownData } from '../../NecttosComp/APICaller/ApiServices';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, widthChangeAnimation } from '../../NecttosComp/Layout/Layout';
import Input from '../../NecttosComp/Input/Input';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
// import { ProgressBar, calculateProfilepPercentage } from "../../../NecttosComp/ProgressBar/ProgressBar";
import Loader from '../../NecttosComp/Loader/Loader';
// import { ProfileDataDisplayKeyVlaue } from "../../../FunctionalExport/Formate";
import { ProfileDataDisplayKeyVlaue, ProgressBar, calculateProfilepPercentage } from '../NewProfile';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}

function BankDetails({ onClose, studentId, onNext, modal }: Props) {
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { data: profileData, refetch } = useProfileData();

	const [values, setValues] = useState({});

	useEffect(() => {
		setValues({
			beneficiary: profileData?.pendingData?.bankDetails?.beneficiary || profileData?.bankDetails?.beneficiary,
			ifcs: profileData?.pendingData?.bankDetails?.ifcs || profileData?.bankDetails?.ifcs,
			panCard: profileData?.pendingData?.bankDetails?.panCard || profileData?.bankDetails?.panCard,
			accountNo: profileData?.pendingData?.bankDetails?.accountNo || profileData?.bankDetails?.accountNo,
		});
	}, [profileData]);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	const placeHoldValues = {
		beneficiary: ' Beneficiary',
		ifcs: 'IFSC Code',
		panCard: 'Pancard',
		accountNo: 'Acc. No.',
	};

	return (
		<>
			{/* <Main title="Bank Information" height="90vh" width={"60vw"}> */}
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap justify-center'>
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>

				<Body width='100%' height='93%'>
					<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 w-full h-full place-items-center'>
						<div className='px-3 py-2 max-w-[45vw] w-full h-full flex flex-col justify-between shadow rounded bg-white' style={{ backgroundColor: 'white', color: 'black' }}>
							<div className='w-full h-auto p-4'>
								<ProgressBar title='Verified Data' progress={parseInt(calculateProfilepPercentage(profileData?.bankDetails, placeHoldValues)?.percentage) || 0} />
							</div>

							<div className='w-full h-auto overflow-y-scroll flex-1'>
								<ProfileDataDisplayKeyVlaue temp={profileData?.bankDetails} placeHoldValues={placeHoldValues} title='Verified Data' />
							</div>

							{!isEditing ? (
								<Button onClick={() => setIsEditing(!isEditing)} type='update'>
									Edit
								</Button>
							) : null}
						</div>

						{isEditing && (
							<div className='w-full h-full max-w-[40vw] p-4 flex flex-col justify-between items-center shadow rounded bg-white' style={{ backgroundColor: 'white', color: 'black' }}>
								<div className='w-full h-auto p-4'>
									<ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
								</div>

								<h5 className='text-blue-900 text-[1.2vw] font-bold text-center'>You can edit your data here!</h5>

								<div id='scrollBarEnable' className='px-3 py-2 w-[35vw] z-50 overflow-auto h-full max-h-[65vh]'>
									<Input width='98%' fieldName='Beneficiary' returnKey='beneficiary' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='IFC' returnKey='ifcs' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='PAN Card' returnKey='panCard' setState={setValues} state={values} type='text' />
									<Input width='98%' fieldName='Account No.' returnKey='accountNo' setState={setValues} state={values} type='text' />
								</div>

								<Button
									width='100%'
									onClick={async () => {
										setIsLoading(true);
										await EditDetails({ bankDetails: values });
										await refetch();
										setIsEditing(!isEditing);
										setIsLoading(false);
									}}
									type='save'
									children='Save'
								/>
							</div>
						)}
					</div>
				</Body>
			</div>
			{/* </Main> */}
			{isLoading && <Loader />}
		</>
	);
}

export default BankDetails;
