import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../NecttosComp/Button/Button";
import { Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import { getAxiosInstance, getAxiosTokenInstance } from "../../utils/axiosInstance";
import Input from "../../NecttosComp/Input/Input";
import ReactToPrint from "react-to-print";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";
import { log } from "console";
import { message } from "antd";

interface invoiceManagementProps {
  onClose: () => void;
}

function TimeTableManagement({ onClose }: invoiceManagementProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const { collegeId, user } = useContext(FirebaseContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [examSubjectData, setExamSubjectData] = useState<any>({});
  const [values, setValues] = useState<any>({});
  const [collegeData, setCollegeData] = useState<any>([]);

  

  const getExaminationDetails = (collegeId: any) => async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.get("/college/examProcess/getExamInit", {
        params: { collegeId },
      });
      console.log("Examinit", resp.data);
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      } else {
        return resp?.data?.list;
      }
    } catch (error) {
      console.error("API Error:", error);
      throw new Error("API Error");
    }
  };

  const { data: examData = [], refetch } = useQuery("getExamInit", getExaminationDetails(collegeId));

  const getExamSubjectData = async () => {
    setIsLoading(true);
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .get("/college/examProcess/getExamSubjectData", { params: { collegeId, userId: user.uid, studentId: values?._id, examId: exam } })
        .then((res: any) => {
          setExamSubjectData(res.data);
          console.log("res", res.data);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (err) {
      throw new Error("API Error Bundle Data");
    }
  };

  useEffect(() => {
    getExamSubjectData();
  }, []);

  console.log("examSubjectData", examSubjectData);

  const getCollege = async () => {
    try {
      const instance = await getAxiosInstance();
      const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
        params: { collegeId },
      });

      if (data?.statusCode === 200) {
        setCollegeData(data.collegeData);
        return data.collegeData;
      } else {
        throw new Error(data?.message || "Failed to fetch college details.");
      }
    } catch (error) {
      throw new Error("API Error");
    }
  };
  useEffect(() => {
    getCollege();
  }, []);

  // console.log("CollegeData", collegeData);
  const [exam, setExam] = useState<any>("");

  const [examDates, setExamDates] = useState({
    //exam datesubmit
    AEC: "",
    AEC_OL: "",
    DSC_A: "",
    DSC_B: "",
    DSC_C: "",
    MDC: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setExamDates({ ...examDates, [name]: value });
  };

  //submit dates

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log('Submitted Data:', examDates);
    try {
      const instance = await getAxiosTokenInstance();
      const response = await instance.post("/college/examProcess/examDates", examDates);
      if (response.status === 200) {
        message.success("Dates submitted successfully");
        onClose();
        setExamDates({
          AEC: "",
          AEC_OL: "",
          DSC_A: "",
          DSC_B: "",
          DSC_C: "",
          MDC: "",
        });
      }
    } catch (error) {
      message.error("Error submitting the dates");
    }
  };
  // console.log("Submitted Data:", examDates);

  //get exam dates

  const [submittedDates, setSubmittedDates] = useState<any>(null);
  const getSubmittedDates = async () => {
    try {
      const instance = await getAxiosInstance();
      const { data } = await instance.get("/college/examProcess/examDates");
      if (data?.statusCode === 200) {
        setSubmittedDates(data.examDates);
      } else {
        throw new Error(data?.message || "Failed to fetch submitted dates.");
      }
    } catch (error) {
      console.error("API Error: ", error);
    }
  };
  console.log("gettingdates", submittedDates);

  useEffect(() => {
    getSubmittedDates();
  }, [examDates]);

  const handleExamSelect = async (e: any) => {
    const { name, value } = e.target;
    setExam(value);
    await getExamSubjectData();
  };
  console.log(exam);



  const [examRegisterData, setExamRegisterData] = useState<any>([]);
  const getAllExamRegisterData = async () => {
    console.log("called");

    setIsLoading(true);
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .get("/college/examProcess/getExamRegisterData", {
          params: { collegeId, userId: user.uid, studentId: values?._id,examId:exam },
        })
        .then((res: any) => {
          console.log("examRegData", res.data);
          setExamRegisterData(res.data.list);
          console.log("res", res);

          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (err) {
      throw new Error("API Error Bundle Data");
    }
  };

  useEffect(() => {
    // getCollege()
    getAllExamRegisterData();
  }, [exam]);

  console.log("examRegisterData", examRegisterData);


  return (
    <>

    
      <Main title=" Exam TimeTable Management " width="100vw" height="100% ">
        <div className="flex flex-row justify-end self-end p-4">
          <ReactToPrint trigger={() => <Button type="fetch">Print</Button>} content={() => componentRef.current} />
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
        <select className="w-[290px] h-20 my-2  shadow rounded" name="exam" id="" onChange={handleExamSelect}>
          ̦<option value="">Choose Examination</option>
          {examData.map((exam: any) => (
            <option value={exam._id}>{exam._id}</option>
          ))}
        </select>
        <div className="flex flex-row   h-[80%] overflow-hideen">
          <div className="w-4/5 h-[100%]  flex flex-col space-y-1 p-4 bg-white rounded-lg shadow-lg">
            <div className="w-full bg-slate-300 text-lg font-semibold rounded p-1 text-center">Choose the Dates</div>
            <label className="text-lg font-semibold">AEC Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose AEC exam date" type="date" name="AEC" value={examDates.AEC} onChange={handleChange} />

            <label className="text-lg font-semibold">AEC_OL Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose ACE_OL exam date" type="date" name="AEC_OL" value={examDates.AEC_OL} onChange={handleChange} />

            <label className="text-lg font-semibold">DSC_A Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose DSC_A exam date" type="date" name="DSC_A" value={examDates.DSC_A} onChange={handleChange} />

            <label className="text-lg font-semibold">DSC_B Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose DSC_B exam date" type="date" name="DSC_B" value={examDates.DSC_B} onChange={handleChange} />

            <label className="text-lg font-semibold">DSC_C Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose DSC_C exam date" type="date" name="DSC_C" value={examDates.DSC_C} onChange={handleChange} />

            <label className="text-lg font-semibold">MDC Exam</label>
            <input className="border border-gray-300 rounded p-2" placeholder="Choose MDC exam date" type="date" name="MDC" value={examDates.MDC} onChange={handleChange} />

            <div className="self-end">
              <button onClick={handleSubmit} className="w-4/5 bg-blue-500 text-white rounded p-2 mt-4" type="submit">
                Submit
              </button>
            </div>
          </div>
            <div ref={componentRef} className="printable w-auto bg-white p-10  overflow-scroll  "style={{marginLeft:'5%',marginRight:'5%',marginBottom:'2%'}}>
              <div className="flex flex-col items-center">
                <h5 className="mt-2 text-center underline ">{collegeData.collegeName}</h5>
                <div className="flex justify-between w-full mt-2 mb-2">
                  <p>25785/EA1-1/Integrated PG</p>
                  <p>P.D.Hills,15.06.2024</p> 
                </div>
                <p className="text-center font-bold underline">NOTIFICATION</p>
                <p className="mt-3 text-justify leading-relaxed">It is hereby notified that the [[[[[ Tempora nulla ex itaque sit voluptatum. Inventore error, laborum tempora excepturi quod nihil! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum voluptatibus asperiores facere a optio eaque quod fuga ad nemo recusandae labore debitis modi, id architecto maiores voluptatum amet officiis sequi. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores accusamus, minima quia dicta qui reprehenderit ]]]]] Examinations are scheduled to be conducted from 05.07.2024 as detailed below</p>
                <p className="text-center font-bold mt-4 underline">Time Table</p>
                <p className="text-center">(Time: 01.30 pm to 04.30 pm on all days, except on Fridays. On Fridays: 02.00 pm to 05.00 pm)</p>
 <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        examRegisterData.map((item:any) => (
          <strong  key={item._id}>{item.examName}</strong>
        ))
      )}
    </div>              </div>

              <table className="table-auto border-collapse border border-gray-300 w-full">
                <thead>
                  <tr>
                    <th className="border text-center border-gray-300 px-2 w-[120px] py-1">Day & Date</th>
                    <th className="border text-center border-gray-300 px-2 w-[100px] py-1">Type</th>
                    <th className="border text-center border-gray-300 px-4 py-2 w-[120px]">Code</th>
                    <th className="border text-center border-gray-300 px-4 py-2">Subject</th>
                  </tr>
                </thead>
                <tbody>
                  {examSubjectData?.subjectData &&
                    Object.entries(examSubjectData?.subjectData).map(([key, item]: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td colSpan={4} className="border border-gray-300 px-4 py-2 text-center bg-gray-100 font-semibold">
                            {item.subject?.subjectName}
                          </td>
                        </tr>
                        {item?.subject?.subSubjects?.map((x: any, subIndex: number) => (
                          <tr key={subIndex}>
                           <td className="border border-gray-300 px-4 py-2 text-center">{submittedDates && submittedDates[x.subType] ? submittedDates[x.subType] : "n/a"} </td>
                               <td className="border border-gray-300 px-4 py-2 text-center">{x.subType}</td>
                            <td className="border border-gray-300 px-4 py-2  text-center">{x.subcode}</td>
                            <td className="border border-gray-300 px-4 py-2 text-center">{x.subName}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>

              <p style={{ fontSize: "11px" }}>
                The last date for the receipt of applications in the University Office is
                <strong>24.06.2024</strong> (without fine),
                <strong>26.06.2024</strong> (with a fine of 615/-) and
                <strong>28.06.2024</strong> (with a superfine of 1225/-). Applications received after the last date will be summarily rejected.
              </p>
              <div className=" mt-5 text-end">
                <p className="leading-[4px]">Dr. SREEJITH C M</p>
                <p className="leading-[4px]">CONTROLLER OF THE EXAMINATIONS</p>
              </div>
              <p>To</p>
              <ol>
                <li className="leading-[4px]">PRO/Enquiry</li>
                <li className="leading-[4px]" >PS to VC/PVC</li>
                <li className="leading-[4px]">The Principal of College Concerned</li>
                <li className="leading-[4px]">The Principal of College Concerned</li>
              </ol>
            </div>
          
        </div>
      </Main>
    </>
  );
}

export default TimeTableManagement;
