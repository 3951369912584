import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { degree, getClassName, getMinorName, plustwo, priorityFields } from './Component';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getAllMinor } from '../../NecttosComp/APICaller/ApiServices';
import { useQuery } from 'react-query';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { Certificate } from 'crypto';
interface RecalculateProps {
	classId: string;
}

interface ResponseData {
	statusCode: number;
	list: {
		indexMark: any;
		earnedMark: number;
	};
}
function MCAApplication({ values, componentRef2, classes, type, collegeData, getQuotaTypes }: any) {
	const tableContent = 'px-3 py-2 bg-white whitespace-wrap text-left text-lg fw-normal text-black border-2 content-center';
	const tableContent3 = 'px-3 py-2 bg-white whitespace-wrap text-left text-md fw-normal text-black border-2 content-center';
	const tableHeading = 'px-3 py-2 bg-white whitespace-wrap text-left text-xl fw-bold text-black bor content-center text-center';
	const tableContent2 = 'px-3 py-2 bg-gray-300 whitespace-wrap text-left text-lg fw-bold text-black';

	const [enable, setEnable] = useState<boolean>(false);
	const [bonasClasses, setBonasClasses] = useState<any>({});

	const Tablehelper = ({ title, value }: any) => {
		return (
			<div className='flex h-[30px]'>
				<div className='w-[20%]'> {title} </div>
				<div className='w-[80%]'>: {value} </div>
			</div>
		);
	};
	const tdStyle = {
		width: '100%',
		top: '9%',
		height: 'max-content',
		position: 'static',
		verticalAlign: 'bottom',
		borderWidth: ' 2px',
	};

	const [appNo, setAppNo] = useState<string>('');

	const { data: minorData = [] } = useQuery('getAllMinor', getAllMinor(collegeData?.collegeId));

	useEffect(() => {
		let appNio = '';
		if (collegeData?.collegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B') {
			appNio = values?.applicationNo?.toString();
		} else if (collegeData?.collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F') {
			const paddedApplicationNo = values?.applicationNo?.toString().padStart(4, '0');
			appNio = collegeData?.collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' ? (type?.toUpperCase() === 'UG' ? '241' + values?.applicationNo?.toString().padStart(4, '0') : '242' + values?.applicationNo?.toString().padStart(4, '0')) : values?.applicationNo;
		} else {
			setAppNo(values?.applicationNo);
		}
		setAppNo(appNio);
	}, [values?.applicationNo, collegeData?.collegeId, type]);

	useEffect(() => {
		if (collegeData?.collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F') {
			setEnable(false);
		} else {
			setEnable(true);
		}
	}, [collegeData?.collegeId]);

	const recalculate = async ({ classId }: RecalculateProps) => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post<ResponseData>(type?.toUpperCase() === 'UG' ? '/college/admissionProcess/calculateIndexMark' : '/college/admissionProcess/calculateIndexMarkPG', {
				studentId: values?._id,
				collegeId: collegeData?.collegeId,
				values,
				classId: classId,
			});
			// console.log({ resp });

			if (resp?.data?.statusCode === 200) {
				setBonasClasses((prevBonasClasses: any) => ({
					...prevBonasClasses,
					[classId]: resp?.data?.list?.earnedMark?.toFixed(2),
				}));
			}
		} catch (error) {
			console.error('Error calculating index mark:', error);
		}
	};
	// console.log("values", values)
	// console.log("collegedata", collegeData)

	useEffect(() => {
		for (let priorityOrder = 1; priorityOrder <= 3; priorityOrder++) {
			const classId = values?.[`preferedCourse${priorityOrder}`];
			if (classId) {
				recalculate({ classId });
			}
		}
	}, [values]);

	return (
		<div ref={componentRef2} id='printable' className='printable page-break bg-white overflow-x-auto min-w-[210mm]'>
			<div className='hidden'>SAMPLE WATERMARK</div>
			{/* <div className="watermark">SAMPLE WATERMARK</div> */}
			<div className='p-6'>
				<div className='w-full flex h-64'>
					<div className='border border-3 flex flex-column items-center justify-center w-[24%] mr-4 font-bold' style={{ fontFamily: 'Times New Roman' }}>
						<p>APPLN NO.:</p>
						{values?.mcaApplicationNo ? (
							<p className='mb-3'>
								MCA/
								{values?.mcaApplicationDate && moment(values.mcaApplicationDate).format('YYYY')}/{values?.mcaApplicationNo}
							</p>
						) : (
							<p>Application No. not assigned</p>
						)}
						{/* <p className="mb-3">MCA/{values?.mcaApplicationDate && moment(values.mcaApplicationDate).format("YYYY")}/{values?.mcaApplicationNo}</p> */}
						<p>% of marks in the</p>
						<p>Qualifying Exam: {values?.degreePercentage}%</p>
					</div>
					<div className='flex flex-column items-center justify-center w-[52%] ml-4'>
						<img src={collegeData?.dashBoardLink} alt='college Logo' />
					</div>
					<div className='flex items-end justify-end items-end w-[24%] h-[100%]'>
						<img src={values?.photoURL} alt='Photo' className='object-contain  h-full' />
					</div>
				</div>

				<div className='text-center mt-4	'>
					<h2 className='font-bold text-2xl leading-3'>NIRMALA COLLEGE, MUVATTUPUZHA</h2>
					<h2 className='font-normal text-xl  leading-3'>Ernakulam Dist., Kerala, India.</h2>
					<h2 className='font-normal text-lg  leading-3'>(Accredited by NAAC, Approved by AICTE, Affiliated to M.G. University, Kottayam)</h2>
					<h2 className='font-normal text-lg  leading-3'>Email : mca@nirmalacollege.ac.in, Website : nirmalacollege.org</h2>
				</div>
				<h2 className='text-center text-xl font-bold mt-4 mb-1'>APPLICATION FOR MCA ADMISSION 2024-2025</h2>
				<div>
					<table className='min-w-full'>
						<tbody className='min-w-full '>
							<tr className='min-w-full '>
								{/* <td className="px-3 py-2 bg-white whitespace-wrap text-left text-lg fw-bold text-black" style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  <Tablehelper title="Name" value={values?.name?.toUpperCase()} />
                </td> */}
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									COURSE APPLIED FOR :
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									MCA
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full '>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									NAME OF THE CANDIDATE :
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									{values?.name.toUpperCase()}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full '>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									ADDRESS : {values?.houseName}, {values?.place}, {values?.postOffice}, {values?.district}, {values?.state}, {values?.nationality}, Pin: {values?.pinCode}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									PIN :
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									{values?.pinCode}
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									AADHAAR NO :
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									{values?.aadhaarNumber}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									PHONE :
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									{values?.phoneNumber?.slice(-10)}
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									EMAIL : {values?.email}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									DATE OF BIRTH : {moment(values?.dob).format('DD-MM-YYYY')}
								</td>

								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									AGE : {values?.dob && Number(new Date().getFullYear() - new Date(values.dob).getFullYear())}
								</td>

								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									SEX : {values?.gender?.toUpperCase()}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									VILLAGE : {values?.permanentVillage}
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									TALUK : {values?.permanentTaluk}
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									STATE : {values?.state}
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									DISTRICT : {values?.district}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									RELIGION : {values?.religion}
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									CASTE : {values?.caste}
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									CATEGORY : {values?.category}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									NAME OF QUALIFYING EXAMINATION :
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									{values?.degreeCourse}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									NUMBER OF ATTEMPTS : {values?.degreeNoOfChance}
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									PERCENTAGE/GRADE : {values && values?.degreePercentage}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									REGISTER NO. :
								</td>
								<td className={tableContent} style={{ width: '25%', fontFamily: 'Times New Roman' }}>
									{values?.degreeRegisterNo}
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									MONTH & YEAR OF PASSING : {moment(values?.degreeYearOfPass).format('MMMM, YYYY')}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									NAME OF LAST COLLEGE ATTENDED : {values?.degreeInstitutionName}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									UNIVERSITY : {values?.degreeUniversity}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									WHETHER THE CANDIDATE IS IN NSS/NCC :
								</td>
								<td className={tableContent} style={{ width: '50%', fontFamily: 'Times New Roman' }}>
									{values?.certificates?.nss_certificate === true ? 'NSS' : ''} {values?.certificates?.ncc_a_certificate === true ? 'NCCA' : ''} {values?.certificates?.ncc_b_certificate === true ? 'NCCB' : ''}
								</td>
							</tr>
						</tbody>
					</table>
					<div className='pageBreak'></div>
					<br />
					<table className='min-w-full mt-4 pt-2'>
						<tbody>
							<tr>
								<td className={tableHeading} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									Academic Details
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<thead>
							<th className={tableContent3} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
								SCHOOL/ COLLEGE
							</th>
							<th className={tableContent3} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
								COURSE
							</th>

							<th className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
								GRADE
							</th>
							<th className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
								PERCENTAGE
							</th>
							<th className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
								YEAR OF PASS
							</th>
						</thead>
						<tbody>
							<tr>
								<td className={tableContent3} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
									{values?.degreeInstitutionName}
								</td>
								<td className={tableContent3} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.degreeCourse}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.degreeGrade}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.degreePercentage}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.degreeYearOfPass?.split('-')[0]}
								</td>
							</tr>
							<tr>
								<td className={tableContent3} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
									{values?.plusTwoInstitutionName}
								</td>
								<td className={tableContent3} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									XII
								</td>

								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{/* {values?.totalMarks} */}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.plusTwoPercentage}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.plusTwoYearOfPass?.split('-')[0]}
								</td>
							</tr>
							<tr>
								<td className={tableContent3} style={{ width: '20%', fontFamily: 'Times New Roman' }}>
									{values?.sslcInstitutionName}
								</td>
								<td
									className={tableContent3}
									style={{
										width: '19%',
										fontFamily: 'Times New Roman',
										textAlign: 'center',
									}}>
									X
								</td>

								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{/* {values?.totalMarks} */}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.sslcPercentage}
								</td>
								<td className={tableContent3 + ' text-center'} style={{ width: '19%', fontFamily: 'Times New Roman' }}>
									{values?.sslcYearOfPass?.split('-')[0]}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full mt-4'>
						<tbody>
							<tr>
								<td className={tableHeading} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									Details of Common Entrance Examination for Admission to MCA
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent3} style={{ width: '34%', fontFamily: 'Times New Roman' }}>
									ROLL NO :{values?.lbsRollNo}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									RANK :{values?.lbsMcaRank}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									MARK OBTAINED :{values?.lbsMcaMark}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full mt-4'>
						<tbody>
							<tr>
								<td className={tableHeading} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
									Details of Parent/Guardian
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent3} style={{ width: '34%', fontFamily: 'Times New Roman' }}>
									NAME : {values?.gardianName}
								</td>
								<td className={tableContent3} style={{ width: '66%', fontFamily: 'Times New Roman' }}>
									ADDRESS : {values?.houseName}, {values?.place}, {values?.postOffice}, {values?.district}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent3} style={{ width: '34%', fontFamily: 'Times New Roman' }}>
									PIN : {values?.pinCode}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									PHONE : {values?.guardianMobile}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									ANNUAL INCOME : {values?.annualIncomeFigure}
								</td>
							</tr>
						</tbody>
					</table>
					<table className='min-w-full'>
						<tbody>
							<tr>
								<td className={tableContent3} style={{ width: '34%', fontFamily: 'Times New Roman' }}>
									RELATIONSHIP : {values?.guardianRelation}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									OCCUPATION : {values?.guardianOccupation}
								</td>
								<td className={tableContent3} style={{ width: '33%', fontFamily: 'Times New Roman' }}>
									SOCIALLY AND ECONOMICALLY BACKWARD CLASSES : {values?.category}
								</td>
							</tr>
						</tbody>
					</table>
					<div className='mt-4'>
						<h5 className='text-center  text-xl' style={{ fontFamily: 'Times New Roman' }}>
							Declaration By the Applicant
						</h5>
						<p className='text-xl' style={{ fontFamily: 'Times New Roman' }}>
							I have gone through the prospectus and accept the conditions set forth therein, and do here by promise to abide the rules and regulations of the college; I also declare that the details furnished above are true
						</p>
					</div>
					<div className='flex mt-6'>
						<div style={{ width: '50%' }}>
							<p className='text-xl mb-0 pb-0' style={{ fontFamily: 'Times New Roman' }}>
								PLACE : {values?.place}
							</p>
							<p className='text-xl' style={{ fontFamily: 'Times New Roman' }}>
								DATE : {values?.mcaApplicationDate ? moment(values?.mcaApplicationDate).format('DD/MM/YYYY, hh:mm:ss A') : moment().format('DD/MM/YYYY, hh:mm:ss A')}
							</p>

							<p className='text-xl mt-10 mb-0 pb-0' style={{ fontFamily: 'Times New Roman' }}>
								VERIFIED BY :
							</p>
							<p className='text-xl' style={{ fontFamily: 'Times New Roman' }}>
								NAME & SIGNATURE{' '}
							</p>
						</div>
						<div className='bg-white flex justify-end relative' style={{ width: '50%' }}>
							<div className='absolute bottom-18 bg-green-400' style={{ width: '200px', height: '100px' }}>
								<img src={values?.signature} style={{ width: '200px', height: '100px' }} alt='signature' />
							</div>
							<p className='text-xl absolute bottom-6' style={{ fontFamily: 'Times New Roman' }}>
								[Signature of the Candidate]
							</p>
							<p className='text-xl absolute bottom-0' style={{ fontFamily: 'Times New Roman' }}>
								{values?.name}
							</p>
						</div>
					</div>
					<div>
						<table className='min-w-full mt-4'>
							<tbody>
								<tr>
									<td className={tableHeading} style={{ width: '100%', fontFamily: 'Times New Roman' }}>
										(to be filled by the office)
									</td>
								</tr>
							</tbody>
						</table>
						<table className='min-w-full'>
							<tbody>
								<tr>
									<td className={tableContent} style={{ width: '28%', fontFamily: 'Times New Roman' }}>
										Admitted On
									</td>
									<td className={tableContent} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
										<span className='inline-flex items-center'>
											Management <input className='ml-2' style={{ display: 'inline', color: 'red' }} type='checkbox'></input>
										</span>
									</td>
									<td className={tableContent} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
										<span className='inline-flex items-center'>
											Merit <input className='ml-2' style={{ display: 'inline', color: 'red' }} type='checkbox'></input>
										</span>
									</td>
									<td className={tableContent} style={{ width: '24%', fontFamily: 'Times New Roman' }}>
										<span className='inline-flex items-center'>
											Others <input className='ml-2' style={{ display: 'inline', color: 'red' }} type='checkbox'></input>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<table className='min-w-full'>
							<tbody>
								<tr>
									<td className={tableContent} style={{ width: '28%', fontFamily: 'Times New Roman' }}>
										RANK NO.
									</td>
									<td className={tableContent} style={{ width: '72%', fontFamily: 'Times New Roman' }}></td>
								</tr>
							</tbody>
						</table>
						<table className='min-w-full'>
							<tbody>
								<tr>
									<td className={tableContent} style={{ width: '28%', fontFamily: 'Times New Roman' }}>
										DATE OF ADMISSION
									</td>
									<td className={tableContent} style={{ width: '72%', fontFamily: 'Times New Roman' }}></td>
								</tr>
							</tbody>
						</table>
						<table className='min-w-full'>
							<tbody>
								<tr>
									<td className={tableContent} style={{ width: '28%', fontFamily: 'Times New Roman' }}>
										ADMISSION NO.
									</td>
									<td className={tableContent} style={{ width: '72%', fontFamily: 'Times New Roman' }}></td>
								</tr>
							</tbody>
						</table>
						<table className='min-w-full'>
							<tbody>
								<tr className='h-24'>
									<td className='px-3 py-2 bg-white whitespace-wrap text-center text-lg fw-normal text-black text-end border-2' style={{ width: '100%', fontFamily: 'Times New Roman' }}>
										PRINCIPAL
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					{/* {type?.toUpperCase() === "UG" && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Plus Two Mark List</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Subject
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Obtained Mark
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Total Mark
                    </td>
                  </tr>
                </tbody>
              </table>
              {plustwo?.map((x: any) => (
                <table className="min-w-full">
                  <tbody>
                    <tr>
                      <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "Name"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "ObtainedMark"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "TotalMark"]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </>
          )}
          {type?.toUpperCase() === "PG" && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Degree Qualification</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeRegisterNo && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Reg No : {values?.degreeRegisterNo}
                      </td>
                    )}
                    {values?.degreeYearOfPass && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Month & Year of Pass : {values?.degreeYearOfPass}
                      </td>
                    )}
                    {values?.degreeNoOfChance && (
                      <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                        No of Chances : {values?.degreeNoOfChance}
                      </td>
                    )}
                    {values?.degreeScheme && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Stream : {values?.degreeScheme}
                      </td>
                    )}
                    {values?.degreeInstState && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Institution State : {values?.degreeInstState}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeUniversity && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        University : {values?.degreeUniversity}
                      </td>
                    )}
                    {values?.degreeInstitutionName && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Institution Name : {values?.degreeInstitutionName}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeSpecialisation && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Specialisation : {values?.degreeSpecialisation}
                      </td>
                    )}
                    {values?.degreeMarkSystem && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Mark System : {values?.degreeMarkSystem}
                      </td>
                    )}
                    {values?.degreeCourse && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Programme Stream : {values?.degreeCourse}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <div className="mt-4 mb-2 font-bold fs-5">Degree Grade List</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Field Name
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Marks Awarded / CCPA
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Maximum Marks / Credit
                    </td>
                  </tr>
                </tbody>
              </table>
              {degree?.map(
                (x: any) =>
                  values?.[x?.returnKey + "MaximumMarksCredit"] && (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                            {x?.fieldName}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MarksAwardedCCPA"]}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MaximumMarksCredit"]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
              )}
              <div className="pageBreak"></div>
            </>
          )}
          {enable && (
            <>
              {values?.family?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Family Member</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Name
                        </td>
                        <td className={tableContent2} style={{ width: "10%", fontFamily: "Times New Roman" }}>
                          Relation
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Qualification
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Occupation
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Income
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Mobile No
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.family?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.name}
                          </td>
                          <td className={tableContent} style={{ width: "10%", fontFamily: "Times New Roman" }}>
                            {x?.relation}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.qualification}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.job}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.income}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.mobile}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
              {values?.travelDetails?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Mode of Travel to College</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Vehicle Type
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Starts From
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Ends at
                        </td>
                        <td className={tableContent2} style={{ width: "5%", fontFamily: "Times New Roman" }}>
                          Km
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Energy Used
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Co-traveller
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.travelDetails?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.typeOfVehicle}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.travelStart}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.travelTo}
                          </td>
                          <td className={tableContent} style={{ width: "5%", fontFamily: "Times New Roman" }}>
                            {x?.km}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.typeOfEnergy}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.coTraveler1}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
              {values?.culturalActivity?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Extracurricular/ Cocurricular Details</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                          Type
                        </td>
                        <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                          Item
                        </td>
                        <td className={tableContent2} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                          Achievements
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.culturalActivity?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.type}
                          </td>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.item}
                          </td>
                          <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                            {x?.specialised}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
            </>
          )}

          {priorityFields?.length > 0 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Priorities Courses</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Priority
                    </td>
                    <td className={tableContent2} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Course
                    </td>
                    <td className={tableContent2} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Index
                    </td>
                  </tr>
                </tbody>
              </table>
              {priorityFields?.map(
                (x: any) =>
                  values?.[x.key] && (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.title}
                          </td>
                          <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                            {getClassName(values?.[x.key], classes)}
                          </td>
                          <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                            {bonasClasses[values?.[x.key]]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
              )}
            </>
          )} */}
					{/* {values?.dsc_a && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Major (DSC A)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getClassName(values?.dsc_a, classes)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.dsc_c_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Minor 1 (DSC B)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getMinorName(values?.dsc_b_1, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.dsc_c_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Minor 2 (DSC C)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getMinorName(values?.dsc_c_1, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.mdc_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">MDC</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 1 : {getMinorName(values?.mdc_1, minorData)}
                    </td>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 2 : {getMinorName(values?.mdc_2, minorData)}
                    </td>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 3 : {getMinorName(values?.mdc_3, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )} */}
				</div>
			</div>
		</div>
	);
}

export default MCAApplication;
