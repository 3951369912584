import React, { useCallback, useContext, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import message from 'antd/lib/message';
import { useQuery } from 'react-query';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import CustomInput from '../../../myComp/CustomInput';
import CustomDropDown from '../../../myComp/CustomDropDown';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { getAditionalStatiData } from '../../../NecttosComp/APICaller/ApiServices';
import { Main } from '../../../NecttosComp/Layout/Layout';

function NewQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = useState(itemData);
	const [data, setData] = useState({});

	const { data: getAditionalStati = [] } = useQuery(['getAditionalStatiDataData', data], getAditionalStatiData({ collegeId, collection: 'users', stateData: state }));

	console.log({ state });

	const manageDDCSubject = useCallback(async () => {
		let selectedClassArray = [];

		let catogoryDatas = Object.keys(state)?.map((key) => ({
			type: String(key).slice(0, 2),
			category: String(key).slice(2, 100),
			value: state[key],
		}));

		catogoryDatas.forEach((items) => {
			if (items.type === '~l') {
				if (items.value) {
					selectedClassArray.push(items.category);
				}
			}
		});

		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/iqac/postQuestionnair', {
				collegeId,
				title: state.title,
				effectFrom: state.effectFrom,
				type: state.type,
				from: state.from,
				mode: state.mode,
				semester: state.semester,
				academicYear: state.academicYear,
				nature: state.nature,
				lastDate: state.lastDate,
				_id: questionnaireId,
				whoFill: selectedClassArray,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				refectOrgin();
				onSuccess();
				setState({});
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			onSuccess();
		}
	}, [state]);

	return (
		<Main width='50vw' height='100vh'>
			<div className='p-6 bg-white rounded-md shadow-md'>
				<div className='flex justify-between items-center mb-4'>
					<span className='text-lg font-semibold'>New Questionnaire</span>
					<button onClick={onCloseModal} className='text-red-500 hover:text-red-700'>
						Cancel
					</button>
				</div>
				{/* <Scrollbars> */}
				<div className='flex flex-col'>
					<div className='w-full mb-4'>
						<CustomInput keyData={'title'} titleData={'Title of Questionnaire'} typeData={'text'} stateData={state} setStateData={setState} />
						<CustomInput keyData={'effectFrom'} titleData={'Which Effect From'} typeData={'date'} stateData={state} setStateData={setState} />
						<CustomDropDown keyData={'type'} titleData={'Category'} dropValues={['Student', 'Teacher', 'Staff']} stateData={state} setStateData={setState} />
						{state?.type === 'Student' && (
							<>
								<CustomDropDown keyData={'mode'} titleData={'Mode of Questionnaire'} dropValues={['Teacher Evaluation', 'General Evaluation']} stateData={state} setStateData={setState} />
								{state?.mode === 'Teacher Evaluation' && (
									<>
										<CustomDropDown keyData={'semester'} titleData={'Set of Semesters'} dropValues={['Odd Semester [1,3,5]', 'Even Semester [2,4,6]']} stateData={state} setStateData={setState} />
										<CustomDropDown keyData={'academicYear'} titleData={'Academic Year'} dropValues={['2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027']} stateData={state} setStateData={setState} />
									</>
								)}
							</>
						)}
						<CustomDropDown keyData={'nature'} titleData={'Nature'} dropValues={['Very Important', 'Important', 'Partially Important', 'Normal']} stateData={state} setStateData={setState} />
						<CustomInput keyData={'lastDate'} titleData={'Last Date'} typeData={'date'} stateData={state} setStateData={setState} />
						{state?.type === 'Student' && (
							<>
								<label className='font-semibold mb-2'>Department</label>
								<div className='flex flex-wrap'>
									{getAditionalStati?.department?.map((categoryKey) => (
										<label key={categoryKey?._id} className='text-sm font-bold text-green-800 m-2'>
											<input
												type='checkbox'
												className='mr-2'
												defaultChecked={data['d' + categoryKey?._id]}
												onChange={() => {
													setData({
														...data,
														['d' + categoryKey?._id]: !data['d' + categoryKey?._id],
													});
													setState({
														...state,
														['d' + categoryKey?._id]: !state['d' + categoryKey?._id],
													});
												}}
											/>
											{categoryKey?.name}
										</label>
									))}
								</div>
								<label className='font-semibold mb-2'>Batches</label>
								<div className='flex flex-wrap'>
									{getAditionalStati?.batches?.map((categoryKey) => (
										<label key={categoryKey?._id} className='text-sm font-bold text-green-800 m-2'>
											<input
												type='checkbox'
												className='mr-2'
												defaultChecked={data['b' + categoryKey?._id]}
												onChange={() => {
													setData({
														...data,
														['b' + categoryKey?._id]: !data['b' + categoryKey?._id],
													});
													setState({
														...state,
														['b' + categoryKey?._id]: !state['b' + categoryKey?._id],
													});
												}}
											/>
											{categoryKey?.name}
										</label>
									))}
								</div>
								<label className='font-semibold mb-2'>Classes</label>
								<div className='flex flex-wrap'>
									{getAditionalStati?.classes?.map((categoryKey) => (
										<label key={categoryKey?._id} className='text-sm font-bold text-green-800 m-2'>
											<input
												type='checkbox'
												className='mr-2'
												defaultChecked={state['~l' + categoryKey?._id]}
												onChange={() =>
													setState({
														...state,
														['~l' + categoryKey?._id]: !state['~l' + categoryKey?._id],
													})
												}
											/>
											{categoryKey?.name}
										</label>
									))}
								</div>
							</>
						)}
					</div>
					<button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' onClick={() => manageDDCSubject()}>
						Save
					</button>
				</div>
				{/* </Scrollbars> */}
			</div>
		</Main>
	);
}

export default NewQuestionnaire;
