import React, { useContext, useEffect, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Main } from '../../NecttosComp/Layout/Layout';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import Input from '../../NecttosComp/Input/Input';
import Button from '../../NecttosComp/Button/Button';
import TextArea from 'antd/lib/input/TextArea';
import { getAttendanceShortList, getStudentsFromRoles, useAllClasses } from '../../NecttosComp/APICaller/queryHooks';
import { useQuery } from 'react-query';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import moment from 'moment';

const defaultValues = {
	admissionNumber: '',
	reason: '',
	timeFrom: '09:00',
	type: 'rollNo',
	timeTo: '17:00',
	dateFrom: '',
	dateTo: '',
};

export const AttendanceCorrectionBulk = ({ onClose }) => {
	const { collegeId, user } = useContext(FirebaseContext);
	const [mode, setMode] = useState('correction');
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState(() => {
		try {
			const savedData = localStorage.getItem('valuesAttendanceCorrection');
			return savedData ? JSON.parse(savedData) : defaultValues;
		} catch (error) {
			console.error('Failed to parse local storage data for valuesAttendanceCorrection:', error);
			return defaultValues;
		}
	});
	console.log({ values });

	useEffect(() => {
		try {
			localStorage.setItem('valuesAttendanceCorrection', JSON.stringify(values));
		} catch (error) {
			console.error('Failed to save valuesAttendanceCorrection to local storage:', error);
		}
	}, [values]);

	const handleChange = (key, value) => {
		setValues((prevValues) => ({ ...prevValues, [key]: value }));
	};
	const { data } = useQuery([`getStudentsFromRoles`, collegeId, values?.admissionNumber, values?.type], getStudentsFromRoles);

	const [tableData, setTableData] = useState([]);

	const { data: tableDatae = [], refetch: refetchShortList } = useQuery([`getShortList`, collegeId, values.year, values.base, values.classId], getAttendanceShortList, { staleTime: 1000 * 60 * 50, cacheTime: 1000 * 60 * 100 });

	useEffect(() => {
		setTableData(tableDatae);
	}, [tableDatae]);

	const datadsdsd = { needAll: false, classOnly: true, subId: false };
	const allClasses = useAllClasses(datadsdsd);
	const classList = allClasses?.data || [];

	const handleExport = async () => {
		setLoading(true);
		const { admissionNumber, reason, dateFrom, timeFrom, dateTo, timeTo, type } = values;
		if (!admissionNumber || !reason || !dateFrom || !timeFrom || !dateTo || !timeTo) {
			alert('Please fill all the fields');
			setLoading(false);
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			const res = await instance.post('/college/attendance/take/officeAttendanceCorrection', {
				uid: user.uid,
				selected: admissionNumber,
				type,
				collegeId,
				dateTimeFrom: `${dateFrom} ${timeFrom}`,
				dateTimeTo: `${dateTo} ${timeTo}`,
				reason,
			});
			if (res.statusCode === 200) {
				onClose();
			}
			console.log(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};
	const handleChange3 = (e, i) => {
		const { name, value } = e.target;
		const updatedValues = [...tableData];
		updatedValues[i] = { ...updatedValues[i], [name]: value };
		setTableData(updatedValues);
	};
	const handleResetCacheAndRefetch = async () => {
		try {
			setLoading(true); // Show loading spinner
			await refetchShortList(); // Force API call to fetch fresh data
		} catch (error) {
			console.error('Error refreshing data:', error);
			alert('Failed to refresh data. Please try again.'); // Show user-friendly error
		} finally {
			setLoading(false); // Hide loading spinner
		}
	};

	const handleSeventyFive = async ({ studentId, classId, semester }) => {
		setLoading(true);

		try {
			const instance = await getAxiosTokenInstance();
			const res = await instance.post('/college/attendance/report/makeSeventyFive', {
				uid: user.uid,
				studentId,
				collegeId,
				classId,
				semester,
				reason: 'Programs',
			});
			if (res.statusCode === 200) {
				onClose();
			}
			console.log(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleSeventyFiveInternal = async () => {
		setLoading(true);

		try {
			const instance = await getAxiosTokenInstance();
			const res = await instance.post('/college/attendance/report/makeSeventyFiveInternal', {
				tableData,
				collegeId,
			});
			if (res.statusCode === 200) {
				onClose();
			}
			console.log(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main height='100%' width='100%' title='Bulk Attendance Correction'>
			{loading ? (
				<LoadingAnimation dark />
			) : (
				<>
					<div className='flex justify-between mb-6'>
						<button className={`px-4 py-2 rounded ${mode === 'correction' ? 'bg-indigo-600 text-white' : 'bg-gray-200'}`} onClick={() => setMode('correction')}>
							Correction Controller
						</button>

						<button className='px-4 py-2 bg-blue-500 text-white rounded' onClick={handleResetCacheAndRefetch}>
							Refresh Data
						</button>
						<Input width='300px' didntShowKey fieldName='Select Class' optionDisplay='className' returnKey='classId' optionKey='_id' state={values} setState={setValues} options={classList} type='drop' />
						<Input width='200px' fieldName='Year' returnKey='year' state={values} setState={setValues} options={['2020', '2021', '2022', '2023', '2024', '2025', '2026']} type='drop' />
						<Input width='200px' fieldName='Base' returnKey='base' state={values} setState={setValues} options={['1-3-5', '2-4-6']} type='drop' />
						<Button
							type='delete'
							onClick={() => {
								handleSeventyFiveInternal({});
							}}>
							Manipulate Internal Attendance
						</Button>
						<button className={`px-4 py-2 rounded ${mode === 'shortlist' ? 'bg-indigo-600 text-white' : 'bg-gray-200'}`} onClick={() => setMode('shortlist')}>
							Shortlist
						</button>
					</div>

					{mode === 'correction' && (
						<div className='flex flex-wrap'>
							<div className='w-full md:w-[50%] p-4 space-y-6'>
								<div>
									<label htmlFor='type' className='block text-sm font-medium text-gray-700'>
										Select Type
									</label>
									<select id='type' value={values.type} onChange={(e) => handleChange('type', e.target.value)} className='mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-12'>
										<option value='rollNo'>Roll Number</option>
										<option value='admisionNo'>Admission Number</option>
										<option value='phoneNumber'>Phone Number</option>
									</select>
								</div>
								<TextArea placeholder='Enter roll numbers, separated by commas' className='fs-3' autoSize={{ minRows: 3, maxRows: 12 }} value={values.admissionNumber} onChange={(e) => handleChange('admissionNumber', e.target.value)} />
								<TextArea placeholder='Enter reason for attendance correction' className='fs-3' autoSize={{ minRows: 4, maxRows: 8 }} value={values.reason} onChange={(e) => handleChange('reason', e.target.value)} />
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
									<Input width='100%' fieldName='Date From *' returnKey='dateFrom' state={values} setState={setValues} type='date' value={values.dateFrom} />
									<Input width='100%' fieldName='Date To *' returnKey='dateTo' state={values} setState={setValues} type='date' value={values.dateTo} />
								</div>
								<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
									<Input width='100%' fieldName='Time From *' returnKey='timeFrom' state={values} setState={setValues} type='time' value={values.timeFrom} />
									<Input width='100%' fieldName='Time To *' returnKey='timeTo' state={values} setState={setValues} type='time' value={values.timeTo} />
								</div>
								<div className='flex justify-between mt-6'>
									<Button type='save' onClick={handleExport}>
										Download
									</Button>
									<Button type='close' onClick={onClose}>
										Close
									</Button>
								</div>
							</div>

							<div className='w-full md:w-[50%] p-4 space-y-4 bg-slate-50 rounded-lg'>
								<span className='text-lg font-semibold'>Selected : {data?.message}</span>
								<span className='block text-sm text-gray-600'>Selected Numbers: {data?.filteredNumbers}</span>

								<div className='space-y-4'>
									{data?.data?.map((item, index) => (
										<div key={index} className='flex items-center space-x-4 border-b pb-4'>
											<img src={item.photoURL} alt={item.name} className='w-16 h-16 rounded-full object-cover' />
											<div className='flex flex-col'>
												<span className='text-md font-medium'>{item.name}</span>
												<span className='text-sm text-gray-500'>Roll No: {item.rollNo ?? 'Please select'}</span>
												<span className='text-sm text-gray-500'>Admn No: {item.admissionNo ?? 'Please select'}</span>
												<span className='text-sm text-gray-500'>{item.className ?? 'Please select'}</span>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					)}
					{mode === 'shortlist' && (
						<>
							<Table width='100%' innerWidth={['5%', '10%', '15%', '15%', '15%', '10%']}>
								<Thead>
									<Tr>
										<Th position={0}>Sl.No.</Th>
										<Th position={2}>Name</Th>
										<Th position={2}>Class</Th>
										<Th position={3}>Actual %</Th>
										<Th position={4}>Added %</Th>
										<Th position={5}>Internal Attendance</Th>
										<Th position={1}>Action</Th>
									</Tr>
								</Thead>
								<Tbody height='auto'>
									{tableData?.map((x, i) => (
										<Tr>
											<Td index={i} position={0}>
												{i + 1}
											</Td>
											<Td index={i} position={2}>
												{x?.name}
											</Td>
											<Td index={i} position={2}>
												{x?.className}
											</Td>
											<Td index={i} position={3}>
												{x?.percentage}
											</Td>
											<Td index={i} position={4}>
												{x?.added}
											</Td>
											<Td index={i} position={5}>
												<input type='text' value={x?.percentageGiven || ''} name='percentageGiven' onChange={(e) => handleChange3(e, i)} />
											</Td>
											<Td index={i} position={1}>
												<div className='flex'>
													<Button
														type='delete'
														onClick={() => {
															handleSeventyFive({ studentId: x._id, semester: x.semester, classId: x.classId[0] });
														}}>
														CLICK TO 75%
													</Button>
												</div>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</>
					)}
				</>
			)}
		</Main>
	);
};
