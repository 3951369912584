import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getDropdownData, getNewClasses, getRulesofIndex } from '../../NecttosComp/APICaller/ApiServices';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { subjects_array } from '../Form/Component';

function RankListRules({ onClose }: { onClose: () => void }) {
	const { collegeId, user } = useContext(FirebaseContext);

	const [selectedClass, setSelectClass] = useState('');

	const { data: listOfRules } = useQuery(['listofRulesofInsex', selectedClass], getRulesofIndex(collegeId, selectedClass));

	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId, user?.uid));
	const [saveSuccess, setSaveSuccess] = useState(false);
	const [saveError, setSaveError] = useState('');
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [selectedSubjects, setSelectedSubjects] = useState<any>([]);
	const [selectedSubjectPosition, setSelectedSubjectPosition] = useState<any>({});
	const [maxSubjects, setMaxSubjects] = useState('');
	const [maxMarks, setMaxMarks] = useState('');
	const [convertToMark, setConvertToMark] = useState('');
	const [markType, setMarkType] = useState('');
	const [marks, setMarks] = useState('');
	const [controlledSubjectFrom, setControlledSubjectFrom] = useState('');

	const [controlledSubjectFrom2, setControlledSubjectFrom2] = useState('');
	const [selectedSubjects2, setSelectedSubjects2] = useState<any>([]);
	const [markType2, setMarkType2] = useState('');
	const [marks2, setMarks2] = useState('');
	const [maxSubjects2, setMaxSubjects2] = useState('');
	const [maxMarks2, setMaxMarks2] = useState('');

	const [secondAdition, setSecondAdition] = useState(false);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	useEffect(() => {
		if (!listOfRules) {
			setSelectedSubjects([]);
			setSelectedSubjectPosition({});
			setMaxSubjects('');
			setMaxMarks('');
			setMarkType('');
			setMarks('');
			setConvertToMark('');
			setSelectedSubjects2([]);
			setControlledSubjectFrom('');
			setControlledSubjectFrom2('');
			setMaxSubjects2('');
			setMaxMarks2('');
			setMarkType2('');
			setSecondAdition(false);
			setMarks2('');
		} else {
			setSelectedSubjects(listOfRules?.selectedSubjects);
			setSelectedSubjectPosition(listOfRules?.selectedSubjectPosition);
			setMaxSubjects(listOfRules?.maxSubjects);
			setMaxMarks(listOfRules?.maxMarks);
			setConvertToMark(listOfRules?.convertToMark);
			setMarkType(listOfRules?.markType);
			setMarks(listOfRules?.marks);
			setControlledSubjectFrom(listOfRules?.controlledSubjectFrom);
			setControlledSubjectFrom2(listOfRules?.controlledSubjectFrom2);
			setSelectedSubjects2(listOfRules?.selectedSubjects2);
			setMaxSubjects2(listOfRules?.maxSubjects2);
			setMaxMarks2(listOfRules?.maxMarks2);
			setMarkType2(listOfRules?.markType2);
			setMarks2(listOfRules?.marks2);
			setSecondAdition(listOfRules?.secondAdition || false);
		}
		setSaveSuccess(false);
		setSaveError('');
	}, [listOfRules, selectedClass]);

	const handleSubjectChange = (event: any) => {
		const value = event.target.value;
		if (!selectedSubjects?.includes(value)) {
			setSelectedSubjects([...(selectedSubjects || []), value]);
		}
	};
	const handleSubjectChange2 = (event: any) => {
		const value = event.target.value;
		if (!selectedSubjects2?.includes(value)) {
			setSelectedSubjects2([...(selectedSubjects2 || []), value]);
		}
	};

	const removeSubject = (subject: any) => {
		setSelectedSubjects(selectedSubjects.filter((s: any) => s !== subject));
	};
	const removeSubject2 = (subject: any) => {
		setSelectedSubjects2(selectedSubjects2.filter((s: any) => s !== subject));
	};
	const handleMarkTypeChange = (event: any) => {
		setMarkType(event.target.value);
		setMarks('');
	};

	const handleControlledSubjectFrom = (event: any) => {
		setControlledSubjectFrom(event.target.value);
	};

	const handleControlledSubjectFrom2 = (event: any) => {
		setControlledSubjectFrom2(event.target.value);
	};

	const handleMarkTypeChange2 = (event: any) => {
		setMarkType2(event.target.value);
		setMarks2('');
	};
	const handleMarksChange = (event: any) => {
		const value = event.target.value;
		if (markType === 'fixed' && value) {
			const numericValue = parseInt(value, 10);
			if (numericValue <= parseInt(maxMarks, 10)) {
				setMarks(value);
			} else {
				alert(`Marks cannot exceed the maximum of ${maxMarks}.`);
				setMarks(maxMarks);
			}
		}
	};
	const handleMarksChange2 = (event: any) => {
		const value = event.target.value;
		if (markType2 === 'fixed' && value) {
			const numericValue = parseInt(value, 10);
			if (numericValue <= parseInt(maxMarks2, 10)) {
				setMarks2(value);
			} else {
				alert(`Marks cannot exceed the maximum of ${maxMarks2}.`);
				setMarks2(maxMarks2);
			}
		}
	};

	const saveSelectedSubjects = async () => {
		const instance = await getAxiosTokenInstance();

		try {
			const resp = await instance.post('/college/admissionProcess/setRulesOfIndex', {
				selectedSubjects,
				selectedSubjectPosition,
				maxSubjects,
				classId: selectedClass,
				maxMarks,
				markType,
				convertToMark,
				secondAdition,
				controlledSubjectFrom,
				marks,
				_id: listOfRules?._id || undefined,
				collegeId,
				handicaped: {
					noOfChance1: 0,
					noOfChance2: 5,
					noOfChance3: 10,
					noOfChance4: 15,
				},
				selectedSubjects2,
				maxSubjects2,
				maxMarks2,
				markType2,
				marks2,
				controlledSubjectFrom2,
			});

			if (resp?.data?.statusCode === 200) {
				setSaveSuccess(true);
				setSelectClass('');
				setTimeout(() => setSaveSuccess(false), 3000);
			} else {
				setSaveError('Failed to save. Please try again.');
				setTimeout(() => setSaveError(''), 3000);
			}
		} catch (error) {
			setSaveError('An error occurred. Please try again.');
			setTimeout(() => setSaveError(''), 3000);
		}
	};

	const handleCheckboxChange = (index: any, isChecked: any) => {
		setSelectedSubjectPosition((prev: any) => ({
			...prev,
			[index]: isChecked,
		}));
	};

	const [batchName, setBatchName] = useState<string | undefined>();

	useEffect(() => {
		const foundBatch = classes?.list?.find((x: any) => x._id === selectedClass)?.batchName;
		setBatchName(foundBatch);
	}, [classes, selectedClass]);

	return (
		<Main title='Rank List Auto' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Input fieldName='Select Class' setState={setSelectClass} state={selectedClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='30vw' />
				</div>
				<div className='flex'>
					<Button type='save' onClick={saveSelectedSubjects}>
						Final Submission
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			{selectedClass && (
				<Body>
					{saveSuccess && (
						<div className='mt-2 flex items-center justify-center p-2 bg-green-100 rounded-md shadow'>
							<p className='text-green-600'>Data has been successfully saved.</p>
						</div>
					)}
					{saveError && (
						<div className='mt-2 flex items-center justify-center p-2 bg-red-100 rounded-md shadow'>
							<p className='text-red-600'>{saveError}</p>
						</div>
					)}
					{batchName === '2024-2026' ? (
						<div className='flex flex-col md:flex-row gap-10 p-4'>
							<div className='bg-white shadow-md rounded-lg p-4 w-full md:w-1/2'>
								<h2 className='text-xl font-semibold mb-6 text-center text-gray-800'>Choose Eligibility</h2>
								<div className='space-y-4'>
									{selectedSubjects?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-blue-100 p-2 rounded'>
											<span>{subject}</span>
											<button onClick={() => removeSubject(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
											Specialization
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleSubjectChange} value=''>
											<option value=''>Select a subject...</option>
											{dropDowns?.[4]?.qualification?.[0]?.UG?.Specialisation?.map((subject: any, index: any) => (
												<option key={index} value={subject}>
													{subject}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className='bg-gray-100 shadow-md rounded-lg p-6 w-full md:w-1/2'>
								<h2 className='text-xl font-semibold mb-6 text-center text-gray-800'>Addition of Index Mark</h2>
								<div className='bg-white shadow-md rounded-lg p-4 w-full md:w-1/2'>
									<h2 className='text-xl font-semibold mb-6 text-center text-gray-800'>Choose Subjects for Position Index</h2>
									{[
										{
											title: 'Subject 1',
											keyValues: 'subject1',
										},
										{
											title: 'Subject 2',
											keyValues: 'subject2',
										},
										{
											title: 'Subject 3',
											keyValues: 'subject3',
										},
										{
											title: 'Subject 3.2',
											keyValues: 'subject32',
										},
										{
											title: 'Subject 3.3',
											keyValues: 'subject33',
										},
										{
											title: 'Subject 4',
											keyValues: 'subject4',
										},
										{
											title: 'Subject 5',
											keyValues: 'subject5',
										},
										{
											title: 'Subject 6',
											keyValues: 'subject6',
										},
										{
											title: 'Subject 7',
											keyValues: 'subject7',
										},
									]?.map((ddd, index) => {
										const subjectId = ddd?.keyValues;
										return (
											<div key={index} className='flex items-center space-x-2'>
												<input type='checkbox' id={subjectId} name={subjectId} checked={selectedSubjectPosition[subjectId] || false} onChange={(e) => handleCheckboxChange(subjectId, e.target.checked)} className='w-4 h-4' />
												<label htmlFor={subjectId} className='text-sm text-gray-700'>
													{ddd?.title}
												</label>
											</div>
										);
									})}

									<div>
										<label htmlFor='multiple' className='block text-sm font-medium text-gray-700'>
											Multiple to
										</label>
										<input type='number' id='convertToMark' placeholder='Enter number' value={convertToMark} onChange={(e) => setConvertToMark(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
									</div>
								</div>
								<div className='flex flex-col gap-4 mt-4'>
									<div>
										<label className='block text-sm font-medium text-gray-700'>Segmented Sovereignty</label>
										<select value={controlledSubjectFrom} onChange={handleControlledSubjectFrom} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
											<option>Select</option>
											<option value='selectPartAll'>All Part</option>
											<option value='selectPart1'>Part I</option>
											<option value='selectPart2'>Part II</option>
											<option value='selectPart1_2'>Part I & II</option>
											<option value='selectPart3'>Part III</option>
										</select>
									</div>

									<div>
										<label className='block text-sm font-medium text-gray-700'>Mark Type</label>
										<select value={markType} onChange={handleMarkTypeChange} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
											<option>Select</option>
											<option value='fixed'>Fixed Mark</option>
											<option value='obtained'>Obtained</option>
										</select>
									</div>
									<div>
										<label htmlFor='maxMarks' className='block text-sm font-medium text-gray-700'>
											Maximum Marks
										</label>
										<input type='number' id='maxMarks' placeholder='Enter number' value={maxMarks} onChange={(e) => setMaxMarks(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
									</div>
									<div>
										<label htmlFor='maxSubjects' className='block text-sm font-medium text-gray-700'>
											Maximum Subjects
										</label>
										<input type='number' id='maxSubjects' placeholder='Enter number' value={maxSubjects} onChange={(e) => setMaxSubjects(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
									</div>
									{markType === 'fixed' && (
										<div>
											<label htmlFor='marks' className='block text-sm font-medium text-gray-700'>
												Marks
											</label>
											<input type='number' id='marks' placeholder='Enter marks' value={marks} onChange={handleMarksChange} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
										</div>
									)}
								</div>

								<div className='flex items-center space-x-2 my-6'>
									<input type='checkbox' id='secondAdition' checked={secondAdition} onChange={(e) => setSecondAdition(!secondAdition)} className='w-4 h-4' />
									<label htmlFor='secondAdition' className='text-sm text-gray-700'>
										Need Second Addition of Index Mark
									</label>
								</div>

								{secondAdition && (
									<>
										<h2 className='text-xl font-semibold my-6 text-center text-gray-800'>Second Addition of Index Mark</h2>
										<div className='space-y-4'>
											{selectedSubjects2?.map((subject: any, index: any) => (
												<div key={index} className='flex justify-between items-center bg-blue-100 p-2 rounded'>
													<span>{subject}</span>
													<button onClick={() => removeSubject2(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
														Remove
													</button>
												</div>
											))}
											<div className='mt-4'>
												<label htmlFor='subjects2' className='block text-sm font-medium text-gray-700 mb-1'>
													Subjects
												</label>
												<select id='subjects2' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleSubjectChange2} value=''>
													<option value=''>Select a subject...</option>
													{subjects_array?.map((subject, index) => (
														<option key={index} value={subject}>
															{subject}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className='flex flex-col gap-4 mt-4'>
											<div>
												<label className='block text-sm font-medium text-gray-700'>Segmented Sovereignty</label>
												<select value={controlledSubjectFrom2} onChange={handleControlledSubjectFrom2} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
													<option>Select</option>
													<option value='selectPartAll'>All Part</option>
													<option value='selectPart1'>Part I</option>
													<option value='selectPart2'>Part II</option>
													<option value='selectPart1_2'>Part I & II</option>
													<option value='selectPart3'>Part III</option>
												</select>
											</div>
											<div>
												<label className='block text-sm font-medium text-gray-700'>Mark Type</label>
												<select value={markType2} onChange={handleMarkTypeChange2} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
													<option>Select</option>
													<option value='fixed'>Fixed Mark</option>
													<option value='obtained'>Obtained</option>
												</select>
											</div>

											<div>
												<label htmlFor='maxMarks2' className='block text-sm font-medium text-gray-700'>
													Maximum Marks
												</label>
												<input type='number' id='maxMarks2' placeholder='Enter number' value={maxMarks2} onChange={(e) => setMaxMarks2(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
											</div>
											<div>
												<label htmlFor='maxSubjects2' className='block text-sm font-medium text-gray-700'>
													Maximum Subjects
												</label>
												<input type='number' id='maxSubjects2' placeholder='Enter number' value={maxSubjects2} onChange={(e) => setMaxSubjects2(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
											</div>
											{markType2 === 'fixed' && (
												<div>
													<label htmlFor='marks2' className='block text-sm font-medium text-gray-700'>
														Marks
													</label>
													<input type='number' id='marks2' placeholder='Enter marks' value={marks2} onChange={handleMarksChange2} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
												</div>
											)}
										</div>
									</>
								)}
							</div>
							<div className='bg-gray-100 shadow-md rounded-lg p-6 w-full md:w-1/2'>
								<h2 className='text-lg font-medium text-gray-800'>Chances will be reduce the mark asper below</h2>
								<div className='flex row justify-between p-4 bg-gray-200 rounded-lg'>
									<div className='text-gray-700'>Chance 1 = 0</div>
									<div className='text-gray-700'>Chance 2 = 5</div>
									<div className='text-gray-700'>Chance 3 = 10</div>
									<div className='text-gray-700'>Chance 4 = 15</div>
								</div>
							</div>
						</div>
					) : (
						<div className='flex flex-col md:flex-row gap-10 p-4'>
							<div className='bg-white shadow-md rounded-lg p-4 w-full md:w-1/2'>
								<h2 className='text-xl font-semibold mb-6 text-center text-gray-800'>Choose Subjects for Position Index</h2>
								{Array(7)
									.fill(null)
									.map((_, index) => {
										const subjectId = `subject${index + 1}`;
										return (
											<div key={index} className='flex items-center space-x-2'>
												<input type='checkbox' id={subjectId} name={subjectId} checked={selectedSubjectPosition[subjectId] || false} onChange={(e) => handleCheckboxChange(subjectId, e.target.checked)} className='w-4 h-4' />
												<label htmlFor={subjectId} className='text-sm text-gray-700'>
													Subject {index + 1}
												</label>
											</div>
										);
									})}
								<div>
									<label htmlFor='maxMarks' className='block text-sm font-medium text-gray-700'>
										Maximum Mark Based on Index (All marks convert to this mark)
									</label>
									<input type='number' id='convertToMark' placeholder='Enter number' value={convertToMark} onChange={(e) => setConvertToMark(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
								</div>
							</div>

							<div className='bg-gray-100 shadow-md rounded-lg p-6 w-full md:w-1/2'>
								<h2 className='text-xl font-semibold mb-6 text-center text-gray-800'>Addition of Index Mark</h2>
								<div className='space-y-4'>
									{selectedSubjects?.map((subject: any, index: any) => (
										<div key={index} className='flex justify-between items-center bg-blue-100 p-2 rounded'>
											<span>{subject}</span>
											<button onClick={() => removeSubject(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
												Remove
											</button>
										</div>
									))}
									<div className='mt-4'>
										<label htmlFor='subjects' className='block text-sm font-medium text-gray-700 mb-1'>
											Subjects
										</label>
										<select id='subjects' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleSubjectChange} value=''>
											<option value=''>Select a subject...</option>
											{subjects_array?.map((subject, index) => (
												<option key={index} value={subject}>
													{subject}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='flex flex-col gap-4 mt-4'>
									<div>
										<label className='block text-sm font-medium text-gray-700'>Segmented Sovereignty</label>
										<select value={controlledSubjectFrom} onChange={handleControlledSubjectFrom} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
											<option>Select</option>
											<option value='selectPartAll'>All Part</option>
											<option value='selectPart1'>Part I</option>
											<option value='selectPart2'>Part II</option>
											<option value='selectPart1_2'>Part I & II</option>
											<option value='selectPart3'>Part III</option>
										</select>
									</div>

									<div>
										<label className='block text-sm font-medium text-gray-700'>Mark Type</label>
										<select value={markType} onChange={handleMarkTypeChange} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
											<option>Select</option>
											<option value='fixed'>Fixed Mark</option>
											<option value='obtained'>Obtained</option>
										</select>
									</div>
									<div>
										<label htmlFor='maxMarks' className='block text-sm font-medium text-gray-700'>
											Maximum Marks
										</label>
										<input type='number' id='maxMarks' placeholder='Enter number' value={maxMarks} onChange={(e) => setMaxMarks(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
									</div>
									<div>
										<label htmlFor='maxSubjects' className='block text-sm font-medium text-gray-700'>
											Maximum Subjects
										</label>
										<input type='number' id='maxSubjects' placeholder='Enter number' value={maxSubjects} onChange={(e) => setMaxSubjects(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
									</div>
									{markType === 'fixed' && (
										<div>
											<label htmlFor='marks' className='block text-sm font-medium text-gray-700'>
												Marks
											</label>
											<input type='number' id='marks' placeholder='Enter marks' value={marks} onChange={handleMarksChange} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
										</div>
									)}
								</div>

								<div className='flex items-center space-x-2 my-6'>
									<input type='checkbox' id='secondAdition' checked={secondAdition} onChange={(e) => setSecondAdition(!secondAdition)} className='w-4 h-4' />
									<label htmlFor='secondAdition' className='text-sm text-gray-700'>
										Need Second Addition of Index Mark
									</label>
								</div>

								{secondAdition && (
									<>
										<h2 className='text-xl font-semibold my-6 text-center text-gray-800'>Second Addition of Index Mark</h2>
										<div className='space-y-4'>
											{selectedSubjects2?.map((subject: any, index: any) => (
												<div key={index} className='flex justify-between items-center bg-blue-100 p-2 rounded'>
													<span>{subject}</span>
													<button onClick={() => removeSubject2(subject)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded'>
														Remove
													</button>
												</div>
											))}
											<div className='mt-4'>
												<label htmlFor='subjects2' className='block text-sm font-medium text-gray-700 mb-1'>
													Subjects
												</label>
												<select id='subjects2' className='form-multiselect block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' onChange={handleSubjectChange2} value=''>
													<option value=''>Select a subject...</option>
													{subjects_array?.map((subject, index) => (
														<option key={index} value={subject}>
															{subject}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className='flex flex-col gap-4 mt-4'>
											<div>
												<label className='block text-sm font-medium text-gray-700'>Segmented Sovereignty</label>
												<select value={controlledSubjectFrom2} onChange={handleControlledSubjectFrom2} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
													<option>Select</option>
													<option value='selectPartAll'>All Part</option>
													<option value='selectPart1'>Part I</option>
													<option value='selectPart2'>Part II</option>
													<option value='selectPart1_2'>Part I & II</option>
													<option value='selectPart3'>Part III</option>
												</select>
											</div>
											<div>
												<label className='block text-sm font-medium text-gray-700'>Mark Type</label>
												<select value={markType2} onChange={handleMarkTypeChange2} className='mt-1 block  p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'>
													<option>Select</option>
													<option value='fixed'>Fixed Mark</option>
													<option value='obtained'>Obtained</option>
												</select>
											</div>

											<div>
												<label htmlFor='maxMarks2' className='block text-sm font-medium text-gray-700'>
													Maximum Marks
												</label>
												<input type='number' id='maxMarks2' placeholder='Enter number' value={maxMarks2} onChange={(e) => setMaxMarks2(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
											</div>
											<div>
												<label htmlFor='maxSubjects2' className='block text-sm font-medium text-gray-700'>
													Maximum Subjects
												</label>
												<input type='number' id='maxSubjects2' placeholder='Enter number' value={maxSubjects2} onChange={(e) => setMaxSubjects2(e.target.value)} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
											</div>
											{markType2 === 'fixed' && (
												<div>
													<label htmlFor='marks2' className='block text-sm font-medium text-gray-700'>
														Marks
													</label>
													<input type='number' id='marks2' placeholder='Enter marks' value={marks2} onChange={handleMarksChange2} className='mt-1 block w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500' />
												</div>
											)}
										</div>
									</>
								)}
							</div>

							<div className='bg-gray-100 shadow-md rounded-lg p-6 w-full md:w-1/2'>
								<h2 className='text-lg font-medium text-gray-800'>Chances will be reduce the mark asper below</h2>
								<div className='flex row justify-between p-4 bg-gray-200 rounded-lg'>
									<div className='text-gray-700'>Chance 1 = 0</div>
									<div className='text-gray-700'>Chance 2 = 5</div>
									<div className='text-gray-700'>Chance 3 = 10</div>
									<div className='text-gray-700'>Chance 4 = 15</div>
								</div>
							</div>
						</div>
					)}
				</Body>
			)}
		</Main>
	);
}

export default RankListRules;
