import React, { useContext, useEffect, useState } from 'react';
import './C2_1.css';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useMutation } from 'react-query';
import moment from 'moment';
import LoadingAnimation from '../../components/LoadingAnimation';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';

const exportPdf = async ({ olddata, setLoading }) => {
	const instance = await getAxiosTokenInstance();

	let data = {
		...olddata,
	};

	try {
		const resp = await instance.post(`/college/NAAC/getNAAC_C2_8`, data, {
			responseType: 'blob',
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

function C2_8({ onClose }) {
	const [values, setValues] = useState();
	const { collegeId } = useContext(FirebaseContext);
	const exportPdfMutation = useMutation(exportPdf);
	const [loading, setLoading] = useState(true);
	const onExportExam = async () => {
		setLoading(true);

		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let olddata = {
				collegeId,
				academicYear: '2022-23',
				needExcel: true,
			};

			link.download = 'NAAC File 1_1 ' + moment(new Date()).format('DD_MM_YYYY HH MM');
			const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
			link.click();
			setLoading(false);

			onClose();
		} catch (e) {}
	};
	const fetchData = async () => {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.post('/college/NAAC/getNAAC_C2_8', { academicYear: '2022-23', collegeId }).catch((x) => console.error(x));
		setValues(data);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);
	return (
		<>
			{loading ? (
				<div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]'>
					<LoadingAnimation />
				</div>
			) : null}

			<Main title='2.6.3 Pass percentage of Students during the year' height='80vh' width='80vw'>
				<Header>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</Header>
				<Body>
					<Table width='100%' innerWidth={['10%', '10%', '10%', '25%', '25%', '20%']}>
						<Thead>
							<Tr>
								<Th position={0}>Year</Th>
								<Th position={1}>Program Code</Th>
								<Th position={2}>Program Name</Th>
								<Th position={3}>Number of students appeared in the final year examination</Th>
								<Th position={4}>Number of students passed in final year examination</Th>
								<Th position={5}>Link to Supporting Document</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td position={0}>1</Td>
								<Td position={1}>1</Td>
								<Td position={2}>1</Td>
								<Td position={3}>1</Td>
								<Td position={4}>1</Td>
								<Td position={5}>1</Td>
							</Tr>
						</Tbody>
					</Table>
				</Body>
			</Main>
			{/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <table className="table">
          <tr>
            <th className="w-1/4">Year</th>
            <th className="w-1/4">Program Code</th>
            <th className="w-1/4">Program Name</th>
            <th className="w-1/4">Number of students appeared in the final year examination</th>
            <th className="w-1/4">Number of students passed in final year examination</th>
            <th className="w-1/4">Link to Supporting Document</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>2</th>
            <th>2</th>
            <th>1</th>
            <th>2</th>
          </tr>
        </table>
      </div> */}
		</>
	);
}

export default C2_8;
