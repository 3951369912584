import React, { useContext } from 'react';
import classNames from './college.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { Main } from '../../../NecttosComp/Layout/Layout';
import { FirebaseContext } from '../../../context/FirebaseContext';

const getSupperVission =
	({ collegeId }) =>
	async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/timetable/getTimetableOnDate', {
				params: { collegeId },
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				return resp?.data?.timetable;
			}
		} catch (error) {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

function SupperVision({ onClose }) {
	const { collegeId } = useContext(FirebaseContext);

	let { data } = useQuery(['DFCertyhgfdR0', collegeId], getSupperVission({ collegeId }));

	data = data?.sort((a, b) => a?.className?.localeCompare(b?.className));

	return (
		<Main height='100%' width='100%' title='Super Vission'>
			<Scrollbars style={{ width: '100%', height: '100%' }}>
				<div className={classNames.card_container}>
					{data?.map((item, _i) => (
						<button className={classNames.role_card} style={{ backgroundColor: item.attendance === 'Not-taked' ? 'red' : 'green' }}>
							<div key={item.key} className={classNames.icon}>
								<img src={item?.periodDetail?.photoURL} className={classNames.iconSize} />
							</div>
							<div style={{ alignItems: 'left' }}>
								<h2>
									{item?.className} [ {item?.periodDetail?.totalStrenght ? (((item?.periodDetail?.presents || 0) / (item?.periodDetail?.totalStrenght || 0)) * 100).toFixed(0) : 0}%]
								</h2>
								<h2>{item?.periodDetail?.name}</h2>
								<h2>{item?.periodDetail?.subjectDetails?.name.slice(0, 20)}</h2>
							</div>
						</button>
					))}
				</div>
			</Scrollbars>
			<button className={classNames.closeButton} onClick={onClose}>
				Close
			</button>
		</Main>
	);
}
export default SupperVision;
