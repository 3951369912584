import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './dashLayout.module.scss';
import './style.css';
import Navbar from './navbar';
import ToolTip from '../../components/ToolTip/ToolTip';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import { validateRole } from '../../utils';
import { FirebaseContext } from '../../context/FirebaseContext';

import deskTopWall from '../../static/images/bg/desktop.jpg';
import Clock from '../../components/Clock/Clock';

function DashLayout({ footers, active, children, className, title, background, superAdmin }) {
	const { data } = useProfileData();
	const { collegeId } = useContext(FirebaseContext);

	let currentFooter = [];

	footers?.forEach((item) => {
		if (validateRole(item.allowedUsers, data?.collegeRoles, collegeId)) {
			currentFooter.push(item);
		}
	});

	return (
		<div className='dashLayout' style={{ backgroundImage: `url(${deskTopWall})` }}>
			{/* <Navbar title={title} superAdmin={superAdmin} /> */}
			{/* <Clock /> */}

			<div className={`${className} ${styles.content}`}>{children}</div>
		</div>
	);
}

export default DashLayout;
