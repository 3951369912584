import React, { useCallback, useContext, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import message from 'antd/lib/message';
import { useQuery } from 'react-query';
import classNames from './addBookModal.module.scss';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import CustomInput from '../../../../myComp/CustomInput';
import CustomDropDown from '../../../../myComp/CustomDropDown';
import { getAditionalStatiData } from '../../../../NecttosComp/APICaller/ApiServices';

function NewQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {
	const { collegeId } = useContext(FirebaseContext);
	const [state, setState] = useState(itemData);
	const [data, setData] = useState({});

	const { data: getAditionalStati = [] } = useQuery(['getAditionalStatiDataData', data], getAditionalStatiData({ collegeId, collection: 'users', stateData: state }));

	console.log({ state });

	const manageDDCSubject = useCallback(async () => {
		let selectedClassArray = [];

		let catogoryDatas = Object.keys(state)?.map((key) => ({
			type: String(key).slice(0, 2),
			category: String(key).slice(2, 100),
			value: state[key],
		}));

		catogoryDatas.forEach((items) => {
			if (items.type === '~l') {
				if (items.value) {
					selectedClassArray.push(items.category);
				}
			}
		});

		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/iqac/postQuestionnair', {
				collegeId,
				title: state.title,
				effectFrom: state.effectFrom,
				type: state.type,
				from: state.from,
				mode: state.mode,
				semester: state.semester,
				academicYear: state.academicYear,
				nature: state.nature,
				lastDate: state.lastDate,
				_id: questionnaireId,
				whoFill: selectedClassArray,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				refectOrgin();
				onSuccess();
				setState({});
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			onSuccess();
		}
	}, [state]);

	return (
		<div className={classNames.addBookModal}>
			<div className={classNames.head}>
				<span>New Questionnaire</span>
				<button onClick={onCloseModal} className={classNames.cancel}>
					Cancel
				</button>
			</div>
			<Scrollbars>
				<div style={{ flexDirection: 'column', display: 'flex' }}>
					<div className={classNames.titlesSub} style={{ width: '100%' }}>
						<CustomInput keyData={'title'} titleData={'Title of Questionnaire'} typeData={'text'} stateData={state} setStateData={setState} />
						<CustomInput keyData={'effectFrom'} titleData={'Which Effect From'} typeData={'date'} stateData={state} setStateData={setState} />
						<CustomDropDown keyData={'type'} titleData={'Catogory'} dropValues={['Student', 'Teacher', 'Staff']} stateData={state} setStateData={setState} />
						{state?.type === 'Student' && (
							<>
								<CustomDropDown keyData={'mode'} titleData={'Mode of Questionnair'} dropValues={['Teacher Evaluation', 'General Evaluation']} stateData={state} setStateData={setState} />
								{state?.mode === 'Teacher Evaluation' && (
									<>
										<CustomDropDown keyData={'semester'} titleData={'Set of Semesters'} dropValues={['Odd Semester [1,3,5]', 'Even Semester [2,4,6]']} stateData={state} setStateData={setState} />
										<CustomDropDown keyData={'academicYear'} titleData={'Accademic Year'} dropValues={['2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027']} stateData={state} setStateData={setState} />
									</>
								)}
							</>
						)}
						<CustomDropDown keyData={'nature'} titleData={'Nature'} dropValues={['Very Important', 'Important', 'Partially Important', 'Normal']} stateData={state} setStateData={setState} />
						<CustomInput keyData={'lastDate'} titleData={'Last Date'} typeData={'date'} stateData={state} setStateData={setState} />
						{state?.type === 'Student' && (
							<>
								<label className={classNames.head}>Department</label>
								<div style={{ flexDirection: 'row', textAlign: 'center' }}>
									{getAditionalStati?.department?.map((categoryKey) => (
										<label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}>
											<input
												type='checkbox'
												style={{ height: 12, width: 12, marginRight: 10 }}
												defaultChecked={data['d' + categoryKey?._id]}
												onChange={() => {
													setData({ ...data, ['d' + categoryKey?._id]: !data['d' + categoryKey?._id] });
													setState({ ...state, ['d' + categoryKey?._id]: !state['d' + categoryKey?._id] });
												}}
											/>
											{categoryKey?.name}
										</label>
									))}
								</div>
								<label className={classNames.head}>Batches</label>
								<div style={{ flexDirection: 'row', textAlign: 'center' }}>
									{getAditionalStati?.batches?.map((categoryKey) => (
										<label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}>
											<input
												type='checkbox'
												style={{ height: 12, width: 12, marginRight: 10 }}
												defaultChecked={data['b' + categoryKey?._id]}
												onChange={() => {
													setData({ ...data, ['b' + categoryKey?._id]: !data['b' + categoryKey?._id] });
													setState({ ...state, ['b' + categoryKey?._id]: !state['b' + categoryKey?._id] });
												}}
											/>
											{categoryKey?.name}
										</label>
									))}
								</div>
								<label className={classNames.head}>Classes</label>
								<div style={{ flexDirection: 'row', textAlign: 'center' }}>
									{getAditionalStati?.classes?.map((categoryKey) => (
										<label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}>
											<input type='checkbox' style={{ height: 12, width: 12, marginRight: 10 }} defaultChecked={state['~l' + categoryKey?._id]} onChange={() => setState({ ...state, ['~l' + categoryKey?._id]: !state['~l' + categoryKey?._id] })} />
											{categoryKey?.name}
										</label>
									))}
								</div>
							</>
						)}
					</div>
					<button className={classNames.add} onClick={() => manageDDCSubject()}>
						Save
					</button>
				</div>
			</Scrollbars>
		</div>
	);
}

export default NewQuestionnaire;
