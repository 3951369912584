import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import SearchableUserInputPhoto from '../../myComp/SearchableUserInputPhoto';
import { getProfile } from '../../NecttosComp/APICaller/queryHooks';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { CiMobile2 } from 'react-icons/ci';
import { IoMdMail } from 'react-icons/io';
import { FaGlobe } from 'react-icons/fa';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { uploadPhotoToFirebase } from '../../NecttosComp/APICaller/APICaller';

interface invoiceManagementProps {
	onClose: () => void;
}

function Modal({ isOpen, onClose, children }: any) {
	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
			<div className='bg-white p-6 rounded-lg shadow-lg max-w-xl w-full'>
				{/* <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={onClose}>
					&times;
				</button> */}
				{children}
			</div>
		</div>
	);
}

function Certificate({ onClose }: invoiceManagementProps) {
	const ref = useRef<any>(null);
	const [addNew, setAddNew] = useState<any>({});
	const { collegeId } = useContext(FirebaseContext);
	const [values, setValues] = useState<any>({});
	const [headerUrl, setHeaderUrl] = useState<any>(null);
	const [footerUrl, setFooterUrl] = useState<any>(null);
	const { data } = useQuery([`profileData`, collegeId, addNew?._id], getProfile);

	const [collegeData, setCollegeData] = useState<any>([]);

	useEffect(() => {
		getCollege();
	}, [headerUrl, footerUrl]);

	useEffect(() => {
		if (data) {
			setValues(data);
		}
	}, [data]);

	// console.log({ collegeData });

	const getCollege = async () => {
		try {
			const instance = await getAxiosInstance();
			const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
				params: { collegeId },
			});

			if (data?.statusCode === 200) {
				setCollegeData(data.collegeData);
				return data.collegeData;
			} else {
				throw new Error(data?.message || 'Failed to fetch college details.');
			}
		} catch (error) {
			throw new Error('API Error');
		}
	};

	const [selected, setSelected] = useState('');
	const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));

	const handlePrint = useReactToPrint({
		content: () => ref.current,
		documentTitle: values?.name + '-' + selected?.toUpperCase(),
	});

	const [formData, setFormData] = useState<any>({
		name: '',
		course: '',
		year: '',
	});

	useEffect(() => {
		if (values) {
			setFormData({
				name: values.name,
				course: values.longName,
				year: values.batchName,
			});
		}
	}, [values]);

	const handleChange = (e: any) => {
		console.log(e.target.value);
		setSelected(e.target.value);
	};

	const pageStyle = `@page {
    size: 210mm 297mm;
    }
    @media print {
    @page {  size: a4 landscape;
        margin: 0mm !important;
    }
    @media all {
                    .pagebreak {
                      overflow: visible; 
                    }
                }
            }
        }`;
	const pageStyle2 = `@page {
    size: 210mm 297mm;
    }
    @media print {
    @page {  size: a4 ;
        margin: 0mm !important;
    }
    @media all {
                    .pagebreak {
                      overflow: visible; 
                    }
                }
            }
        }`;

	const getBatchYear = (batchName: any) => {
		const [startYear, endYear] = batchName?.split('-').map(Number);
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth() + 1;
		let academicYear;
		if (currentMonth >= 6) {
			academicYear = currentYear;
		} else {
			academicYear = currentYear - 1;
		}
		if (academicYear < startYear) {
			return 'Not yet started';
		} else if (academicYear >= endYear) {
			return 'Already completed';
		} else {
			const yearDifference = academicYear - startYear;

			switch (yearDifference) {
				case 0:
					return 'First year';
				case 1:
					return 'Second year';
				case 2:
					return 'Third year';
				case 3:
					return 'Fourth year';
				case 4:
					return 'Fifth year';
				default:
					return 'Unknown year';
			}
		}
	};

	// const getCourseDuration = (batchName: string) => {
	//   const [startYear, endYear] = batchName?.split('-').map(Number);
	//   const courseDuration = endYear - startYear;
	//   const durationMap: any = {
	//     1: 'One year',
	//     2: 'Two years',
	//     3: 'Three years',
	//     4: 'Four years',
	//     5: 'Five years',
	//   };
	//   return durationMap[courseDuration] || '..... years';
	// };
	const getCourseDuration = (batchName: any) => {
		const [startYear, endYear] = batchName?.split('-').map(Number);

		if (!startYear || !endYear || endYear <= startYear) {
			return 'Invalid batch name';
		}

		const courseDuration = endYear - startYear;
		switch (courseDuration) {
			case 1:
				return 'One year';
			case 2:
				return 'Two years';
			case 3:
				return 'Three years';
			case 4:
				return 'Four years';
			case 5:
				return 'Five years';
			default:
				return '..... years';
		}
		// return ${courseDuration} years;
	};
	// console.log({ values });

	const handleImageUpload = (e: any) => {
		const files = e.target.files;
		console.log('files', files);
	};

	const handleHeaderChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		const files = event.target.files;
		const instance = getAxiosInstance();
		if (files && files[0]) {
			try {
				//   if(name==='header'){
				//   const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/letterHeader.jpg`, (progress) => {});
				//   const { data } = await instance.post('/college/action/updateLetterHeader', { collegeId,url});
				//   setHeaderUrl(url)
				//   }else{
				//   const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/letterFooter.jpg`, (progress) => {});
				//   const { data } = await instance.post('/college/action/updateLetterHeader', { collegeId,url});
				//   setHeaderUrl(url)
				// }
				const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/${name}.jpg`, (progress) => {});
				const { data } = await instance.post('/college/action/updateLetterHeader', { collegeId, name, url });
				if (name === 'letterHeader') {
					setHeaderUrl(url);
				} else {
					setFooterUrl(url);
				}
			} catch (error) {
				console.error('Error uploading file:', error);
			}
		} else {
			console.error('no uploading file:');
		}
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	return (
		<>
			<Main title='Certificates' width='100%' height='100%'>
				<Header>
					<div className=' flex w-50%'>
						<SearchableUserInputPhoto primoryKey={'applicationNo'} secondaryKey={'name'} tertiaryKey={'phoneNumber'} collection={'users'} stateData={addNew} setStateData={setAddNew} titleData={'Search Admision No or Name'} />
					</div>

					<div className='flex'>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				{addNew?._id && (
					<Body>
						<div className='flex align-center'>
							<select name='selectForm' id='' className='w-80 h-10 rounded text-xl mt-2' onChange={handleChange}>
								<option value='' className=''>
									Choose Certificate
								</option>
								<option value='ksrtcCertificateNoHeader' className=' '>
									KSRTC Certificate- No Header
								</option>
								<option value='StudenshipCertificateNoHeader' className=' '>
									Studenship Certificate- No Header
								</option>
								<option value='CourseCompletionCertificateNoHeader' className=' '>
									Course Completion- No Header
								</option>
								<option value='mediumOfInstructionCertificateNoHeader' className=' '>
									Medium of Instruction- No Header
								</option>
								<option value='letterOfRecommendationNoHeader' className=' '>
									Letter of Recommendation- No Header
								</option>
								{collegeId === 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' && values?.collegeRoles[0]?.type !== 'student' && (
									<option value='dutyCertificateNoHeader' className=' '>
										Duty Certificate- No Header
									</option>
								)}
								<option value='ksrtcForm' className=''>
									KSRTC Certificate
								</option>
								<option value='mediumOfInstruction' className=' '>
									Medium of Instruction
								</option>
								<option value='bonafideStudentshipCertificate' className=' '>
									Studentship Certificate
								</option>
								<option value='CourseCompletionCertificate' className=' '>
									Course Completion Certificate
								</option>

								{/* <option value='courseAndConductCertificatea5' className=' '>
									Course & Conduct Certificate
								</option> */}
							</select>

							{selected !== 'choose' && (
								<>
									<button onClick={handlePrint} className='mb-4 px-4 py-2 bg-green-500 w-36 h-10 mt-2 ml-2 content-center text-white rounded'>
										Print
									</button>

									<div className='flex items-center justify-center'>
										<button className='bg-blue-700 text-white rounded-lg shadow-md hover:bg-blue-900 h-auto shadow border-blue-800 text-md p-2 mb-3 ml-3' onClick={openModal}>
											Update Header or Footer
										</button>

										<Modal isOpen={isModalOpen} onClose={closeModal}>
											<h2 className='text-xl font-semibold'>Update Header or Footer</h2>
											<div className='flex justify-left'>
												<label className='font-semibold' htmlFor=''>
													Header
													<input name='letterHeader' type='file' className='mt-2 h-10 ml-2 w-auto' onChange={handleHeaderChange} accept='image/*' />
												</label>
												{(headerUrl || collegeData.letterHeader) && <img src={collegeData?.letterHeader ? collegeData?.letterHeader : headerUrl} alt='header' className='w-56 h-auto mt-2' />}
											</div>
											<div className='flex justify-left'>
												<label className='font-semibold' htmlFor=''>
													Footer
													<input name='letterFooter' type='file' className='mt-2 h-10 ml-2 w-auto' onChange={handleHeaderChange} accept='image/*' />
												</label>
												{(footerUrl || collegeData.letterFooter) && <img src={collegeData?.letterFooter ? collegeData?.letterFooter : footerUrl} alt='header' className='w-56 h-auto mt-2' />}
											</div>
											<button className='mt-6 px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600' onClick={closeModal}>
												OK
											</button>
										</Modal>
									</div>
								</>
							)}
						</div>
						{selected !== '' && (
							<div className='' style={{ fontFamily: 'Times New Roman' }}>
								{selected === 'ksrtcForm' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>
											<img src={collegeData?.letterHeader ? collegeData?.letterHeader : collegeData?.dashBoardLink} alt='collegeLogo' className='h-auto w-100 object-contain' />
										</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-end pr-0 flex justify-end text-xl'>
												{/* Date: */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>

											<div className='px-20 '>
												<h5 contentEditable className='text-2xl text-center underline mt-16  mb-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOMSOEVER IT MAY CONCERN
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `${values?.name}, ${values?.houseName}, ${values?.place}, ${values?.postOffice}, ${values?.district}, ${values?.state}, ${values?.nationality}, Pin: ${values?.pinCode}, ${values?.hallTicketNo ? 'PRN. ' + values?.hallTicketNo : '....(type prn no)'} (${values?.admisionNo ? 'Admission No. ' + values?.admisionNo : '....(type admission no)'}) is a ${getBatchYear(values?.batchName)} bonafide student of ${values?.longName} (${values?.governBody} programme). ${values?.gender?.toLowerCase() === 'male' ? 'He' : 'She'} is in need of travel concession from ${values?.place + ', ' + values?.district} to ${collegeData.address}, ${collegeData.district} (Dist). Kindly do the needful.`}
												</textarea>
												<p contentEditable className='text-[16px] pr-10 text-end '>
													Yours Faithfully,
												</p>
												<div className='mt-24'>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.name}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.houseName}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.postOffice}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.place}, {values?.district}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														Pincode - {values?.pinCode}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.state}
													</p>
												</div>
												<p contentEditable className='text-[16px] mt-[190px] leading-[4px]'>
													Boarding Point : {values?.place}, {values?.district}
												</p>
												<p contentEditable className='text-[16px] leading-[4px] pl-[80px]'>{`To : ${collegeData.address}, ${collegeData.district}`}</p>
											</div>
										</section>
										<footer className='bg-red-10 h-[15mm]'>
											<img src={collegeData?.letterFooter ? collegeData?.letterFooter : ''} alt='collegeFooter' className='h-[15mm] w-100' />
										</footer>
									</div>
								)}
								{selected === 'mediumOfInstruction' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>
											<img src={collegeData?.letterHeader ? collegeData?.letterHeader : collegeData?.dashBoardLink} alt='collegeLogo' className='h-auto w-100 object-contain' />
										</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												Date:
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-auto'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOMSOEVER IT MAY CONCERN
												</h5>
												<textarea className='w-full h-[320px]  text-[16px] border-none text-justify' name='' id=''>{`This is to certify that ${values?.name}, Reg No. ${values.hallTicketNo ? values.hallTicketNo : '...(reg no here)'}, Admission No. ${values?.admisionNo ? values?.admisionNo : '...(Adm No here'} was a bonafide student of ${values?.longName}, during the academic year of ${values?.batchName} in ${collegeData.collegeName},${collegeData.district}, which is affiliated to ${collegeData.universityDetails ? collegeData.universityDetails?.name : ' [university name]'} . The medium of instruction for the entire programme was English.`}</textarea>
											</div>
										</section>
										<footer className='bg-red-10 h-[15mm]'>
											<img src={collegeData?.letterFooter ? collegeData?.letterFooter : ''} alt='collegeFooter' className='h-[15mm] w-100' />
										</footer>
									</div>
								)}
								{selected === 'bonafideStudentshipCertificate' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>
											<img src={collegeData?.letterHeader ? collegeData?.letterHeader : collegeData?.dashBoardLink} alt='collegeLogo' className='h-auto w-100 object-contain' />
										</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												Date:
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOMSOEVER IT MAY CONCERN
												</h5>
												<textarea className='w-full h-[320px]  text-[16px] border-none text-justify' name='' id=''>{`This is to certify that ${values?.name}, Reg No. ${values.hallTicketNo ? values.hallTicketNo : '...(reg no here)'}, Admission No. ${values?.admisionNo} was a bonafide regular student of ${getCourseDuration(values?.batchName)}, ${values?.longName}, programme during the academic year of ${values?.batchName} in ${collegeData.collegeName},${collegeData.district}, which is affiliated to ${collegeData.universityDetails ? collegeData.universityDetails?.name : ' [university name]'} .\n\nThis certificate is issued to ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} on request.
                          `}</textarea>
											</div>
										</section>
										<footer className='bg-red-10 h-[15mm]'>
											<img src={collegeData?.letterFooter ? collegeData?.letterFooter : ''} alt='collegeFooter' className='h-[15mm] w-100' />
										</footer>
									</div>
								)}
								{selected === 'CourseCompletionCertificate' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>
											<img src={collegeData?.letterHeader ? collegeData?.letterHeader : collegeData?.dashBoardLink} alt='collegeLogo' className='h-auto w-100 object-contain' />
										</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												{collegeId !== 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' && 'Date:'}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													COURSE COMPLETION CERTIFICATE
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `This is to certify that ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name}, ${values?.houseName}, ${values?.place}, ${values?.postOffice}, ${values?.district}, ${values?.state}, ${values?.nationality}, Pin: ${values?.pinCode}, ${values?.hallTicketNo ? 'PRN. ' + values?.hallTicketNo : '....(type prn no)'} (${values?.admisionNo ? 'Admission No. ' + values?.admisionNo : '....(type admission no)'}) has completed ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} ${values?.longName} programme from this institution during the period of ${values?.batchName}.\n \nDuring the above period ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} character and conduct were good.`}
												</textarea>
											</div>
										</section>
										<footer className=' h-[15mm]'>
											<img src={collegeData?.letterFooter ? collegeData?.letterFooter : ''} alt='collegeFooter' className='h-[15mm] w-100' />
										</footer>
									</div>
								)}
								{selected === 'letterOfRecommendationNoHeader' && (
									<div ref={ref} className='flex flex-column justify-between w-[210mm] h-[420mm] bg-white printable'>
										{/* <div className="flex flex-column justify-between p-0 m-0 bg-white"> */}

										<div>
											<style type='text/css' media='print'>
												{pageStyle2}
											</style>
											<div className='flex w-full'>
												<div className='w-full flex justify-center my-3 h-[200px]'>{/* <img src={collegeData?.dashBoardLink} alt="collegeLogo" className="w-[85%] h-auto" /> */}</div>
											</div>
											{/* <p contentEditable className="text-white text-lg text-center bg-gray-800">ERNAKULAM (DIST), KERALA - 686661, INDIA (Affliated to Mahatma Gandhi University, Kottayam)</p> */}
											<div className='text-xl text-end pr-0 flex justify-end'>
												{/* Date: */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='pt-2 pb-0 px-20 mt-16 h-[270mm]'>
												<h5 contentEditable className='text-2xl text-center underline my-3' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOMSOEVER IT MAY CONCERN
												</h5>
												<textarea className='w-100 h-[180mm] border-none text-[16px] text-justify' name='' id=''>{`This is to certify that ${values?.name}, ${values.hallTicketNo ? 'PRN ' + values.hallTicketNo : '...(please type reg no here)'}, Admission No. ${values?.admisionNo} was a bonafide student of this institution  for the period of ${values?.batchName ? getCourseDuration(values?.batchName) : '...(type duration)'} ${values?.longName} programme, during the academic year of ${values?.batchName} . ${values?.gender?.toLowerCase() === 'male' ? 'He' : 'She'} attended ${values?.batchName ? getCourseDuration(values?.batchName) : 'three year'} programme run by college and the medium of instruction was English. ${values?.gender?.toLowerCase() === 'male' ? 'He' : 'She'} has successfully completed ${values?.longName} programme at ${collegeData.collegeName},${collegeData.district}, which is affiliated with the ${collegeData.universityDetails ? collegeData.universityDetails?.name : ' [university name]'} .\n\nI am very pleased to write this letter of recommendation with regard to ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} interest in future endeavour. ${values?.gender?.toLowerCase() === 'male' ? 'He' : 'She'} has shown keen interest in learning and understanding as a student and was found very sincere and hardworking.\n\nI wish ${values?.gender?.toLowerCase() === 'male' ? 'him' : 'her'} all the best.
                 `}</textarea>
												{collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' && (
													<div className='left-0 mt-1'>
														<p contentEditable className='leading-[4px] text-[16px]'>
															PRINCIPAL-IN-CHARGE
														</p>
														<p contentEditable className='leading-[4px] text-[16px]'>
															Rev. Dr. Jestin K Kuriakose
														</p>
														<p contentEditable className='leading-[4px] text-[16px]'>
															nirmalacollege@gmail.com
														</p>
														<p contentEditable className='leading-[4px] text-[16px]'>
															Mob: 9447001864
														</p>
													</div>
												)}
											</div>
											{/* </div> */}
										</div>
										<div className=''></div>
									</div>
								)}
								{selected === 'courseAndConductCertificatea5' && (
									<div ref={ref} className='w-[297mm] h-[210mm] bg-white p-0 m-0 bg-blue-200 flex printable'>
										<style type='text/css' media='print'>
											{pageStyle}
										</style>
										<div className='w-[148mm] h-[205mm] bg-white p-0 m-0 mt-0'>
											<div className='flex h-[150px]'>
												<div className='w-full flex justify-center'>
													<img src={collegeData?.dashBoardLink} alt='collegeLogo' className='w-auto h-auto' />
												</div>
											</div>
											{collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' && (
												<>
													<p contentEditable className='text-[11px] text-center mb-0 pb-0'>
														(Affliated to Mahatma Gandhi University, Kottayam, Accredited by NAAC with A++ Grade)
													</p>
													<p contentEditable className='text-[14px] text-center pt-0 mb-0'>
														Tel: 0485 2832361
													</p>
												</>
											)}
											<div className='pt-1 pb-0 px-8 h-[120mm]'>
												<h5 contentEditable className='text-center underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													COURSE & CONDUCT CERTIFICATE
												</h5>
												<div className='text-end pr-0 flex justify-end'>
													Date:
													<input type='date' className='w-auto items-end border-none m-date-input appearance-transparent mr-[-40px] mb-10' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														Name of Student
													</label>
													<input type='text' className='text-center h-7 py-0 border-x-0 border-t-0 w-full border-b-2 border-dotted' id='name' value={formData?.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														Course
													</label>
													<input
														type='text'
														className='text-center h-7 py-0 border-x-0 border-t-0 w-full border-b-2 border-dotted'
														id='course'
														value={formData?.course}
														onChange={(e) =>
															setFormData({
																...formData,
																course: e.target.value,
															})
														}
													/>
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														Optional Subjects
													</label>
													<input type='text' className='text-center h-7 py-0 border-x-0 border-t-0 w-full border-b-2 border-dotted' id='optional' />
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														Period of Study
													</label>
													<input type='text' className='text-center h-7 py-0 border-x-0 border-t-0 w-full border-b-2 border-dotted' id='period' value={formData?.year} onChange={(e) => setFormData({ ...formData, year: e.target.value })} />
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														The Student has completed/not completed the course
													</label>
												</div>
												<div className='flex '>
													<label contentEditable className='w-auto text-nowrap pt-2 mr-2'>
														{values?.gender?.toLowerCase() === 'male' ? 'His' : 'Her'} Character and Conduct are
													</label>
													<input type='text' className='text-center h-7 py-0 border-x-0 border-t-0 w-full border-b-2 border-dotted' id='character' />
												</div>
												<p contentEditable className='text-end mr-10 mt-12'>
													Principal
												</p>
											</div>
										</div>
										<div className='w-[148mm] h-[210mm] p-0 m-0'></div>
									</div>
								)}
								{selected === 'ksrtcCertificateNoHeader' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>.</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-end pr-0 flex justify-end text-xl'>
												{/* Date: */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>

											<div className='px-20 '>
												<h5 contentEditable className='text-2xl text-center underline mt-16  mb-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOMSOEVER IT MAY CONCERN
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `${values?.name}, ${values?.houseName}, ${values?.place}, ${values?.postOffice}, ${values?.district}, ${values?.state}, ${values?.nationality}, Pin: ${values?.pinCode}, ${values?.hallTicketNo ? 'PRN. ' + values?.hallTicketNo : '....(type prn no)'} (${values?.admisionNo ? 'Admission No. ' + values?.admisionNo : '....(type admission no)'}) is a ${getBatchYear(values?.batchName)} bonafide student of ${values?.longName} (${values?.governBody} programme). ${values?.gender?.toLowerCase() === 'male' ? 'He' : 'She'} is in need of travel concession from ${values?.place + ', ' + values?.district} to ${collegeData.address}, ${collegeData.district} (Dist). Kindly do the needful.`}
												</textarea>
												<p contentEditable className='text-[16px] pr-10 text-end '>
													Yours Faithfully,
												</p>
												<div className='mt-24'>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.name}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.houseName}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.postOffice}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.place}, {values?.district}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														Pincode - {values?.pinCode}
													</p>
													<p contentEditable className='text-[16px] leading-[4px]'>
														{values?.state}
													</p>
												</div>
												<p contentEditable className='text-[16px] mt-[190px] leading-[4px]'>
													Boarding Point : {values?.place}, {values?.district}
												</p>
												<p contentEditable className='text-[16px] leading-[4px] pl-[80px]'>{`To : ${collegeData.address}, ${collegeData.district}`}</p>
											</div>
										</section>
										<footer className='bg-red-10 h-[15mm]'>.</footer>
									</div>
								)}
								{selected === 'StudenshipCertificateNoHeader' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>.</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												{/* {collegeId !== 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' &&
                        "Date:"
                        } */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													STUDENTSHIP CERTIFICATE
												</h5>
												<textarea className='w-full h-[320px]  text-[16px] border-none text-justify' name='' id=''>{`This is to certify that ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name}, Reg No. ${values.hallTicketNo ? values.hallTicketNo : '...(reg no here)'}, Admission No. ${values?.admisionNo} was a bonafide regular student of ${getCourseDuration(values?.batchName)}, ${values?.longName} programme during the academic year of ${values?.batchName} at ${collegeData.collegeName},${collegeData.district}, which is affiliated to ${collegeData.universityDetails ? collegeData.universityDetails?.name : ' [university name]'}.\n\nThis certificate is issued to ${values?.gender?.toLowerCase() === 'male' ? 'him' : 'her'} on request.
                          `}</textarea>
											</div>
										</section>
										<footer className=' h-[15mm]'>.</footer>
									</div>
								)}
								{selected === 'CourseCompletionCertificateNoHeader' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>.</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												{/* {collegeId !== 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' &&
                        "Date:"
                        } */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													COURSE COMPLETION CERTIFICATE
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `This is to certify that ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name}, ${values?.houseName}, ${values?.place}, ${values?.postOffice}, ${values?.district}, ${values?.state}, ${values?.nationality}, Pin: ${values?.pinCode}, ${values?.hallTicketNo ? 'PRN. ' + values?.hallTicketNo : '....(type prn no)'} (${values?.admisionNo ? 'Admission No. ' + values?.admisionNo : '....(type admission no)'}) has completed ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} ${values?.longName} programme from this institution during the period of ${values?.batchName}.\n \nDuring the above period ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} character and conduct were good.`}
												</textarea>
											</div>
										</section>
										<footer className=' h-[15mm]'>.</footer>
									</div>
								)}
								{selected === 'mediumOfInstructionCertificateNoHeader' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>.</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												{/* {collegeId !== 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' &&
                        "Date:"
                        } */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOM IT MAY CONCERN
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `This is to certify that ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name} has completed ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} ${values?.longName} programme from this institution during the period of ${values?.batchName}. The medium of instruction for the programme was English for the entire period of ${values.batchName ? getCourseDuration(values?.batchName) : '[... years]'}. ${values?.gender?.toLowerCase() === 'male' ? 'His' : 'Her'} ${values?.hallTicketNo ? 'Register Number is ' + values?.hallTicketNo : '[...reg no]'}. During the above period ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} character and conduct were good.\n \nI recommend ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name} to pursue ${values?.gender?.toLowerCase() === 'male' ? 'his' : 'her'} studies abroad and wish ${values?.gender?.toLowerCase() === 'male' ? 'him' : 'her'} a rewarding educational career. `}
												</textarea>
											</div>
										</section>
										<footer className=' h-[15mm]'>.</footer>
									</div>
								)}
								{selected === 'dutyCertificateNoHeader' && (
									<div ref={ref} className='w-[210mm] h-screen  print:w-full print:h-full printable flex flex-col justify-between'>
										<header className='bg-white h-[60mm]'>.</header>
										<section className='bg-gray-100 print:bg-white flex-grow'>
											<div className='text-xl text-end pr-0 flex justify-end'>
												{/* {collegeId !== 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' &&
                        "Date:"
                        } */}
												<input type='date' className='w-auto items-end pr-0 border-none m-date-input appearance-transparent' value={startDate} onChange={(e: any) => setStartDate(e.target.value)} />
											</div>
											<div className='px-20 mt-16 h-[246mm]'>
												<h5 contentEditable className='text-center text-2xl underline my-4' style={{ fontFamily: 'Times New Roman' }}>
													TO WHOM IT MAY CONCERN
												</h5>
												<textarea className='text-[16px] w-100 h-72 border-none resize-none text-justify' name='' id=''>
													{values && `This is to certify that ${values?.gender?.toLowerCase() === 'male' ? 'Mr.' : 'Ms.'} ${values?.name}, ${values?.designation} of ${collegeData.collegeName} has attended duty as External Examiner for conducting Project Viva for IV Semester M.Com Students at ${collegeData.collegeName} on [... dates..]. `}
												</textarea>
											</div>
										</section>
										<footer className=' h-[15mm]'>.</footer>
									</div>
								)}
							</div>
						)}
					</Body>
				)}
			</Main>
		</>
	);
}

export default Certificate;
