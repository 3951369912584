import React, { useState } from "react";
import { Body, Footer, Header, Main } from "../Layout/Layout";
import Button from "../Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import toast, { Toaster } from "react-hot-toast";
import { Table, Tbody, Td, Th, Thead, Tr } from "../Table/Table";
import { CSVReader } from "react-papaparse";

interface CSVEditorProp {
  setCsv: (val: boolean) => void;
  returnData: (val: any) => void;
  keysNeeded: string[];
  specific?: boolean;
}

function CSVEditor({ setCsv, returnData, keysNeeded, specific = false }: CSVEditorProp) {
  const [csvData, setCsvData] = useState<any>();

  const validateCSVFile = (item: any[]) => {
    let tempArray: any[] = [];
    item?.forEach((item) => {
      tempArray.push({ data: item?.data });
    });
    setCsvData(tempArray);
  };

  const deleteElementFrom = (index: number) => {
    if (index >= 0 && index < csvData.length) {
      let newData = [...csvData];
      newData.splice(index, 1);
      setCsvData([...newData]);
    } else {
      console.log("Index out of bounds");
    }
  };
  return (
    <>
      <Toaster />
      <Main height="auto" width="auto">
        <Header>
          <Button
            type="close"
            onClick={() => {
              setCsv(false);
            }}
          >
            Close
          </Button>
          {!csvData?.[0] && (
            <Button
              type="update"
              onClick={() => {
                setCsvData([{ data: keysNeeded }]);
              }}
            >
              Create
            </Button>
          )}
          {csvData?.[0] && (
            <div className="flex">
              <Button type="delete" onClick={() => setCsvData([])}>
                Remove Data
              </Button>

              <form
                className="flex justify-center items-center"
                onSubmit={async (e) => {
                  e.preventDefault();
                  const instance = await getAxiosTokenInstance();
                  let validation = false;
                  for (let i in keysNeeded) {
                    validation = false;
                    if (csvData?.[0]?.data?.includes(keysNeeded[i])) {
                      validation = true;
                    } else {
                      toast.error(keysNeeded[i] + " not Found Please Add");
                      break;
                    }
                  }

                  if (validation) {
                    let data = [...csvData];
                    const keys: any = [];
                    const genearetedData: object[] = [];
                    for (const i in data[0].data) {
                      keys.push(data[0].data[i]);
                    }
                    for (let i = 1; i < data.length; i++) {
                      let values: any = {};
                      for (const j in data[i].data) {
                        values[keys[j]] = data[i].data[j];
                      }
                      genearetedData.push(values);
                    }
                    returnData(genearetedData);
                  }
                }}
              >
                <label className="flex text-red-800 w-[400px] text-[13px] h-[20px] items-center justify-center">
                  <input required type="checkbox" name="" id="" className="w-[20px]" />
                  The information presented herein is accurate.
                </label>
                <Button type="save">Upload Data</Button>
              </form>
            </div>
          )}
        </Header>
        <Body>
          {csvData?.[0] ? (
            <>
              <Table width="100%" innerWidth={[`${100 / (csvData?.[0]?.data?.length + 1)}%`]}>
                <Thead>
                  <Tr>
                    {csvData?.[0]?.data?.map((x: string | number, index: any) => (
                      <Th position={0}>
                        <div className="flex">
                          <select
                            className="text-black"
                            value={x}
                            onChange={(e) => {
                              if (!specific) {
                                const temp = [...csvData];
                                temp[0].data[index] = e.target.value;
                                setCsvData([...temp]);
                              }
                            }}
                          >
                            {keysNeeded.map((option, optionIndex) => (
                              <option key={option + optionIndex} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {!specific && (
                            <button
                              className="p-1 ms-1 bg-red-500 hover:bg-red-700 transition rounded text-[8px] h-5 w-5"
                              onClick={() => {
                                let temp = [...csvData];

                                for (let i in temp) {
                                  temp[i].data?.splice(index, 1);
                                }
                                setCsvData([...temp]);
                              }}
                            >
                              X
                            </button>
                          )}
                        </div>
                      </Th>
                    ))}
                    <Th position={0}>
                      {!specific && (
                        <Button
                          type="update"
                          onClick={() => {
                            let temp = [...csvData];

                            for (let i in temp) {
                              temp[i].data?.push("");
                            }
                            setCsvData([...temp]);
                          }}
                        >
                          Add Column
                        </Button>
                      )}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody height="75vh">
                  {csvData?.map((y: any, index: number) => (
                    <>
                      {index !== 0 && (
                        <Tr>
                          {y?.data?.map((x: any, i: number) => (
                            <Td position={0} index={index}>
                              <input
                                type="text"
                                className="text-black"
                                value={x}
                                onChange={(e) => {
                                  const temp = [...csvData];
                                  temp[index].data[i] = e.target.value;
                                  setCsvData([...temp]);
                                }}
                              />
                            </Td>
                          ))}

                          <Td position={0} index={index}>
                            <Button
                              type="delete"
                              onClick={() => {
                                deleteElementFrom(index);
                              }}
                            >
                              Delete
                            </Button>
                          </Td>
                        </Tr>
                      )}
                    </>
                  ))}
                  <Tr>
                    <Button
                      type="update"
                      onClick={() => {
                        let field: string[] = [];

                        csvData?.[0]?.data?.map(() => {
                          field.push("");
                        });
                        setCsvData([...csvData, { data: field }]);
                      }}
                    >
                      Add New data
                    </Button>
                  </Tr>
                </Tbody>
              </Table>
            </>
          ) : (
            <CSVReader
              onDrop={(val) => {
                validateCSVFile([...val]);
              }}
            >
              <p className="text-slate-800 font-extrabold text-[20px]">Upload</p>
            </CSVReader>
          )}
        </Body>
        <Footer />
      </Main>
    </>
  );
}

export default CSVEditor;
