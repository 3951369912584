import React from 'react';

function groupBy(objectArray, property) {
	return objectArray?.reduce((acc, obj) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push({ ...obj, selected: false });
		return acc;
	}, {});
}

export default function CustomeBilling({ options, setTotalAmount, selectedItems, setSelectedItems }) {
	const optionSelected = options?.filter((x) => x.key !== 'Others');
	const daya = groupBy(optionSelected[0]?.dues, 'year');

	const handleCheckboxChange = (option, year) => {
		const uniqueId = `${year}-${option.key}`;
		const updatedSelectedItems = { ...selectedItems };
		if (updatedSelectedItems[uniqueId]) {
			delete updatedSelectedItems[uniqueId];
		} else {
			updatedSelectedItems[uniqueId] = option;
		}
		setSelectedItems(updatedSelectedItems);
		const total = Object.values(updatedSelectedItems).reduce((acc, item) => acc + Number(item.currentDue), 0);
		setTotalAmount(total);
	};

	return (
		<div className='w-full flex flex-col items-center justify-center'>
			<div className='w-full list'>
				<div className='w-full h-2/3 overflow-auto text-center'>
					{Object.keys(daya).map((keyM) => (
						<div key={keyM} className='mb-8 bg-slate-100 p-3 rounded-2xl'>
							<span className='block font-sans text-green-600 text-lg mb-4'>{keyM.toUpperCase()}ear Tuition Fee </span>
							<div className='grid grid-cols-3 gap-4'>
								{daya[keyM]?.map((option) => {
									const uniqueId = `${keyM}-${option.key}`;
									return (
										<label key={option.id} htmlFor={uniqueId} className='cursor-pointer shadow-md p-4 rounded-xl text-gray-700 text-center hover:bg-blue-100 transition-all duration-300 w-full flex flex-col items-center space-y-2'>
											<input type='checkbox' id={uniqueId} className='w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500' checked={!!selectedItems[uniqueId]} onChange={() => handleCheckboxChange(option, keyM)} />
											<p className='font-medium text-blue-900 text-base'>{option.key}</p>
											<span className='bg-purple-100 text-blue-900 font-bold text-lg px-6 py-2 rounded-full'>{option.currentDue}</span>
										</label>
									);
								})}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
