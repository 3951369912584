import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { JournalEntry } from './IncomeExpenditure/DayBook';
import DetailedReportTable from './RecieptPaymentSub';
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import useApiCaller from '../../NecttosComp/CustomApiCaller/customApiCaller';
import { sumWithKey } from '../../utils';
import moment from 'moment';

export const BankCash = ({ onClose }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const componentRef = useRef();
	const [date, setDate] = useState({ date: '', type: 'Government', subType: '' });
	const [autoFetch, setAutoFetch] = useState(false);
	const [dataBit, setData] = useState([]);

	// useEffect(() => {
	//     fetch('https://scholar.googleusercontent.com/citations?view_op=export_citations&user=uDhWO2IAAAAJ&citsig=AM0yFCkAAAAAZbXaTGLMUqA7F9XNemEpmuKPlSI&hl=en')
	//         .then(response => response.text())
	//         .then(textData => {
	//             const parsedData = parseBibTeX(textData);
	//             setData(parsedData);
	//         })
	//         .catch(error => {
	//             console.error('Error fetching data:', error);
	//         });
	// }, []);

	const { data, refetch } = useApiCaller({
		method: 'GET',
		url: '/college/fees/getIncomeExpenditure',
		params: { collegeId, date: date.date, type: date.type, subType: date.subType, endDate: date.endDate, accoundMode: 'bankCash' },
		autoFetch,
	});

	useEffect(() => {
		setAutoFetch(true);
		setTimeout(() => {
			setAutoFetch(false);
		}, 100);
	}, [collegeId, date.date, date.type, date.subType, date.endDate]);

	const { tableItems, requestSort, getIcon } = useSortableData(data?.selectedAccountData);
	let toAccounts = data?.types;
	let toSubAccounts = ['All Accounts', ...(data?.subTypes || [])];

	return (
		<>
			<Main title={'Bank & Cash A/C'} height={'90vh'} width={'90vw'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Input type='date' state={date} fieldName='Start Date' returnKey='date' setState={setDate} />
						<Input type='date' state={date} returnKey='endDate' fieldName='End Date' setState={setDate} />
						<Input type='drop' options={toAccounts} state={date} fieldName='Main Head' returnKey='type' setState={setDate} />
						<Input type='drop' options={toSubAccounts} state={date} fieldName='Sub Head' returnKey='subType' setState={setDate} />
					</div>
					<div className='flex flex-wrap items-center'>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
						<Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button type='pdf' tableRef={componentRef.current} pdfId='accounts'>
							PDF
						</Button>

						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={componentRef} className='printable '>
						<div class='flex flex-row items-center justify-between p-4 border-b-2'>
							<div class='flex items-center'>
								<img class='w-16 h-16 mr-4' src={selectedCollege?.logo} alt='College Logo' />
								<div>
									<h1 class='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
									<p class='text-md font-semibold '>{selectedCollege?.accreditedGrade}</p>
									<p class='text-md font-semibold '>{selectedCollege?.address}</p>
								</div>
							</div>

							<div class='text-right'>
								<p class='text-xl font-medium '>Income & Expenditure A/C</p>
								<p class='text-md font-medium '>
									{date.type} - {date.subType}
								</p>

								<p class='text-sm font-medium '>
									{moment(date.date).format('DD MMM YYYY')} to {moment(date.endDate).format('DD MMM YYYY')}
								</p>
								<p class='text-sm font-medium '>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
							</div>
						</div>
						<div className='flex justify-center items-center'>
							<Table id={'accounts'} width={'95%'} innerWidth={['10%', '50%', '20%', '20%']}>
								<Thead>
									<Tr>
										<Th position={0} item={'Heads'} />
										<Th position={1} onClick={() => requestSort('accountName')} icon={getIcon('accountName')} item={'Heads'} />
										<Th position={2} onClick={() => requestSort('reciept')} icon={getIcon('reciept')} item={'reciept'} />
										<Th position={3} onClick={() => requestSort('payment')} icon={getIcon('payment')} item={'Payment'} />
									</Tr>
								</Thead>

								<Tbody height={'58vh'}>
									{tableItems?.map((item, i) => (
										<Tr
											key={i}
											onClick={() => {
												setDate({ ...date, data: item.data, to: item._id, itemName: item.accountName });
												refetch();
											}}
											style={{ cursor: 'pointer', backgroundColor: item.elective === 'Discountined' ? '#ff8f87' : '' }}>
											<Td position={0} index={i} item={i + 1} />
											<Td position={1} textAlign={'left'} index={i} item={item?.accountName} />
											<Td position={2} textAlign={'right'} index={i} item={item?.reciept?.toFixed(2)} />
											<Td position={3} textAlign={'right'} index={i} item={item?.payment?.toFixed(2)} />
										</Tr>
									))}
								</Tbody>

								<Tfoot>
									<TfootTr>
										<TfootTd merge={2} position={0} item={'Grand Total'} />
										<TfootTd position={2} item={sumWithKey(tableItems, 'reciept')?.toFixed(2)} />
										<TfootTd position={3} item={sumWithKey(tableItems, 'payment')?.toFixed(2)} />
									</TfootTr>
								</Tfoot>
							</Table>
						</div>
					</div>
				</Body>
				<Footer></Footer>
			</Main>
			{date.showEdit && <JournalEntry onClose={() => setDate({ ...date, showEdit: false, values: {} })} datavalues={date.values} setStateData={setDate} stateData={date} />}
			{date?.data?.length > 0 && (
				<div className='fixed z-30 h-screen w-screen top-0 left-0 flex justify-center items-center bg-[#000000a9]'>
					<DetailedReportTable date={date} setDate={setDate} account={date.itemName} />
				</div>
			)}
		</>
	);
};
