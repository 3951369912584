import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import Button from '../../NecttosComp/Button/Button';
import './template.css';
import moment from 'moment';
function Template({ data, onClose }: { data: any[]; onClose: () => void }) {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const ref = useRef(null);
	const selectedCollege = collegeList?.filter((x: any) => x?._id === collegeId)[0];
	const [academic, setAcademicYear] = useState<string>('2023-24');

	useEffect(() => {}, []);
	function calculateAge(dob: Date) {
		if (!dob) return null; // Return null if dob is not provided

		const birthday = new Date(dob);
		const today = new Date();
		let age = today.getFullYear() - birthday.getFullYear();
		const m = today.getMonth() - birthday.getMonth();

		if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
			age--;
		}

		return age;
	}
	function getFamilyMemberDetails(x: { family: any[] }, relation: string) {
		if (!x || !x.family) {
			return { message: 'No family data available' };
		}

		const familyMember = x.family.find((member) => member.relation === relation);
		if (!familyMember) {
			return { message: `${relation} not listed` };
		}

		return {
			name: familyMember.name,
			mobile: familyMember.mobile,
			job: familyMember.job,
		};
	}

	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	function getSiblingsNames(x: { family: any[] }) {
		if (!x || !x.family) {
			return 'No family data available';
		}
		const siblings = x.family.filter((member) => ['Brother', 'Sister'].includes(member.relation));
		return siblings.map((sibling) => sibling.name).join(', ');
	}

	return (
		<div className='absolute top-0 left-0 right-0 bottom-0 h-screen overflow-auto'>
			<div className='flex'>
				<Button type='close' onClick={onClose}>
					Back
				</Button>
				<Button type='print' tableRef={ref.current} disabled={isLoading}>
					{isLoading ? 'Loading..' : 'Print'}
				</Button>
			</div>
			<div ref={ref} className='printable'>
				{data?.map((x: any) => (
					<>
						<div className=' grid grid-cols-2  p-7 gap-8  bg-white break-after-page	'>
							<div className='border border-black border-2 p-3'>
								<h6 className='text-[0.855vw] ps-2 mt-3'>Progression to higher studies</h6>
								<div className='w-full h-[25vh] border border-black'></div>
								<h6 className='text-[0.855vw] ps-2 mt-3'>Placement received</h6>
								<div className='w-full h-[25vh] border border-black'></div>
								<h6 className='text-[0.855vw] ps-2 mt-3'>Mentors</h6>
								<table className='w-full'>
									<thead>
										<tr>
											<th className='text-black w-1/6 py-2 bg-white border-black border'>Year</th>
											<th className='text-black w-5/6 py-2 bg-white border-black border'>Mentor Name and Signature</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className='text-black w-1/6 py-2 bg-white border-black border'>1</td>
											<td className='text-black w-5/6 py-2 bg-white border-black border'></td>
										</tr>
										<tr>
											<td className='text-black w-1/6 py-2 bg-white border-black border'>2</td>
											<td className='text-black w-5/6 py-2 bg-white border-black border'></td>
										</tr>
										<tr>
											<td className='text-black w-1/6 py-2 bg-white border-black border'>3</td>
											<td className='text-black w-5/6 py-2 bg-white border-black border'></td>
										</tr>
									</tbody>
								</table>
								<div className='flex justify-between mt-10'>
									<h6 className='text-[0.855vw]'>Class tutor</h6>
									<h6 className='text-[0.855vw]'>Head of Dept.</h6>
									<h6 className='text-[0.855vw]'>Principal</h6>
								</div>
							</div>
							<div className='border border-black border-2 p-3 relative'>
								<img src={selectedCollege?.logo} alt='college logo' className='absolute left-4 top-2 w-[6vw]' />
								{collegeId === 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' ? <h6 className='text-center text-[1.5vw] font-bold'>KOODE</h6> : <h6 className='text-center text-[1.5vw] font-bold'>INTERNAL QUALITY ASSURANCE CELL</h6>}
								<h4 className='text-center text-[1.22vw] font-semibold'>{selectedCollege?.collegeName}</h4>
								<h5 className='text-center text-[1.1vw] font-semibold'>Mentee Profile </h5>
								<h5 className='text-center text-[1.1vw] font-semibold'>ACADEMIC YEAR {academic} </h5>
								<div className='absolute top-20 right-2'>
									<p className='font-semibold text-[1.1vw]'>CLASS UG/PG/PhD</p>
									<div className='aspect-[9/10] border border-black w-[10vw]'>
										{x?.photoURL ? (
											<img src={x.photoURL} alt='Student' className='object-cover w-full h-full' />
										) : (
											<div className='flex items-center justify-center w-full h-full bg-gray-200'>
												<span>No Image</span>
											</div>
										)}
									</div>
								</div>
								<h6 className='text-[1vw] mt-3 ps-2'>Department : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Student Name : {x?.name}</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Date of Birth : {x?.dob}</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>
									Age {'('}as on {moment().format('DD/MM/YYYY')}
									{')'} : {'  ' + calculateAge(x.dob)}
								</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Phone No. : {x?.phoneNumber?.slice(-10)}</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>E-mail Id : {x?.email}</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Aadhar Number : {x?.aadhaarNumber}</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>
									Permanent Address : {x?.houseName}, {x?.postOffice}, {x?.place} - {x?.pinCode}
								</h6>

								<div className='flex w-1/2 mt-24 justify-between'>
									<h6 className='text-[1vw]  ps-2'>Community : {x?.religion}</h6>
									<h6 className='text-[1vw]  ps-2'>Caste : {x?.caste}</h6>
								</div>
								<h6 className='text-[1vw] ps-2'>Blood Group : {x?.bloodGroup}</h6>
								<h6 className='text-[1vw] ps-2'>Name of Father : {getFamilyMemberDetails(x, 'Father').name || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Profession : {getFamilyMemberDetails(x, 'Father').job || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Ph. No. : {getFamilyMemberDetails(x, 'Father').mobile || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Name of Mother : {getFamilyMemberDetails(x, 'Mother').name || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Profession : {getFamilyMemberDetails(x, 'Mother').job || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Phone No : {getFamilyMemberDetails(x, 'Mother').mobile || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Name of Guardian : {getFamilyMemberDetails(x, 'Guardian').name || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Phone No : {getFamilyMemberDetails(x, 'Guardian').mobile || 'N/A'}</h6>
								<h6 className='text-[1vw] ps-2'>Siblings: {getSiblingsNames(x)}</h6>
							</div>
						</div>
						<div className=' grid grid-cols-2  p-7 gap-8  bg-white break-after-page'>
							<div className='border border-black border-2 p-3'>
								<h6 className='text-[1vw] mt-3 ps-2'>Hosteler/ Day-scholar : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>CAP ID : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Admission No. : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Category : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>University Reg. No. : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>
									Scholarship Details {'('}if any{')'} :{' '}
								</h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Aim : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>Hobbies : </h6>
								<h6 className='text-[1vw] mt-3 ps-2'>
									Weather Having any serious illness? {'('}If yes d {')'} :{' '}
								</h6>

								<h6 className='text-[0.855vw] ps-2 mt-3 font-semibold'>Academic Achievements :</h6>
								<table className='w-full text-black'>
									<thead>
										<tr>
											<th className='bg-white w-1/4 border py-1 border-black'>Class </th>
											<th className='bg-white w-1/4 border py-1 border-black'>Board /University</th>
											<th className='bg-white w-1/4 border py-1 border-black'>Grade</th>
											<th className='bg-white w-1/4 border py-1 border-black'></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>Percentage</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>10</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>+2</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>
												UG {'('}for PG{')'}
											</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>PG for PhD.</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
									</tbody>
								</table>

								<h6 className='text-[0.855vw] ps-2 mt-3 font-semibold'>Activities in College / University </h6>
								<table className='w-full text-black'>
									<thead>
										<tr>
											<th className='bg-white w-1/4 border py-1 border-black'>Sports </th>
											<th className='bg-white w-1/4 border py-1 border-black'>Arts </th>
											<th className='bg-white w-1/4 border py-1 border-black'>Students Council/Others</th>
											<th className='bg-white w-1/4 border py-1 border-black'>NCC/NSS/Others</th>
										</tr>
									</thead>
									<tbody>
										<tr className='h-[10vh]'>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className='border border-black border-2 p-3 relative'>
								<h6 className='text-[1vw] ps-2'>Extra curricular achievements : </h6>
								<h6 className='text-[1vw] ps-2'>
									WWS / SSP/ Others {'('}Specify{')'} :{' '}
								</h6>
								<h6 className='text-[1vw] ps-2'>Scholarships: </h6>
								<div className='flex w-5/6 justify-between'>
									<h6 className='text-[1vw]  ps-2'>TC issued on : </h6>
									<h6 className='text-[1vw]  ps-2'>Specimen Signature : </h6>
								</div>
								<hr style={{ borderTop: '4px dotted black', color: 'black' }} />
								<h6 className='text-[0.855vw] text-center underline'>For use of Mentor </h6>
								<h6 className='text-[0.855vw] ps-2 mt-2'>Academic Progress</h6>
								<table className='w-full text-black'>
									<thead>
										<tr>
											<th className='bg-white w-1/4 border py-1 border-black'>Semester</th>
											<th className='bg-white w-1/4 border py-1 border-black'>Mark/CGPA</th>
											<th className='bg-white w-1/4 border py-1 border-black'>Overall Mark/ CGPA</th>
											<th className='bg-white w-1/4 border py-1 border-black'>Percentage</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>1</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>1</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>2</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>3</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>4</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>5</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>6</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
										<tr>
											<td className='bg-white w-1/4 border py-1 border-black'>Consolidated</td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
											<td className='bg-white w-1/4 border py-1 border-black'></td>
										</tr>
									</tbody>
								</table>
								<h6 className='text-[0.855vw] ps-2 mt-2'>Extra Curricular Activities</h6>
								<div className='w-full h-[8vh] border border-black'></div>
								{collegeId === 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0' ? (
									<>
										<h6 className='text-[0.855vw] ps-2 mt-2'>Notes</h6>
                    <div className='w-full h-60 border border-black'>

                    </div>
									</>
								) : (
									<>
										<h6 className='text-[0.855vw] ps-2 mt-2'>Academic Grievance if any</h6>
										<table className='w-full text-black'>
											<thead>
												<tr>
													<th className='bg-white w-1/4 border py-1 border-black'>Semester</th>
													<th className='bg-white w-1/4 border py-1 border-black'>Mark/CGPA</th>
													<th className='bg-white w-1/4 border py-1 border-black'>Overall Mark/ CGPA</th>
													<th className='bg-white w-1/4 border py-1 border-black'>Percentage</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>1</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>1</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>2</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>3</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>4</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>5</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>6</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
												<tr>
													<td className='bg-white w-1/4 border py-1 border-black'>Consolidated</td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
													<td className='bg-white w-1/4 border py-1 border-black'></td>
												</tr>
											</tbody>
										</table>
									</>
								)}
							</div>
						</div>
					</>
				))}
			</div>
		</div>
	);
}

export default Template;
