import moment from "moment";
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { getClassName } from "./Component";

interface AdmitCardProps {
  values: any;
  componentRef2: any;
  classes: any;
  type: string;
  collegeData: any;
  getQuotaTypes: (admissionTo: any) => string;
}

interface ResponseData {
  statusCode: number;
  list: {
    earnedMark: number;
  };
}

const TableHelper = ({ title, value }: { title: string; value: any }) => (
  <div className="flex h-[30px]">
    <div className="w-[10%] text-lg font-medium">{title}</div>
    <div className="w-[90%] text-lg">: {value}</div>
  </div>
);

const AdmitCard: React.FC<AdmitCardProps> = ({ values, componentRef2, classes, type, collegeData, getQuotaTypes }) => {
  const tableContent = "px-3 py-2 bg-white whitespace-wrap text-left text-lg text-black border";
  const [enable, setEnable] = useState<boolean>(false);
  const [bonusClasses, setBonusClasses] = useState<any>({});
  const [appNo, setAppNo] = useState<string>("");

  useEffect(() => {
    let appNo = "";
    if (collegeData?.collegeId === "COL-08B6B8276F75-46EE-A69C-77177AAA488B") {
      appNo = values?.applicationNo?.toString();
    } else if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      appNo = type?.toUpperCase() === "UG" ? "241" + values?.applicationNo?.toString().padStart(4, "0") : "242" + values?.applicationNo?.toString().padStart(4, "0");
    } else {
      appNo = values?.applicationNo;
    }
    setAppNo(appNo);
  }, [values?.applicationNo, collegeData?.collegeId, type]);

  useEffect(() => {
    setEnable(collegeData?.collegeId !== "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F");
  }, [collegeData?.collegeId]);

  const recalculate = async ({ classId }: { classId: string }) => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post<ResponseData>("/college/admissionProcess/calculateIndexMark", {
        studentId: values?._id,
        collegeId: collegeData?.collegeId,
        values,
        classId: classId,
      });
      if (resp?.data?.statusCode === 200) {
        setBonusClasses((prev: any) => ({
          ...prev,
          [classId]: resp?.data?.list?.earnedMark,
        }));
      }
    } catch (error) {
      console.error("Error calculating index mark:", error);
    }
  };

  useEffect(() => {
    for (let priorityOrder = 1; priorityOrder <= 10; priorityOrder++) {
      const classId = values?.[`preferedCourse${priorityOrder}`];
      if (classId) {
        recalculate({ classId });
      }
    }
  }, [values]);

  return (
    <div ref={componentRef2} id="printable" className="printable page-break bg-white font-medium">
      <div className="watermark">SAMPLE WATERMARK</div>
      <div className="p-10">
        <table className="min-w-full">
          <tbody>
            <tr>
              <td className={tableContent} style={{ width: "70%" }}>
                <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto rounded-full" />
              </td>
              <td className={tableContent} style={{ width: "15%" }}>
                <div className="relative w-26 h-26">
                  <CircularProgressbar
                    value={values?.percentageFilled}
                    styles={buildStyles({
                      pathColor: "#4caf50",
                      trailColor: "#d6d6d6",
                      textColor: "#212529",
                      textSize: "0px",
                      pathTransitionDuration: 0.5,
                    })}
                  />
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-[80%]">
                      <div className="text-[15px] text-green-900 font-medium text-center">Appl. Completed {values?.percentageFilled?.toFixed(2)}%</div>
                    </div>
                  </div>
                </div>
              </td>
              <td className={tableContent} style={{ width: "15%" }}>
                <img src={values?.photoURL} alt="Student Photo" className="h-full w-auto rounded-md" />
              </td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-center text-xl font-semibold my-3">Admission Data Card and Affirmation</h2>
        <div>
          {["COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F", "COL-08B6B8276F75-46EE-A69C-77177AAA488B"]?.includes(collegeData?.collegeId) ? (
            <>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className="px-3 py-2 bg-white whitespace-wrap text-left text-lg font-medium text-black" style={{ width: "20%" }}>
                      <TableHelper title="Name" value={values?.name?.toUpperCase()} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "50%" }}>
                      Appl. No. : {appNo}
                    </td>
                    <td className={tableContent} style={{ width: "50%" }}>
                      Fees Received : {values?.applicationAmount?.totalAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "50%" }}>
                      Name. : {values?.name}
                    </td>
                    <td className={tableContent} style={{ width: "50%" }}>
                      PROGRAMME : {getClassName(values?.dsc_a, classes)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "50%" }}>
                      Admission No. : {values?.admisionNo}
                    </td>
                    <td className={tableContent} style={{ width: "50%" }}>
                      CAPID: {values?.capId}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <table className="min-w-full">
            <tbody>
              <tr>
                {["COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F", "COL-08B6B8276F75-46EE-A69C-77177AAA488B"]?.includes(collegeData?.collegeId) ? (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Quota Applied for : {getQuotaTypes(values?.admissionTo)}
                  </td>
                ) : (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Admitted Qouta : {values?.admType}
                  </td>
                )}

                <td className={tableContent} style={{ width: "50%" }}>
                  Religion : {values?.religion}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Category : {values?.category}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Caste : {values?.caste}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Date : {moment(values?.applicationAmount?.date).format("DD-MM-YYYY HH:mm A")}
                </td>
                {enable && values?.bloodGroup && (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Blood Group : {values?.bloodGroup}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Date of Birth : {values?.dob}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Mob : {values?.phoneNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Aadhaar No : {values?.aadhaarNumber}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Gender : {values?.gender?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Email : {values?.email}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Name of Guardian : {values?.gardianName?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Guardian Mob : {values?.guardianMobile?.slice(-10)}
                </td>
                <td className={tableContent} style={{ width: "50%" }}>
                  Whatsapp : {values?.whatsappNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%" }}>
                  Mother Tongue Language : {values?.motherTongue}
                </td>
                {enable && values?.placeOfBirth && (
                  <td className={tableContent} style={{ width: "50%" }}>
                    Place of Birth : {values?.placeOfBirth}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "40%" }}>
                  Disabilities : {values?.disabilities}
                </td>
                {values?.disabilities === "Yes" && (
                  <>
                    <td className={tableContent} style={{ width: "30%" }}>
                      Disability Status : {values?.disabilityStatus}
                    </td>
                    <td className={tableContent} style={{ width: "30%" }}>
                      Disability Percentage : {values?.disabilityPercentage}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "20%" }}>
                  Permanent Address
                </td>
                <td className={tableContent} style={{ width: "80%" }}>
                  {values?.permanentHouseName?.toUpperCase()}, {values?.permanentPlace?.toUpperCase()}, {values?.permanentPostOffice?.toUpperCase()}, {values?.permanentDistrict?.toUpperCase()}, {values?.permanentState?.toUpperCase()}, {values?.permanentNationality?.toUpperCase()}, Pin:{values?.permanentPinCode}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-4 mb-2 font-medium fs-5 text-center">Declaration</div>
          <div style={{ textAlign: "justify", marginBottom: "20px" }}>
            <p>I, Mr./Ms. __________________________________________ having accepted the studentship of {collegeData?.collegeName}, do hereby affirm that I will abide by the rules and regulations of the College as specified in the prospectus and I will not indulge in any activity which amounts to the obstruction of classes, destruction/disfiguring of property, intimidation of other students like ragging, manhandling etc and I will accept any disciplinary action imposed upon me by the Principal of the college in the better interest of the institution and society.</p>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <div>
                <p>Counter Signature of the Parent:</p>
                <p>Name:</p>
                <p>Date:</p>
              </div>
              <div>
                <p>Signature of the Student:</p>
                <p>Name of the Student:</p>
              </div>
            </div>
            <div className="bg-black h-[1px] w-full"></div>
            <div className=" text-center mt-1 font-bold">FOR OFFICE USE ONLY</div>
            {["COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F", "COL-08B6B8276F75-46EE-A69C-77177AAA488B"]?.includes(collegeData?.collegeId) ? (
              <div className="flex justify-between mt-1">
                <div>
                  <p>Admission granted to:</p>
                  <p>Date of Admission:</p>
                  <p>Fees Type:</p>
                </div>

                <div className="text-right space-y-10">
                  <p>Signature of:</p>
                  <p className="font-bold">Vice Principal/Principal</p>
                </div>
              </div>
            ) : (
              <>
                <p className="font-bold">Fee Remittence</p>

                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="bg-white w-[14.29%] h-6 text-center border">PTA</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">Special Fee</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">CD</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">Tuition Fee</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">Uniform Fee</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">MISC Fee</th>
                      <th className="bg-white w-[14.29%] h-6 text-center border">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                      <td className="bg-white w-[14.29%] h-12 text-center border">____</td>
                    </tr>
                  </tbody>
                </table>

                <div className="flex justify-between mt-1">
                  <div>
                    <p>Documents Verified By:</p>
                    <p>Name:</p>
                    <p>Signature:</p>
                  </div>
                  <div>
                    <p>Nodel officer:</p>
                    <p>Name:</p>
                    <p>Signature:</p>
                  </div>
                  <div>
                    <p>Superintendent:</p>
                    <p>Name:</p>
                    <p>Signature:</p>
                  </div>
                  <div className="text-right space-y-10">
                    <p>Signature of:</p>
                    <p className="font-bold">Vice Principal/Principal</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmitCard;
