import { initializeApp } from 'firebase/app';

const firebaseConfig =
	!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
		? {
				apiKey: 'AIzaSyAMMa5mwY-Jryky1c8sBahJ3JKd5IPTJTU',
				authDomain: 'necttos-production.firebaseapp.com',
				projectId: 'necttos-production',
				storageBucket: 'necttos-production.appspot.com',
				messagingSenderId: '979469191516',
				appId: '1:979469191516:web:1cb6e9d0c902dd1f369999',
				measurementId: 'G-77YHD4ZG8Y',
		  }
		: {
				apiKey: 'AIzaSyAMMa5mwY-Jryky1c8sBahJ3JKd5IPTJTU',
				authDomain: 'necttos-production.firebaseapp.com',
				projectId: 'necttos-production',
				storageBucket: 'necttos-production.appspot.com',
				messagingSenderId: '979469191516',
				appId: '1:979469191516:web:1cb6e9d0c902dd1f369999',
				measurementId: 'G-77YHD4ZG8Y',
		  };

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
