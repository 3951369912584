import React from 'react';
import { Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';

function Metric1of1({ onClose, enableMetric }) {
	return (
		<Main width='100vw' height='100vh' className='bg-gray-900'>
			<div className='flex justify-between items-center bg-gray-800 py-3 px-4 rounded-lg shadow-md'>
				<div>
					<h6 className='text-lg font-semibold text-white'>{enableMetric?.title}</h6>
					<h3 className='text-2xl font-bold text-gray-300 uppercase tracking-wide'>{enableMetric?.subTitle}</h3>
				</div>
				<Button type='close' onClick={onClose} className='text-white hover:text-red-500'>
					Close
				</Button>
			</div>

			<main className='w-full h-full flex items-center justify-center'>
				<section className='w-3/4 h-3/4 bg-gray-800 p-8 rounded-lg shadow-lg'>
					<p className='text-xl text-white mb-6'>{enableMetric?.title}</p>

					<div className='flex flex-col items-center bg-gray-700 p-6 rounded-lg shadow-md transition-transform duration-300 hover:scale-105'>
						<p className='text-gray-300 mb-4 text-xl font-medium'>🚧 This feature is under development.</p>

						<a
							href='#'
							className='text-blue-400 hover:text-blue-300 flex items-center space-x-2 group'
							onClick={(e) => {
								e.preventDefault();
								alert('Stay tuned! This feature will be available soon.');
							}}>
							<span className='text-lg group-hover:underline'>Learn More</span>

							<svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 transition-transform duration-300 transform group-hover:translate-x-1' viewBox='0 0 20 20' fill='currentColor'>
								<path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm1.707-10.707a1 1 0 10-1.414 1.414L10.586 10H7a1 1 0 000 2h3.586l-.293.293a1 1 0 101.414 1.414l2-2a1 1 0 000-1.414l-2-2z' clipRule='evenodd' />
							</svg>
						</a>
					</div>
				</section>
			</main>
		</Main>
	);
}

export default Metric1of1;
