import React, { useContext, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button } from 'antd';
import { getAxiosTokenInstance } from '../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';

const getProfileGraph = (data) => async () => {
	let { setLoading } = data;
	setLoading(true);
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/profile/getStaffGraphData', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			setLoading(false);

			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
			return resp?.data;
		}
	} catch (error) {
		setLoading(false);
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const StaffProfile = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [state, setState] = React.useState({});
	const { data: allData = [], refetch } = useQuery(['getStaffProfileGraph', state.classId, state.searchValue], getProfileGraph({ collegeId: selectedCollege.collegeId, classId: state.classId, setLoading }));
	if (allData.length > 0) {
		setState(false);
	}

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<>
					<div className={classNames.listContainer2}>
						<div style={{ color: 'red', fontWeight: '900', textAlign: 'center', fontSize: 40 }}>
							{' '}
							{state.departmentName ? '' : 'Non-teaching Staff'} {state.departmentName?.toUpperCase()} Profile Statistics
						</div>

						<div style={{ height: '5%', width: '80%', flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>
							<div style={{ height: '100%', margin: 10, backgroundColor: 'red', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Unverified Data {((state.unverified || allData?.percentageOfPendingData) - (state.verified || allData?.percentageOfVerifiedData))?.toFixed(2)}%</div>
							<div style={{ height: '100%', margin: 10, backgroundColor: 'green', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Verified Data {(state.verified || allData?.percentageOfVerifiedData)?.toFixed(2)}%</div>
							<div style={{ height: '100%', margin: 10, backgroundColor: '#007aa3', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>Total Data {(state.unverified || allData?.percentageOfPendingData)?.toFixed(2)}%</div>
						</div>
						{state.intividualData ? (
							<div style={{ height: '70.5%', width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
								{state?.dataData?.map((x) => (
									<div onClick={() => setState({ ...state, intividualData: false, departmentName: '' })} style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center' }}>
										<div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 52, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)', transformOrigin: 'right' }}> {x.name}</div>
										<div style={{ height: `${x.percentageOfCompletion2 / 2}%`, backgroundColor: 'red', position: 'absolute', bottom: '19%', width: 52, color: '#c6ffbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageOfCompletion2?.toFixed(2)}%</div>
										<div style={{ height: `${x.percentageOfCompletion / 2}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageOfCompletion?.toFixed(2)}%</div>
									</div>
								))}
							</div>
						) : (
							<div style={{ height: '70.5%', width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
								{allData?.list?.map((x) => (
									<div onClick={() => setState({ ...state, intividualData: true, dataData: x?.data, departmentName: x?.title })} style={{ cursor: 'pointer', height: '79%', margin: '2.5%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center' }}>
										<div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 52, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)', transformOrigin: 'right' }}>{x.title.toUpperCase()}</div>
										<div style={{ height: `${x.percentagePending}%`, backgroundColor: 'red', position: 'absolute', bottom: '19%', width: 52, color: '#c6ffbf', fontWeight: '900', textAlign: 'center' }}>{x.percentagePendingd?.toFixed(2)}%</div>
										<div style={{ height: `${x.percentageAproved}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentageAprovedd?.toFixed(2)}%</div>
									</div>
								))}
							</div>
						)}
						{/* </>)} */}
					</div>
				</>
			</div>
			<Button className={classNames.reloadButton} onClick={refetch()}>
				Refresh
			</Button>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
