import React, { useContext, useState } from "react";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import { useQuery } from "react-query";
import { getAxiosInstance, getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import Input from "../../NecttosComp/Input/Input";
import Template from "./Template";

const getAllClasses =
  (collegeId: string, classOnly: boolean = true) =>
  async () => {
    const instance = getAxiosInstance();
    const { data } = await instance
      .get("/college/classes/getClass", {
        params: { collegeId, needProgress: true, classOnly },
      })
      .catch((error) => {
        throw new Error(error?.response?.data?.message || error.message || "API Error");
      });

    if (data?.statusCode === 200) {
      return data;
    } else {
      throw new Error(data?.message);
    }
  };

const getUserList = async ({ queryKey }: { queryKey: any }) => {
  const [_, { type, collegeId, classId }] = queryKey;
  try {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(`/college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data;
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

function MentorMentee({ onClose }: { onClose: () => void }) {
  const [selectedClass, setSelectClass] = useState("");
  const [template, setTemplate] = useState<any>(false);
  const { collegeId } = useContext(FirebaseContext);

  const { data: classes } = useQuery(["allClasses", collegeId, true], getAllClasses(collegeId, true));
  const { data, status, error, refetch } = useQuery([`userList`, { type: "student", classId: selectedClass, collegeId }], getUserList);
  return (
    <>
      <Main height="80vh" width="80vw">
        <Header>
          <Input fieldName="Select Class" setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="15vw" />
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </Header>
        <Body>
          <div className="flex flex-col justify-center items-center h-full">
            <h1>{data?.list?.length || 0} Students</h1>
            <Button type="save" onClick={() => setTemplate(true)}>
              Print
            </Button>
          </div>
        </Body>
      </Main>
      {template && <Template data={data?.list || []} onClose={() => setTemplate(false)} />}
    </>
  );
}

export default MentorMentee;
