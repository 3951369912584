import React, { useEffect, useState } from 'react';
import classNames from './addUserPage.module.scss';

import { Button } from 'antd';
import AddStudentTableStudents from './AddStudentTableStudents';
import AddUserFormStudents from './AddUserFormStudents';
import StudentFeeDetail from '../../Accounts/FeesBilling/components/StudentFeeDetail';

export const DepartmentVery = ({ onClose }) => {
	const [refreshStatus, setRefreshStatus] = useState(false);

	const [userType, setUserType] = useState('student');
	const [classId, setClassId] = useState('');
	const [departmentId, setDepartmentId] = useState('');
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [admisionNo, setAdmisionNo] = useState('');
	const [id, setId] = useState('');

	const selectUser = (data) => {
		setUserType('student');
		setClassId(data.classId);
		setName(data.name);
		setPhoneNumber(data.phoneNumber?.replace('+91', ''));
		setId(data.id);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={classNames.container}>
				<div className={classNames.modal}>
					<AddStudentTableStudents selectUser={selectUser} toggleRefresh={() => setRefreshStatus(!refreshStatus)} refreshStatus={refreshStatus} />
				</div>
				<Button className={classNames.closeButton} onClick={onClose}>
					Close
				</Button>
			</div>
		</>
	);
};
