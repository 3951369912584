import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useSortableData } from '../../NecttosComp/Table/Table';
import { getAllPhoto, getNewClasses } from '../../NecttosComp/APICaller/ApiServices';

import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';
import PhotoChanger from './PhotoChanger';

interface invoiceManagementProps {
	onClose: () => void;
}

function PhotoVerification({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [allowed, setAllow] = useState(false);

	const keyData = 'applicationStated';

	const { data: tableData = [], refetch } = useQuery(['getAlleeeessddseeeEnquiry', selectedClass, searchTerm], getAllPhoto(collegeId, selectedClass, searchTerm, keyData));
	const { data: classes } = useQuery('getNeeeewCladfdsfsdsses', getNewClasses(collegeId, user?.uid));

	const [filterdata, setFilter] = useState<any>([]);
	const [filterKey, setFilterKey] = useState('ALL');
	useEffect(() => {
		if (!filterKey || !tableData) return;

		switch (filterKey) {
			case 'UG':
				setFilter(tableData.filter((data: any) => ['2024-2028', '2024-2027', '2024-2029'].includes(data.batchName)));
				break;
			case 'PG':
				setFilter(tableData.filter((data: any) => ['2024-2026'].includes(data.batchName)));
				break;
			case 'ALL':
				setFilter([...tableData]);
				break;
			default:
				break;
		}
	}, [filterKey, tableData]);
	const { tableItems, requestSort, getIcon } = useSortableData(filterdata);

	const handleDelete = async (_id: string) => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.delete('/college/action/deleteAccadamicHoliday/' + _id, { params: { collegeId } })
				.then(() => {
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const { data: userDetail } = useProfileData(user.uid);

	useEffect(() => {
		if (userDetail && userDetail?.collegeRoles) {
			const roleTypes = userDetail?.collegeRoles?.filter((role: any) => role.collegeId === collegeId)?.map((role: any) => role.type);
			if (roleTypes.includes('cashier')) setAllow(true);
		}
	}, [userDetail?.collegeRoles, collegeId]);

	const addNewForm = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			instance
				.post('/college/action/postAccadamicHolidays', { ...addNew, collegeId })
				.then(() => {
					setAddNew({});
					refetch();
				})
				.catch((err: any) => {
					console.error(err);
				});
			closeAnimation();
			setTimeout(() => setShowForm(false), 500);
		} catch (err) {
			throw new Error('API Error');
		}
	};

	const skipBill = async (id: any) => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/skipBill', {
				_id: id,
				collegeId,
				managedBy: user?.uid,
				boolType: false,
			});
			if (resp?.data?.statusCode === 200) {
				refetch();
			} else {
			}
		} catch (error) {}
	};

	return (
		<>
			<Main title='Applications Initiated' width='100%' height='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
						<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />
						<view className='h-[50px]  rounded-md p-3 m-2 px-2 bg-white'>
							<h5> Total Applications: {tableItems?.length}</h5>
						</view>
					</div>
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								setFilterKey('ALL');
								setSelectClass('');
							}}>
							Show All
						</Button>
						<Button
							type='fetch'
							onClick={() => {
								setFilterKey('UG');
								setSelectClass('');
							}}>
							Show UG
						</Button>
						<Button
							type='doc'
							onClick={() => {
								setFilterKey('PG');
								setSelectClass('');
							}}>
							Show PG
						</Button>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					{tableData.map((x: any, index: number) => (
						<div
							key={index}
							style={{
								float: 'left',
								width: '250px',
								padding: '10px',
								margin: '10px',
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
								backgroundColor: '#f9f9f9',
								textAlign: 'center',
							}}>
							<div>
								<div style={{ marginBottom: '10px' }}>
									<img src={x.photoURL || 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt={x.name} style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
								</div>
								<a
									href={x.photoURL || 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'}
									download
									style={{
										position: 'absolute',
										top: '0',
										right: '0',
										padding: '5px',
										background: '#f1f1f1',
										borderRadius: '5px',
										cursor: 'pointer',
									}}>
									Download
								</a>
								<div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{x.name}</div>
								<div>
									{x.batchName} {x.shortName}
								</div>
								<div style={{ color: 'gray', fontSize: 'small' }}>Admission No: {x.admissionNo}</div>
							</div>
							<div className='flex'>
								<Button
									type='close'
									onClick={() => {
										setShowForm(false);
									}}>
									Verify
								</Button>
								<Button
									type='save'
									onClick={() => {
										setAddNew({ ...addNew, viewMode: true, _id: x._id });
									}}>
									Change Photo
								</Button>
							</div>
						</div>
					))}
				</Body>
				{addNew?.viewMode && <PhotoChanger onClose={() => setAddNew({ ...addNew, viewMode: false })} studentId={addNew?._id} />}
			</Main>

			{showForm && (
				<Main width='40vw'>
					<Header>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(() => setShowForm(false), 500);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						<div className='flex flex-col w-full'></div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default PhotoVerification;
